import { fetchReport } from './requests/fetchReport.js'

export default {
  namespaced: true,
  state: {
    haltiaRentalStatuses: [],
    haltiaRentalStatusesLoading: false,
    futureHaltiaRentalStatuses: [],
    futureHaltiaRentalStatusesLoading: false,
  },
  getters: {},
  mutations: {
    setHaltiaRentalStatuses (state, data) {
      state.haltiaRentalStatuses = data
    },
    setHaltiaRentalStatusesLoading (state, data) {
      state.haltiaRentalStatusesLoading = data
    },
    setFutureHaltiaRentalStatuses (state, data) {
      state.futureHaltiaRentalStatuses = data
    },
    setFutureHaltiaRentalStatusesLoading (state, data) {
      state.futureHaltiaRentalStatusesLoading = data
    },
  },
  actions: {
    async fetchHaltiaRentalStatuses ({ commit, rootState }, siteId) {
      commit('setHaltiaRentalStatusesLoading', true)
      try {

        const data = await fetchReport(rootState, 'haltiarentalstatuses', siteId)

        commit('setHaltiaRentalStatuses', data)
        commit('setHaltiaRentalStatusesLoading', false)
      } catch (err) {
        commit('setHaltiaRentalStatusesLoading', false)
        throw Error(err)
      }
    },
    async fetchFutureHaltiaRentalStatuses ({ commit, rootState }, siteId) {
      commit('setFutureHaltiaRentalStatusesLoading', true)
      try {

        const data = await fetchReport(rootState, 'futurehaltiarentalstatuses', siteId)
        commit('setFutureHaltiaRentalStatuses', data)

        commit('setFutureHaltiaRentalStatusesLoading', false)

      } catch (err) {
        commit('setFutureHaltiaRentalStatusesLoading', false)
        throw Error(err)
      }
    },
  },
}
