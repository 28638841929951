
<template>
  <div>
    <v-tab-item
      :key="4"
    >
      <h3 class="form-headers">
        {{ $t('create_rent_contract.payments') }}
      </h3>
      <ContractPaymentsTable
        :is-active="isActive"
        :contract-id="contractId"
        :contract="contract"
        :selected-units="selectedUnits"
        :contract-payments="contractPayments"
        :rent-period-start-date="rentPeriodStartDate"
        :rent-payment-domains="rentPaymentDomains"
        :is-out-rent="isOutRent"
        :is-internal-contract="isInternalContract"
        :selected-buildings="selectedBuildings"
        :selected-currency="selectedCurrency"
        @paymentsUpdated="onContractPaymentUpdated"
      />

      <SeparatePaymentsTable
        :is-active="isActive"
        :contract-id="contractId"
        :contract="contract"
        :selected-units="selectedUnits"
        :separate-payments="separatePayments"
        :rent-period-start-date="rentPeriodStartDate"
        :rent-payment-domains="rentPaymentDomains"
        :is-out-rent="isOutRent"
        :is-internal-contract="isInternalContract"
        :selected-buildings="selectedBuildings"
        :selected-currency="selectedCurrency"
        @paymentsUpdated="onSeparatePaymentUpdated"
      />

      <SinglePaymentsTable
        :contract-id="contractId"
        :contract="contract"
        :selected-units="selectedUnits"
        :single-payments="singlePayments"
        :rent-payment-domains="rentPaymentDomains"
        :is-out-rent="isOutRent"
        :selected-buildings="selectedBuildings"
        :selected-currency="selectedCurrency"
        @paymentsUpdated="onSinglePaymentUpdated"
      />

      <AdditionalInfoPayments
        v-if="isTemplateFieldVisible('additionalinfopayments', contractTemplate)"
        :contract="contract"
        @onAdditionalInfoUpdated="onAdditionalInfoUpdated"
      />
    </v-tab-item>
  </div>
</template>

<script>

import helpers from './../../../../helpers'
import SinglePaymentsTable from './Payments/SinglePaymentsTable.vue'
import ContractPaymentsTable from './Payments/ContractPaymentsTable.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import SeparatePaymentsTable from './Payments/SeparatePaymentsTable.vue'
import AdditionalInfoPayments from './Payments/AdditionalInfoPayments.vue'

export default {
  name: "RentPaymentsTab",
  components: {
    SinglePaymentsTable,
    ContractPaymentsTable,
    SeparatePaymentsTable,
    AdditionalInfoPayments,
},
  props: {
    rentPeriodStartDate: {
      type: String,
      default: () => undefined
    },
    isActive: {
      type: Boolean,
      default: false
    },
    contractId: {
      type: Number,
      default: undefined
    },
    selectedUnits: {
      type: Array,
      default: () => []
    },
    singlePayments: {
      type: Array,
      default: () => []
    },
    contractPayments: {
      type: Array,
      default: () => []
    },
    separatePayments: {
      type: Array,
      default: () => []
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    rentPaymentDomains: {
       type: Array,
       default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    isInternalContract: {
      type: Boolean,
      default: () => false
    },
    selectedBuildings: {
      type: Array,
      default: () => []
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
    contractTemplate: {
      type: Object,
      default: undefined
    },
  },
  emits: ['paymentsUpdated'],
  data () {
    return (
      {
        panels: [0, 1],
        paymentToEdit: undefined,
        additionalInfoPayments: this.contract?.additionalInfoPayments ?? null
      }
    )
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById']),
    ...mapGetters('rentContracts', ['isTemplateFieldVisible']),
  },
  watch: {
    async selectedBuildings () {

      if (this.selectedBuildings.length == 0) return
      this.setSelectedBuildings(this.selectedBuildings)
    }
  },
  methods: {
    ...mapActions("calculator", ["setSelectedBuildings"]),
    onSinglePaymentUpdated (payments) {
      this.$emit("paymentsUpdated", payments, this.contractPayments, this.separatePayments, this.additionalInfoPayments)
    },
    onContractPaymentUpdated (payments) {
      this.$emit("paymentsUpdated", this.singlePayments, payments, this.separatePayments, this.additionalInfoPayments)
    },
    onSeparatePaymentUpdated (payments) {
      this.$emit("paymentsUpdated", this.singlePayments, this.contractPayments, payments, this.additionalInfoPayments)
    },
    onAdditionalInfoUpdated (info) {
      this.additionalInfoPayments = info
      this.$emit("paymentsUpdated", this.singlePayments, this.contractPayments, this.separatePayments, info)
    },
    getPaymentTarget (payment) {
      if (payment.wholeContractPayment) {
        return this.$t('create_rent_contract.single_payment_whole_contract')
      }

      const findUnitName = (id) => {
        const foundUnit = this.selectedUnits.find(unit => unit.id === id)
        return foundUnit ? foundUnit.unitCode : this.$t('create_rent_contract.unit_not_found')
      }

      return payment.targetUnits ? payment.targetUnits.map(target => findUnitName(target)).join(", ") : ""
    },
    getPaymentDate (payment) {
      var momentDate = moment(payment.paymentDate).format()
      return payment.paymentDate ? helpers.humanize.date(momentDate, 'datetime') : ""
    },
    getNetSum (payment) {
      return payment.netSum != null ? helpers.humanize.amount_long(payment.netSum) : ""
    },
    getPaymentDomain (payment) {
      let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
      let name = paymentDomain[0].name
      let defDomainLabel = name
      let vat = paymentDomain[0].vatPercent
      return this.$t(defDomainLabel) + ' ' + this.$t('VAT') + ' ' + this.$t(vat) + '%'
    },
  }
}
</script>

<style>
.btn {
  text-transform: unset !important;
}
</style>