<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ $t('Allow automatic billing') }}
    </template>
    <template #content>
      <div style="font-weight: bold;">
        {{ $t('Remove block and allow billing') }}
      </div>

      <v-radio-group
        v-model="allowAutomaticBilling"
        mandatory
        class="pl-2 font-weight-bold mt-4 ml-2"
      >
        <v-radio
          default
          :label="$t('Allow-billing-FullContract')"
          value="full"
          class="font-weight-regular px-2"
        />
          
        <v-radio
            
          value="monthly"
          class="font-weight-regular px-2"
        >
          <template #label>
            <v-select
              v-model="month"
              :label="$t('Mth')"
              :items="months"
              item-text="text"
              item-value="value"
              class="mb-2 mr-2"
            />
            {{ $t('Allow-billing-lastBillingMonth') }}
          </template>
        </v-radio>
      </v-radio-group>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-col
        cols="4"
        class="text-right"
      >
        <v-btn
          text
          rounded
          outlined
          depressed
          class="primary"
          @click="SaveAutomaticBillingModal()"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-col>
    </template>
  </BaseModal>
</template>
    
    <script>
    import { mapState } from 'vuex'
    import BaseModal from '../../../general/BaseModal.vue'      
    import moment from 'moment'

    export default {
  emits: ['close', 'saved'],
      name: "AllowAutomaticBillingModal",
        components: {
          BaseModal,
        },
      
      data () {
      return {
        allowAutomaticBilling: undefined,
        month: undefined,
        months: [],
      }
      },
      computed: {
        ...mapState('app', ['currentDate', 'userWidgets']),
      },
      mounted () {
        this.formBillingMonths()
      },
      methods: {
        close () {
          this.$emit('close')
        },
        SaveAutomaticBillingModal () {
          const date = moment(this.month, 'YYYY-MM').toDate();
          
          const data = {
            date: date,
            type: this.allowAutomaticBilling
          };
          this.$emit("saved", data)
        },
        formBillingMonths () {
          const currentDate = this.currentDate

          for (let i = 0; i < 12; i++) {
            this.months.push({
              text: moment(currentDate).subtract(i, 'months').format('YYYY') + ' ' +
                    this.$t(moment(currentDate).subtract(i, 'months').format('MMMM')),
              value: moment(currentDate).subtract(i, 'months').format('YYYY') + '-' +
                    this.$t(moment(currentDate).subtract(i, 'months').format('MM'))
            });
          }
        },
      }
    }
    </script>
    
    <style scoped>
    </style>