<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ $t('guarantees.newReturn') }}
    </template>
    <template #content>
      <v-row class="my-2">
        <v-col>                    
          <CurrencyField
            :value="returnSum"
            :label="$t('Sum')"
            :currency="selectedCurrency"
            :decimals="2"
            type="number"
            @change="onReturnSumChanged"
          />
        </v-col>
        <v-col>
          <DatePicker
            :label="$t('GuaranteeDateOfReturn')"
            class="date-picker"
            :initial-date="dateOfReturn"
            @dateupdated="dateOfReturnUpdated"
          />
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        color="primary"
        @click="onAddGuaranteeReturn"
      >
        {{ addButtonText }}
      </v-btn>
    </template>
  </Basemodal>
</template>
<script>
import BaseModal from '../../../general/BaseModal.vue'
import DatePicker from '../../../general/DatePicker.vue'
import CurrencyField from '../../../CurrencyField.vue'

export default {
  emits: ['close', 'guaranteeReturnUpdated', 'guaranteeReturnAdded'],
  name: "GuaranteeReturnModal",
  components: {
      BaseModal,
      DatePicker,
      CurrencyField,
  },
  props: {
    return: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
      returnSum: undefined,
      dateOfReturn: undefined,
    }
  },
  computed: {
    addButtonText () {
      return this.return ? this.$t('GuaranteeSave') : this.$t('GuaranteeAdd')
    },
  },
  watch: {
    visible () {
      if (this.visible) {
        this.copyDataFromReturn()
      }
    },
    return () {
      this.copyDataFromReturn()
    },
  },
  methods: {
    copyDataFromReturn () {
      if (this.return) {
        this.returnSum = this.return.returnSum
        this.dateOfReturn = this.return.dateOfReturn
      }
    },
    clear () {
      this.returnSum = undefined
      this.dateOfReturn = undefined
    },
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
        this.clear()
      }
    },
    dateOfReturnUpdated (val) {
      this.dateOfReturn = val
    },
    async onAddGuaranteeReturn () {
      const modifiedGuaranteeReturn = {
          id: this.return?.id,
          returnSum: Number(this.returnSum),
          dateOfReturn: this.dateOfReturn,
      }

      if (this.return) {
        this.$emit('guaranteeReturnUpdated', modifiedGuaranteeReturn)
        this.clear()
      } else {
        this.$emit('guaranteeReturnAdded', modifiedGuaranteeReturn)
        this.clear()
      }
    },
    onReturnSumChanged (value) {
      this.returnSum = value
    },
  }
}
</script>
<style scoped>
.date-picker >>> .form-field {
  margin-top: var(--r-spacing-xxs) !important;
}
</style>
