<template>
  <div>
    <v-dialog
      v-model="showContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          v-if="showContractModal"
          :contract-id="editedContractId"
          :out-rent="outRent"
          @handleSave="onCreateRentContractModalClosed()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="unitMarketingModifyDialog"
      persistent
      max-width="1000"
    >
      <v-card>
        <MarketingModal
          v-if="unitMarketingModifyDialog"
          :selected-unit="selectedUnit"
          @updateWidget="$emit('updateWidget')"
          @close="unitMarketingModifyDialog = false"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="uploadDialogVisible"
      persistent
      max-width="550"
    >
      <FileUploadForm
        v-if="uploadDialogVisible"
        :document="document"
        :site="targetSite"
        :sites="[targetSite]"
        :mode="'new'"
        @cancel="closeUploadDialog"
        @created="documentUploaded"
      />
    </v-dialog>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="showTableDataDialog = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h2>
              {{ tableData.title }}
            </h2>
          </v-card-title>
        </div>
        <Table
          :rows="tableData.rows"
          :headers="tableData.headers"
          :footers="browseFooters"
          :footer-definitions="tableData.footers"
          :pagination.sync="viewDataOptions"
          :custom-formatters="customFormatters"
          :custom-filter-content="data.customFilterContent"
          @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
        >
          <template
            #customComponent="{ item }"
          >
            <slot
              :item="item"
              name="customComponent"
            />
          </template>
        </Table>
      </v-card>
    </v-dialog>
    <invoice-selector
      v-if="widgetId == 'billing.invoice_archive'"
      :widget-id="widgetId"
      @dataUpdated="$emit('dataUpdated', $event)"
    />
    <Table
      v-if="isFullTable"
      :rows="fullTableData.rows"
      :headers="fullTableData.headers"
      :pagination.sync="viewDataOptions"
      :custom-formatters="customFormatters"
      :hide="hideDefaultButtons"
      no-expansion-panel
      @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
      @updatedCurrentRows="$emit('updatedCurrentRows', $event)"
    >
      <template #table-buttons>
        <slot name="table-buttons" />
      </template>
    </Table>
    <v-data-table
      v-else-if="widgetId != 'billing.invoice_archive' || (items && items.length > 0)"
      :header-props="dataTableHeaderDefinitions"
      :headers="
        data.headers.map((h) => {
          return {
            text: $t(h.text),
            value: h.value,
          }
        })
      "
      :items="items"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      :no-data-text="dataMissing"
      class="elevation-0"
      mobile-breakpoint="0"
      locale="fi-FI"
      :multi-sort="data.multiSort"
    >
      <template
        #item="props"
      >
        <tr>
          <template v-for="header in data.headers">
            <td
              v-if="header.format === 'externalLink'"
              :key="header.value"
            >
              <a
                :href="formatData(props.item, header.value, header.format, header)"
                target="_blank"
                rel="noreferrer"
              >
                <v-icon>open_in_new</v-icon>
                <span class="d-sr-only">{{ $t('building_projects.OpenInNewWindow') }}</span>
              </a>
            </td>
            <td
              v-else-if="(header.value === 'site_name' || header.value === 'site' || header.value === 'siteName' || (header.value === 'name' && header.text === $t('Site'))) && (props.item.id_site || props.item.siteIdentifier)"
              :key="header.value"
            >
              <button
                :class="{
                  'col-align-right': isRightAligned(header.format),
                }"
                class="linked"
                @click="linkTo(props.item, 'sites.info')"
              >
                {{ formatData(props.item, header.value, header.format, true) }}
              </button>
            </td>
            <td
              v-else-if="header.value === 'scheme_specification'"
              :key="header.value"
              class="doc-tags"
            >
              <div
                v-if="props.item.scheme_specification.length > 0"
                style="display: block; max-width: 100%"
              >
                <v-chip
                  v-for="spec in props.item.scheme_specification"
                  :key="spec.name"
                  small
                  outlined
                >
                  {{ spec.name }}
                </v-chip>
              </div>
              <div
                v-else
                style="color: #000000de !important"
              >
                {{ formatData(props.item.scheme_specification, header.value, header.format) }}
              </div>
            </td>
            <td
              v-else-if="header.value === 'diary.actions'"
              :key="'diary' + header.value"
            >
              <v-icon
                @click="editDiaryEntry(props.item)"
              >
                edit
              </v-icon>
              <span class="d-sr-only">{{ $t('Edit') }}</span>
              <v-icon
                @click="deleteDiaryEntry(props.item)"
              >
                close
              </v-icon>
              <span class="d-sr-only">{{ $t('Close') }}</span>
            </td>
            <td
              v-else-if="header.value === 'esg.certificate.actions'"
              :key="header.value"
              style="min-width: 130px"
            >
              <v-btn
                icon
                @click="esgCertificateModalOpen = true, certificateId = props.item.id"
              >
                <v-icon>edit</v-icon>
                <span class="d-sr-only">{{ $t('Edit') }}</span>
              </v-btn>
              <v-btn
                icon
                @click="deleteCertificate(props.item)"
              >
                <v-icon>delete</v-icon>
                <span class="d-sr-only">{{ $t('Delete') }}</span>
              </v-btn>
            </td>
            <td
              v-else-if="header.value === 'buildingproject_identifier' || header.value === 'buildingproject_number'"
              :key="header.value"
            >
              <button
                :class="getLinkStyle(props.item)"
                @click="showBuildingProjectValueInformation = true; formatBuildingProject(props.item)"
              >
                {{ formatData(props.item, header.value, header.format, true) }}
              </button>
            </td>
            <td
              v-else-if="header.unitLink"
              :key="header.value"
              :class="getLinkStyle(props.item)"
              @click="linkTo(props.item, 'sites.facilitymanagement')"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value === 'file'"
              :key="header.value"
              :class="getLinkStyle(props.item)"
              @click="linkTo(props.item, 'sites.building_document_download')"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value === 'unit_names'"
              :key="header.value"
            >
              <div
                v-for="unit in getUnitNames(props.item.contractId, props.item.id_site)"
                :key="unit.unit_name"
                :class="getLinkStyle(unit)"
                @click="unit.unit_id ? routeTo(props.item.id_site, unit.unit_id) : null"
              >
                <div>
                  {{ escapeHtml(unit.unit_name) }}
                </div>
              </div>
            </td>
            <td
              v-else-if="header.value === 'contractNumber' || header.value === 'contract_number' || (header.value === 'alertIdentifier' && props.item.idContract)"
              :key="header.value"
            >
              <span v-if="!!header.event && (props.item.contractId || props.item.idContract || props.item.id)">
                <button
                  :class="{
                    'col-align-right': isRightAligned(header.format),
                    'linked': !!header.event
                  }"
                  @click="!!header.event
                    ? $emit('triggerCustomEvents', { eventName: header.event, row: props.item })
                    : undefined"
                >
                  <span class="d-sr-only">{{ $t('ViewTheContract') + ' ' }}</span>
                  {{ formatData(props.item, header.value, header.format, true) }}
                </button>
              </span>
              <span
                v-else
                :class="{
                  'col-align-right': isRightAligned(header.format)
                }"
              >
                {{ formatData(props.item, header.value, header.format, true) }}
              </span>
            </td>
            <td
              v-else-if="header.value === 'january' ||
                header.value === 'march' ||
                header.value === 'april' ||
                header.value === 'may' ||
                header.value === 'june' ||
                header.value === 'july' ||
                header.value === 'august' ||
                header.value === 'september' ||
                header.value === 'october' ||
                header.value === 'november' ||
                header.value === 'december'"
              :key="header.value"
              :style="{backgroundColor: colorDerived(props.item, header.value) }"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value === 'diploma_document_id'"
              :key="header.value"
              style="text-align: center"
            >
              <v-btn
                v-if="props.item.diploma_document_id"
                :disabled="!hasApplicationPermissionByName('DOKUMENTTIEN_HALLINTA') || isRetrievingFile"
                small
                depressed
                rounded
                color="primary"
                @click="downloadCertificateDocument(props.item.diploma_document_id)"
              >
                {{ $t('Download') }}
              </v-btn>
              <v-btn
                v-if="!props.item.diploma_document_id"
                :disabled="!hasApplicationPermissionByName('DOKUMENTTIEN_HALLINTA')"
                small
                text
                rounded
                outlined
                @click="addCertificationDocument(props.item)"
              >
                {{ $t('Add') }}
              </v-btn>
            </td>
            <td
              v-else-if="header.value === 'contract_numbers'"
              :key="header.value"
            >
              <div
                v-for="contract in props.item.contract_numbers"
                :key="contract.id"
              >
                <button
                  v-if="contract"
                  class="linked"
                  @click="openContractModal(contract.id, contract.isOutRent)"
                >
                  {{ contract.contractNumber }}
                </button>
              </div>
            </td>
            <HideOverflowColumn
              v-else-if="header.hideOverflow"
              :key="'hideOverflow' + header.value"
              :header="header"
              :item="props.item"
            />
            <td
              v-else-if="header.value === 'externalLink'"
              :key="header.value"
              class="description"
            >
              <a
                :href="props.item.externalLink"
                onclick="return false"
              >
                <!--return false so user cant use href link, except on the case user wants to right click link and select "open in new tab"-->
                {{ formatData(props.item, header.value, header.format, true) }}
              </a>
            </td>
            <td
              v-else-if="header.value === 'alertStatus'"
              :key="header.value"
              class="py-1"
            >
              <v-chip
                :color="props.item.alertStatus.color"
                small
              >
                {{ $t(props.item.alertStatus.label) }}
              </v-chip>
            </td>
            <td
              v-else-if="header.value === 'rating_class_name' && props.item.rating_class_name"
              :key="header.value"
              class="py-1"
            >
              <v-chip
                color="tertiary"
                small
              >
                {{ $t(props.item.rating_class_name) }}
              </v-chip>
            </td>
            <td
              v-else-if="header.value === 'marketing'"
              :key="header.value"
              class="py-1"
            >
              <v-chip
                :color="props.item.is_enabled ? 'primary' : 'default'"
                class="mt-1 mr-2"
                small
              >
                {{ $t(props.item.marketing) }}
              </v-chip>
              <em
                v-if="props.item.status !== null && !((props.item.status == 'MarketingStateInQueue' && props.item.is_enabled == false) || (props.item.status == 'MarketingStateSent' && props.item.is_enabled == true))"
                class="mr-2"
              >
                {{ $t('marketing.status.' + props.item.status) }}
              </em>
            </td>
            <td
              v-else-if="header.value === 'marketing_id'"
              :key="header.value"
              class="col-align-left"
            >
              <v-btn
                :disabled="!hasApplicationPermissionByName('MARKKINOINTI_MUOKKAUS')"
                small
                icon
                right
                @click="editMarketing(props.item)"
              >
                <v-icon>edit</v-icon>
                <span class="d-sr-only">{{ $t('Modify') }}</span>
              </v-btn>
              <a
                v-if="props.item.public_listing_url != null && props.item.public_listing_url.length > 0"
                :href="props.item.public_listing_url"
                target="_blank"
                rel="noreferrer"
              >
                <v-icon>open_in_new</v-icon>
                <span class="d-sr-only">{{ $t('marketing.public_listing_url_description') }}</span>
              </a>
            </td>
            <td
              v-else-if="header.value === 'customComponent'"
              :key="'customComponent' + header.value"
            >
              <slot
                v-if="props.item.showCustomComponent !== false"
                :item="props.item"
                name="customComponent"
              />
            </td>
            <td
              v-else-if="header.value !== 'general_rating' &&
                header.value !== 'report_date' &&
                header.value !== 'projectState' &&
                header.value !== 'damageState' &&
                header.value !== 'adjustmentState' &&
                header.value !== 'costEstimate' &&
                header.value !== 'agreement_end_date' &&
                header.value !== 'certification_expires' &&
                header.value !== 'curPartyStartDate' &&
                header.value !== 'taskDate' ||
                (header.value === 'taskDate' && props.item.alertStatus.label !== 'alert.not_completed')"
              :key="header.value"
              :class="{ 'col-align-right': isRightAligned(header.format) }"
            >
              {{ formatData(props.item, header.value, header.format) }}
            </td>
            <td
              v-else
              :key="header.value"
            >
              <v-row
                no-gutters
                class="flex-nowrap"
              >
                <v-col>
                  <StatusIcon
                    :field="
                      getObjectWithUnit(props.item[header.value], header.value)
                    "
                    :data="
                      formatData(props.item, header.value, header.format)
                    "
                  />
                </v-col>
                <v-col
                  :key="header.value"
                  class="d-flex align-center justify-end text-no-wrap"
                >
                  {{ formatData(props.item, header.value, header.format) }}
                </v-col>
              </v-row>
            </td>
          </template>
        </tr>
      </template>
      <template slot="body.append">
        <tr tabindex="0">
          <td
            v-for="(footer, idx) in data.footers"
            :key="idx"
            :class="{ 'col-align-right': isRightAligned(footer.format) }"
          >
            {{
              footer.numeratorSum !== undefined ?
                getSumQuotientFooterValue(data.items, footer) :
                getFooterValue(
                  data.items,
                  footer.value,
                  footer.text,
                  footer.average,
                  footer.format
                )
            }}
          </td>
        </tr>
      </template>
      <!-- Slot for filtering the contents. Filter options can be added in the widget object (see widgets/leasing f.ex.) -->
      <template
        v-if="data.filter"
        #footer
      >
        <div
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-menu
            offset-x
            :close-on-content-click="!data.filter.multiple"
          >
            <template #activator="{ on, attrs }">
              <v-card
                elevation="0"
                class="filterBox"
              >
                <v-btn
                  v-bind="attrs"
                  outlined
                  x-small
                  fab
                  rounded
                  class="filterButton"
                  v-on="on"
                >
                  <v-icon>
                    filter_list
                  </v-icon>
                  <span
                    class="text-subtitle-2"
                  >{{ hasMultipleFilters ? $t('Multiple') : displayText }}</span>
                </v-btn>
              </v-card>
            </template>
            <v-list>
              <v-list-item-group
                v-model="filters"
                active-class="active"
                return-object
                :multiple="data.filter.multiple || false"
              >
                <v-list-item
                  v-for="(listItem, index) in data.filter.options"
                  :key="Array.isArray(listItem.value) ? listItem.value.join() + 'idx' + index : listItem.value + 'idx' + index"
                >
                  <template #default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(listItem.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="data.filter.multiple">
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <div
          v-else
          style="margin: 0px 0px -45px 15px"
        >
          <v-menu
            offset-x
            :close-on-content-click="!data.filter.multiple"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                outlined
                x-small
                fab
                rounded
                v-on="on"
              >
                <v-icon>
                  filter_list
                </v-icon>
              </v-btn>
              <span class="d-sr-only">: {{ $t('Filters') + filterTexts }}</span>
            </template>
            <v-list>
              <v-list-item-group
                v-model="filters"
                active-class="active"
                return-object
                :multiple="data.filter.multiple || false"
              >
                <v-list-item
                  v-for="(listItem, index) in data.filter.options"
                  :key="index"
                >
                  <template #default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(listItem.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="data.filter.multiple">
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-tooltip
            v-if="hasMultipleFilters"
            top
          >
            <template #activator="{ on }">
              <span v-on="on">...</span>
            </template>
            <span class="text-subtitle-1">{{ filterTexts }}</span>
          </v-tooltip>
          <span
            v-else
            class="text-subtitle-1"
          >{{ displayText }}</span>
        </div>
      </template>
      <template
        v-slot:footer.page-text="item"
        style="overflow-y:hidden"
      >
        <div
          v-if="!$vuetify.breakpoint.mobile"
        >
          <slot name="table-buttons" />
          <ImportButton
            v-if="importing"
            :headers="data.headers"
            @dataUpdate="$emit('dataUpdated', $event)"
          />
          <v-btn
            v-if="importing || exportCsv"
            small
            outlined
            rounded
            @click="exportAsCsv(data.headers, data.items)"
          >
            {{ $t('Export to spreadsheet') }}
          </v-btn>
          <DiaryDialog
            v-if="data.button === 'addDiary'"
            v-model="siteDiaryDialog"
            :site-id="siteId"
            :items="itemsToEdit"
            @updateWidget="$emit('updateWidget')"
            @close="siteDiaryDialog = false"
          />
          <EsgCertificateForm
            v-if="data.button === 'addEsgCertificate'"
            v-model="esgCertificateModalOpen"
            :site-id="siteId"
            :certificate-id="certificateId"
            @updateWidget="$emit('updateWidget')"
            @close="esgCertificateModalOpen = false, certificateId = null"
          />
          <v-btn
            v-if="!noBrowse"
            small
            outlined
            rounded
            :aria-label="`${$t('Browse')}: ${title}`"
            @click="viewData(title, data.headers, data.items,data.browseHeaders)"
          >
            {{ $t('Browse') }}
          </v-btn>
          {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
          {{ item.itemsLength.toLocaleString('fi-FI') }}
        </div>
        <div
          v-if="$vuetify.breakpoint.mobile"
          class="buttons-container2"
        >
          {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
          {{ item.itemsLength.toLocaleString('fi-FI') }}
        </div>
      </template>
    </v-data-table>
    <div
      v-if="$vuetify.breakpoint.mobile"
      class="buttons-container"
    >
      <slot name="table-buttons" />
      <ImportButton
        v-if="importing"
        :headers="data.headers"
        @dataUpdate="$emit('dataUpdated', $event)"
      />
      <v-btn
        v-if="importing || exportCsv"
        small
        outlined
        rounded
        @click="exportAsCsv(data.headers, data.items)"
      >
        {{ $t('Export to spreadsheet') }}
      </v-btn>
      <v-btn
        v-if="!noBrowse"
        small
        outlined
        rounded
        @click="viewData(title, data.headers, data.items,data.browseHeaders)"
      >
        {{ $t('Browse') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import helpers from '../../helpers'
import Table from '../Table.vue'
import ImportButton from '../../components/ImportButton.vue'
import StatusIcon from '../../components/StatusIcon.vue'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUploadForm from '../FileUploadForm.vue'
import DiaryDialog from '../DiaryDialog.vue'
import MarketingModal from '../Leasing/MarketingModal.vue'
import CreateRentContractModal from '../Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'
import InvoiceSelector from './components/InvoiceSelector.vue'
import HideOverflowColumn from '../../components/general/HideOverflowColumn.vue'
import EsgCertificateForm from '../EsgCertificateForm.vue'
import { currencySumFields } from '../../helpers/table.js'

export default {
  components: {
    Table,
    ImportButton,
    StatusIcon,
    FileUploadForm,
    MarketingModal,
    DiaryDialog,
    CreateRentContractModal,
    InvoiceSelector,
    HideOverflowColumn,
    EsgCertificateForm
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    customFormatters: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isFullTable: {
      type: Boolean,
      default: false
    },
    hideDefaultButtons: {
      type: Boolean,
      default: false
    },
    widgetId: {
      type: String,
      default: null
    },
    hideTotalRow: {
      type: Boolean,
      default: false,
    },
    averageTotalRow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateWidget', 'triggerCustomEvents', 'dataUpdated', 'updatedCurrentRows', 'view-data'],
  data () {
    return {
      colorAreasRed: false,
      showTableDataDialog: false,
      unitMarketingModifyDialog: false,
      siteDiaryDialog: false,
      esgCertificateModalOpen: false,
      itemsToEdit: {},
      certificateId: null,
      selectedUnit: null,
      showContractModal: false,
      outRent: true,
      editedContractId: null,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {
        page: 1,
        itemsPerPage: this.data.rowsPerPage ? this.data.rowsPerPage : 5,
        sortBy: Array.isArray(this.data.sortBy) ? this.data.sortBy : [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: Array.isArray(this.data.sortDesc) ? this.data.sortDesc : [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        page: 1,
/*    Puhuttiin että "selaa" -näkymässä saa olla aina kaikki rivit, joten tämän voinee poistaa?
      Tätä dataa ei käytetä muualla ja viewData -funktiossa joka tapauksessa jyrätään.
          itemsPerPage: this.data.browseRowsPerPage
          ? this.data.browseRowsPerPage
          : this.data.rowsPerPage
            ? this.data.rowsPerPage
            : 100,
*/
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      importing: this.data.importing ? this.data.importing : false,
      exportCsv: this.data.exportCsv ? this.data.exportCsv : false,
      browseFooters: [],
      uploadDialogVisible: false,
      document: null,
      targetSite: null,
      targetCertificationRow: null,
      isRetrievingFile: false,
      noBrowse: this.data.noBrowse ?? false,
      showBuildingProjectValueInformation: false,
      buildingProjectId: '',
      buildingProject: null,
      filters: [],
      dataMissing: '',
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions', 'hasApplicationPermissionByName', 'currencies']),
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    ...mapState('app', ['sites']),
    items: function () {
      const headers = this.data.headers
      const items = []
      this.data.items.map(item => {
        // Filter data if filtering is selected
        if (this.filters.length > 0 && !this.filters.some(filter => !helpers.tableFilter.filterRow(filter, this.data.filter, item))) {
          return
        }
        const thisItem = JSON.parse(JSON.stringify(item))
        headers.forEach(header => {
          const key = header.value
          if (Array.isArray(item[key]) && header.format !== 'Date' && header.format !== 'List') {
            // No need to duplicate things
            const list = [... new Set(item[key]) ]
            let itemStr = ''
            // If there is more than 4 items, just show the first 4 items and add ... as the last item
            if (list.length > 4) {
              itemStr = [
                ...list.slice(0, 4).map(v => this.getValueWithUnit(v, key))].join(', ') + '...'
            } else {
              itemStr = [...list]
                .map(v => this.getValueWithUnit(v, key))
                .filter(v => v !== null && v !== '')
                .join(', ')
            }
            thisItem[key] = itemStr
          }
        })
        items.push(thisItem)
      })
      return items
    },
    siteId () {
      return Number(this.$route.params.id_site)
    },
    fullTableData () {
      if (!this.isFullTable) {
        return  {
          title: '',
          headers: [],
          rows: [],
          footers: []
        }
      } else {
        return this.getTableData(this.title, this.data.headers, this.data.items)
      }
    },
    filterTexts () {
      return this.filters.map(index => this.$t(this.data.filter.options[index].text)).join(', ');
    },
    hasMultipleFilters () {
      return this.filters.length > 1;
    },
    displayText () {
      return this.filters.length === 1 ? this.filterTexts : this.$t('All');
    },
  },
  watch: {
    filters (value) {
      if (!Array.isArray(value)) {
        this.filters = [value];
      }
    },
  },
  mounted () {
    this.colorAreasRed = this.hasNonDWGSAreas()
    // If default filter is given, set it on mounted
    if (this.data.filter && this.data.filter.defaultFilter != null) {
      this.filters.push(this.data.filter.options.findIndex(option => option.text === this.data.filter.defaultFilter.text))
    }
    if (this.isFullTable) {
      this.setTableData(this.title, this.data.headers, this.data.items)
    }
    const date = this.$store.state.app.currentDate
    const today = new Date()

    if (!moment(date, 'day').isSame(today, 'day')) {
      this.dataMissing = this.$t('No data available or not time related')
    } else {
      this.dataMissing = this.$t('No data available')
    }
  },
  methods: {
    ...mapActions('dashboard', ['updateCertificationDocumentId']),
    hasNonDWGSAreas () {
      const buildingsWithDwgs = this.buildingsForCurrentSite.filter(x => x.has_dwg)
      if (buildingsWithDwgs.length > 0) {
        const buildings = buildingsWithDwgs.map(b => {
          return b.building_name
        })
        const itemBuildings = this.data.items.map(i => {
          if (i.building_name) {
            return i.building_name
          }
          if (i.buildingName) {
            return i.buildingName
          }
        })
        if (itemBuildings !== undefined && itemBuildings.length > 0) {
          const set = [...new Set(itemBuildings)]
          // Logic here is that if the datatable contains buildings that are not among
          // the ones that have dwgs available and thus the length of the arrays differs,
          // we return true as it means widget contains area values that have no dwgs as a base
          if (set !== undefined && set.length === buildings.length) {
            return false
          } else if (set !== undefined && set.length > buildings.length){
            return true
          }
        }
      } else {
        return true
      }
    },
    onCreateRentContractModalClosed () {
      this.showContractModal = false
      this.outRent = true
      this.editedContractId = undefined
    },
    openContractModal (id, isOutRent) {
      this.editedContractId = Number(id)
      this.showContractModal = true
      this.outRent = isOutRent
    },
    editMarketing (selectedUnit) {
      this.selectedUnit = selectedUnit
      this.unitMarketingModifyDialog = true
    },
    async linkTo (destination, path) {
      let target = undefined
      if (destination.siteIdentifier !== undefined ) {
        target = destination.siteIdentifier
      }
      else if (destination.id_site !== undefined) {
        target = destination.id_site
      }
      else if (destination.siteId !== undefined) {
        target = destination.siteId
      } else if (destination.instance_id !== undefined) {
        target = destination.instance_id
      }

      if (target !== undefined) {
        if (path === 'sites.info') {
          this.$router.push({
            name: 'sites.info',
            params: { id_site: target }
          })
        }

        if (path === 'sites.facilitymanagement') {
          let unitparam = undefined
          // dashboard link
          if (destination.unitIdentifier !== undefined) {
            unitparam = destination.unitIdentifier
          }
          // site link
          else if (destination.unitId !== undefined) {
            unitparam = destination.unitId
          }
          else if (destination.unit_id !== undefined) {
            unitparam = destination.unit_id
          }
          else if (destination.id_unit !== undefined) {
            unitparam = destination.id_unit
          }

          let plan = await this.$rambollfmapi.sites.get(target)
          if(plan.has_dwg === true && unitparam !== undefined) {
            this.$router.push({
              name: 'sites.facilitymanagement',
              params: { id_site: target },
              query: { suite_id: unitparam }
          })}
          else {
            this.$router.push({
              name: 'sites.info',
              params: { id_site: target }
          })}
        }
        if (path === 'sites.building_document_download')
        {
          await this.$rambollfmapi.documents
            .downloadBuildingDocument(destination.instance_id, destination.file)
            .then(res => {
              this.isRetrievingFile = false
              const blob = new Blob([res.data], { type: 'octet/stream' })
              helpers.saveAs(blob, destination.file)
            })
            .catch(() => {
              this.isRetrievingFile = false
            })
        }
      }
    },
    getValueWithUnit (value, header) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }
      if (header === 'general_rating') {
        return helpers.format.valueWithUnit(value, header)
      }
      if (this.siteId !== undefined && this.siteId !== null) {
        if (!isNaN(this.siteId)) {
            if (header.indexOf('area') >= 0 && this.colorAreasRed === true) {
              return helpers.format.markValueAsRed(value, header)
            }
        }
      }
      return helpers.format.valueWithUnit(value, header)
    },
    // link exceptions
    getLinkStyle (value)
    {
      if(value.priority !== undefined)
        return null
      else
        return 'linked'
    },
    getObjectWithUnit (data, name) {
      var object = { name, data }
      return object
    },
    getFooterValue (data, headerName, text, average, format) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }
      // If there are multiple currencies in the data, selectedCurrency value will be 'multiple' and sum will not be calculated, instead '-' will be shown in the footer
      const rowCurrency = helpers.table.defineRowCurrency(data, this.currencies)

      let value = currencySumFields.includes(headerName) && rowCurrency === 'multiple' ? 0 : data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (headerName === 'general_rating') {
        var totalCount = 0
        var averageRating = 0
        var sitesWithAudit = 0
        for (let i = 0; i < data.length; i++) {
          var singleRate = data[i].general_rating
          if (singleRate !== null) {
            totalCount += singleRate
            sitesWithAudit += 1
          }
        }
        averageRating = totalCount / sitesWithAudit
        if (isNaN(averageRating)) {
          averageRating = 0
          }
        value = helpers.format.formatData(averageRating, format)
        return value
      }
      if (typeof average !== 'undefined' && average === true) {
        if (data.length === 0) {
          return 0
        }
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      value = helpers.format.formatData(value, format, rowCurrency)
      return value
    },
    // TODO: implement this with dataFetcher, example dataImport.new_contracts widget
    getSumQuotientFooterValue (data, footer) {
      if (data.length === 0) {
        return 0
      }
      const numeratorSum = data
        .map(i => {
          const toNumber = Number(i[footer.numeratorSum])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      let denominatorSum
      if (footer.denominator) {
        denominatorSum = Number(data[0][footer.denominator])
        if (isNaN(denominatorSum)) {
          denominatorSum = 0
        }
      } else {
        denominatorSum = data
          .map(i => {
            const toNumber = Number(i[footer.denominatorSum])
            return isNaN(toNumber) ? 0 : toNumber
          })
          .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      }
      let multiplier = 1
      if (footer.unit === "percentage") {
        multiplier = 100
      }
      let quotientValue = (numeratorSum / denominatorSum * multiplier).toFixed(2)
      if (footer.hundredMinusValue) {
        quotientValue = 100 - quotientValue
      }
      return helpers.format.formatData(quotientValue, footer.format)
    },
    getTableData (title, headers, data) {
      // Filter data if filtering is selected
      let filteredData = []
      if (this.filters.length > 0 && this.data.filter != null) {
        data.map(item => {
          if (!this.filters.some(filter => !helpers.tableFilter.filterRow(filter, this.data.filter, item))) {
            return
          }
          filteredData.push(item)
        })
      } else {
         filteredData = data
      }
      const newTableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          return h
        }),
        rows: filteredData,
        footers: this.data.footers
      }
      return newTableData
    },
    setTableData (title, headers, data) {
      this.tableData = this.getTableData(title, headers, data)
      this.viewDataOptions = {
        sortDesc: this.options.sortDesc,
        sortBy: this.options.sortBy,
        itemsPerPage: -1
      }

      if (this.widgetId == 'billing.invoice_archive') {
        this.viewDataOptions.itemsPerPage = this.data.browseRowsPerPage || 100
      }
      this.browseFooters = helpers.table.createFooters(this.data.headers, this.data.items, this.data.browseFooters, this.data.footers, this.hideTotalRow, this.averageTotalRow)
    },
    viewData (title, headers, data, browseHeaders) {
      if (browseHeaders !== null && browseHeaders !== undefined) {
        headers = browseHeaders;
      }
      // this.$root.$emit('view-data', title, headers, data)
      this.setTableData(title, headers, data)
      this.showTableDataDialog = true
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    exportAsCsv (originalHeaders, originalData) {
      const headers = [...JSON.parse(JSON.stringify(originalHeaders))]
      const data = [...JSON.parse(JSON.stringify(originalData))]

      const csvHeaders = headers.map(h => this.$t(h.text))
      const csvData = data.map(d => {
        const row = []
        headers.forEach(h => {
          let val = d[h.value]
          val = val === undefined ? '' : val
          val = this.getValueWithUnitForCsvExport(val, h.value, false)
          row.push(val)
        })
        return row
      })
      helpers.csv.export(csvHeaders, csvData)
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, encode)
      }

      if (header.includes('date')) {
        return helpers.format.valueWithUnit(value, header, encode)
      }

      if (value === null) {
        return ''
      }

      if (!isNaN(value)) {
        return value.toString().replace('.', ',')
      }

      return value
    },
    getTruncatedStringFromArray (value, header) {
      // No need to duplicate things
      const list = new Set(value)
      let itemStr = ''
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      if (list.length > 4) {
        itemStr = [
          ...list
            .slice(0, 4)
            .map(v => this.getValueWithUnit(v, header)),
          '...'
        ].join(', ')
      } else {
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header))
          .filter(v => v !== null && v !== '')
          .join(', ')
      }

      return itemStr
    },
    formatData (row, header, format, isLink = false) {
      if (this.customFormatters && this.customFormatters.hasOwnProperty(header)) {
        return this.customFormatters[header](row, this)
      }
      else if (Array.isArray(row[header])) {
        return this.getTruncatedStringFromArray(row[header], header)
      }
      const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
      return helpers.format.formatData(value, format)
    },
    colorDerived (row, header)
    {
      if ((header === 'february' && row.derived_month <= 2)||
        (header === 'march' && row.derived_month <= 3)||
        (header === 'april' && row.derived_month <= 4)||
        (header === 'may' && row.derived_month <= 5)||
        (header === 'june' && row.derived_month <= 6)||
        (header === 'july' && row.derived_month <= 7)||
        (header === 'august' && row.derived_month <= 8)||
        (header === 'september' && row.derived_month <= 9)||
        (header === 'october' && row.derived_month <= 10)||
        (header === 'november' && row.derived_month <= 11)||
        (header === 'december' && row.derived_month <= 12)
      )
      {
        return '#fce4d6'
      }
      else return '#ffffff'
    },
    getUnitNames (contractId, siteId) {
      const siteData = this.data.items.find(item => item.contractId === contractId && item.id_site === siteId)
      // remove dublicates from site unit ids and unit names
      const siteUnitIds = [...new Set(siteData.unit_ids)]
      const siteUnitNames = [...new Set(siteData.unit_names)]
      const siteUnits = siteUnitNames.map((name, idx) => {
        return {
          unit_name: name,
          unit_id: siteUnitIds[idx]
        }
      })
      if (siteUnits.length > 4) {
        const siteUnitsWithDot = siteUnits.slice(0, 4)
        siteUnitsWithDot.push({ unit_name: '...' })
        return siteUnitsWithDot
      }

      return siteUnits
    },
    async routeTo (siteId, unitId) {
      let plan = await this.$rambollfmapi.sites.get(siteId)
      plan.has_dwg ?
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: siteId },
            query: { suite_id: unitId }
          }) :
           this.$router.push({
            name: 'sites.info',
            params: { id_site: siteId }
          })
    },
    escapeHtml (unitName) {
    return helpers.format.escapeHtml(unitName)
    },
    formatBuildingProject (bp) {
      this.buildingProjectId = bp.buildingproject_identifier
      this.buildingProject = {
        building_identifier: bp.building_identifier,
        building_code: bp.building_code,
        buildingproject_identifier: bp.buildingproject_identifier,
        buildingproject_number: bp.buildingproject_number,
        name: bp.name,
        purpose_of_use_with_denomination: bp.purpose_of_use_with_denomination,
        unit_area: bp.unit_area,
        owner: bp.landlord ? bp.landlord : bp.owner,
        ownership_relation: bp.buildingproject_ownership_relation,
        property_maintenance_name: bp.property_maintenance_name,
        property_maintenance_phone: bp.property_maintenance_phone,
        property_maintenance_email: bp.property_maintenance_email,
        situation_code: bp.situation_code,
        situation_description: bp.situation_description
      }
    },
    addCertificationDocument (item) {
      this.targetCertificationRow = item
      this.targetSite = this.sites.find(s => s.id_site === item.id_site)
      this.document = {
        title:'Breeam In-Use sertifiointitodistus: ' + item.site_name + ', ' + helpers.format.formatData(item.certification_date, 'Date'),
        description: item.site_name + ', sertifiointipäivämäärä: ' + helpers.format.formatData(item.certification_date, 'Date'),
        type: 'Breeam In-Use sertifiointitodistus',
        tags: ['sertifiointitodistus', item.certification_version]
      }
      this.uploadDialogVisible = true
    },
    closeUploadDialog () {
      this.uploadDialogVisible = false
      this.targetCertificationRow = null
      this.targetSite = null
      this.document = null
    },
    async documentUploaded (document) {
      this.uploadDialogVisible = false
      try {
        await this.updateCertificationDocumentId({ documentId: document.idDocument, siteId: this.targetSite.id_site, certificationDate: this.targetCertificationRow.certification_date })
      } catch (error) {
        // Remove the created doc if certification document id appending fails
        await this.$rambollfmapi.documents.delete(document.idDocument)
        alert(this.$t('Certification document appending failed'))
      }
        // Clear "selections"
        this.targetCertificationRow = null
        this.targetSite = null
        this.document = null
    },
    async downloadCertificateDocument (documentId) {
      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      const filename = await this.$rambollfmapi.documents.getFilename(documentId)
      this.$rambollfmapi.documents
        .download(documentId, progress)
        .then(res => {
          const blob = new Blob([res.data], { type: 'octet/stream' })
          helpers.saveAs(blob, filename)
          this.isRetrievingFile = false
        })
        .catch(() => {
          this.isRetrievingFile = false
        })
    },
    getCurrentDate () {
      return this.$store.state.app.currentDate
    },
    editDiaryEntry (item) {
      this.siteDiaryDialog = true
      this.itemsToEdit = item
    },
    async deleteDiaryEntry (item) {
      if(confirm(this.$t('site.diary.delete_confirmation')+ '\n'
       + this.$t('site.diary.notification_date') +': '+ this.getHumanReadableDate(item.notification_date) +'\n'
       + this.$t('site.diary.notifier') +': '+ item.notifier +'\n'
       + this.$t('site.diary.handling_date') +': '+ this.getHumanReadableDate(item.handling_date) +'\n'
       + this.$t('site.diary.author') +': '+ item.author +'\n'
       + this.$t('site.diary.case') +': '+ item.diary_case +'\n'
       + this.$t('site.diary.solution') +': '+ item.solution +'\n'
       + this.$t('site.diary.state') +': '+ item.state +'\n'
       + this.$t('site.diary.assignee') +': '+ item.assignee +'\n')) {
        await this.$rambollfmapi.diaries.delete(item, this.getCurrentDate())
        this.$emit('updateWidget')
      }
    },
    async deleteCertificate (item) {
      if(confirm(this.$t('esg.certificate.confirm_delete'))) {
        await this.$rambollfmapi.esg.certificates().delete(item.id)
        this.$emit('updateWidget')
      }
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    }
  }
}
</script>
<style scoped>
.icon_padding {
  padding-right: 0.5em;
}
.buttons-container {
  display: flex;
  padding-left: 15px;
  justify-content: center;
  overflow: auto;
}
.buttons-container2 {
  display: flex;
  justify-content: right;
}
.filterButton {
  margin: 0px;
  border-radius: 2em;
  width: 100%;
  justify-content: start;
}
.filterBox {
  margin: 1em 1.5em;
  display: flex;
  justify-content: center;
  opacity: 0.87;
}
</style>
