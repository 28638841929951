<template>
  <BaseModal
    :is-loading="dataLoading"
    @cancel="close"
  >
    <template #title>
      {{ isEdit ? deviceName : $t('device.add') }}
    </template>
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <ValidationObserver ref="observer">
          <div>
            <div
              v-if="isEdit"
            >
              <v-text-field
                v-model="currentSite.name"
                disabled
                :label="$t('Site')"
                class="form-field"
              />

              <v-autocomplete
                v-if="allBuildings.length > 0 && currentSite"
                v-model="selectedBuilding"
                :label="$t('Building')"
                :items="allBuildings"
                :placeholder="$t('Building')"
                item-text="building_name"
                hide-details
                return-object
                :disabled="disabled"
                class="location-selector form-field"
              />

              <v-autocomplete
                v-if="allFloors.length > 0 && selectedBuilding"
                v-model="floor"
                :label="$t('Floor')"
                :items="allFloors"
                :placeholder="$t('Floor')"
                item-text="floor_name"
                hide-details
                return-object
                :disabled="disabled"
                class="location-selector form-field"
                @change="() => onFloorChange(floor?.id)"
              />

              <validationProvider
                v-slot="{ errors }"
                rules="required"
              >
                <v-autocomplete
                  v-if="floor && floor.spaces"
                  v-model="selectedSpaceId"
                  :label="$t('Space')"
                  :items="floor.spaces"
                  :placeholder="$t('Space')"
                  item-text="name"
                  item-value="id"
                  hide-details
                  required
                  :rules="[v => !!v || $t('Required')]"
                  :hint="$t('Required')"
                  :disabled="disabled"
                  :error-messages="errors"
                  class="location-selector form-field"
                />
              </validationProvider>
            </div>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-text-field
                v-model="device.name"
                :label="$t('Name')"
                required
                :rules="[v => !!v || $t('Required')]"
                :hint="$t('Required')"
                :disabled="disabled"
                class="form-field"
                :error-messages="errors"
              />
            </ValidationProvider>

            <v-autocomplete
              v-model="device.def_type"
              :items="deviceTypeDefs"
              item-text="label"
              item-value="id"
              :label="$t('Type')"
              :disabled="disabled"
              class="form-field"
            />
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-text-field
                v-model="device.count"
                :label="$t('Count')"
                :disabled="disabled"
                class="form-field"
                type="number"
                :error-messages="errors"
                :rules="countRules"
              />
            </ValidationProvider>

            <v-autocomplete
              v-model="device.def_responsibility"
              :items="deviceResponsibilityDefs"
              item-text="label"
              item-value="id"
              :label="$t('Responsibility')"
              :disabled="disabled"
              class="form-field"
            />
            <div
              class="form-field"
            >
              <span class="ml-2">
                <strong>{{ $t ('Connections') }}</strong>
              </span>
              <v-row
                v-for="(connection, index) in device.connections"
                :key="index"
                align-items="center"
                no-gutters
              >
                <v-autocomplete
                  v-model="connection.def_connection"
                  :items="deviceConnectionDefs"
                  item-text="label"
                  item-value="id"
                  :label="$t('Connection') + ' ' + (index + 1)"
                  :disabled="disabled"
                  class="my-2 mr-2"
                />

                <cds-button
                  :aria-label="$t('connection.delete')"
                  type="danger"
                  icon-left="delete"
                  :hide-text="true"
                  :disabled="disabled"
                  @click="device.connections.splice(index, 1)"
                />
              </v-row>
              <v-row
                class="py-2 px-1 mt-0"
                justify="center"
              >
                <cds-button
                  :aria-label="$t('connection.add')"
                  type="primary"
                  icon-left="add"
                  :hide-text="true"
                  :disabled="disabled"
                  @click="device.connections.push({ })"
                />
              </v-row>
            </div>
            <v-textarea
              v-model="device.additional_info"
              :label="$t('Additional info')"
              :disabled="disabled"
              class="form-field"
            />
          </div>
        </ValidationObserver>
      </v-form>
    </template>
    <template #footer>
      <cds-button
        :disabled="disabled || dataLoading === true || !valid"
        :aria-label="isEdit ? $t('Save') : $t('Add')"
        type="primary"
        @click="validateForm"
      >
        {{ isEdit ? $t('Save') : $t('Add') }}
      </cds-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import lodash from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CdsButton from '../CircleDesignSystem/CdsButton.vue'


export default {
  emits: ['close'],
  name: 'AddDeviceForm',
  components: {
    BaseModal,
    ValidationObserver,
    ValidationProvider,
    CdsButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    selectedSpace: {
      type: Object,
      default: null
    },
    selectedFloor: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      selectedSite: null,
      device: {
        id_space: null,
        name: null,
        count: 1,
        connections: [],
        def_type: null,
        def_responsibility: null,
        additional_info: null,
      },
      dataLoading: false,
      isEdit: false,
      deviceTypeDefs: [],
      deviceResponsibilityDefs: [],
      deviceConnectionDefs: [],
      allBuildings: [],
      selectedBuilding: null,
      allFloors: [],
      floor: null,
      selectedSpaceId: null,
      deviceName: null,
      valid: true,
      countRules: [
        v => !!v || this.$t('Required'),
        v => Number.isInteger(Number(v)) || this.$t('Number must be an integer')
      ]
    }
  },
  computed: {
    ...mapState('device', ['deviceToModify']),
    ...mapGetters('sites', ['currentSite', 'buildingsForCurrentSite']),
    ...mapGetters('app', ['definitionsByGroupLabel']),

  },
  watch: {
    deviceToModify: {
      handler () {
        this.initialize()
      },
      immediate: true
    },
    selectedSpace: {
      handler () {
        this.initialize()
      },
      immediate: true
    },
    selectedBuilding (newBuilding) {
      this.allFloors = []

      if (this.isEdit) {
        this.floor = null
      }

      if (newBuilding) {
        this.fetchFloors(newBuilding.building_code)
      }
    },
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions('device', [
      'addDevice',
      'patchDevice',
      'endDevice',
      'updateDeviceConnections'
    ]),
    initialize () {
      this.dataLoading = true
      this.device.count = 1
      if (this.deviceToModify) {
        this.isEdit = true
        this.device = lodash.cloneDeep(this.deviceToModify)
        this.deviceName = this.device.name
        this.setSiteInfo()
      }
      this.deviceTypeDefs = this.getDefinitions('device.type')
      this.deviceResponsibilityDefs = this.getDefinitions('device.responsibility')
      this.deviceConnectionDefs = this.getDefinitions('device.connection')
      this.selectedSpaceId = this.selectedSpace.id
      this.dataLoading = false
    },
    setSiteInfo () {
      this.selectedBuilding = null
      this.allBuildings = this.buildingsForCurrentSite

      this.selectedBuilding = this.allBuildings.find(building =>
        building.building_code === this.selectedFloor.building_code
      )
    },
    async onFloorChange (floorId) {
      this.dataLoading = true
      if (!floorId) {
        return
      }

      this.floor = this.allFloors.find(floor =>
        floor.id === this.selectedFloor.id
      )

      this.floor.spaces = await this.$rambollfmapi.floors.spaces(floorId, this.currentDate).list()
      this.dataLoading = false
    },

    async fetchFloors (building_code) {
      this.dataLoading = true
      if (!building_code) {
        return
      }

      await this.$rambollfmapi.buildings
        .floors(building_code)
        .list(this.currentDate)
        .then(async (res) => {
          this.allFloors = res
          await this.onFloorChange(this.selectedFloor.id)
        })
      this.dataLoading = false
    },

    getDefinitions (group) {
      return this.definitionsByGroupLabel(group)
        .map(status => {
          return {
            label: this.$t(status.label),
            id: status.id
          }
        })
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1
        })
    },
    async validateForm () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.add()
      }
    },
    async add () {
      this.dataLoading = true
      this.device.connections = this.device.connections.filter(connection => connection.def_connection)
      this.device.id_space = this.selectedSpaceId

      if (this.isEdit) {

        if (this.deviceToModify.id_space !== this.device.id_space) {
          // If space is changed, we need to end the current device and create a new one
          this.device.start_date = this.currentDate
          await this.addDevice(this.device)

          await this.endDevice(this.deviceToModify.id_device)

        } else {
          let connectionsChanged = false
          // If space is not changed, we can update the device
          const deviceCopy = JSON.parse(JSON.stringify(this.deviceToModify))
          const deviceObserver = this.$jsonpatch.observe(deviceCopy)
          let connections = []
          Object.keys(deviceCopy).forEach(key => {
            if (this.device[key] !== deviceCopy[key]) {
              // If connections are changed, we need to update them separately
              if (key === 'connections') {
                connectionsChanged = true
                connections = this.device[key]
              } else {
                deviceCopy[key] = this.device[key]
              }
            }
          })
          const devicePatch = this.$jsonpatch.generate(deviceObserver)

          if (devicePatch.length > 0) {
            await this.patchDevice({ deviceId: this.deviceToModify.id_device, devicePatch, updatedDevice: this.device })
          }
          if (connectionsChanged) {
            await this.updateDeviceConnections({ deviceId: this.deviceToModify.id_device, connections })
          }
        }
      } else {
        this.addDevice(this.device)
      }
      this.close()
    },
    close () {
      this.resetForm()
      this.$emit('close')
    },
    resetForm () {
      this.device = {
        id_space: null,
        name: null,
        count: 1,
        connections: [],
        def_type: null,
        def_responsibility: null,
        additional_info: null,
      }
      this.$refs.form.resetValidation()
      this.$refs.observer.reset()
      this.selectedBuilding = null
      this.allFloors = []
      this.floor = null
      this.selectedSpaceId = null
      this.isEdit = false
      this.dataLoading = false
    },
  },
}
</script>