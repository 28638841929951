<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid }"
    >
      <AriaLive />
      <BaseModal
        @cancel="close()"
      >
        <template #title>
          {{ title }}
        </template>
        <template #content>
          <p>{{ $t('Choose contract which invoices you wan to refund') }}</p>
          <!-- IF CHECKBOX NOT SELECTED -->
          <div>
            <v-row>
              <v-col cols="8">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="contractId"
                    :items="contracts"
                    :label="$t('Contract')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    :disabled="!newInvoice"
                    item-text="contractNumber"
                    item-value="id"
                    class="form-fields mt-2"
                    required
                    @change="onContractChange"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="manualRefundInvoice"
                  :label="$t('Manual refundinvoice')"
                  :disabled="!newInvoice"
                  class="form-fields mt-2"
                  @change="SetupAutomaticLegend"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-menu
                  :close-on-content-click="false"
                  class="mt-4"
                  offset-y
                  min-width="0"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="formatDate(startDate)"
                      :label="$t('new_handinvoice.contract_startdate')"
                      class="form-fields mt-2"
                      readonly
                      v-on="on"
                    />
                  </template>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  :close-on-content-click="false"
                  class="mt-4"
                  offset-y
                  min-width="0"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="formatDate(endDate)"
                      :label="$t('new_handinvoice.contract_enddate')"
                      class="form-fields mt-2"
                      readonly
                      v-on="on"
                    />
                  </template>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="tenant"
                  :label="$t('Tenant')"
                  text="tenant"
                  class="form-fields mt-2"
                  readonly
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="landlord"
                  :label="$t('Landlord')"
                  class="form-fields mt-2"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedUnits"
                  :items="filteredUnits"
                  :label="$t('Units')"
                  item-value="id"
                  class="form-fields mt-2 height-auto"
                  item-text="name"
                  readonly
                  multiple
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedStructures"
                  :items="filteredStructures"
                  :label="$t('Structures')"
                  item-text="name"
                  item-value="id"
                  class="form-fields mt-2 height-auto"
                  multiple
                  readonly
                />
              </v-col>
            </v-row>
            
            <v-row v-if="manualRefundInvoice === true">
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-autocomplete
                    v-model="selectedPaymentDomain"
                    :items="rentPaymentDomains"
                    :label="$t('create_rent_contract.payment_domain')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    :return-object="true"
                    item-text="fullName"
                    class="form-fields mt-2 mb-4"
                    required
                    @change="onPaymentDomainChange"
                  >
                    <template #selection="{ item, index }">
                      {{ item.name ? $t(`${item.name}`): "" }} {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }}
                    </template>
                    <template #item="{item, index}">
                      {{ $t(`${item.name}`) }} {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }}
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </div>
          <div v-if="manualRefundInvoice === true">
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-text-field
                    v-model="fullContractRefundedGross"
                    :label="$t('refund_invoice.gross_refund')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    class="form-fields mt-2"
                    type="number"
                    @change="onFullContractRefundedGrossChange()"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required`"
                >
                  <v-text-field
                    v-model="fullContractRefundedNet"
                    :label="$t('refund_invoice.net_refund')"
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    required
                    class="form-fields mt-2"
                    type="number"
                    @change="onFullContractRefundedNetChange()"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </div>
          
          <!-- IF CHECKBOX SELECTED -->
          
          <v-row class="form-fields mt-2">
            <v-col cols="12">
              <v-expansion-panels
                v-if="manualRefundInvoice === false"
                v-model="panel"
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('refund_invoice.invoices') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      v-model="invoiceSelected"
                      :headers="headersParent"
                      :items="sortedInvoices"
                      :items-per-page="-1"
                      :expanded.sync="invoiceExpanded"
                      item-key="id"
                      show-expand
                      hide-default-header
                      hide-default-footer
                      class="v-data-table--parent"
                      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                    >
                      <template #[`item.category`]="{ item }">
                        <span class="text-subtitle-2 blue--text text--darken-3">{{ item.category }}</span>
                      </template>
              
                      <template
                        v-if="!isExistingInvoice"
                        #[`header.data-table-select`]="{ on, props }"
                      > 
                        <v-simple-checkbox
                          v-bind="props" 
                          color="blue"
                          v-on="on"
                        />
                      </template>
                      <template 
                        v-if="!isExistingInvoice"
                        #[`item.data-table-select`]="{ isSelected, select }"
                      >
                        <v-simple-checkbox
                          :value="isSelected"
                          color="blue"
                          @input="select($event)"
                        />
                      </template>
                      <template #[`item.month`]="{ item }">
                        <span class="text-subtitle-2 text--darken-3">{{ item.month }}</span>
                      </template>
  
                      <template #expanded-item="{ headers, item }">
                        <td
                          :colspan="headers.length"
                        >
                          <v-data-table
                            v-model="paymentsSelected"
                            :headers="invoiceHeaders"
                            :items="item.payments"
                            :items-per-page="-1"
                            :show-select="newInvoice"
                            item-key="id"
                            hide-default-footer
                            class="v-data-table--child"
                          >
                            <template
                              v-if="!isExistingInvoice" 
                              #[`header.data-table-select`]="{ on, props }"
                            >
                              <v-simple-checkbox
                                v-bind="props" 
                                color="blue"
                                v-on="on"
                              />
                            </template>
  
                            <template 
                              v-if="!isExistingInvoice"
                              #[`item.data-table-select`]="{ isSelected, select }"
                            >
                              <v-simple-checkbox
                                :value="isSelected"
                                color="blue"
                                @input="select($event)"
                              />
                            </template>
                            <template #item.grossRefund="props">
                              <v-text-field
                                v-if="isSelected(props.item.id)"
                                v-model.number="props.item.grossRefund"
                                :min="0"
                                :max="props.item.grossSum"
                                type="number"
                                dense
                                class="borderless"
                                append-icon="edit"
                                @change="onGrossRefundChange(props.item.grossRefund, props.item, item.id)"
                              />
                            </template>
                            <template #item.netRefund="props">
                              <v-text-field
                                v-if="isSelected(props.item.id)"
                                v-model.number="props.item.netRefund"
                                :min="0"
                                :max="props.item.netSum"
                                type="number"
                                dense
                                class="borderless"
                                append-icon="edit"
                                @change="onNetRefundChange(props.item.netRefund, props.item, item.id)"
                              />
                            </template>
                          </v-data-table>
                        </td>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <CdsAlert
                v-if="!manualRefundInvoice &&
                  paymentsSelected.length < 1"
                size="small"
                :description="$t('OneInvoiceRequired')"
              />
            </v-col>
          </v-row>   
                
          <div v-if="manualRefundInvoice === false">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="totalRefundedGross"
                  :label="$t('total_refund_invoice.gross_refund')"
                  class="form-fields mt-2"
                  type="number"
                  readonly
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="totalRefundedNet"
                  :label="$t('total_refund_invoice.net_refund')"
                  class="form-fields mt-2"
                  type="number"
                  readonly
                />
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="description"
                :label="$t('Invoice description')"
                class="form-fields mt-2"
                @change="onDataChanged()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="autofill"
                :label="$t('Automatic description')"
                class="form-fields mt-2"
                readonly
                @change="onDataChanged()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <DatePicker
                v-if="manualRefundInvoice"
                :label="$t('BillingMonth')"
                :value="formatDate(billingDate)"
                :hint="$t('Required')"
                :default-hint="false"
                :rules="!manualRefundInvoice ? '' : 'required'"
                required
                :display-month-only="true"
                :initial-date="billingDate"
                :allowed-dates="allowedBillingDates"
                :disabled="!manualRefundInvoice"
                :calendar-type="'month'"
                @dateupdated="onBillingDateChange"
              />
            </v-col>
          </v-row>
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <v-col
            cols="4"
            class="text-right align-center"
          >
            <small v-if="invalid || (!manualRefundInvoice && paymentsSelected.length < 1)"> {{ $t('FillRequiredFields') }}</small>
            <v-btn
              :disabled="invalid || (!manualRefundInvoice && paymentsSelected.length < 1) || creatingInvoice"
              rounded
              depressed
              color="accent"
              @click="CreateRefundInvoice"
            >
              {{ $t('Accept invoice') }}
            </v-btn>
          </v-col>
        </template>
      </BaseModal>
    </ValidationObserver>
  </div>
</template>
    
    <script>
    import { mapState, mapGetters } from 'vuex'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import moment from 'moment'
    import BaseModal from '../general/BaseModal.vue'
    import AriaLive from '../AriaLive.vue'
    import CdsAlert from '../CircleDesignSystem/CdsAlert.vue'
    import DatePicker from '../general/DatePicker.vue'
    
    export default {
  emits: ['close', 'created'],
      name: "NewRefundInvoice",
      components: {
        BaseModal,
        AriaLive,
        ValidationProvider,
        ValidationObserver,
        CdsAlert,
        DatePicker
      },
      props: {
        invoiceMaterial: {
          type: Object,
          default: function () {
            undefined
          }
        },
        isExistingInvoice : {
          type: Boolean,
          default: false
        }
      },
      data () {
        return {
          panel: [0],
          contracts: [],
          allParties: [],
          selectedSite: undefined,
          selectedBuilding: undefined,
          selectedFloor: undefined,
          selectedUnits: [],
          selectedStructures: [],
          filteredBuildings: undefined,
          filteredFloors: undefined,
          filteredUnits: [],
          filteredStructures: [],
          manualRefundInvoice: false,
          contractId: undefined,
          startDate: undefined,
          endDate: undefined,
          tenantId: undefined,
          tenant: undefined,
          landlordId: undefined,
          landlord: undefined,
          employeeList: [],
          billingDate: undefined,
          description: undefined,
          invoiceCreator: undefined,
          contractNumber: undefined,
          autofill: undefined,
          invoiceSelected: [],
          paymentsSelected: [],
          invoiceExpanded: [],
          invoicesForSelectedContract: undefined,
          invoiceRowsForSelectedContract: undefined,
          fullContractRefundedGross: undefined,
          fullContractRefundedNet: undefined,
          totalRefundedGross: undefined,
          totalRefundedNet: undefined,
          invoiceDueDate: undefined,
          headersParent: [      
            { text: this.$t('BillingMonth'), align: 'left', value: 'month', width: '90%', },
            { text: '', value: 'data-table-expand', width: '25px', class: 'expand', cellClass: 'expand', sortable: false },
          ], 
          invoiceHeaders: [
            {
              text: this.$t('BillingMonth'),
              align: 'start',
              sortable: false,
              value: 'month',
              width: '10%'
            },
            { text: this.$t('refund_invoice.payment_domain'), value: 'paymentDomain', width: '10%', sortable: false },
            { text: this.$t('refund_invoice.legend'), value: 'legend', width: '10%', sortable: false },
            { text: this.$t('refund_invoice.gross_sum'), value: 'grossSum', width: '10%', sortable: false },
            { text: this.$t('refund_invoice.net_sum'), value: 'netSum', width: '10%', sortable: false },
            { text: this.$t('refund_invoice.gross_refund'), value: 'grossRefund', width: '15%', sortable: false },
            { text: this.$t('refund_invoice.net_refund'), value: 'netRefund', width: '15%', sortable: false },
            { text: this.$t('refund_invoice.net_left_refunded'), value: 'netAvailableForRefund', width: '15%', sortable: false },
            { text: '', value: 'data-table-expand', width: '42px', sortable: false },
          ],
          invoices: [],
          rentPaymentDomains: [],
          selectedPaymentDomain: undefined,
          creatingInvoice: false
        }
      },
      computed: {
        ...mapState('app', ['sites', 'userInfo','currentDate']),
        ...mapGetters('app', ['definitionsByGroupLabel', 'definitionByLabel']),
    
        billingAddressTypes () {
          return this.definitionsByGroupLabel('rent_contracts.billing_address_type')
        },
        title () {
          if (this.isExistingInvoice) 
            return this.$t('Modify refund invoice') 
          else 
            return this.$t('Create refund invoice')
        },
        newInvoice () {
          return !this.isExistingInvoice
        },
        sortedInvoices () {
          return this.invoices.slice().sort((a, b) => {
            const aMonthName = a.month.split(",")[0].trim();
            const bMonthName = b.month.split(",")[0].trim();
            
            const aMonth = this.getMonthNumber(aMonthName);
            const bMonth = this.getMonthNumber(bMonthName);

            const aYear = parseInt(a.month.split(",")[1].trim());
            const bYear = parseInt(b.month.split(",")[1].trim());

            if (aYear !== bYear) {
              return aYear - bYear;
            } else if (aMonth !== bMonth) {
              return aMonth - bMonth;
            } else {
              const aInvoiceNumber = parseInt(a.invoiceNumber);
              const bInvoiceNumber = parseInt(b.invoiceNumber);
              
              if (aInvoiceNumber < bInvoiceNumber) {
                return -1;
              } else if (aInvoiceNumber > bInvoiceNumber) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        },
      },
      watch: {
        selectedSite () {
          this.getBuildingsBySiteId()
        },
        selectedBuilding () {
          this.getFloorsByBuildingId()
        },
        selectedFloor () {
          this.getUnitsByFloorId()
        },
        invoiceSelected (month) {
          this.SetupAutomaticLegend()
          this.paymentsSelected = []
          if (month.length > 0) {
            month.forEach(main => {
              main.subInvoices.forEach(sub => {
                this.paymentsSelected.push(sub)
              })
            })
          }
        },
        paymentsSelected () {
          this.SetupAutomaticLegend()
          this.setupTotalRefundAmount()
        },
        invoiceMaterial () {
          this.initializeInvoice()
        }
      },
      async mounted () {
        this.contracts = await this.$rambollfmapi.contracts.contract.getAllContractIds(true, true, true)
    
        this.allParties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })
        this.employeeList = this.allParties.filter(party => party.type == 1)
        this.rentPaymentDomains = await this.getAllRentPaymentDomains()
        this.address = this.address ?? this.definitionByLabel('BillingAddressTypeTenanAddress')?.id

        this.initializeInvoice()
      },
      methods: {
        isSelected (id) {
          return this.paymentsSelected.some(pay => pay.id === id)
        },
        getMonthNumber (monthName) {
          const monthNames = [
            this.$t('January'), this.$t('February'), this.$t('March'), this.$t('April'),
            this.$t('May'), this.$t('June'), this.$t('July'), this.$t('August'),
            this.$t('September'), this.$t('October'), this.$t('November'), this.$t('December')
          ];
          return monthNames.indexOf(monthName);
        },
        onRefundChange (val, payment, id) {
        },
        formatDate (date) {
          const formatted = moment(date, "YYYY-MM-DD")
          return formatted.isValid() ? formatted.format("MM/YYYY") : undefined
        },
        allowedBillingDates (date) {
          const selectedDate = new Date(date)
          selectedDate.setHours(0, 0, 0, 0);
          const currentDate = new Date()
          currentDate.setHours(0, 0, 0, 0);

          //min date = current month - 1
          const minDate = new Date(currentDate)
          minDate.setDate(0)
          minDate.setDate(1)
          //max date = current month + 10
          const maxDate = new Date(currentDate)
          maxDate.setDate(1)
          maxDate.setMonth(currentDate.getMonth() + 10)

          return (selectedDate >= minDate && selectedDate <= maxDate)
        },
        setupTotalRefundAmount () {
          this.totalRefundedNet = 0
          this.totalRefundedGross = 0
          for (var selectedPayment = 0; selectedPayment < this.paymentsSelected.length; selectedPayment++) {
            this.totalRefundedNet += Number(this.paymentsSelected[selectedPayment].netRefund)
            this.totalRefundedGross += Number(this.paymentsSelected[selectedPayment].grossRefund)
          }
          
          this.totalRefundedNet = Number(this.totalRefundedNet.toFixed(2))
          this.totalRefundedGross = Number(this.totalRefundedGross.toFixed(2))
  
          if (this.totalRefundedNet ==0) {
            this.totalRefundedNet = undefined
          }
          if (this.totalRefundedGross ==0) {
            this.totalRefundedGross = undefined
          }
        },
        onDataChanged () {
        },
        onBillingDateChange (value) {
          this.billingDate = value ?? ""
        },
        getMonthName (monthNumber) {         
          var date = new Date();       
          // lets add first day of the month, because for example if datetime is '2023-31-08' and monthNumber is september,
          // semptember does not have 31 days, it will jump to october.     
          date.setDate(1);                         
          date.setMonth(monthNumber);         
          return date.toLocaleString('en-US', { month: 'long' });
        },
        resetModal () {
          this.contractId = undefined
          this.manualRefundInvoice = false
          this.tenant = undefined
          this.landlord = undefined
          this.selectedUnits = []
          this.selectedStructures = []
          this.fullContractRefundedGross = undefined
          this.fullContractRefundedNet = undefined
          this.invoiceSelected = []
          this.paymentsSelected = []
          this.totalRefundedGross = undefined
          this.totalRefundedNet = undefined
          this.description = undefined
          this.autofill = undefined
          this.billingDate = undefined
          this.invoices = []
          this.startDate = undefined
          this.endDate = undefined
          this.selectedPaymentDomain = undefined
          this.creatingInvoice = false
        },
        onNetRefundChange (val, payment, id) {
          if (val<0) {
            payment.netRefund = 0
          }
          if (val > payment.netAvailableForRefund) {
            payment.netRefund = payment.netAvailableForRefund
          }
         
          payment.netRefund = Number(payment.netRefund.toFixed(2))
  
          payment.grossRefund = ((100+payment.rentPaymentDomain.vatPercent)/100) *payment.netRefund
          payment.grossRefund = Number(payment.grossRefund.toFixed(2))
          this.setupTotalRefundAmount()
        },
        onGrossRefundChange (val, payment, id) {
          if (val<0) {
            payment.grossRefund = 0
          }
          if (val > payment.grossSum) {
            payment.grossRefund = payment.grossSum
          }
          payment.grossRefund = Number(payment.grossRefund.toFixed(2))
  
          payment.netRefund = payment.grossRefund / ((100+payment.rentPaymentDomain.vatPercent)/100)
          payment.netRefund = Number(payment.netRefund.toFixed(2))
          
          if (payment.netRefund > payment.netAvailableForRefund) {
            payment.netRefund = payment.netAvailableForRefund
            this.onNetRefundChange(payment.netRefund,payment)
          }
  
          this.setupTotalRefundAmount()
        },
        async getBuildingsBySiteId () {
          this.filteredBuildings = await this.$rambollfmapi.buildings.list({ query: { siteId: this.selectedSite } })
        },
        async onPaymentDomainChange () {
          await this.calculateSums()
        },
        async calculateSums () {
          
          if (this.selectedPaymentDomain === undefined || this.fullContractRefundedGross === undefined || this.fullContractRefundedNet === undefined) {
            return
          }

          let vatpercent = await this.getVatPercent();

          this.fullContractRefundedGross = parseFloat(this.fullContractRefundedGross);
          this.fullContractRefundedGross = Number(this.fullContractRefundedGross.toFixed(2))
          this.fullContractRefundedNet = this.fullContractRefundedGross / ((100+vatpercent)/100)
          this.fullContractRefundedNet = Number(this.fullContractRefundedNet.toFixed(2))
        },
        async getFloorsByBuildingId () {
          this.filteredFloors = await this.$rambollfmapi.buildings.floors(this.selectedBuilding).list()
        },
        async getUnitsByFloorId () {
          this.filteredUnits = await this.$rambollfmapi.floors.units(this.selectedFloor).list()
        },
        async getAllRentPaymentDomains () {
          const result = await this.$rambollfmapi.contracts.contract.getRentPaymentDomains()
          return result.map(r => ({...r, fullName: `${r.name} ${this.$t('ALV')} ${r.vatPercent} %`}))
        },
        async onFullContractRefundedGrossChange () {
          let vatpercent = await this.getVatPercent();

          this.fullContractRefundedGross = parseFloat(this.fullContractRefundedGross);
          this.fullContractRefundedGross = Number(this.fullContractRefundedGross.toFixed(2))
          this.fullContractRefundedNet = this.fullContractRefundedGross / ((100+vatpercent)/100)
          this.fullContractRefundedNet = Number(this.fullContractRefundedNet.toFixed(2))
        },
        async onFullContractRefundedNetChange () {
          let vatpercent = await this.getVatPercent();

          this.fullContractRefundedNet = parseFloat(this.fullContractRefundedNet);
          this.fullContractRefundedNet = Number(this.fullContractRefundedNet.toFixed(2))
          this.fullContractRefundedGross = ((100+vatpercent)/100) *this.fullContractRefundedNet
          this.fullContractRefundedGross = Number(this.fullContractRefundedGross.toFixed(2))

        },
        async getVatPercent () {
          if (this.selectedPaymentDomain === undefined) {
            return;
          }
          
          let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === this.selectedPaymentDomain.id);
          let vatpercent;
          if (paymentDomain?.[0]) {
            vatpercent = paymentDomain[0].vatPercent;
          }

          return vatpercent;
        },
        async onContractChange () {
          if (!this.contractId) return this.invoices = []
          const contract = await this.$rambollfmapi.contracts.contract.getWithRows({ contractId: this.contractId})
          this.paymentsSelected = []
          this.selectedUnits = []
          this.filteredUnits = []
  
          if (contract.rows.length > 0) {
            contract.rows.forEach(r => {
              if (r.unitId) {
                this.selectedUnits.push(r.unitId)
                this.filteredUnits.push({
                  name: r.unitName,
                  id: r.unitId
                })
              } else {
                this.selectedStructures.push(r.structureId)
                this.filteredStructures.push({
                  name: r.structureName,
                  id: r.structureId
                })
              }
            })
          } else {
            this.selectedUnits = []
            this.filteredUnits = []
            this.selectedStructures = []
            this.filteredStructures = []
          }
          const creditInvoicesForContract = await this.$rambollfmapi.invoices.getCreditInvoicesForContract(this.contractId, this.includeBlocked = true)
          const InvoicesForContract = await this.$rambollfmapi.invoices.getInvoicesForContract(this.contractId, this.includeBlocked = true)
          const filteredInvoices = InvoicesForContract.map(invoice => ({
            ...invoice,
            invoiceRows: invoice.invoiceRows.filter(row => !row.isDiscountPayment)
          })).filter(invoice => 
            invoice.invoiceType !== "CreditInvoice" && invoice.invoiceRows.length > 0
          );    
  
          const invoicesWithPayments = []
          let sumOfNetRows = 0
          let sumOfGrossRows = 0
  
          for (var invoice = 0; invoice < filteredInvoices.length; invoice++) {
            
            const payments = [];
  
            for (let row = 0; row < filteredInvoices[invoice].invoiceRows.length; row++) {
              payments.push(filteredInvoices[invoice].invoiceRows[row])
              sumOfNetRows += filteredInvoices[invoice].invoiceRows[row].netSum
              sumOfGrossRows += filteredInvoices[invoice].invoiceRows[row].grossSum
            }
  

            const invoicePayments = payments.map(payment => {
              var netAvailableForRefund = payment.netSum
              var matchingInvoices = creditInvoicesForContract.filter(inv => inv.originalInvoiceId === filteredInvoices[invoice].id);
              if (this.invoiceMaterial && this.invoiceMaterial.id !== undefined && this.invoiceMaterial.id !== null) {
                matchingInvoices = matchingInvoices.filter(inv => inv.id !== this.invoiceMaterial.id);
              }
              if (matchingInvoices.length>0) {
                
                const totalNetSum = matchingInvoices.reduce((sum, invoice) => {
                  const matchingInvoiceRows = invoice.invoiceRows.filter(row => row.originalInvoiceRowId === payment.id);
                  const matchingNetSum = matchingInvoiceRows.reduce((rowSum, row) => rowSum + row.netSum, 0);
                  return sum + matchingNetSum;
                }, 0);

                const matchingInvoiceRow = filteredInvoices[invoice].invoiceRows.find(row => row.id === payment.id);

                if (matchingInvoiceRow) {
                  const updatedNetSum = matchingInvoiceRow.netSum + totalNetSum;
                  netAvailableForRefund = updatedNetSum;
                }                
                
                if (netAvailableForRefund<0) {
                  netAvailableForRefund =0
                }
              }
             
              var grossRefund = ((100+payment.rentPaymentDomain.vatPercent)/100) *netAvailableForRefund
              grossRefund = Number(grossRefund.toFixed(2))
              netAvailableForRefund = Number(netAvailableForRefund.toFixed(2))
              
              return {
                ...payment,
                InvoiceId: filteredInvoices[invoice].id,
                id: payment.id,
                legend: payment.legend,
                paymentDomain: payment.rentPaymentDomain.name + ' ' + payment.rentPaymentDomain.vatPercent + " %",
                netSum: payment.netSum, 
                grossSum: payment.grossSum,
                netRefund: netAvailableForRefund,
                netAvailableForRefund: netAvailableForRefund,
                grossRefund: grossRefund,
                month: `${this.$t(this.getMonthName(new Date(filteredInvoices[invoice].billingDate).getMonth()))}`,
                billingDate: this.formatDate(filteredInvoices[invoice].billingDate)
              };
            });
            
            // // Create data for the invoice table
            invoicesWithPayments.push({
              ...filteredInvoices[invoice],
              payments: invoicePayments
            });
          }

          const invoiceTypes = {};
          var invoiceType = undefined
          for (var invoice = 0; invoice < filteredInvoices.length; invoice++) {
            const idInvoice = filteredInvoices[invoice].id;
            invoiceType = filteredInvoices[invoice].invoiceType
            if (invoiceType == "Normal") {
              invoiceType = this.$t('contract_payment')
            }
            else if (invoiceType == "HandInvoice") {
              invoiceType = this.$t('invoice.HandInvoice')
            }
            if (!invoiceTypes.hasOwnProperty(idInvoice)) {
              invoiceTypes[idInvoice] = invoiceType;
            }
          }
          // Update invoices, Replace invoice month number with month name
          this.invoices = invoicesWithPayments.map(invoice => {
            const invoiceType = invoiceTypes[invoice.id];
            const invoiceNumber = invoice.invoiceNumber

            const date = new Date(invoice.stateTimestamp);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            
            const formattedDate = `${this.$t('invoice.Sent')}: ${day}.${month}.${year} klo ${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;
            
            return {
              ...invoice,
              netSum: sumOfNetRows,
              grossSum: sumOfGrossRows,
              month: `${this.$t(this.getMonthName(new Date(invoice.billingDate).getMonth()))}, ${new Date(invoice.billingDate).getFullYear()}: ${invoiceType} ${invoiceNumber}, ${formattedDate}`
            };
          });


          this.contractNumber = contract.contract.contractNumber
    
          this.startDate = contract.contract.startDate
          this.endDate = contract.contract.endDate
          this.tenantId = contract.contract.tenantId
          this.landlordId = contract.contract.landlordId
    
          this.tenant = this.getPersonById(this.tenantId)
          this.landlord = this.getPersonById(this.landlordId)
  
          this.SetupAutomaticLegend()
        },
        SetupAutomaticLegend () {
          if (this.manualRefundInvoice == true) {
            if (this.contractId != null) {
              this.autofill = "Hyvitetty sopimusta " + this.contractNumber
            }
          }else {
            
            if (this.contractId != null) {
  
              let Payments = new Set();

              for (var payment = 0; payment < this.paymentsSelected.length; payment++) {
                Payments.add(this.paymentsSelected[payment].InvoiceId)
                this.invoiceDueDate = this.paymentsSelected[payment].billingDate
                this.billingDate = this.paymentsSelected[payment].billingDate
              }
              
              if (Payments.size>1) {
                this.autofill = "Hyvitetty laskuja nro " + Array.from(Payments, x => x).join(', ');
              }
              else if (Payments.size==1) {
                this.autofill = Array.from(Payments, x => `Hyvitetty laskua nro ${x}`).join(', ');
                this.autofill += ", laskutuskuukausi " + this.invoiceDueDate
              }else {
                this.autofill = ''
              }
              Payments.clear()
            }
          }  
        },
        getPersonById (id) {
          return this.allParties.find(p => p.id === id).name
        },
        close () {
          if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
            this.resetModal()
            this.$emit('close')
          }
        },
        async CreateRefundInvoice () {
          if (this.contractId == undefined) return
          this.creatingInvoice = true
          
          this.SetupAutomaticLegend()
          
          const ListOfRefundInvoices = []
          
          if (this.manualRefundInvoice) {
            const invoiceRowMaterialDTO = {
              NetSum: Number(this.fullContractRefundedNet),
              GrossSum: Number(this.fullContractRefundedGross),
              RentPaymentDomain: this.invoices[0].payments[0]?.rentPaymentDomain
            };
            const invoiceRows = [invoiceRowMaterialDTO]; 
            const invoiceMaterialDTO = {
                          
              manualRefundInvoice: this.manualRefundInvoice,
              startDate: this.startDate,
              endDate: this.endDate,
              tenantId: this.tenantId,
              landlordId: this.landlordId,
              billingDate: this.billingDate,
              payerRference: this.payerReference,
              invoiceCreator: undefined,    
              IdContract: this.contractId,
              ReferenceNumber: undefined,
              legend: this.description,
              AutomaticLegend: this.autofill,
              InvoiceRows: invoiceRows,
              unitIds: this.selectedUnits,
              structureIds: this.selectedStructures,
              State: 3,
              Id: this.isExistingInvoice ? this.invoiceMaterial.id : 0,
              RentPaymentDomain: this.selectedPaymentDomain
            };
            ListOfRefundInvoices.push(invoiceMaterialDTO)
            await this.$rambollfmapi.invoices.createRefundInvoice(ListOfRefundInvoices)
          }
          else {
            for (var invoice = 0; invoice < this.invoices.length; invoice++) {
              const invoiceMaterialDTO = {
                selectedSite: this.selectedSite,
                selectedBuilding: this.selectedBuilding,
                selectedFloor: this.selectedFloor,
                unitIds: this.selectedUnits,
                structureIds: this.selectedStructures,
                manualRefundInvoice: this.manualRefundInvoice,
                startDate: this.startDate,
                endDate: this.endDate,
                tenantId: this.tenantId,
                landlordId: this.landlordId,
                billingDate: this.billingDate,
                payerRference: this.payerReference,
                invoiceCreator: undefined,    
                IdContract: this.contractId,
                ReferenceNumber: this.invoices[invoice].referenceNumber,
                legend: this.description,
                AutomaticLegend: this.autofill,
                InvoiceRows: [],
                OriginalInvoiceId: this.invoices[invoice].id,
                Id: this.isExistingInvoice ? this.invoiceMaterial.id : 0,
              };
  
              const invoiceRows = [];
              for (var selectedPayment = 0; selectedPayment < this.paymentsSelected.length; selectedPayment++) {
                if (this.paymentsSelected[selectedPayment].InvoiceId == this.invoices[invoice].id) {
                  let foundInvoicePaymentIndex = 0
                  if (this.isExistingInvoice) {
                    for(let iInvoicePayment = 0; iInvoicePayment < this.invoices[invoice].payments.length; iInvoicePayment++) {
                      if (this.invoices[invoice].payments[iInvoicePayment].id === this.paymentsSelected[selectedPayment].id) {
                        foundInvoicePaymentIndex = iInvoicePayment
                        break
                      }
                    }
                  }
                  const invoiceRowMaterialDTO = {
                    IdInvoice: this.isExistingInvoice ? this.invoiceMaterial.id : 0,
                    OriginalInvoiceRowId: this.isExistingInvoice ? 
                      this.invoices[invoice].payments[foundInvoicePaymentIndex].originalInvoiceRowId : 
                      this.paymentsSelected[selectedPayment].id,
                    NetSum: this.paymentsSelected[selectedPayment].netRefund,
                    GrossSum: this.paymentsSelected[selectedPayment].grossRefund,
                    RentPaymentDomain: this.paymentsSelected[selectedPayment].rentPaymentDomain,
                    Legend: this.paymentsSelected[selectedPayment].legend,
                    BillingDate: this.paymentsSelected[selectedPayment].billingDate,
                  };

                  invoiceRows.push(invoiceRowMaterialDTO);
                }
              }
              invoiceMaterialDTO.InvoiceRows = invoiceRows
              if (invoiceMaterialDTO.InvoiceRows.length != 0) {
                ListOfRefundInvoices.push(invoiceMaterialDTO)
              }
            }
            for (const invoice of ListOfRefundInvoices) {
              if (invoice.InvoiceRows && invoice.InvoiceRows.length > 0) {
                invoice.billingDate = invoice.InvoiceRows[0].BillingDate;
              }
            }
            await this.$rambollfmapi.invoices.createRefundInvoice(ListOfRefundInvoices)
          }
          this.resetModal()
          this.$store.dispatch('app/addAriaLive', this.$t('InvoiceCreateSuccess'))
          this.$emit('created')
          this.$emit("close")
      },
      async initializeInvoice () {
        if (this.isExistingInvoice) {
          this.contractId = this.invoiceMaterial.idContract
          if (this.contractId) 
            await this.onContractChange()
          this.dueDate = this.invoiceMaterial.dueDate
          this.description = this.invoiceMaterial.legend
          this.paymentsSelected = []
          this.invoiceExpanded = []
          let selectedInvoice = undefined
          let selectedPayment = undefined
          this.manualRefundInvoice = this.invoiceMaterial.originalInvoiceId === null
          if (this.invoiceMaterial.rentPaymentDomain != undefined && this.invoiceMaterial.rentPaymentDomain != null) {
            this.selectedPaymentDomain = this.rentPaymentDomains.filter(d => d.id === this.invoiceMaterial.rentPaymentDomain.id )[0]
          }

          this.billingDate = this.invoiceMaterial.billingDate
          if (this.manualRefundInvoice) {
            this.fullContractRefundedNet = 
                - this.invoiceMaterial.invoiceRows[0].netSum
            this.fullContractRefundedGross =
                - this.invoiceMaterial.invoiceRows[0].grossSum
            return
          }
          this.invoices.forEach((invoice) => {
            if (invoice.id === this.invoiceMaterial.originalInvoiceId) {
              selectedInvoice = invoice
              this.invoiceExpanded.push(invoice)
              invoice.payments.forEach((payment) => {
                this.invoiceMaterial.invoiceRows.forEach((row) => {
                  if (row.originalInvoiceRowId === payment.id) {
                    payment.originalInvoiceRowId = row.originalInvoiceRowId
                    payment.id = row.id
                    payment.netRefund = -row.netSum 
                    payment.grossRefund = -row.grossSum
                    payment.legend = row.legend
                    payment.rentPaymentDomain = row.rentPaymentDomain
                    selectedPayment = payment
                    this.paymentsSelected.push(payment)
                  }
                })
              })
            }
          })
          if (selectedInvoice && selectedPayment) {
            selectedInvoice.payments = this.paymentsSelected
            this.invoices = []
            this.invoices.push(selectedInvoice);
          }
        }
      }
    }
  }
    </script>
      
    <style>
.v-data-table--parent {
  padding-bottom: 16px;
}
.v-data-table--parent .v-data-table__expanded__content > td {
  padding-left: 0 !important;
}
.v-data-table--parent
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.v-data-table__expanded__content)
  > td {
  padding: 4px 8px !important;
}
.v-data-table--child {
  padding-bottom: 0;
}
.v-data-table--child td {
  border: none !important;
  border-top: 1px solid #eeeff5 !important;
}
.v-data-table--child th {
  vertical-align: middle;
  height: 32px !important;
  padding: 0.5em 8px !important;
  line-height: 1;
  border: none !important;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.expand {
  width: 42px !important;
}
.height-auto {
  height: auto !important;
}
</style>
  