<template>
  <div>
    <v-expansion-panels
      :value="minimized"
      multiple
    >
      <!-- v-model doesn't work the same anymore on v-expansion-panel inside v-expansion-panels, so workaround -->
      <v-expansion-panel
        class="no-padding"
      >
        <v-expansion-panel-header>
          {{ title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="text-align: center; padding: 2em;"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
            />
          </div>
          <div
            v-else-if="barData === null"
            class="text-center mt-4"
          > 
            <span style="font-weight: normal;">{{ $t('No data available or not time related') }}</span>
          </div>
          <div
            v-else
            style="position: relative"
          >
            <v-row
              no-gutters
              class="flex-column flex-nowrap"
            >
              <v-row
                align="center"
                justify="space-between"
                no-gutters
              >
                <h3 class="subtitle">
                  {{ subtitle }}
                </h3>
                <v-row
                  align="center"
                  justify="end"
                  no-gutters
                >
                  <v-menu
                    v-if="data.groups"
                    left
                  >
                    <template #activator="{ on }">
                      <v-btn
                        outlined
                        small
                        icon
                        style="background: rgba(255, 255, 255, 0.8) !important"
                        v-on="on"
                      >
                        <v-icon>menu</v-icon>
                        <span class="d-sr-only">{{ $t('Menu') }}</span>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="group in data.groups"
                        :key="group.value"
                        :class="{ 'active': currentGroup.text === group.text}"
                        @click="changeCurrentGroup(group)"
                      >
                        <v-list-item-title>{{ $t(group.text) }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </v-row>
    
              <VerticalBarChart
                v-if="barData !== null"
                :data="barData"
                :options="barOptions"
                :plugins="barPlugins"
                style="height: 480px; position: relative"
              />
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import helpers from '../../helpers'
import { calculateTotal, calculateGraphNetTotals, calculateConfirmedTotal } from '../../helpers/leasing/situationAndTarget.js'
import VerticalBarChart from '../VerticalBarChart.vue'
import { i18n } from '../../plugins/i18n.js'
import moment from 'moment'

export default {
  emits: ['changeGroup'],
  components: {
    VerticalBarChart
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentDate: {
      type: Date,
      default: Date.now()
    },
    currentSet: {
      type: Object,
      default: null
    },
    currentGroup: {
      type: Object,
      default: null
    },
    targetCoefficiency: {
      type: Number,
      default: 1
    },
    lowerBoundQuarterDates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      minimized: [0],
      yearChoices: [],
      yearAmount: null,
      divideByArea: false,
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          labels: {
            fontColor: 'black',
            fontSize: 12,
            boxWidth: 20,
            usePointStyle: true,
            generateLabels: (chart) => {
              let labels = []
              chart.data.datasets.forEach((dataset) => {
                const backgroundColor = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[0] : dataset.backgroundColor
                const borderColor = Array.isArray(dataset.backgroundColor) ? dataset.borderColor[0] : dataset.borderColor
                labels.push({
                  text: dataset.label,
                  fillStyle: backgroundColor,
                  strokeStyle: borderColor,
                  pointStyle: dataset.pointStyle,
                  hidden: false,
                  type: dataset.type
                })
              })
              // Add extra labels for predicition ending contracts lighter colors
              let endingDataset = chart.data.datasets.find(dataset => 
                dataset.key === 'ending_fixed'
              )
              if (endingDataset != null) {
                labels.push({
                  text: this.$t('leasing.ending_fixed_prediction'),
                  fillStyle: this.lightenColor(endingDataset.backgroundColor[0]),
                  strokeStyle: this.lightenColor(endingDataset.backgroundColor[0]),
                  pointStyle: endingDataset.pointStyle,
                  lineWidth: 0,
                  hidden: false,
                  type: endingDataset.type
                })
              }
              let terminatedDataset = chart.data.datasets.find(dataset => 
                dataset.key === 'terminated_permanent'
              )
              if (terminatedDataset != null) {
                labels.push({
                  text: this.$t('leasing.terminated_permanent_prediction'),
                  fillStyle: this.lightenColor(terminatedDataset.backgroundColor[0]),
                  strokeStyle: this.lightenColor(terminatedDataset.backgroundColor[0]),
                  pointStyle: terminatedDataset.pointStyle,
                  lineWidth: 0,
                  hidden: false,
                  type: terminatedDataset.type
                })
              }
              labels.sort((a,b) => a.type.localeCompare(b.type))
              return labels
            }
          },
          align: 'center',
          position: 'bottom',
        },
        tooltips: {
          enabled: false,

          custom: function (tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip')

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>'
              document.body.appendChild(tooltipEl)
            }
            tooltipEl.classList.add('bottom', 'left')

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0
              return;
            }

            function getBody (bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              let titleLines = tooltipModel.title || []
              let bodyLines = tooltipModel.body.map(getBody)['0']



              let innerHtml = '<thead>'

              titleLines.forEach(function (title) {
                // Rounded corners for scatter plot tooltips
                let borderRadius = tooltipModel.title[0].split(':')[0] === i18n.t('leasing.start_target') ? '15px;' : '0px;'
                // Smaller height for line tooltip
                let height = tooltipModel.title[0].split(':')[0] === i18n.t('leasing.target_so_far') ? '5px;' : '15px;'
                // Rotation for net total to make it match icon on graph
                let rotation = tooltipModel.title[0].split(':')[0] === i18n.t('leasing.net_total') ? '(45deg);' : '(0deg);'

                // Colored box based on label colors
                let colors = tooltipModel.labelColors['0']
                let colorBoxStyle = 'width: 15px; height: ' + height + '; display: inline-block; left: 5px; top: 5px; margin-right: 5px; background-color: ' + colors.backgroundColor + '; border: 1px solid #fff; border-radius: ' + borderRadius + ' transform:rotate' + rotation
                // Add room below title if there is bodyLines
                let paddingBottom = bodyLines.length > 0 ? '5px;' : '0px;'

                let colorBox = '<div style="' + colorBoxStyle +'"></div>'
                innerHtml += '<tr><th><div style="display: flex; align-items: center; justify-content: flex-start; padding-bottom: ' + paddingBottom + '">' + colorBox + title + '</div></th></tr>'
              });
              innerHtml += '</thead><tbody>'

              bodyLines.forEach(function (body, i) {
                // Text
                innerHtml += '<tr><td><div style="padding-left: 20px;"> ' + body + '</div></td></tr>'
              });
              innerHtml += '</tbody>'

              let tableRoot = tooltipEl.querySelector('table')
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            let position = this._chart.canvas.getBoundingClientRect();


            // Display, position, and set styles for font
            tooltipEl.style.opacity = 0.85
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
            tooltipEl.style.fontFamily = 'Roboto'
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
            tooltipEl.style.zIndex = 1
            tooltipEl.style.backgroundColor = '#000'
            tooltipEl.style.color = '#fff'
            tooltipEl.style.pointerEvents = 'none'
            tooltipEl.style.maxWidth = "450px"
          },
          itemSort: function (a, b) {
            return b.datasetIndex - a.datasetIndex
          },
          callbacks: {
            title: (toolTipItem, data) => {
              if (data.datasets[toolTipItem[0].datasetIndex].type === 'line' ) {
                return data.datasets[toolTipItem[0].datasetIndex].label + ": " + helpers.format.formatData(data.datasets[toolTipItem[0].datasetIndex].data[toolTipItem[0].index].y, this.currentGroup.format)
              }
              let dataValue = data.datasets[toolTipItem[0].datasetIndex].data[toolTipItem[0].index]
              if (dataValue < 0 && data.datasets[toolTipItem[0].datasetIndex].type === 'bar') {
                dataValue = - dataValue
              }
              let value = 
              data.datasets[toolTipItem[0].datasetIndex].label 
              + ': ' 
              + helpers.format.formatData(dataValue, this.currentGroup.format)
              return [value]
            },
            label: (toolTipItem, data) => {
              // If data is type line, add custom label
              if (data.datasets[toolTipItem.datasetIndex].type === 'line' ) {
                return data.datasets[toolTipItem.datasetIndex].clarification
              }
              // If data has subarrays, display their values in label
              if (data.datasets[toolTipItem.datasetIndex].subArrays) {
                const subArrays = data.datasets[toolTipItem.datasetIndex].subArrays[toolTipItem.index]
                return subArrays ? subArrays.map(item => item.text + ': ' + helpers.format.formatData(item.value, this.currentGroup.format)) : ''
              }
              return []
            }
          },
          // usePointStyle: true,
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              id: 'xAxis1',
              type: 'category',
              display: true,
              scaleLabel: {
                display: this.data.xLabel,
                labelString: this.data.xLabel
              },
              gridLines: {
                color: 'white',
                zeroLineColor: 'white'
              },
              ticks: {
                fontColor: '#8b8b8b',
                fontSize: 11,
              },
              stacked: true
            },
            {
              id: 'xAxis2',
              type: 'linear',
              display: false,
              scaleLabel: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 1
              },
              stacked: true
            }
          ],
          yAxes: [
            {
              id: 'yAxis1',
              gridLines: {
                color: '#8b8b8b',
                zeroLineColor: '#000',
                zeroLineWidth: 2,
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#8b8b8b',
                callback: function (value, x, y) {
                  return helpers.format.formatData(value, this.options.format)
                }
              },
              stacked: true,
              offset: true,
              format: this.currentGroup.format
            },
          ],
        },
      },
      barPlugins:  [
        {
          // Splits labels to multiple rowas
          beforeInit: function (chart) {
            chart.data.labels.forEach(function (e, i, a) {
                if (/\n/.test(e)) {
                  a[i] = e.split(/\n/);
                }
            });
          },
        }
      ]
    }
  },
  computed: {
    subtitle () {
      if (this.currentGroup != null) {
          return this.$t(this.currentGroup.text)
      }
      else {
          return ''
      }
    },
    barData () {
      if (!this.data.items) {
        return null
      }
      if (Object.keys(this.data.items).length > 0 && this.currentSet != null && this.currentGroup != null) {
          
        let labels = []
        if (this.currentSet.value === 'all' || this.currentSet.value === 'new') {
            labels = [this.$t('leasing.confirmed'), this.$t('leasing.target'),  this.$t('leasing.prediction_graph'), this.$t('leasing.currentFree_graph')]
        }
        if (this.currentGroup.value === 'marketRent') {
          labels = [this.$t('leasing.confirmed'),  this.$t('leasing.prediction_graph'), this.$t('leasing.currentFree_graph')]
          if (this.currentSet.value === 'new') {
            labels.splice(1, 1)
          }
        }

        const data = this.data.items[this.currentSet.value]

        // We need to reorder data so it's stacked correctly (Largest class on top, others on bottom)
        let dataSetKeys = []

        if (data.others && data.others.order > - 1) {
          let othersOrder = data.others.order
          for (let i = othersOrder; i >= 0; i--) {
            for (let key in data) {
              if (data[key].order === i) {
                dataSetKeys.push(key)
              }
            }
          }
        }
        else {
          for (let key in data) {
            if (data[key].showGraph == true) {
              dataSetKeys.push(key)
            }
          }
          // Hack to get types in right order
          dataSetKeys = dataSetKeys.filter(key => key != 'ending_fixed' && key != 'terminated_permanent')
          dataSetKeys.sort()
          dataSetKeys.reverse()
        }

        dataSetKeys = dataSetKeys.concat(Object.keys(data).filter(key => key === 'ending_fixed' || key === 'terminated_permanent'))

        const datasets = []

        // Add datas based on current set. Ending fixed and terminated permanent contract data added as negative.
        dataSetKeys.forEach((key, idx) => {
          // Check if value has subArrays and them for tooltip data
          let subArrays = null
          if (data[key].subArrays) {
            subArrays = [data[key].subArrays.confirmed, data[key].subArrays.target, data[key].subArrays.prediction, data[key].subArrays.currentFree ]
          }
          let confirmed 
          if (key === 'ending_fixed' || key === 'terminated_permanent') {
            confirmed = calculateTotal(data[key].confirmed, this.currentGroup)
          } else {
            confirmed = calculateConfirmedTotal(data[key].confirmed, this.currentGroup)
          }
          const target = calculateTotal(data[key].target, this.currentGroup, this.targetCoefficiency)
          const prediction = calculateTotal(data[key].prediction, this.currentGroup)
          const currentFree = calculateTotal(data[key].currentFree, this.currentGroup)

          let graphData = key === 'ending_fixed' || key === 'terminated_permanent' ? 
                [-confirmed,  -target, -prediction, -currentFree]
                :
                [confirmed, target, prediction, currentFree]

          if (this.currentGroup.value === 'marketRent') {
            graphData.splice(1,1)
            if (this.currentSet.value === 'new') {
               graphData.splice(1,1)
            }
          }

          datasets.push({
            key: key,
            type: 'bar', 
            label: this.$t(data[key].text),
            order: idx + 3,
            data: graphData,
            borderColor:  [data[key].color, data[key].color, this.lightenColor(data[key].color), this.lightenColor(data[key].color)],
            backgroundColor: [data[key].color, data[key].color, this.lightenColor(data[key].color), this.lightenColor(data[key].color)],
            xAxisID: 'xAxis1',
            subArrays: subArrays,
            pointStyle: 'rect'
          })
        })
        
        //  Add line for target
        const startOfYear = new Date(moment(this.currentDate).startOf('year'))
        const endOfYear = new Date(moment(this.currentDate).endOf('year'))
        let current = moment().startOf('day')
        const daysSoFar = Math.round(moment.duration(current.diff(moment(this.currentDate).startOf('year'))).asDays())
        const daysThisYear = Math.round(moment.duration(moment(this.currentDate).endOf('year').diff(moment(this.currentDate).startOf('year'))).asDays())

        const targetPercentage = (this.currentDate - startOfYear) / (endOfYear -startOfYear)
        let targetSource = data.total ?? data.new ?? data.renewals ?? data.renegotiations
        const targetLineValue = targetPercentage * calculateTotal(targetSource.target, this.currentGroup)
        
        // Values for net totals
        if (data.net_total) {

          const calculatedNetTotal = calculateGraphNetTotals(data, this.currentGroup, this.currentSet, this.currentDate)

          const confirmedNetTotalValue = calculatedNetTotal.confirmed
          const confirmedNetTotalColor =  calculatedNetTotal.color

          // Add points for net total
          datasets.push({
            label: this.$t('leasing.net_total'),
            type: 'scatter',
            data: [confirmedNetTotalValue],
            pointRadius: 6,
            pointHitRadius: 9,
            borderColor: ['#fff'],
            backgroundColor: [confirmedNetTotalColor],
            order: 1,
            xAxisID: 'xAxis1',
            pointStyle: 'rectRot'
          })
        }
        else if (this.currentSet.value !== 'all' && this.currentSet.value !== 'new') {
          
          

          const confirmedNetTotalValue = calculateTotal(targetSource.confirmed, this.currentGroup)
          const confirmedNetTotalColor = confirmedNetTotalValue >= targetLineValue ? '#0dc526' : '#ff0000'
          
          // Add points for net total
          datasets.push({
            label: this.$t('leasing.net_total'),
            type: 'scatter',
            data: [confirmedNetTotalValue],
            pointRadius: 6,
            pointHitRadius: 9,
            borderColor: ['#fff'],
            backgroundColor: [confirmedNetTotalColor],
            order: 1,
            xAxisID: 'xAxis1',
            pointStyle: 'rectRot'
          })
        }

        let startTarget = data.startTarget
        let startCurrentFree = data.startCurrentFree

        let pointData =  [null, startTarget, null, startCurrentFree]
        if (this.currentGroup.value === 'marketRent') {
          pointData.splice(1, 1)
          if (this.currentSet.value === 'new') {
            pointData.splice(1,1)
          }
        }

        
        if (!(this.lowerBoundQuarterDates.length > 0)) {

          // Add points for start of year targets and vacantspaces
          datasets.push({
            label: this.$t('leasing.start_target'),
            type: 'scatter',
            data: pointData,
            pointRadius: 6,
            pointHitRadius: 10,
            borderColor: '#fff',
            backgroundColor: '#000',
            order: 2,
            xAxisID: 'xAxis1'
          })

          let lineArrayLength = 17
          if (this.currentSet.value !== 'all' && this.currentSet.value !== 'new') {
            lineArrayLength = 22
          }
          let lineData = []
          if (this.currentGroup.value !== 'marketRent') {
            lineData = new Array(lineArrayLength).fill(0).map((item, index) => index).map(item => { return{ x: 0.015 + item*0.03, y: targetLineValue }})
          }

          let clarification = this.$t('leasing.target_so_far_clarification_1') + ': ' + helpers.format.formatData(targetLineValue, this.currentGroup.format) + ". " 
            + this.$t('leasing.target_so_far_clarification_2') + " " +  Math.round(targetPercentage*100) + '% (' + daysSoFar + ' vrk / ' + daysThisYear + ' vrk)'

          datasets.push({
            label: this.$t('leasing.target_so_far'),
            clarification: clarification,
            type: 'line',
            data: lineData,
            pointRadius: 0,
            pointHitRadius: 5,
            pointHoverRadius: 0,
            borderColor: '#9b2ac9',
            backgroundColor: '#9b2ac9',
            fill: false,
            order: 2,
            xAxisID: 'xAxis2',
            pointStyle: 'line'
          }) 
        }
        return {
          labels: labels,
          datasets: datasets,
        }
      } else {
        return null
      }
    },
  },
  watch: {
    currentGroup: function (newValue) {
      this.barOptions.scales.yAxes.find(item => item.id ==='yAxis1').format = newValue.format
    }
  },
  methods: {
    lightenColor (colorString) {

      // First convert to rgb
      let r = 0, g = 0, b = 0;

      // 3 digits
      if (colorString.length == 4) {
        r = "0x" + colorString[1] + colorString[1];
        g = "0x" + colorString[2] + colorString[2];
        b = "0x" + colorString[3] + colorString[3];

      // 6 digits
      } else if (colorString.length == 7) {
        r = "0x" + colorString[1] + colorString[2];
        g = "0x" + colorString[3] + colorString[4];
        b = "0x" + colorString[5] + colorString[6];
      }

      // Then convert to hsl and add lighten
      r /= 255;
      g /= 255;
      b /= 255;

      // Find greatest and smallest channel values
      let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

      // Calculate hue
      // No difference
      if (delta == 0)
        h = 0;
      // Red is max
      else if (cmax == r)
        h = ((g - b) / delta) % 6;
      // Green is max
      else if (cmax == g)
        h = (b - r) / delta + 2;
      // Blue is max
      else
        h = (r - g) / delta + 4;

      h = Math.round(h * 60);
        
      // Make negative hues positive behind 360 degrees
      if (h < 0)
          h += 360;

      l = (cmax + cmin) / 2;

      // Calculate saturation
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        
      // Multiply l and s by 100 and increase l by 15 to add lightening effect
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1) + 20;

      return "hsl(" + h + "," + s + "%," + l + "%)";
    },
    changeCurrentGroup (value) {
      this.$emit('changeGroup', value)
    }
  }
}
</script>

<style scoped>
.btn-area {
  text-transform: none;
  font-size: 12px;
  font-weight: 700;
  max-width: 28px;
  max-height: 28px;
}
.subtitle {
  padding-left: var(--r-spacing-s);
}
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>