!<template>
  <v-form
    ref="editNoteDialog"
  >
    <BaseModal
      @cancel="close"
    >
      <template #title>
        {{ $t("prospect.editNote") }}
      </template>
      <template #content>
        <v-card
          v-if="note"
          elevation="0"
        >
          <h3
            v-if="note && note.prospectName"
            class="ml-3 mb-4"
          >
            {{ $t("prospect") }}: <span class="font-weight-regular">{{ note.prospectName }}</span>
          </h3>
          <h3
            v-else-if="note && note.siteName"
            class="ml-3 mb-4"
          >
            {{ $t("Site") }}: <span class="font-weight-regular">{{ note.siteName }}</span>
          </h3>
          <v-card
            elevation="0"
            class="note-card pa-3 mt-3 mx-3"
          >
            <v-card-title class="pa-0 ma-0">
              <p class="text-caption font-weight-light">
                {{ note.editor }}
              </p>
              <v-spacer />
              <p class="text-caption font-weight-light">
                {{ formatDate(note.modifiedDate) }}
              </p>
            </v-card-title>
            <v-textarea
              v-model="comment"
              rows="4"
              solo
              flat
              hide-details
              auto-grow
              autofocus
              class="ma-0 pa-1 note-no-border"
            />
          </v-card>
        </v-card>
      </template>
      <template #footer>
        <v-btn
          type="submit"
          rounded
          depressed
          color="primary"
          :disabled="note && note.comment === comment"
          :loading="editSaving"
          @click="save"
        >
          {{ $t('Save') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-form>
</template>

<script>
import moment from 'moment'
import BaseModal from '../general/BaseModal.vue'

export default {
  emits: ['close', 'save'],
    name: "EditNoteDialog",
    components: {
      BaseModal,
    },
    props: {
      note: {
        type: Object,
        default: null
      },
      isShown: {
        type: Boolean,
        default: false
      },
      editSaving: {
        type: Boolean,
        default: false
      }
    },
    data () {
        return {
          comment:""
        }
    },
    watch: {
      isShown: function (shown) {
        if (shown) {
          this.comment = this.note.comment
        }
        else {
          this.comment = ""
        }
      }
    },
    mounted () {
      this.comment = this.note.comment
    },
    methods: {
      close () {
        this.$emit('close');
      },
      save (e) {
        e.preventDefault()
        if (this.comment.length > 4000) {
          alert(this.$t("prospect.note_too_long"))
        }
        else {
          this.$emit('save', this.comment, this.note.noteId );
        }
      },
      formatDate (date) {
        return moment(date).format('HH:mm \xa0 DD.MM.YYYY');
      },
    }
}
</script>

<style scoped>
.note-card {
  border: 2px solid var(--c-color-accent) !important;
  border-radius: 20px !important;
}
.note-no-border {
  border: none !important;
}
</style>