<template>
  <v-row class="xs8 nowrap">
    <v-col
      cols="5"
      class="text-left nowrap"
    >
      <span
        class="information-field"
      >{{ $t(getFieldName(sourceData.text)) }}</span>
    </v-col>
    <v-row
      class="xs4 nowrap"
      justify="start"
    >
      <v-col
        cols="6"
      >
        <span class="information-field">{{ formatData(sourceData.value, sourceData.format) }}</span>
      </v-col>
      <v-col
        cols="1"
      >
        <StatusIcon
          :field="{ name: sourceData.text, data: sourceData.value }"
          :data="sourceData"
        />
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import format from '../../helpers/format.js'
import StatusIcon from '../../components/StatusIcon.vue'
export default {
    name: 'InformationFieldWithStatusIcon',
    components: {
      StatusIcon
    },
    props: {
      sourceData: {
        type: Object,
        default: null
      }
    },
    data () {
        return {}
    },
    mounted () {

    },
    methods: {
      formatData (value, fieldFormat) {
        return format.formatData(value, fieldFormat)
      },
      getFieldName (field) {
      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)
      return field
      }
    }
    
}
</script>
<style scoped>
.information-field {
  font-size: 14px;
  font-weight: normal;
}
</style>