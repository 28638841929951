!<template>
  <div class="px-0 pb-4">
    <v-card
      flat
      class="note-card mx-0"
    >
      <v-card-title class="pa-0 ma-0">
        <p class="date-text my-0 text-right mr-5">
          {{ notification.modified_date ? formatDate(notification.modified_date) : '' }}
        </p>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-row
          align="center"
          justify="space-between"
        >
          <v-col cols="1">
            <v-icon>priority_high</v-icon>
          </v-col>
          <v-col
            class="pa-1 text-left text-subtitle-2 "
          >
            <span class="note-valuefont-weight-medium">{{ $t(getNotificationText(notification.event_type)) }}: </span>
            <span
              v-if="notification.id_prospect"
              class="linked"
              @click="openProspect(notification.id_prospect)"
            >{{ notification.prospect_description }}</span>
            <v-col
              v-else-if="notification.reservation_date"
              style="padding: 0"
            >
              <span
                class="linked"
                @click="routeTo(notification.id_site)"
              > {{ notification.site_name }}
              </span>
              <span>
                {{ ': ' + $t('Facilitys') + ' ' }}
              </span>
              <span
                class="linked"
                @click="routeTo(notification.id_site, notification.id_unit)"
              > {{ notification.unit_name }}
              </span>
              <span> {{ $t('reservation') + ' (' + notification.unit_area + ' m²) ' + $t('is ending') + '  '+ formatDate(notification.reservation_date, true) + '.' }}</span>
            </v-col>
            <v-col
              v-else-if="notification.preventation_date"
              style="padding: 0"
            >
              <span
                class="linked"
                @click="routeTo(notification.id_site)"
              > {{ notification.site_name }}
              </span>
              <span>
                {{ ': ' + $t('Facilitys') + ' ' }}
              </span>
              <span
                class="linked"
                @click="routeTo(notification.id_site, notification.id_unit)"
              > {{ notification.unit_name }}
              </span>
              <span> {{ ' ' + $t('web visibility preventation') + ' (' + notification.unit_area + ' m²) ' + $t('is ending') + ' ' + formatDate(notification.preventation_date, true) + '.' }}</span>
            </v-col>
          </v-col>  
          <v-col class="text-right">
            <v-icon
              @click="close"
            >
              close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  emits: ['remove'],
  name: 'Notification',
  props: {
    notification: {type: Object, default: null}
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions('leasing', ['changeProspectModalState']),
    close () {
      this.$emit('remove', this.notification.id, this.notification.id_site, this.notification.id_unit, this.notification.event_type)
    },
    formatDate (date, facilityNote) {
      if(facilityNote) {
        return moment(date).format('DD.MM.YYYY')
      }
      // Show only time for notifications from today
      if (moment(date) > moment().startOf('day')) {
        return moment(date).format('HH:mm')
      }
      // Show weekday shorthand and time for notifications from the last 6 weekdays
      else if (moment(date) > moment().startOf('day').subtract(6, 'days')) {
        return moment(date).locale('fi').format('dd \xa0 HH:mm')
      }
      // For other notifications show date and time
      else {
        return moment(date).format('DD.MM.YYYY \xa0 HH:mm')
      }
    },
    openProspect (prospectId) {
      this.changeProspectModalState(prospectId)
    },
    getNotificationText (eventType) {
      switch (eventType) {
        case 'Prospekti luotu':
          switch (this.notification.prospect_type.typeText) {
            case 'leasing.renegotiations':
              return 'notification.new_renegotiations'
            case 'leasing.renewal':
              return 'notification.new_renewals'
            case 'leasing.new_negotiation':
              return 'notification.new_prospect'
          }
          break
        case 'Prospekti merkitty voitetuksi':
          return 'notification.prospect_awaiting_allocation'
        case 'Unit reservation ending':
          return 'notification.reservation'
        case 'Unit preventation ending':
          return 'notification.preventation'
        default:
          return 'notification.unknown'
      }
    },
    async routeTo (siteId, unitId = null) {
      let plan = await this.$rambollfmapi.sites.get(siteId)
      plan.has_dwg && unitId? 
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: siteId },
            query: { suite_id: unitId }
          }) : 
           this.$router.push({
            name: 'sites.info',
            params: { id_site: siteId }
          })
    },
  }
}
</script>

<style scoped>
.note-card {
  border-radius: 30px !important;
  background-color: #d5e9f6 !important;
}
.note-value {
  white-space: pre-line;
  text-align: justify;
}
.date-text {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 10px;
}
</style>