import format from '../format.js'

export const dateInSpanOfDays = (date, start, days) => {

  if (!date) {
    return false
  }

  let startDate = new Date()

  if (start) {
    startDate = new Date(start)
  }

  const endDate = new Date(startDate)
  endDate.setDate(endDate.getDate() + days)

  const curDate = new Date(date)

  return start < curDate && curDate < endDate
}

// Used for calculating widget data for widget leasing.occupancy_rates.
// At the moment of writing this, this widget is in two views. components/tabs/Leasing/GeneralSituation and views/RentalContracts
export function loadOccupancyRates (
  futureRentalStatuses,
  allSitesRentalStatuses,
  carparkOccupancyRates,
  sites,
  rentalDefs,
  currentDate = new Date(),
  capitalOccupancy
) {

  const groupedCarparkRates = carparkOccupancyRates.reduce((acc, cur) => {

    if(acc[cur.id_site]){
      acc[cur.id_site].carparks += cur.carparks
      acc[cur.id_site].carparks_rented += cur.carparks_rented
      acc[cur.id_site].carparksFutureRented += cur.carparksFutureRented
    } else {
      acc[cur.id_site] = {...cur}
    }

    return acc
  }, {})

  const groupedCapitalOccupancy = capitalOccupancy.reduce((acc, cur) => {
    acc[cur.id_site] = {...cur}
    return acc
  }, {})

  // If inspection year and quarter is selected, use that for dates
  const permittedStatuses = ['rentable', 'rentable.koy']
  const rentableDefs = rentalDefs
    .filter((x) => permittedStatuses.includes(x.label))
    .map((x) => x.id)

  const ownedSites = sites.filter(site => site.ownership_status === "Omistuksessa")

  // Only show sites with ownership_status 'Omistuksessa'
  const filteredSites = ownedSites.map((item) => item.id_site)
  const filteredRentalStatuses = allSitesRentalStatuses
    .filter((item) => filteredSites.includes(item.siteId))
    .filter((item) => rentableDefs.includes(item.unitStatus))

  // Add future rental statuses to data
  const futureRentalStatusIds = futureRentalStatuses.map((rs) => rs.unitId)

  // Calculate data for all sites
  const siteData = ownedSites.reduce((acc, site) => {

    const carparkRates = groupedCarparkRates[site.id_site]
    const capital = groupedCapitalOccupancy[site.id_site]

    return {
      ...acc,
      [site.id_site]: {
        id_site: site.id_site,
        siteName: site.name,
        commercialName: site.commercial_name,
        upkeep_rent_budget_vat: site.upkeep_rent_budget_vat || 0,
        totalArea: 0,
        rentedArea: 0,
        freeArea: 0,
        endingUnitArea: 0,
        futureRentedArea: 0,
        renovationsArea: 0,
        totalMarketRent: 0,
        
        carparkCount: carparkRates ? carparkRates.carparks : 0,
        carparksRented: carparkRates ? carparkRates.carparks_rented : 0,
        carparksFutureRented: carparkRates ? carparkRates.carparksFutureRented : 0,

        unitCapitalCurrent: capital?.unit_capital_current,
        carparkCapitalCurrent: capital?.carpark_capital_current,
        capitalFuture: capital?.unit_capital_future + capital?.carpark_capital_future,
        unitMarketRentTotal: capital?.unit_market_rent_total,
        carparkMarketRentTotal: capital?.carpark_market_rent_total,
        unitMarketRentFree: capital?.unit_market_rent_free,
        carparkMarketRentFree: capital?.carpark_market_rent_free,

        // Unit counts
        totalUnitCount: 0,
        rentedUnitCount: 0,
        futureRentedUnitCount: 0,
        freeUnitCount: 0
      }
    }
  }, {})

  filteredRentalStatuses.forEach((rs) => {
    const { area, marketRent } = rs

    const site = siteData[rs.siteId]

    site.totalArea += area
    site.totalMarketRent += (area ?? 0) * (marketRent ?? 0)
    site.totalUnitCount += 1

    // Rented
    if (rs.curPartyId !== 0) {
      site.rentedArea += area
      site.rentedUnitCount += 1
    // Contract starting in future
    } else if (futureRentalStatusIds.includes(rs.unitId)) {
      const futureRentalStatus = futureRentalStatuses.find((item) => item.unitId === rs.unitId)
      site.futureRentedArea += futureRentalStatus.area
      site.futureRentedUnitCount += 1
    // Last unit ending date in 365 days from given date
    } else if (dateInSpanOfDays(rs.lastUnitEndDate, currentDate, 365)) {
      site.futureRentedArea += area
      site.futureRentedUnitCount += 1
    // Has next tenant defined
    } else if (rs.nextPartyId !== 0) {
      site.futureRentedArea += area
      site.futureRentedUnitCount += 1
    // Otherwise its rentable area.
    } else {
      site.freeArea += area
      site.freeUnitCount += 1
    }
  })

  const rented = Object.values(siteData).map(site => ({
    id_site: site.id_site,
    siteName: site.siteName,
    commercialName: site.commercialName,
    area: site.rentedArea,
    status: 'Vuokrattu & sopimus alkanut',
    // Data for details modal
    totalArea: site.totalArea,
    rentedArea: site.rentedArea,
    futureRentedArea: site.futureRentedArea,
    freeArea: site.freeArea,
    techincalUsageRate: (site.rentedArea / site.totalArea) * 100,

    carparkCount: site.carparksRented, // To pie chart
    // Needed for table and occupancy rate calculations
    totalCarparkCount: site.carparkCount,
    carparksRented: site.carparksRented,
    carparksFutureRented: site.carparksFutureRented,
    freeCarparks: site.carparkCount - site.carparksFutureRented - site.carparksRented,

    // Unit Counts
    unitCount: site.rentedUnitCount,
    totalUnitCount: site.totalUnitCount,
    rentedUnitCount: site.rentedUnitCount,
    futureRentedUnitCount: site.futureRentedUnitCount,
    freeUnitCount: site.freeUnitCount,
    unitRate: ((site.rentedUnitCount + site.futureRentedUnitCount) / site.totalUnitCount ) * 100,

    capital: site.unitCapitalCurrent + site.carparkCapitalCurrent, // To pie chart
    // Needed for table and calculations
    unitCapitalCurrent: site.unitCapitalCurrent,
    carparkCapitalCurrent: site.carparkCapitalCurrent,
    capitalFuture: site.capitalFuture,
    unitMarketRentTotal: site.unitMarketRentTotal,
    carparkMarketRentTotal: site.carparkMarketRentTotal,
    marketRentFree: site.unitMarketRentFree,
    economicOccupancy: ((site.capitalFuture + site.unitCapitalCurrent + site.carparkCapitalCurrent)
    / (site.capitalFuture + site.unitCapitalCurrent + site.carparkCapitalCurrent + site.unitMarketRentFree + site.carparkMarketRentFree)) * 100
  }))

  const rentable = Object.values(siteData).map(site => ({
    id_site: site.id_site,
    siteName: site.siteName,
    commercialName: site.commercialName,
    area: site.freeArea,
    marketRent: site.freeMarketRent,
    status: 'Vapaana',
    // Data for details modal
    totalArea: site.totalArea,
    rentedArea: site.rentedArea,
    futureRentedArea: site.futureRentedArea,
    freeArea: site.freeArea,
    techincalUsageRate: (site.rentedArea / site.totalArea) * 100,

    carparkCount: site.carparkCount - site.carparksRented - site.carparksFutureRented, // To pie chart
    // Needed for table and occupancy rate calculations
    totalCarparkCount: site.carparkCount,
    carparksRented: site.carparksRented,
    carparksFutureRented: site.carparksFutureRented,
    freeCarparks: site.carparkCount - site.carparksFutureRented - site.carparksRented,

    // Unit Counts
    unitCount: site.freeUnitCount,
    totalUnitCount: site.totalUnitCount,
    rentedUnitCount: site.rentedUnitCount,
    futureRentedUnitCount: site.futureRentedUnitCount,
    freeUnitCount: site.freeUnitCount,
    unitRate: ((site.rentedUnitCount + site.futureRentedUnitCount) / site.totalUnitCount ) * 100,

    capital: site.unitMarketRentFree + site.carparkMarketRentFree, // To pie chart
    // Needed for table and calculations
    unitCapitalCurrent: site.unitCapitalCurrent,
    carparkCapitalCurrent: site.carparkCapitalCurrent,
    capitalFuture: site.capitalFuture,
    unitMarketRentTotal: site.unitMarketRentTotal,
    carparkMarketRentTotal: site.carparkMarketRentTotal,
    marketRentFree: site.unitMarketRentFree,
    economicOccupancy: (site.capitalFuture + site.unitCapitalCurrent + site.carparkCapitalCurrent)
    / (site.capitalFuture + site.unitCapitalCurrent + site.carparkCapitalCurrent + site.unitMarketRentFree + site.carparkMarketRentFree) * 100
  }))

  const futureRented = Object.values(siteData).map(site => ({
    id_site: site.id_site,
    siteName: site.siteName,
    commercialName: site.commercialName,
    area: site.futureRentedArea + site.endingUnitArea,
    marketRent: site.futureRentedMarketRent + site.endingUnitMarketRent,
    status: 'Vapaana & tulevaisuudessa vuokrattu',
    // Data for details modal
    totalArea: site.totalArea,
    rentedArea: site.rentedArea,
    futureRentedArea: site.futureRentedArea,
    freeArea: site.freeArea,

    endingUnitArea: site.endingUnitArea,
    total: site.futureRentedArea + site.endingUnitArea,

    carparkCount: site.carparksFutureRented, // To pie chart
    // Needed for table and occupancy rate calculations
    totalCarparkCount: site.carparkCount,
    carparksRented: site.carparksRented,
    carparksFutureRented: site.carparksFutureRented,
    freeCarparks: site.carparkCount - site.carparksFutureRented - site.carparksRented,

    // Unit Counts
    unitCount: site.futureRentedUnitCount,
    totalUnitCount: site.totalUnitCount,
    rentedUnitCount: site.rentedUnitCount,
    futureRentedUnitCount: site.futureRentedUnitCount,
    freeUnitCount: site.freeUnitCount,
    unitRate: ((site.rentedUnitCount + site.futureRentedUnitCount) / site.totalUnitCount ) * 100,

    capital: site.capitalFuture,  // To pie chart
    // Needed for table and calculations
    unitCapitalCurrent: site.unitCapitalCurrent,
    carparkCapitalCurrent: site.carparkCapitalCurrent,
    capitalFuture: site.capitalFuture,
    unitMarketRentTotal: site.unitMarketRentTotal,
    carparkMarketRentTotal: site.carparkMarketRentTotal,
    marketRentFree: site.unitMarketRentFree,
    economicOccupancy: (site.capitalFuture + site.unitCapitalCurrent + site.carparkCapitalCurrent)
    / (site.capitalFuture + site.unitCapitalCurrent + site.carparkCapitalCurrent + site.unitMarketRentFree + site.carparkMarketRentFree) * 100

  }))

  const data = [...rented, ...futureRented, ...rentable]

  // Calculate and add summary value
  const totals = Object.values(siteData).reduce((acc, curr) => {
    acc.rentedArea += curr.rentedArea + curr.futureRentedArea + curr.endingUnitArea
    acc.totalArea += curr.totalArea

    acc.totalUnitCount += curr.totalUnitCount
    acc.rentedUnitCount += curr.rentedUnitCount
    acc.futureRentedUnitCount += curr.futureRentedUnitCount

    acc.carparkCount += curr.carparkCount
    acc.carparksRented += curr.carparksRented
    acc.carparksFutureRented += curr.carparksFutureRented
    acc.capital += curr.unitCapitalCurrent + curr.carparkCapitalCurrent + curr.capitalFuture
    acc.marketRent += curr.unitMarketRentFree + curr.carparkMarketRentFree
    return acc
  }, {
    rentedArea: 0,
    totalArea: 0,
    carparkCount: 0,
    carparksRented: 0,
    carparksFutureRented: 0,
    capital: 0,
    marketRent: 0,
    totalUnitCount: 0,
    rentedUnitCount: 0,
    futureRentedUnitCount: 0,
  })

  const summary = {
    id: 'summary',
    area: (totals.rentedArea / totals.totalArea) * 100,
    carparkCount: ((totals.carparksRented + totals.carparksFutureRented) / totals.carparkCount ) * 100,
    unitCount: ((totals.rentedUnitCount + totals.futureRentedUnitCount) / totals.totalUnitCount ) * 100,
    capital: (totals.capital) / (totals.marketRent + totals.capital) * 100,
    format: 'Percentage'
  }

  data.push(summary)

  return data
}

export const calculateUsageRate = (siteData, futureOrEndingAsFree = false) => {
  let technicalUsageRate = 0
  let economicUsageRate = 0
  if (futureOrEndingAsFree) {
    technicalUsageRate = (((siteData.rented_area + siteData.leased_or_ending_in_future_area) / siteData.total_area) * 100).toFixed(2)
    economicUsageRate = (((siteData.rented_market_rent + siteData.leased_or_ending_in_future_rent) / siteData.total_market_rent) * 100).toFixed(2)
  } else {

    const currentlyFreeArea = siteData.total_area - siteData.leased_or_ending_in_future_area

    if (!currentlyFreeArea) {
      technicalUsageRate = 0
    } else {
      technicalUsageRate = (siteData.rented_area / currentlyFreeArea * 100).toFixed(2)
    }

    const currentlyFreeMarketRent = siteData.total_market_rent - siteData.leased_or_ending_in_future_rent
    if (!currentlyFreeMarketRent) {
      economicUsageRate = 0
    } else {
      economicUsageRate = (siteData.rented_market_rent / currentlyFreeMarketRent * 100).toFixed(2)
    }

  }
  return { technicalUsageRate, economicUsageRate }
}

export const loadOccupancyRateProgress = (data) => {
  const bars = data.map(item => {
    const bar = {...item}
    bar.month = format.formatData(item.date, 'MonthAndYear')
    
    // Occupancy rates
    const capitalTotal = bar.rented_capital + bar.future_rented_capital + bar.free_capital
    bar.rented_capital_percentage = bar.rented_capital / capitalTotal * 100
    bar.future_rented_capital_percentage = bar.future_rented_capital / capitalTotal * 100
    bar.free_capital_percentage = bar.free_capital / capitalTotal * 100
    bar.occupancy_rate_capital = bar.rented_capital_percentage + bar.future_rented_capital_percentage

    const unitsAreatotal = bar.rented_units_area + bar.future_rented_units_area + bar.free_units_area
    bar.rented_units_area_percentage = bar.rented_units_area / unitsAreatotal * 100
    bar.future_rented_units_area_percentage = bar.future_rented_units_area / unitsAreatotal * 100
    bar.free_units_area_percentage = bar.free_units_area / unitsAreatotal * 100
    bar.occupancy_rate_units_area = bar.rented_units_area_percentage + bar.future_rented_units_area_percentage

    const unitsTotal = bar.rented_units + bar.future_rented_units + bar.free_units
    bar.rented_units_percentage = bar.rented_units / unitsTotal * 100
    bar.future_rented_units_percentage = bar.future_rented_units / unitsTotal * 100
    bar.free_units_percentage = bar.free_units / unitsTotal *  100
    bar.occupancy_rate_units = bar.rented_units_percentage + bar.future_rented_units_percentage

    const carparksTotal = bar.rented_carparks + bar.future_rented_carparks + bar.free_carparks
    bar.rented_carparks_percentage = bar.rented_carparks / carparksTotal * 100
    bar.future_rented_carparks_percentage = bar.future_rented_carparks / carparksTotal * 100
    bar.free_carparks_percentage = bar.free_carparks / carparksTotal *  100
    bar.occupancy_rate_carparks = bar.rented_carparks_percentage + bar.future_rented_carparks_percentage

    return bar
  }).sort((prev,next) => new Date(prev.date) - new Date(next.date))


  return bars
}