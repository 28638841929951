<template>
  <div
    id="budgetingandrealisation"
    no-gutters
  >
    <AriaLive />
    <v-dialog
      v-model="budgetCreationDialogOpen"
      persistent
      max-width="550"
    >
      <BaseModal
        @cancel="closeBudgetCreation"
      >
        <template #title>
          {{ $t('internalrent.budget_creation_from_realization') }}
        </template>
        <template #content>
          <div class="section-textfield">
            <h4 
              class="text--secondary"
              style="text-align: center"
            >
              {{ $t('internalrent.set_scaling_percents_for_year') + ' ' + expenseYears.nextYear + ' ' + $t('internalrent.budget_per_expense_type') }}
            </h4>
          </div>
          <v-row
            class="align-left flex-column"
            justify="center"
          >
            <v-col
              v-for="expenseType in budgetCreationItems"
              :key="expenseType.idExpense"
              cols="12"
            >
              <v-row
                class="form-field"
              >
                <v-col
                  cols="7"
                  align-self="center"
                >
                  {{ expenseType.expenseName }}
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="expenseType.scalingPercent"
                    type="number"
                  />
                </v-col>
                <v-col cols="1" />
                <v-col
                  cols="1"
                  align-self="center"
                >
                  %
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br>
        </template>
        <template #footer>
          <v-btn
            :disabled="creatingBudget"
            rounded
            depressed
            color="primary"
            @click="createBudget"
          >
            <span v-if="!creatingBudget">{{ $t('internalrent.create_budget') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <div>
      <draggable 
        v-model="budgetingAndRealisationWidgets"
        :disabled="!isDraggable"
      >
        <div
          v-for="widget in budgetingAndRealisationWidgets"
          :key="widget.id"
        >
          <ExpenseTable
            :id="widget.id"
            :data="widget.data"
            :edit="widget.edit"
            :loading="loading"
            :is-draggable="isDraggable"
            :is-open="widget.isOpen"
            :is-saving="isSaving"
            :realization-to-budget-btn="widget.realizationToBudgetBtn && hasApplicationPermissionByName('SIS_VUOKRALASKENTA')"
            :show-edit="widget.showEdit !== undefined ? widget.showEdit : hasApplicationPermissionByName('SIS_VUOKRALASKENTA')"
            @editing="handleEditingMode"
            @saveChanges="saveChanges"
            @changeOpenStatus="widget.isOpen = $event"
            @openRealizationToBudgetDialog="openBudgetCreation"
          />
        </div>
      </draggable>
      <v-row
        v-show="isDraggable"
        style="right: 0; bottom: 0; position: fixed; z-index: 1"
      >
        <v-row
          align="center"
          justify="end"
        >
          <v-btn
            outlined
            rounded
            text
            @click="getWidgetOrder()"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            rounded
            depressed
            class="primary"
            @click="setWidgetOrder()"
          >
            {{ $t('Save default view') }}
          </v-btn>
        </v-row>
      </v-row>
    </div>
  </div>
</template>

<script>
import ExpenseTable from './ExpenseTable.vue'
import BaseModal from '../general/BaseModal.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import AriaLive from '../../components/AriaLive.vue'
export default {
  name: 'BudgetingAndRealisation',
  components: {
    BaseModal,
    ExpenseTable,
    draggable,
    AriaLive
  },
  props: {
    inspectionYear: {
      default: () => {
        return new Date().getFullYear()
      },
      type: Number
    }
  },
  data () {
    return {
      loading: true,
      isSaving: false,
      budgetCreationDialogOpen: false,
      budgetCreationItems: [],
      creatingBudget: false,
      months: [
        { text: this.$t('January'), value: 1 },
        { text: this.$t('February'), value: 2 },
        { text: this.$t('March'), value: 3 },
        { text: this.$t('April'), value: 4 },
        { text: this.$t('May'), value: 5 },
        { text: this.$t('June'), value: 6 },
        { text: this.$t('July'), value: 7 },
        { text: this.$t('August'), value: 8 },
        { text: this.$t('September'), value: 9 },
        { text: this.$t('October'), value: 10 },
        { text: this.$t('November'), value: 11 },
        { text: this.$t('December'), value: 12 }
      ],
      inspectionMonths: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      expenseYears: {
        previousYear: this.inspectionYear - 2,
        lastYear: this.inspectionYear - 1,
        thisYear: this.inspectionYear,
        nextYear: this.inspectionYear + 1
      },
      widgetOrder: [],
      budgetingAndRealisationWidgets: [],
      widgets: [
        {
          id: 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_maintenance_charges',
          type: 'ExpenseTable',
          edit: false,
          isOpen: [ true ],
          realizationToBudgetBtn: true,
          isRealizationWidget: true,
          data: {}
        },
        {
          id: 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_triple_net_rent',
          type: 'ExpenseTable',
          edit: false,
          isOpen: [ true ],
          realizationToBudgetBtn: true,
          isRealizationWidget: true,
          data: {}
        },
        {
          id: 'internalrent_budgeting_and_realization.maintenance_budgeting_by_expenses',
          type: 'ExpenseTable',
          edit: false,
          isOpen: [ true ],
          realizationToBudgetBtn: false,
          isRealizationWidget: false,
          data: {}
        },
        {
          id: 'internalrent_budgeting_and_realization.triplenet_budgeting_by_expenses',
          type: 'ExpenseTable',
          edit: false,
          isOpen: [ true ],
          realizationToBudgetBtn: false,
          isRealizationWidget: false,
          data: {}
        },
        {
          id: 'internalrent_budgeting_and_realization.swarmed_expenses',
          type: 'ExpenseTable',
          edit: false,
          isOpen: [ true ],
          showEdit: false,
          realizationToBudgetBtn: false,
          isRealizationWidget: false,
          data: {}
        }
      ],
      swarmedExpensesWidget: false
    }
  },
  computed: {
    ...mapState('internalRent', ['expenses', 'portfolio', 'targetingCodesForMonths']),
    ...mapState('app', ['userWidgets', 'sortableWidgets']),
    ...mapGetters('app', ['applicationPermissions', 'hasApplicationPermissionByName']),
    isDraggable () {
      return this.sortableWidgets
    },
  },
  watch: {
    portfolio: async function () {
      this.loading = true
      if(this.swarmedExpensesWidget) {
        await this.getTargetingCodesForSelectedMonths({ idOrganization: this.userOrganizations[0].id, portfolioId: this.portfolio.id })
      }
      this.updateWidgetData()      
      this.loading = false
    },
    inspectionYear: async function () {
      this.loading = true
      // Update years
      this.expenseYears = {
        previousYear: this.inspectionYear - 2,
        lastYear: this.inspectionYear - 1,
        thisYear: this.inspectionYear,
        nextYear: this.inspectionYear + 1
      }
      if(this.swarmedExpensesWidget) {
        await this.getTargetingCodesForSelectedMonths({ idOrganization: this.userOrganizations[0].id, portfolioId: this.portfolio.id })
      }
        this.updateWidgetData()
        this.loading = false
      }
  },
  async mounted () {
    this.userOrganizations = await this.$rambollfmapi.userOrganizations.user()
    // Lets get the data for targeting codes if user has swarmed expenses in it's widget collection
    this.swarmedExpensesWidget = this.widgets.find(w => w.id === 'internalrent_budgeting_and_realization.swarmed_expenses')
    if(this.swarmedExpensesWidget) {
      await this.getTargetingCodesForSelectedMonths({ idOrganization: this.userOrganizations[0].id, portfolioId: this.portfolio.id})
    }
    this.getWidgetOrder()
    this.updateWidgetData()
    this.loading = false
  },
  methods: {
    ...mapActions('internalRent', [
      'getExpenses',
      'getPortfolio',
      'updateBudgetsForYear',
      'setMonthExpensesForYear',
      'getTargetingCodesForSelectedMonths'
    ]),
    ...mapActions('app', ['getUserInfo']),
    ...mapMutations('app', [ 'setSortableWidgets' ]),
    handleEditingMode (widgetId, editMode) {
      this.widgets.find(w => w.id === widgetId).edit = editMode
    },
    /**
     * Get expense based to Id and Year
     * params:
     * - id
     * - budgets
     * - year
     */
    getValueWithIdAndYear (id, budgets, year) {
      const expense = budgets.filter(budget => {
        if (budget.idExpense === id && budget.year === year) {
          return true
        }
        return false
      })
      return expense.length ? expense : [{ amount: 0 }]
    },
    getCalculatedAmounts (expense, month, squareMonthDivider, year) {
      return this.getValueWithIdYearAndMonth(
        expense.id,
        this.portfolio.monthExpenseBudgets,
        year,
        month.value
      ).reduce((acc, cur) => {
        acc.amount += cur.amount
        return acc
      }, { amount: 0}).amount / squareMonthDivider
    },
    getValueWithIdYearAndMonth (id, budgets, year, month) {
      const expense = budgets.filter(budget => {
        if (
          budget.idExpense === id &&
          budget.year === year &&
          budget.month === month
        ) {
          return true
        }
        return false
      })
      return expense.length ? expense : [{ amount: 0 }]
    },
    getAllocatedRealizationsValuesWithIdYearAndMonth (expenseId, budgets, year, month) {
      let totalExpense = 0
      budgets.map(budget => {
          budget.expenses.map(expense => {
            if(expense.id === expenseId) {
              const expenseCostsSubSum = expense.costs.reduce((total, cost) => {
                const realizationMonth = new Date(cost.realizationMonth)
                if (realizationMonth.getFullYear() === year &&
                realizationMonth.getMonth() + 1 === month) {
                  total += cost.value
                }
              return total
              }, 0)
            totalExpense += expenseCostsSubSum
            }
          })
      })
      return { amount: totalExpense }
    },
    async saveChanges (data, rentType) {
      this.isSaving = true
      if (rentType === 'internalrent_budgeting_and_realization.maintenance_budgeting_by_expenses' || rentType === 'internalrent_budgeting_and_realization.triplenet_budgeting_by_expenses') {
          const budgetingData = {}
          this.inspectionMonths.forEach(month => {
            const modifiedData = data.map(budget => {
              return budget['month_' + month]
            })
            budgetingData[month] = modifiedData
          })
          await Promise.all(Object.keys(budgetingData).map(async month => {
            const budgetingParams = {
              portfolioId: this.portfolio.id,
              year: this.expenseYears.thisYear,
              month: month,
              budgets: budgetingData[month]
            }
            await this.updateBudgetsForYear(budgetingParams)
          }))
      } else {
        var otherItems = []

        if (rentType === 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_triple_net_rent') {
          otherItems = this.budgetingAndRealisationWidgets.find(w => w.id === 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_maintenance_charges').data.items
        } else if (
          rentType === 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_maintenance_charges'
        ) {
          otherItems = this.budgetingAndRealisationWidgets.find(w => w.id === 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_triple_net_rent').data.items
        }
        const realisationData = {}
        this.inspectionMonths.forEach(month => {
          const modifiedData = data.map(expense => {
            return expense['month_' + month]
          })
          const otherData = otherItems.map(item => {
            return {
              amount: item['month_' + month],
              idExpense: item.idExpense
            }
          })
          realisationData[month] = [...modifiedData, ...otherData]
        })
        await Promise.all(Object.keys(realisationData).map(async month => {
          const realisationParams = {
            portfolioId: this.portfolio.id,
            year: this.inspectionYear,
            month: month,
            expenses: realisationData[month]
          }
          await this.setMonthExpensesForYear(realisationParams)
        }))
      }
      await this.getPortfolio({
        portfolioId: this.portfolio.id,
        current_year: this.inspectionYear,
        from_allocation_code: false
      })
      this.updateWidgetData()
      this.isSaving = false
      this.handleEditingMode(rentType, false)
    },
    async getWidgetOrder () {
      this.widgetOrder = this.userWidgets.filter(w => w.id !== -1)
      const filteredWidgets = this.widgets.filter(w => this.widgetOrder.find(wo => wo.name === w.id))
      const order = this.widgetOrder.map(wo => wo.name)
      const sortedWidgets = filteredWidgets.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })
      sortedWidgets.map(sw => {
        if (this.widgetOrder.find(wo => wo.name === sw.id).isOpen !== null) {
          sw.isOpen[0] = !!this.widgetOrder.find(wo => wo.name === sw.id).isOpen
        } else if (typeof sw.isOpen === 'undefined') {
          sw.isOpen[0] = true
        }
      })

      if (sortedWidgets.length > 0) {
        this.budgetingAndRealisationWidgets = sortedWidgets
      }
      this.setSortableWidgets(false)
    },
    async setWidgetOrder () {
      const currentChanges = this.budgetingAndRealisationWidgets
      const newOrder = currentChanges.map(widget => widget.id)
      const newWidgetOrder = this.widgetOrder.sort((a, b) => {
        return newOrder.indexOf(a.name) - newOrder.indexOf(b.name)
      })
      newWidgetOrder.map(wo => {
        if(currentChanges.find(cc => cc.id === wo.name)) {
          wo.isOpen = currentChanges.find(cc => cc.id === wo.name).isOpen[0]
        }
      })
      
      await this.$rambollfmapi.accounts.widgets.put(newWidgetOrder)
      this.getUserInfo()
      this.setSortableWidgets(false)
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.filters_saved_and_closed'))
    },
    updateWidgetData () {
      this.widgets.forEach(w => {
        w.data = this.getWidgetData(w.id)
      })
    },
    getWidgetData (widget) {
      if(widget === 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_maintenance_charges') {
        return this.getMaintenanceChargesTableData()
      } else if ( widget === 'internalrent_budgeting_and_realization.realization_monitoring_by_expenses_triple_net_rent') {
        return this.getTripleNetRentTableData()
      } else if (widget === 'internalrent_budgeting_and_realization.maintenance_budgeting_by_expenses') {
        return this.getMaintenanceBudgetingTableData()
      } else if ( widget === 'internalrent_budgeting_and_realization.triplenet_budgeting_by_expenses') {
        return this.getTripleNetBudgetingTableData()
      } else if ( widget === 'internalrent_budgeting_and_realization.swarmed_expenses') {
        return this.getSwarmedExpensesData()
      } else {
        return {}
      }
    },
    getMaintenanceChargesTableData () {
      const title =
        this.$t('internalrent_budgeting_and_realization.realization_monitoring_by_expenses_maintenance_charges')
        
      var headers = [
        { text: '', value: 'id' },
        {
          text: this.$t('internalrent.cost_variety'),
          value: 'name',
          editable: false
        },
        {
          text:
            `${this.inspectionYear - 2}` +
            ' ' +
            this.$t('internalrent.realisation'),
          value: 'previousYearRealization',
          editable: false,
          format: 'Euro'
        },
        {
          text:
            `${this.inspectionYear - 1}` +
            ' ' +
            this.$t('internalrent.realisation'),
          value: 'lastYearRealization',
          editable: false,
          format: 'Euro'
        },
        {
          text: `${this.inspectionYear}` + ' ' + this.$t('internalrent.budget'),
          value: 'thisYearBudget',
          editable: false,
          format: 'Euro'
        },
        {
          text:
            `${this.inspectionYear}` +
            ' ' +
            this.$t('internalrent.realisation_until_now'),
          value: 'thisYearRealisation',
          editable: false,
          format: 'Euro'
        }
      ]

      const squareMonthDivider = !this.portfolio.grossRent ? this.inspectionMonths.length : 1

      const items = this.expenses
        .filter(expense => {
          if (expense.selectable === false) {
            return false
          } else if (expense.tripleNet) {
            return false
          }
          return true
        })
        .map((expense, idx) => {
          const id = idx + 1
          const idExpense = expense.id
          const name = expense.name

          const previousYearRealization = this.getValueWithIdAndYear(
            expense.id,
            this.portfolio.monthExpenses,
            this.expenseYears.previousYear
          ).reduce((acc, cur) => {
            acc.amount += cur.amount
            return acc
          }, { amount: 0}).amount / squareMonthDivider

          const lastYearRealization = this.getValueWithIdAndYear(
            expense.id,
            this.portfolio.monthExpenses,
            this.expenseYears.lastYear
          ).reduce((acc, cur) => {
            acc.amount += cur.amount
            return acc
          }, { amount: 0}).amount / squareMonthDivider
          
          var thisYearBudget = 0

          this.months.forEach(month => {
            thisYearBudget += this.getCalculatedAmounts(expense, month, squareMonthDivider, this.expenseYears.thisYear)
          })

          const thisYearRealisation = this.getValueWithIdAndYear(
            expense.id,
            this.portfolio.monthExpenses,
            this.expenseYears.thisYear
          ).reduce((acc, cur) => {
            acc.amount += cur.amount
            return acc
          }, { amount: 0}).amount / squareMonthDivider

          var object = {
            id,
            idExpense,
            name,
            previousYearRealization,
            lastYearRealization,
            thisYearBudget,
            thisYearRealisation
          }

          this.inspectionMonths.forEach(month => {
            object['month_' + month] = this.getValueWithIdYearAndMonth(
              expense.id,
              this.portfolio.monthExpenses,
              this.expenseYears.thisYear,
              month
            )[0].amount
          });
          return object
        })
      var footers = [
        {},
        {
          text: this.$t('Totals')
        },
        {
          text: items
            .map(i => Number(i.previousYearRealization))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        },
        {
          text: items
            .map(i => Number(i.lastYearRealization))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        },
        {
          text: items
            .map(i => Number(i.thisYearBudget))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        },
        {
          text: items
            .map(i => Number(i.thisYearRealisation))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        }
      ]
      const sortBy = []
      const sortDesc = [ true ]
      const itemsPerPage = 19

      this.inspectionMonths.forEach(month => {
        headers.push(
          {
          text: this.months.find(m => m.value === month).text,
          value: 'month_' + month,
          editable: true,
          format: 'Euro'
          }
        )
        footers.push(
          {
          text: items
            .map(i => Number(i['month_' + month]))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
          }
        )
      });
      return { title, headers, items, footers, sortBy, sortDesc, itemsPerPage }
    },
    getTripleNetRentTableData () {
      const title =
        this.$t('internalrent_budgeting_and_realization.realization_monitoring_by_expenses_triple_net_rent')

      var headers = [
        { text: '', value: 'id' },
        {
          text: this.$t('internalrent.cost_variety'),
          value: 'name',
          editable: false
        },
        {
          text:
            `${this.inspectionYear - 2}` +
            ' ' +
            this.$t('internalrent.realisation'),
          value: 'previousYearRealization',
          editable: false,
          format: 'Euro'
        },
        {
          text:
            `${this.inspectionYear - 1}` +
            ' ' +
            this.$t('internalrent.realisation'),
          value: 'lastYearRealization',
          editable: false,
          format: 'Euro'
        },
        {
          text: `${this.inspectionYear}` + ' ' + this.$t('internalrent.budget'),
          value: 'thisYearBudget',
          editable: false,
          format: 'Euro'
        },
        {
          text:
            `${this.inspectionYear}` +
            ' ' +
            this.$t('internalrent.realisation_until_now'),
          value: 'thisYearRealisation',
          editable: false,
          format: 'Euro'
        }
      ]
      
      const squareMonthDivider = !this.portfolio.grossRent ? this.inspectionMonths.length : 1
      
      const items = this.expenses
        .filter(expense => {
          if (expense.selectable === false) {
            return false
          } else if (expense.tripleNet) {
            return true
          }
          return false
        })
        .map((expense, idx) => {
          const id = idx + 1
          const idExpense = expense.id
          const name = expense.name

          const previousYearRealization = this.getValueWithIdAndYear(
            expense.id,
            this.portfolio.monthExpenses,
            this.expenseYears.previousYear
          ).reduce((acc, cur) => {
            acc.amount += cur.amount
            return acc
          }, { amount: 0}).amount / squareMonthDivider

          const lastYearRealization = this.getValueWithIdAndYear(
            expense.id,
            this.portfolio.monthExpenses,
            this.expenseYears.lastYear
          ).reduce((acc, cur) => {
            acc.amount += cur.amount
            return acc
          }, { amount: 0}).amount / squareMonthDivider

          var thisYearBudget = 0

          this.months.forEach(month => {
            thisYearBudget += this.getCalculatedAmounts(expense, month, squareMonthDivider, this.expenseYears.thisYear)
          })

          const thisYearRealisation = this.getValueWithIdAndYear(
            expense.id,
            this.portfolio.monthExpenses,
            this.expenseYears.thisYear
          ).reduce((acc, cur) => {
            acc.amount += cur.amount
            return acc
          }, { amount: 0}).amount / squareMonthDivider

          var object = {
            id,
            idExpense,
            name,
            previousYearRealization,
            lastYearRealization,
            thisYearBudget,
            thisYearRealisation
          }
          this.inspectionMonths.forEach(month => {
            object['month_' + month] = this.getValueWithIdYearAndMonth(
              expense.id,
              this.portfolio.monthExpenses,
              this.expenseYears.thisYear,
              month
            )[0].amount
          });
          return object
        })
      var footers = [
        {},
        {
          text: this.$t('Totals')
        },
        {
          text: items
            .map(i => Number(i.previousYearRealization))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        },
        {
          text: items
            .map(i => Number(i.lastYearRealization))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        },
        {
          text: items
            .map(i => Number(i.thisYearBudget))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        },
        {
          text: items
            .map(i => Number(i.thisYearRealisation))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        }
      ]
      const sortBy = []
      const sortDesc = [true]
      const itemsPerPage = 19

      this.inspectionMonths.forEach(month => {
        headers.push(
          {
          text: this.months.find(m => m.value === month).text,
          value: 'month_' + month,
          editable: true,
          format: 'Euro'
          }
        )
        footers.push(
          {
          text: items
            .map(i => Number(i['month_' + month]))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
          }
        )
      });
      return { title, headers, items, footers, sortBy, sortDesc, itemsPerPage }
    },
    getMaintenanceBudgetingTableData () {
      const title = this.$t('internalrent.maintenance_budgeting_by_expenses')
      var headers = [
        { text: '', value: 'id' },
        {
          text: this.$t('internalrent.cost_variety'),
          value: 'name',
          editable: false
        },
        {
          text:
            `${this.expenseYears.thisYear}` +
            ' ' +
            this.$t('internalrent.budget'),
          value: 'thisYearBudget',
          editable: false,
          format: 'Euro'
        }
      ]
      
      const squareMonthDivider = !this.portfolio.grossRent ? this.inspectionMonths.length : 1

      const items = this.expenses
        .filter(expense => {
          if (expense.selectable === false) {
            return false
          } else if (expense.tripleNet) {
            return false
          }
          return true
        })
        .map((expense, idx) => {
          const id = idx + 1
          const idExpense = expense.id
          const name = expense.name
          var thisYearBudget = 0
          // new value parameter for easier expense modification in Expensetable
          var expenseValue = 0

          this.months.forEach(month => {
            thisYearBudget += this.getCalculatedAmounts(expense, month, squareMonthDivider, this.expenseYears.thisYear)
          })

          var object = {
            id,
            idExpense,
            name,
            thisYearBudget,
            expenseValue
          }
          this.inspectionMonths.forEach(month => {
            object['month_' + month] = this.getValueWithIdYearAndMonth(
              expense.id,
              this.portfolio.monthExpenseBudgets,
              this.expenseYears.thisYear,
              month
            )[0].amount
          });
          return object
        })
      var footers = [
        {},
        {
          text: this.$t('Totals')
        },
        {
          text: items
            .map(i => Number(i.thisYearBudget))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        }
      ]
      const sortBy = []
      const sortDesc = true
      const itemsPerPage = 19

      this.inspectionMonths.forEach(month => {
        headers.push(
          {
          text: this.months.find(m => m.value === month).text,
          value: 'month_' + month,
          editable: true,
          format: 'Euro'
          }
        )
        footers.push(
          {
          text: items
            .map(i => Number(i['month_' + month]))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
          }
        )
      });
      return { title, headers, items, footers, sortBy, sortDesc, itemsPerPage }
    },
    getTripleNetBudgetingTableData () {
      const title = this.$t('internalrent.triplenet_budgeting_by_expenses')
      var headers = [
        { text: '', value: 'id' },
        {
          text: this.$t('internalrent.cost_variety'),
          value: 'name',
          editable: false
        },
        {
          text:
            `${this.expenseYears.thisYear}` +
            ' ' +
            this.$t('internalrent.budget'),
          value: 'thisYearBudget',
          editable: false,
          format: 'Euro'
        }
      ]
      
      const squareMonthDivider = !this.portfolio.grossRent ? this.inspectionMonths.length : 1
      
      const items = this.expenses
        .filter(expense => {
          if (expense.selectable === false) {
            return false
          } else if (expense.tripleNet) {
            return true
          }
          return false
        })
        .map((expense, idx) => {
          const id = idx + 1
          const idExpense = expense.id
          const name = expense.name
          var thisYearBudget = 0
          // new value parameter for easier expense modification in Expensetable
          var expenseValue = 0

          this.months.forEach(month => {
            thisYearBudget += this.getCalculatedAmounts(expense, month, squareMonthDivider, this.expenseYears.thisYear)
          })

          var object = {
            id,
            idExpense,
            name,
            thisYearBudget,
            expenseValue
          }
          this.inspectionMonths.forEach(month => {
            object['month_' + month] = this.getValueWithIdYearAndMonth(
              expense.id,
              this.portfolio.monthExpenseBudgets,
              this.expenseYears.thisYear,
              month
            )[0].amount
          });
          return object
        })
      var footers = [
        {},
        {
          text: this.$t('Totals')
        },
        {
          text: items
            .map(i => Number(i.thisYearBudget))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        }
      ]
      const sortBy = []
      const sortDesc = true
      const itemsPerPage = 19

      this.inspectionMonths.forEach(month => {
        headers.push(
          {
          text: this.months.find(m => m.value === month).text,
          value: 'month_' + month,
          editable: true,
          format: 'Euro'
          }
        )
        footers.push(
          {
          text: items
            .map(i => Number(i['month_' + month]))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
          }
        )
      });
      return { title, headers, items, footers, sortBy, sortDesc, itemsPerPage }
    },
    getSwarmedExpensesData ( ) {
      const title = this.$t('internalrent_budgeting_and_realization.swarmed_expenses')
      var headers = [
        { text: '', value: 'id' },
        {
          text: this.$t('internalrent.cost_variety'),
          value: 'name',
          editable: false
        },
        {
          text:
            `${this.expenseYears.nextYear}` +
            ' ' +
            this.$t('internalrent.budget'),
          value: 'nextYearBudget',
          editable: false,
          format: 'Euro'
        }
      ]
      const items = this.expenses
        .filter(expense => {
          if (expense.selectable === false) {
            return false
          }
          return true
        })
        .map((expense, idx) => {
          const id = idx + 1
          const idExpense = expense.id
          const name = expense.name
          var nextYearBudget = 0
          // new value parameter for easier expense modification in Expensetable
          var expenseValue = 0

          var object = {
            id,
            idExpense,
            name,
            nextYearBudget,
            expenseValue
          }
          this.inspectionMonths.forEach(month => {
            const monthExpenses = this.targetingCodesForMonths.find(b => b.month === month)
            object['month_' + month] = this.getAllocatedRealizationsValuesWithIdYearAndMonth(
              expense.id,
              monthExpenses && monthExpenses.targetingCodes ? monthExpenses.targetingCodes : [],
              this.expenseYears.nextYear,
              month
            ).amount
          })

          // calculate total budget for year from expenses month summaries
          Object.keys(object).map(key => {
            if(key.includes('month_')) {
              object.nextYearBudget += object[key]
            }
          })
          return object
        })
      var footers = [
        {},
        {
          text: this.$t('Totals')
        },
        {
          text: items
            .map(i => Number(i.nextYearBudget))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
        }
      ]
      const sortBy = []
      const sortDesc = true
      const itemsPerPage = 19

      this.inspectionMonths.forEach(month => {
        headers.push(
          {
          text: this.months.find(m => m.value === month).text,
          value: 'month_' + month,
          editable: true,
          format: 'Euro'
          }
        )
        footers.push(
          {
          text: items
            .map(i => Number(i['month_' + month]))
            .reduce((acc, cur) => Number(acc) + Number(cur), 0),
          format: 'Euro'
          }
        )
      });
      return { title, headers, footers, items, sortBy, sortDesc, itemsPerPage }
    },
    openBudgetCreation (expensesData) {
      this.budgetCreationItems = expensesData.map( expenseData => {
        const expenseName = this.expenses.find(expense => expense.id === expenseData.idExpense).name
        return {
          ...expenseData, scalingPercent: 0, expenseName
        }
      })
      this.budgetCreationDialogOpen = true
    },
    closeBudgetCreation () {
      this.budgetCreationDialogOpen = false
      this.budgetCreationItems = []
    },
    async createBudget () {
      if(confirm(this.$t('internalrent.budget_creation_confirmation'))) {
        this.creatingBudget = true
        const budgetingData = {}
        this.inspectionMonths.forEach(month => {
          const modifiedData = this.budgetCreationItems.map(budget => {
            let scaledBudgetForExpenseType = 0
            //Calculation when negative percent is given
            if(Math.sign(budget.scalingPercent) ===  -1) {
              scaledBudgetForExpenseType = budget['month_' + month].amount * ((100 + Number(budget.scalingPercent)) / 100)
            } else {
              // Calculation when 0 or bigger scaling percent is given
              scaledBudgetForExpenseType = budget['month_' + month].amount * (budget.scalingPercent / 100)
            }
            budget['month_' + month].amount = parseFloat(scaledBudgetForExpenseType.toFixed(2))
            return budget['month_' + month]
          })
          budgetingData[month] = modifiedData
        })
        
        await Promise.all(Object.keys(budgetingData).map(async month => {
          const budgetingParams = {
            portfolioId: this.portfolio.id,
            year: this.expenseYears.nextYear,
            month: month,
            budgets: budgetingData[month]
          }
          await this.updateBudgetsForYear(budgetingParams)
        }))

        await this.getPortfolio({
          portfolioId: this.portfolio.id,
          current_year: this.inspectionYear,
          from_allocation_code: false
        })

        this.updateWidgetData()
        this.closeBudgetCreation()
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.budget_added'))
        this.creatingBudget = false
      }
    }
  }
}
</script>
<style scoped>
.form-field {
  margin-top: 1.5em !important;
  margin-left: 1.5em !important;
}
</style>
