<template>
  <!-- SINGLE VEGETATION BOX -->
  <v-expansion-panels
    v-if="selectedVegetationFromViewer.length"
    style="margin-top: 0.5em"
    :value="selectedVegetationFromViewer.length ? [0] : null"
    multiple
  >
    <v-expansion-panel
      v-if="selectedVegetationFromViewer.length === 1"
    >
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
          no-gutters
        >
          <v-icon>grass</v-icon>
          {{ $t('Vegetation area') + ': ' + selectedVegetationFromViewer[0].id_plantarea }}
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="metadata.vegetation"
          :value="selectedVegetationFromViewer[0]"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- OUTDOORS AS EXPAND -->
    <v-expansion-panel
      v-if="selectedVegetationFromViewer.length > 1"
    >
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
          no-gutters
        >
          <v-icon>emoji_nature</v-icon>
          {{ $t('Vegetation areas') }}
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list
          dense
          class="info-box"
        >
          <v-list-item class="tile">
            <v-list-item-content class="tile-content">
              Valinta
            </v-list-item-content>
            <v-list-item-content
              class="tile-content"
            >
              {{ selectedVegetationFromViewer.length + ' Kasvialuetta' }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div
          v-for="o in selectedVegetationFromViewer"
          :key="o.id_plantarea"
          class="inner-expansion-container"
        >
          <v-expansion-panel class="inner-expansion">
            <v-expansion-panel-header>
              {{ o.id_plantarea }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MetadataForm
                :value="o"
                :metadata="metadata.vegetation"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import MetadataForm from '../../components/MetadataForm.vue'

export default {
  name: 'VegetationBox',
  components: { MetadataForm },
  props: {
    metadata: { default: null },
    selectedVegetationFromViewer: { default: null },
    show: { default: null }
  }
}
</script>

<style>
</style>
