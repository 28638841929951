<template>
  <v-col>
    <v-container v-if="task.id">
      <v-row
        v-if="!commentDialog"
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('Comments') }}
          </h4>
        </v-col>
      </v-row>
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h5 class="text--secondary">
            {{ $t('Write comment') }}
          </h5>
        </v-col>
      </v-row>
      <v-row
        class="section fill-height"
      >
        <v-col class="section-textfield">
          <v-textarea
            v-model="newComment.comment"
            :placeholder="$t('Write comment here')"
            :disabled="task.finished"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!commentDialog"
        class="section fill-height"
      >
        <v-col
          class="buttons justify-end"
        >
          <v-btn
            small
            text
            outlined
            class="v-btn--outline"
            :disabled="!newComment.comment"
            @click="clearNewComment"
          >
            {{ $t('Clear') }}
          </v-btn>
          <v-btn
            small
            rounded
            depressed
            color="primary"
            :disabled="!newComment.comment || loading"
            @click="addComment"
          >
            <span v-if="!loading">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="hasComments"
        class="section"
      >
        <v-col class="section-textfield">
          <h5 class="text--secondary">
            {{ $t('Earlier comments') }}:
          </h5>
        </v-col>
      </v-row>
      <v-row
        v-if="hasComments"
        class="listsection"
      >
        <v-card
          flat
          class="overflow-y-auto"
          min-height="50"
          max-height="150"
          max-width="550"
          width="100%"
        >
          <v-card-text
            v-for="commentObj in sortedComments"
            :key="commentObj.id"
          >
            <div>
              <span class="comment">{{ commentObj.comment }}</span>
            </div>
            <div>
              <span
                class="appender"
              >{{ $t('Appended') }}
                {{
                  commentObj.submitted
                    ? new Date(commentObj.submitted).toLocaleDateString(
                      $t('Locale'),
                      options
                    )
                    : '-'
                }}
                {{ commentObj.submitter }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-spacer />
      <v-divider />
      <br>
    </v-container>
  </v-col>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'TaskComments',
  props: {
    task: Object,
    commentDialog: {
      default: () => {
        return false
      }
    }
  },
  emits: ['saveComment'],
  data () {
    return {
      newComment: {
        comment: undefined
      },
      options: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      loading: false
    }
  },
  computed: {
    sortedComments: function () {
      return [...this.task.comments].reverse()
    },
    hasComments: function () {
      return this.task.comments && this.task.comments.length > 0
    }
  },
  methods: {
    ...mapActions('ltp', ['saveNewComment']),
    addComment () {
      this.loading = true
      const newComment = this.newComment
      const params = { id: this.task.id, newComment }
      this.saveNewComment(params).then(() => this.clearNewComment())
    },
    clearNewComment () {
      this.newComment.comment = undefined
      this.loading = false
    }
  },
  watch: {
    newComment: {
      handler: function () {
        if (this.commentDialog) {
          this.$emit('saveComment', this.newComment)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.header {
  background: var(--c-color-accent) !important;
  color: #8b8b8b;
  padding-left: 0.5em;
  font-size: 1.5em;
}
.listsection {
  padding-bottom: 1em;
  background-color: white !important;
}
.buttons {
  display: flex;
}
/* .comment {
  color: #8b8b8b;
} */
.appender {
  color: #8b8b8b;
  font-size: 0.8em;
}
.grey-text {
  color: #8b8b8b;
  padding-left: 1em;
  padding-top: 0.5em;
}
.title-card {
  background: #d5e9f6;
}
</style>
