<template>
  <div v-if="$vuetify.breakpoint.mobile">
    <v-menu offset-x>
      <template #activator="{ on, attrs }">
        <v-card
          elevation="0"
          class="filterBox"
        >
          <v-btn
            v-bind="attrs"
            outlined
            x-small
            fab
            rounded
            class="filterButton"
            v-on="on"
          >
            <v-icon> filter_list </v-icon>
            <span
              v-if="filter != null"
              class="text-subtitle-2"
            >
              {{ $t(options[filter].text) }}
            </span>
          </v-btn>
        </v-card>
      </template>
      <v-list>
        <v-list-item-group
          v-model="filter"
          active-class="active"
          return-object
        >
          <v-list-item
            v-for="(listItem, index) in options"
            :key="
              Array.isArray(listItem.value)
                ? listItem.value.join() + 'idx' + index
                : listItem.value + 'idx' + index
            "
          >
            <v-list-item-title>
              {{ $t(listItem.text) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
  <div
    v-else
    style="margin: 0px 0px -45px 15px"
  >
    <v-menu offset-x>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          outlined
          x-small
          fab
          rounded
          v-on="on"
        >
          <v-icon> filter_list </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          v-model="filter"
          active-class="active"
          return-object
        >
          <v-list-item
            v-for="(listItem, index) in options"
            :key="
              Array.isArray(listItem.value)
                ? listItem.value.join() + 'idx' + index
                : listItem.value + 'idx' + index
            "
          >
            <v-list-item-title>
              {{ $t(listItem.text) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <span
      v-if="filter != null"
      class="text-subtitle-1"
    >{{
      $t(options[filter].text)
    }}</span>
  </div>
</template>
<script>
export default {
  emits: ['change'],
  components: {},
  props: {
    options: { type: Array, default: () => [] }
  },
  data () {
    return {
        filter: null
    }
  },
  computed: {},
  watch: {
    filter () {
        this.$emit("change", this.filter)
    }
  },
  methods: {},
}
</script>
<style scoped>
@media screen and (max-width: 760px) {
  .filterButton {
    margin: 0px;
    border-radius: 2em;
    width: 100%;
    justify-content: start;
  }
  .filterBox {
    margin: 1em 1.5em;
    display: flex;
    justify-content: center;
    opacity: 0.87;
  }
}
</style>
