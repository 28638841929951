import { i18n } from '../../plugins/i18n.js'

const POSITIVES = ["kyllä", "kylla", "yes", "true"]

const NEGATIVES = ["ei", "no", "false"]

export function getBooleanString (value) {
  if (value === true || value === 1) {
    return i18n.t("Yes")
  }

  if(value === false || value === 0){
    return i18n.t("No")  
  }

  return null
}

export function stringToBoolean (str) {
  if (typeof str !== "string") {
    return null
  }

  const lwrStr = str.trim().toLowerCase()

  const isPositive = POSITIVES.reduce(
    (acc, cur) => acc || lwrStr === cur,
    false
  )

  if (isPositive) {
    return true
  }

  const isNegative = NEGATIVES.reduce(
    (acc, cur) => acc || lwrStr === cur,
    false
  )

  if (isNegative) {
    return false
  }

  return null
}

export function stringToNumber (str) {
  const booleanValue = stringToBoolean(str)

  if (booleanValue === true) {
    return 1
  }

  if (booleanValue === false) {
    return 0
  }

  return null
}
