<template>
  <div>
    <v-dialog
      v-model="editDialog"
      persistent
      width="550"
    >
      <!--persistent-->
      <v-card>
        <PortfolioModificationForm
          :original="original"
          :modifying="editing"
          @close_dialog="editDialog = false"
          @refresh="refresh"
        />
      </v-card>
    </v-dialog>
    <v-col>
      <v-toolbar
        flat
        tile
      >
        <v-toolbar-title>
          <h1>{{ $t('Portfolio management') }}</h1>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            @click="editDialog = true"
          >
            <v-icon>add</v-icon>
            <span class="d-sr-only">{{ $t('Create new portfolio') }}</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col
          v-for="siteList in portfolioList"
          :key="siteList.id_portfolio"
          lg="4"
          cols="12"
        >
          <h2>
            {{ Object.keys(siteList)[0] }}
            <v-icon
              v-if="siteList.id_portfolio !== null"
              style="float: right; margin-right: 20px"
              @click="startEditing(siteList)"
            >
              edit
            </v-icon>
          </h2>
          <v-card>
            <draggable
              style="height: 75vh !important; overflow: auto"
              :list="siteList[Object.keys(siteList)[0]]"
              group="sites"
              @add="updated"
            >
              <v-btn
                v-for="site in siteList[Object.keys(siteList)[0]]"
                :key="site.id_site"
                block
                rounded
                outlined
                :text="!siteList.id_portfolio"
              >
                {{ site.name }}
              </v-btn>
            </draggable>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-row
      style="right: 0; bottom: 0; position: sticky"
    >
      <v-spacer />
      <v-btn
        v-if="modified"
        outlined
        rounded
        @click="cancel()"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        v-if="modified"
        class="primary"
        outlined
        rounded
        @click="saveChanges()"
      >
        {{ $t('Save changes') }}
      </v-btn>
    </v-row>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="null"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import PortfolioModificationForm from '../components/PortfolioModificationForm.vue'
import Alert from '../components/Alert.vue'

export default {
  name: 'PortfolioManagement',
  components: { draggable, PortfolioModificationForm, Alert },
  metaInfo () {
    return {
      title: `${this.$t('Portfolio management')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      multipleSelect: false,
      selectedSiteIds: [],
      portfolioList: {},
      availableSites: [],
      originalPortfolioList: {},
      editDialog: false,
      modified: false,
      original: undefined,
      editing: false,
      showIndicator: false,
      operationResult: null,
      indicatorMessage: null
    }
  },
  computed: {
    ...mapState('app', [
      'portfolios',
      'sites',
      'currentDate'
    ]),
    ...mapGetters('app', ['hasApplicationPermissionByName'])
  },
  watch: {
    sites: function (sites) {
      if (sites.length > 0) {
        this.availableSites = sites
      }
    },
    availableSites: function (sites) {
      this.portfolioList = []
      this.portfolios.map(p => {
        var dumb = {}
        dumb[p.label] = sites.filter(s => s.name !== null && s.name !== undefined && s.name !== '' && s.portfolio === p.id)
        dumb.id_portfolio = p.id
        this.portfolioList.push(dumb)
      })
      if (sites.filter(s => s.portfolio === null).length > 0) {
        var dumb = {}
        dumb[this.$t('Sites without portfolio')] = sites.filter(s => s.portfolio === null)
        dumb.id_portfolio = null
        this.portfolioList.push(dumb)
      }
      this.originalPortfolioList = this.portfolioList
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.showIndicator = false
        }, 4000)
      }
    }
  },
  mounted: function () {
    if (!this.hasApplicationPermissionByName('SALKKUHALLINTA')) {
      this.$router.push({ name: 'dashboard' })
    } else {
      this.refresh()
    }
  },
  methods: {
    ...mapActions('app', [
      'getPortfolios',
      'initializeParties',
      'getSites'
    ]),
    selectSite (id) {
      if (this.selectedSiteIds.find(siteId => siteId === id)) {
        this.selectedSiteIds = this.selectedSiteIds.filter(siteId => siteId !== id)
      } else if (this.multipleSelect) {
        this.selectedSiteIds.push(id)
      } else {
        this.selectedSiteIds = [id]
      }
    },
    updated () {
      this.modified = false
      for (const portfolio of this.portfolioList) {
        portfolio[Object.keys(portfolio)[0]].map( (site) => {
          if (site.portfolio !== portfolio.id_portfolio) {
            this.modified = true
           }
        })}
    },
    async saveChanges () {
      this.modified = false
      var status = null
      let modifiedPortfolios = null

      for (const portfolio of this.portfolioList) {
        portfolio[Object.keys(portfolio)[0]].map(async (site) => {
          if (site.portfolio !== portfolio.id_portfolio) {
            site.portfolio = portfolio.id_portfolio
            const sitePatch = [
              {
                value: site.portfolio,
                path: '/portfolio',
                op: 'replace'
              }
            ]
            try {
              const response = await this.$rambollfmapi.sites.patch(site.id_site, this.currentDate, site.version, sitePatch)
              if (response.status === 204) {
                modifiedPortfolios + 1
                status = 'success'
                this.alert(status)
              }
            } catch (error) {
              status = 'error'
              this.alert(status)
            }
          }
        })
      }    
    },
    startEditing (object) {
      this.original = {
        label: Object.keys(object)[0],
        id: object.id_portfolio
      }
      this.editing = true
      this.editDialog = true
    },
    async refresh (status = null) {
      this.modified = false
      this.editDialog = false
      this.editing = false
      this.original = undefined
      await this.getSites()
      this.initializeParties()
      this.getPortfolios()

      if (status !== null) {
        this.alert(status)
      }
    },
    cancel () {
      this.modified = false
      // force update
      this.availableSites = []
      this.availableSites = this.sites
    },
    alert (status) {
      this.showIndicator = false
      this.operationResult = status
      if (status === 'success') {
        this.indicatorMessage = this.$t('Save success')
      } else if (status === 'error') {
        this.indicatorMessage = this.$t('Error while saving')
      } else if (status === 'warning') {
        this.indicatorMessage = this.$t('No changes')
      }
      this.showIndicator = true
    }
  }
}
</script>
