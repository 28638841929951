<template>
  <div>
    <v-dialog
      v-model="addStructureDialog"
      persistent
      max-width="550"
    >
      <AddStructure
        :estate-id="estateId"
        @close="addStructureDialog = false"
        @saved="$emit('saved')"
      />
    </v-dialog>
    <!--This modal should be made file-->
    <v-dialog
      v-model="addBuildingDialog"
      persistent
      max-width="550"
    >
      <BaseModal
        @cancel="cancel()"
      >
        <template #title>
          {{ $t('Add building') }}
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-checkbox
              v-if="hasApplicationPermissionByName('RAKENNUKSEN_LISAYS_LUONNOS')"
              v-model="newBuilding.isDraft"
              :label="$t('Building draft')"
              class="form-field"
              hide-details
              @change="changeBuildingDraftState"
            />
            <v-text-field
              v-model.trim="newBuilding.building_name"
              :rules="[(v) => !!v || $t('Required')]"
              :label="$t('Name')"
              :hint="$t('Required')"
              persistent-hint
              required
              class="form-field"
            />
            <v-text-field
              v-if="facta_building && !factaDisabled"
              v-model="factaBuilding.building_class"
              :label="$t('Building class')"
              disabled
              class="form-field"
            />
            <v-text-field
              v-if="facta_building && !factaDisabled"
              v-model="factaBuilding.building_sub_class"
              :label="$t('Building sub class')"
              disabled
              class="form-field"
            />
            <v-text-field
              v-if="facta_building && !factaDisabled"
              v-model="factaBuilding.building_main_class"
              :label="$t('Building main class')"
              disabled
              class="form-field"
            />
            <NumericField
              v-model="changedNumbers.coordinate_x"
              :is-required="true"
              :label="$t('Coordinate x')"
              :hint="$t('Required')"
              persistent-hint
              :rules="[(v) => !!v || $t('Required')]"
              class="form-field"
              @change="updateX"
            />
            <NumericField
              v-model="changedNumbers.coordinate_y"
              :is-required="true"
              :label="$t('Coordinate y')"
              :hint="$t('Required')"
              persistent-hint
              :rules="[(v) => !!v || $t('Required')]"
              class="form-field"
              @change="updateY"
            />
            <template v-if="!newBuilding.isDraft">
              <v-text-field
                v-model.trim="newBuilding.building_code"
                :rules="[
                  (v) => !!v || $t('Required'),
                  indicatorMessage !== 'Building code is already in use.' || $t(indicatorMessage),
                ]"
                :label="$t('Building code') + ' *'"
                required
                class="form-field"
                @change="resetValidation"
              />
              <v-combobox
                v-model="newBuilding.building_usage"
                :value="newBuilding.building_usage"
                :items="usageDefs"
                :label="$t('Building usage')"
                item-text="label"
                item-value="id"
                class="form-field"
              />
              <template v-if="!factaDisabled">
                <v-row 
                  class="mt-6" 
                  no-gutters
                >
                  <v-text-field
                    v-model.trim="factaBuilding.building_tunniste"
                    :label="$t('Building tunniste')"
                  />
                  <v-btn
                    rounded
                    depressed
                    allign-right
                    class="primary mr-0"
                    @click="getBuildingData(factaBuilding.building_tunniste, 'sisainen_rakennusnumero')"
                  >
                    {{ $t('Get from facta') }}
                  </v-btn>
                </v-row>
                <v-row 
                  class="mt-2" 
                  no-gutters
                >
                  <v-text-field
                    v-model="factaBuilding.building_identifier"
                    :label="$t('Building identifier')"
                  />
                  <v-btn
                    rounded
                    depressed
                    allign-right
                    class="primary mr-0"
                    @click="getBuildingData(factaBuilding.building_identifier, 'pysyva_rakennustunnus')"
                  >
                    {{ $t('Get from facta') }}
                  </v-btn>
                </v-row>
              </template>
              <template v-if="facta_building && !factaDisabled">
                <v-text-field
                  v-model="factaBuilding.completed_year"
                  :label="$t('Completed year')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.address"
                  :label="$t('Address')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.zip_code"
                  :label="$t('Zip code')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.volume"
                  :label="$t('Volume')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.total_floor_area"
                  :label="$t('Total floor area')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.official_gross_area"
                  :label="$t('Gross area')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.energy_class"
                  :label="$t('Energy class')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="temporary"
                  :label="$t('Temporary')"
                  disabled
                  class="form-field"
                />
                <v-text-field
                  v-model="factaBuilding.temporary_until"
                  :label="$t('Temporary until')"
                  disabled
                  class="form-field"
                />
              </template>
            </template>
          </v-form>
        </template>
        <template #footer>
          <Alert
            :show="showIndicator"
            :result="operationResult"
            :message="$t(indicatorMessage)"
          />
          <v-btn
            :disabled="!valid || saving"
            depressed
            rounded
            color="primary"
            @click="save"
          >
            <span v-if="!saving">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="white"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <v-spacer />
    <v-btn
      v-if="disabled === false"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      @click="showAddStructureDialog()"
    >
      <span>{{ $t('Add structure') }}</span>
    </v-btn>
    <v-btn
      v-if="disabled === false"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      @click="showAddBuildingDialog()"
    >
      <span>{{ $t('Add building') }}</span>
    </v-btn>
    <div class="block">
      <EndHierarchyItemDialog
        level="estate"
        :id-from-parent="estateId"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import proj4 from 'proj4'
import Alert from '../components/Alert.vue'
import NumericField from '../components/NumericField.vue'
import AddStructure from '../components/AddStructure.vue'
import { mapGetters, mapState, } from 'vuex'
import BaseModal from './general/BaseModal.vue'
import EndHierarchyItemDialog from './EndHierarchyItemDialog.vue'
export default {
  emits: ['saved'],
  components: {
    Alert,
    BaseModal,
    NumericField,
    AddStructure,
    EndHierarchyItemDialog
  },
  props: ['estateId', "estateIdentifier"],
  data () {
    return {
      updatedDraftStatus: false,
      loading: false,
      facta_building: false,
      saving: false,
      valid: true,
      disabled: true,
      factaDisabled: true,
      addBuildingDialog: false,
      addStructureDialog: false,
      usageDefs: [],
      temporary: null,
      factaBuilding: {
        building_identifier: null,
        building_tunniste: null,
        completed_year: null,
        address: null,
        zip_code: null,
        volume: null,
        total_floor_area: null,
        official_gross_area: null,
        energy_class: null,
        temporary: null,
        temporary_until: null,
      },
      newBuilding: {
        building_code: null,
        building_name: '',
        building_usage: null,
        coordinate_y: 0,
        coordinate_x: 0,
        latitude: 0,
        longitude: 0,
        managed_by_intg: false,
        start_date: new Date().toISOString().split('T')[0],
        is_draft: false
      },
      changedNumbers: {
        coordinate_x: null,
        coordinate_y: null
      },

      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      commisionDateCalendarVisible: false
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'hasApplicationPermissionByName']),
    ...mapState('app', ['currentDate']),
  },
  watch: {
    'newBuilding.isDraft': function (newVal) {
      if (newVal && this.newBuilding.building_name == null) {
        this.newBuilding.building_name = 'LUONNOS - ';
      } else if (newVal && !this.newBuilding.building_name.startsWith('LUONNOS - ')) {
        this.newBuilding.building_name = 'LUONNOS - ' + this.newBuilding.building_name;
      } else if (!newVal && this.newBuilding.building_name != null && this.newBuilding.building_name.startsWith('LUONNOS - ')) {
         this.newBuilding.building_name = this.newBuilding.building_name.replace('LUONNOS - ', '');
      }
    },
    addBuildingDialog (value) {
      this.loading = true
      if (value === true) {
        this.usageDefs = this.definitionsByGroupLabel('building.purposeofuse')
        this.usageDefs.unshift({
          id: null,
          annotationGroup: null,
          label: this.$t('undefined')
        })
        this.loading = false
      }
      if(this.addStructureDialog === false){
        this.$refs.form?.resetValidation()
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  mounted () {
    this.$rambollfmapi.accounts.currentgroups().then(res => {
      res.forEach(group => {
        if (
          group.applicationPermissions.find(app => {
            return app.id === '04.02.021'
          })
        ) {
          this.disabled = false
        }
        if (
          group.applicationPermissions.find(app => {
            return app.id === '9011'
          })
        ) {
          this.factaDisabled = false
        }
      })
    })
  },
  methods: {
    showAddBuildingDialog () {
      this.addBuildingDialog = true
    },
    showAddStructureDialog () {
      this.addStructureDialog = true
    },
    formatDate (date) {
      if (!date) return null

      return moment(date).format('DD.MM.YYYY')
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    cancel () {
      this.addBuildingDialog = false
      this.facta_building = false
      this.$refs.form.reset()
    },
    resetValidation (){
      this.$refs.form.resetValidation()
      this.showIndicator = false
      this.operationResult = ''
      this.indicatorMessage = ''
    },
    updateY (field, value) {
      this.changedNumbers.coordinate_y = value
    },
    updateX (field, value) {
      this.changedNumbers.coordinate_x = value
    },
    async getBuildingData (property, propertyName) {
      if ( property === null) {
        return
      }
      this.loading = true;
      const building = await this.$rambollfmapi.buildings.facta(propertyName, property, this.estateIdentifier)
      this.facta_building = false
      Object.keys(this.factaBuilding).forEach(key => {
        delete this.factaBuilding[key];
      }) 
      if (building.building_identifier) {
        this.newBuilding.name = building.building_name ?? this.newBuilding.name
        this.newBuilding.building_usage = this.usageDefs.find(d => d.id === building.building_usage)
        this.newBuilding.managed_by_intg = true
        this.changedNumbers.coordinate_x = building.coordinate_x
        this.changedNumbers.coordinate_y = building.coordinate_y
        this.factaBuilding ={
          building_identifier: building.building_identifier,
          building_tunniste: building.building_tunniste,
          completed_year: building.completed_year,
          address: building.address,
          zip_code: building.zip_code,
          volume: building.volume,
          total_floor_area: building.total_floor_area,
          official_gross_area: building.official_gross_area,
          energy_class: building.energy_class,
          temporary: building.temporary,
          temporary_until: building.temporary_until,
          building_class: building.building_class,
          building_class_id: building.building_class_id,
          building_main_class: building.building_main_class,
          building_main_class_id: building.building_main_class_id,
          building_sub_class: building.building_sub_class,
          building_sub_class_id: building.building_sub_class_id
        } 
        this.temporary = this.factaBuilding.temporary ? this.$t('true') : this.$t('false')
        this.facta_building = true;
      }
      else {
        Object.keys(this.newBuilding).forEach(key => {
        delete this.newBuilding[key];
      })
        this.newBuilding.managed_by_intg = false
        this.newBuilding.start_date = new Date().toISOString().split('T')[0]
        this.changedNumbers.coordinate_x = null
        this.changedNumbers.coordinate_y = null
        this.showIndicator = true
        this.operationResult = 'error'
        this.indicatorMessage = building
      }
      this.loading = false;
    },
    changeBuildingDraftState (value) {
      this.newBuilding.is_draft = value
    },
    async save () {
      if (this.saving) {
        return
      }
      this.$log.info('changed number', this.changedNumbers)

      if (this.$refs.form.validate()) {
        this.saving = true
        let obj = {}
        Object.keys(this.newBuilding).forEach(key => {
          obj[key] = this.newBuilding[key];
        })
        Object.keys(this.factaBuilding).forEach(key => {
          obj[key] = this.factaBuilding[key];
        })
        const toSave = JSON.parse(JSON.stringify(obj))
        if (this.changedNumbers.coordinate_x !== null) {
          const value = Number(this.changedNumbers.coordinate_x)
          toSave.coordinate_x = value
        }

        if (this.changedNumbers.coordinate_y !== null) {
          const value = Number(this.changedNumbers.coordinate_y)
          toSave.coordinate_y = value
        }

        // coordinate conversion
        proj4.defs(
          'EPSG:3879',
          '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
        )

        const test = proj4('EPSG:4326', 'EPSG:3879').inverse([
          toSave.coordinate_x,
          toSave.coordinate_y
        ])

        if (test.length > 0) {
          toSave.longitude = test[0]
          toSave.latitude = test[1]
        }

        if (toSave.building_usage !== null) {
          toSave.building_usage = toSave.building_usage.id
        }

        if (toSave.start_date !== null) {
          toSave.start_date = new Date(this.currentDate).toISOString().split('T')[0]
        }

        await this.$rambollfmapi.estates
          .buildings(this.estateId)
          .post(toSave)
          .then(res => {
            if (res.name !== 'Error') {
              this.addBuildingDialog = false
              this.clearForm()
              this.saving = false
              this.$emit('saved')
            }
            else {
              this.saving = false
              var errorData = res.response.data
              if (errorData.message.includes('JsonPatchDocument has a flaw:')) {
                // Error message for when something is wrong with patch file
                this.showIndicator = true
                this.operationResult = 'error'
                this.indicatorMessage = 'Error during patch'
                this.saving = false
              }
              else {
                // Other error messages - buildingCode already in use, startdate not defined etc.
                this.showIndicator = true
                this.operationResult = 'error'
                this.indicatorMessage = errorData.message
                this.saving = false         
              }
            }
          })
      }
    },
    clearForm () {
      this.resetValidation()
      this.newBuilding.is_draft = false
      this.newBuilding.building_code = null
      this.newBuilding.building_name = ''
      this.newBuilding.building_usage = null
      this.newBuilding.managed_by_intg = false
      this.newBuilding.start_date = new Date().toISOString().split('T')[0]
      Object.keys(this.factaBuilding).forEach(key => {
      delete this.factaBuilding[key];
      })
      this.facta_building = false
      this.changedNumbers.coordinate_x = null
      this.changedNumbers.coordinate_y = null
    }
  }
}
</script>
<style scoped>
.block >>> .v-btn {
  width: 100%;
}
</style>
