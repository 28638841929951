<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels
          :value="[0]"
          multiple
        >
          <v-expansion-panel :class="{ 'hide-expansion-panel': noExpansionPanel }">
            <v-expansion-panel-header>
              {{ $t("Filters") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container fluid>
                <SkipTo
                  v-if="tableId"
                  :label="$t('skip-to-table')"
                  :to="'#' + tableId"
                />
                <v-row no-gutters>
                  <v-col
                    v-for="header in headers
                      .filter((h) => h.actionHeader !== true && h.hideFilter !== true)
                      .map((h) => {
                        return {
                          text: $t(h.text),
                          value: h.value,
                          isDefinition: h.isDefinition,
                          type: h.format,
                        }
                      })"
                    :key="header.value"
                    class="widthAdjust"
                  >
                    <v-text-field
                      v-if="header.type == 'Date'"
                      :value="filterValues[header.value]"
                      :label="header.text"
                      clearable
                      @change="(e) => onFilterValueChange(header.value, e)"
                      @input="(e) => onFilterValueChange(header.value, e)"
                    />
                    <v-text-field
                      v-else-if="header.type == 'Time'"
                      :value="filterValues[header.value]"
                      :label="header.text"
                      clearable
                      @change="(e) => onFilterValueChange(header.value, e)"
                      @input="(e) => onFilterValueChange(header.value, e)"
                    />
                    <v-text-field
                      v-else
                      :value="filterValues[header.value]"
                      :label="header.text"
                      :type="header.type"
                      clearable
                      @input="(e) => onFilterValueChange(header.value, e)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  emits: ['change'],
  components: {},
  props: {
    noExpansionPanel: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      default: null,
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      filterTimeouts: {},
      filterValues: {},
    }
  },
  computed: {},
  methods: {
    onFilterValueChange (header, value) {
      const nextValue = value ?? ""

      let currentTimeout = this.filterTimeouts[header]

      if (currentTimeout) {
        clearTimeout(currentTimeout.timeoutId)
        currentTimeout.timeoutId = null
        currentTimeout.value = nextValue
      } else {
        currentTimeout = {
          timeoutId: null,
          value,
        }
        this.filterTimeouts[header] = currentTimeout
      }

      currentTimeout.timeoutId = setTimeout(() => {
        const firedTimeout = this.filterTimeouts[header]
        this.filterValues[header] = firedTimeout.value
        this.$emit("change", {...this.filterValues})
      }, 1000)
    },
  },
}
</script>
<style scoped>
.filters .v-expansion-panel__body {
  padding-bottom: 0.5em;
}
.filters .v-text-field.v-input--is-label-active {
  border-width: 1px !important;
  /* color: #ff0000 !important; */
}

</style>
