<template>
  <div>
    <v-dialog
      v-model="showEditDialog"
      max-width="550"
      persistent
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <BaseModal
            @cancel="showEditDialog = false"
          >
            <template #title>
              {{ title }}
            </template>
            <template #content>
              <v-form ref="form">
                <v-container
                  class="px-2"
                >
                  <v-row
                    v-for="field in copyFields"
                    :key="field.id"
                  >
                    <validation-provider
                      v-if="!namelessSearch"
                      v-slot="{ errors }"
                      :rules="{required:!namelessSearch}"
                      slim
                    >
                      <v-autocomplete
                        v-if="field.editType === 'Select'"
                        id="id"
                        v-model="field.value"
                        :items="field.items"
                        :label="$t(field.label)"
                        :error-messages="errors"
                        item-text="label"
                        item-value="id"
                        class="form-field"
                        clearable
                      />
                    </validation-provider>
                    <template
                      v-if="field.editType === 'CheckBoxAndText'"
                    >
                      <v-checkbox
                        v-model="namelessSearch"
                        :label="$t(field.checkbox.label)"
                        class="form-field"
                      />
                      <v-expand-transition>
                        <validation-provider
                          v-if="namelessSearch"
                          v-slot="{ errors }"
                          :rules="{required:namelessSearch}"
                          slim
                        >
                          <v-text-field
                            v-model="field.value"
                            :label="$t(field.label)"
                            :maxlength="field.maxLength"
                            :error-messages="errors"
                            class="ml-2 mt-5 form-field"
                          />
                        </validation-provider>
                        <template #append>
                          <info-ball
                            :info="$t(field.info.text)"
                            right
                          />
                        </template>
                      </v-expand-transition>
                    </template>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <template #footer>
              <v-btn
                :disabled="invalid"
                :loading="saving"
                type="submit"
                rounded
                depressed
                color="primary"
                @click="save"
              >
                {{ $t('Save') }}
              </v-btn>
            </template>
          </BaseModal>
        </v-card>
      </validation-observer>
    </v-dialog>
    <div class="inline-block pb-1">
      <v-icon @click="showEditDialog = true">
        edit
      </v-icon>
      <span class="px-2 font-weight-bold">{{ title }}:</span>
      <span>{{ valuesAsString }}</span>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import lodash from 'lodash'
import InfoBall from '../InfoBall.vue'

export default {
  emits: ['save'],
  name: 'EditField',
  components: {
    BaseModal,
    InfoBall,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    values: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      copyFields: [],
      showEditDialog: false,
      saving: false,
      namelessSearch: false,
    }
  },
  computed: {
    valuesAsString () {
      if (!this.values) {
        return ''
      }
      return this.fields.map(field => {
        if (field.editType === 'Select') {
          return field.items.find(item => item.id ===this.values[field.id])?.label
        }
        if (field.editType === 'CheckBoxAndText') {
          let message = this.namelessSearch ? this.$t('nameless_search') : ''
          message += this.values[field.id] ? this.values[field.id] : ''
          return message
        }
        return ''
      }).filter(Boolean).join(', ')
    },
  },
  watch: {
    showEditDialog: {
      handler: function (value) {
        if (value) {
          this.open()
        }
      }
    },
    namelessSearch: {
      handler: function (value) {
        const index = value ? 0 : 1
        this.copyFields[index].value = null
      }
    }
  },
  mounted () {
    this.open()
  },
  methods: {
    async open () {
      this.namelessSearch = this.fields.find(field => field.checkbox)?.checkbox.value
      this.copyFields = lodash.cloneDeep(this.fields)
      this.copyFields.forEach(field => field.value = this.values ? this.values[field.id] : null)
    },
    async save () {
      this.saving = true
      this.copyFields.push({id: 'nameless_search', value: this.namelessSearch})
      this.$emit('save', this.copyFields)
      this.showEditDialog = false
      this.saving = false
    }
  }
}
</script>

<style scoped>
</style>