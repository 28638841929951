import { i18n } from '../plugins/i18n.js'

export function DisassembledAndRemovedWidget () {
  return {
    id: 'dashboard.disassembled_and_removed_reporting',
    type: 'ObjectTable',
    loading: true,
    data: {
      headers: [
        { text: i18n.t('hierarchy_removal.level'), value: 'Level', format: 'Translation' },
        { text: i18n.t('Site identifier'), value: 'SiteIdentifier' },
        { text: i18n.t('Site name without link'), value: 'SiteName' },
        { text: i18n.t('hierarchy_removal.identifier'), value: 'Identifier' },
        { text: i18n.t('hierarchy_removal.building_information'), value: 'BuildingInfoCombined'},
        { text: i18n.t('Action'), value: 'Type' },
        { text: i18n.t('hierarchy_removal.reason'), value: 'Reason' },
        { text: i18n.t('hierarchy_removal.unit_count'), value: 'UnitCount', format: 'Amount' },
        { text: i18n.t('Net floor area'), value: 'UnitArea', format: 'Area' },
        { text: i18n.t('End date'), value: 'DateOfRemoval', format: 'Date' }
      ],
      filter: {
        header: ['Level'],
        type: ['equal', 'notEqual'],
        options: [
          { text: 'All', value: [null]},
          { text: 'Site', value: ['Site'] },
          { text: 'Estate', value: ['Estate'] },
          { text: 'Building', value:['Building'] },
          { text: 'Unit', value: ['Unit'] }
        ],
      },
      sortBy: "DateOfRemoval",
      sortDesc: true,
      items: [],
      ignoreSubitemsInTable: true,
    }
  }
}