<template>
  <BaseModal
    @cancel="emit"
  >
    <template #title>
      {{ $t('Select sites') }}
    </template>
    <template #content>
      <v-row
        class="d-flex align-content-start flex-wrap elevation"
        style="padding: 5px 10px;overflow-x:hidden;"
      >
        <v-col
          v-for="site in favouriteSelections.filter(s => s.name !== null && s.name !== undefined && s.name !== '')"
          :key="site.id_site"
          class="buttonBase"
        >
          <v-btn
            block
            rounded
            outlined
            class="buttonHeight"
            @click="selectSites(site.id_site)"
          >
            <v-card
              v-if="site.name.length <= 35"
              flat
              color="transparent"
              elevation="0"
              class="short"
            >
              <v-icon
                left
              >
                star
              </v-icon>
              {{ site.name }}
            </v-card>
            <v-card
              v-else-if="site.name.length > 35 & site.name.length <= 60"
              flat
              color="transparent"
              elevation="0"
              class="medium"
            >
              <v-icon
                left
              >
                star
              </v-icon>
              {{ site.name }}
            </v-card>
            <v-card
              v-else
              flat
              color="transparent"
              elevation="0"
              class="long"
            >
              <v-icon
                left
              >
                star
              </v-icon>
              {{ site.name }}
            </v-card>
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <v-divider />
      <div
        v-for="portfolio in portfoliosWithNull"
        :key="portfolio.label"
      >
        <v-col class="section-textfield">
          <br>
          <h4 class="text--secondary">
            {{ portfolio.label }}
          </h4>
        </v-col>
        <br>
        <v-row>
          <v-row
            class="flex-wrap elevation"
            style="padding: 5px 10px;overflow-x:hidden;"
          >
            <v-col
              v-for="site in sites.filter(s => s.name !== null && s.name !== undefined && s.name !== '' && s.portfolio === portfolio.id)"
              :key="site.id_site"
              class="buttonBase"
            >
              <v-btn
                block
                rounded
                outlined
                class="buttonHeight"
                @click="selectSites(site.id_site)"
              >
                <v-card
                  v-if="site.name.length <= 35"
                  flat
                  color="transparent"
                  elevation="0"
                  class="short"
                >
                  <v-icon
                    v-if="isFavouriteSite(site.id_site)"
                    left
                  >
                    star
                  </v-icon>
                  {{ site.name }}
                </v-card>
                <v-card
                  v-else-if="site.name.length > 35 & site.name.length <= 60"
                  flat
                  color="transparent"
                  elevation="0"
                  class="medium"
                >
                  <v-icon
                    v-if="isFavouriteSite(site.id_site)"
                    left
                  >
                    star
                  </v-icon>
                  {{ site.name }}
                </v-card>
                <v-card
                  v-else
                  flat
                  color="transparent"
                  elevation="0"
                  class="long"
                >
                  <v-icon
                    v-if="isFavouriteSite(site.id_site)"
                    left
                  >
                    star
                  </v-icon>
                  {{ site.name }}
                </v-card>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from './general/BaseModal.vue'
export default {
  emits: ['site-select', 'close'],
  components: { BaseModal },
  props: { 
    sites: {
      type: Array,
      default: () => []
    },
    portfolios: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
    portfoliosWithNull () {
      if (this.sites.some(site => site.portfolio === null)) {
        const nullPortfolio = {
          label: this.$t('Sites without portfolio'),
          id: null
        }
        return [...this.portfolios, nullPortfolio]
      }
      return this.portfolios
    },
    favouriteSelections () {
      var favs = this.$store.state.app.favourites
      return this.sites.filter(s =>
        favs.includes(s.id_site)
      )
    }
  },
  mounted () {
  },
  methods: {
    selectSites (siteId) {
      const selectedSites = this.sites.filter(s => Number(s.id_site) === Number(siteId))
      this.$emit('site-select', selectedSites)
    },
    isFavouriteSite (siteId) {
      var favs = this.$store.state.app.favourites
      return favs.includes(siteId)
    },
    emit (event) {
      this.$emit('close', false)
    },
  }
}
</script>
<style scoped>
@media screen and (max-width: 960px) {
  .buttonBase {
    height: 60px !important;
  }
  .buttonHeight {
    margin-left: 0px !important;
    height: 90% !important;
  }
  .short {
    width: 95%;
  }
  .medium {
    width: 65%;
  }
  .long {
    width: 40%;
  }
}
</style>
