<template>
  <div>
    <SituationAndTargetModalController
      :modal-information="currentModal"
      :party-links="partyLinks"
      :inspection-year="inspectionYear"
      :lower-bound-quarter-dates="lowerBoundQuarterDates"
      :loading="modalLoading"
      @onClose="onCloseModal"
      @onUpdate="onUpdate"
    />
    <v-expansion-panels
      :value="minimized"
      multiple
    >
      <v-expansion-panel
        class="no-padding"
      >
        <v-expansion-panel-header>
          {{ title }}
          <div
            class="justify-end d-flex mr-3"
          >
            <InfoBall
              :info="$t('leasing.prediction_hover_info')"
              top
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="text-align: center; padding: 2em;"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
            />
          </div>
          <div
            v-else
          >
            <v-dialog
              v-model="showTableDataDialog"
              fullscreen
              hide-overlay
            >
              <v-card>
                <div>
                  <v-btn
                    large
                    icon
                    absolute
                    right
                    style="margin-top: 0.8em"
                    @click="closeViewData"
                  >
                    <v-icon>close</v-icon>
                    <span class="d-sr-only">{{ $t('Close') }}</span>
                  </v-btn>
                  <v-card-title
                    primary-title
                    style="width:80%"
                  >
                    <h1>
                      {{ tableData.title }}
                    </h1>
                  </v-card-title>
                </div>
                <v-col>
                  <Table
                    :rows="tableData.rows"
                    :headers="tableData.headers"
                  />
                </v-col>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="
                shownHeaders.map((h) => {
                  return {
                    text: $t(h.text),
                    value: h.value,
                    align: 'right',
                    type: h.type,
                    sortable: false
                  }
                })
              "
              :items="arrayOfItems"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
              class="elevation-0"
            >
              <template
                #item="props"
              >
                <tr
                  :key="props.item.id"
                >
                  <template v-for="header in shownHeaders">
                    <td
                      v-if="header.value === 'text'"
                      :key="header.value"
                      :class="{'bold': isBold(props.item)}"
                      class="cell"
                    >
                      <v-icon
                        v-if="props.item.type === 'total'"
                        :color="props.item.color"
                        small
                      >
                        circle
                      </v-icon>
                      <v-icon
                        v-else-if="props.item.type === 'net_total'"
                        :color="props.item.color"
                        small
                        class="rotated"
                      >
                        square
                      </v-icon>
                      <v-icon
                        v-else-if="props.item.type === 'ending_total'"
                        color="rgba( 255, 255, 255, 0)"
                        small
                      >
                        square
                      </v-icon>
                      <v-icon
                        v-else
                        :color="props.item.color"
                        small
                      >
                        square
                      </v-icon>
                      {{ formatData(props.item, header.value) }}
                    </td>
                    <td
                      v-else-if="header.value === 'prediction' && showTooltip(props.item, 'terminated_permanent')"
                      :key="header.value"
                      :class="{
                        'col-align-right': isRightAligned(currentFormat),
                        'empty-cell': isEmptyCell(props.item, header.value),
                        'bold': isBold(props.item),
                        'linked': isLinked(props, header)}"
                      class="cell"
                      @click="isLinked(props, header) ? onCellClick(props, header) : null"
                    >
                      <v-tooltip
                        max-width="400"
                        right
                      >
                        <template #activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ formatData(props.item, header.value, currentFormat) }}
                          </div>
                        </template>
                        <div>{{ $t('leasing.terminated_permanent_tooltip') }}</div>
                      </v-tooltip>
                    </td>
                    <td
                      v-else-if="header.value === 'currentFree' && showTooltip(props.item, 'net_total')"
                      :key="header.value"
                      :class="{
                        'col-align-right': isRightAligned(currentFormat),
                        'empty-cell': isEmptyCell(props.item, header.value),
                        'bold': isBold(props.item)}"
                      class="cell"
                    >
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ formatData(props.item, header.value, currentFormat) }}
                          </div>
                        </template>
                        <div>
                          <h2 class="pb-2">
                            {{ $t('leasing.currently_free_prediction') }}
                          </h2>
                          <p>
                            {{ $t('leasing.calculated_estimation_end_of_year') }}
                            <br>
                            <span class="font-italic">
                              {{ $t('leasing.currentFree') }}
                              <br>
                              - {{ $t('leasing.confirmed') }}
                              <br>
                              + {{ $t('leasing.ending_fixed') }} {{ $t('leasing.prediction') }}
                              <br>
                              + {{ $t('leasing.terminated_permanent') }} {{ $t('leasing.prediction') }}
                            </span>
                          </p>
                        </div>
                      </v-tooltip>
                    </td>
                    <td
                      v-else
                      :key="header.value"
                      :class="{
                        'col-align-right': isRightAligned(currentFormat),
                        'empty-cell': isEmptyCell(props.item, header.value),
                        'bold': isBold(props.item),
                        'linked': isLinked(props, header)}"
                      class="cell"
                      @click="isLinked(props, header) ? onCellClick(props, header) : null"
                    >
                      {{ formatData(props.item, header.value, currentFormat) }}
                    </td>
                  </template>
                </tr>
              </template>
              <template
                slot="footer.page-text"
              >
                <v-btn
                  v-if="!data.noBrowse"
                  small
                  outlined
                  rounded
                  @click="viewData(title, data.browseHeaders, items)"
                >
                  {{ $t('Browse') }}
                </v-btn>
              </template>
              <!-- Slot for filtering the contents. Filter options can be added in the widget object (see widgets/leasing f.ex.) -->
              <template
                v-if="data.filter"
                #footer
              >
                <div
                  v-if="$vuetify.breakpoint.mobile"
                >
                  <v-menu
                    offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <v-card
                        elevation="0"
                        class="filterBox"
                      >
                        <v-btn
                          v-bind="attrs"
                          outlined
                          x-small
                          fab
                          rounded
                          class="filterButton"
                          v-on="on"
                        >
                          <v-icon>
                            filter_list
                          </v-icon>
                          <span
                            v-if="currentSet != null"
                            class="text-subtitle-2"
                          >
                            {{ $t(currentSet.text) }}
                          </span>
                        </v-btn>
                      </v-card>
                    </template>
                    <v-list>
                      <v-list>
                        <v-list-item
                          v-for="option in data.filter.options"
                          :key="option.text"
                          :class="{ 'active': currentSet.text === option.text}"
                          @click="changeCurrentSet(option)"
                        >
                          <v-list-item-title>
                            {{ $t(option.text) }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-menu>
                </div>
                <div
                  v-else
                  style="margin: 0px 0px -45px 15px"
                >
                  <v-menu
                    offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        outlined
                        x-small
                        fab
                        rounded
                        v-on="on"
                      >
                        <v-icon>
                          filter_list
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list>
                        <v-list-item
                          v-for="option in data.filter.options"
                          :key="option.text"
                          :class="{ 'active': currentSet.text === option.text}"
                          @click="changeCurrentSet(option)"
                        >
                          <v-list-item-title>{{ $t(option.text) }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-menu>
                  <span
                    v-if="currentSet != null"
                    class="text-subtitle-1"
                  >{{ $t(currentSet.text) }}</span>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import helpers from '../../helpers'
import { calculateTotal, calculateNetTotals, calculateConfirmedTotal, calculateConfirmedEndedTotal } from '../../helpers/leasing/situationAndTarget.js'
import Table from '../Table.vue'
import SituationAndTargetModalController from "./Modals/SituationAndTargetModalController.vue"
import InfoBall from './../InfoBall.vue'
import { hiddenCells, areaLinkedCells, rentalTypeLinkedCells } from '../../constants/leasing/situationAndTarget.js'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'SituationAndTarget',
  components: {
    Table,
    SituationAndTargetModalController,
    InfoBall
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentSet: {
      type: Object,
      default: null
    },
    currentGroup: {
      type: Object,
      default: null
    },
    partyLinks: {
      type: Array,
      default: () => []
    },
    targetCoefficiency: {
      type: Number,
      default: 1
    },
    inspectionYear: {
      type: Number,
      default: 0
    },
    lowerBoundQuarterDates: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update', 'changeSet'],
  data () {
    return {
      minimized: [0],
      showTableDataDialog: false,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {
        descending: this.data.descending ? this.data.descending : false,
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 5,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        descending: this.data.descending ? this.data.descending : false,
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 100,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      arrayOfItems: [],
      currentFormat: 'Area',
      currentModal: null,
      modalLoading: false,
    }
  },
  computed: {
    ...mapState('app', ['currentDate', 'buildingIds']),
    ...mapGetters('app', ['definitionsByGroupLabel']),
    items: function () {
      if (!this.data.items) return []
      const items = []
      if (Object.keys(this.data.items).length > 0 && this.currentSet != null && this.currentGroup != null) {
        const type = this.currentSet.value

        const itemsObject = this.data.items[type]

        const itemsArray =  Object.keys(itemsObject).filter(key => key !== 'startTarget' && key !== 'startCurrentFree' && key !== "net_total").map((key) => {

          let confirmed = null
          if(this.showCell(this.currentGroup.value, type, "confirmed", key)) {
            if(key === "ending_fixed" || key === "ending_total" || key === "terminated_permanent"){
              confirmed = calculateConfirmedEndedTotal(itemsObject[key].confirmed)
            } else {
              confirmed = calculateConfirmedTotal(itemsObject[key].confirmed, this.currentGroup)
            }
          }

          const prediction = this.showCell(this.currentGroup.value, type, "prediction", key) ? calculateTotal(itemsObject[key].prediction, this.currentGroup) : null
          const target = this.showCell(this.currentGroup.value, type, "target", key ) ?  calculateTotal(itemsObject[key].target, this.currentGroup, this.targetCoefficiency) : null
          const currentFree = this.showCell(this.currentGroup.value, type, "currentFree", key ) ? calculateTotal(itemsObject[key].currentFree, this.currentGroup) : null

          return {
            order: itemsObject[key].order,
            type: key,
            classifications: itemsObject[key].classifications,
            text: this.$t(itemsObject[key].text),
            color: itemsObject[key].color,
            confirmed,
            prediction,
            target,
            currentFree,
            subItems: itemsObject[key].subArrays,
          }
        })


        if(itemsObject.net_total){
          itemsArray.push({
            order: itemsObject.net_total.order,
            type: "net_total",
            text: this.$t(itemsObject.net_total.text),
            color: itemsObject.net_total.color,
            ...calculateNetTotals(itemsArray, this.currentGroup.value, type, this.currentDate)
          })
        }

        itemsArray.sort((a,b) => a.order - b.order).map((item, idx) => {
          let thisItem = {}
          thisItem.id = idx
          thisItem.type = item.type
          thisItem.text = item.text
          thisItem.color = item.color
          thisItem.classifications = item.classifications
          let values = ['confirmed', 'prediction', 'target', 'currentFree']
          values.forEach(key => {
              thisItem[key] = item[key]
          })
          thisItem.subItems =  item.subItems ?? null
          items.push(thisItem)
        })
      }
      return items
    },
    shownHeaders () {
      if (this.currentGroup.value === 'marketRent') {
        let filteredHeaders = this.data.headers.filter(header => header.value != 'target')
        if (this.currentSet.value === 'new') {
          filteredHeaders = filteredHeaders.filter(header => header.value != 'prediction')
        }
        return filteredHeaders
      }
      else if (this.currentSet.value === 'all' || this.currentSet.value === 'new') {
        return this.data.headers
      }
      else {
        const filteredHeaders = this.data.headers.filter(header => header.value != 'currentFree')
        return filteredHeaders
      }
    }
  },
  watch: {
    items: {
      handler: function (items) {
        if (!this.loading) {
            this.arrayOfItems = items
        }
      },
      deep: true
    },
    currentGroup: {
      handler: function (newGroup) {
        this.currentFormat = newGroup.format
      }
    }
  },
  mounted () {
    this.arrayOfItems = this.items
  },
  methods: {
    onCloseModal (){
      this.currentModal = null
    },
    onUpdate () {
      this.$emit('update')
      this.onCloseModal()
    },
    async onCellClick (props, header){

      const { value: set } = this.currentSet

      const items = this.data.items[set]

      const { item } = props
      const { type } = item

      const { value: col } = header

      let values = items[type][col]

      if (col === 'target') {
        if (!this.inspectionYear) {
          alert(this.$t('Set inspection year'))
          return
        }
        this.currentModal = {
          header,
          item,
          items: []
        }
        this.modalLoading = true
        values = await this.getTargetData(item.classifications);
        this.modalLoading = false
        if (this.currentModal) {
          this.currentModal = {
            header,
            item,
            items: values
          }
        }
      }
      else {
        this.currentModal = {
          header,
          item,
          items: values
        }
      }
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    viewData (title, headers, data) {
      this.tableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          if (h.editType) {
            h.format = h.editType
          }
          return h
        }),
        rows: data
      }
      this.viewDataOptions = {
        descending: this.options.descending,
        sortBy: this.options.sortBy,
        itemsPerPage: -1
      }
      this.showTableDataDialog = true
    },
    closeViewData () {
      this.showTableDataDialog = false
      this.tableData = {}
      this.shownHeaders.map(h => {
        if(h.editType) {
          delete h.format
        }
      })

    },
    formatData (row, header, format) {
      if (row[header] == null) return ''
      else return helpers.format.formatData(row[header], format)
    },
    isEmptyCell (row, header) {
        return row[header] == null
    },
    isBold (row) {
        return row.type === 'total' || row.type === 'ending_total'
    },
    isLinked (props, header){
      const validGroups = ['area', 'rentalType']
      if(!validGroups.includes(this.currentGroup.value)){
        return false
      }

      let columns = areaLinkedCells[this.currentSet.value]

      if (this.currentGroup.value === 'rentalType') {
        columns = rentalTypeLinkedCells[this.currentSet.value]
      }

      if(!columns){
        return false
      }

      const rows = columns[header.value]

      if(!rows){
        return false
      }

      const { index } = props

      if(rows[index]){
        return true
      }

      return false
    },
    showTooltip (row, type) {
      return row.type === type
    },
    changeCurrentSet (value) {
      this.$emit('changeSet', value)
    },
    /**
     * Classifictaion refers to confirmed|prediction etc.
     * Set is the selected set eg. all, new etc.
     * Key is the
     */
    showCell (group, set, col, row){

      const hiddenGroup = hiddenCells[group]

      if(hiddenGroup){

        const hiddenCol = hiddenGroup[col]

        if(hiddenCol){
          const hiddenSet = hiddenCol[set]

          if(hiddenSet){
            const hiddenRow = hiddenSet[row]

            if(hiddenRow){
              return false
            }
          }
        }
      }

      return true
    },
    async getTargetData (classifications) {
      const permittedStatuses = ['rentable', 'rentable.koy']
      const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
      const rentableDefs = rentalDefs
        .filter((x) => permittedStatuses.includes(x.label))
        .map((x) => x.id)
      const date = new Date(moment({ year: this.inspectionYear}).utc(true).startOf('year'))
      let rentalStatuses = await this.$rambollfmapi.reports.post('rentalstatus', this.buildingIds.buildings, date)

      if (classifications) {
        rentalStatuses = rentalStatuses.filter(item => classifications.includes(item.site_classification))
      }
      return rentalStatuses
        .filter(item => rentableDefs.includes(item.unitStatus))
    }
  }
}
</script>
<style scoped>
.cell {
  height: 42px !important;
  min-width: 120px !important;
}
.empty-cell {
  background-color: #eeeff5;
}
.bold {
  font-weight: bold;
}
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.rotated {
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  .filterButton {
    margin: 0px;
    border-radius: 2em;
    width: 100%;
    justify-content: start;
  }
  .filterBox {
    margin: 1em 1.5em;
    display: flex;
    justify-content: center;
    opacity: 0.87;
  }
}
</style>
