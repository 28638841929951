<template>
  <v-dialog
    v-model="AllocationInfoDiag"
    persistent
    width="550"
  >      
    <v-card v-if="modifiedItem">
      <BaseModal
        @cancel="close()"
      >
        <template #title>
          {{ modifiedItem.code ? $t('Code') +': '+ modifiedItem.code : $t(modifiedItem.type) +': '+ modifiedItem.label }}
        </template>
        <template #content>
          <v-form
            v-if="modifiedItem.type === 'Allocation code'"
            ref="form"
            v-model="valid"
          >
            <span class="right-content">
              <v-autocomplete
                v-model="selectedExpense"
                :items="selectableExpenses"
                :label="$t('Cost type')"
                :rules="[v => !!v || $t('Required')]"
                clearable
                item-text="name"
                item-value="id"
                return-object
                required
              />
            </span>
            <span class="right-content">
              <v-text-field
                v-model="selectedItem.description"
                :label="$t('Description')"
                style="border= 0px"
                required
              />
            </span>
            <span class="right-content">
              <v-text-field
                v-model="selectedItem.cost"
                :label="$t('Expense')"
                :rules="[v => !!v || $t('Required')]"
                style="border= 0px"
                type="number"
                required
              />
            </span>
            <span
              v-if="modifiedItem.type === 'Allocation code' && !editMode"
              class="right-align"
            >
              <v-btn
                text
                rounded
                class="primary"
                @click="insertNewCost"
              >
                {{ $t('Add value') }}
              </v-btn>
            </span>
            <span
              v-if="modifiedItem.type === 'Allocation code' && editMode"
              class="right-align"
            >
              <v-btn
                text
                rounded
                outlined
                color="error"
                @click="removeItem(false)"
              >
                {{ $t('Delete') }}
              </v-btn>
              <v-spacer />
              <v-btn
                text
                outlined
                rounded
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                text
                rounded
                class="primary"
                @click="modifyItem"
              >
                {{ $t('Modify') }}
              </v-btn>
            </span>
            <v-divider />
          </v-form>
          <v-list>
            <v-list-item
              v-if="modifiedItem.type !== 'Allocation'"
              class="tile"
            >
              <v-treeview
                :items="modifiedItem.costs"
                :active="activeNode"
                :open="openIds"
                open-on-click
                hoverable
                activatable
                class="tree_view"
                @update:active="setSelectedNode"
              >
                <v-list
                  v-slot:label="props"
                > 
                  <v-list-item>
                    <span>
                      {{ props.item.name }}
                    </span>
                    <v-spacer />
                    <span>
                      {{ Number.parseFloat(props.item.cost).toFixed(2) }}
                    </span>
                  </v-list-item>
                </v-list>
              </v-treeview>
            </v-list-item>
            <v-list-item
              v-if="!modify && modifiedItem.type === 'Allocation'"
            >
              <span>
                {{ $t('Name') }} 
              </span>
              <v-spacer />
              <span class="right-content">
                {{ modifiedItem.label }}
              </span>
            </v-list-item>
            <v-list-item
              v-if="modify && modifiedItem.type === 'Allocation'"
              class="tile"
            >
              <v-text-field
                v-model="modifiedItem.label"
                :label="$t('Name')"
                style="border= 0px"
              />
            </v-list-item>
            <v-list-item
              v-if="modify && modifiedItem.level > 1"
            >
              <v-list-item-content>
                <v-select
                  v-model="modifiedItem.id_parent"
                  :items="parents.filter(p => p.level === (modifiedItem.level - 1))"
                  :label="$t('Parent level')"
                  item-text="value"
                  item-value="id"
                  class="tile-content"
                  required
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="modifiedItem.type !== 'Allocation code'"
              class="tile"
            >
              <span>
                {{ $t('Area') }} 
              </span>
              <v-spacer />
              <span class="right-content">
                {{ formatData(modifiedItem.area, 'Area') }}
              </span>
            </v-list-item>
          </v-list>
        </template>
        <template
          v-if="modify || modifiedItem.type === 'Allocation code'"
          #hideCancel
        >
          <p />
        </template>
        <template
          v-if="modify || modifiedItem.type === 'Allocation code'"
          #footer
        >
          <v-btn
            text
            rounded
            outlined
            color="error"
            @click="emit('delete')"
          >
            {{ $t('Delete') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            outlined
            rounded
            @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            text
            rounded
            class="primary"
            @click="emit('save')"
          >
            {{ $t('Save') }}
          </v-btn>
        </template>
      </BaseModal>
    </v-card>
  </v-dialog>
</template>
<script>
import format from '../../helpers/format.js'
import BaseModal from '.././general/BaseModal.vue'
import uuid from '../../helpers/uuid.js'

export default {
  emits: ['input', 'close', 'addModifiedCost', 'addCostToBeRemoved', 'addNewCost', 'addNewExpense'],
  components: {
    BaseModal,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modify: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    parents: {
      type: Array,
      default: () => {
        return []
      }
    },
    expenses: {
      type: Array,
      default: () => []
    },
    date: {
      type: Date,
      default: () => {
        return new Date()
      }
    }
  },    
  data () {
    return {
      AllocationInfoDiag: true,
      modifiedItem: null,
      selectedExpense: null,
      allocationDescription: null,
      openNodes: [1],
      activeNode: [],
      selectedID: null,
      selectedItem: {},
      editMode: false,
      valid: true,
      selectedNode: null,
      openIds: []
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    selectableExpenses () {
      return this.expenses.slice().filter(x => x.selectable)
    }
  },
  watch: {
    item (item) {
      this.modifiedItem = item
    }
  },
  mounted () {
    this.modifiedItem = JSON.parse(JSON.stringify(this.item))
    this.openIds = this.getAllNodeIds(this.modifiedItem.costs[0])
    if(this.modifiedItem.type === "Portfolio") this.modifiedItem.type = "Status portfolio"
  },
  methods: {
    close () {
      this.AllocationInfoDiag = false
      this.$emit('close')
    },
    cancel () {
      this.$refs.form.reset()
      this.editMode = false
      this.activeNode = []
    },
    modifyItem () {
      if (this.selectedExpense.name === this.selectedItem.expense) {
        let result = this.findNodeById(this.modifiedItem.costs[0], this.selectedItem.id)
        result.name = String(this.selectedItem.description)
        result.cost = Number(this.selectedItem.cost)
        result.costCode = String(this.selectedItem.costCode)
        const modifiedCostItem = {
          value: this.selectedItem.cost,
          code: this.modifiedItem.code,
          costCode: this.selectedItem.costCode,
          realizationMonth: new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), 1))
        }
        this.$emit('addModifiedCost', modifiedCostItem)
      } else {
        // if expense type is changed remove the existing cost item
        this.removeItem(true)
        this.insertNewCost()
      }
      this.countCosts(this.modifiedItem.costs[0])
    },
    removeItem (modify = false) {
      let parent = this.findNodeByName(this.modifiedItem.costs[0], this.selectedItem.expense)
      if (parent.children) {
        let index = parent.children.findIndex((c) => c.id === this.selectedNodeId)
        if(index >= 0) {
          parent.children.splice(index, 1)
          if(!modify) {
            let nodeToApi = {
              "externalIdOrganization": "ei ole käytössä",
              "code": this.modifiedItem.code,
              "costCode": this.selectedItem.costCode,
              "value": parseInt(this.selectedItem.cost),
              "realizationMonth": new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), 1)),
              "expenseId": this.selectedExpense.id,
              "name": this.selectedItem.description,
            }
            // Append cost to removal list
            this.$emit('addCostToBeRemoved', JSON.parse(JSON.stringify(nodeToApi)))
          }
        }
      }
      if(parent.children && parent.children.length === 0) {
        let index = this.modifiedItem.costs[0].children.findIndex((c) => c.name === this.selectedItem.expense)
        if(index >= 0) {
          this.modifiedItem.costs[0].children.splice(index, 1)
        }
      }
      this.countCosts(this.modifiedItem.costs[0])
      if (!modify) this.cancel()
    },
    findNodeById (node, id, result = null) {
      if (node == null) {
        return null
      }

      if(node.id === id) {
        result = node
      }

      if (!node.children) {
        return result
      }

      node.children.forEach((child) => {
        result = this.findNodeById(child, id, result)
      })
      return result
    },
    findNodeByName (node, name, result = null) {
      if (node == null) {
        return null
      }

      if(node.name === name) {
        result = node
      }

      if (!node.children) {
        return result
      }

      node.children.forEach((child) => {
        result = this.findNodeByName(child, name, result)
      })
      return result
    },
    formatData (value, formatter) {
      return format.formatData(value, formatter)
    },
    setSelectedNode (node) {
      this.selectedNodeId = node[0]
      let selectedNode = this.traverseId(this.modifiedItem.costs[0], node[0])
      
      if (!selectedNode || selectedNode.level < 2) {
        return
      }
      this.selectedItem = this.traverseId(this.modifiedItem.costs[0], node[0])
      
      if(Object.keys(this.selectedItem).length === 0) {
        this.cancel()
        return
      }

      this.selectedExpense = this.selectableExpenses.find(o => o.name === this.selectedItem.expense)
      this.editMode = true
    },
    emit (action) {
      if(this.modifiedItem.type === "Allocation code") {
        this.$emit(action, this.modifiedItem, this.selectedExpense)
        return
      }
      
      this.$emit(action, this.modifiedItem)
    },
    getSelectedExpense (item) {
      const found = this.expenses.find(x => x.name === item.name)
      
      if (found) {
        return found
      }
      return null
    },
    traverseId (node, id, result = {}) {
      if (id === null || id === 0) {
        return ""
      }

      if(id === node.id) {
        result["description"] = node.name
        result["cost"] = node.cost
        result["id"] = node.id
        result["costCode"] = node.costCode
        return node
      }

      if (!node.children) {
        return node
      }
      
      node.children.forEach(el => {
        if(el.id === id) {
          result["expense"] = node.name
        }
      });

      node.children.forEach((child) => {
        node = this.traverseId(child, id, result)
      })
      return result
    },
    findBiggestId (node, result = 0) {
      if (result < node.id) {
        result = node.id
      }

      if (!node.children) {
        return result
      }

      node.children.forEach((child) => {
        result = this.findBiggestId(child, result)
      })
      return result;
    },
    async insertNewCost () {
      if (this.$refs.form.validate()) {
        let biggestId = this.findBiggestId(this.modifiedItem.costs[0])
        let newCostCode = this.modifiedItem.code +'§'+ uuid.v4()
        let newNode = {
          "id": biggestId + 1,
          "level": 2,
          "name": this.selectedItem.description,
          "cost": parseInt(this.selectedItem.cost)
        }

        let newNodeToApi = {
            "externalIdOrganization": "ei ole käytössä",
            "code": this.modifiedItem.code,
            "costCode": newCostCode,
            "value": parseInt(this.selectedItem.cost),
            "realizationMonth": new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), 1, 0)),
            "expenseId": this.selectedExpense.id,
            "name": this.selectedItem.description,
        }

        this.$emit('addNewCost', newNodeToApi)
        this.activeNode = []

        let index = this.modifiedItem.costs[0].children.findIndex((el) => {
          if (this.selectedExpense.name === el.name) return true;
          
          return false;      
        })

        let newExpenseNodeToApi = {
            "code":newCostCode,
            "label": this.selectedItem.description,
            "idExpense": this.selectedExpense.id
        }
        
        if (index >= 0) {
          this.modifiedItem.costs[0].children[index].children.push(newNode)
          this.openIds.push(newNode.id)
        } else {
          let newExpenseNode = {
            "id": biggestId + 2,
            "level": 1,
            "name": this.selectedExpense.name,
            "cost": newNode.cost,
            "children": [newNode]
          }

          this.modifiedItem.costs[0].children.push(newExpenseNode)
          this.openIds.push(newExpenseNode.id)
        }
        this.$emit('addNewExpense', newExpenseNodeToApi)
        this.countCosts(this.modifiedItem.costs[0])
        this.cancel()
      }
    },
    countCosts (node) {
      if(node == null) {
        return
      }

      let sumOfChildren = 0
      if(node.children) {
        node.children.forEach((child) => {
          sumOfChildren += this.countCosts(child)
        })
      
        node.cost = sumOfChildren
      }

      if (node.level === 1) {
        return sumOfChildren
      }
      return parseInt(node.cost)
    },
    getAllNodeIds (node) {
      let allNodeIds = []

      if(node == null) {
        return allNodeIds
      }

      allNodeIds.push(node.id)
      
      if(node.children) {
        node.children.forEach((child) => {
         allNodeIds = allNodeIds.concat(this.getAllNodeIds(child))
        })
      }

      return allNodeIds
    }
  }
}
</script>
<style scoped>
.tree_view {
  width: 100%;
}
.right-content {
  margin-right: 22px; /* Default margin for v-treeview */
}
.right-align {
  display: flex;
  justify-content: right;
  right: 0px;
  padding: 25px 0px 25px 10px;
}
</style>