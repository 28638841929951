import RambollFMAPI from '../api/rambollfm.js'
import { i18n } from '../plugins/i18n.js'

export default {
  namespaced: true,
  state: {
    billingWidgets: [],
    widgetList: {
      'billing.unbilled_contracts_with_suppression': 'GetUnbilledContractsWithSuppression',
      'billing.unbilled_handmade_invoices_and_credit_notes':'getUnbilledHandmadeInvoicesAndCreditNotes',
      'billing.sent_invoices': 'GetSentInvoices',
      'billing.rent_review_bundles': 'GetRentReviewBundles',
    },
    selectedWidgetName: undefined,
    getAll: undefined,
    searchKeys: {
      contractNumbers: [],
      tenants: [],
      siteIdentifiers: []
    }
  },
  getters: {
  },
  mutations: {
    setBillingWidgets (state, widgets) {
      state.billingWidgets = widgets
    },
    setSelectedWidgetName (state, name) {
      state.selectedWidgetName = name
    },
    setGetAll (state, getAll) {
      state.getAll = getAll
    },
    setSearchKeys (state, keys) {
      state.searchKeys = keys
    }
  },
  actions: {
    async getBillingWidgets ({ commit, rootState, state }, selectedDate) {
      const { userWidgets } = rootState.app

      const validWidgets = []
      userWidgets.filter(w => w.name.startsWith('billing') && state.widgetList[w.name] != null).forEach(bw => {
        validWidgets.push(state.widgetList[bw.name])
      });

      if (validWidgets.length > 0) {
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const widgets = await api.reports.list(validWidgets, [], selectedDate)
        commit('setBillingWidgets', widgets)
      }
    },
    async getOneBillingWidget ({ commit, rootState, state}, selectedDate) {
      if (state.selectedWidgetName === undefined)
        return
      const { userWidgets } = rootState.app

      const validWidgets = []

      userWidgets.filter(w => w.name == state.selectedWidgetName).forEach(bw => {
        validWidgets.push(state.widgetList[bw.name])
      });

      if (validWidgets.length > 0) {
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const widgets = await api.reports.list(validWidgets, [], selectedDate, [], [], null, state.getAll)
        const allWidgets = state.billingWidgets
        for(const widget in widgets)
          for (const updatedWidgetElement in widgets)
            if (widget == updatedWidgetElement)
              allWidgets[widget] = widgets[widget]
        commit('setBillingWidgets', allWidgets)
      }
    },
    async setSelectedWidgetName ({ commit }, widgetName) {
      commit('setSelectedWidgetName', widgetName)
    },
    async setGetAll ({ commit }, getAll) {
      commit('setGetAll', getAll)
    },
    async getInvoiceArchiveSearchKeys ({ commit, state }, date) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const searchKeys = await api.invoices.getInvoiceArchiveSearchKeys(date)
      const InvoicesWithNoContractText = i18n.t('InvoicesWithNoContract')

      if (searchKeys.contractNumbers.includes("InvoicesWithNoContract")) {
        searchKeys.contractNumbers[0] = InvoicesWithNoContractText;
      }
      commit('setSearchKeys', searchKeys)
    }
  }
}