<template>
  <div>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="showTableDataDialog = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h1>
              {{ tableData.title }}
            </h1>
          </v-card-title>
        </div>
        <Table
          :rows="tableData.rows"
          :headers="tableData.headers"
          :footers="createFooters"
          :footer-definitions="tableData.footers"
          :pagination.sync="viewDataOptions"
        />
      </v-card>
    </v-dialog>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :headers="data.headers.map(h => {
        return {
          text: $t(h.text),
          value: h.value
        }
      })"
      :items="items"
      :options.sync="options"
      :item-key="'parentId'"
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      :no-data-text="$t('No data available')"
      class="elevation-1"
      mobile-breakpoint="0"
    >
      <template
        v-slot:item="props"
      >
        <tr>
          <template v-for="(header) in data.headers">
            <td
              v-if="header.value === 'site_name' || header.value === 'name' || header.value === 'site' || header.value === 'siteName'"
              :key="header.value"
              :class="{ 'col-align-right': isRightAligned(header.format) }"
              class="linked"
              @click="linkTo(props.item, 'sites.info')"
            >
              {{ formatData(props.item, header.value, header.format, true) }}
            </td>
            <td
              v-else-if="header.value !== 'toggle'"
              :key="header.value"
              :class="{ 'col-align-right': isRightAligned(header.format) }"
            >
              {{ formatData(props.item, header.value, header.format) }}
            </td>
            <td
              v-else
              :key="header.value"
              class="no-padding"
            >
              <v-btn
                v-if="props.item.showToggle === true"
                icon
                @click="props.item.expanded = !props.item.expanded"
              >
                <span v-if="props.item.expanded">
                  <v-icon>keyboard_arrow_up</v-icon>
                  <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                </span>
                <span v-else>
                  <v-icon>keyboard_arrow_down</v-icon>
                  <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                </span>
              </v-btn>
            </td>
          </template>
        </tr>
        <tr
          v-for="(child, index) in childItems.filter(child => child.parentId === props.item.parentId)"
          v-show="props.item.expanded"
          :key="child.id + index"
          class="no-padding"
        >
          <td
            v-for="header in data.headers"
            :key="header.value + child.id + props.item.id"
            class="sub-item"
            :class="{
              'col-align-right': isRightAligned(header.format),
              'linked': header.unitLink,
            }"
            @click="{ if (header.unitLink) subitemLinkTo(child, 'unit') }"
          >
            {{ header.value !== 'toggle' ? formatData( child, header.value, header.format) : '' }}
          </td>
        </tr>
      </template>
      <template
        slot="body.append"
      >
        <tr>
          <td
            v-for="(footer, idx) in data.footers"
            :key="idx"
            :class="{ 'col-align-right': isRightAligned(footer.format) }"
          >
            {{ footer.numeratorSum === undefined ?
              getFooterValue(data.items, footer.value, footer.text, footer.format, footer.average) : 
              getGroupedQuotientValue(data.items, footer) }}
          </td>
        </tr>
      </template>
      <template
        v-slot:footer.page-text="item"
      >
        <v-btn
          small
          outlined
          rounded
          @click="viewData(title, data.headers, data.items)"
        >
          {{ $t('Browse') }}
        </v-btn>
        {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
        {{ item.itemsLength.toLocaleString('fi-FI') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import helpers from '../../helpers'
import Table from '../Table.vue'
import { mapGetters } from 'vuex'

export default {
  emits: ['view-data'],
  components: {
    Table
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      showTableDataDialog: false,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {        
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 5,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        page: 1,
        itemsPerPage: this.data.itemsPerPage ? this.data.itemsPerPage : 100,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      }
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions']),
    childItems: function () {
      const parentIdentifiers = [
        ...new Set(this.data.items.map(x => x[this.data.group]))
      ]
      var childItems = []
      for (const identifier of parentIdentifiers) {
        const childRows = this.data.items.filter(x => x[this.data.group] === identifier).map(x => {
          x.parentId = identifier
          return x
        })
        childItems.push(...childRows.filter(row => row.showChild === true || row.showChild === undefined))
      }
      childItems.sort((a, b) => (a.id > b.id ? 1 : -1))
      return childItems
    },
    items: function () {
      const parentIdentifiers = [
        ...new Set(this.data.items.map(x => x[this.data.group]))
      ]
      const result = []
      for (const identifier of parentIdentifiers) {
        const childRows = this.data.items.filter(
          x => x[this.data.group] === identifier
        )
        if (childRows.length > 0) {
          // Sum the child values
          result.push(
            childRows.reduce((acc, cur) => {
              acc[this.data.group] = identifier
              acc.showToggle = true
              for (const header of this.data.headers) {
                if (header.sum === true) {
                  if (acc[header.value] === undefined) {
                    acc[header.value] = cur[header.value]
                  } else {
                    acc[header.value] += cur[header.value]
                  }
                } else if (header.onlyParent === true) {
                  acc[header.value] = cur[header.value]
                } else {
                  acc[header.value] = ''
                }
                acc.expanded = false
              }
              return acc
            }, {})
          )
        }
      }
      return result.map(item => {
        const keys = Object.keys(item)
        const thisItem = JSON.parse(JSON.stringify(item))
        keys.forEach(key => {
          if (Array.isArray(item[key])) {
            // No need to duplicate things
            const list = new Set(item[key])
            let itemStr = ''
            // If there is more than 4 items, just show the first 4 items and add ... as the last item
            if (list.length > 4) {
              itemStr = [
                ...list.slice(0, 4).map(v => this.getValueWithUnit(v, key)),
                '...'
              ].join(', ')
            } else {
              itemStr = [...list]
                .map(v => this.getValueWithUnit(v, key))
                .filter(v => v !== null && v !== '')
                .join(', ')
            }
            thisItem[key] = itemStr
          }
        })
        thisItem.parentId = item[this.data.group]
        return thisItem
      })
    }
  },
  methods: {
    linkTo (destination, path) {
      let target = undefined
      if(destination.siteIdentifier !== undefined){
        target = destination.siteIdentifier
      }
      if(target !== undefined){
        this.$router.push({
          name: path,
          params: { id_site: target}
        })
      }
    },
    subitemLinkTo (child, path) {
      if (path == "unit") {
        this.$router.push({
          name: 'sites.facilitymanagement',
          params: { id_site: child.parentId },
          query: { suite_id: child.id }
        })
      }
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    getValueWithUnit (value, header) {
      return helpers.format.valueWithUnit(value, header)
    },
    getFooterValue (data, headerName, text, format, average) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }

      let value = data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      return helpers.format.formatData(value, format)
    },
    getGroupedQuotientValue (data, footer) {
      let numeratorSum = data
        .map(i => {
          const toNumber = Number(i[footer.numeratorSum])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      let siteIdsIncluded = []
      let denominatorSum = data
        .map(i => {
          if (siteIdsIncluded.indexOf(i[this.data.group]) !== -1) {
            return 0
          }
          const toNumber = Number(i[footer.denominatorSum])
          siteIdsIncluded.push(i[this.data.group])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
        return helpers.format.formatData((numeratorSum / denominatorSum * 100).toFixed(2), footer.format)
    },
    viewData (title, headers, data) {
      // this.$root.$emit('view-data', title, headers, data)
      this.tableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          return h
        }),
        footers: this.data.items,
        rows: this.data.items
      }
      this.viewDataOptions = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        itemsPerPage: -1
      }
      this.showTableDataDialog = true
    },
    createFooters (headers, rows) {
      return helpers.table.createFooters(headers, rows, this.data.browseFooters, this.data.footers)
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    formatData (row, header, format, isLink = false) {
      const value = isLink ? helpers.format.escapeHtml(row[header]) : row[header]
      return helpers.format.formatData(value, format)
    }
  }
}
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
}
.sub-item {
  background-color: #f0f9ff;
}
</style>
