import RambollFMAPI from '../api/rambollfm.js'

export default {
    namespaced: true,
    state: {
      zonePrice: null,
      balance: null,
      buildingProjects: [],
      internalRentCalculationValues: undefined,
      sites: [],
      buildings: [],
      selectedBuildings: [],
      selectedUnits: [],
      calculationBasesItems: [],
      tenantIds: [],
      personParties: [],
      parties: [],
      canChangeZonePriceAndBalance: false,
      siteIdFromSingleBuilding: undefined,
      singleBuildingId: undefined,
      canAddMoreTenants: false,
    },
    getters: {
    },
    mutations: {
      setZonePrice (state, value) {
        state.zonePrice = value;
      },
      setBalance (state, value) {
        state.balance = value;
      },
      setBuildingProjects (state, value) {
        state.buildingProjects = value;
      },
      setInternalRentCalculationValues (state, internalRentCalculationValues) {
        state.internalRentCalculationValues = internalRentCalculationValues
      },
      setSelectedBuildings (state, selectedBuildings) {
        if (selectedBuildings !== state.selectedBuildings) {
          state.canChangeZonePriceAndBalance = true; // Set a boolean flag to true
        }
        state.selectedBuildings = selectedBuildings
      },
      setSites (state, sites) {
        state.sites = sites
      },
      setBuildings (state, buildings) {
        state.buildings = buildings
      },
      setSelectedUnits (state, selectedUnits) {
        if (selectedUnits !== state.selectedUnits) {
          state.canChangeZonePriceAndBalance = true; // Set a boolean flag to true
        }
        state.selectedUnits = selectedUnits
      },
      addTenantId (state, tenantId) {
        if (tenantId == undefined) return
        if (!state.tenantIds.includes(tenantId)) {
          state.tenantIds.push(tenantId);
        }
      },
      clearTenantIds (state) {
        state.tenantIds = [];
      },
      resetCalculatorData (state) {
        state.zonePrice = undefined;
        state.balance = undefined;
        state.buildingProjects = [];
        state.internalRentCalculationValues = undefined;
        state.selectedBuildings = [],
        state.selectedUnits = [],
        state.tenantIds = [];
        state.personParties = [],
        state.parties = [],
        state.canChangeZonePriceAndBalance = false;
        state.singleBuildingId = undefined;
        state.siteIdFromSingleBuilding = undefined;
        state.canAddMoreTenants = false
      },
      resetCanChangeZonePriceAndBalance (state) {
        state.canChangeZonePriceAndBalance = false;
      }
    },
    actions: {
      setZonePrice ({ commit }, value) {
        commit("setZonePrice", value);
      },
      setBalance ({ commit }, value) {
        commit("setBalance", value);
      },
      setBuildingProjects ({ commit }, value) {
        commit("setBuildingProjects", value);
      },
      setInternalRentCalculationValues ({ commit }, value) {
        commit("setInternalRentCalculationValues", value);
      },
      setSelectedBuildings ({ commit }, value) {
        commit("setSelectedBuildings", value);
      },
      setSelectedUnits ({ commit }, value) {
        commit("setSelectedUnits", value);
      },
      addTenantId ({ commit }, tenantId) {
        commit("addTenantId", tenantId);
      },
      resetCalculatorData ({ commit }) {
        commit("resetCalculatorData");
      },
      clearTenantIds ({ commit }) {
        commit("clearTenantIds");
      },
      async setDataFromSingleBuilding ({ commit, state }, { siteId, buildingId }) {
        state.singleBuildingId = buildingId;
        state.siteIdFromSingleBuilding = siteId;
        state.canAddMoreTenants = true
      },
      resetCanChangeZonePriceAndBalance ({ commit }) {
        commit("resetCanChangeZonePriceAndBalance");
      },
      async setupCalculatorStartingValues ({ commit, state  }) {

        if (!this.getters['app/hasApplicationPermissionByName']('SISVUOKRA_LASKIN')) return

        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

        let parties = await api.parties.list({ query: { visibility_status: true } })
        state.personParties = parties.filter(party => party.type == 1).map(p => (
          {
            name: p.name,
            id: p.id,
          })
        );
        state.parties = parties.filter(party => party.type == 0).map(p => (
        {
          name: p.name,
          id: p.id,
        })
        );

        const uniqueSiteIdsSet = new Set();
        state.selectedUnits.forEach(unit => {
          //Getting siteID from site or from building they have different variable name
          const siteId = unit.id_site || unit.siteId;
          if (siteId !== undefined) {
            uniqueSiteIdsSet.add(siteId);
          }
        });
        let uniqueSiteIdsArray = Array.from(uniqueSiteIdsSet);
        if (uniqueSiteIdsArray.length === 0 && state.siteIdFromSingleBuilding !== undefined) {
          uniqueSiteIdsArray = Array.isArray(state.siteIdFromSingleBuilding)
            ? state.siteIdFromSingleBuilding
            : [state.siteIdFromSingleBuilding];
        }

        const results = [];
        const currentDate = new Date();
            
        for (const siteId of uniqueSiteIdsArray) {
          const buildingProjects = await api.buildingProjects.getBuildingProjectsBySiteId(siteId, currentDate);
          results.push(buildingProjects);
        }
        const building_projects = [].concat(...results);
        commit('setBuildingProjects', building_projects);

        const site = await api.sites.get(uniqueSiteIdsArray[0])
        commit('setSites', [site])

        const buildings = await api.buildings.list({ query: { siteId: uniqueSiteIdsArray[0] } })
        commit('setBuildings', buildings)

        if (state.singleBuildingId != undefined) {
          const newArray = [state.singleBuildingId];
          commit("setSelectedBuildings", newArray);
        }

        const zoneprice = await api.contracts.calculator.getZonePrice(state.selectedBuildings, uniqueSiteIdsArray);
        commit('setZonePrice', zoneprice);

        if(state.selectedBuildings.length != 0) {
          let balance = await api.contracts.calculator.getBalance(state.selectedBuildings);
          if (typeof balance === 'object') {
            balance = balance.data;
          }
          commit('setBalance', balance);
        }

        try {
          var calculationBases = await api.contracts.calculator.getCalculationBases();
          
          // Check if calculationBases is an array and not empty
          if (Array.isArray(calculationBases) && calculationBases.length > 0) {
              for (let calculationBase of calculationBases) {
                  state.calculationBasesItems.push(calculationBase);
              }
          }
        } 
        catch (error) {
            return
        }
      }
    }
  };