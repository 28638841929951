<template>
  <v-expansion-panels
    v-if="carpark"
    class="my-2"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
        >
          <div
            style="text-align: right"
          >
            {{ $t('leasing.commercial_info') }}
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="getMetadataForCarPark(carpark)"
          :value="getDataForCarpark(carpark)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import MetadataForm from '../../../components/MetadataForm.vue'
import humanize from '../../../helpers/humanize.js'

export default {
  name: 'CarparkCommercialInfo',
  components: {
    MetadataForm
  },
  props: {
    carpark: { type: Object, default: null },
  },
  methods: {
    getCommercialInfoUpdated (carpark) {
      return carpark.commercial_info_date ? `${carpark.commercial_info_user}, ${humanize.date(carpark.commercial_info_date)}` : '';
    },
    getRentPlanUpdated (carpark) {
      return carpark.lease_plan_date ? `${carpark.lease_plan_user}, ${humanize.date(carpark.lease_plan_date)}` : '';
    },
    getTotalParkinglotRent (carpark) {
      if (carpark.amount !== null && carpark.market_rent !== null) {
        return carpark.amount * carpark.market_rent
      }
      return null
    },
    getDataForCarpark (carpark) {

      if (carpark.rental_type === 'Single') {
        return {
          market_rent_carpark: carpark.market_rent,
          carpark_rent_target_single: carpark.rent_target,
          carpark_lease_plan: carpark.lease_plan,
          carpark_lease_plan_updated: this.getRentPlanUpdated(carpark),
          carpark_commercial_info_updated: this.getCommercialInfoUpdated(carpark),
        }
      }

      if (carpark.rental_type === 'AreaRent') {
        return {
          rental_type: carpark.rental_type,
          sum_of_market_rent: this.getTotalParkinglotRent(carpark),
          market_rent_car_space: carpark.market_rent,
          sum_of_rent_target: carpark.rent_target,
          carpark_lease_plan: carpark.lease_plan,
          carpark_lease_plan_updated: this.getRentPlanUpdated(carpark),
          carpark_commercial_info_updated: this.getCommercialInfoUpdated(carpark),
        }
      }

      if (carpark.rental_type === 'Privilege') {
        return {
          market_rent_carpark: carpark.market_rent,
          carpark_rent_target_single: carpark.rent_target,
          carpark_lease_plan: carpark.lease_plan,
          carpark_lease_plan_updated: this.getRentPlanUpdated(carpark),
          carpark_commercial_info_updated: this.getCommercialInfoUpdated(carpark),
        }
      }

      return {}
    },
    getMetadataForCarPark (carpark) {
      if (carpark.rental_type === 'Single') {
        return {
          market_rent_carpark: { isShown: true, category: 'None' },
          carpark_rent_target_single: { isShown: true, category: 'None' },
          carpark_lease_plan: { isShown: true, category: 'None', editType: 'ReadOnlyTextAreaw' },
          carpark_lease_plan_updated: { isShown: true, category: 'None' },
          carpark_commercial_info_updated: { isShown: true, category: 'None' },
        }
      }

      if (carpark.rental_type === 'AreaRent') {
        return {
          sum_of_market_rent: { isShown: true, category: 'None' },
          market_rent_car_space: { isShown: true, category: 'None' },
          sum_of_rent_target: { isShown: true, category: 'None' },
          carpark_lease_plan: { isShown: true, category: 'None', editType: 'ReadOnlyTextAreaw' },
          carpark_lease_plan_updated: { isShown: true, category: 'None' },
          carpark_commercial_info_updated: { isShown: true, category: 'None' },
        }
      }

      if (carpark.rental_type === 'Privilege') {
        return {
          market_rent_carpark: { isShown: true, category: 'None' },
          carpark_rent_target_single: { isShown: true, category: 'None' },
          carpark_lease_plan: { isShown: true, category: 'None', editType: 'ReadOnlyTextAreaw' },
          carpark_lease_plan_updated: { isShown: true, category: 'None' },
          carpark_commercial_info_updated: { isShown: true, category: 'None' },
        }
      }

      return {}
    }
  }
}
</script>
<style scoped>
</style>
