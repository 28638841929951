<template>
  <v-row
    v-if="widgetId == 'billing.invoice_archive'"
    no-gutters
    class="my-4"
  >
    <v-col
      sm="10"
    >
      <v-row no-gutters>
        <v-col class="ml-2">
          <v-autocomplete
            v-model="searchValues.contractNumbers"
            small-chips
            clearable
            deletable-chips
            multiple
            hide-details
            :items="searchKeys.contractNumbers"
            :label="$t('Contract number')"
            :class="contractsOverflow"
            :menu-props="{ maxHeight: 400 }"
            @change="resetSearchFields('contractNumbers')"
          >
            <template #prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="selectAll('contractNumbers')"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ currentField == 'contractNumbers' ? icon : 'check_box_outline_blank' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ allSelected && currentField == 'contractNumbers' ? $t('site_filters.deselect') : $t('site_filters.select') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="ml-2">
          <v-autocomplete
            v-model="searchValues.siteIdentifiers"
            small-chips
            clearable
            deletable-chips
            multiple
            hide-details
            :items="searchKeys.siteIdentifiers"
            :label="$t('Site identifier')"
            :class="sitesOverflow"
            :menu-props="{ maxHeight: 400 }"
            @change="resetSearchFields('siteIdentifiers')"
          >
            <template #prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="selectAll('siteIdentifiers')"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ currentField == 'siteIdentifiers' ? icon : 'check_box_outline_blank' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ allSelected && currentField == 'siteIdentifiers' ? $t('site_filters.deselect') : $t('site_filters.select') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="ml-2">
          <v-autocomplete
            v-model="searchValues.tenants"
            small-chips
            clearable
            deletable-chips
            multiple
            hide-details
            :items="searchKeys.tenants"
            :label="$t('Tenant')"
            :class="tenantsOverflow"
            :menu-props="{ maxHeight: 400 }"
            @change="resetSearchFields('tenants')"
          >
            <template #prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="selectAll('tenants')"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ currentField == 'tenants' ? icon : 'check_box_outline_blank' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ allSelected && currentField == 'tenants' ? $t('site_filters.deselect') : $t('site_filters.select') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      sm="2"
      class="d-flex justify-center"
    >
      <v-btn
        text
        rounded
        outlined
        depressed
        class="blue-button px-12"
        @click="searchArchive(widgetId)"
      >
        {{ $t('Search') }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  emits: ['dataUpdated'],
  components: {},
  props: {
    widgetId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
        searchValues: {
            contractNumbers: [],
            tenants: [],
            siteIdentifiers: []
        },
        currentField: "contractNumbers"
        }
  },
  computed: {
    ...mapState('billing', ['searchKeys']),
    icon () {
        if (this.allSelected) return 'check_box'
        if (this.someSelected) return 'indeterminate_check_box'
        return 'check_box_outline_blank'
    },
    allSelected () {
      switch (this.currentField) {
        case 'contractNumbers':
          return this.searchValues.contractNumbers.length == this.searchKeys.contractNumbers.length
        case 'tenants':
          return this.searchValues.tenants.length == this.searchKeys.tenants.length
        default:
          return this.searchValues.siteIdentifiers.length == this.searchKeys.siteIdentifiers.length
      }
    },
    someSelected () {
      switch (this.currentField) {
        case 'contractNumbers':
          return this.searchValues.contractNumbers.length > 0 && !this.allSelected
        case 'tenants':
          return this.searchValues.tenants.length > 0 && !this.allSelected
        default:
          return this.searchValues.siteIdentifiers.length > 0 && !this.allSelected
      }
    },
    contractsOverflow () {
      return this.searchValues.contractNumbers.length > 10 ? "overflow-auto" : ""
    },
    sitesOverflow () {
      return this.searchValues.siteIdentifiers.length > 10 ? "overflow-auto" : ""
    },
    tenantsOverflow () {
      return this.searchValues.tenants.length > 10 ? "overflow-auto" : ""
    }
  },
  methods: {
    searchArchive (widgetId) {
      const search = {
        query: this.searchValues,
        widgetId
      }
      this.$emit('dataUpdated', search)
    },
    resetSearchFields (currentField) {
      this.currentField = currentField
      switch (currentField) {
        case 'contractNumbers':
          this.searchValues.tenants = []
          this.searchValues.siteIdentifiers = []
          break;
        case 'tenants':
          this.searchValues.contractNumbers = []
          this.searchValues.siteIdentifiers = []
          break;
        default:
          this.searchValues.contractNumbers = []
          this.searchValues.tenants = []
      }
    },
    selectAll (currentField) {
      this.$nextTick(() => {
        this.resetSearchFields(currentField)
        switch (currentField) {
          case 'contractNumbers':
            if (this.allSelected) {
              this.searchValues.contractNumbers = []
            } else {
              this.searchValues.contractNumbers = this.searchKeys.contractNumbers.slice()
            }
            break;
          case 'tenants':
            if (this.allSelected) {
              this.searchValues.tenants = []
            } else {
              this.searchValues.tenants = this.searchKeys.tenants.slice()
            }
            break;
          default:
            if (this.allSelected) {
              this.searchValues.siteIdentifiers = []
            } else {
              this.searchValues.siteIdentifiers = this.searchKeys.siteIdentifiers.slice()
            }
        }
      })
    }
  }
}
</script>

<style scoped>
>>> .v-select__slot {
  min-height: 34px;
}

.v-autocomplete {
  height: auto !important;
  max-height: 250px;
}
</style>
