import { render, staticRenderFns } from "./TaskProjectInformation.vue?vue&type=template&id=24459766&scoped=true"
import script from "./TaskProjectInformation.vue?vue&type=script&lang=js"
export * from "./TaskProjectInformation.vue?vue&type=script&lang=js"
import style0 from "./TaskProjectInformation.vue?vue&type=style&index=0&id=24459766&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24459766",
  null
  
)

export default component.exports