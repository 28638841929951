<template>
  <v-row
    class="mb-5 flex-column"
  >
    <v-col>
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          :class="{ 'hide-expansion-panel': noExpansionPanel }"
        >
          <v-expansion-panel-header>
            {{ $t('Information') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="v-data-table__wrapper">
              <table
                class="v-data-table theme--light"
                style="border-spacing: 0px;"
              >
                <tbody v-if="getTotalRows(dataRows) > 0">
                  <tr
                    v-for="header in headers"
                    :key="header.value"
                  >
                    <th
                      class="columnheader"
                      style="font-size: 12px"
                      :style="header.text === $t('audits general rating') ? 'font-weight: 700;' : 'font-weight: 500;'"
                    >
                      {{ header.text }}
                    </th>
                    <td
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <v-row class="row align-center vertical_icon">
                        <v-row
                          class="col-align-left flex-column"
                          style="padding-left: 1em; padding-right: 1em;"
                          :style="header.text === $t('audits general rating') ? 'font-weight: 700;' : 'font-weight: 500;'"
                        >
                          <span
                            :key="index"
                          >
                            {{ formatData(item[header.value], header.format) }}
                          </span>
                        </v-row>
                        <v-row
                          class="col-align-right flex-column"
                        >
                          <StatusIcon
                            :field="getObjectWithUnit(item[header.value], header.value)"
                            :data="getValueWithUnit(item[header.value], header.value)"
                          />
                        </v-row>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-else
                >
                  <tr
                    v-for="header in headers"
                    :key="header.value"
                  >
                    <th
                      class="columnheader"
                      style="font-size: 12px"
                      :style="header.text === $t('audits general rating') ? 'font-weight: 700;' : 'font-weight: 500;'"
                    >
                      {{ header.text }}
                    </th>
                    <td>
                      {{ $t('audits no data') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="v-data-footer"
              style="border-top: 2px solid #eeeff5;"
            >
              <div class="v-data-footer__pagination">
                <v-btn
                  v-if="!hide"
                  small
                  outlined
                  rounded
                  @click="exportAs('pdf')"
                >
                  {{
                    $t('Export to PDF')
                  }}
                </v-btn>&nbsp;
                <v-btn
                  v-if="!hide"
                  small
                  outlined
                  rounded
                  @click="exportAs('csv')"
                >
                  {{
                    $t('Export to spreadsheet')
                  }}
                </v-btn>&nbsp;
                {{ dataRows.length ? 1 : 0 }}-{{ dataRows.length.toLocaleString('fi-FI') }} {{ $t('of') }}
                {{ dataRows.length.toLocaleString('fi-FI') }}
              </div>
              <div class="v-data-footer__icons-before">
                <v-btn
                  class="v-btn v-btn--disabled v-btn--icon v-btn--round v-btn--text theme--light v-size-default"
                  disabled="disabled"
                  aria-label="Previous page"
                  text
                >
                  <span class="v-btn__content">
                    <i class="v-icon material-icons theme--light">chevron_left</i>
                  </span>
                </v-btn>
              </div>
              <div class="v-data-footer__icons-after">
                <v-btn
                  class="v-btn v-btn--disabled v-btn--icon v-btn--round v-btn--text theme--light v-size-default"
                  disabled="disabled"
                  aria-label="Next page"
                  text
                >
                  <span class="v-btn__content">
                    <i class="v-icon material-icons theme--light">chevron_right</i>
                  </span>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import helpers from '../helpers'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import StatusIcon from '../components/StatusIcon.vue'

export default {
  emits: ['showFilterDialog', 'select_view', 'remove-row', 'event_child', 'edit-row', 'exported'],
  name: 'TableVertical',
  components: {
    StatusIcon
  },
  props: {
    rows: {
      default: () => {
        return []
      },
      type: Array
    },
    headers: {
      default: () => {
        return []
      },
      type: Array
    },
    footers: {
      default: () => {
        return []
      },
      type: Array
    },
    pagination: {
      default: () => {
        return {
          sortDesc: [true],
          rowsPerPage: -1
        }
      },
      type: Object
    },
    showControls: { 
      default: false, 
      type: Boolean
    },
    showFilters: {
      default: () => {
        return true
      },
      type: Boolean
    },
    noExpansionPanel: Boolean,
    dataSource: {
      type: Function
    },
    hide: {
      type: Boolean,
      default: false
    },
    showFilter: Boolean,
    documentType: {
      default: () => {
        return undefined
      },
      type: String
    },
    showSaveSelection: {
      default: () => {
        return false
      },
      type: Boolean
    },
    selectedViewString: {
      default: () => {
        return null
      },
      type: String
    },
    storedViews: {
      default: () => {
        return []
      },
      type: Array
    },
    showConfirm: {
      default: () => {
        return false
      },
      type: Boolean
    }
  },
  data () {
    return {
      options: {},
      dataRows: [],
      dataHeaders: [],
      dataFooters: [],
      visibleHeaders: [],
      visibleRows: [],
      filterValues: {},
      showVisibleColumnsDialog: false,
      showSaveSelectionDialog: false,
      totalDataRows: 0,
      loading: true,
      nameOfSelection: null,
      needsConfirm: false,
      selectedView: null,
      selectedViewName: null
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionLabelById']),
    useDataSource () {
      if (typeof this.dataSource === 'function') {
        return true
      }
      return false
    },
    sortedHeaders () {
      return [...this.dataHeaders].sort((a, b) =>
        a.translatedName.localeCompare(b.translatedName)
      )
    },
    currentVisibleRows () {
      const currentFilters = Object.keys(this.filterValues)
        .filter(f => {
          if (
            typeof this.filterValues[f] !== 'undefined' &&
              this.filterValues[f] !== null &&
              this.filterValues[f] !== ''
          ) {
            return true
          }
          return false
        })
        .reduce((acc, cur) => {
          acc[cur] = this.filterValues[cur]
          return acc
        }, {})
      return this.dataRows.filter(row => {
        // iterate over columns
        const filteredHeaders = Object.keys(currentFilters)

        for (let h = 0; h < filteredHeaders.length; h++) {
          const header = filteredHeaders[h]
          let rowValue = row[header]
          const queryValue = currentFilters[header].split(',')

          if (typeof rowValue === 'number') {
            rowValue = '' + rowValue // tostring
          }

          if (Array.isArray(rowValue)) {
            rowValue = rowValue.map(x => x.groupName).join(', ')
          }

          if (typeof rowValue === 'string') {
            let found = false
            for (let i = 0; i < queryValue.length; i++) {
              if (
                rowValue.toLowerCase().indexOf(queryValue[i].toLowerCase()) >= 0
              ) {
                found = true
              }
            }
            // All option
            if (!found) {
              return false
            }
          }
          // If row value is empty in filtered column it is left out
          if (rowValue === null) {
            return false
          }
        }

        return true
      })
    },
    currentVisibleHeaders () {
      const headers = this.dataHeaders.filter(
        h => this.visibleHeaders.indexOf(h.value) >= 0
      )
      if (this.showControls) {
        headers.push({ text: this.$t('Actions'), value: '_actions' })
      }
      return headers
    },
    currentVisibleFilterHeaders () {
      const filterFields = this.dataHeaders.filter((
        h // Add header value if is not 'net_floor_area'
      ) => {
        if (h.value === 'net_floor_area') {
          return false
        } else {
          return this.visibleHeaders.indexOf(h.value) >= 0
        }
      })
      // Add floor area min and floor area max if visibleHeaders includes net_floor_area
      if (this.visibleHeaders.includes('net_floor_area')) {
        filterFields.push({
          value: 'floorAreaMin',
          text: this.$t('Net floor area min')
        })
        filterFields.push({
          value: 'floorAreaMax',
          text: this.$t('Net floor area max')
        })
      }
      return filterFields
    },
    items: function () {
      return this.dataRows.map(item => {
        const thisItem = JSON.parse(JSON.stringify(item))
        return thisItem
      })
    }
  },  
  watch: {
    rows: {
      handler: function (value) {
        this.updateRows(value)
        this.updateFooters()
      },
      deep: true
    },
    headers: {
      handler: function (value) {
        this.updateHeaders(value)
      },
      deep: true
    },
    footers: {
      handler: function (value) {
        this.updateFooters(value)
      },
      deep: true
    },
    pagination: {
      handler: function (value) {
        this.updateOptions(value)
      }
    },
    options: {
      handler () {
        if (this.useDataSource) {
          this.updateRows()
        }
      },
      deep: true
    },
    filterValues: {
      handler: function (value) {
        this.updateFooters()
      },
      deep: true
    },
    showFilter: function () {
      if (this.hide && this.showFilter) {
        this.showVisibleColumnsDialog = true
      }
      if (this.hide && !this.showFilter) {
        this.showVisibleColumnsDialog = false
      }
    },
    showVisibleColumnsDialog: function () {
      if (this.hide && !this.showVisibleColumnsDialog) {
        if (this.showSaveSelectionDialog) {
          this.showSaveSelectionDialog = false
        } else {
          this.$emit('showFilterDialog')
        }
      }
    },
    documentType: function () {
      if (this.hide && this.documentType === 'pdf') {
        this.exportAs('pdf')
      }
      if (this.hide && this.documentType === 'csv') {
        this.exportAs('csv')
      }
      //
    },
    selectedView: function (selectedView) {
      if (typeof selectedView !== 'undefined' && selectedView !== null) {
        this.visibleHeaders = this.dataHeaders
          .filter(dh =>
            selectedView.stored_fields.find(sf => sf.field === dh.value)
          )
          .map(dh => {
            return dh.value
          })
        this.nameOfSelection = selectedView.name
        this.selectedViewName = selectedView.name
      } else {
        this.visibleHeaders = this.dataHeaders.map(dh => {
          return dh.value
        })
        this.nameOfSelection = null
      }
      this.$emit('select_view', selectedView)
    },
    showConfirm: function (boolean) {
      if (boolean === true) {
        this.needsConfirm = boolean
      }
    },
    nameOfSelection: function () {
      this.needsConfirm = false
    },
    selectedViewString: function (string) {
      this.selectedViewName = string
    },
    selectedViewName: function (name) {
      if (name) {
        this.selectedView = this.storedViews.find(sw => sw.name === name)
      } else {
        this.selectedView = null
      }
    }
  },
  mounted () {
    this.updateOptions(this.pagination)
    this.updateHeaders(this.headers)
    this.updateRows(this.rows)
    this.updateFooters(this.footers)
  },
  methods: {
    updateFooters (footers) {
      if (typeof this.footers === 'function') {
        this.dataFooters = this.footers(this.headers, this.currentVisibleRows)
      } else if (!footers) {
        if (typeof this.dataFooters[0] !== 'undefined') {
          Object.keys(this.dataFooters[0]).forEach(result => {
            if (result.includes('area')) {
              this.dataFooters[0][result] = this.currentVisibleRows
                .map(s => Number(s[result]))
                .reduce((acc, cur) => acc + cur, 0)
            }
          })
        }
      } else {
        this.dataFooters = footers
      }
    },
    updateHeaders (headers) {
      this.dataHeaders = JSON.parse(JSON.stringify(headers))
      this.dataHeaders.forEach(header => {
        header.translatedName = this.$t(header.text)
      })
      this.visibleHeaders = this.dataHeaders.map(h => h.value)
      this.filterValues = this.dataHeaders.reduce((acc, cur) => {
        acc[cur.value] = null
        return acc
      }, {})
    },
    async updateRows (rows) {
      if (this.useDataSource) {
        this.loading = true
        rows = await this.dataSource(this.options)
        this.loading = false
        this.totalDataRows = Number(rows.count)
        rows = rows.items
      } else {
        this.totalDataRows = undefined // See Vuetify documentation
      }
      this.dataRows = [...rows]
    },
    updateOptions (pag) {
      Object.assign(this.options, pag)
    },
    removeRow (row) {
      this.$emit('remove-row', row)
      // HACK to support the old functionality
      this.$emit('event_child', row, 'delete')
    },
    editRow (row) {
      this.$emit('edit-row', row)
      // HACK to support the old functionality
      this.$emit('event_child', row, 'edit')
    },
    exportAs (type) {
      const headers = this.currentVisibleHeaders.filter(
        h => h.value !== '_actions'
      )
      const data = [...JSON.parse(JSON.stringify(this.currentVisibleRows))]
      if (this.options.sortDesc[0]) {
        data.sort((a, b) =>
          a[this.options.sortBy] < b[this.options.sortBy] ? 1 : -1
        )
      } else {
        data.sort((a, b) =>
          a[this.options.sortBy] > b[this.options.sortBy] ? 1 : -1
        )
      }

      if (type === 'csv') {
        const csvHeaders = headers.map(h => this.$t(h.text))
        const csvData = data.map(d => {
          const row = []
          headers.forEach(h => {
            let val = d[h.value]
            val = val === undefined ? '' : val
            val = this.getValueWithUnitForCsvExport(val, h.value, false)
            row.push(val)
          })
          return row
        })
        helpers.csv.export(csvHeaders, csvData)
      }

      if (type === 'pdf') {
        const csvData = data.map(d => {
          const row = []
          headers.forEach(h => {
            let val = d[h.value]
            val = val === undefined ? '' : val
            val = this.getValueWithUnitForPdfExport(val, h.value, false)
            row.push(val)
          })
          return row
        })

        this.$log.debug(csvData)

        const doc = new JsPDF('landscape')
        doc.autoTable({
          margin: {
            top: 5,
            left: 5,
            right: 5
          },
          head: [headers.map(h => this.$t(h.text))],
          body: csvData
        })

        doc.save('report.pdf')
      }
      this.$emit('exported')
    },
    getTruncatedStringFromArray (value, header, encode) {
      // No need to duplicate things
      const list = new Set(value)
      let itemStr = ''
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      if (list.length > 4) {
        itemStr = [
          ...list
            .slice(0, 4)
            .map(v => this.getValueWithUnit(v, header, encode)),
          '...'
        ].join(', ')
      } else {
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header, encode))
          .filter(v => v !== null && v !== '')
          .join(', ')
      }

      return itemStr
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (header === 'general_rating_site') {
        value = helpers.format.valueWithUnit(value, header, encode)
        return value.replace('<b>', '').replace('</b>', '')
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, encode)
      }

      if (header.includes('date')) {
        return helpers.format.valueWithUnit(value, header, encode)
      }

      if (value === null) {
        return ''
      }

      if (value && !isNaN(value) && typeof value !== 'string') {
        return Number(value)
          .toFixed(2)
          .toString()
          .replace('.', ',')
      }

      return value
    },
    createAssignmentString (assign, html = true) {
      // If the party has only a single assignment, then we show that assignment fully in the table.
      // When the party has multiple assignments we count through all the different assignment types
      // and collect the strings for the locations where the party has been assigned.
      // These strings are added to the table inside a hidden span, so that they can be filtered with
      // the table component, but remain hidden, since a single party can have over a hundred assignments.
      if (assign.length === 1) {
        if (html) {
          return (
            '<b>' +
            this.$t(this.definitionLabelById(assign[0].idDefinition)) +
            ':</b> ' +
            this.createSingleAssignmentString(assign[0])
          )
        } else {
          return (
            this.$t(this.definitionLabelById(assign[0].idDefinition)) +
            ': ' +
            this.createSingleAssignmentString(assign[0])
          )
        }
      }
      const assignObject = {}
      assign.forEach(a => {
        if (assignObject[a.idDefinition] === undefined) {
          assignObject[a.idDefinition] = {
            name: html
              ? '<b>' +
                this.$t(this.definitionLabelById(a.idDefinition)) +
                '</b>'
              : this.$t(this.definitionLabelById(a.idDefinition)),
            count: 0,
            search: '',
            values: []
          }
        }
        assignObject[a.idDefinition].values.push(assign)
        assignObject[a.idDefinition].count += 1
        assignObject[a.idDefinition].search += a.siteName
      })

      const assignmentArray = []
      const searchArray = []
      Object.values(assignObject).forEach(value => {
        assignmentArray.push(sanitizeHtml(value.name) + ' (' + value.count + ')')
        searchArray.push(sanitizeHtml(value.search))
      })

      if (html) {
        return (
          assignmentArray.join('<br>') +
          '<span style="display: none">' +
          searchArray.join() +
          '</span>'
        )
      } else {
        return assignmentArray.join(', ')
      }
    },
    createSingleAssignmentString (assign) {
      let assignment = ''
      assignment += assign.buildingName ? assign.buildingName : assign.siteName
      if (assign.unitName !== null) {
        assignment += ' [' + assign.unitName + ']'
      } else if (assign.spaceName !== null) {
        assignment += ' [' + assign.spaceName + ']'
      }
      return sanitizeHtml(assignment)
    },
    getHeaderValue (header, headerText) {
      if (header === 'general_rating_site') {
        return headerText.bold()
      } else {
        return headerText
      }
    },
    getValueWithUnit (value, header, encode) {
      if (header === 'general_rating_site') {
        value = helpers.format.valueWithUnit(value, header, encode)
        return value.bold()
      } else {
        value = helpers.format.valueWithUnit(value, header, encode)
        return value
      }
    },
    getValueWithUnitForPdfExport (value, header, encode) {
      if (header === 'general_rating_site') {
        value = helpers.format.valueWithUnit(value, header, encode)
        return value.replace('<b>', '').replace('</b>', '')
      } else {
        return helpers.format.valueWithUnit(value, header, encode)
      }
    },
    getTotalRows (dataRows) {
      var totalRows = dataRows.length
      if (totalRows !== 0) {
        return totalRows
      } else {
        return 0
      }
    },
    getObjectWithUnit (data, name) {
      var object = { name, data }
      return object
    },
    saveSelection () {
      this.$emit(
        'save-selection',
        this.nameOfSelection,
        this.visibleHeaders,
        false
      )
    },
    formatData (value, format) {
      return helpers.format.formatData(value, format)
    }
  }
}
</script>
<style>
.filters .v-expansion-panel__body {
  padding-bottom: 0.5em;
}
.filters .v-text-field.v-input--is-label-active {
  border-width: 1px !important;
  /* color: #ff0000 !important; */
}
.filters .v-text-field.v-input--is-label-active .v-label--active {
  /* color: #ff0000 !important; */
}
.col-align-right {
  text-align: right;
}

.hide-expansion-panel .v-expansion-panel__header {
  display: none !important;
}
.viewsize-scroll thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.viewsize-scroll .v-table__overflow {
  max-height: 90vh;
  overflow: scroll;
}
.vertical_icon {
  padding-right: 0.5em;
}
</style>
