<template>
  <v-container>
    <v-row
      class="section fill-height"
    >
      <v-col>
        <h4 class="text--secondary">
          {{ $t('Currency') }}
        </h4>
      </v-col>
    </v-row>
    <v-row
      class="section fill-height"
    >
      <v-col cols="12">
        <v-select
          v-model="currency.currencyId"
          :label="$t('Currency')"
          :items="currencyList"
          item-text="code"
          item-value="id"
          :readonly="!!task.id"
          @change="$emit('change', currency)"
        />
      </v-col>
    </v-row>
    <v-col class="section-divider">
      <v-spacer />
      <v-divider />
      <br>
    </v-col>
  </v-container>
</template>
<script>
export default {
  emits: ['change'],
  name: 'TaskCurrency',
  props: {
    task: {
      type: Object,
       default: () => {}
    },
    currencyList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currency: {
        currencyId: this.task.currencyId ?? 1
      },
    } 
  },
}
</script>

<style scoped>
</style>