<template>
  <v-row
    id="spacemodify"
    class="flex-column"
  >
    <v-dialog 
      v-model="spaceModifyDialog"
      persistent
      max-width="1200"
    >
      <AriaLive />
      <v-card>
        <SpaceModifyForm        
          :space-visibility-rights="spaceVisibilityRights"
          :is-widget-fields="isWidgetFields"
          :links="spacePartyLinks"
          :links-in-some-space="partyInSomeLink"
          :spaces="filteredSpaces"
          :parties="parties"
          :purposes-of-use="purposesOfUse"
          :cleaning-areas="cleaningAreas"
          :fire-areas="fireAreas"
          :costcenters="costcenters"
          :space-shares="spaceShares"
          :disabled="readonly"
          :contract-number-sap="contractNumberSap"
          :customizable-fields="customizableFields"
          @update="$emit('update')"
          @close="close"
          @saved="saveIndicator"
          @popup="onPopup"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SpaceModifyForm from '../modules/v3viewer/SpaceModifyForm.vue'
import AriaLive from './AriaLive.vue'

export default {
  emits: ['update', 'saveError', 'saveSuccess', 'close'],
  name: 'SpaceModify',
  components: {
    SpaceModifyForm,
    AriaLive
  },
  props: {
    spaces: {
      type: Array,
      default: () => []
    },
    customizableFields: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      spaceModifyDialog: true,
      isWidgetFields: true,
      spaceVisibilityRights: {},
      spacePartyLinks: [],
      partyInSomeLink: [],
      parties: [],
      cleaningAreas: [],
      fireAreas: [],
      costcenters: [],
      readonly: false,
      spaceShares: null,
      filteredSpaces: [],
      contractNumberSap: []
    }
  },
  computed: {
    ...mapState('sites', ['loadedSites', 'currentSiteId']),
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById', 'hasApplicationPermissionByName']),
    ...mapState('app', ['purposeZones', 'purposesOfUse'])
  },
  watch: {
    spaces: function (spaces) {
      this.filteredSpaces = spaces.filter(s => s.has_dwg === false)
      this.updatePopupData()
    }
  },
  async mounted () {
    this.getVisibilityRights()
    this.filteredSpaces = this.spaces.filter(s => s.has_dwg === false)
    this.getPurposeZones()
    this.getPurposes()
    this.getCostcenters()
    this.initialize()
  },
  methods: {
    ...mapActions('sites', ['loadSite', 'loadSiteParties']),
    ...mapActions('app', ['getPurposeZones', 'getPurposes']),
    async getVisibilityRights () {
      const visibilityRights = await this.$rambollfmapi.spaces.metadata()
      
      this.spaceVisibilityRights = visibilityRights
    },
    async initialize () {
      this.$rambollfmapi.definitions
        .cleaningarea()
        .then(res => {
          this.cleaningAreas = res
        })
        .catch(error => this.$log.info(error))

      this.$rambollfmapi.definitions
        .firearea()
        .all()
        .then(res => {
          this.fireAreas = res
        })
        .catch(error => this.$log.info(error))
    },
    saveIndicator (result) {
      this.operationResult = result
      if (result === 'error') {
        this.$emit('saveError')
      }
      if (result === 'success') {
        this.$emit('saveSuccess')
      }
    },
    onPopup (modifiedSpaces){
      this.modifiedSpaces = modifiedSpaces
      
      const w = document.getElementById('spacemodifyform').clientWidth
      const h = document.getElementById('spacemodifyform').clientHeight + 1

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom
      const top = (height - h) / 2 / systemZoom 
      
      const baseURL = window.location.href.replace(this.$route.path, '')
      window.addEventListener('message', this.receivePopupMessage)
      this.popupWindow = window.open(
        `${baseURL}/spacemodify/`,
        `myWindow${new Date()}`,
        `width=${w}, height=${h}, top=${top}, left=${left}`
      )
    },
    receivePopupMessage (event){
      if (event.data == 'READY') {
        this.windowReady = true
        this.updatePopupData(true)
      } else if (event.data == 'UPDATE'){
        this.$emit('update')
      }
    },
    updatePopupData (sendPreModifiedData) {
      let spaceData = {
        isWidgetFields: this.isWidgetFields,
        spacePartyLinks: this.spacePartyLinks,
        partyInSomeLink: this.partyInSomeLink,
        spaces: this.filteredSpaces,
        spaceShares: this.spaceShares,
        parties: this.parties,
        purposesOfUse: this.purposesOfUse,
        cleaningAreas: this.cleaningAreas,
        fireAreas: this.fireAreas,
        costcenters: this.costcenters,
        spaceVisibilityRights: this.spaceVisibilityRights,
        modifiedSpaces: sendPreModifiedData ? this.modifiedSpaces : {},
        capacityQuantity: this.capacityQuantity,
        capacityUnit: this.capacityUnit,
        readonly: this.readonly,
        contractNumberSap: this.contractNumberSap,
        customizableFields: this.customizableFields
      }
      
      this.popupWindow.postMessage(spaceData)
    },
    getCostcenters () {
      this.$rambollfmapi.organizations
        .costCenters()
        .list()
        .then(res => {
          this.costcenters = res.map(cc => {
            return {
              id: cc.id,
              name: cc.code + ' - ' + cc.name,
              allocation_rule: cc.allocation_rule,
              code: cc.code
            }
          })
        })
    },
    close () {
      this.spaceModifyDialog = false
      this.$emit('close')
    }
  }
}
</script>