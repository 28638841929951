<template>
  <v-form
    ref="photoChoosesForm"
  >
    <div
      id="draggable-container" 
      ref="draggableContainer"
      class="dialog"
    >
      <v-card>
        <div
          id="draggable-header"
          class="text-h5 grey lighten-2"
          @mousedown="dragMouseDown"
        >
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="cancel"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title 
            class="text-h5 grey lighten-2"
            primary-title
          >
            {{ $t('photos.modify_photos_chooses') }}
          </v-card-title>
        </div>
        <table class="my-4 px-1">
          <tr>
            <td style="padding-left: 0.5em">
              <div v-if="photo.idUnit">
                {{ $t('photos.linkedToUnit') }}
                <b> {{ photo.unitName }} </b>
              </div>
              <div v-else>
                {{ $t('photos.linkedToUnit') }} 
                <b> {{ $t('photos.unitIdAlternative') }}</b>
              </div>
              <div v-if="!photo.idUnit">
                - {{ $t('photos.unitHelpText') }}
              </div>
              <div v-if="photo.idUnit && photo.toMainImage || photo.moodImage || photo.logoImage || photo.unitMainImage || photo.unitMoodImage">
                - {{ $t('photos.photoLinkingHelpText') }}
              </div>
            </td>
          </tr>  
        </table>         
        <table class="my-4 px-1">
          <tr>
            <td style="padding-left: 0.5em">
              <v-tooltip
                :disabled="!isRentalPortalDisabled()"
                left
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-switch
                      v-model="photo.toRentalPortal"
                      :disabled="isRentalPortalDisabled()"
                      :label="$t('photos.toRentalPortal')"
                      hide-details
                      class="pa-0 ma-0"
                      @change="changeState(photo)"
                    />
                  </div>
                </template>
                <span>{{ $t('photos.noHighQualityToRental_tooltip') }}</span>
              </v-tooltip>
            </td>
          </tr>
          <div v-if="photo.toRentalPortal">
            <tr
              v-for="property in imageProperties"
              :key="property"
            >
              <td style="padding-left: 3em">
                <v-tooltip
                  :disabled="!selectionDisabled(property)"
                  max-width="400"
                  left
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-checkbox
                        v-model="photo[property]"
                        :disabled="selectionDisabled(property)"
                        :label="$t('photos.' + property)"
                        hide-details
                        class="pa-0 ma-0"
                        @change="changeState(photo)"
                      />
                    </div>
                  </template>
                  <span>{{ tooltipText(property) }}</span>
                </v-tooltip>
              </td>
            </tr>
          </div>
          <tr
            v-for="property in outPutImages"
            :key="property"
          >
            <td style="padding-left: 0.5em">
              <v-tooltip
                :disabled="!selectionDisabled(property, true)"
                left
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-switch
                      v-model="photo[property]"
                      :disabled="selectionDisabled(property, true)"
                      :label="$t('photos.' + property)"
                      hide-details
                      class="pa-0 ma-0"
                      @change="changeState(photo)"
                    />
                  </div>
                </template>
                <span>{{ tooltipText(property, true) }}</span>
              </v-tooltip>
            </td>
          </tr>
        </table>
        <v-divider />
      </v-card>
    </div>
  </v-form>
</template>
<script>
  export default {
  emits: ['close', 'updatePhotoList'],
    components: {  },
    props: {
      data: {
        type: Object,
        default: null
      },
      files: {
        type: Array,
        default: null
      },
      quality: {
        type: String,
        default: undefined
      }
    },
    data () {
      return {
        dialog: false,
        photo: {
          type: Object,
          default: null
        },
        positions: {
          clientX: undefined,
          clientY: undefined,
          movementX: 0,
          movementY: 0
        },
        imageProperties: ['toMainImage', 'moodImage', 'logoImage', 'unitMainImage', 'unitMoodImage'],
        outPutImages: ['toOutputImage1', 'toOutputImage2'],
        unitProperties: ['unitMainImage', 'unitMoodImage']
      }
    },
    async mounted () {
      this.photo = {...this.data}
    },
    methods: {
      cancel () {
        this.$emit('close')
      },
      dragMouseDown: function (event) {
        // kts. https://dev.to/mandrewcito/vue-js-draggable-div-3mee
        event.preventDefault()
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        document.onmousemove = this.elementDrag
        document.onmouseup = this.closeDragElement
      },
      elementDrag: function (event) {
        event.preventDefault()
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        // set the element's new position:
        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
        this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
      },
      closeDragElement () {
        document.onmouseup = null
        document.onmousemove = null
      },
      async changeState (photo){
        if (!photo.toRentalPortal){
          // clear all selections if image is taken away from rental portal
          this.imageProperties.forEach(property => {
            photo[property] = false
          })
        }
        try {
          const result = await this.$rambollfmapi.photos.put(photo.idPhoto, photo)
          this.$emit('updatePhotoList', result)
        } catch (err) {
          this.hasError = this.$t('Update failed')
          return 
        }         
      },
      isRentalPortalDisabled () {
        //Rental portal toggle is disabled with high quality and the toggle is off
        if(!this.photo.toRentalPortal && this.quality=='high'){
          return true
        }
        return false
      },
      alreadySelected (property) {
        // check if this is unit property and if unit property has been selected in other photos
        if(this.unitProperties.includes(property))
        {
          return !this.photo[property] && (this.files.filter(p => p[property] && p.idUnit === this.photo.idUnit).length > 0)
        }
        // check if setting already selected on this photo or in any other photo in site or if photo is linked to unit
        return !this.photo[property] && (this.files.filter(p=>p[property]).length > 0) || Boolean(this.photo.idUnit)
      },
      anotherPropertySelected (property, output) {
        // check if this is unit property and if other unit property has been selected
        if(this.unitProperties.includes(property))
        {
          const properties = this.unitProperties.filter(p => p !== property)
          for (const imgProp of properties) {
            if (this.photo[imgProp]) {
              return true
            }
          }
          return false
        }
        // check if some other non-unit property of this photo has already been selected
        const imageTypes = output ? this.outPutImages : this.imageProperties
        const properties = imageTypes.filter(p => p !== property && !this.unitProperties.includes(p))
        for (const imgProp of properties) {
          if (this.photo[imgProp]) {
            return true
          }
        }
        return false
      },
      selectionDisabled (property, output) {
        if(this.unitProperties.includes(property) && this.photo.idUnit === null ||
        // Next conditions disables unit properties if site mood image main image or logo image is selected
        this.unitProperties.includes(property) && this.photo.moodImage || 
        this.unitProperties.includes(property) && this.photo.toMainImage ||
        this.unitProperties.includes(property) && this.photo.logoImage ||
        // Next conditions disables site properties if unit mood image or unit main image is selected
        !this.unitProperties.includes(property) && this.photo.unitMainImage || 
        !this.unitProperties.includes(property) && this.photo.unitMoodImage) {
          return true
        }
        return this.anotherPropertySelected(property, output) || this.alreadySelected(property)
      },
      tooltipText (property, output) {
        if(this.unitProperties.includes(property))
        {
          if(this.photo.idUnit === null) {
            return this.$t('photos.unitHelpText') 
          }
          else if(this.photo.moodImage || this.photo.toMainImage) {
            return this.$t('photos.inSiteHelpText')
          }
          return this.anotherPropertySelected(property, output) ? this.$t('photos.onlyOnePropertyAllowedForUnit') : `${this.$t('photos.onlyOnePerUnitAllowed')} ${this.$t('photos.' + property).toLowerCase()} ${this.$t('photos.onlyOnePerUnitAllowedContinued')}`
        }
        if(!this.unitProperties.includes(property))
        {
          if(this.photo.idUnit) {
            return this.$t('photos.inLinkedUnitHelpText')
          }
          if(this.photo.unitMainImage || this.photo.unitMoodImage) {
            return this.$t('photos.inUnitHelpText')
          }
        }
        return this.anotherPropertySelected(property, output) ? this.$t('photos.onlyOnePropertyAllowed') : `${this.$t('photos.onlyOnePerSiteAllowed')} ${this.$t('photos.' + property).toLowerCase()} ${this.$t('photos.onlyOnePerSiteAllowedContinued')}` 
      },
    }
  }
</script>
<style scoped>
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
table {
  table-layout: fixed;
  width: 28em;
}
</style>
