<template>
  <v-row
    class="dashboard"
  >
    <v-col
      lg="8"
      md="12"
      cols="12"
    >
      <SituationAndTarget
        :title="$t(situationWidget.id)"
        :data="{
          ...situationWidget.data,
          items: selectedData
        }"
        :target-coefficiency="data.targetCoefficiency"
        :loading="loading"
        :current-set="selectedSet"
        :current-group="selectedGroup"
        :party-links="partyLinks"
        :inspection-year="inspectionYear"
        :lower-bound-quarter-dates="lowerBoundQuarterDates"
        @changeSet="changeSet"
        @update="$emit('onUpdate')"
      />
    </v-col>
    <v-col
      lg="4"
      md="6"
      cols="12"
    >
      <SituationAndTargetGraph
        :title="$t(situationGraph.id)"
        :data="{
          ...situationGraph.data,
          items: selectedData
        }"
        :target-coefficiency="data.targetCoefficiency"
        :loading="loading"
        :current-date="currentDate"
        :current-set="selectedSet"
        :current-group="selectedGroup"
        :lower-bound-quarter-dates="lowerBoundQuarterDates"
        @changeGroup="changeGroup"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SituationAndTarget from '../../components/Leasing/SituationAndTarget.vue'
import SituationAndTargetGraph from '../../components/Leasing/SituationAndTargetGraph.vue'

export default {
  emits: ['onUpdate'],
  name: 'SituationWidgets',
  components: {  SituationAndTarget, SituationAndTargetGraph },
  props: {
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    partyLinks: {
      type: Array,
      default: () => []
    },
    lowerBoundQuarterDates: {
      type: Array,
      default: () => []
    },
    inspectionYear: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedSet: { text: 'prospect.show_all', value: 'all' },
      selectedGroup: { text: 'leasing.square_meters', value: 'area', format: 'Area' },
    }
  },
  computed: {
    ...mapState('app', ['sites', 'currentDate']),
    selectedData () {
      return this.data[this.selectedGroup.value]
    },
    selectedFormat () {
      return this.selectedGroup.format
    },
    situationWidget () {
      return {
        id: 'leasing.current_year_situation_and_target',
        data: {
          headers: [
            { text: '', value: 'text' },
            { text: 'leasing.confirmed', value: 'confirmed', format: this.selectedFormat },
            { text: 'leasing.target', value:  'target', format: this.selectedFormat },
            { text: 'leasing.prediction', value: 'prediction', format: this.selectedFormat },
            { text: 'leasing.currentFree', value:  'currentFree', format: this.selectedFormat },
          ],
          browseHeaders: [
            { text: '', value: 'text' },
            { text: 'leasing.confirmed', value: 'confirmed', format: this.selectedFormat, leasing: true  },
            { text: 'leasing.target', value:  'target', format: this.selectedFormat, leasing: true },
            { text: 'leasing.prediction', value: 'prediction', format: this.selectedFormat, leasing: true  },
            { text: 'leasing.currentFree', value:  'currentFree', format: this.selectedFormat, leasing: true },
          ],
          filter: {
            header: 'prospect_type',
            type: 'equal',
            options: [
              { text: 'prospect.show_all', value: 'all' },
              { text: 'leasing.new_negotiation', value: 'new' },
              { text: 'leasing.all_renegotiations',  value: 'allRenegotiations'},
              { text: 'leasing.renegotiations_fixed', value: 'renegotiations' },
              { text: 'leasing.renewals_permanent', value: 'renewals' }
            ]
          },
          footers: [],
          items: null,
          itemsPerPage: 9,
          sortDesc: false
        },
        maximized: [true],
        loading: true
      }
    },
    situationGraph () {
      return {
        id: 'leasing.current_year_situation_and_target',
        data: {
          groups: [
            { text: 'leasing.square_meters', value: 'area', format: 'Area' },
            { text: 'leasing.rental_type', value: 'rentalType', format: 'Area' },
          ],
          items: null,
        },
        loading: true,
        maximized: [true]
      }
    }
  },
  methods: {
    changeSet (set) {
      this.selectedSet = set
    },
    changeGroup (group) {
      this.selectedGroup = group
    }
  }
}
</script>
<style scoped>
.widget {
  padding-top: 0;
}
.v-progress-circular {
  min-width: 100%;
}
</style>