<template>
  <v-text-field
    v-model="model"
    :label="label"
    :suffix="suffix"
    :readonly="readonly"
    :rules="allRules"
    @change="$emit('change', convertToNumber(model))"
    @input="$emit('input', convertToNumber(model))"
    @blur="$emit('blur', convertToNumber(model))"
  />
</template>

<script>
import helpers from '../helpers'
import { removeExtraDotsAndCommas } from '../helpers/leasing/rentalprocess.js'

export default {
  emits: ['change', 'input', 'blur'],
  name: "AreaField",
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    label: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      model: null, // string reprensentation of the value
    };
  },
  computed: {
    allRules () {
      const allRules = this.rules
      if (!this.required && (this.value === null || this.value === undefined || this.value === '')) {
        return []
      }
      allRules.push(
        (val) => /^-?([0-9\s])+([.,]+[0-9]+)?$/.test(val)
      )
      return allRules
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.model = this.convertToString(value);
      },
    },
  },
  methods: {
    // Converts the real value to a string representation
    convertToString (value) {
      if (value) {
        value = value.toString() // convert to string
        if (isNaN(value.replace(/[,.]/g, ''))) { // remove all commas and dots, if the result is not a number, return 0
          return '0'
        }
        value = value.replace(/\s+/g, '') // remove spaces
        value = removeExtraDotsAndCommas(value)
        value = Number(value.replace(/,/g, '.')) // replace commas with dots and change back to number
        return helpers.humanize.area(value)
      }
      return null; 
    },
    convertToNumber (value) {
      if (value === null || value === undefined || value === '') {
        return null
      }
      value = value.replace(/\s+/g, '') // remove spaces
      value = removeExtraDotsAndCommas(value)
      value = Number(value.replace(/,/g, '.')) // replace commas with dots and change back to number
      return value
    },
  },
}

</script>