<template>
  <v-row
    justify="end"
  >
    <v-btn
      v-if="!active"
      style="background-color: white"
      icon
      text
      rounded
      class="elevation-0 map-action-btn"
      @click="$emit('select')"
    >
      <v-icon>
        search
      </v-icon>
    </v-btn>
    <v-autocomplete
      v-if="active"
      v-model="model"
      :search-input.sync="search"
      autofocus
      auto-select-first
      item-text="name"
      item-value="graveId"
      class="white-background"
      :label="$t('Deceased')"
      prepend-icon="search"
      :single-line="true"
      clearable
      :items="items"
      :no-data-text="resultText"
      @blur="close"
    />
  </v-row>
</template>

<script>
export default {
  emits: ['select', 'found-grave', 'deselect'],
  props: {
    selectedAction: {
      type: String,
      default: null
    },
    cemeteryIds: {
      type: Array,
      default: () => []
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      model: null,
      search: null,
      items: [],
      loading: false
    }
  },
  computed: {
    resultText () {
      if (this.loading) {
        return this.$t('Searching results...')
      } else {
        return this.$t('No results')
      }
    }
  },
  watch: {
    search: function (value) {
      if (value) {
        if (this.timeout) {
          clearTimeout(this.timeout); 
        }
        this.loading = true
        this.timeout = setTimeout(() => {
          this.$rambollfmapi.graveyards.search.deceased(value, 20, this.cemeteryIds)
          .then(result => {
            this.items = result
            this.loading = false
          })
        }, 300); // delay
      }
    },
    model: function (value) {
      if (value) {
        this.$emit('found-grave', this.model)
        this.close()
      }
    }
  },
  methods: {
    close () {
      this.model = null
      this.items = []
      this.$emit('deselect')
    }
  }
}
</script>

<style scoped>
.map-action-btn {
  border: 1px solid #8b8b8b;
}
.white-background {
  background-color: white;
}
</style>