<template>
  <div>
    <AriaLive />
    <v-dialog
      v-model="saveConfirmation"
      max-width="550"
      persistent
    >
      <BaseModal
        @cancel="saveConfirmation = false"
      >
        <template #title>
          {{ $t('site_filters.default') }}
        </template>
        <template #content>
          {{ $t('site_filters.confirmation') }}
          <br>
        </template>
        <template #footer>
          <v-btn
            :disabled="saving"
            rounded
            depressed
            color="primary"
            @click="saveDefaultFilters"
          >
            <span v-if="!saving">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <BaseModal
      @cancel="$emit('close')"
    >
      <template #title>
        {{ $t('All filters') }}
      </template>
      <template #content>
        <div
          v-if="Object.keys(availableFilters).length > 0"
        >
          <v-row
            v-for="filterType in filterTypes"
            :key="filterType"
            no-gutters
            class="mb-2"
          >
            <v-expansion-panels
              v-model="expansionOpen[filterType]"
              multiple
            >
              <v-expansion-panel
                v-if="!(filterType === 'sites' && filterValues !== undefined && filterValues.length > siteLimitForFilters)"
                expand
                :class="{ 'hide-expansion-panel': false }"
              >
                <v-expansion-panel-header>
                  {{ $t(filterTypeText[filterType]) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    style="padding: 5px 10px;overflow-x:hidden;"
                    no-gutters
                  >
                    <v-col
                      v-for="filter in availableFilters[filterType]"
                      :key="filter.name"
                      class="buttonBase"
                    >
                      <v-btn
                        block
                        outlined
                        rounded
                        class="buttonHeight"
                        :class="filter.selected ? 'selected' : ''"
                        @click="toggleFilter(filter)"
                      >
                        <div
                          v-if="filter.text.length <= 35"
                          class="short"
                        >
                          {{ filter.text }}
                          <span class="d-sr-only">: {{ filter.selected ? $t('On') : $t('Off') }}</span>
                        </div>
                        <div
                          v-else-if="filter.text.length > 35 & filter.text.length <= 60"
                          class="medium"
                        >
                          {{ filter.text }}
                          <span class="d-sr-only">: {{ filter.selected ? $t('On') : $t('Off') }}</span>
                        </div>
                        <div
                          v-else
                          class="long"
                        >
                          {{ filter.text }}
                          <span class="d-sr-only">: {{ filter.selected ? $t('On') : $t('Off') }}</span>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="end"
                    no-gutters
                  >
                    <v-spacer />
                    <ToggleAll
                      :aria-label="`${$t('site_filters.in_filter_category')}: ${$t(filterTypeText[filterType])}`"
                      :selected="selectedGroupFilters(filterType)"
                      :all="allGroupFilters(filterType)"
                      @toggleUpdated="toggleGroupFilters($event, filterType)"
                    />
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-row
          align="center"
          no-gutters
        >
          <v-col
            cols="12"
            md="4"
            lg="5"
          >
            <v-btn
              text
              rounded
              outlined
              @click="saveConfirmation = true"
            >
              {{ $t('site_filters.save_default') }}
            </v-btn>
            <v-btn
              text
              rounded
              outlined
              @click="loadDefaultFilters"
            >
              {{ $t('site_filters.load_default') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-radio-group
              :value="filterType"
              row
              @change="(e) => setFilterType(e)"
            >
              <v-radio
                v-for="type in availableFilterTypes"
                :key="`radio-${type}`"
                :label="$t(`filter-type-${type}`)"
                :value="type"
              />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-row justify="end">
              <ToggleAll
                :aria-label="$t('site_filters.filters')"
                :selected="selectedFilters"
                :all="allFilters"
                @toggleUpdated="toggleAllFilters($event)"
              />
              <v-btn
                rounded
                depressed
                color="primary"
                @click="$emit('apply', convertToOldFilters(availableFilters))"
              >
                {{ $t('Save and close') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import BaseModal from './general/BaseModal.vue'
import ToggleAll from './general/ToggleAll.vue'
import AriaLive from './AriaLive.vue'
import { filterTypes as siteFilterTypes } from '../helpers/filters/SiteFilterController.js'

export default {
  emits: ['close', 'apply'],
  components: {
    BaseModal,
    ToggleAll,
    AriaLive,
  },
  data () {
    return {
      filterTypeText: {},
      expansionOpen: {},
      filterTypes: [],
      partyTypes: [],
      saveConfirmation: false,
      saving: false,
      availableFilters: {},
      availableFilterTypes: siteFilterTypes()
    }
  },
  computed: {
    ...mapState('app', [
      'portfolios',
      'availableSites',
      'userInfo',
      'allSitesParties',
      'siteFilters',
      'siteMetadata',
      'siteIds',
      'filterValues',
      'siteLimitForFilters',
      'filterType'
    ]),
    ...mapGetters('app', ['definitionById']),
    filtersList () {
      const newFilters = Object.keys(this.siteMetadata).filter(metadata => this.siteMetadata[metadata].showFilter) || []
      newFilters.push("partyFilters")
      newFilters.push("sites")
      return newFilters
    },
    selectedFilters () {
      const filters = Object.values(this.availableFilters).flat()
      return filters ? filters.filter(f => f.selected === true) : []
    },
    allFilters () {
      const filters = Object.values(this.availableFilters).flat().map(filter => ({
        ...filter,
        value: filter.text.replaceAll(' ', '_')
      }))
      return filters
    },
  },
  watch: {
    filterTypes: {
      handler: function () {
        const availableFilters = {}
        this.filterTypes.forEach(type => {
          availableFilters[type] = this.getAvailableFilters(type).sort((a, b) => a.text.localeCompare(b.text));
        })
        this.availableFilters = availableFilters;
      },
      deep: true
    },
  },
  async mounted () {
    this.$store.dispatch('app/addAriaLive', '')
    await this.setFilters()
    // add current filters to view
   
    this.initCurrentSelection()
  },
  methods: {
    ...mapMutations('app', ['setFilterType']),
    //Converts the new filter format to the old format before emiting
    convertToOldFilters (availableFilters) 
    {
      var selectedFilters = {}
      for (var type of Object.keys(availableFilters)) {
        selectedFilters[type] = availableFilters[type].filter(x => x.selected)
      }

      return selectedFilters
    },
    getAvailableFilters (type) {
      // filter either from prefetched filtervalues or from site data (default)
      const filterSites = this.filterValues === undefined ? this.availableSites : this.filterValues
      if(this.partyTypes.has(type)){
          const parties = this.allSitesParties.filter(p =>
            p.sites.find(id => filterSites.map(s => s.id_site).includes(id))
          )
          const filteredParties = parties.filter(p => p.annotationLabel === type)
          let gatheredPartyIdArr = []
          let uniqueFilteredParties = []
          filteredParties.forEach(p => {
            if (!gatheredPartyIdArr.includes(p.id)) {
              uniqueFilteredParties.push(p)
              gatheredPartyIdArr.push(p.id)
            }
          })
          const filteredType = []
          uniqueFilteredParties.forEach(p => {
            filteredType.push({ text: p.name, id: p.id, type, selected: false }) // for some reason id is name (it is used by the api)
          })
          return filteredType
      } else if (type === "sites" && filterSites.length < this.siteLimitForFilters) {
        return filterSites.map(s => {
          return { text: s.name, id: s.id_site, type, selected: false }
        })
      } else {
        return [...new Set(filterSites.map(s => s[type]))]
          .filter(s => s !== null && s !== undefined && s !== "")
          .map(s => { 
            if(this.siteMetadata[type].definitionGroupId) {
              return { text: this.getDefinitionLabelById(s), id: s, type, selected: false }
            }
            let text = s
            if (typeof s === "boolean") { 
              text = s ? this.$t("true") : this.$t("false")
            }
            return { text, id: s, type, selected: false }
        })
      }
    },
    async setFilters () {
      const newFilters = Object.keys(this.siteMetadata).filter(metadata => this.siteMetadata[metadata].showFilter) || []
      newFilters.sort((a, b) => {
            if (this.siteMetadata[a].order < this.siteMetadata[b].order) {
              return -1
            }
            if (this.siteMetadata[a].order > this.siteMetadata[b].order) {
              return 1
            }
            return 0
          })

      const hideFilters = await this.$rambollfmapi.siteFilters.hideFilters()
      const hideFilterLabels = new Set()
      hideFilters.forEach(filter => hideFilterLabels.add(filter.labelDefinition))
      const partyCategories = new Set(this.allSitesParties.map(party => party.annotationLabel).filter(filterLabel => !hideFilterLabels.has(filterLabel)))

      partyCategories.forEach(item => {
        if(item && !newFilters.includes(item)){
          newFilters.push(item)
        }
      })
      if(this.availableSites !== undefined && this.siteLimitForFilters > this.availableSites.length)
      {
        newFilters.push("sites")
      }
      this.partyTypes = partyCategories
      this.filterTypes = newFilters
    
      newFilters.forEach(res => {
        if(res === "site_status") {
          this.filterTypeText[res] = "Status"
        } else {
          this.filterTypeText[res] = this.getFieldName(res)
        }
      })
    },
    initCurrentSelection (defaultFilters = null, restoreDefaults = false) {
      const filters = defaultFilters !== null ? defaultFilters : this.siteFilters
      // filter either from prefetched filtervalues or from site data (default)
      const availableFilters = this.availableFilters
      Object.keys(availableFilters).forEach(type => {
        if (restoreDefaults) {
          availableFilters[type].forEach(filter => filter.selected = false)
        }
        if (this.partyTypes.has(type)) {
          if(filters.partyFilters[type]) {
            for(var partyFilter of filters.partyFilters[type]) {
              var filter = availableFilters[type].find(x => x.id === partyFilter)
              if(filter) {
                filter.selected = true
              }
            }
          }
        }
        else {
          if(filters[type]) {
            for (var otherFilter of filters[type]) {
              var filterOther = availableFilters[type].find(x => x.id === otherFilter)
              if(filterOther) {
                filterOther.selected = true
              }
            }
          }
        }
      })

      Object.keys(availableFilters).forEach(type =>{
        this.expansionOpen[type] = availableFilters[type].some(x => x.selected) ? [0] : []
      })
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    toggleFilter (filter) {
      filter.selected = !filter.selected
    },
    getFieldName: function (field) {
      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)
      return field
    },
    toggleAllFilters (isAllSelected) {
      if (isAllSelected) {
        Object.values(this.availableFilters).forEach(filters => {
          filters.forEach(f => f.selected = false)
        })
      } else {
        Object.values(this.availableFilters).forEach(filters => {
          filters.forEach(f => f.selected = true)
        })
      }
    },
    selectedGroupFilters (filterType) {
      return this.availableFilters[filterType].filter(f => f.selected === true)
    },
    allGroupFilters (filterType) {
      return this.availableFilters[filterType]
    },
    toggleGroupFilters (isAllSelected, filterType) {
      const filters = this.availableFilters[filterType]
      if (isAllSelected) {
        filters.forEach(f => f.selected = false)
      } else {
        filters.forEach(f => f.selected = true)
      }
    },
    async loadDefaultFilters () {
      const loadedFilters = await this.$rambollfmapi.siteFilters.list()
      const filters = []
      filters.partyFilters = {}
      loadedFilters.forEach(f => {
        const values = f.filter_values.map(value => f.is_id ? parseInt(value) : value )
        if(this.filtersList.includes(f.filter_type)) {
          filters[f.filter_type] = values
        } else if(f.filter_values.length > 0) {  
          filters.partyFilters[f.filter_type] = values
        }
      })
      // show loaded filters
      this.initCurrentSelection(filters, true)      
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.default_filters_loaded'))
    },
    async saveDefaultFilters () {
      this.saving = true
      const filters = this.convertToOldFilters(this.availableFilters)
      const saveFilters = Object.keys(filters).filter(f => filters[f].length > 0)
        .map(f =>
              {
                return {
                  filter_type: f,
                  filter_values: filters[f].map(item => item.id.toString()),
                  is_id: typeof(filters[f][0].id) != 'string'
                }
              }
            )
      await this.$rambollfmapi.siteFilters.put(saveFilters)
      this.saving = false
      this.saveConfirmation = false
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.default_filters_saved_and_closed'))
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 960px) {
  .buttonBase {
    height: 60px !important;
  }
  .buttonHeight {
    margin-left: 0px !important;
    height: 90% !important;
  }
  .short {
    width: 95%;
  }
  .medium {
    width: 65%;
  }
  .long {
    width: 40%;
  }
}
</style>
