import RambollFMAPI from '../api/rambollfm.js'
// import { i18n } from '../plugins/i18n.js'
import loglevel from 'loglevel'
import store from '../store/store.js'


function GetAllocated (report) {
  const result = report.reduce((acc, cur) => {
    const key = cur.siteName + cur.allocationCode
    
    // first level
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        site_id: cur.idSite,
        site_name: cur.siteName,
        net_floor_area: 0,
        allocation_code: cur.allocationCode,
        id_code: cur.idCode,
        person_count: 0,
        total_person_count: 0
      }
    }
    if (typeof acc[key][cur.idUnit] === 'undefined') {
      acc[key][cur.idUnit] = null
      acc[key].net_floor_area += cur.netFloorArea
    }

    // second level
    if (cur.costcenterCode && cur.allocationCode) {
      if (typeof acc[key].subItems === 'undefined') {
        acc[key].subItems = {},
        acc[key].showToggle = true
      }
      if (typeof acc[key].subItems[cur.costcenterCode] === 'undefined') {
        acc[key].subItems[cur.costcenterCode] = {
          site_name: cur.siteName,
          net_floor_area: null,
          costcenter_code: cur.costcenterCode,
          costcenter: cur.costcenter,
          person_count: cur.personCount ?? 0,
          total_person_count: 0,
          id_costcenter: cur.idCostcenter
        }

        acc[key].person_count += acc[key].subItems[cur.costcenterCode].person_count
      }
      // third level
      if (cur.purposeZone1) {
        if (typeof acc[key].subItems[cur.costcenterCode].subItems === 'undefined') {
          acc[key].subItems[cur.costcenterCode].subItems = {},
          acc[key].subItems[cur.costcenterCode].showToggle = true
        }
        if (cur.purposeZone1 && typeof acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1] === 'undefined') {
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1+'_'+cur.idCostcenter] = {
            site_name: cur.siteName,
            purpose_zone_1: cur.purposeZone1,
            ppz1: cur.purposeZone1,
            net_floor_area: 0,
            person_count: 0,
            allocation_percentage: 0,
            person_count_internal: 0,
            person_count_locked: false,
            person_count_external: 0,
            total_person_count: 0,
            id_code: cur.idCode,
            id_purposezone: cur.idPurposezone,
            id_costcenter: cur.idCostcenter
          }
        }
        if (typeof acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1+'_'+cur.idCostcenter][cur.idUnit] === 'undefined' && cur.netFloorArea > 0) {
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1 + '_' + cur.idCostcenter].net_floor_area += cur.netFloorArea
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1 + '_' + cur.idCostcenter].allocation_percentage = cur.percentage ?? 0,
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1 + '_' + cur.idCostcenter].person_count_internal = cur.personCountInternal ?? 0,
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1 + '_' + cur.idCostcenter].person_count_locked = cur.lockPersonCount ?? false,
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1 + '_' + cur.idCostcenter].person_count_external = cur.personCountExternal ?? 0,
          acc[key].subItems[cur.costcenterCode].subItems[cur.purposeZone1 + '_' + cur.idCostcenter][cur.idUnit] = null
        }
      }
    }
    return acc
  }, [])
  
  // Convert items and subitems to arrays
  var allocatedCodes = Object.keys(result).map(level1 => {
    if (typeof result[level1].subItems !== 'undefined') {
      result[level1].subItems = Object.keys(result[level1].subItems).map(level2 => {
        if (typeof result[level1].subItems[level2].subItems !== 'undefined') {
          result[level1].subItems[level2].subItems = Object.keys(result[level1].subItems[level2].subItems).map(level3 => {
            const res3Val = result[level1].subItems[level2].subItems[level3]
            return res3Val
          })
        }
        const res2Val = result[level1].subItems[level2]
        const somePersonCountLocked = res2Val.subItems.some(subItem => subItem.person_count_locked)
        res2Val.subItems.map(subItem => subItem.some_person_count_locked = somePersonCountLocked)
        return res2Val
      })
    }
    const val = result[level1]
    return val
  })

  // calculate total person count
  allocatedCodes.map(level1 => {
    if (level1.subItems) {
      level1.subItems.map(level2 => {
        var pcToAllocate = level2.person_count
        if (level2.subItems) {
          const itemsOverRule = level2.subItems.filter(level3 => level3.person_count_locked)
          const itemsBoundToRule = level2.subItems.filter(level3 => !level3.person_count_locked)
          itemsOverRule.map(item => {
            if (item.person_count_internal <= pcToAllocate) {
              item.person_count = item.person_count_internal
            } else {
              item.person_count = pcToAllocate
            }
            pcToAllocate -= item.person_count
            item.total_person_count = item.person_count + item.person_count_external
            level2.total_person_count += item.total_person_count
            level1.total_person_count += item.total_person_count
            
            if (pcToAllocate < 0) {
              pcToAllocate = 0
            }
          })
          var remainingPc = pcToAllocate
          itemsBoundToRule.map(item => {
            var calculatedPc = pcToAllocate * (item.allocation_percentage / 100)
            if (calculatedPc > remainingPc) {
              item.person_count = remainingPc
            } else {
              item.person_count = calculatedPc
              remainingPc -= calculatedPc
            }
            item.total_person_count = item.person_count + item.person_count_external
            item.person_count_internal = (Math.round((pcToAllocate * (item.allocation_percentage / 100) * 2) / 2).toFixed(0))
            level2.total_person_count += item.total_person_count
            level1.total_person_count += item.total_person_count
          })
        }
      })
    }
  })

  return allocatedCodes
}

function GetUnallocated (report) {
  var result = report.reduce((acc, cur) => {
    const key = cur.idCode + '_' + cur.allocationCode

    // first level
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        id_code: cur.idCode,
        allocation_code: cur.allocationCode,
        person_count: 0
      }
    }

    // second level
    if (cur.costcenterCode && cur.allocationCode) {      
      if (typeof acc[key].subItems === 'undefined') {
        acc[key].subItems = {},
        acc[key].showToggle = true
      }
      if (typeof acc[key].subItems[cur.costcenterCode] === 'undefined') {
        acc[key].subItems[cur.costcenterCode] = {
          costcenter_code: cur.costcenterCode,
          costcenter: cur.costcenter,
          person_count: 0,
          person_count_to_divide: 0,
          person_count_divider: 0
        }
      }
      acc[key].subItems[cur.costcenterCode].person_count_to_divide += cur.personCount
      acc[key].subItems[cur.costcenterCode].person_count_divider += 1
    }
    return acc
  }, [])

  // Convert items and subitems to arrays and calculate person count
  var unallocatedCodes = Object.keys(result).map(level1 => {
    if (typeof result[level1].subItems !== 'undefined') {
      result[level1].subItems = Object.keys(result[level1].subItems).map(level2 => {
        result[level1].subItems[level2].person_count = (result[level1].subItems[level2].person_count_to_divide / result[level1].subItems[level2].person_count_divider)
        result[level1].person_count += result[level1].subItems[level2].person_count
        return result[level1].subItems[level2]
      })
    }
    return result[level1]
  })
  
  return unallocatedCodes
}

function GetFaultyAllocations (report) {
  var result = report.reduce((acc, cur) => {
    const key = cur.idCode + '_' + cur.allocationCode

    // first level
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        id_code: cur.idCode,
        allocation_code: cur.allocationCode,
        person_count: 0
      }
    }

    // second level
    if (cur.personCount) {           
      if (typeof acc[key].subItems === 'undefined') {
        acc[key].subItems = {},
        acc[key].showToggle = true
      }
      if (typeof acc[key].subItems[cur.costcenterCode] === 'undefined') {
        acc[key].subItems[cur.costcenterCode] = {
          costcenter_code: cur.costcenterCode,
          costcenter: cur.costcenter,
          person_count: 0,
          person_count_to_divide: 0,
          person_count_divider: 0
        }
      }
      acc[key].subItems[cur.costcenterCode].person_count_to_divide += cur.personCount
      acc[key].subItems[cur.costcenterCode].person_count_divider += 1
    }
    return acc
  }, [])

  // Convert items and subitems to arrays and calculate person count
  var unallocatedCodes = Object.keys(result).map(level1 => {
    if (typeof result[level1].subItems !== 'undefined') {
      result[level1].subItems = Object.keys(result[level1].subItems).map(level2 => {
        result[level1].subItems[level2].person_count = (result[level1].subItems[level2].person_count_to_divide / result[level1].subItems[level2].person_count_divider)
        result[level1].person_count += result[level1].subItems[level2].person_count
        return result[level1].subItems[level2]
      })
    }
    return result[level1]
  })
  
  return unallocatedCodes
}

export default {
  namespaced: true,
  state: {
    loadedWidgetData: [],
    apiEndpoint: null,
    apiToken: null
  },
  getters: {
    dataForWidget: (state) => (widgetName) => {
      const found = state.loadedWidgetData.find(row => row.name === widgetName)
      if (found) {
        return found.data
      }
      return null
    }
  },
  mutations: {
    setWidgetData (state, { name, data }) {
      let names = name
      if (!Array.isArray(name)) {
        names = [name]
      }

      names.forEach(name => {
        const existing = state.loadedWidgetData.findIndex(r => r.name === name)
        if (existing >= 0) {
          state.loadedWidgetData.splice(existing, 1)
        }

        state.loadedWidgetData.push({ name: name, data: data })
      })
    }
  },
  actions: {
    async loadWidgetData ({ commit, rootState, state }, time, siteIds) {
      const perfStart = performance.now()

      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = time ?? rootState.app.currentDate

      if (siteIds === undefined || siteIds === null) {
        siteIds = rootState.app.siteIds.sites
      }

      var sitesArr = new Array()
      for (var key in siteIds) {
        let value = siteIds[key]
          sitesArr.push({ 'id_site': value})
      }
      
      let buildings = await api.buildings.simpleview()
      const buildingIds = buildings.filter(b => sitesArr.find(s => s.id_site === b.id_site)).map(b => b.id_building)

      if (buildingIds.length === 0) {
        return
      }

      // We need to know which report is used for which widget
      const requiredReports = {
        'person_allocation.allocated_personnel': ['GetPersonnelAllocations'],
        'person_allocation.unallocated_personnel': ['GetUnallocatedPersonnel'],
        'person_allocation.faulty_person_count_codes': ['GetFaultyPersonnelAllocations']
      }
      
      const widgets = [
        { name: 'person_allocation.allocated_personnel'},
        { name: 'person_allocation.unallocated_personnel'},
        { name: 'person_allocation.faulty_person_count_codes'}
      ]

      const allReports = widgets.map(w => w.name).reduce((acc, cur) => {
        if (requiredReports[cur]) {
          acc.push(...requiredReports[cur])
        }
        return acc
      }, []).filter((val, idx, arr) => arr.indexOf(val) === idx)
      const reports = await api.reports.list(allReports, buildingIds, currentDate)

      // If a report has null value then there is some problem with the data received frm the API.
      Object.keys(reports).forEach(key => {
        if (reports[key] === null) {
          loglevel.error('Report with name: ' + key + ' received no data from the API.')
          store.dispatch('error/addError', 'err.report_no_data')
          reports[key] = []
        }
      })

      // report data loaded. commit it to the store
      for (const w of widgets) {
        let data = null
        // We do special handling of data here
        try {
          if (w.name === 'person_allocation.allocated_personnel') {
            const {GetPersonnelAllocations} = reports
            data = GetAllocated(GetPersonnelAllocations)
          } else if (w.name === 'person_allocation.unallocated_personnel') {
            const {GetUnallocatedPersonnel} = reports
            data = GetUnallocated(GetUnallocatedPersonnel)
          } else if (w.name === 'person_allocation.faulty_person_count_codes') {
            const {GetFaultyPersonnelAllocations} = reports
            data = GetFaultyAllocations(GetFaultyPersonnelAllocations)
          } else { 
            // See if the widget gets it's data straight from the report and requires no special handling.
            const requiredReportForWidget = requiredReports[w.name]
            if (requiredReportForWidget !== undefined && requiredReportForWidget.length > 0) {
              data = reports[requiredReportForWidget[0]]
            }
          }
        } catch (error) {
          loglevel.error('Error trying to process data for widget: "' + w.name + '"', error)
          store.dispatch('error/addError', 'err.report_data_handling_failed')
        }

        if (data !== null) {
          commit('setWidgetData', {
            name: w.name,
            data: data
          })
        }
      }

      loglevel.debug('Widget data loading took ' + (performance.now() - perfStart) + ' ms.')
    },
    async updateAllocationConfiguration ({ state }, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const { rows, time } = data
      await api.internalrent.personnelAllocations.updateConfiguration(rows, time)
    },
    async updateAllocation ({ state }, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const { codes, time } = data
      await api.internalrent.personnelAllocations.updateAllocation(codes, time)
    },
    async getAllocationExtraInfo ({state}, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const { allocationCode, time } = data
      const allocationExtraInfo = await api.internalrent.personnelAllocations.getAllocationExtraInfo(allocationCode, time)
      return allocationExtraInfo
    }
  }
}
