<template>
  <div>
    <v-dialog
      v-model="outdoorModifyDialog"
      max-width="1200"
    >
      <v-card>
        <OutDoorModifyForm
          :outdoors="selectedOutdoorsFromViewer"
          :disabled="readonly"
          :outdoors-visibility-rights="outdoorsVisibilityRights"
          @update="$emit('update')"
          @close="outdoorModifyDialog = false"
        />
      </v-card>
    </v-dialog>
    <!-- SINGLE OUTDOORS BOX -->
    <v-expansion-panels
      v-if="selectedOutdoorsFromViewer.length"
      style="margin-top: 0.5em"
      :value="selectedOutdoorsFromViewer.length ? [0] : null"
      multiple
    >
      <v-expansion-panel
        v-if="selectedOutdoorsFromViewer.length === 1"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>emoji_nature</v-icon>
            {{ $t('Region') + ': ' + selectedOutdoorsFromViewer[0].id_outdoor }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :metadata="metadataForOutdoors()"
            :value="selectedOutdoorsFromViewer[0]"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- OUTDOORS AS EXPAND -->
      <v-expansion-panel
        v-if="selectedOutdoorsFromViewer.length > 1"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>emoji_nature</v-icon>
            {{ $t('Outdoor areas') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            dense
            class="info-box"
          >
            <v-list-item class="tile">
              <v-list-item-content class="tile-content">
                {{ $t('Selection') }}
              </v-list-item-content>
              <v-list-item-content
                class="tile-content"
              >
                {{ selectedOutdoorsFromViewer.length + ' Ulkoaluetta' }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div
            v-for="o in selectedOutdoorsFromViewer"
            :key="o.id_outdoor"
            class="inner-expansion-container"
          >
            <v-expansion-panel class="inner-expansion">
              <v-expansion-panel-header>
                {{ o.id_outdoor }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <MetadataForm
                  :value="o"
                  :metadata="metadataForOutdoors()"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- OUTDOOR EDIT MENU -->
      <v-row
        v-if="selectedOutdoorsFromViewer.length > 0 && isAnyFieldEditable()"
        id="outdoorModifyButton"
        align="end"
        justify="end"
      >
        <v-menu
          v-if="!readonly && selectedOutdoorsFromViewer.length"
          offset-y
        >
          <template
            #activator="{ on }"
          >
            <v-btn
              text
              rounded
              outlined
              align-end
              @click="outdoorModifyDialog = true"
            >
              <v-icon>edit</v-icon>
              {{ $t('Edit outdoor information') }}
            </v-btn>
          </template>
        </v-menu>
      </v-row>
    </v-expansion-panels>
  </div>
</template>

<script>
import MetadataForm from '../../components/MetadataForm.vue'
import OutDoorModifyForm from './OutDoorModifyForm.vue'

export default {
  name: 'OutDoorBox',
  components: { MetadataForm, OutDoorModifyForm },
  props: {
    selectedOutdoorsFromViewer: { default: null },
    show: { default: null },
    outdoorsVisibilityRights: { type: Object, default: null },
    readonly: { type: Boolean, default: null },
  },
  emits: ['update'],
  data () {
    return {
      outdoorModifyDialog: false,

    }
  },
  methods:
  {
      isAnyFieldEditable ()
      {
        if(this.outdoorsVisibilityRights === null)
        return false
        const propertyValues = Object.values(this.outdoorsVisibilityRights);
        return  propertyValues.some(x => x.editType !== "None")
      },
      showOutdoorsProp (prop) {
        const metadata = this.outdoorsVisibilityRights[prop]
        if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        return { isShown, category, order, format }
        }
        else {
          return { isShown: false, category: 'None', order: 999, format: 0 }
        }
      },
      metadataForOutdoors () {
      let OutdoorMetadata = {
        type: {
          ...this.showOutdoorsProp('type')
        },
        surface: {
          ...this.showOutdoorsProp('surface')
        },
        maintenance: {
          ...this.showOutdoorsProp('maintenance')
        },
        area: {
          ...this.showOutdoorsProp('area')
        },
        snow: {
          ...this.showOutdoorsProp('snow'),
          isDefinition:true
        },
        maintenance_classification: {
          ...this.showOutdoorsProp('maintenance_classification'),
          isDefinition:true
        },
      }
      return OutdoorMetadata
    },
  }
}

</script>

<style>
</style>
