<template>
  <div>
    <h3>{{ $t("dataimport.import_success") }}</h3>
    <br>
    <h4>{{ $t("dataimport.rows_imported") + " " + itemsLength }}</h4>
    <br>
    <div
      v-for="change in changesList"
      :key="change.text"
    >
      <h4>{{ change.text + " " + change.changes.length }}</h4>
    </div>
    <h4>{{ $t("dataimport.change_errors") + " " + errors.length }}</h4>
    <h4>
      {{ $t("dataimport.items_not_changed") + " " + itemsNotChanged }}
    </h4>
    <br>
    <div
      v-for="widget in widgets"
      :key="widget.id"
    >
      <div
        v-if="shouldShowWidget(widget)"
        class="mb-2"
      >
        <dynamic-widget
          :type="widget.type"
          :title="$t(widget.id)"
          :data="{
            ...widget.data,
            items: dataForWidget(widget),
          }"
          :loading="widget.loading"
          :maximized="widget.maximized"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DynamicWidget from '../../../DynamicWidget.vue'

export default {
  name: "ImportChangesView",
  components: {
    DynamicWidget,
  },
  props: {
    // List lengths
    itemsLength: { type: Number, default: 0 },
    itemsNotChanged: { type: Number, default: 0 },

    errors: { type: Array, default: () => [] },

    // Changes list
    changesList: {
      type: Array,
      default: () => [
        { changes: [], text: this.$t("dataimport.list_changes") },
        { changes: [], text: this.$t("dataimport.new_contracts") },
      ],
    },
    widgets: { type: Array, default: () => []}
  },
  computed: {
    newContracts () {
      if (!this.changesList) {
        return []
      }

      const listObject = this.changesList.find(
        (obj) => obj.text === this.$t("dataimport.new_contracts")
      )

      if (listObject && listObject.changes) {
        return listObject.changes
      }

      return []
    },
    changedContracts () {
      if (!this.changesList) {
        return []
      }

      const listObject = this.changesList.find(
        (obj) => obj.text === this.$t("dataimport.list_changes")
      )

      if (listObject && listObject.changes) {
        return listObject.changes
      }

      return []
    },
  },
  methods: {
    shouldShowWidget (widget) {
      return widget.shouldShow(this)
    },
    dataForWidget (widget) {
      return widget.dataFetcher(this)
    },
  },
}
</script>

<style></style>
