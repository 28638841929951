import { render, staticRenderFns } from "./RentingProcess.vue?vue&type=template&id=5b86341a&scoped=true"
import script from "./RentingProcess.vue?vue&type=script&lang=js"
export * from "./RentingProcess.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b86341a",
  null
  
)

export default component.exports