<template>
  <div
    id="generalModifyForm"
  >
    <BaseModal
      @cancel="emit"
    >
      <template #title>
        {{ $t(title) }}
      </template>
      <template #content>
        <div>
          <p class="font-italic">
            {{ infoText }}
          </p>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row
            v-if="items.length > 0"
          >
            <v-card
              v-show="groupUpdate && items.length > 1"
              elevation="0"
            >
              <table
                class="v-datatable v-data-table v-table them--light table-overflow"
              >
                <thead>
                  <th class="column">
                    {{ $t('Group update') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <th class="column">
                      {{ $t('New values') }}
                    </th>
                    <GenericModifyField
                      v-for="field in filteredFields"
                      :key="field.id"
                      v-model="groupModifiedFields[field.id]"
                      :field-type="field.fieldType"
                      :items="field.items"
                      :value="groupModifiedFields[field.id]"
                      :rules="field.rules"
                    />
                  </tr>
                  <tr>
                    <td
                      v-for="field in filteredFields"
                      :key="field.value"
                      style="border: 0 !important;"
                    >
                      <v-btn
                        outlined
                        rounded
                        block
                        @click="setToAllFields(field)"
                      >
                        {{ $t('Set to all') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card>
            <v-card
              elevation="0"
            >
              <table class="v-datatable v-data-table v-table them--light table-overflow">
                <thead>
                  <th class="column">
                    {{ header }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                  >
                    <th class="column">
                      {{ item.item_code }}
                    </th>
                    <GenericModifyField
                      v-for="field in filteredFields"
                      :key="item.id + field.id"
                      v-model="modifiedItems[item.id][field.id]"
                      :field-type="field.fieldType"
                      :items="field.items"
                      :value="modifiedItems[item.id][field.id]"
                      :rules="field.rules"
                    />
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-row>
        </v-form>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <columns-chooser
          v-model="visibleColumns"
          :stored-view-parent="'facilitymanagement'"
          :headers="editableFields"
          header-value="value"
          header-text="header"
          :small="false"
        />
        <v-btn
          v-if="items.length > 1"
          text
          outlined
          rounded
          @click="groupUpdate = !groupUpdate"
        >
          {{ $t('Group update') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          rounded
          outlined
          @click="emit"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          :disabled="!valid || isSaving"
          rounded
          depressed
          color="primary"
          @click="validate"
        >
          {{ $t('Save') }}
          <v-progress-circular
            v-if="isSaving"
            size="16"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import GenericModifyField from './GenericModifyField.vue'
import { mapState } from 'vuex'
import humanize from '../../helpers/humanize.js'
import BaseModal from '../general/BaseModal.vue'
import ColumnsChooser from '../../components/general/ColumnsChooser.vue'
export default {
  name: 'GeneralModifyForm',
  components: {
    GenericModifyField,
    ColumnsChooser,
    BaseModal,
  },
  props: {
    disabled: { type: Boolean, default: null },
    items: { type: Array, default: null },
    header: { type: String, default: null },
    infoText: { type: String, default: null },
    fields:  { type: Array, default: () => [] },
    title: { type: String, default: null },
  },
  emits: ['close', 'update', 'patch'],
  data () {
    return {
      groupUpdate: false,
      groupModifiedFields: {},
      modifiedItems: {},
      valid: true,
      isSaving: false,
      currentVisibility: false,
      visibleColumns: [],
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    editableFields () {
      return this.fields.filter(field => field.show)
    },
    filteredFields () {
      return this.fields.filter(this.isFieldVisible);
    }
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      async handler (oldVal) {
        this.modifiedItems = {};
        oldVal.forEach(element => {
          const modifiedItem = {};
          Object.keys(element).forEach(key => {
            modifiedItem[key] = element[key];
          });
          this.$set(this.modifiedItems, element.id, modifiedItem);
        });
      }
    },
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
  },
  mounted () {
    this.setVisibilities()
  },
  methods: {
    isFieldVisible (field) {
      const fieldValueFound = this.items.some(item => item[field.id] !== undefined);
      const fieldSetAsVisible = this.visibleColumns.includes(field.value);
      return fieldValueFound && fieldSetAsVisible && field.show
    },
    emit () {
      this.$emit('update')
      this.close()
      this.$refs.form.reset()
    },
    close () {
      this.currentVisibility = false
      this.groupModifiedFields = {}
      this.groupUpdate = false
      this.$emit('close')
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'date')
    },
    setToAllFields (field) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedItems)) {
          this.modifiedItems[key][field.id] = JSON.parse(
            JSON.stringify(this.groupModifiedFields[field.id])
          )
        }
      })
    },
    async validate () {
      this.isSaving = true
      this.patch()
      this.isSaving = false
      this.$emit('close')
    },
    patch () {

      for (const item in this.modifiedItems) {

        const itemObject = this.items.find(e => {
          return e.id === parseInt(item)
        })

        const modifiedItemsCopy = JSON.parse(JSON.stringify(this.modifiedItems))

        Object.keys(modifiedItemsCopy[item]).forEach(key =>{
          if(Array.isArray(modifiedItemsCopy[item][key])) {
            modifiedItemsCopy[item][key] = modifiedItemsCopy[item][key].join(", ")
          }
        })

        const itemCopyForItem = Object.fromEntries(Object.entries(itemObject))

        const itemObserverForItem = this.$jsonpatch.observe(itemCopyForItem)

        Object.keys(itemCopyForItem).forEach(key => {
          const value = modifiedItemsCopy[item][key]

          if (value !== undefined) {
            const field = this.fields.find(field => field.id === key)

            if (field?.fieldType === 'number_field' && (isNaN(value) || value === '')) {
              modifiedItemsCopy[item][key] = null
            }

            itemCopyForItem[key] = modifiedItemsCopy[item][key]
          }
        })

        const itemPatch = this.$jsonpatch.generate(itemObserverForItem)

        if (itemPatch.length) {
          const time = this.currentDate.toISOString().slice(0, 10);
          this.$emit('patch', item, time, itemPatch)
        }
      }
      this.$emit('update')
    },
    setVisibilities () {
      this.visibleColumns = []
      this.fields.forEach(field => {
        this.visibleColumns.push(field.value)
      })
    },
  }
}
</script>
<style scoped>
.v-datatable tbody tr td v-text-field {
  width: 100px;
}
.v-datatable tbody tr td {
  vertical-align: 0%;
}
</style>
