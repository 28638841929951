!
<template>
  <BaseModal @cancel="$emit('close')">
    <template #title>
      <span v-if="contract && !contract.type_renewal">{{
        $t("leasing.no_renegotiations")
      }}</span>
      <span v-else>{{ $t("leasing.no_renewal") }}</span>
    </template>
    <template #content>
      <p class="text-left">
        <span
          v-if="contract && !contract.type_renewal"
          class="font-weight-bold text-subtitle-1"
        >{{ $t("leasing.no_renegotiatios_pick_reason") }}</span>
        <span
          v-else
          class="font-weight-bold text-subtitle-1"
        >{{
          $t("leasing.no_renewal_pick_reason")
        }}</span>
        <br>
        <span class="font-italic text-subtitle-2">{{
          $t("leasing.no_renegotiations_clarification")
        }}</span>
      </p>
      <v-select
        v-model="inactivationReasonChoice"
        :items="inactivationReasons"
        item-text="label"
        :label="$t('Reason')"
        return-object
        class="normal-height-select"
      >
        <template #selection="{ item }">
          {{ $t(`${item.label}`) }}
        </template>
        <template #item="{ item }">
          {{ $t(`${item.label}`) }}
        </template>
      </v-select>
      <br>
      <p>
        <span class="font-weight-bold text-subtitle-1">{{
          $t("leasing.no_renegotiations_pick_date")
        }}</span>
        <br>
        <span class="font-italic text-subtitle-2">{{
          $t("leasing.no_renegotiations_pick_date_clarification")
        }}</span>
      </p>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn
            rounded
            outlined
            class="gray-outline ml-0"
            v-on="on"
          >
            <v-icon> calendar_today </v-icon>
            {{ passedDateAsString }}
          </v-btn>
        </template>
        <v-date-picker
          v-model="passedDate"
          no-title
          scrollable
          first-day-of-week="1"
          :locale="$i18n.locale"
        />
      </v-menu>
      <br>
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        color="primary"
        :disabled="inactivationReasonChoice == null"
        :loading="prospectSaving"
        @click="save"
      >
        {{ $t("Save") }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex"
import humanize from '../../helpers/humanize.js'
import BaseModal from '../general/BaseModal.vue'

export default {
  emits: ['close'],
  name: "NoRenegotiationsModal",
  components: { BaseModal },
  props: {
    contract: {
      type: Object,
      default: null,
    },
    prospectSaving: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "PROSPECT"
    }
  },
  data () {
    return {
      inactivationReasonChoice: null,
      passedDate: null,
    }
  },
  computed: {
    ...mapGetters("app", ["definitionsByGroupLabel"]),
    inactivationReasons () {
      
      if(this.type === "RENTAL_PROCESS"){
        return this.definitionsByGroupLabel("rentalprocess.abandonedreason")
      }

      return this.definitionsByGroupLabel("prospect.abandonedreason")
    },
    passedDateAsString () {
      const date = new Date(this.passedDate)
      return humanize.date(date.toISOString())
    },
  },
  mounted () {
    this.passedDate = new Date().toISOString()
  },
  methods: {
    save () {
      this.$emit("save", {
        inactivationReasonChoice: this.inactivationReasonChoice,
        passedDate: this.passedDate,
      })
    },
  },
}
</script>

<style scoped>
::v-deep .v-select__selections {
  min-height: 75px !important;
}
.normal-height-select ::v-deep .v-select__selections {
  min-height: inherit !important;
}
</style>
