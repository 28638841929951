
<template>
  <div>
    <ValidationObserver ref="observer">
      <v-tab-item
        :key="3"
      >
        <v-form
          ref="form1"
          class="pt-2"
        >
          <ContractSignatories
            :landlord-id="landlordId"
            :tenant-id="tenantId"
            :other-tenant-id="otherTenantId"
            :id-landlord-contact-person="idLandlordContactPerson"
            :landlord-signatory="landlordSignatory"
            :tenant-signatories="tenantSignatories"
            :tenant-type="tenantType"
            :first-tab-tenant-type-change="firstTabTenantTypeChange"
            :all-parties="allParties"
            :is-disabled="isSigningDisabled"
            :is-out-rent="isOutRent"
            :can-use-electronic-signing="canUseElectronicSigning"
            :request-state="requestState"
            :is-electronic-signing="isElectronicSigning"
            @updated="onSignatoriesUpdated"
          />
          <h4 class="ml-2">
            {{ $t('create_rent_contract.preview_the_contract') }}
          </h4>
          <p
            v-if="isNewOrDraft"
            class="mt-2 ml-2 mb-2"
          >
            {{ signatureMethod === 'noESignature' ? $t('create_rent_contract.esignature_infotext2') : $t('create_rent_contract.esignature_infotext') }}
          </p>
          <v-btn
            rounded
            depressed
            class="blue-button"
            @click="$emit('downloadContract')"
          >
            <v-icon small>
              download
            </v-icon>
            {{ $t('create_rent_contract.download_contract') }}
          </v-btn>

          <div
            v-if="showValidationMessage && requiredFieldValidationMessages.length > 0"
            cols="12"
          >
            <CdsAlert
              type="danger"
              :title="$t('Following mandatory contract fields should be filled')"
              class="mt-4"
            >
              <ul>
                <li
                  v-for="requiredFieldValidationMessage in requiredFieldValidationMessages"
                  :key="requiredFieldValidationMessage"
                >
                  {{ requiredFieldValidationMessage }}
                </li>
              </ul>
            </CdsAlert>
          </div>
        </v-form>
      </v-tab-item>
    </ValidationObserver>
  </div>
</template>
<script>
import helpers from '../../../../helpers'
import { ValidationObserver } from 'vee-validate'
import CdsAlert from '../../../CircleDesignSystem/CdsAlert.vue'
import ContractSignatories from './ContractSignatories'

export default {
  name: "SendContractForSigningTabVue",
  components: {
    CdsAlert,
    ContractSignatories,
    ValidationObserver
  },
  props: {
    tenantId: {
      type: Number,
      default: undefined
    },
    otherTenantId: {
      type: Number,
      default: undefined
    },
    landlordId: {
      type: Number,
      default: undefined
    },
    idLandlordContactPerson: {
      type: Number,
      default: undefined
    },
    tenantType: {
      type: String,
      default: undefined
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    requiredFieldValidationMessages: {
      type: Array,
      default: () => []
    },
    showValidationMessage: {
      type: Boolean,
      default: false
    },
    allParties: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    settings: {
      type: Object,
      default: () => undefined
    },
    firstTabTenantTypeChange: {
      type: Boolean,
      default: false
    }
  },
  emits: ['downloadContract', 'isPageValidChanged', 'updated'],
  data () {
    return {
      signatureMethod: 'eSignature',
      isElectronicSigning: true,
      landlordSigned: false,
      landlordSignatoryDate: undefined,
      landlordSignatory: this.idLandlordContactPerson ? this.idLandlordContactPerson : undefined,
      tenantSignatories: [],
      tenantSignatoryIds: [],
      signedContract: undefined,
      retrievalProgress: 0,
      isRetrievingFile: false,
      isPageValid: false,
      canUseElectronicSigning: true,
      requestState: undefined
    }
  },
  computed: {
    isNewOrDraft () {
      return !this.contract || this.contract?.isDraft
    },
    isSigned () {
      return (this.tenantSignatories !== undefined &&
         this.tenantSignatories.some(s => s.signed === true)) ||
         (this.landlordSignatory !== undefined &&
         this.landlordSignatory.signed === true)
    },
    isSigningDisabled () {
      return !this.isNewOrDraft
        || this.isSigned
        || (this.settings.length > 0 && !this.settings.canUseElectronicSigning)
    },
  },
  watch: {
    async showValidationMessage () {
      this.isPageValid = Object.keys(this.$refs).length > 0 ? await this.$refs.observer.validate() : false
      this.$emit("isPageValidChanged", this.isPageValid)
    },
    contract () {
      if (this.contract) {
        this.updateDataFromExistingContract()
      }
    },
    signatureMethod () {
      if (this.canUseElectronicSigning == false) {
        this.signatureMethod = 'noESignature'
      }
      this.isElectronicSigning = this.signatureMethod === 'eSignature'
      this.onDataChanged()
    },
    settings: function () {
      if (this.settings.length === 0) {
        this.canUseElectronicSigning = true;
      }
      else {
        this.canUseElectronicSigning = this.settings.canUseElectronicSigning
      }

      if (this.canUseElectronicSigning == false) {
        this.signatureMethod = 'noESignature'
      }
    }
  },
  methods: {
    downloadSignedContract () {
      const id = this.contract.contractPDFId
      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      const self = this
      this.$rambollfmapi.documents
        .download(id, progress)
        .then(res => {
          self.isRetrievingFile = false
          self.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          const filename = res.headers["content-disposition"].match(/filename\s*=\s*(.+);/i)[1].replaceAll('"', '');
          helpers.saveAs(blob, filename)
        })
        .catch(() => {
          this.isRetrievingFile = false
          // this.showFileInfo('Dokumenttia ei pystytä lataamaan', 'error')
        })
    },
    async updateDataFromExistingContract () {
      this.isElectronicSigning = this.contract.isElectronicSigning
      this.landlordSignatory =
        {'id': this.contract.landlordSignatory}
      this.tenantSignatories = this.contract.tenantSignatories.map(id => ({'id': id}))
      this.requestState = this.contract.contractSigningState
      if (!this.isNewOrDraft && this.isElectronicSigning) {
         const signatories = await this.$rambollfmapi.contracts.contract.signers(this.contract.contractPDFId)
         signatories.forEach(s => {
          if (s.signerId === this.contract.landlordSignatory) {
            this.landlordSignatoryDate = s.signedDate
            this.landlordSigned = s.isSigned
            this.landlordSignatory.signatoryDate = s.signedDate
            this.landlordSignatory.signed = s.isSigned
          } else {
            this.tenantSignatories.forEach(t => {
              if (t.id === s.signerId) {
                t.signatoryDate = s.signedDate
                t.signed = s.isSigned
              }
            })
          }
        })
      }

      this.signatureMethod = this.contract.isElectronicSigning === true ? 'eSignature' : 'noESignature'

      if (this.allParties) {
        this.tenantSignatories.forEach(signatory => {
          const idData = this.allParties.filter(party => party.id === signatory.id).map(p => (
            {
              name: p.name,
              email: p.email,
              id: p.id,
            }
          ))
          if (idData.length > 0) {
            signatory.name = idData[0].name
            signatory.email = idData[0].email
          }
        })
        if (this.tenantSignatories.length == 0) {
          this.tenantSignatories = [
            {
              id: undefined,
              name: undefined,
              email: undefined,
              signatoryDate: undefined,
              signed: false,
            }
          ]
        }
      }
	},
  onSignatoriesUpdated (data) {
      this.landlordSignatory = data.landlordSignatory
      this.tenantSignatories = data.tenantSignatories
      this.signedContract = data.signedContract
      this.isElectronicSigning = data.isElectronicSigning
      this.tenantsHasEmails = data.tenantsHasEmails
      this.landlordHasEmail = data.landlordHasEmail
      this.onDataChanged ()
  },
	async onDataChanged () {
    await new Promise(resolve => setTimeout(resolve, 50))
    const tenantEmails = []
    this.tenantSignatories.map(t => tenantEmails.push(t.email))
    let tenantsHasEmails = false
    if (!tenantEmails.includes(null) && !tenantEmails.includes(e => e.length < 1))
      tenantsHasEmails = true
		const data = {
      landlordSignatory: this.landlordSignatory,
      tenantSignatories: this.tenantSignatories,
      signedContract: this.signedContract,
      isElectronicSigning: this.isElectronicSigning,
      landlordHasEmail: this.landlordHasEmail,
      tenantsHasEmails: tenantsHasEmails
    }
    this.$emit('updated', data)
	}
  }
}
</script>

<style>
.v-input--checkbox {
  display: inline-flex;
}
</style>