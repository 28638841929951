<template>
  <v-row
    id="esg"
    class="my-4 mx-2"
  >
    <v-col
      lg="4"
      md="3"
      sm="6"
      cols="12"
    >
      <!--Site information-->
      <div
        v-for="(widget) in widgets.filter(w => w.id === 'esg.site')"
        :key="widget.id"
      >
        <v-expansion-panels
          v-if="currentSite"
          :value="widget.maximized[0] === true ? [0] : []"
          multiple
        >
          <v-expansion-panel
            v-for="(source, idx) in Array.isArray(widget.data.items)
              ? widget.data.items
              : widget.data.items !== undefined
                ? [widget.data.items]
                : []"
            :key="widget + '-' + idx"
            v-model="widget.maximized"
            class="no-padding"
          >
            <v-expansion-panel-header>
              <v-row
                align="center"
                justify="start"
              >
                <v-icon v-if="widget.data.icon !== null">
                  {{
                    widget.data.icon
                  }}
                </v-icon>
                {{
                  widget.data.titleSource
                    ? source[widget.data.titleSource]
                    : $t(widget.id)
                }}
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row v-if="widget.loading">
                <v-spacer />
                <v-col class="shrink">
                  <v-progress-circular
                    size="64"
                    indeterminate
                    color="primary"
                    style="margin: 3em"
                  />
                </v-col>
                <v-spacer />
              </v-row>
              <div v-else>
                <MetadataForm
                  v-if="widget.type === 'MetadataForm'"
                  :value="widget.data.items"
                  :metadata="widget.data.metadata"
                  :category="widget.data.category"
                  :multiline="widget.data.multiline"
                  :with-status-icon="true"
                  :disabled="!hasWritePermission"
                  @change="fieldUpdate"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <!-- Estate and Building information -->
      <draggable
        v-model="smallWidgets"
      >
        <div
          v-for="(widget) in smallWidgets"
          :key="widget.id"
          class="widget"
        >
          <v-expansion-panels
            v-if="currentSite"
            :style="{ 'border-color': draggableColor }"
          >
            <v-expansion-panel
              v-for="(source, idx) in Array.isArray(widget.data.items)
                ? widget.data.items
                : widget.data.items !== undefined
                  ? [widget.data.items]
                  : []"
              :key="widget.id + '-' + idx"
              class="no-padding"
              @click="onExpansionPanelClick(widget)"
            >
              <v-expansion-panel-header>
                <v-row
                  align="center"
                  justify="start"
                >
                  <v-icon v-if="widget.data.icon !== null">
                    {{
                      widget.data.icon
                    }}
                  </v-icon>
                  {{
                    widget.data.titleSource
                      ? source[widget.data.titleSource]
                      : $t(widget.id)
                  }}
                  <span
                    v-if="
                      (Array.isArray(widget.data.items)
                        ? widget.data.items
                        : [widget.data.items]
                      ).length > 1
                    "
                  >({{ idx + 1 }} / {{ widget.data.items.length }})</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  v-if="widget.loading"
                >
                  <v-spacer />
                  <v-col class="shrink">
                    <v-progress-circular
                      size="64"
                      indeterminate
                      color="primary"
                      style="margin: 3em"
                    />
                  </v-col>
                  <v-spacer />
                </v-row>
                <div v-else>
                  <MetadataForm
                    v-if="widget.type === 'MetadataForm'"
                    :value="source"
                    :metadata="widget.data.metadata"
                    :category="widget.data.category"
                    :multiline="widget.data.multiline"
                    :with-status-icon="true"
                    :disabled="!hasWritePermission"
                    @change="fieldUpdate"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </draggable>
      <v-row
        class="flex-column"
        style="right: 0; bottom: 0; position: sticky; z-index: 1"
      >
        <v-row
          v-if="saveButtonVisible"
        >
          <v-spacer />
          <v-btn
            v-if="!isSavingChanges"
            :class="'primary'"
            rounded
            depressed
            @click="saveChanges()"
          >
            {{ $t('Save changes') }}
          </v-btn>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-row>
      </v-row>
    </v-col>
    <!-- Large widgets -->
    <v-col
      lg="8"
      md="9"
      sm="12"
      cols="12"
    >
      <draggable
        v-model="largeWidgets"
      >
        <div
          v-for="widget in largeWidgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :has-action="widget.action ? true : false"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            :style="{ 'border-color': draggableColor }"
            :maximized="widget.maximized"
            @updateWidget="updateData"
          />
        </div>
      </draggable>
    </v-col>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import MetadataForm from '../components/MetadataForm.vue'
import Alert from '../components/Alert.vue'
import draggable from 'vuedraggable'
import DynamicWidget from '../components/DynamicWidget.vue'

export default {
  name: 'Esg',
  components: {
    MetadataForm,
    draggable,
    Alert,
    DynamicWidget
  },
  data () {
    return {
      draggableColor: null,
      smallWidgets: [],
      largeWidgets: [],
      currentEsgSiteOriginal: null,
      currentEsgSiteWithChanges: null,
      currentEsgSitePatch: null,
      currentEsgBuildings: {},
      currentEsgEstates: {},
      saveButtonVisible: false,
      isSavingChanges: false,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top'
    }
  },
  computed: {
    ...mapGetters('sites', [
      'currentSite'
    ]),
    ...mapGetters('esg', [
      'estatesEsgForCurrentSite',
      'buildingsEsgForCurrentSite',
      'siteEsgForCurrentSite'
    ]),
    ...mapGetters('esg', [ 'getEsgCertificates' ]),
    ...mapState('sites', [ 'sitePermissions' ]),
    ...mapState('esg', [
      'esgCertificates',
      'siteEsgMetadata',
      'estateEsgMetadata',
      'buildingEsgMetadata',
      'widgets'
      ]),
    ...mapState('app', [ 'currentDate' ]),
    siteId () {
      return Number(this.$route.params.id_site)
    },
  },
  watch: {
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    currentDate: function () {
      this.setData()
      this.loadDataAndInit()
    },
  },
  async mounted () {
    if(!this.currentSite ) {
      await this.setCurrentSite({ siteId: this.siteId })
      await this.loadSite(this.siteId)
    }
    this.setData()
    this.loadDataAndInit()
  },
  methods: {
    ...mapActions('sites', [ 'loadSite' ]),
    ...mapActions('app', [ 'getEnterpriseParties' ]),
    ...mapActions('esg', [
      'loadReportData',
      'loadEsgData',
      'loadEsgMetadata'
      ]),
    ...mapMutations('sites', [ 'setCurrentSite' ]),
    ...mapMutations('esg', [ 'setCurrentSiteId' ]),
    async loadDataAndInit () {
      await this.loadEsgData(this.siteId)
      await this.loadEsgMetadata()
      await this.getEnterpriseParties()
      await this.loadReportData(this.siteId)
      this.initViewStart()
    },
    async setData () {
      await this.setCurrentSiteId(this.siteId)
    },
    initViewStart () {
      if(this.currentSite) {
        this.saveButtonVisible = false
        this.sortWidgets(this.widgets)
        this.initChanges()
        this.updateWidgetData()
      }
    },
    async updateWidgetData () {
      await this.iterateWidgetData(this.widgets)
    },
    iterateWidgetData (data) {
      for (let w of data) {
        if (w.data.report !== undefined) {
          w.data.items = this[w.data.report]
          if (w.data.items === null || w.data.items === undefined) {
            w.data.items = []
          }
        }
        else if (w.data.source !== undefined && w.data.source !== null) {
          w.data.items = this[w.data.source]
        }
        w.loading = false

        if (w.data.metadataSource !== null) {
          w.data.metadata = this[w.data.metadataSource]
        }
      }
    },
    async sortWidgets (widgets) {
      this.smallWidgets = widgets.filter((w) => {
        return w.small && w.id !== 'esg.site'
      })
      this.largeWidgets = widgets.filter((w) => {
        return w.small !== true && w.id !== 'esg.site'
      })
    },
    onExpansionPanelClick (widget) {
      widget.maximized[0] = !widget.maximized[0]
    },
    fieldUpdate ({ data, field, value }){
      if (
        typeof data.id_site !== 'undefined' &&
        typeof data.building_code === 'undefined' &&
        typeof data.id_estate === 'undefined'
      ) {
        this.siteFieldUpdate({ data, field, value })
      }

      if (typeof data.building_code !== 'undefined') {
        this.buildingFieldUpdate({ data, field, value })
      }

      if (
        typeof data.id_estate !== 'undefined' &&
        typeof data.id_site !== 'undefined' &&
        typeof data.building_code === 'undefined'
      ) {
        this.estateFieldUpdate({ data, field, value })
      }

      if (this.hasWritePermission) {
        this.saveButtonVisible = true
      }
    },
    siteFieldUpdate ({ field, value }) {
      this.currentEsgSiteWithChanges[field.name] = value
      this.currentEsgSitePatch = this.$jsonpatch.compare(
        this.currentEsgSiteOriginal,
        this.currentEsgSiteWithChanges
      )
    },
    estateFieldUpdate ({ data, field, value }) {
      const estate = this.currentEsgEstates[data.id_estate]
      estate.withChanges[field.name] = value
      estate.patch = this.$jsonpatch.compare(
        estate.original,
        estate.withChanges
      )
    },
    buildingFieldUpdate ({ data, field, value }) {
      const building = this.currentEsgBuildings[data.building_code]

      building.withChanges[field.name] = value
        building.patch = this.$jsonpatch.compare(
        building.original,
        building.withChanges
      )
    },
    initChanges () {

      //Site
      this.currentEsgSiteOriginal = JSON.parse(JSON.stringify(this.siteEsgForCurrentSite))
      this.currentEsgSiteWithChanges = JSON.parse(JSON.stringify(this.siteEsgForCurrentSite))

      //Estate
      this.estatesEsgForCurrentSite.forEach(estate => {
        const withChanges = JSON.parse(JSON.stringify(estate))
        this.currentEsgEstates[estate.id_estate] = {
          original: JSON.parse(JSON.stringify(estate)),
          withChanges: withChanges,
          patch: null
        }
      })

      //Buildings
      this.buildingsEsgForCurrentSite.forEach(building => {
        const withChanges = JSON.parse(JSON.stringify(building))
        this.currentEsgBuildings[building.building_code] = {
          original: JSON.parse(JSON.stringify(building)),
          withChanges: withChanges,
          patch: null
        }
      })

    },
    async saveChanges () {
      this.isSavingChanges = true
      const time = this.currentDate.toISOString()

      //Site
      let siteResult = null
      if (this.siteEsgForCurrentSite.version === 0 && this.currentEsgSitePatch !== null) {
        let newVersion = this.siteEsgForCurrentSite
        if(newVersion.id_parking_company !== null) {
          newVersion.id_parking_company = newVersion.id_parking_company.id
        }
        siteResult = await this.$rambollfmapi.esg.post().site(
          this.siteEsgForCurrentSite.id_site,
          time,
          newVersion
        )
        this.currentEsgSitePatch = null
        this.currentEsgSiteOriginal = JSON.parse(
          JSON.stringify(newVersion)
        )
      } else if(this.currentEsgSitePatch !== null) {
        siteResult = await this.$rambollfmapi.esg.patch().site(
          this.currentSite.id_site,
          time,
          this.currentEsgSitePatch
        )
        this.currentEsgSitePatch = null
        this.currentEsgSiteOriginal = JSON.parse(
          JSON.stringify(this.currentEsgSiteWithChanges)
        )
      }
      if(siteResult && siteResult.status !== 204) {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
      }

      //Estates
      const estates = Object.keys(this.currentEsgEstates).map(
        e => this.currentEsgEstates[e]
      )
      let estateResult = null
      for (let i = 0; i < estates.length; i++) {
        const estate = estates[i]
        if(this.estatesEsgForCurrentSite[i].version === 0 && estate.patch !== null) {
          estateResult = await this.$rambollfmapi.esg.post().estate(
          estate.withChanges.id_estate,
          JSON.stringify(estate.withChanges.waste_fractions_collected),
          time,
          this.estatesEsgForCurrentSite[i]
          )
        } else if (estate.patch !== null) {
            estateResult = await this.$rambollfmapi.esg.patch().estate(
            estate.withChanges.id_estate,
            JSON.stringify(estate.withChanges.waste_fractions_collected),
            time,
            estate.patch
          )
        }
        if(estateResult && estateResult.status !== 204) {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
        }
        estate.patch = null
        estate.original = JSON.parse(JSON.stringify(estate.withChanges))
      }

      //Buildings
      const buildings = Object.keys(this.currentEsgBuildings).map(
        b => this.currentEsgBuildings[b]
      )
      let buildingResult = null
      for (let i = 0; i < buildings.length; i++) {
        const building = buildings[i]

        if (this.buildingsEsgForCurrentSite[i].version === 0 && building.patch !== null) {
          buildingResult = await this.$rambollfmapi.esg.post().building(
            building.withChanges.building_code,
            time,
            this.buildingsEsgForCurrentSite[i]
          )
        } else {
          if(building.patch !== null) {
            buildingResult = await this.$rambollfmapi.esg.patch().building(
              building.withChanges.building_code,
              time,
              building.patch
            )
          }
        }

        if(buildingResult && buildingResult.status !== 204) {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
        }
        building.patch = null
        building.original = JSON.parse(JSON.stringify(building.withChanges))
      }

      this.isSavingChanges = false
      this.saveButtonVisible = false
      await this.loadEsgData(this.siteId)
      this.initViewStart()

      if(this.operationResult !== 'error') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.showIndicator = true
        this.operationResult = 'success'
        this.yPosition = 'bottom'
      }
    },
    hasWritePermission () {
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    updateData () {
      this.loadDataAndInit()
    }
  }
}
</script>
<style scoped>
#esg {
  padding: 0.5em;
  padding-top: 0;
}

#esg > .col {
  padding: 0.5em;
  padding-top: 0;
}

</style>