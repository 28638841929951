
<template>
  <div>
    <v-dialog
      v-model="showSinglePaymentModal"
      persistent
      eager
      width="85%"
    >
      <SinglePaymentModal
        :payment="paymentToEdit"
        :selected-units="selectedUnits"
        :contract="contract"
        :contract-id="contractId"
        :visible="showSinglePaymentModal"
        :rent-payment-domains="rentPaymentDomains"
        :is-out-rent="isOutRent"
        :selected-buildings="selectedBuildings"
        :selected-currency="selectedCurrency"
        @paymentAdded="onPaymentAdded"
        @paymentUpdated="onPaymentUpdated"
        @close="onSinglePaymentModalClosed"
      />
    </v-dialog>

    <v-form
      v-if="!showSinglePaymentModal"
      ref="form1"
      class="pt-2"
    >
      <v-expansion-panels
        v-model="panels"
        class="my-2"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('create_rent_contract.single_payments') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="localSinglePayments.length > 0"
              class="v-data-table theme--light scrollable"
            >
              <table :style="{ width: '100%'}">
                <thead>
                  <th class="column">
                    {{ $t('create_rent_contract.single_payment_target') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.single_payment_date') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.single_payment_domain') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.single_payment_netsum') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.payment_legend') }}
                  </th>
                  <th class="column">
                    {{ $t('Invoiced') }}
                  </th>
                  <th class="column">
                    {{ $t('create_rent_contract.actions') }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="(payment, index) in localSinglePayments"
                    :key="index"
                  >
                    <td>
                      {{ getPaymentTarget(payment) }}
                    </td>

                    <td class="col-align-right">
                      {{ getPaymentDate(payment) }}
                    </td>

                    <td>
                      {{ getPaymentDomain(payment) }}
                    </td>

                    <td class="col-align-right">
                      {{ getNetSum(payment) }}
                    </td>

                    <td>
                      {{ payment.legend }}
                    </td>

                    <td class="col-align-right">
                      {{ getLatestBillingDate(payment) }}
                    </td>

                    <td>
                      <v-row justify="center">
                        <slot
                          name="row-actions"
                          :row="payment"
                        >
                          <div class="d-flex justify-center align-center">
                            <CdsButton
                              type="tertiary"
                              icon-left="edit"
                              size="small"
                              hide-text
                              @click="editPayment(payment)"
                            >
                              {{ $t('Modify') }}
                            </CdsButton>
                            <CdsButton
                              v-if="isRemovable(payment)"
                              type="danger"
                              icon-left="delete"
                              size="small"
                              hide-text
                              @click="deletePayment(payment)"
                            >
                              {{ $t('Delete') }}
                            </CdsButton>
                          </div>
                        </slot>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-else
              class="d-flex justify-center align-center mt-4"
            >
              <v-icon class="mr-1">
                info
              </v-icon>
              {{ $t('create_rent_contract.no_single_payments') }}
            </div>
            <div class="text-right mt-3 mr-3">
              <CdsButton
                type="primary"
                icon-left="add"
                @click="onAddNewPayment"
              >
                {{ $t('create_rent_contract.new_single_payment') }}
              </CdsButton>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </div>
</template>

<script>

import helpers from './../../../../../helpers'
import services from './Services'
import SinglePaymentModal from './SinglePaymentModal.vue'
import CdsButton from '../../../../CircleDesignSystem/CdsButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: "SinglePaymentsTable",
  components: {
    SinglePaymentModal,
    CdsButton,
  },
  props: {
    contractId: {
      type: Number,
      default: undefined
    },
    selectedUnits: {
      type: Array,
      default: () => []
    },
    singlePayments: {
      type: Array,
      default: () => []
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    rentPaymentDomains: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    selectedBuildings: {
      type: Array,
      default: () => []
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  emits: ['paymentsUpdated'],
  data () {
    return (
      {
        panels: [0],
        showSinglePaymentModal: false,
        paymentToEdit: undefined,
        localSinglePayments: [...this.singlePayments]
      }
    )
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById']),
  },
  methods: {
    isRemovable (payment) {
      return payment?.id < 0 ||
        (this.contract?.isDraft && !payment?.latestBillingDate)
    },
    onPaymentAdded (payment) {
      // create pseudo id
      payment.id = Math.floor(Math.random() * -1000000000)
      this.localSinglePayments.push(payment)
      this.showSinglePaymentModal = false
      this.$emit("paymentsUpdated", this.localSinglePayments)
    },
    onPaymentUpdated (payment) {
      const paymentIdx = this.localSinglePayments.findIndex(p => p.id === payment.id)
      this.localSinglePayments[paymentIdx] = payment
      this.$emit("paymentsUpdated", this.localSinglePayments)
      this.showSinglePaymentModal = false
    },
    onSinglePaymentModalClosed () {
      this.showSinglePaymentModal = false
    },
    onContractPaymentModalClosed () {
      this.showContractPaymentModal = false
    },
    getPaymentTarget (payment) {
      return services.tableColumnsService.getTargetUnits(payment, this.selectedUnits)
    },
    getPaymentDate (payment) {
      return services.tableColumnsService.getDate(payment.paymentDate)
    },
    getNetSum (payment) {
      return (payment?.netSum !== undefined && payment?.netSum !== null) ? helpers.humanize.amount_long(Number(payment.netSum).toFixed(2)) + ' ' + this.selectedCurrency.symbol : ''
    },
    getPaymentDomain (payment) {
      let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
      let name = paymentDomain[0].name
      let defDomainLabel = name
      let vat = paymentDomain[0].vatPercent
      return this.$t(defDomainLabel) + ' ' + this.$t('VAT') + ' ' + this.$t(vat) + '%'
    },
    async deletePayment (payment) {
      const paymentIdx = this.localSinglePayments.findIndex(p => p.id === payment.id)
      this.localSinglePayments.splice(paymentIdx, 1)
      this.$emit("paymentsUpdated", this.localSinglePayments)
    },
    editPayment (payment) {
      this.paymentToEdit = payment;
      this.showSinglePaymentModal = true
    },
    onAddNewPayment () {
      this.paymentToEdit = null
      this.showSinglePaymentModal = true
    },
    getLatestBillingDate (payment) {
      var latestBillingDate = payment.latestBillingDate ? new Date(payment.latestBillingDate) : null
      return latestBillingDate ? helpers.format.formatData(latestBillingDate, 'MonthAndYear') : ''
    },
  }
}
</script>

<style>
.scrollable {
  overflow-x: scroll;
}
</style>