<template>
  <div>
    <v-dialog
      v-model="historyDialog"
      max-width="550"
      persistent
    >
      <BaseModal
        @cancel="historyDialog = false"
      >
        <template #title>
          {{ $t('Budget changes') }}
        </template>
        <template #content>
          <div
            v-for="(budget, i) in sortedBudgets"
            :key="budget.budgetId"
          >
            <v-card
              flat
              :style="{marginBottom: '1em', marginTop: '1em'}"
            >
              <div>
                <span style="font-weight: bold">{{ `${$t('New budget')}: ` }}</span>
                {{ `${budget.budgetTo} ${currency.symbol}` }}
              </div>
              <div>
                <span style="font-weight: bold">{{ `${$t('Previous budget')}: ` }}</span>
                {{ `${budget.budgetFrom} ${currency.symbol}` }}
              </div>
              <div>
                <span style="font-weight: bold">{{ `${$t('Comments')}: ` }}</span>
                {{ budget.information }}
              </div>
              <div>
                <span style="font-weight: bold">{{ `${$t('Changed')}: ` }}</span>
                {{ showSubmitter(budget) }}
              </div>
            </v-card>
            <v-divider v-if="i + 1 < sortedBudgets.length" />
          </div>
        </template>
        <template #footer />
      </BaseModal>
    </v-dialog>
    <br>
    <v-container>
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('Budgeting') }}
          </h4>
        </v-col>
      </v-row>
      <v-row
        class="section fill-height"
      >
        <v-col
          v-if="task.id && task.originalBudget !== 0"
          cols="12"
        >
          <CurrencyField
            readonly
            :label="$t('Original budget')"
            :value="task.originalBudget"
            :currency="currency"
          />
        </v-col>
        <v-col cols="12">
          <CurrencyField
            :value="budgetting.budget"
            :label="
              task.id && task.originalBudget !== 0
                ? $t('New budget')
                : $t('Budget')
            "
            :currency="currency"
            :rules="[rules.budget]"
            :readonly="task.finished"
            @change="budgetChanged"
          />
        </v-col>
        <v-col cols="12">
          <CurrencyField             
            :value="budgetting.budgetSpent"
            :label="$t('Spent budget')"
            :currency="currency"
            :rules="[rules.spentBudget]"
            :readonly="task.finished"
            @change="budgetSpentChanged"
          /> 
        </v-col>
        <v-col class="section-switch">
          <v-switch
            v-model="budgetting.isInOriginalBusinessPlan"
            :label="$t('taskBudgeting.isInOriginalBusinessPlan')"
            :disabled="task.finished"
            @change="$emit('budgetChange', budgetting)"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-if="task.id"
            v-model="budgetting.information"
            :readonly="budgetting.budget === task.budget || task.finished"
            :label="$t('More info')"
            @change="$emit('budgetChange', budgetting)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="text-end"
        >
          <v-btn
            v-if="task.id"
            small
            rounded
            depressed
            class="primary"
            :readonly="!budgetting.information || loading"
            @click="updateBudget"
          >
            <span v-if="!loading">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="section-link mb-4"
        >
          <a
            v-if="task.id"
            color="blue"
            dark
            @click="historyDialog = true"
          >{{ $t('Look budget history') }}</a>
        </v-col>
      </v-row>
      <v-spacer />
      <v-divider />
      <v-spacer />
    </v-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import BaseModal from '../general/BaseModal.vue'
import CurrencyField from '../CurrencyField.vue'

export default {
  emits: ['budgetChange'],
  name: 'TaskBudjeting',
  components: { 
    BaseModal,
    CurrencyField,
  },
  props: {
    task: {
      type: Object,
       default : () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
      budgetting: {
        budget: this.task.budget,
        budgetSpent: this.task.budgetSpent,
        isInOriginalBusinessPlan: this.task.isInOriginalBusinessPlan,
        information: undefined
      },
      loading: false,
      historyDialog: false
    }
  },
  computed: {
    sortedBudgets: function () {
      return this.task.budgets
        ? [...this.task.budgets].sort((a, b) => {
          return (
            new Date(b.submitted).getTime() - new Date(a.submitted).getTime()
          )
        })
        : []
    }
  },
  methods: {
    ...mapActions('ltp', ['saveBudget', 'updateTask']),
    async updateBudget () {
      this.loading = true
      const budget = { ...this.budgetting }
      const params = { id: this.task.id, budget }
      if (this.task.budget !== budget.budget) {
        await this.saveBudget(params)
      }
      if (this.task.budgetSpent !== budget.budgetSpent || this.task.inOriginaBusinessPlan !== budget.isInOriginalBusinessPlan) {
        await this.updateTask({...this.task, budgetSpent: budget.budgetSpent, isInOriginalBusinessPlan: budget.isInOriginalBusinessPlan})
      }
      this.budgetting.information = undefined
      this.loading = false
    },
    showSubmitter (budget) {
      const options = {
        dateStyle: 'medium',
        timeStyle: 'short'
      }
      return `${new Intl.DateTimeFormat('fi-FI', options).format(new Date(budget.submitted))} ${budget.submitter}`
    },
    budgetChanged (value) {
      this.budgetting.budget = value
      this.$emit('budgetChange', this.budgetting)
    },
    budgetSpentChanged (value) {
      this.budgetting.budgetSpent = value
      this.$emit('budgetChange', this.budgetting)
    },
  }
}
</script>
<style scoped>
.header {
  background: var(--c-color-accent) !important;
  color: #8b8b8b;
  padding-left: 0.5em;
  font-size: 1.5em;
}
.section-link {
  padding-top: 1em;
  padding-bottom: 1em;
}
.buttons {
  display: flex;
}
.grey-text {
  color: #8b8b8b;
  padding-left: 1em;
  padding-top: 0.5em;
}
.title-card {
  background: #d5e9f6;
}
</style>

}
