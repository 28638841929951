import csv from './csv.js'
import saveAs from './saveAs.js'
import humanize from './humanize.js'
import hash from './hash.js'
import uuid from './uuid.js'
import format from './format.js'
import ltpConstants from './ltpConstants.js'
import requestTypes from './requestTypes.js'
import * as VeeValidate from 'vee-validate' // new plugin/add-on to help with form validations
import dataTable from './dataTable.js'
import breeamInUseCertification from './breeamInUseCertification.js'
import variableLists from './variableLists.js'
import codeUtility from './codeUtility.js'
import tableFilter from './tableFilter.js'
import clone from './clone.js'
import table from './table.js'
import parties from './parties.js'

export default {
  csv: csv,
  uuid: uuid,
  saveAs: saveAs,
  humanize: humanize,
  format: format,
  hash: hash,
  ltpConstants: ltpConstants,
  VeeValidate: VeeValidate,
  requestTypes: requestTypes,
  dataTable: dataTable,
  breeamInUseCertification: breeamInUseCertification,
  variableLists: variableLists,
  codeUtility: codeUtility,
  tableFilter: tableFilter,
  clone: clone,
  table: table,
  parties: parties
}
