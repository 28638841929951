<template>
  <div
    v-if="widgetTable.length"
    id="widget-layout"
    class="mt-4"
  >
    <draggable
      v-model="widgetTable"
      :disabled="!isDraggable"
      handle=".large-widget"
      @change="fixTable"
    >
      <div
        v-for="(row, idx) in widgetTable"
        :key="idx"
      >
        <draggable
          :list="row.items"
          :disabled="!isDraggable"
          class="row widget no-gutters"
          group="row"
          handle=".small-widget"
          @change="fixTable"
        >
          <div
            v-for="(widget, indx) in row.items"
            :key="widget ? widget.id : idx + '_' + indx"
            :class="widget && !widget.small ? 'large-widget' : 'small-widget'"
          >
            <ExpansionMetaDataForm
              v-if="widget && widget.type === 'MetadataForm'"
              :widget="widget"
              :draggable-color="draggableColor"
              :has-write-permission="hasWritePermission"
            />
            <DynamicWidget
              v-else-if="widget"
              :type="widget.type"
              :title="$t(widget.id)"
              :data="widget.data"
              :loading="widget.loading"
              :style="{ 'border-color': draggableColor }"
              :maximized="widget.maximized"
              @open="widget.maximized = $event"
              @triggerCustomEvents="onCustomEvent"
            />
          </div>
        </draggable>
      </div>
    </draggable>
    <div
      v-show="isDraggable"
      style="right: 0; bottom: 0; position: fixed; z-index: 1"
    >
      <v-row
        align="center"
        justify="end"
        no-gutters
      >
        <v-btn
          outlined
          rounded
          text
          @click="getWidgetOrder()"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          rounded
          depressed
          class="primary"
          @click="setWidgetOrder()"
        >
          {{ $t('Save default view') }}
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DynamicWidget from '../DynamicWidget.vue'
import draggable from 'vuedraggable'
import ExpansionMetaDataForm from '../ExpansionMetaDataForm.vue'
export default {
  emits: ['onCustomEvent'],
  name: 'SortableWidgetsLayout',
  components: {
    DynamicWidget,
    draggable,
    ExpansionMetaDataForm
  },
  props: {
    widgets: {
      type: Array,
      default: () => []
    },
    overrides: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      draggableColor: null,
      frameStyle: null,
      sortedWidgets: [],
      widgetOrder: [],
      leftWidgets: [],
      rightWidgets: [],
      rw: {},
      widgetTable: [],
      fixingTable: false
    }
  },
  computed: {
    ...mapState('app', ['userWidgets', 'sortableWidgets']),
    ...mapState('sites', ['sitePermissions']),
    ...mapGetters('app', ['widgetPermissions']),
    isDraggable () {
      return this.sortableWidgets
    },
    hasWritePermission () {
      const permission = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    }
  },
  watch: {
    widgets: {
      handler: function () {
        this.getWidgetOrder()
      }
    },
    sortableWidgets: function (boolean) {
      if (boolean === true) {
        this.draggableColor = '#8b8b8b'
        this.frameStyle = '2px dashed #8b8b8b'
      } else {
        this.draggableColor = null
        this.frameStyle = null
      }
    }
  },
  mounted () {
    this.getWidgetOrder()
  },
  methods: {
    async getWidgetOrder () {
      this.widgetOrder = this.userWidgets.filter(w => w.id !== -1)

      const order = this.widgetOrder.map(wo => wo.name)

      const filteredWidgets = this.getSortableWidgets().filter(w => this.widgetOrder.find(wo => wo.name === w.id))
      var sortedWidgets = filteredWidgets.map(w => { return w }).sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })

      const sortedWithSettings = sortedWidgets.map(sw => {
        const fromWO = this.widgetOrder.find(wo => wo.name === sw.id)
        if (fromWO) {
          sw.leftSide = fromWO.leftSide !== null ? fromWO.leftSide : true
          sw.maximized = fromWO.isOpen !== null ? [fromWO.isOpen] : [true]
        } else {
          sw.leftSide = true
          sw.maximized = [true]
        }
        return sw
      })

      var widgetTable = []

      sortedWithSettings.forEach(w => {
        if (w.small && !w.leftSide) {
          if (!widgetTable[widgetTable.length - 1]) {
            widgetTable.push({
              items: []
            })
          }
          if (widgetTable[widgetTable.length - 1].items.find(w => w && w.small && !w.leftSide)) {
            widgetTable.push({
              items: [null, w]
            })
          } else {
            widgetTable[widgetTable.length - 1].items[1] = w
          }
        } else if (w.small) {
          widgetTable.push({
            items: [w, null]
          })
        } else {
          widgetTable.push({
            items: [w]
          })
        }
      })
      this.widgetTable = widgetTable
      this.fixTable()
      this.$store.commit('app/setSortableWidgets', false)
    },
    async setWidgetOrder () {
      this.widgetTable.map(row => {
        if (row.items[0]) {
          row.items[0].leftSide = true
        }
        if (row.items[1]) {
          row.items[1].leftSide = false
        }
      })

      var currentChanges = []
      this.widgetTable.forEach(row => {
        currentChanges.push(...row.items.filter(item => item))
      })

      const newOrder = currentChanges.map(widget => widget.id)
      const newWidgetOrder = this.widgetOrder.sort((a, b) => {
        return newOrder.indexOf(a.name) - newOrder.indexOf(b.name)
      })
      newWidgetOrder.map(wo => {
        const currentWidget = currentChanges.find(cc => cc.id === wo.name)
        if (currentWidget) {
          wo.isOpen = currentWidget.maximized[0]
          wo.leftSide = currentWidget.leftSide
        }
      })

      await this.$rambollfmapi.accounts.widgets.put(newWidgetOrder)
      this.$store.dispatch('app/getUserInfo')
      this.$store.commit('app/setSortableWidgets', false)
    },
    fixTable () {
      if (!this.fixingTable) {
        this.fixingTable = true
        var tempTable = []

        this.widgetTable.forEach((row, idx) => {
          if (row.items.length > 1 && row.items.find(widget => widget && !widget.small)) {
            // Row has large widget and another widget -> parse it to 2 rows
            row.items.forEach(item => {
              tempTable.push({
                items: [item]
              })
            })
          } else if (row.items.length > 2) {
            // Row has more than 2 widgets -> parse it to 2 rows
            if (row.items[row.items.length - 1] === null) {
              tempTable.push({
                items: [row.items[0], row.items[1]]
              })
            } else {
              tempTable.push({
                items: [row.items[0], row.items[1]]
              })
              tempTable.push({
                items: [row.items[2], row.items[3] ? row.items[3] : null]
              })
            }
          } else if (row.items.length === 1 && row.items.find(widget => widget && widget.small)) {
            // Row has one small widget -> make null area so it can be swapped left and right
            row.items.push(null)
            tempTable.push(row)
          } else if (row.items.find(w => w !== null)) {
            // Nothing to be fixed, rest should be NULL rows, so they can be ignored
            tempTable.push(row)
          }
        })
        // Make null row as last row, so table allows to drag widgets to new row
        tempTable.push({ items: [null, null] })
        this.widgetTable = tempTable
        this.fixingTable = false
      }
    },
    isWidgetVisible: function (widget) {
      return (
        this.widgetPermissions.find(
          w => w.name === widget.id || this.overrides.includes(widget.id)
        ) !== undefined
      )
    },
    getSortableWidgets () {
      return this.widgets.filter((w) => {
        return this.isWidgetVisible(w)
      })
    },
     onCustomEvent (event){
      this.$emit('onCustomEvent', event)
    }
  }
}
</script>
<style scoped>
#widget-layout {
  padding: 0.5em;
  padding-top: 0;
}

#widget-layout > .col {
  padding: 0.5em;
  padding-top: 0;
}

#widget-layout > .col > div:not(:first-child) > .v-expansion-panel {
  margin-top: 0.5em;
}

#widget-layout .subpanel {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: auto;
}

#widget-layout .subpanel:not(:first-child) {
  margin-top: 0.5em;
}

#widget-layout .subpanel:last-child {
  margin-bottom: 0.5em;
}

#widget-layout .widget {
  padding-top: 1em;
}

.widget-row {
  margin-bottom: 0.5em;
  border-radius: 1.5em;
}

.large-widget {
  width: 100%;
  padding-bottom: 1em;
}

.small-widget {
  width: 48vw;
  padding-left: 1em;
  padding-bottom: 1em;
}
</style>
