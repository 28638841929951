<template>
  <div style="position: relative">
    <v-row
      justify="space-between"
      align="center"
      no-gutters
    >
      <v-tooltip
        v-if="info.length > 0"
        top
        max-width="400"
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            left
            icon
            v-on="on"
          >
            <v-icon>
              info
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t(info) }}</span>
      </v-tooltip>

      <v-row
        align="center"
        justify="space-between"
        no-gutters
      >
        <h3
          :class="info.length > 0 ? 'pl-15' : 'pl-2'"
        >
          {{ $t(subtitle) }}
        </h3>
        <h3
          v-if="data.showSetAsHeader"
        >
          {{ setText }}
        </h3>
        <div>
          <v-menu
            v-if="data.areaColumn"
            left
          >
            <template #activator="{ on }">
              <v-btn
                class="btn-area"
                outlined
                icon
                v-on="on"
              >
                €/m²
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :class="{ 'active': divideByArea === false}"
                @click="divideByArea = false"
              >
                <v-list-item-title>{{ $t('€/site') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :class="{ 'active': divideByArea === true}"
                @click="divideByArea = true"
              >
                <v-list-item-title>{{ $t('€/m²') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="data.groups && currentGroup != null"
            left
          >
            <template #activator="{ on }">
              <v-btn
                outlined
                text
                small
                class="no-transform"
                v-on="on"
              >
                <span
                  v-for="(value, index) in data.groups"
                  :key="value.text"
                  :class="{ highlighted: value == currentGroup}"
                >
                  {{ $t(value.text) }}
                  <span v-if="index != data.groups.length - 1">/</span>
                </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="group in data.groups"
                :key="group.value"
                :class="{ 'active': currentGroup.value === group.value}"
                @click="currentGroup = group"
              >
                <v-list-item-title>{{ $t(group.text) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="data.sets"
            left
          >
            <template #activator="{ on }">
              <v-btn
                outlined
                small
                icon
                style="background: rgba(255, 255, 255, 0.8) !important"
                v-on="on"
              >
                <v-icon>menu</v-icon>
                <span class="d-sr-only">{{ $t('Menu') }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="set in data.sets"
                :key="set.text"
                :class="{ 'active': currentSet === set}"
                @click="currentSet = set"
              >
                <v-list-item-title>{{ set.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
    </v-row>
    
    <VerticalBarChart
      v-if="barData !== null"
      :data="barData"
      :options="barOptions"
      style="height: 360px"
    />
    <v-row
      v-if="data.difference"
      justify="center"
      no-gutters
    >
      {{ difference }}: {{ parseInt(differenceValue) > 0 ? '+' : '' }}{{ differenceValue }} {{ differenceUnit }}
    </v-row>
    <v-row
      v-else-if="data.cumulative.length > 0"
      justify="center"
      no-gutters
    >
      {{ cumulative }}: {{ cumulativeValue }} {{ cumulativeUnit }}
    </v-row>

    <v-row
      align="start"
      class="mr-4 ml-4"
      style="height: 2em"
      no-gutters
    >
      <v-range-slider
        v-if="data.rangeSlider"
        v-model="sliderRange"
        :max="data.items.length"
        color="accent"
        dense
        class="aligned-slider"
        ticks="always"
        tick-size="2"
        min="0"
      />
      <v-slider
        v-else
        v-model="yearAmount"
        color="accent"
        dense
        class="aligned-slider"
        ticks="always"
        tick-size="2"
        max="10"
        min="1"
      />
    </v-row>
  </div>
</template>

<script>
import humanize from '../../helpers/humanize.js'
import VerticalBarChart from '../VerticalBarChart.vue'
import { mapMutations, mapState } from 'vuex'
import format from './../../helpers/format.js' 

export default {
  components: {
    VerticalBarChart
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    widgetId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      percentageMarker: '%',
      yearChoices: [],
      yearAmount: null,
      currentSet: null,
      sliderRange: [0, 1],
      divideByArea: false,
      currentGroup: null,
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          labels: {
            fontColor: 'black',
            fontSize: 12,
            boxWidth: 20,
            // We have to generate labels manually to give rectange pointstyle for bar data (pointStyle for bar data is not supported in chartjs 2.9.4)
            generateLabels: function (chart) {
              return chart.data.datasets
                .filter((dataset => dataset.data.length > 0))
                .map(dataset => ({
                    text: dataset.label,
                    fillStyle: dataset.backgroundColor,
                    strokeStyle: dataset.borderColor,
                    pointStyle: dataset.pointStyle,
                }))
           },
           usePointStyle: true
          },
          align: 'center',
          position: 'bottom',
          reverse: true,
          onClick: (e) => e.stopPropagation()
        },
        tooltips: {
          position: 'nearest',
          mode: 'label',
          itemSort: function (a, b) {
            return b.datasetIndex - a.datasetIndex
          },
          filter: this.data.customTooltip === true ? function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].showOnTooltip
          } : null,
          callbacks: {
            beforeBody: this.data.customTooltip ? this.additionalTooltipData() : function () {},
            afterLabel: function (tooltipItem, data) {
              const datasetIndex = tooltipItem.datasetIndex
              const index = tooltipItem.index
              if (data.datasets[datasetIndex].afterLabel) {
                return data.datasets[datasetIndex].afterLabel[index] ? 
                  format.formatData(data.datasets[datasetIndex].afterLabel[index], data.datasets[datasetIndex].afterLabelFormat)  
                  : 
                  null
              }
            }
          },
          displayColors: this.data.displayLabelColors ??  'true'
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: this.data.xLabel,
                labelString: this.data.xLabel
              },
              gridLines: {
                color: 'white',
                zeroLineColor: 'white'
              },
              ticks: {
                minRotation: 45,
                fontColor: '#8b8b8b'
              },
              stacked: true
            }
          ],
          yAxes: [
            {
              id: 'y-axis-bar',
              scaleLabel: {
                display: this.data.yLabel,
                labelString: this.data.yLabel
              },
              gridLines: {
                color: '#8b8b8b',
                zeroLineColor: '#8b8b8b'
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#8b8b8b',
                callback: function (value, index, values) {
                  return value + ' <'
                }
              },
              stacked: true
            },
            {
              id: 'percentage',
              beforeUpdate: function (scale) {
                const ticks = scale.chart.scales['y-axis-bar']?.ticksAsNumbers
                const percentageAxis = scale.chart.options.scales.yAxes.find(item => item.id === 'percentage')
                if (!percentageAxis) {
                  return
                }
                if (Math.min(...ticks) < 0) {
                  const belowZeroTicks = ticks.filter(value => value < 0).length
                  const aboveZeroTicks = ticks.filter(value => value > 0).length
                  const min = 100 * (belowZeroTicks / ( aboveZeroTicks ?? 1)) 
                  percentageAxis.ticks.min = -min
                  percentageAxis.ticks.steps = 10 + belowZeroTicks
                  percentageAxis.ticks.stepSize = Math.round((100 / (aboveZeroTicks ?? 1)))
                }
              },
              position: 'right',
              display: false,
              scaleLabel: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                fontColor: '#8b8b8b',
                callback: function (value, index, values) {
                  if (value >= 0) {
                    return value + ' %'
                  }
                  else {
                    return ''
                  }
                },
              },
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapState('dashboard', [ 'widgetFilters' ]),
    subtitle () {
      if (this.currentGroup && this.currentGroup.subtitle) {
        return this.currentGroup.subtitle
      }
      if (this.data.subtitle == null) {
        return ''
      }
      if (this.divideByArea && this.data.subtitle.length === 2) {
        return this.data.subtitle[1]
      } else if (this.data.subtitle.length > 0) {
        return this.data.subtitle[0]
      }
      return ''
    },
    cumulative () {
      if (this.divideByArea && this.data.cumulative.length === 2) {
        return this.data.cumulative[1]
      } else if (this.data.cumulative.length > 0) {
        return this.data.cumulative[0]
      }
      return ''
    },
    cumulativeValue () {
      if (this.barData === null) {
        return 0
      }
      if (this.divideByArea && this.data.cumulative.length === 2) {
        const valueByLabel = []
        for(let i = 0; i < this.barData.labels.length; ++i) {
          valueByLabel.push(0)
          for(const dataset of this.barData.datasets) {
            valueByLabel[i] += dataset.data[i]
          }
        }
        const average = valueByLabel.reduce((a, b) => a + b, 0) / valueByLabel.length
        return humanize.amount_long(average)
      } else if (this.data.cumulative.length > 0) {
        const value = this.barData.datasets.filter(dataset => dataset.type == 'bar').reduce((acc, cur) => {
          acc += cur.data.reduce((a, b) => {
            a = a ?? 0
            b = b ?? 0
            return a + b
          }, 0)
          return acc
        }, 0)
        return humanize.amount_long(value, 0)
      }
      return 0
    },
    cumulativeUnit () {
      if (this.currentSet) {
        return this.currentSet.unit
      }
      else {
        return ''
      }
    },
    difference () {
      return this.$t(this.data.difference)
    },
    differenceValue () {
      if (this.barData === null) {
        return 0
      }

      const { totalsDifference } = this.data

      let differenceData = []

      if (totalsDifference) {

        // We should calculate the total values for each bar.
        differenceData = this.barData.datasets
          .filter(data => data.type === 'bar') // Select only bars.
          .map(data => data.data) // Should for two dimensional list where first dimension is "headers" and second is x - dimension.
          .reduce((acc, cur) => acc.map((num, i) => {

            let val1 = 0
            let val2 = 0

            if (num) {
              val1 = num
            }

            if (cur[i]) {
              val2 = cur[i]
            }

            return val1 + val2
          })) // Reduce to one dimensional list which contains total values for each x dimension.
      } else {
        differenceData = this.barData.datasets.find(item => item.label === this.data.differenceLabel).data
      }

      const secondaryDifferenceData = this.data.useSecondaryDifferenceLabel ? this.barData.datasets.find(item => item.label === this.data.secondaryDifferenceLabel).data : []

      const end = differenceData[differenceData.length - 1] ?? 0
      const start = differenceData[0] ?? 0

      const secondaryEnd = secondaryDifferenceData[secondaryDifferenceData.length - 1] ?? 0

      const secondaryStart = secondaryDifferenceData[0] ?? 0

      return humanize.thousand_separator((end + secondaryEnd) - (start + secondaryStart), 2)
    },
    differenceUnit () {
      if (this.currentSet && this.currentSet.unit) {
        return this.currentSet.unit
      }
      else if (this.currentGroup && this.currentGroup.unit) {
        return this.currentGroup.unit
      }
      else {
        return this.data.yUnit
      }
    },
    info () {
      if (this.currentSet && this.currentSet.info) {
        return this.currentSet.info
      }
      else if (this.data.info) {
        return this.data.info
      }
      else {
        return ''
      }
    },
    setText () {
      if(this.currentSet && this.currentSet.text)
      {
        return this.currentSet.text
      }
      return ""
    },
    barData () {
      if (this.data !== null && this.currentSet !== null) {
        // Labels that run vertically along the bottom of the graph.
        let labels = []
        // In case we have rangeSlider, slice from both ends
        if (this.data.rangeSlider) {
          labels = this.data.items.map(x => x[this.data.column]).slice(this.sliderRange[0], this.sliderRange[1])
        }
        else {
          labels = this.data.items.map(x => x[this.data.column]).slice(0, this.yearAmount)
        }

        // Labels for parts of a single bar that are stacked atop another.
        let setHeaders = this.data.headers[this.currentSet.value].filter(header => header.tooltipOnly == null)
        let tooltipHeaders = this.data.headers[this.currentSet.value].filter(header => header.tooltipOnly)

        // If group is active, filter out headers by group
        if (this.currentGroup) {
          setHeaders = setHeaders.filter(header => header.group === this.currentGroup.value)
          tooltipHeaders = tooltipHeaders.filter(header => header.group == null || header.group === this.currentGroup.value)
        }

        const datasets = []

        // If line items are given, add their data to graph
        if (this.data.lineItems) {
          let lineData = labels.map(x => {
            if (this.lineItemForLabel(x) != null) {
              return this.lineItemForLabel(x)[this.currentSet.value]
            }
            else {
              return 0
            }
          })
          
          datasets.push({
            label: this.$t(this.data.lineItemsLegend),
            type: 'line',
            data: lineData,
            borderColor: this.data.lineColor,
            backgroundColor: this.data.lineColor,
            fill: false,
            order: -1,
            yAxisID: 'percentage',
            lineTension: 0.01,
            pointRadius: 1,
            pointHitRadius: 20,
            showOnTooltip: this.data.showLineOnTooltip,
            pointStyle: 'line'
          })
        }

        for(const setHeader of setHeaders) {
          datasets.push({
            label: setHeader.text,
            data: labels.map(x => {
              if (this.divideByArea) {
                const itemForLabel = this.itemForLabel(x)
                return itemForLabel[setHeader.value] / itemForLabel.area
              }
              return this.itemForLabel(x)[setHeader.value]
              }),
            backgroundColor: setHeader.color,
            showOnTooltip: setHeader.showOnTooltip,
            type: 'bar',
            pointStyle: 'rect',
            afterLabel: labels.map(x => {
              return this.itemForLabel(x)[setHeader.afterLabel]
            }),
            afterLabelFormat: setHeader.afterLabelFormat
          })
        }

        // To add additional info to tooltips, we add datasets with no data array
        if (tooltipHeaders.length > 0) {
          for (const header of tooltipHeaders) {
            datasets.push({
              label: header.text,
              tooltipData: labels.map(x => {
                return this.itemForLabel(x)[header.value]
              }),
              unit: header.unit,
              decimals: header.decimals,
              tooltipOnly: true
            })
          }
        }

        // Add net totals if required
        if (this.data.showTotals) {
          datasets.push({
            label: this.$t('leasing.net_total'),
            type: 'scatter',
            data: labels.map(x => {
              const itemForLabel = this.itemForLabel(x)
              let total = 0
              for (const setHeader of setHeaders) {
                total += itemForLabel[setHeader.value]
              }
              return total
            }),
            pointRadius: 6,
            pointHitRadius: 9,
            borderColor: '#fff',
            backgroundColor: '#ff0000',
            order: -2,
            pointStyle: 'rectRot'
          })
        }

        return {
          labels: labels,
          datasets: datasets
        }
      } else {
        return null
      }
    }
  },
  watch: {
    data: {
      handler: async function (newData, oldData) {
        this.currentSet = this.data.sets[0]
      },
      deep: true
    },
    currentSet: function (newValue) {
      if(this.widgetId === 'dashboard.ltmp_year_overview_fimx') {
        const idx = this.data.sets.findIndex(set => set.value === newValue.value)
        this.setWidgetDataFilter({widget: 'fimx', value: idx})
      }
      if (newValue.unit) {
        this.barOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return humanize.thousand_separator(value) + ' ' + newValue.unit
        }
        this.barOptions.tooltips.callbacks.label = function (tooltipItem, data) {
          if ( data.datasets[tooltipItem.datasetIndex].type !== 'line') {
            let decimals = 2
            if (newValue.unit.trim() === '') {
              decimals = 0
            }
            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ?? 0
            return  data.datasets[tooltipItem.datasetIndex].label + ': ' + humanize.thousand_separator(value,decimals) + ' ' + newValue.unit
          }
          else {
            return  data.datasets[tooltipItem.datasetIndex].label + ': ' + humanize.thousand_separator(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],2) + ' ' + '%'
          }
        }
      }
    },
    widgetFilters: {
      handler: function () {
        if(this.widgetId === 'dashboard.ltmp_year_overview_fimx') {
          const idx = this.widgetFilters['fimx']
          this.currentSet = this.data.sets[idx]
        }
      }, deep: true
    },
    currentGroup: function (newValue) {
      if (newValue.unit) {
        this.barOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return humanize.thousand_separator(value) + ' ' + newValue.unit
        }
        this.barOptions.tooltips.callbacks.label = function (tooltipItem, data) {
          if ( data.datasets[tooltipItem.datasetIndex].type !== 'line') {
            let decimals = 2
            if (newValue.unit.trim() === '') {
              decimals = 0
            }
            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ?? 0
            return  data.datasets[tooltipItem.datasetIndex].label + ': ' + humanize.thousand_separator(value,decimals) + ' ' + newValue.unit
          }
          else {
            return  data.datasets[tooltipItem.datasetIndex].label + ': ' + humanize.thousand_separator(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],2) + ' ' + '%'
          }
        }
      }
    },
  },
  mounted () {
    for(let i = 10; i > 0; --i) {
      this.yearChoices.push(String(i))
    }
    this.yearAmount = this.yearChoices[0]

    if(this.widgetId === 'dashboard.ltmp_year_overview_fimx') {
      const idx = this.widgetFilters['fimx']
      this.currentSet = this.data.sets[idx]
    } else {
    this.currentSet = this.data.sets[0]
    }
    if (this.data.rangeSlider) {
      this.sliderRange = [0, this.data.items.length]
    }

    if (this.data.groups && this.data.groups.length > 0) {
      this.currentGroup = this.data.groups[0]
    }
    
    const unit = this.data.yUnit
    const lineUnit = this.data.lineUnit
    this.barOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
      return humanize.thousand_separator(value) + unit
    }
    this.barOptions.tooltips.callbacks.label = function (tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label
      // Set value to 0 if it's not defined
      let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ?? 0
      // Use lineunit for line dataset
      if (data.datasets[tooltipItem.datasetIndex].type === 'line') {
        return label + ': ' + humanize.thousand_separator(value, 2) + ' ' + lineUnit
      }
      if (value% 1 !== 0 ) {
        return label + ': ' + humanize.thousand_separator(value,2) + ' ' + unit
      } 
      else {
        return label + ': ' +humanize.thousand_separator(value) + ' ' + unit
      }
    }
    if (this.data.lineUnit) {
      this.barOptions.scales.yAxes.find(axis => axis.id === 'percentage').display = true
    }
  },
  methods: {
    ...mapMutations('dashboard', ['setWidgetDataFilter']),
    itemForLabel (label, divideByArea) {
      return this.data.items.find(x => x[this.data.column] === label)
    },
    lineItemForLabel (label) {
      return this.data.lineItems.find(x => x[this.data.column] === label)
    },
    // In order for this to work, at least one of the shown data points has to be on the tooltip
    additionalTooltipData () {
      function additionaTooltip (tooltipItems, data) {
        let lines = []
        const index = tooltipItems[0].index
        const tooltipData = data.datasets.filter(dataset => dataset.tooltipData)
        tooltipData.forEach(set => {
          let value = humanize.thousand_separator(set.tooltipData[index], set.decimals)
          lines.push(set.label + ': ' + value + ' ' + set.unit)
        })
        return lines.join('\n')

      }
      return additionaTooltip
    },
  }
}
</script>

<style scoped>
.btn-area {
  text-transform: none;
  font-size: 12px;
  font-weight: 700;
  max-width: 28px;
  max-height: 28px;
}
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.aligned-slider {
  transform: translate(0, -1em);
}
.highlighted {
  color: var(--c-color-accent) !important;
}
.no-transform {
  text-transform: none;
}
</style>