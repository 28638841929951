import RambollFMAPI from '../api/rambollfm.js'
import helpers from '../helpers'
import { i18n } from '../plugins/i18n.js'

export default {
  namespaced: true,
  state: {
    tasks: [],
    filteredTasks: [],
    searchedTasks: [],
    timelineTasks: [],
    currentTask: undefined,
    yearRange: 10,
    selectedYear: new Date().getFullYear(),
    selectedCode: undefined,
    classCode: undefined,
    selectedCategory:undefined,
    budgetLow: 0,
    budgetHigh: null,
    loadingStatus: true,
    ltpBuildings: [],
    buildingFilter: [],
    columnFilter: [],
    edited: false,
    saveStatus: false,
    detailedSearchActive: false,
    statusFilter: Object.values(helpers.ltpConstants.statusMappings()).filter(s => s !== 'Acknowledge'),
    apiEndpoint: null,
    apiToken: null,
    esgClasses: [],
    capexClasses: [],
    esgCodes: [],
    capexCodes: [],
    capexCategories: [
      {
        code: 'Capex',
        name: i18n.t('Capex')
      }  
    ],
    esgCategories: [
      {
      code: 'ESG',
      name: i18n.t('ESG')
      },
      {
        code: 'Capex & ESG',
        name: i18n.t('Capex & ESG')
      }
    ],
    taskStatuses: [
      { name: 'New', value: 0, order: 0 },
      { name: 'Planned', value: 1, order: 2 },
      { name: 'Started', value: 2, order: 3 },
      // Acknowledge state is handled separately by the UI. Not available in dropdowns.
      /*{ name: 'Acknowledge', value: 3, order: 4 },*/ 
      { name: 'Removed', value: 4, order: 7 },
      { name: 'In survey', value: 5, order: 1 },
      { name: 'Done', value: 6, order: 6 },
      { name: 'Not implemented', value: 7, order: 5 },       
    ]
  },
  getters: {
    statuses: state => {
      return state.taskStatuses.toSorted((a, b) => a.order - b.order)
    },
    codes: state => {
      return [...state.esgCodes, ...state.capexCodes]
    },
    getAllTasks: state => {
      return state.tasks
    },
    getTaskById: state => id => {
      return state.filteredTasks.find(task => task.id === id)
    },
    getCurrentTask: state => {
      return state.currentTask
    },
    getSearchedTasks: state => {
      return state.searchedTasks
    },
    getCurrentYearTasks: state => {
      return state.searchedTasks.filter(
        task =>
          new Date(task.implementationStart).getFullYear() ===
          state.selectedYear
      )
    }
  },
  mutations: {
    setTasks (state, tasks) {
      state.tasks = tasks
    },
    setNewTask (state, task) {
      state.tasks = [...state.tasks, task]
    },
    setNewFilteredTask (state, task) {
      state.filteredTasks = [...state.filteredTasks, task]
    },
    setUpdatedTask (state, task) {
      if (task.implementationStatus === 4) {
        // if task status has been set as removed (status 4), remove it from the list
        state.filteredTasks = state.filteredTasks.filter(item => {
          return item.id !== task.id
        })
      } else {
        // update the task to the task list
        state.filteredTasks = state.filteredTasks.map(item => {
          if (item.id === task.id) {
            const updatedTask = { ...task }
            updatedTask.schedules = item.schedules
            updatedTask.comments = item.comments
            updatedTask.budgets = item.budgets
            return updatedTask
          }
          return item
        })
      }
    },
    setUpdatedTasks (state, tasks) {
      state.filteredTasks = state.filteredTasks.map(item => {
        const taskIndex = tasks.findIndex(task => {
          return task.id === item.id
        })
        if (taskIndex > -1) {
          const updatedTask = { ...tasks[taskIndex] }
          updatedTask.schedules = item.schedules
          updatedTask.comments = item.comments
          updatedTask.budgets = item.budgets
          return updatedTask
        }
        return item
      })
    },
    setDeletedTask (state, task) {
      state.tasks = state.tasks.filter(item => item.id !== task.id)
    },
    setCurrentTask (state, task) {
      state.currentTask = task
    },
    setFilteredTasks (state, tasks) {
      state.filteredTasks = tasks
    },
    setRange (state, range) {
      state.yearRange = range
    },
    setYear (state, year) {
      state.selectedYear = year
    },
    setCode (state, code) {
      state.selectedCode = code
    },
    setClassCode (state, classCode) {
      state.classCode = classCode
    },
    setCategory (state, category) {
      state.selectedCategory = category
    },
    setBudgetLow (state, budget) {
      state.budgetLow = budget
    },
    setBudgetHigh (state, budget) {
      state.budgetHigh = budget
    },
    setSearch (state) {
      // use filtered tasks by sites/buildings
      // filter tasks that are within selected year and year range
      let searchedTasks = state.filteredTasks.filter(task => {
        const year = new Date(task.implementationStart).getFullYear()
        return (
          year >= state.selectedYear &&
          year < state.selectedYear + state.yearRange
        )
      })
      
      // filter tasks for the timeline chart
      // filter only tasks that start year earlier and continue to the selectedYear or over
      let timelineTasks = state.filteredTasks.filter(task => {
        const startYear = new Date(task.implementationStart).getFullYear()
        const endYear = new Date(task.implementationEnd).getFullYear()
        return startYear < state.selectedYear && endYear >= state.selectedYear
      })

      // filter tasks that are within budget range
      if (state.budgetLow || state.budgetHigh) {
        searchedTasks = searchedTasks.filter(task => {
          if (state.budgetHigh) {
            return (
              task.budget >= state.budgetLow && task.budget <= state.budgetHigh
            )
          } else {
            return task.budget >= state.budgetLow
          }
        })

        timelineTasks = timelineTasks.filter(task => {
          if (state.budgetHigh) {
            return (
              task.budget >= state.budgetLow && task.budget <= state.budgetHigh
            )
          } else {
            return task.budget >= state.budgetLow
          }
        })
      }

      // filter tasks which code matches the selected code
      // or with the selected class
      if (state.selectedCode) {
        searchedTasks = searchedTasks.filter(task => {
          if (state.selectedCode.length === 3 || state.selectedCode.slice(0, 3) === 'ESG') {
            return task.taskCode ? task.taskCode === state.selectedCode : false
          } 
          // selected code is a subclass
          return task.taskCode
            ? task.taskCode.slice(0, 2) === state.selectedCode
            : false
        })
        timelineTasks = timelineTasks.filter(task => {
          if (state.selectedCode.length === 3 || state.selectedCode.slice(0, 3) === 'ESG') {
            return task.taskCode ? task.taskCode === state.selectedCode : false
          }
          // selected code is a subclass
          return task.taskCode
            ? task.taskCode.slice(0, 2) === state.selectedCode
            : false
        })
      } else if (state.classCode) {
        searchedTasks = searchedTasks.filter(task => {
          return task.taskCode
            ? task.taskCode.slice(0, 3) === 'ESG' ? task.taskCode.slice(0, 3) === state.classCode : task.taskCode.slice(0, 1) === state.classCode
            : false
        })

        timelineTasks = timelineTasks.filter(task => {
          return task.taskCode
            ? task.taskCode.slice(0, 3) === 'ESG' ? task.taskCode.slice(0, 3) === state.classCode : task.taskCode.slice(0, 1) === state.classCode
            : false
        })
      }

      //filter by category
      if(state.selectedCategory) {
        searchedTasks = searchedTasks.filter(task => {
          return task.taskCategory ? task.taskCategory === state.selectedCategory : false
        })
        
        timelineTasks = timelineTasks.filter(task => {
          return task.taskCategory ? task.taskCategory === state.selectedCategory : false
        }) 
      }

      // filter by selected buildings
      if (state.buildingFilter.length > 0) {
        searchedTasks = searchedTasks.filter(
          task => !state.buildingFilter.includes(task.buildingId)
        )
        timelineTasks = timelineTasks.filter(
          task => !state.buildingFilter.includes(task.buildingId)
        )
      }

      // Filter by the status of the task.
      if (!state.statusFilter) {
        searchedTasks = []
      } else {
        const statuses = helpers.ltpConstants.statusMappings()

        searchedTasks = searchedTasks.filter(task => {
          const status = statuses[task.implementationStatus]
          return state.statusFilter.includes(status)
        })
      }

      state.searchedTasks = searchedTasks
      state.timelineTasks = timelineTasks
      state.loadingStatus = false
    },
    setLoading (state, status) {
      state.loadingStatus = status
    },
    setAllBuildings (state, buildings) {
      state.ltpBuildings = buildings
    },
    setBuildingFilter (state, buildings) {
      state.buildingFilter = buildings
    },
    setStatusFilter (state, statusFilter) {
      state.statusFilter = statusFilter
    },
    setColumns (state, columns) {
      state.columnFilter = columns
    },
    setEdited (state, edited) {
      state.edited = edited
    },
    setSaveStatus (state, status) {
      state.saveStatus = status
    },
    setScheduleUpdate (state, { id, changedSchedule }) {
      state.filteredTasks = state.filteredTasks.map(item => {
        if (item.id === id) {
          const updatedItem = { ...item }
          updatedItem.implementationStart = changedSchedule.toStart
          updatedItem.implementationEnd = changedSchedule.toEnd
          updatedItem.schedules = updatedItem.schedules ? [...updatedItem.schedules, changedSchedule] : [changedSchedule]
          return updatedItem
        }
        return item
      })
    },
    setNewComment (state, { id, comment }) {
      state.filteredTasks = state.filteredTasks.map(item => {
        if (item.id === id) {
          const updatedItem = { ...item }
          updatedItem.comments = updatedItem.comments ? [...updatedItem.comments, comment] : [comment]
          return updatedItem
        }
        return item
      })
    },
    setBudgetUpdate (state, { id, savedBudget }) {
      state.filteredTasks = state.filteredTasks.map(item => {
        if (item.id === id) {
          const updatedItem = { ...item }
          updatedItem.budget = savedBudget.budgetTo
          updatedItem.budgets = updatedItem.budgets ? [...updatedItem.budgets, savedBudget] : [savedBudget]
          return updatedItem
        }
        return item
      })
    },
    setDetailedSearchActive (state, active) {
      state.detailedSearchActive = active
    },
    setEsgClasses (state, esgClasses) {
      state.esgClasses = esgClasses
    },
    setEsgCodes (state, esgCodes) {
      state.esgCodes = esgCodes
    },
    setCapexClasses (state, capexClasses) {
      state.capexClasses = capexClasses
    },
    setCapexCodes (state, capexCodes) {
      state.capexCodes = capexCodes
    },
  },
  actions: {
    async getTasks ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const tasks = await api.tasks.list()
      commit('setTasks', tasks)
    },
    async saveNewTask ({ commit, dispatch, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const task = await api.tasks.post(params)
      commit('setNewTask', task)
      await dispatch('saveNewFilteredTask', task)
      await dispatch('setSearchStatus')
    },
    async saveNewFilteredTask ({ commit, state }, params) {
      commit('setNewFilteredTask', params)
    },
    async updateTask ({ commit, dispatch, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const task = await api.tasks.put(params)
      commit('setUpdatedTask', task)
      await dispatch('setSearchStatus')
    },
    async updateManyTasks ({ commit, dispatch, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const editedTasks = []
      for (const item of params) {
        editedTasks.push(api.tasks.put(item))
      }
      Promise.all(editedTasks).then(res => {
        commit('setUpdatedTasks', res)
        dispatch('setSearchStatus')
      })
    },
    async deleteTask ({ commit, state }, params) {
      // const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      // const task = await api.tasks.delete(params)
      // temp handling:
      const task = params
      commit('setDeletedTask', task)
    },
    async setCurrentTask ({ commit, state }, task) {
      commit('setCurrenTask', task)
    },
    async setFilteredTasks ({ commit, dispatch, state }, tasks) {
      // filter tasks based on selected sites
      await dispatch('filterTasks', tasks)
      await dispatch('setSearchStatus')
    },
    async filterTasks ({ commit, state }, tasks) {
      // filter tasks based on selected sites
      commit('setFilteredTasks', tasks)
    },
    setYearRange ({ commit, state }, range) {
      commit('setRange', range)
    },
    setSelectedYear ({ commit, state }, year) {
      commit('setYear', year)
    },
    setSelectedCode ({ commit, state }, code) {
      commit('setCode', code)
    },
    setSelectedCategory ({commit, state}, category){
      commit('setCategory', category)
    },
    setSelectedClass ({ commit, state }, classCode) {
      commit('setClassCode', classCode)
    },
    setLowLimit ({ commit, state }, budget) {
      commit('setBudgetLow', budget)
    },
    setHighLimit ({ commit, state }, budget) {
      commit('setBudgetHigh', budget)
    },
    async setSearchStatus ({ commit, dispatch, state }) {
      await dispatch('setLoadingStatus', true)
      commit('setSearch')
    },
    async setLoadingStatus ({ commit, state }, status) {
      commit('setLoading', status)
    },
    setLTPBuildings ({ commit, state }, buildings) {
      commit('setAllBuildings', buildings)
    },
    setBuildingsFilter ({ commit, state }, buildings) {
      commit('setBuildingFilter', buildings)
    },
    setColumnFilter ({ commit, state }, columns) {
      commit('setColumns', columns)
    },
    async saveNewComment ({ commit, dispatch, state }, params) {
      const { id, newComment } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const comment = await api.taskComments.post(id, newComment)
      commit('setNewComment', { id, comment })
      await dispatch('setSearchStatus')
    },
    async saveScheduleChange ({ commit, dispatch, state }, params) {
      const { id, change } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const changedSchedule = await api.taskSchedules.post(id, change)
      commit('setScheduleUpdate', { id, changedSchedule })
      await dispatch('setSearchStatus')
    },
    async saveBudget ({ commit, dispatch, state }, params) {
      const { id, budget } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const savedBudget = await api.taskBudgets.post(id, budget)
      commit('setBudgetUpdate', { id, savedBudget })
      await dispatch('setSearchStatus')
    },
    async getLTPClassesAndCodes ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const codes = await api.tasks.codes()
      const esgClasses = []
      const esgCodes = []
      const capexClasses = []
      const capexCodes = []

      codes.forEach(c => {
        if (c.category === 'ESG') {
          if (!esgClasses.find(cc => cc.code === c.class)) {
            esgClasses.push({
              "code": c.class,
              "id": c.class_id,
              "name": i18n.t('capex.' + c.list + '.' + c.class)
            })
          }
          esgCodes.push({
            "code": c.code,
            "name": i18n.t('capex.' + c.list + '.' + c.code),
            "id": c.id,
            "classId": c.class_id,
          })
        } else if (c.category === 'CAPEX') {
          if (!capexClasses.find(cc => cc.code === c.class)) {
            capexClasses.push({
              "code": c.class,
              "id": c.class_id,
              "name": i18n.t('capex.' + c.list + '.' + c.class)
            })
          }
          capexCodes.push({
            "code": c.code,
            "name": i18n.t('capex.' + c.list + '.' + c.code),
            "id": c.id,
            "classId": c.class_id,
          })
        }
      })
      commit('setEsgClasses', esgClasses)
      commit('setEsgCodes', esgCodes)
      commit('setCapexClasses', capexClasses)
      commit('setCapexCodes', capexCodes)
    },
  }
}