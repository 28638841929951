<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t("leasing.rental_offer") + ' ' + (currentPage+1) + '/' + pages.length }}
    </template>
    <template #content>
      <!-- General info about selected prospect, site and units -->
      <div class="pt-1 px-2 pb-3">
        <v-row
          class="pb-1"
          justify="center"
          align="center"
        >
          <v-col cols="5">
            <span class="text-body-2 font-weight-bold">
              {{ $t("Site") }}
            </span>
          </v-col>
          <v-col
            cols="5"
            class="text-body-2"
          >
            {{ currentSite.name }}
          </v-col>
        </v-row>
        <v-row
          class="pb-1"
          justify="center"
          align="center"
        >
          <v-col cols="5">
            <span class="text-body-2 font-weight-bold">
              {{ $t('leasing.brochure_units') }}
            </span>
          </v-col>
          <v-col
            cols="5"
            class="text-body-2"
          >
            {{ unitsString }} ({{ totalArea }} m²)
          </v-col>
        </v-row>
        <v-row
          class="pb-1"
          justify="center"
          align="center"
        >
          <v-col
            cols="5"
          >
            <span
              class="text-body-2 font-weight-bold"
            >
              {{ $t("prospect") }}
            </span>
          </v-col>
          <v-col
            v-if="prospect != null"
            cols="5"
            class="text-body-2"
          >
            {{ prospect.prospect_description }}
          </v-col>
        </v-row>
      </div>
      <v-divider />

      <!-- Pagination buttons -->
      <div class="text-center pb-4 pt-2">
        <v-tooltip 
          v-for="page in pages"
          :key="page.id"
          top
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              rounded
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="currentPage = page.id"
            >
              <v-icon
                small
                :class="currentPage === page.id ? 'current-icon' : page.state + '-icon'"
              >
                circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{ currentPage === page.id ? $t('leasing.current_page_tooltip') : $t(`leasing.${page.state}_tooltip`) }}</span>
        </v-tooltip>
      </div>

      <!-- Tabs -->
      <v-col #extension>
        <v-tabs
          v-model="currentPage"
        >
          <v-tab
            v-for="page in pages"
            :key="page.id"
          />
        </v-tabs>
      </v-col>
      <v-tabs-items v-model="currentPage">
        <!-- First Tab -->
        <v-tab-item
          :key="0"
        >
          <validation-observer 
            ref="rental_observer1" 
            v-slot="{ invalid1 }"
          >
            <v-form
              ref="form1"
              class="pt-2"
            >
              <!-- Offer space info -->
              <v-checkbox
                v-model="onlyPartOfUnit"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.offer_only_part_of_unit')"
              />
              <v-expand-transition>
                <v-card
                  v-show="onlyPartOfUnit"
                  elevation="0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Estimated space"
                    :rules="'required|between:1,' + (totalArea - 1)"
                  >
                    <v-text-field
                      v-model="estimatedSpace"
                      type="number"
                      lang="fi"
                      :label="$t('leasing.estimated_space') + ' *'"
                      suffix="m²"
                      class="form-fields"
                      :error-messages="$t(errors)"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Info on rentable space"
                    rules="required"
                  >
                    <v-text-field
                      v-model="infoOnRentalSpace"
                      type="text"
                      :label="$t('leasing.info_on_rental_space') + ' *'"
                      class="form-fields"
                      :error-messages="$t(errors)"
                    />
                  </validation-provider>
                </v-card>
              </v-expand-transition>

              <!-- Type of offer -->
              <validation-provider
                ref="validation_offer_type"
                v-slot="{ errors }"
                name="Offer type"
                rules="required"
              >
                <v-radio-group
                  v-model="offerType"
                  :label="$t('leasing.type_of_offer') + ' *'"
                  row
                  class="pl-2 font-weight-bold"
                >
                  <v-radio
                    :label="$t('leasing.new_rental')"
                    value="Uusi vuokraus"
                    class="font-weight-regular px-2"
                  />
                  <v-radio
                    :label="$t('leasing.continuation_contract')"
                    value="Jatkosopimus"
                    class="font-weight-regular px-2"
                  />
                </v-radio-group>
              </validation-provider>

              <!-- Tenant corporation -->
              <h3 class="form-headers">
                {{ $t('prospect.potential_business_party') + ' *' }}
              </h3>
              <validation-provider
                ref="validation_tenant_corporation"
                v-slot="{ errors }"
                name="Tenant corporation"
                rules="required"
              >
                <v-autocomplete
                  v-model="selectedTenantCorporation"
                  :items="tenantCorporations.data"
                  item-text="name"
                  item-value="id"
                  class="form-fields"
                  disabled
                  :label="$t('Tenant corporation')"
                  :loading="tenantCorporations.fetching"
                  :error-messages="$t(errors)"
                />
              </validation-provider>
              <v-text-field
                v-model="tenantClarification"
                type="text"
                :label="$t('leasing.possible_tenant_clarification')"
                class="form-fields"
              />

              <!-- Rental offer term -->
              <h2 class="pb-4 px-2">
                {{ $t("leasing.lease_duration") }}
              </h2>
              <h3 class="form-headers">
                {{ $t('leasing.start_date') }} *
              </h3>
              <v-row
                align="center"
              >
                <v-col
                  cols="4"
                >
                  <validation-provider
                    ref="validation_start_date"
                    v-slot="{ errors }"
                    name="Start date"
                    rules="date_after"
                  >
                    <v-menu
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template #activator="{ on }">
                        <v-btn
                          large
                          rounded
                          outlined
                          class="gray-outline"
                          v-on="on"
                        >
                          <v-icon
                            left
                            class="mr-2"
                          >
                            calendar_today
                          </v-icon>
                          {{ startDateAsString }}
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :locale="$i18n.locale"
                      />
                    </v-menu>
                    <v-col cols="12">
                      <span class="red--text">{{ $t(errors[0]) }}</span>
                    </v-col>
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <span class="font-weight-bold">{{ $t('leasing.information') }}:</span>
                  <br>
                  <span class="font-weight-bold">{{ $t('leasing.units_free_from') }}</span>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    large
                    rounded
                    outlined
                    disabled
                    class="disabled-date"
                  >
                    {{ lastContractEndDateForUnits }}
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Validity of offer -->
              <validation-provider
                ref="validation_validity"
                v-slot="{ errors }"
                name="Validity"
                rules="required"
              >
                <v-radio-group
                  v-model="validity"
                  :label="$t('Validity') + ' *'"
                  row
                  class="pt-4 pl-2 font-weight-bold"
                >
                  <v-radio
                    :label="$t('Fixed term')"
                    value="Määräaikainen"
                    class="font-weight-regular px-2"
                  />
                  <v-radio
                    :label="$t('Permanent')"
                    value="Toistaiseksi voimassaoleva"
                    class="font-weight-regular px-2"
                  />
                </v-radio-group>
              </validation-provider>

              <!-- Fixed-term validity fields -->
              <v-expand-transition>
                <v-card
                  v-show="validity === 'Määräaikainen'"
                  elevation="0"
                >
                  <h3
                    class="form-headers"
                  >
                    {{ $t('leasing.select_validity') }} *
                  </h3>
                  <v-row
                    align="center"
                  >
                    <v-col
                      cols="3"
                      class="text-center"
                    >
                      <v-select
                        v-model="fixedTermLength"
                        :items="fixedTermYears"
                        :label="$t('leasing.lease_length')"
                        class="mt-0 ml-2"
                        @input="changeFixedTerm('select')"
                      />
                    </v-col>
                    <v-col cols="1" />
                    <v-col
                      cols="3"
                      class="text-center"
                    >
                      <validation-provider
                        ref="validation_end_date"
                        v-slot="{ errors }"
                        name="End date"
                        :rules="'date_after:' + startDate"
                      >
                        <v-menu 
                          offset-y
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <v-btn
                              large
                              rounded
                              outlined
                              class="gray-outline mt-1"
                              v-on="on"
                            >
                              <v-icon
                                left
                                class="mr-2"
                              >
                                calendar_today
                              </v-icon>
                              {{ dateAsString(fixedTermEndDate) }}
                            </v-btn>
                          </template>
                          <v-date-picker
                            v-model="fixedTermEndDate"
                            no-title
                            scrollable
                            first-day-of-week="1"
                            :locale="$i18n.locale"
                            @input="changeFixedTerm('date-picker')"
                          />
                        </v-menu>
                        <span class="red--text">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-checkbox
                    v-model="tenantContinuationOption"
                    class="font-weight-medium form-headers pt-4 pb-2"
                    hide-details
                    :label="$t('leasing.tenant_continuation_option')"
                  />
                  <v-expand-transition>
                    <v-card
                      v-show="tenantContinuationOption"
                      elevation="0"
                    >
                      <v-textarea
                        v-model="tenantContinuationDetails"
                        rows="3"
                        flat
                        hide-details
                        auto-grow
                        :label="$t('leasing.tenant_continuation_details')"
                        class="form-fields"
                      />
                    </v-card>
                  </v-expand-transition>
                </v-card>
              </v-expand-transition>
              <!-- Permanent validity fields -->
              <v-expand-transition>
                <v-card
                  v-show="validity === 'Toistaiseksi voimassaoleva'"
                  elevation="0"
                >
                  <v-row
                    class="form-fields"
                  >
                    <v-col cols="5">
                      <v-select
                        v-model="tenantNoticePeriod"
                        :items="permanentTermMonths"
                        :label="$t('Agreement notice period (Tenant)')"
                        class="mt-0 ml-2"
                      />
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="5">
                      <v-select
                        v-model="landlordNoticePeriod"
                        :items="permanentTermMonths"
                        :label="$t('Agreement notice period (Lanlord)')"
                        class="mt-0 ml-2"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-expand-transition>
              <h3 class="form-headers">
                {{ $t('leasing.additional_fixed_term_info') }}
              </h3>
              <v-textarea
                v-model="validityDetails"
                rows="3"
                flat
                hide-details
                auto-grow
                class="form-fields"
              />
            </v-form>
          </validation-observer>
        </v-tab-item>
        <!-- Second Tab -->
        <v-tab-item
          :key="1"
        >
          <validation-observer 
            ref="rental_observer2" 
            v-slot="{ invalid2 }"
          >
            <v-form
              ref="form2"
              class="pt-2"
            >
              <h2 class="pb-2 px-2">
                {{ $t("leasing.offer_commercial_info") }}
              </h2>
              <!-- VAT -->                    
              <validation-provider
                ref="validation_vat_amount"
                v-slot="{ errors }"
                name="Vat amount"
                rules="required"
              >
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-radio-group
                    v-model="tenantVat"
                    row
                    class="pt-4 pl-2 font-weight-bold"
                    :label="$t('Vat amount') + ' *'"
                  >
                    <v-col
                      cols="10"
                      md="5"
                    >
                      <v-radio
                        :label="$t('leasing.tenant_vat_yes')"
                        :value="true"
                        class=" text-caption font-weight-regular px-2"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-radio
                        :label="$t('leasing.tenant_vat_no')"
                        :value="false"
                        class=" text-caption font-weight-regular px-2"
                      />
                    </v-col>
                  </v-radio-group>
                  <v-col cols="1">
                    <v-icon
                      v-if="!tenantVat"
                      color="warning"
                    >
                      error
                    </v-icon>  
                  </v-col>
                </v-row>
              </validation-provider>
              <!-- Contract type -->
              <h3 class="form-headers">
                {{ $t('Rental contract type') }} *
              </h3>
              <validation-provider
                ref="validation_contract_type"
                v-slot="{ errors }"
                name="Contract type"
                rules="required"
              >
                <v-select
                  v-model="selectedContractType"
                  :items="contractTypes"
                  class="mt-0 mx-2 medium-field form-fields"
                  return-object
                  item-value="id"
                >
                  <template #selection="{ item, index }">
                    {{ $t(`${item.label}`) }}
                  </template>
                  <template #item="{item, index}">
                    {{ $t(`${item.label}`) }}
                  </template>
                </v-select>
              </validation-provider>
              <v-expand-transition>
                <v-card
                  v-show="selectedContractType && selectedContractType.label !== 'Brutto (Gross)' "
                  elevation="0"
                >
                  <h3 class="form-headers">
                    {{ $t('Capital rent') }} {{ $t('Total (€/mth)').toLowerCase() }}*
                  </h3>
                  <validation-provider
                    ref="validation_capital_rent"
                    v-slot="{ errors }"
                    name="Capital rent"
                    rules="rent|required"
                  >
                    <v-text-field
                      v-model="capitalRent"
                      suffix="€"
                      lang="fi"
                      type="number"
                      class="form-fields medium-field"
                      :error-messages="$t(errors)"
                    />
                  </validation-provider>
                </v-card>
              </v-expand-transition>
                  
              <!-- Contract type: Gross rent -->
              <v-expand-transition>
                <v-card
                  v-show="selectedContractType && selectedContractType.label === 'Brutto (Gross)' "
                  elevation="0"
                >
                  <h3 class="form-headers">
                    {{ $t('portfolios.gross_rent') }} € *
                  </h3>
                  <validation-provider
                    ref="validation_gross_rent"
                    v-slot="{ errors }"
                    name="Gross rent"
                    rules="rent|required"
                  >
                    <v-text-field
                      v-model="grossRent"
                      type="number"
                      lang="fi"
                      suffix="€"
                      class="form-fields medium-field"
                      :error-messages="$t(errors)"
                    />
                  </validation-provider>
                </v-card>
              </v-expand-transition>
              <v-row
                align="center"
                  
                class="pt-2"
              >
                <v-col cols="4">
                  <h3 class="form-headers">
                    {{ $t('Market rent simple') }} (€/m²)
                  </h3>
                </v-col>
                <v-col cols="1" />
                <v-col cols="7">
                  <h3 class="form-headers">
                    {{ $t('Total market rent') + ' €' }}
                  </h3>
                </v-col>
                <v-col cols="4"> 
                  <v-text-field
                    v-model="unitsMarketRentString"
                    type="text"
                    suffix="€"
                    disabled
                    class="form-fields"
                  />
                </v-col>
                <v-col cols="1" />
                <v-col cols="3">
                  <v-text-field
                    v-model="unitsMarketRentTotal"
                    type="number"
                    suffix="€"
                    disabled
                    class="form-fields"
                  />
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-card
                  v-show="selectedContractType && selectedContractType.label === 'jaettu vuokra (=ylläpitovuokra)'"
                  elevation="0"
                >
                  <h3 class="form-headers">
                    {{ $t('Maintenance charges estimation') + ' (€/m²/' + $t('Mth') + ') *' }}
                  </h3>
                  <validation-provider
                    ref="validation_maintenance_charges"
                    v-slot="{ errors }"
                    name="Maintenance charges"
                    rules="rent|required"
                  >
                    <v-text-field
                      v-model="upkeepRent"
                      suffix="€"
                      type="number"
                      lang="fi"
                      class="form-fields medium-field"
                      :error-messages="$t(errors)"
                    />
                  </validation-provider>
                  <v-row
                    align="center"
                      
                    class="pt-2"
                  >
                    <v-col cols="4">
                      <h3 class="form-headers">
                        {{ $t('leasing.site_upkeep_rent_estimate') }} (€/m²)
                      </h3>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="3">
                      <h3 class="form-headers">
                        {{ $t('leasing.upkeep_rent_estimate_total') }}
                      </h3>
                    </v-col>
                    <v-col cols="4" />
                    <v-col cols="4"> 
                      <v-text-field
                        v-model="upkeepRentEstimateSite"
                        type="number"
                        disabled
                        suffix="€"
                        class="form-fields"
                      />
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="3">
                      <v-text-field
                        v-model="upkeepRentEstimateTotal"
                        type="number"
                        suffix="€"
                        disabled
                        class="form-fields"
                      />
                    </v-col>
                  </v-row>
                  <h3 class="form-headers">
                    {{ $t('leasing.total_expense') + ' (€/' + $t('Mth') + ')' }}
                  </h3>
                  <v-text-field
                    v-model="totalExpense"
                    type="number"
                    disabled
                    suffix="€"
                    class="form-fields medium-field"
                  />
                </v-card>
              </v-expand-transition>
              <!-- Other rent related information -->
              <v-checkbox
                v-model="salesTiedRent"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.rent_tied_to_sales')"
              />
              <v-expand-transition>
                <v-card
                  v-show="salesTiedRent"
                  elevation="0"
                >
                  <v-textarea
                    v-model="salesTiedRentComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
              <v-checkbox
                v-model="marketingPayment"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.marketing_payment')"
              />
              <v-expand-transition>
                <v-card
                  v-show="marketingPayment"
                  elevation="0"
                >
                  <v-textarea
                    v-model="marketingPaymentComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
              <v-checkbox
                v-model="additionalServices"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.additional_services')"
              />
              <v-expand-transition>
                <v-card
                  v-show="additionalServices"
                  elevation="0"
                >
                  <v-textarea
                    v-model="additionalServicesComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
              <v-checkbox
                v-model="clarificationsOnRent"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.clarifications_on_rent')"
              />
              <v-expand-transition>
                <v-card
                  v-show="clarificationsOnRent"
                  elevation="0"
                >
                  <v-textarea
                    v-model="clarificationsOnRentComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
                
              <v-row
                align="center"
                class="pt-4 pb-2"
              >
                <v-col
                  cols="4"
                  class="pl-2 font-weight-bold"
                >
                  <h3>
                    {{ $t('leasing.deposit') }} *
                  </h3>
                </v-col>
                <v-col cols="5">
                  <validation-provider
                    ref="validation_deposit_type"
                    v-slot="{ errors }"
                    name="Deposit type"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedDepositType"
                      :items="depositTypes"
                      return-object
                      item-value="id"
                      class="mt-0 mx-2"
                    >
                      <template #selection="{ item, index }">
                        {{ $t(`${item.label}`) }}
                      </template>
                      <template #item="{item, index}">
                        {{ $t(`${item.label}`) }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <h3 class="form-headers">
                {{ $t('leasing.deposit_comments') }}
              </h3>
              <v-textarea
                v-model="depositComments"
                rows="3"
                flat
                hide-details
                auto-grow
                class="form-fields"
              />
            </v-form>
          </validation-observer>
        </v-tab-item>
        <!-- Third tab  -->
        <v-tab-item
          :key="2"
        >
          <validation-observer 
            ref="rental_observer3" 
            v-slot="{ invalid3 }"
          >
            <v-form
              ref="form3"
              class="pt-2"
            >
              <!-- Incentives for tenant -->
              <h2 class="pb-4 px-2">
                {{ $t("leasing.incentives_for_tenant") }}
              </h2>
              <v-col>
                <h3 class="form-headers">
                  {{ $t('leasing.rental_renovation_budget') }}
                </h3>
              </v-col>
              <v-col cols="5"> 
                <v-text-field
                  v-model="renovationBudget"
                  type="text"
                  suffix="€"
                  disabled
                  class="form-fields"
                />
              </v-col>
              <v-checkbox
                v-model="renovations"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.renovations')"
              />
              <v-expand-transition>
                <v-card
                  v-show="renovations"
                  elevation="0"
                >
                  <v-textarea
                    v-model="renovationsComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
              <v-checkbox
                v-model="rentFree"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.rent_free')"
              />
              <v-expand-transition>
                <v-card
                  v-show="rentFree"
                  elevation="0"
                >
                  <v-select
                    v-model="selectedRentFree"
                    :items="permanentTermMonths.slice(0, 6)"
                    class="mt-0 ml-2 medium-field form-fields"
                  />
                </v-card>
              </v-expand-transition>
              <v-checkbox
                v-model="otherRentFrees"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.other_rent_frees')"
              />
              <v-expand-transition>
                <v-card
                  v-show="otherRentFrees"
                  elevation="0"
                >
                  <v-textarea
                    v-model="otherRentFreesComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
              <v-checkbox
                v-model="otherIncentives"
                class="font-weight-medium form-headers"
                hide-details
                :label="$t('leasing.other_incetives')"
              />
              <v-expand-transition>
                <v-card
                  v-show="otherIncentives"
                  elevation="0"
                >
                  <v-textarea
                    v-model="otherIncentivesComment"
                    rows="3"
                    flat
                    hide-details
                    auto-grow
                    class="form-fields"
                    :label="$t('Comments')"
                  />
                </v-card>
              </v-expand-transition>
              <!-- Other offer information to be printed -->
              <h2 class="py-4 px-2">
                {{ $t("leasing.rental_offer_other_information") }}
              </h2>
              <h3 class="form-headers">
                {{ $t('Number of car parks') }}
              </h3>
              <v-text-field
                v-model="numberOfCarParks"
                type="number"
                class="form-fields"
              />
              <h3 class="form-headers">
                {{ $t('leasing.index_condition') }}
              </h3>
              <v-select
                v-model="selectedRentReviewType"
                :items="rentReviewTypes"
                return-object
                item-value="id"
                class="mt-0 ml-2 medium-field"
              >
                <template #selection="{ item, index }">
                  {{ $t(`${item.label}`) }}
                </template>
                <template #item="{item, index}">
                  {{ $t(`${item.label}`) }}
                </template>
              </v-select>
              <h3 class="form-headers pt-4">
                {{ $t('leasing.audit_other_terms') }}
              </h3>
              <v-text-field
                v-model="auditOtherTerms"
                type="text"
                class="form-fields"
              />
              <!-- Offer validity period -->
              <h3 class="form-headers">
                {{ $t('leasing.offer_validity') }}
              </h3>
              <validation-provider
                ref="validation_validity_date"
                v-slot="{ errors }"
                name="Validity date"
                rules="date_after"
              >
                <v-menu
                  offset-y
                  :close-on-content-click="false"
                >
                  <template #activator="{ on }">
                    <v-btn
                      large
                      rounded
                      outlined
                      class="gray-outline mt-1"
                      v-on="on"
                    >
                      <v-icon
                        left
                        class="mr-2"
                      >
                        calendar_today
                      </v-icon>
                      {{ dateAsString(offerValidityDate) }}
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="offerValidityDate"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
                <v-col cols="12">
                  <span class="red--text">{{ $t(errors[0]) }}</span>
                </v-col>
              </validation-provider>
              <h3 class="form-headers pt-3">
                {{ $t('Extra info') }}
              </h3>
              <v-textarea
                v-model="offerComments"
                rows="3"
                flat
                hide-details
                auto-grow
                class="form-fields"
              />
            </v-form>
          </validation-observer>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-col
        cols="3"
        class="text-center"
      >
        <v-btn
          v-if="currentPage !== 0"
          text
          rounded
          outlined
          depressed
          @click="currentPage--"
        >
          <v-icon left>
            arrow_back_ios
          </v-icon>
          {{ $t('Previous') }}
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <v-btn
          rounded
          depressed
          class="primary"
          @click="saveAsDraft"
        >
          {{ $t('Save as draft') }}
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        class="text-center"
      >
        <v-btn
          v-if="currentPage !== 2"
          text
          rounded
          outlined
          depressed
          @click="currentPage++"
        >
          {{ $t('Next') }}
          <v-icon right>
            arrow_forward_ios
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="blue-button"
          rounded
          depressed
          @click="print"
        >
          {{ $t('Print') }}
        </v-btn>
      </v-col>
    </template>
  </BaseModal>
</template>

<script>

import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from "vee-validate";
import { between } from "vee-validate/dist/rules"
import helpers from '../../helpers'
import { mapGetters } from 'vuex'
import { i18n } from '../../plugins/i18n.js'
import BaseModal from '../general/BaseModal.vue'


setInteractionMode('eager')

extend('between', {
   message: 'leasing.incorrect_input',
  ...between,
})

extend('date_after', {
   message: 'leasing.incorrect_date',
  validate (value, args) {
    if (args.length > 0) {
      return value.localeCompare(args[0]) >= 0
    }
    else {
      const today = new Date()
      today.setHours(0,0,0,0);
      const todayString = today.toISOString()
      return value.localeCompare(todayString) >= 0
    }
    
  }
})

extend('rent', {
  validate: (value, args) => {
    if (value > 100000000) {
      return false
    }
    else {
      return true
    }
  },
  message: 'prospect.rent_validation'
})

export default {
  emits: ['close', 'save'],
    name: "RentalOfferDialog",
      components: {
        BaseModal,
        ValidationProvider,
        ValidationObserver
      },
    props: {
        prospect: { type: Object, default: null },
        units: { type: Array, default: () => [] },
        totalArea: { type: Number, default: 0 },
        unitsString: { type: String, default: ""},
        isShown: { type: Boolean, default: false },
        currentSite: { type: Object, default: null }
    },
    data () {
        return (
            {
                currentPage: 0,
                pages: [
                  { id: 0, state: 'unknown'},
                  { id: 1, state: 'unknown'},
                  { id: 2, state: 'unknown'}
                ],
                onlyPartOfUnit: false,
                estimatedSpace: '',
                infoOnRentalSpace: '',
                offerType: 'Uusi vuokraus',
                selectedTenantCorporation: null,
                tenantCorporations: {
                  data: [],
                  fecthing: false,
                },
                tenantClarification: '',
                startDate: new Date().toISOString(),
                validity: 'Määräaikainen',
                fixedTermWithEndDate: false,
                fixedTermLength: null,
                fixedTermEndDate: new Date().toISOString(),
                tenantContinuationOption: false,
                tenantContinuationDetails: '',
                validityDetails: '',
                tenantNoticePeriod: 12,
                landlordNoticePeriod: 6,
                tenantVat: true,
                selectedContractType: null,
                capitalRent: '',
                upkeepRent: '',
                grossRent: '',
                salesTiedRent: false,
                salesTiedRentComment: '',
                marketingPayment: false,
                marketingPaymentComment: '',
                additionalServices: false,
                additionalServicesComment: '',
                clarificationsOnRent: false,
                clarificationsOnRentComment: '',
                selectedDepositType: null,
                depositComments: '',
                renovations: false,
                renovationsComment: '',
                rentFree: false,
                selectedRentFree: 1,
                otherRentFrees: false,
                otherRentFreesComment: '',
                otherIncentives: false,
                otherIncentivesComment: '',
                numberOfCarParks: '',
                selectedRentReviewType: null,
                auditOtherTerms: '',
                offerValidityDate: new Date().toISOString(),
                offerComments: ''
            } 
        )
    },
    computed: {
      ...mapGetters('app', ['definitionLabelById', 'definitionsByGroupLabel']),
      startDateAsString () {
        let converted = new Date(this.startDate).toISOString()
        return helpers.humanize.date(converted, 'date')
      },
      lastContractEndDateForUnits () {
          const endDates = this.units.filter(item => item.contract_end_date != null).map(unit => unit.contract_end_date)
          if (endDates.length > 0) {
            endDates.sort((a,b) => - a.localeCompare(b))
            return helpers.humanize.date(endDates[0], 'date')
          }
          else {
            return '-'
          }
      },
      fixedTermYears () {
        let array = Array(15).fill(1).map((item, index) => {
          return {value: index+1, text: `${index+1} ${this.$t('years')}`}
        })
        return array;
      },
      permanentTermMonths () {
        let array = Array(24).fill(1).map((item, index) => {
          return {value: index+1, text: `${index+1} ${this.$t('Mth')}`}
        })
        return array;
      },
      unitsMarketRentString () {
        return this.units.reduce((prev, curr, index) => {
          let returnString = prev
          let currMarketRent = curr.market_rent.toString()
          if (i18n.locale === 'fi' ) currMarketRent = currMarketRent.replace('.', ',')
          if (curr.market_rent) returnString += currMarketRent
          else  returnString += '0'
          if (index < this.units.length-1) returnString += ", "
          return returnString 
        }, '')
      },
      unitsMarketRentTotal () {
        const total = this.units.reduce((prev, curr) => {
          return prev + curr.market_rent * curr.area
        }, 0)
        return Math.floor(total * 100) / 100
      },
      upkeepRentEstimateSite () {
        if (this.tenantVat) {
          return Number(this.currentSite.upkeep_rent_budget_vat)
        }
        else {
          return Number(this.currentSite.upkeep_rent_budget)
        }
      },
      upkeepRentEstimateTotal () {
        const space = Number(this.estimatedSpace)
        const upkeepValue = Number(this.upkeepRent)
        let returnValue = 0
        if (this.onlyPartOfUnit && space > 0) {
          returnValue =  upkeepValue * space
        }
        else {
          returnValue = upkeepValue * this.totalArea
        }
        return  Math.round((returnValue + Number.EPSILON) * 100) / 100
      },
      totalExpense () {
        let returnValue = (Number(this.capitalRent) + Number(this.upkeepRentEstimateTotal))
        return  Math.round((returnValue + Number.EPSILON) * 100) / 100
      },
      contractTypes () {
        const types = this.definitionsByGroupLabel('site.contracttype')
        const filteredTypes = types.filter(type =>  
          type.label == 'Double-net (Double Net)' ||
          type.label == 'Triple-net (Triple Net)' ||
          type.label == 'Brutto (Gross)' ||
          type.label == 'jaettu vuokra (=ylläpitovuokra)')
        return filteredTypes
      },
      depositTypes () {
        return this.definitionsByGroupLabel('rentaloffer.guaranteetype')
      },
      rentReviewTypes () {
        return this.definitionsByGroupLabel('rentaloffer.rentreviewtype')
      },
      renovationBudget () 
      {
        if(this.units === undefined)
        {
          return 0
        }

        return this.units.reduce ((prev, cur) => {
          if(cur.area === null || (cur.clarified_ti_expense === null && cur.estimated_ti_expense === null))
          {
            return prev
          }
          return prev + (cur.clarified_ti_expense === null ? cur.estimated_ti_expense * cur.area : cur.clarified_ti_expense * cur.area)
        }, 0)
      }
    },
    watch: {
      isShown: async function (shown) {
        if (!shown) {
          this.reset()
          this.$refs.rental_observer1.reset()
          if (this.$refs.rental_observer2) {
            this.$refs.rental_observer2.reset()
          }
          if (this.$refs.rental_observer3) {
            this.$refs.rental_observer3.reset()
          }
        }
        else {
          await this.getTenantCorporations()
          if (this.prospect && this.prospect.id_osapuoli) {
            const foundIndex = this.tenantCorporations.data.findIndex(corporation => 
              corporation.id == this.prospect.id_osapuoli
            )
            this.selectedTenantCorporation = this.tenantCorporations.data[foundIndex]
          }
          const rentalContractType = this.currentSite.rental_contract_type
          const contractIndex = this.contractTypes.findIndex(item => item.value == rentalContractType)
          if (contractIndex > -1) {
            this.selectedContractType = this.contractTypes[contractIndex]
          }
          else {
            this.selectedContractType = this.contractTypes[0]
          }
          this.selectedDepositType = this.depositTypes[0]
          this.selectedRentReviewType = this.rentReviewTypes[0]
          const fortnight = new Date();
          fortnight.setDate(fortnight.getDate() + 14)
          this.offerValidityDate = fortnight.toISOString()
        }
      },
      // Change fixed term end date if fixed term length is set in years
      startDate: function (newDate) {
        if (this.validity === 'Määräaikainen' && !this.fixedTermWithEndDate) {
          let selectedDate = new Date(newDate);
          selectedDate.setFullYear(selectedDate.getFullYear() + this.fixedTermLength);
          this.fixedTermEndDate = selectedDate.toISOString()
          this.fixedTermWithEndDate = false
        }
      },
      // Validation when changing pages
      currentPage: async function (newPage, oldPage) {
        let isValid = false
        if (this.isShown) {
          switch(oldPage) {
            case 0:
              isValid = await this.validatePage(0)
              if (isValid) {
                this.pages[0].state = 'success'
              }
              else {
                this.pages[0].state = 'error'
              }
              break
            case 1:
              isValid = await this.validatePage(1)
              if (isValid) {
                this.pages[1].state = 'success'
              }
              else {
                this.pages[1].state = 'error'
              }
              break
            case 2:
              isValid = await this.validatePage(2)
              if (isValid) {
                this.pages[2].state = 'success'
              }
              else {
                this.pages[2].state = 'error'
              }
              break
            default:
              break
          }
        }
      },
    },
    async mounted () {
      await this.getTenantCorporations()
      if (this.prospect && this.prospect.id_osapuoli) {
        const foundIndex = this.tenantCorporations.data.findIndex(corporation => 
          corporation.id == this.prospect.id_osapuoli
        )
        this.selectedTenantCorporation = this.tenantCorporations.data[foundIndex]
      }
      const rentalContractType = this.currentSite.rental_contract_type
      const contractIndex = this.contractTypes.findIndex(item => item.value == rentalContractType)
      if (contractIndex > -1) {
        this.selectedContractType = this.contractTypes[contractIndex]
      }
      else {
        this.selectedContractType = this.contractTypes[0]
      }
      this.selectedDepositType = this.depositTypes[0]
      this.selectedRentReviewType = this.rentReviewTypes[0]
      const fortnight = new Date();
      fortnight.setDate(fortnight.getDate() + 14)
      this.offerValidityDate = fortnight.toISOString()
    },
    methods: {
      close () {
       if (confirm(this.$t('leasing.offer_close_confirmation'))) {
          this.$emit('close')
       }
      },
      save (offer) {
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
        this.$emit('save', offer)
      },
      saveAsDraft () {
        const offer = this.createOffer()
        alert(this.$t('leasing.offer_close_temp_text'))
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_template_successful'))
        this.save(offer)
      },
      async print () {
        let isValid = true
        for (let i = 0; i < 3; i++) {
          isValid = await this.validatePage(i)
          if (!isValid) {
            break;
          }
        }
        if (isValid) {
          const offer = this.createOffer(false)
          alert(this.$t('leasing.offer_close_temp_text'))
          this.save(offer)
        }
        else {
          alert(`${this.$t('leasing.incorrect_input')}. ${this.$t('leasing.please_check_inputs')}.`)
        }
      },
      reset () {
      Object.assign(this.$data, this.$options.data());
      },
      async getTenantCorporations () {
        this.tenantCorporations.fetching = true
        const data = await this.$rambollfmapi.parties.list({ query: { party_type: 0, visibility_status: true } })
        this.tenantCorporations.data = data.filter(party => party.tenant_corporation === true)
        this.tenantCorporations.fetching = false
      },
      dateAsString (givenDate) {
        let converted = new Date(givenDate).toISOString()
        return helpers.humanize.date(converted, 'date')
      },
      // Change fixed term end date if fixed term is set in years
      changeFixedTerm (type) {
        if (type === 'select') {
          let selectedDate = new Date(this.startDate);
          selectedDate.setFullYear(selectedDate.getFullYear() + this.fixedTermLength);
          this.fixedTermEndDate = selectedDate.toISOString()
          this.fixedTermWithEndDate = false
        }
        else {
          this.fixedTermWithEndDate = true
          this.fixedTermLength = null
        }
      },
      // Validate a single validation provider field
      validateField (field) {
        const provider = this.$refs[field];

        // Validate the field
        return provider.validate();
      },
      async validatePage (pageIndex) {
        if (pageIndex === 0) {
          // If only part of unit -checkbox is selected, we can validate the entire page...
          if (this.onlyPartOfUnit) {
            return await this.$refs.rental_observer1.validate()
          }
          // ... otherwise we have to validate only the fields that are shown
          else {
            let promises = []
            const fields = ['validation_offer_type', 'validation_tenant_corporation', 'validation_validity', 'validation_start_date', 'validation_end_date']
            fields.forEach(item => promises.push(this.validateField(item)))
            const results = await Promise.all(promises)
            return results.every(result => result.valid)
          }
        }
        else if (pageIndex === 1) {
          // List of fields that need to be validated
          const fields = ['validation_vat_amount', 'validation_contract_type', 'validation_deposit_type']

          if (this.selectedContractType.label === 'Brutto (Gross)') {
            fields.push('validation_gross_rent')
          }
          else {
            fields.push('validation_capital_rent')
          }

          if (this.selectedContractType.label === 'jaettu vuokra (=ylläpitovuokra)') {
            fields.push('validation_maintenance_charges')
          }

          const promises = []
          fields.forEach(item => promises.push(this.validateField(item)))
          const results = await Promise.all(promises)
          return results.every(result => result.valid)
        }
        else {
          return await this.$refs.rental_observer3.validate()
        }
      },
      createOffer () {

        // Only part of unit
        let spaceEstimate = null
        if (this.onlyPartOfUnit) {
          spaceEstimate =  parseFloat(this.estimatedSpace)
        }

        // Notice periods
        let noticeTenant = null
        let noticeLandlord = null
        let fixedTermEndDate = null
        if (this.validity === 'Toistaiseksi voimassaoleva') {
          noticeTenant = parseInt(this.tenantNoticePeriod)
          noticeLandlord = parseInt(this.landlordNoticePeriod)
        }
        else {
          fixedTermEndDate = this.fixedTermEndDate
        }

        // Rent free months
        let rentFreeMonths = null
        if (this.rentFree) {
          rentFreeMonths = parseInt(this.selectedRentFree)
        }

        // Rents
        let absoluteNetRent = null
        let grossRent = null
        let maintenanceCostEstimate = null
        let totalCost = null
        if (this.selectedContractType.label === 'Brutto (Gross)') {
          grossRent = parseFloat(this.grossRent)
        }
        else if (this.selectedContractType.label === 'jaettu vuokra (=ylläpitovuokra)') {
          absoluteNetRent = parseFloat(this.capitalRent)
          maintenanceCostEstimate = parseFloat(this.upkeepRent)
          totalCost = parseFloat(this.totalExpense)
        }
        else {
          absoluteNetRent = parseFloat(this.capitalRent)
        }

        // Parkingspaces
        let parkingSpaces = null
        if (this.numberOfCarParks.length > 0) {
          parkingSpaces = parseInt(this.numberOfCarParks)
        }


        // Create offer object from component data
        const offer = {
          id_prospect_printout: null,
          ver: 1,
          only_part_of_unit: this.onlyPartOfUnit,
          space_estimate: spaceEstimate,
          space_details: this.infoOnRentalSpace,
          type_of_offer: this.offerType,
          id_konserni: this.selectedTenantCorporation.id,
          tenant_details: this.tenantClarification,
          lease_start_date: this.startDate,
          lease_end_date: fixedTermEndDate,
          validity_type: this.validity,
          notice_tenant: noticeTenant,
          notice_landlord: noticeLandlord,
          tenant_continuation_option: this.tenantContinuationOption,
          tenant_continuation_details: this.tenantContinuationDetails,
          term_details: this.validityDetails,
          vat_required_operation: this.tenantVat,
          rental_contract_type: this.selectedContractType.id,
          absolute_net_rent: absoluteNetRent,
          gross_rent: grossRent,
          maintenance_cost_estimate: maintenanceCostEstimate,
          total_cost: totalCost,
          sales_tied_rent: this.salesTiedRent,
          sales_tied_rent_details: this.salesTiedRentComment,
          marketing_payment: this.marketingPayment,
          marketing_payment_details: this.marketingPaymentComment,
          additional_services: this.additionalServices,
          additional_services_details: this.additionalServicesComment,
          rent_clarification: this.clarificationsOnRent,
          rent_clarification_details: this.clarificationsOnRentComment,
          guarantee_type: this.selectedDepositType.id,
          guarantee_details: this.depositComments,
          modifications_before_start: this.renovations,
          modifications_before_start_details: this.renovationsComment,
          rent_free_months_absolute_net_rent: this.rentFree,
          rent_free_months_absolute_net_rent_amount: rentFreeMonths,
          other_free_months: this.otherRentFrees,
          other_free_months_details: this.otherRentFreesComment,
          other_incentives: this.otherIncentives,
          other_incentives_details: this.otherIncentivesComment,
          parking_spaces: parkingSpaces,
          rent_review_condition: this.selectedRentReviewType.id,
          rent_review_additional_terms: this.auditOtherTerms,
          offer_validity_date: this.offerValidityDate,
          additional_info: this.offerComments
        }
        return offer
      }
    }
}
</script>

<style scoped>
.form-fields {
  margin: 0px 10px 25px 10px !important;
  display: flex;
}
.form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
.current-icon {
  color: var(--c-color-accent) !important;
}
.error-icon {
  color: #ff5252 !important;
}
.success-icon {
  color: #47ccaa !important;
}
.unknown-icon {
  color: grey !important;
}
.medium-field {
  width: 300px;
}
.v-input--is-disabled {
  background: #f5f5f5 !important;
  border: #8b8b8b 1px solid !important;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-input--is-disabled >>> .v-input__slot::before {
  border-style: none !important;
}
.disabled-date {
  background: #f5f5f5 !important;
  border: #8b8b8b 1px solid !important;
}
</style>