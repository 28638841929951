<template>
  <v-main>
    <v-dialog
      :value="shownProspect != null && showProspectModal"
      persistent
      width="700"
    >
      <v-card>
        <ProspectModal
          :prospect="shownProspect"
          :is-shown="showProspectModal"
          :user-info="userInfo"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      :value="shownCorporation && showCorporationModal"
      persistent
      width="700"
    >
      <v-card>
        <CorporationModal
          :corporation="shownCorporation"
          :rental-processes="rentalProcesses"
          :is-shown="showCorporationModal"
        />
      </v-card>
    </v-dialog>
    <nav :aria-label="$t('SubMenu')" >
      <v-row no-gutters>
        <v-col>
          <v-tabs
            class="pl-5"
            show-arrows
            cols="auto"
          >
            <v-tab
              :to="{ name: 'renting.own_active' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('leasing.own_active') }}
            </v-tab>
            <v-tab
              v-if="hasApplicationPermissionByName('LEASING_YLEISTILANNE')"
              :to="{ name: 'renting.general_situation' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('leasing.general_situation') }}
            </v-tab>
            <v-tab
              v-if="hasApplicationPermissionByName('LEASING')"
              :to="{ name: 'renting.renting_process' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('leasing.rentingProcess') }}
            </v-tab>
            <v-tab
              v-if="hasApplicationPermissionByName('LEASING_JATKOT_UUSINNAT')"
              :to="{ name: 'renting.renegotiations_and_renewals' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('leasing.renegotiations_and_renewals') }}
            </v-tab>
            <v-tab
              v-if="hasApplicationPermissionByName('LEASING_VUOKRATTAVAT_TILAT')"
              :to="{ name: 'renting.rentable_units' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('leasing.rentable_units') }}
            </v-tab>
            <v-tab
              v-if="hasApplicationPermissionByName('LEASING_SUUNNITELMA')"
              :to="{ name: 'renting.lease_plan' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('Lease plan') }}
            </v-tab>
            <v-tab
              v-if="hasApplicationPermissionByName('LEASING_ASIAKKAAT')"
              :to="{ name: 'renting.customers' }"
              exact
              @click="updateTabLocation()"
            >
              {{ $t('leasing.customers') }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col 
          cols="auto"
          v-if="siteFiltersByTypes('all').length > 0"
          class="text-right"
        >
          <FilteringStatusChip  />
        </v-col>
      </v-row>
      <v-divider />
    </nav>
    <v-container fluid>
      
      <!-- Switch for filtering responsible sites -->
      <v-container
        class="toolbar dashboard fill-height wrap"
        style="margin-left: 0px"
      > 
        <v-row
          v-if="
            tabLocation === 'renting.prospects' ||
              tabLocation === 'renting.renegotiations_and_renewals' ||
              tabLocation === 'renting.rentable_units' ||
              tabLocation === 'renting.renting_process'
          "
          justify="start"
          align="center"
        >
          <v-col
            cols="6"
            sm="5"
            md="4"
            lg="3"
            xl="3"
          >
            <v-switch
              v-model="responsibility"
              :label="$t('leasing.my_responsiblity') "
              class="switch ma-0"
              hide-details="true"
            />
          </v-col>
          <v-col
            cols="6"
            xl="3"
          >
            <MultiSelect
              v-if="tabLocation === 'renting.renting_process'"
              v-model="selectedResponsibles"
              :label="$t('leasing.select_other_responsibles')"
              class="ma-0"
              :items="rentalProcessResponsibles"
              item-text="name"
            />
          </v-col>
        </v-row>
        <v-container 
          v-if="isMobile"
          style="text-align:center"
        >
          <div
            v-if="
              inspectionQuarters.length != 0 || inspectionYear != thisYear
            "
            style="margin-left: 15px"
          >
            <slot
              v-if="
                inspectionPeriod <= -3 ||
                  (inspectionYear && inspectionYear < thisYear) ||
                  (inspectionYear === thisYear &&
                    thisMonth - 3 >= inspectionQuarters) 
              "
            >
              <p style="font-weight: bold">
                {{ $t('Viewing past') }}
              </p>
            </slot>
            <slot
              v-else-if="
                inspectionPeriod >= 3 ||
                  inspectionYear > thisYear ||
                  (inspectionYear === thisYear &&
                    thisMonth < inspectionQuarters)
              "
            >
              <p style="font-weight: bold; color: red">
                {{ $t('Viewing future') }}
              </p>
            </slot>
            <slot v-else-if="inspectionQuarters.length >= 2">
              <p style="font-weight: bold">
                {{ $t('Viewing multiple selections') }}
              </p>
            </slot>
          </div>
        </v-container>
        <!-- Quarter select -->
        <v-row
          v-if="
            tabLocation === 'renting.general_situation' ||
              tabLocation === 'renting.renegotiations_and_renewals' ||
              tabLocation === 'renting.rental_offers'
          "
          no-gutters
        >
          <v-row
            align="baseline"
            justify="center"
            style="margin: 5px 10px"
            no-gutters
          >
            <v-select
              v-model="inspectionYear"
              :disabled="sitesLoading"
              :label="$t('Selected Year')"
              :items="years"
              class="select"
              :menu-props="{ offsetY: true }"
              hide-details
              @change="onInspectionYearChange"
            />
            <v-select
              v-model="inspectionQuarters"
              :disabled="sitesLoading"
              :label="$t('leasing.inspection_quarter')"
              :items="quarters"
              class="select"
              style="margin-left: 5px"
              :menu-props="{ offsetY: true }"
              multiple
              persistent-placeholder
              offset-y
              hide-details
              @change="onInspectionQuartersChange"
            />
            <p class="time-select-splitter">
              {{ $t('or').toUpperCase() }}
            </p>
            <v-select
              v-model="inspectionPeriod"
              :disabled="sitesLoading"
              :label="$t('leasing.now')"
              :items="timePeriods"
              class="select"
              style="margin-left: 5px"
              :menu-props="{ offsetY: true }"
              hide-details
              @change="onInspectionPeriodChange"
            />
          </v-row>
          <v-col v-if="!isMobile">
            <div
              v-if="
                inspectionQuarters.length != 0 || inspectionYear != thisYear
              "
              style="margin-left: 15px"
            >
              <slot
                v-if="
                  inspectionPeriod <= -3 ||
                    (inspectionYear && inspectionYear < thisYear) ||
                    (inspectionYear === thisYear &&
                      thisMonth - 3 >= inspectionQuarters) 
                "
              >
                <p style="font-weight: bold">
                  {{ $t('Viewing past') }}
                </p>
              </slot>
              <slot
                v-else-if="
                  inspectionPeriod >= 3 ||
                    inspectionYear > thisYear ||
                    (inspectionYear === thisYear &&
                      thisMonth < inspectionQuarters)
                "
              >
                <p style="font-weight: bold; color: red">
                  {{ $t('Viewing future') }}
                </p>
              </slot>
              <slot v-else-if="inspectionQuarters.length >= 2">
                <p style="font-weight: bold">
                  {{ $t('Viewing multiple selections') }}
                </p>
              </slot>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <div class="ma-4">
      <div
        v-if="!tabLocation"
        class="loading"
      >
        <v-progress-circular
          size="64"
          indeterminate
          color="primary"
          class="text-center"
        />
      </div>
      <OwnActive v-else-if="tabLocation === 'renting.own_active'" />
      <GeneralSituation
        v-else-if="tabLocation === 'renting.general_situation'"
        :lower-bound-quarter-dates="lowerBoundQuarterDates"
        :upper-bound-quarter-dates="upperBoundQuarterDates"
        :inspection-year="inspectionYear"
        :party-links="partyLinks"
        :party-links-loading="partyLinksLoading"
      />
      <Prospects
        v-else-if="tabLocation === 'renting.prospects'"
        :user-sites="userSites"
        :show-only-user-sites="responsibility"
      />
      <RentingProcess
        v-else-if="tabLocation === 'renting.renting_process'"
        :filter-responsibility="responsibility"
        :selected-responsibles="selectedResponsibles"
        :user-sites="userSites"
      />
      <RenegotiationsAndRenewals
        v-else-if="tabLocation === 'renting.renegotiations_and_renewals'"
        :user-sites="userSites"
        :lower-bound-quarter-dates="lowerBoundQuarterDates"
        :upper-bound-quarter-dates="upperBoundQuarterDates"
      />
      <RentableUnits
        v-else-if="tabLocation === 'renting.rentable_units'"
        :user-sites="userSites"
      />
      <Customers v-else-if="tabLocation === 'renting.customers'" />
      <LeasePlan
        v-else-if="tabLocation === 'renting.lease_plan'"
        :party-links="partyLinks"
        :party-links-loading="partyLinksLoading"
      />
    </div>
  </v-main>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import FilteringStatusChip from '../components/general/FilteringStatusChip.vue'
import OwnActive from '../components/Leasing/tabs/OwnActive.vue'
import GeneralSituation from '../components/Leasing/tabs/GeneralSituation.vue'
import Prospects from '../components/Leasing/tabs/Prospects.vue'
import RentingProcess from '../components/Leasing/tabs/RentingProcess.vue'
import RenegotiationsAndRenewals from '../components/Leasing/tabs/RenegotiationsAndRenewals.vue'
import RentableUnits from '../components/Leasing/tabs/RentableUnits.vue'
import Customers from '../components/Leasing/tabs/Customers.vue'
import ProspectModal from '../components/Leasing/ProspectModal.vue'
import CorporationModal from '../components/Leasing/CorporationModal.vue'
import LeasePlan from '../components/Leasing/tabs/LeasePlan.vue'
import MultiSelect from '../components/MultiSelect.vue'
import moment from 'moment'

export default {
  name: 'Leasing',
  components: {
    FilteringStatusChip,
    OwnActive,
    GeneralSituation,
    Prospects,
    RentingProcess,
    RenegotiationsAndRenewals,
    RentableUnits,
    Customers,
    ProspectModal,
    CorporationModal,
    LeasePlan,
    MultiSelect
  },
  metaInfo () {
    return {
      title: `${this.$t('leasing.renting')} ·`,
    }
  },
  data () {
    return {
      loading: false,
      responsibility: false,
      inspectionYear: moment().get('year'),
      inspectionQuarters: [],
      inspectionPeriod: null,
      thisYear: moment().year(),
      thisMonth: moment().month() + 1,
      quarters: [
        { text: 'Q1', value: 2 },
        { text: 'Q2', value: 5 },
        { text: 'Q3', value: 8 },
        { text: 'Q4', value: 11 },
      ],
      timePeriods: [
        { text: '- 12kk', value: -12 },
        { text: '- 9kk', value: -9 },
        { text: '- 6kk', value: -6 },
        { text: '- 3kk', value: -3 },
        { text: '+ 3kk', value: 3 },
        { text: '+ 6kk', value: 6 },
        { text: '+ 9kk', value: 9 },
        { text: '+ 12kk', value: 12 },
      ],
      partyLinks: [],
      partyLinksLoading: false,
      selectedResponsibles: [],
      tabLocation: null
    }
  },
  computed: {
    ...mapState('app', [
      'sites',
      'userInfo',
      'currentDate',
      'sitesLoading',
      'buildings',
    ]),
    ...mapGetters('app', [
      'hasApplicationPermissionByName',
      'siteFiltersByTypes',
    ]),
    ...mapState('leasing', [
      'prospects',
      'shownCorporation',
      'showCorporationModal',
      'shownProspect',
      'showProspectModal',
      'responsibleSites',
      'leasingUsers'
    ]),
    ...mapState('rentalProcess', ['rentalProcesses']),
    isMobile () {
      return visualViewport.width <= 760
    },
    siteIds () {
      return this.sites.map((site) => site.id_site)
    },
    userSites () {
      if (this.responsibility) {
        return this.sites.filter((site) =>
          this.responsibleSites.includes(site.id_site)
        )
      } else {
        return this.sites
      }
    },
    // Selectable years for quarter select
    years () {
      // Year choice is set to start from 2022
      const startYear = 2022
      const thisYear = moment().year()
      const totalYears = thisYear - startYear + 6
      // We show 5 years to both directions for now
      return new Array(totalYears)
        .fill(1)
        .map((item, index) => startYear + index)
    },
    // So four cases.
    // 1. Only year defined => return list with only one value which is the first day of the year
    // 2. Year and One or more quarters defined => return list with each value is the start date of selected quarter on selected year.
    // 3. Period selected => return list with only one value which is the starting point of the period.
    // 4. No time filter selected => return empty array.
    lowerBoundQuarterDates () {
      const time = new Date(this.currentDate)

      if (this.inspectionPeriod) {
        if (this.inspectionPeriod < 0) {
           time.setMonth(time.getMonth() + this.inspectionPeriod)
        }
       
        return [time]
      }

      if (this.inspectionQuarters && this.inspectionQuarters.length > 0) {
        const values = [...this.inspectionQuarters]
        return values
          .sort((a, b) => a - b)
          .map((quarter) => {
            const time = moment()
            time.utc(true)
            time.set('year', this.inspectionYear)
            time.set('month', quarter - 2)
            time.startOf('month')
            return new Date(time)
          })
      }

      // No time filter selected
      if (time.getFullYear() === this.inspectionYear) {
        return []
      }

      // First day of the year
      return [new Date(moment().utc(true).set('year', this.inspectionYear).startOf('year'))]
    },
    upperBoundQuarterDates () {
      const time = new Date(this.currentDate)

      if (this.inspectionPeriod) {
        if (this.inspectionPeriod < 0) {
          return [time]
        }

        time.setMonth(time.getMonth() + this.inspectionPeriod)
        return [time]
      }

      if (this.inspectionQuarters && this.inspectionQuarters.length > 0) {
        const values = [...this.inspectionQuarters]
        return values
          .sort((a, b) => a - b)
          .map((quarter) => {
            const time = moment()
            time.utc(true)
            time.set('year', this.inspectionYear)
            time.set('month', quarter)
            time.endOf('month')
            time.add(-999, "milliseconds")
            return new Date(time)
          })
      }

      // No time filter selected
      if (time.getFullYear() === this.inspectionYear) {
        return []
      }

      // Last day of the year
      return [new Date(moment().utc(true).set('year', this.inspectionYear).endOf('year').add(-999, "milliseconds"))]
    },

    rentalProcessResponsibles () {
      const rentalProcessOwners = this.rentalProcesses.map(process => process.owner)
      const rentalProcessSecondaryOwners = this.rentalProcesses.map(process => process.secondaryOwner)

      const responsibleIds = new Set(rentalProcessOwners.concat(rentalProcessSecondaryOwners))

      return this.leasingUsers.filter(user => responsibleIds.has(user.id_user))
    }
  },
  watch: {
    currentDate: async function (newValue) {
      this.resetSelection()
      const time = new Date(newValue)
      this.fetchAllGoals(time)
      this.getRentalStatuses(this.siteIds)
      this.getFutureRentalStatuses({ siteIds: this.siteIds })
      this.getPastRentalStatuses({ siteIds: this.siteIds })
      this.getQuarterlyUsage(newValue)
      this.getFutureUsages(newValue)
      this.getPartyLinks()
      this.fetchCarparkOccupancyRates()
    },
    tabLocation: function () {
      this.resetSelection()
    },
    buildings: async function () {
      this.getRentalStatuses(this.siteIds)
      this.getFutureRentalStatuses({ siteIds: this.siteIds })
      this.getPastRentalStatuses({ siteIds: this.siteIds })
      this.getFutureUsages(this.currentDate)
      this.getLeasingSummaries()
      this.fetchCarparkOccupancyRates()
    }
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('LEASING')) {
      this.$router.push({ name: 'dashboard' })
      return
    }
    this.updateTabLocation()

    // Load data that doesn't change with awaits so it's available for sure
    this.loading = true
    await this.getParties()
    await this.getMunicipalities()
    await this.getLeasingUsers()
    await this.getResponsibleSites()

    // We set initial view after loading unmutable data
    this.loading = false

    // Larger data that is subject to changes we load asynchronously
    this.getProspects()
    this.getProspectNotes()
    this.getCorporations()
    this.getClosedContracts()
    this.getQuarterlyUsage(this.currentDate)
    this.getAllProspectEvents()
    this.getIgnoredProspects()
    this.getFutureUsages(this.currentDate)
    this.fetchAllGoals(this.currentDate)
    
    if (this.hasApplicationPermissionByName('LEASING')) {
      this.getRentalProcess({})
    }
    

    // Load sites if needed
    if (this.sites.length === 0) {
      await this.getSites()
    }
    this.getRentalStatuses(this.siteIds)
    this.getFutureRentalStatuses({ siteIds: this.siteIds })
    this.getPastRentalStatuses({ siteIds: this.siteIds })
    this.getLeasingSummaries()
    this.getPartyLinks()
    this.fetchCarparkOccupancyRates()
  },
  methods: {
    ...mapActions('leasing', [
      'getProspects',
      'getUserNotifications',
      'getLeasingUsers',
      'getFutureRentalStatuses',
      'getMunicipalities',
      'getParties',
      'getUserNotifications',
      'getCorporations',
      'getResponsibleSites',
      'getClosedContracts',
      'getQuarterlyUsage',
      'getAllProspectEvents',
      'getIgnoredProspects',
      'getPastRentalStatuses',
      'getRentalStatuses',
      'getFutureUsages',
      'getLeasingSummaries',
      'getProspectNotes',
    ]),
    ...mapActions('app', ['getSites']),
    ...mapActions('rentingGoal', ['fetchAllGoals']),
    ...mapActions("carpark", ["fetchCarparkOccupancyRates"]),
    ...mapActions('rentalProcess', ['getRentalProcess']),
    resetPeriodSelection () {
      if(this.inspectionPeriod !== null){
        this.inspectionPeriod = null
      }
    },
    resetSelection () {
      this.inspectionYear = moment().get('year')
      this.inspectionQuarters = []
      this.resetPeriodSelection()
    },
    onInspectionYearChange (){
      this.resetPeriodSelection()
    },
    onInspectionQuartersChange () {
      if(this.inspectionYear === null){
        this.inspectionYear = moment().get('year')
      }

      this.resetPeriodSelection()
    },
    onInspectionPeriodChange () {
      if(this.inspectionYear !== null){
        this.inspectionYear = null
      }

      if(this.inspectionQuarters !== []){
        this.inspectionQuarters = []
      }
    },
    getPartyLinks () {
      this.partyLinksLoading = true
      const partyLinksPromise = this.$rambollfmapi.parties.assignments(1)
      const partiesPromise = this.$rambollfmapi.parties.list({ query: { party_type: 1, visibility_status: true, time: this.currentDate } })

      Promise.all([partyLinksPromise, partiesPromise])
        .then(res => {
          this.partyLinks = res[0].filter(partyLink => partyLink.linkType === 'site').map(partyLink => ({ ...partyLink, partyName: res[1].find(party => party.id === partyLink.id)?.name ?? '' }))
        })
        .catch(err => {
          this.$log.error(`API error: ${err}`)
          this.$store.dispatch('error/addError', 'err.report_api_failure')
        })
        .finally(() => {
          this.partyLinksLoading = false
        })
    },
    updateTabLocation () {
      // Initialize first tab to be open
      if (this.$router.history.current.name === 'renting') {
        this.$router.push({name: 'renting.own_active'})
      }
      this.tabLocation = this.$router.history.current.name
    }
  },
}
</script>

<style scoped>
.switch {
  margin: 5px 10px;
  padding: 10px;
  border: none;
  color: var(--c-color-accent);
}
.loading {
  text-align: center;
  padding-top: 2em;
}
.select {
  height: 38px !important;
  width: 175px !important;
}
.time-card {
  border: 1px solid var(--c-color-accent) !important;
  border-radius: 1.5em !important;
  width: 150px !important;
}
.box-title {
  position: absolute;
  color: var(--c-color-accent) !important;
  background: white !important;
  top: -15px;
  left: 10px;
}
.time-select-splitter {
  margin-left: 5px;
  margin-right: 5px;
  color: var(--c-color-accent);
  font-size: 14px;
}
</style>
