<template>
  <v-expansion-panel
    v-if="carpark"
  >
    <v-expansion-panel-header>
      <v-row
        align="center"
        justify="start"
      >
        <div
          v-if="carpark.rental_type === 'AreaRent'"
          style="text-align: right"
        >
          {{ $t('Carpark area') + ' ' + carpark.nro }}
        </div>
        <div
          v-else-if="carpark.rental_type === 'Privilege'"
          style="text-align: right"
        >
          <v-icon>rule_folder</v-icon>
          {{ $t('Parking privilege') }} {{ carpark.nro }}
        </div>
        <div
          v-else
          style="text-align: right"
        >
          <v-icon>directions_car</v-icon>
          {{ $t('Carpark') + ' ' + carpark.nro }}
        </div>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <CarparkBasicInfo
        :carpark="carpark"
        :tenant="carparkTenant"
        :building-code="buildingCode"
        :building-carparks-summary="buildingCarparksSummary"
        @openContract="openContract"
      />
      <CarparkContractInfo
        :carpark="carpark"
        :contracts="contractData"
      />
      <CarparkCommercialInfo
        :carpark="carpark"
      />
      <CarparkVacancyRateAssumptionInfo
        :carpark="carpark"
      />
      <CarparkAdditionalInfo
        :carpark="carpark"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

import CarparkBasicInfo from './CarparkBasicInfo.vue'
import CarparkContractInfo from './CarparkContractInfo.vue'
import CarparkCommercialInfo from './CarparkCommercialInfo.vue'
import CarparkAdditionalInfo from './CarparkAdditionalInfo.vue'
import CarparkVacancyRateAssumptionInfo from './CarparkVacancyRateAssumptionInfo.vue'

export default {
  emits: ['openContract'],
  name: 'CarparkDetails',
  components: {
    CarparkBasicInfo,
    CarparkContractInfo,
    CarparkCommercialInfo,
    CarparkAdditionalInfo,
    CarparkVacancyRateAssumptionInfo,
  },
  props: {
    carpark: { type: Object, default: null },
    carparkContracts: { type: Array, default: null },
    tenantInfo: { type: Array, default: null },
    buildingCode: { type: String, default: null },
    buildingCarparksSummary: { type: Object, default: null }
  },
  computed: {
    carparkTenant () {
      if (!this.tenantInfo || !this.carpark) {
        return null
      }
      return this.tenantInfo.find((tenant) => tenant.idCarpark === this.carpark.id_carspace)
    },
    contractData () {

      if (!this.carpark || !this.carparkContracts) {
        return []
      }

      const contractsByContractNumber = this.carparkContracts.reduce((acc, cur) => {

        const contract = acc[cur.contract_number]

        if (!contract) {
          acc[cur.contract_number] = {
            ...cur,
            curPartyName: cur.tenant,
            contractNumber: cur.contract_number,
            carparkLongCodes: [cur.carpark_code_long],
            carparkCodes: cur.carpark_code
          }
          return acc
        }

        contract.area += cur.area
        contract.carparkLongCodes.push(cur.carpark_code_long)
        contract.carparkCodes += `, ${cur.carpark_code}`

        return acc
      }, {})

      return Object.values(contractsByContractNumber)
        .filter(contract => contract.carparkLongCodes.includes(this.carpark.carpark_code_long))
    }
  },
  methods: {
    openContract (contract) {
      this.$emit('openContract', contract)
    },
  },
}
</script>
<style scoped>
</style>
