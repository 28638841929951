<template>
  <div>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :items="items"
      :server-items-length="totalCount"
      :options.sync="options"
      :headers="headers.map((h) => {
        return {
          text: $t(h.text),
          value: h.value,
          format: h.format
        }
      })"
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      :no-data-text="$t('No data available')"
      :loading="loading"
      :loading-text="$t('Gathering information. Wait a second...')"
      :no-results-text="$t('Gathering information. Wait a second...')"
      header-key="value"
      mobile-breakpoint="0"
      color="red"
    >
      <template
        #item="props"
      >
        <tr>
          <template v-for="header in headers">
            <td
              v-if="(header.type === 'dropdown' && !showDialog)"
              :key="header.value"
            >
              <v-select
                v-model="props.item[header.value]"
                clearable
                :items="header.choices()"
                :label="header.label"
                @change="selectChanged(header.value, props.item)"
              />
            </td>
            <td
              v-else-if="!!header.event"
              :key="header.value"
            >
              <button
                :style="getCustomStyles(props.item, header.value)"
                :class="{ 'linked': getLink(props.item, header.value) || !!header.event, 'col-align-right': isRightAligned(header.format) }"
                @click="$emit('triggerCustomEvents', { eventName: header.event, row: props.item })"
              >
                <span
                  v-if="header.value === 'contractNumber'"
                  class="d-sr-only"
                >
                  {{ $t('ViewTheContract') + ' ' }}
                </span>
                {{ formatData(props.item, header.value, header.format) }}
              </button>
            </td>
            <HideOverflowColumn
              v-else-if="header.hideOverflow"
              :key="'hideOverflow' + header.value"
              :header="header"
              :item="props.item"
            />
            <td
              v-else
              :key="header.value"
            >
              <!-- Data cell starts here -->
              <v-row
                justify="space-between"
              >
                <!-- Data inside cell -->
                <v-col
                  :style="getCustomStyles(props.item, header.value)"
                  :class="{ 'linked': getLink(props.item, header.value) || !!header.event, 'col-align-right': isRightAligned(header.format) }"
                >
                  <span
                    @click="goToLink(props.item, header.value)"
                  >
                    {{ formatData(props.item, header.value, header.format) }}
                  </span>
                </v-col>
                <!-- Possible icon after data -->
                <v-col
                  v-if="iconObj = fetchIcon(props.item, header.value)"
                >
                  <v-icon
                    v-if="iconObj = fetchIcon(props.item, header.value)"
                    :color="iconObj.color"
                    style="align-self: flex-end; margin-right: 0.5em"
                  >
                    {{ iconObj.icon }}
                  </v-icon>
                </v-col>
              </v-row>
            </td>
          </template>
        </tr>
      </template>
      <template
        v-if="footers"
        slot="body.append"
      >
        <tr>
          <td>
            {{ $t('Total') }}
          </td>
          <td
            v-for="header in headers.slice(1)"
            :key="header.value"
          >
            <v-row
              justify="space-between"
            >
              <!-- Data inside footer cell -->
              <v-col :class="{ 'col-align-right': isRightAligned(header.format) }">
                {{ getFooterValue(header.value, header.format) }}
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
      <template
        #[`footer.page-text`]="item"
      >
        <slot
          :get-all-filtered-data="getAllFilteredData"
          name="table-buttons"
        />
        <columns-chooser
          v-if="exportAvailable"
          v-model="columnChooserVisibleHeaders"
          :stored-view-parent="widgetId.substr(0, widgetId.indexOf('.'))"
          :stored-view-level="widgetId.substr(widgetId.indexOf('.') + 1)"
          :headers="dataHeaders"
          :show-save-selection="true"
        />
        <v-btn
          v-if="exportAvailable"
          small
          outlined
          rounded
          :aria-label="`${$t('Export to PDF')}: ${title}`"
          @click="exportAsPdf()"
        >
          {{ $t('Export to PDF') }}
        </v-btn>&nbsp;
        <v-btn
          v-if="exportAvailable"
          small
          outlined
          rounded
          :aria-label="`${$t('Export to spreadsheet')}: ${title}`"
          @click="exportAsCsv()"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>&nbsp;
        <v-btn
          v-if="!exportAvailable && !hide"
          outlined
          rounded
          small
          :aria-label="`${$t('Browse')}: ${title}`"
          @click="showTableDataDialog()"
        >
          {{ $t('Browse') }}
        </v-btn>
        {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
        {{ item.itemsLength.toLocaleString('fi-FI') }}
      </template>
      <!-- Slot for filtering the contents. Filter options can be added in the widget object (see widgets/leasing f.ex.) -->
      <template
        v-if="data.filter"
        #footer
      >
        <div
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-menu
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-card
                elevation="0"
                class="filterBox"
              >
                <v-btn
                  v-bind="attrs"
                  outlined
                  x-small
                  fab
                  rounded
                  class="filterButton"
                  v-on="on"
                >
                  <v-icon>
                    filter_list
                  </v-icon>
                  <span
                    v-if="footerFilter != null"
                    class="text-subtitle-2"
                  >
                    {{ $t(data.filter.options[footerFilter].text) }}
                  </span>
                </v-btn>
              </v-card>
            </template>
            <v-list>
              <v-list-item-group
                v-model="footerFilter"
                active-class="active"
              >
                <v-list-item
                  v-for="listItem in data.filter.options"
                  :key="Array.isArray(listItem.value) ? listItem.value.join() : listItem.value"
                  @click="chooseFilter(data.filter.property, listItem.value)"
                >
                  <v-list-item-title>
                    {{ $t(listItem.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <div
          v-else
          style="margin: 0px 0px -45px 15px"
        >
          <v-menu
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                outlined
                x-small
                fab
                rounded
                v-on="on"
              >
                <v-icon>
                  filter_list
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group
                v-model="footerFilter"
                active-class="active"
              >
                <v-list-item
                  v-for="listItem in data.filter.options"
                  :key="Array.isArray(listItem.value) ? listItem.value.join() : listItem.value"
                  @click="chooseFilter(data.filter.property, listItem.value)"
                >
                  <v-list-item-title>
                    {{ $t(listItem.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <span
            v-if="footerFilter != null"
            class="text-subtitle-1"
          >{{ $t(data.filter.options[footerFilter].text) }}</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import helpers from '../../helpers'
import humanize from '../../helpers/humanize.js'
import ColumnsChooser from '../general/ColumnsChooser.vue'
import { mapGetters, mapState } from 'vuex'
import HideOverflowColumn from './HideOverflowColumn.vue'

export default {
  components: {
    ColumnsChooser,
    HideOverflowColumn,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      required: true
    },
    searchCriteria: {
      type: Object,
      default: () => {
        return {
          siteIds: [],
          buildingIds: [],
          cemeteryIds: [],
          organizationIds: [],
        }
      }
    },
    pagination: {
      type: Object,
      default: function () {
        return {
          page: 1,
          itemsPerPage: 5
        }
      }
    },
    headers: {
      type: Array,
      default: function () {
        return []
      }
    },
    customStyles: {
      type: Object,
      default: function () {
        return {}
      }
    },
    customFormatters: {
      type: Object,
      default: function () {
        return {}
      }
    },
    icons: {
      type: Object,
      default: function () {
        return {}
      }
    },
    links: {
      type: Object,
      default: function () {
        return {}
      }
    },
    filters: {
      type: Object,
      default: function () {
        return {}
      }
    },
    exportAvailable: {
      type: Boolean,
      default: () => false
    },
    dataHeaders: {
      type: Array,
      default: function () {
        return []
      }
    },
    widgetId: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    tableFooters: {
      type: Array,
      default: function () {
        return []
      }
    },
    hide: {
      type: Boolean,
      default: () => false
    },
    triggerGetAllData: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  emits: ['triggerCustomEvents', 'updateColumns', 'getAllFilteredData', 'openTableDataDialog', 'passCount', 'changePproperty', 'filterChange'],
  data () {
    return {
      totalCount: 0,
      localCount: this.count,
      items: [],
      loading: true,
      abortController: undefined,
      options: {
        page: 1,
        itemsPerPage: 5,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortBy: [],
        sortDesc: []
      },
      footers: [],
      columnChooserVisibleHeaders: [],
      footerFilter: null
    }
  },
  computed : {
    ...mapGetters('app', ['dataTableHeaderDefinitions']),
    ...mapState('app', ['defaultFiltersLoaded']),
  },
  watch: {
    options: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (this.defaultFiltersLoaded) {
          const watchProps = ['page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort', 'sortBy', 'sortDesc']
          for (let i = 0; i < watchProps.length; i++) {
            const key = watchProps[i]
            if (oldValue[key] !== newValue[key]) {
              if (Array.isArray(newValue[key]) && Array.isArray(oldValue[key]) && newValue[key].length === 0 && oldValue[key].length === 0) {
                // watch incorrectly detects empty arrays as changed
                continue
              }
              // reload when pagination changes or browse button is pressed (use different pagination for browse view)
              return this.reload()
            }
          }
        }
      }
    },
    filters: {
      deep: true,
      handler: function () {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          if (this.options.page === 1) {
            this.reload()
          } else {
            // This triggers reload from the watch
            this.options.page = 1
          }
        }, 500); // delay
      }
    },
    searchCriteria: {
      handler: function () {
        if (this.defaultFiltersLoaded) {
          // reload when site filters have changed
          this.localCount = 0
          this.totalCount = 0
          this.reload()
        }
      }
    },
    defaultFiltersLoaded: {
      handler: function (bool) {
        if (bool){
          this.reload()
        }
      }
    },
    columnChooserVisibleHeaders: {
      deep: true,
      handler: function (newValues, oldValues) {
        if ( newValues !== oldValues && newValues.length) {
          this.$emit('updateColumns', newValues)
        }
      }
    },
    triggerGetAllData: function () {
      this.$emit('getAllFilteredData', this.getAllFilteredData())
    },
    count (newVal) {
      this.localCount = newVal;
    },
  },
  async mounted () {
    this.options = this.pagination
  },
  methods: {
    async reload () { /*reloadCountAndFooters, isFiltersChange*/
      this.loading = true

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal

      const queryBody = this.queryBodyForPagination()
      if (this.localCount > 0) {
        this.totalCount = this.localCount
      }

      // v-text-field "clearable" sets filter value to null. If is not deleted from
      // queryBody, API will use the filter in the search even if the value is empty (null)
      Object.keys(queryBody.filters).forEach(filter => {
        if (queryBody.filters[filter] == null) {
          delete queryBody.filters[filter]
        }
      })

      if (queryBody.time !== null) {
        const result = await this.$rambollfmapi.widgets.dataTable(queryBody, signal)

        // Update if the request has not been canceled
        if (result?.body?.length >= 0) {
          let items = result.body
          if (this.customFormatters) {
            const customValues = []
            this.headers.forEach(header => {
              if(header.type && header.type == "dropdown" && this.customFormatters.hasOwnProperty(header.value)) {
                customValues.push(header.value)
              }
            })
            if (customValues.length) {
              items = items.map(row => {
                customValues.forEach(value => {
                  row[value] = this.customFormatters[value](row, this)
                })
                return row
              })
            }
          }
          this.items = items
          this.totalCount = result.count
          this.footers = result.footers
        }
      }

      this.loading = false
    },
    getCustomStyles (row, header) {
      if (this.customStyles && this.customStyles.hasOwnProperty(header)) {
        return this.customStyles[header](row, this)
      }
      return null
    },
    formatData (row, header, format) {
      if (this.customFormatters && this.customFormatters.hasOwnProperty(header)) {
        return this.customFormatters[header](row, this)
      }
      return helpers.format.formatData(row[header], format)
    },
    fetchIcon (row, header) {
      if (this.icons && this.icons.hasOwnProperty(header)) {
        return this.icons[header](row)
      }
      return null
    },
    getLink (row, header) {
      if (this.links && this.links.hasOwnProperty(header)) {
        return this.links[header](row)
      }
      return null
    },
    goToLink (row, header) {
      const link = this.getLink(row, header)
      if (link) {
        this.$router.push(this.links[header](row))
      }
    },
    queryBodyForPagination () {
      const descending = this.options.sortDesc && this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false
      const sortBy = this.options.sortBy && this.options.sortBy.length > 0 ? this.options.sortBy[0] : null
      var formattedFilters = this.formatFilters()
      return {
        ...this.searchCriteria,
        source: this.source,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        fetch: this.options.itemsPerPage,
        sortBy: sortBy,
        descending: descending,
        filters: this.removeEmpty(formattedFilters),
        totalAmount: this.localCount > 0 ? this.localCount : this.totalCount, // new queryBody variable to store the already counted total item amount
        reCount: this.localCount > 0 || this.totalCount > 0 ? false : true // new queryBody variable to mark when to perform total item count again
      }
    },
    async itemsForExport () {
      const queryBody = this.queryBodyForPagination()
      queryBody.offset = 0
      queryBody.fetch = this.totalCount

      const result = await this.$rambollfmapi.widgets.dataTable(queryBody)
      Object.keys(result.body).forEach(rowKey => {
        Object.keys(result.body[rowKey]).forEach(key => {
          let value = result.body[rowKey][key]
          if (value !== undefined && value !== null && typeof value === "string") {
             result.body[rowKey][key] = value.trim()
          }
        })
      })
      return result.body.map(row => {
        const resultRow = []
        this.headers.forEach(header => {
          resultRow.push(this.formatData(row, header.value, header.format))
        })
        return resultRow
      })
    },
    async exportAsPdf () {
      this.loading = true
      const items = await this.itemsForExport()
      const doc = new JsPDF('landscape')
      doc.autoTable({
        head: [this.headers.map(h => this.$t(h.text))],
        body: items
      })
      doc.save('report.pdf')
      this.loading = false
    },
    async exportAsCsv () {
      this.loading = true
      const items = await this.itemsForExport()
      const csvHeaders = this.headers.map(h => this.$t(h.text))
      helpers.csv.export(csvHeaders, items)
      this.loading = false
    },
    getFooterValue (columnName, columnFormat){
      let footerObj = this.footers.filter(f => f[columnName])[0]
      if(footerObj){
        if(columnFormat === 'Number') {
          return humanize.thousand_separator(footerObj[columnName])
        }
        else {
          return helpers.format.formatData(footerObj[columnName], columnFormat)
        }
      }
      return ''
    },
    showTableDataDialog () {
      this.$emit('openTableDataDialog')
      this.$emit('passCount', this.totalCount)
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    formatFilters () {
      var formattedFilters = { ...this.filters }
      // Method used to handle datetime filters
      // Because LazyTable has been designed to perform dataqueries more dynamically
      // then other widgets, we need to perform potential filter values formations on the fly

      // First, pick the datetime related filters...
      var t = Object.fromEntries(Object.entries(formattedFilters).filter(
        ([key]) => key.toString().toLowerCase().includes('date'))
      )

      //..then should the value for date filters be at certain length and include the dot char...
      Object.keys(t).forEach(key => {
        if (formattedFilters[key].includes('.') && formattedFilters[key].length > 7) {
          //...split the input, add frontal zeroes to date and month parts if needed
          // and then rejoin and format the input to YYYY-MM-DD format which is the format
          // used in database and can be compared to
          var asArr = formattedFilters[key].split('.')
          var newArr = []

          newArr.push(asArr[2])
          newArr.push(asArr[1].padStart(2, '0'))
          newArr.push(asArr[0].padStart(2, '0'))

          var dateString = newArr.join('-')
          formattedFilters[key] = dateString
        } else if (
          formattedFilters[key].includes('.') ||
          formattedFilters[key].includes('/') ||
          formattedFilters[key].includes('-')
        ) {
          //handle partial date searching and transform DD.MM.YYYY to YYYY.MM.DD
          const dateRegex = /^(\d{1,2})?[./-]?(\d{1,2})?[./-]?(\d{4})?/g
          const matches = [ ...formattedFilters[key].matchAll(dateRegex) ][0]
          if (matches) {
              const dateParts = [
              ... matches[3] ? [ matches[3] ] : [],
              ... matches[2] ? [ matches[2].padStart(2, '0') ] : [],
              ... matches[1] ? [ matches[1].padStart(2, '0') ] : [],
            ]
            const dateString = dateParts.join('-')
            if (dateString?.length > 0) {
              formattedFilters[key] = dateString
            }
          }
        }
      })
      return formattedFilters
    },
    async getAllFilteredData () {
      const queryBody = this.queryBodyForPagination()
      Object.keys(queryBody.filters).forEach(filter => {
        if (queryBody.filters[filter] == null) {
          delete queryBody.filters[filter]
        }
      })
      queryBody.offset = 0
      queryBody.fetch = 1000000000

      const result = await this.$rambollfmapi.widgets.dataTable(queryBody)
      return result.body
    },
    removeEmpty (obj) {
      return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== ""));
    },
    selectChanged (property, item) {
      this.$emit("changePproperty", { property, item} )
    },
    chooseFilter (property, value) {
      this.$emit("filterChange", { property, value } )
    }
  }
}
</script>

<style scoped>
.icon_padding {
  padding-right: 0.5em;
}
</style>