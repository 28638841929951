<template>
  <MarketInfoModifyForm
    :units="units"
    :disabled="readonly"
    :in-popup="true"
    :unit-usages="marketingUnitUsages"
    :rental-attributes="rentalAttributes"
    :technical-attributes="technicalAttributes"
    :unit-condition="unitCondition"
    :move-readiness="moveReadiness"
    :water-meter="waterMeter"
    :electricity-meter="electricityMeter"
    @update="postMessageUpdate"
    @close="close"
  />
</template>
<script>
import { mapMutations } from 'vuex'
import MarketInfoModifyForm from '../components/FacilityManagement/MarketInfoModifyForm.vue'

export default {
  name: 'MarketInfoModifyPopup',
  components: {
    MarketInfoModifyForm: MarketInfoModifyForm
  },
  data: function () {
    return {
      marketingModifyDialog: false,
      units: [],
      readonly: true,
      marketingUnitUsages: [],
      rentalAttributes: [],
      technicalAttributes: [],
      unitCondition: [],
      moveReadiness: [],
      waterMeter: [],
      electricityMeter: [],
    }
  },
  computed: {},
  mounted () {
    this.setPopupOpen(true)
    window.addEventListener('message', this.receiveMessage)
    window.opener.postMessage("READY_MARKET", "*");
  },
  methods: {
    ...mapMutations('app', ['setPopupOpen']),
    receiveMessage (event) {
      if (event.data.units) {
        this.units =  event.data.units
        this.readonly = event.data.readonly
        this.marketingUnitUsages = event.data.marketingUnitUsages
        this.rentalAttributes = event.data.rentalAttributes
        this.technicalAttributes = event.data.technicalAttributes
        this.unitCondition = event.data.unitCondition
        this.moveReadiness = event.data.moveReadiness
        this.waterMeter = event.data.waterMeter
        this.electricityMeter = event.data.electricityMeter
      }
    },
    postMessageUpdate () {
      window.opener.postMessage("UPDATE", "*");
      window.close()
    },
    close () {
      this.marketingModifyDialog = false
      window.close()
    }
  }
}
</script>
<style scoped>
</style>


