import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { i18n } from '../plugins/i18n.js'

export default {
    async createPdf ({ logo, baseData, tenants, isExtraRent, responseData, blob = false }) {
        const doc = new JsPDF('portrait')
        doc.setFontSize(12)
        addHeader(doc, logo)
        addBaseData(doc, baseData)
        addTenants(doc, tenants, isExtraRent)
        addResults(doc, tenants, responseData)
        addFooter(doc)
        
        return blob ? doc.output('blob') : doc.save(`${i18n.t("calculator.pdf.title").toLowerCase()}-${new Date().toISOString()}.pdf`)
    }
}

function createResultsTable (doc, title, results) {
    const yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable({
        startY: yPosition + 10,
        body: [
            [title, '€/m2/kk', '€/kk', '€/vuosi'],
            ...results.map(r => ([r.rowHeaders, r.col1, r.col2, r.col3]))
        ],
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles: {
            0: { cellWidth: 50 },
        }
    })
}

function addResults (doc, tenants, results) {
    const yPosition = doc.lastAutoTable.finalY + 20
    doc.text(i18n.t("calculator.pdf.results"), 10, yPosition)
    tenants.forEach((tenant, i) => createResultsTable(doc, tenant.name, results[i]))
    createResultsTable(doc, i18n.t('calculator.pdf.total'), results[results.length-1])
}

function createTitleRow (isExtraRent) {
    if (isExtraRent) {
        return [i18n.t('calculator.pdf.tenant_name'), i18n.t('calculator.pdf.tenant_rented_area'), i18n.t('calculator.pdf.tenant_modifications_cost'), i18n.t('calculator.pdf.tenant_payment_period')]
    }
    return [i18n.t('calculator.pdf.tenant_name'), i18n.t('calculator.pdf.tenant_rented_area')]
}

function createTenantRow (tenant, isExtraRent) {
    if (isExtraRent) {
        return [tenant.name, Number(tenant.rentedArea).toFixed(2) + ' m²', Number(tenant.modificationsCost).toFixed(2) + ' €', tenant.paymentPeriod + ' v']
    }
    return [tenant.name, Number(tenant.rentedArea).toFixed(2) + ' m²']
}

function addTenants (doc, tenants, isExtraRent) {
    const yPosition = doc.lastAutoTable.finalY + 20
    doc.text(i18n.t("calculator.pdf.tenants"), 10, yPosition)
    doc.autoTable({
        startY: yPosition + 10,
        body: [
            createTitleRow(isExtraRent),
            ...tenants.map(t => createTenantRow(t, isExtraRent)),
        ],
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles: {
            0: { cellWidth: 50 },
        }
    })
}

function CalculationBasisIdToText (id) {
    const calculationBaseEnum = {
        "Perusparannus": 1,
        "Vuokraus ilman investointia": 2,
        "Uudisrakennus": 3,
        "Ulkoavuokrattu tila": 4,
        "Toiminnallinen muutostyö": 5,
    };

    const keys = Object.keys(calculationBaseEnum);
    for (let idx = 0; idx < keys.length; idx++) {
        const key = keys[idx];
        if (calculationBaseEnum[key] === id) {
            return key;
        }
    }
    return "N/A"
}

function addBaseData (doc, baseData) {
    doc.text(i18n.t("calculator.pdf.basedata"), 10, 40)
    doc.autoTable({
        startY: 50,
        body: [
            [i18n.t("calculator.pdf.site_name"), baseData.siteName],
            [i18n.t("calculator.pdf.building_name"), baseData.buildingName],
            [i18n.t("calculator.pdf.basis"), CalculationBasisIdToText(baseData.calculationBasis)], 
            [i18n.t("calculator.pdf.building_project"), baseData.buildingProject], 
            [i18n.t("calculator.pdf.total_funding_provision"), Number(baseData.totalFundingProvision).toFixed(2) + ' €'], 
            [i18n.t("calculator.pdf.total_area"), Number(baseData.totalArea).toFixed(2) + ' m²'], 
            [i18n.t("calculator.pdf.zone_price"), Number(baseData.zonePrice).toFixed(2) + ' €'], 
            [i18n.t("calculator.pdf.balance"), Number(baseData.balance).toFixed(2) + ' €'], 
        ],
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles: {
            0: { cellWidth: 50 },
        }
    })
}

function addHeader (doc, logo) {
    if (logo) {
        var imgData = 'data:image/png;base64,' + logo
        doc.addImage(imgData, 'PNG', 10, 10, 28, 12)
    }
    doc.text(`${i18n.t("calculator.pdf.title")}`, 80, 22)
}

function addFooter (doc) {
    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 1; i <= pageCount; i++) {
        doc.setFontSize(9)
        doc.setPage(i)
        const pageSize = doc.internal.pageSize
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        const footer = `${i18n.t("pdf.page")} ${i} / ${pageCount}`
        doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 10, { baseline: 'bottom' })
    }
}