<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t('Detailed Search') }}
    </template>
    <template #content>
      <v-col
        id="toolbar-title"
        class="toolbar justify-space-between"
        style="overflow-y: hidden; margin-bottom: 1em;"
      >
        <h3 class="grey-text">
          {{ $t('Chosen Buildings') }}
        </h3>
        <v-icon
          small
          color="primary"
          :style="{ marginRight: '1em' }"
          @click="openSelectBuildings"
        >
          launch
        </v-icon>
      </v-col>
      <v-card
        max-height="130"
        flat
        class="overflow-y-auto"
      >
        <BuildingButtons
          :building-filter="filtered"
          @filter="filterBuilding"
        />
      </v-card>
      <v-dialog
        v-model="buildingsSelect"
        persistent
        max-width="1200"
      >
        <v-container>
          <BaseModal
            @cancel="openSelectBuildings"
          >
            <template #title>
              {{ $t('Chosen Buildings') }}
            </template>
            <template #content>
              <v-card
                flat
                max-height="600"
                class="overflow-y-auto"
              >
                <BuildingButtons
                  :building-filter="buildingsSelected"
                  @filter="filterBuildingsSelected"
                />
              </v-card>
            </template>
            <template #footer>
              <v-btn
                text
                rounded
                outlined
                color="blue"
                @click="chooseAll"
              >
                {{ clearSelection ? $t('Remove all') : $t('Choose all') }}
              </v-btn>
              <v-btn
                rounded
                depressed
                color="primary"
                @click="selectBuildings"
              >
                {{ $t('Select') }}
              </v-btn>
            </template>
          </BaseModal>
        </v-container>
      </v-dialog>
      <br>
      <v-form
        id="searchForm"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row
            id="bottom-space"
          >
            <v-col cols="6">
              <v-row
                align="center"
              >
                <v-col cols="6">
                  <h4 class="grey-text">
                    {{ $t('Selected Year') }}
                  </h4>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="chosenYear"
                    class="select-style"
                    :items="years"
                    :rules="[v => !!v || 'Item is required']"
                    required
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row
                align="center"
              >
                <v-col cols="2">
                  <h4 class="grey-text">
                    {{ $t('Period') }}
                  </h4>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="chosenYears"
                    class="select-style"
                    :items="yearSelection"
                    :rules="[v => !!v || 'Item is required']"
                    required
                  />
                </v-col>
                <v-col cols="6">
                  <h4 class="grey-text">
                    {{ $t('years') }}
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            id="bottom-space"
          >
            <v-col cols="6">
              <v-row
                align="center"
              >
                <v-col cols="6">
                  <h4 class="grey-text">
                    {{ $t('Task budget') + " " + $t('from') }}
                  </h4>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="budgetStart"
                    class="select-style"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row
                align="center"
              >
                <v-col cols="2">
                  <h4 class="grey-text">
                    {{ $t('to') }}
                  </h4>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="budgetEnd"
                    class="select-style"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            id="bottom-space"
          >
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="3">
                  <h4 class="grey-text">
                    {{ $t('Category') }}
                  </h4>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="category"
                    :items="categories"

                    class="select-style"
                    item-text="name"
                    item-value="code"
                    clearable
                  >
                    <template #selection="{ item }">
                      <span v-text="item.name" />
                    </template>
                    <template #item="{ item }">
                      <span v-text="item.name" />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            id="bottom-space"
          >
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="3">
                  <h4 class="grey-text">
                    {{ $t('Task class') }}
                  </h4>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedClass"
                    class="select-style"
                    :items="classes"
                    :filter="classFilter"
                    item-text="name"
                    item-value="code"
                    clearable
                  >
                    <template #selection="{ item }">
                      <span v-text="item.code + ' - ' + item.name" />
                    </template>
                    <template #item="{ item }">
                      <span v-text="item.code + ' - ' + item.name" />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            id="bottom-space"
          >
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="3">
                  <h4 class="grey-text">
                    {{ $t('Code') }}
                  </h4>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="code"
                    class="select-style"
                    :items="codes"
                    item-text="name"
                    item-value="code"
                    clearable
                  >
                    <template #selection="{ item }">
                      <span v-text="item.code + ' - ' + item.name" />
                    </template>
                    <template #item="{ item }">
                      <span v-text="item.code + ' - ' + item.name" />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="3">
                  <h4 class="grey-text">
                    {{ $t('Status') }}
                  </h4>
                </v-col>
                <v-col
                  v-for="status in statuses"
                  :key="status.value"
                >
                  <v-checkbox
                    v-model="implementationStatuses"
                    :label="$t(status.name)"
                    :value="status.name"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template #footer>
      <v-btn
        text
        rounded
        depressed
        class="primary"
        @click="saveSearch"
      >
        {{ $t('Search') }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import helpers from '../../helpers'
import BuildingButtons from './BuildingButtons.vue'
import BaseModal from '../general/BaseModal.vue'

const defaultState = {
  allBuildings: false,
  budgetStart: undefined,
  budgetEnd: undefined,
  filtered: [],
  buildingsSelected: [],
  years: [],
  yearSelection: [1, 3, 5, 10],
  chosenYear: new Date().getFullYear(),
  chosenYears: 10,
  classes: [],
  codes: [],
  code: null,
  category: null,
  categories: [],
  selectedClass: null,
  valid: true,
  buildingsSelect: false,
  implementationStatuses: Object.values(
    helpers.ltpConstants.statusMappings()
  ).filter(s => s !== 'Acknowledge'),
  clearSelection: true
}

export default {
  emits: ['close'],
  name: 'DetailedSearch',
  components: { BuildingButtons, BaseModal },
  data () {
    return { ...defaultState }
  },
  computed: {
    ...mapState('ltp', [
      'buildingFilter',
      'budgetHigh',
      'budgetLow',
      'classCode',
      'ltpBuildings',
      'selectedCode',
      'selectedCategory',
      'selectedYear',
      'yearRange',
      'esgCategories',
      'esgClasses',
      'esgCodes',
      'capexCategories',
      'capexClasses',
      'capexCodes'
    ]),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('ltp', ['statuses']),
    permission () {
      return this.hasApplicationPermissionByName('PTS_ESG_TEHTAVAT')
    }
  },
  watch: {
    selectedClass: function () {
      this.codes = this.getCodes(this.selectedClass)
      this.code = null
    },
    chosenYear: function () {
      this.years = this.getYears()
    },
    code: function () {
      if (this.code && !this.selectedClass) {
        this.selectedClass = this.code.startsWith('ESG') ? 'ESG' : this.code.slice(0, 1)
      }
    },
    category: function () {
      this.classes = this.getClasses()
    }
  },
  async mounted () {
    this.years = this.getYears()
    this.classes = this.getClasses()
    this.codes = this.getCodes()
    this.categories = this.getCategories()
  },
  methods: {
    ...mapActions('ltp', [
      'setYearRange',
      'setSelectedYear',
      'setSelectedClass',
      'setSelectedCode',
      'setSelectedCategory',
      'setLowLimit',
      'setHighLimit',
      'setSearchStatus',
      'setBuildingsFilter',
      'setDetailedSearchActive'
    ]),
    ...mapMutations('ltp', ['setDetailedSearchActive', 'setStatusFilter']),
    close () {
      this.budgetStart = this.budgetLow || undefined
      this.budgetEnd = this.budgetHigh
      this.chosenYear = this.selectedYear || new Date().getFullYear()
      this.chosenYears = this.yearRange
      this.code = this.selectedCode ? this.getCode(this.selectedCode) : null
      this.category = this.selectedCategory
      this.selectedClass = this.classCode
      this.classes = this.getClasses()
      this.codes = this.getCodes(this.selectedClass)
      this.filtered = this.buildingFilter
      this.$emit('close')
    },
    async saveSearch () {
      this.setSelectedYear(this.chosenYear)
      this.setYearRange(this.chosenYears)
      this.setSelectedClass(this.selectedClass)
      this.setSelectedCode(this.code)
      this.setSelectedCategory(this.category)
      if (this.budgetStart !== undefined) {
        this.setLowLimit(this.budgetStart)
      } else {
        this.setLowLimit(0)
      }
      this.setHighLimit(this.budgetEnd)
      this.setBuildingsFilter(this.filtered)
      this.setStatusFilter(this.implementationStatuses)
      await this.setSearchStatus()
      this.setDetailedSearchActive(this.filterActive())
      this.close()
    },

    filterActive () {
      // Checking if user has changes filter parameters. Also checks if user sets
      // the parameters back to default.
      if (this.chosenYear !== defaultState.chosenYear) return true
      else if (this.chosenYears !== defaultState.chosenYears) return true
      else if (this.selectedClass) return true
      else if (this.code) return true
      else if (this.category) return true
      else if (this.budgetStart) return true
      else if (this.budgetEnd) return true
      else if (this.filtered.length !== 0) return true
      else if (
        this.implementationStatuses.length !==
        defaultState.implementationStatuses.length
      ) {
        return true
      }
      return false
    },

    classFilter (item, queryText, itemText) {
      const textOne = item.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1
    },

    getCodes (classLetter = undefined) {
      const codes = [...helpers.clone.cloneDeep(this.capexCodes), ...(this.permission ? helpers.clone.cloneDeep(this.esgCodes) : [])]
      if (!classLetter) {
        return codes
      }

      const filteredCodes = codes.filter(code => {
        const letter = classLetter === 'ESG' ? code.code.slice(0, 3).toUpperCase() : code.code.slice(0, 1).toUpperCase()
        if (classLetter === 'ESG') {
          if (letter === classLetter.toUpperCase()) {
            return true
          }
        } else {
          if (letter === classLetter.toUpperCase() && code.code.slice(0, 3).toUpperCase() !== 'ESG') {
            return true
          }
        }
        return false
      })
      return filteredCodes 
    },
    getCode (code) {
      const codes = [this.capexCodes, ...(this.permission ? this.esgCodes : [])]
      return codes.find(item => item.code === code)
    },
    getCategories () {
      return [...this.capexCategories, ...(this.permission ? this.esgCategories : [])]
    },
    getClasses () {
      if (this.permission && (this.category === null || this.category === undefined || this.category === 'Capex & ESG')) {
        return [...this.capexClasses, ...this.esgClasses]
      }
      if (this.permission && this.category === 'ESG') {
        return this.esgClasses
      }
      return this.capexClasses
    },
    getYears () {
      const selection = []
      for (let i = -10; i <= 10; i++) {
        selection.push(this.chosenYear + i)
      }

      return selection
    },
    filterBuilding (building) {
      if (this.filtered.includes(building)) {
        this.filtered = this.filtered.filter(
          buildingCode => buildingCode !== building
        )
        this.buildingsSelected = this.filtered
      } else {
        this.filtered = [...this.filtered, building]
        this.buildingsSelected = this.filtered
      }
    },
    openSelectBuildings () {
      this.buildingsSelected = this.filtered
      this.clearSelection = !(this.filtered.length > 0)
      this.buildingsSelect = !this.buildingsSelect
    },
    selectBuildings () {
      this.filtered = this.buildingsSelected
      this.buildingsSelect = false
    },
    filterBuildingsSelected (building) {
      if (this.buildingsSelected.includes(building)) {
        this.buildingsSelected = this.buildingsSelected.filter(
          buildingCode => buildingCode !== building
        )
      } else {
        this.buildingsSelected = [...this.buildingsSelected, building]
      }
    },
    chooseAll () {
      this.clearSelection = !this.clearSelection
      this.buildingsSelected = this.clearSelection
        ? []
        : this.ltpBuildings.map(s => s.building_code)
    }
  },
}
</script>

<style>
#bottom-space {
  margin-bottom: 1.5em;
}

.grey-text {
  color: #8b8b8b;
}

.toolbar {
  overflow-x: hidden;
}
#toolbar-title {
  background-color: white !important;
}

.select-style {
  border-color: #8b8b8b !important;
  margin-right: 15px !important;
}
</style>
