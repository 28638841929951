<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="750"
  >
    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ modifyDialog ? $t('site.diary.modify_entry') : $t('site.diary.add_entry') }}
      </template>
      <template #content>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  :value="formatDate(diaryItem.handling_date)"
                  :label="$t('site.diary.handling_date')"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="diaryItem.author"
                  :label="$t('site.diary.author')"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="true"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(diaryItem.notification_date)"
                      :label="$t('site.diary.notification_date')"
                      append-icon="calendar_today"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="diaryItem.notification_date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="diaryItem.notifier"
                  :label="$t('site.diary.notifier')"
                  counter
                  maxlength="200"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="diaryItem.diary_case"
                  :label="$t('site.diary.case')"
                  :rules="[required]"
                  auto-grow
                  rows="3"
                  counter
                  maxlength="4000"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="diaryItem.solution"
                  :label="$t('site.diary.solution')"
                  auto-grow
                  rows="3"
                  counter
                  maxlength="4000"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="selectedItem"
                  :label="$t('site.diary.state')"
                  :items="statusItems"
                  item-text="label"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="diaryItem.assignee"
                  :label="$t('site.diary.assignee')"
                  counter
                  maxlength="200"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template #footer>
        <v-btn
          :disabled="!valid || !isModified"
          depressed
          rounded
          color="primary"
          @click="saveDiary()"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
    <template #activator="{ on, attrs }">
      <v-btn
        small
        outlined
        rounded
        v-bind="attrs"
        v-on="on"
        @click="addNewEntry"
      >
        {{ $t('site.diary.add_entry') }}
      </v-btn>
    </template>
  </v-dialog>
</template>
<script>
import BaseModal from './general/BaseModal.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
    export default {
  emits: ['input', 'updateWidget'],
        components: { BaseModal },
        props:{ 
          value: Boolean,
          items: {
            type: Object,
            default: () => {}
          },
          siteId: {
            type: Number,
            default: null
          }
        },
        data () {
            return {
                user: this.$store.state.app.userInfo.name,
                currentDate: this.$store.state.app.currentDate,
                modifyDialog: true,
                isModified: false,
                saving: false,
                menu: false,
                valid: false,
                selectedItem: undefined,
                diaryItem: {},
                patchItem: [],
                originalItem: {}
            }
        },
        computed: {
          ...mapGetters('app', ['definitionsByGroupId', 'definitionsByGroupLabel']),
        
          show: {
            get () {
              return this.value
            },
            set (value) {
              this.$emit('input', value)
            }
          },
          statusItems () {
            let result = this.definitionsByGroupLabel('diary.status').sort((a,b) => {return a.id - b.id})

            if(!result)
              return []

            result = result.map((status) => {
              return {
                id: status.id,
                label: status.label
              }
            })
            return result 
          },
        },
        watch: {
          selectedItem () {
            if(this.selectedItem) {
                this.diaryItem.state = this.selectedItem
              }
          },
          diaryItem: {
            handler:function () {
              this.patchItem = this.generatePatch()
              this.isModified = this.patchItem.length != 0 ? true : false
            },
            deep:true
          }   
        },
        updated () {
          if (this.modifyDialog && this.show) {
              this.diaryItem = Object.assign({}, this.items)
              this.originalItem = Object.assign({}, this.items)
              
              if(this.statusItems) {
                this.selectedItem = this.getDefinitionId(this.statusItems ,this.diaryItem.state)
                this.originalItem.state = this.selectedItem
              }
          } else if (!this.modifyDialog && this.show) {
            if(this.statusItems) {
              this.selectedItem = this.statusItems[0].id
            }
          }
        },
        methods: {
            required (v) {
              return !!v || this.$t('Required')
            },
            addNewEntry () {
              this.diaryItem = {"author": this.user, "handling_date": this.getCurrentDate(), "version": 1}
              this.modifyDialog = false
            },
            cancel () {
              this.show = false
              this.modifyDialog = true
              this.diaryItem = {}
            },
            getDefinitionId (definitions, label) {
              for (const def of definitions) {
                if (def.label === label) {
                  return def;
                }
              }
              return null;
            },
            async saveDiary () {
              if (this.saving) {
                return
              }

              this.saving = true
              if (this.modifyDialog) {
                this.updateDiary()
                return
              }
              const diaryVersion = {
                "NotificationDate": this.diaryItem.notification_date,
                "Notifier": this.diaryItem.notifier,
                "HandlingDate": this.diaryItem.handling_date,
                "Author": this.diaryItem.author,
                "DiaryCase": this.diaryItem.diary_case,
                "Solution": this.diaryItem.solution,
                "DefState": this.diaryItem.state,
                "Assignee": this.diaryItem.assignee,
                "Ver": this.diaryItem.version
              }

              await this.$rambollfmapi.diaries.create(this.siteId, diaryVersion, this.getCurrentDate())
              this.$emit('updateWidget')
              this.saving = false
              this.cancel()
            },
            async updateDiary () {
              let patchObject = this.generatePatch()
              const foundObject = patchObject.find(obj => obj.path === "/state")
              if (foundObject) {
                patchObject.push({
                  "op": "replace",
                  "path": "/handling_date",
                  "value": this.getCurrentDate()
                })
              }
              await this.$rambollfmapi.diaries.update(this.diaryItem.id, patchObject, this.getCurrentDate())
              this.$emit('updateWidget')
              this.saving = false
              this.cancel()
            },
            generatePatch () {
              let result = this.$jsonpatch.compare(this.originalItem, this.diaryItem)
              return result
            },
            getCurrentDate () {
              return this.$store.state.app.currentDate
            },
            formatDate (date) {
              const formatted = moment(date, "YYYY-MM-DD")
              return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
            },
        }

    }

</script>
<style scoped>
form {
  padding-bottom: 30px;
}
</style>
