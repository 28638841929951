<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation 
  >
    <BaseModal 
      :is-loading="isLoading"
      @cancel="close"
    >
      <template #title>
        {{ $t('leasing.rental_won') }}
      </template>
      <template #content>
        {{ $t('leasing.confirmation_info') }}
        <v-row
          justify="space-between"
          class="mx-10"
          no-gutters
        >
          <div
            class="mt-2"
          >
            <v-text-field
              v-model="mainId.idValue"
              :rules="[rules]"
              :label="$t('leasing.main_contract_id')"
            />
          </div>
          <div
            v-for="(otherId, index) in otherIds"
            :key="index"
            class="mt-2"
          >
            <v-text-field
              v-model="otherId.idValue"
              :rules="[rules]"
              :label="$t('leasing.other_contract_id')"
            />
          </div>
        </v-row>
        <v-row no-gutters>
          <v-btn
            v-if="otherIds.length < 5"
            rounded
            depressed
            class="mx-auto form-fields blue-button"
            @click="addNewOtherIdField"
          >
            <v-icon small>
              add
            </v-icon>
            {{ $t('leasing.add_other_id') }}
          </v-btn>
        </v-row>
        <v-alert
          :value="errorMessage !== null"
          :type="warning ? 'warning' : 'error'"
          class="form-field"
        >
          {{ errorMessage }}
        </v-alert>
        <div
          class="mt-4"
        >
          <v-img
            v-show="infoImage !== null"
            :aria-label="$t('leasing.image_info')"
            :src="'data:image/jpeg;base64,' + infoImage"
            contain
          />
        </div>
      </template>
      <template #hideCancel>
        <div />
      </template>
      <template #footer>
        <v-row
          align="start"
          justify="start"
          no-gutters
        >
          <v-btn
            :disabled="saving"
            depressed
            rounded
            @click="close"
          >
            {{ $t('No') }}
          </v-btn>
        </v-row>
        <v-btn
          :disabled="!valid || saving"
          depressed
          rounded
          color="primary"
          @click="confirmVictory"
        >
          {{ $t('Yes') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseModal from '../general/BaseModal.vue'
import { checkRulesForMFilesIds } from '../../helpers/leasing/rentalprocess.js'

export default {
  emits: ['updateStage', 'close'],
  name: 'AddMFilesIdsDialog',
  components: {
    BaseModal,
  },
  props: {
    rentalProcessId: { type: Number, default: null }
  },
  data () {
    return {
      saving: false,
      valid: true,
      isLoading: true,
      otherIds: [{
        idValue: null,
      }],
      mainId: { 
        idValue: null,
      },
      infoImage: undefined,
      errorMessage: null,
      warning: false,
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel']),
    stages () {
      return this.definitionsByGroupLabel('leasing.rental_process_stage')
    },
  },
  watch: {
  },
  async mounted () {
    try {
      const res = await this.$rambollfmapi.images.get("infoImg")

      if(res.status === 200){
        this.infoImage = res.data
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }

    this.isLoading = false
  },
  methods: {
    async checkUsed (ids) {
      try {
          const res = await this.$rambollfmapi.leasing.rentalProcesses.mFileIds.checkUsed(ids)
          if (res?.length === 0) {
            return true
          }

          this.warning = true
          const usedIds = res.map(mf => mf.mfiles_id).join(", ")
          this.errorMessage = this.$t('The same ID is also assigned to another rental. IDs:') + usedIds + this.$t('leasing.continue')
        } catch(err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
        return false
    },
    rules (field) {
      this.warning = false
      this.errorMessage = null
      if (field) {
        return checkRulesForMFilesIds(field)
      }
      return true
    },
    async validateFields (ids) {
      const idsToCheck = ids.map(i => i.mfiles_id)
      if (idsToCheck.some(i => i.toString().length > 5 && i.toString().length < 8)) {
        return this.warning ? true : await this.checkUsed(idsToCheck)
      }
      this.errorMessage = this.$t('leasing.no_fields')
      return false
    },
    async confirmVictory () {
      this.saving = true

      const ids = []
      if (this.mainId.idValue != null) {
        ids.push({ is_main_contract: true, mfiles_id: parseInt(this.mainId.idValue), prospect_id: this.rentalProcessId})
      }
      this.otherIds.forEach(o => {
        if (o.idValue != null && !ids.map(i => i.mfiles_id).includes(parseInt(o.idValue))) {
          ids.push({ is_main_contract: false, mfiles_id: parseInt(o.idValue), prospect_id: this.rentalProcessId})
        }
      });

      if (await this.validateFields(ids)) {
        try {
          const res = await this.$rambollfmapi.leasing.rentalProcesses.mFileIds.post(ids)
          if (res?.message) {
            this.errorMessage = this.$t(res.message) + res.description
          } else {
            this.mainId = { idValue: null }
            this.otherIds = [ { idValue: null } ]
            this.$emit('updateStage', {...this.stages.find((stage) => stage.label === "6. Won"), openAddMFilesIds: false })
          }
        } catch(err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
      }
      this.saving = false
    },
    close () {
      this.mainId = { idValue: null }
      this.errorMessage = null
      this.warning = false
      this.otherIds = [ { idValue: null } ]
      this.$emit('close')
    },
    removeOtherId (index) {
      this.otherIds.splice(index, 1)
    },
    addNewOtherIdField () {
      if (this.otherIds.length < 5) {
        this.otherIds.push({
          idValue: null
        })
      }
    },
  },
}
</script>
<style scoped>
</style>
