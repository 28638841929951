import JsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import helpers from '../../../helpers'
import { i18n } from '../../../plugins/i18n.js'

export default {
  async createRentReviewPdf (rentReviewsByContract, reviewDate, logo, contractNumber = null) {
    const doc = new JsPDF('portrait')
    doc.setFontSize(11)

    var yPosition = 0
    rentReviewsByContract.forEach((contractReviews, index) => {
      yPosition = 10
      const {
        rentReviews,
        contract,
      } = contractReviews

      addHeaders(doc, contract, reviewDate, logo, yPosition)
      addRentReviews(doc, rentReviews, yPosition)
      addOverview(doc, rentReviews, contract.payments, contract.contractRows, yPosition)
      
      if (index < rentReviewsByContract.length - 1) {
        doc.addPage()
        doc.setPage(doc.internal.getNumberOfPages())
      }
    });
    
    addFooter(doc)
    if (contractNumber) {
      doc.save(`${i18n.t('rentReview.increaseNoticeFilename')}_${contractNumber}_${moment(reviewDate).format('YYYY-MM')}.pdf`)
    } else {
      doc.save(`${i18n.t('rentReview.increaseNoticeFilename')}_${moment(reviewDate).format('YYYY-MM')}.pdf`)
      return doc.output('blob')
    }
  }
}

function addFooter (doc) {
  const pageCount = doc.internal.getNumberOfPages()
  for (let i = 1; i <= pageCount; i++) {
    doc.setFontSize(9)
    doc.setPage(i)
    const pageSize = doc.internal.pageSize
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const footer = `${i18n.t("pdf.page")} ${i} / ${pageCount}`
    doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 10, { baseline: 'bottom' })
  }
}

function addHeaders (doc, contract, reviewDate, logo, yPosition) {
  doc.setFont(undefined, 'bold')
  let imgWidth = 0
  if (logo) {
    var imgData = 'data:image/png;base64,' + logo
    imgWidth = 28
    doc.addImage(imgData, 'PNG', 10, 10, imgWidth, 12)
  }

  doc.autoTable(getContactAutoTable(yPosition, getLandlordAndDateTable(doc, contract.contacts.landlord), imgWidth + 5))
  doc.autoTable(getContactAutoTable(doc.lastAutoTable.finalY + 5, getTenantAndContactNumberTable(contract.contacts.tenant, contract.contractNumber)))
  doc.autoTable(getContactAutoTable(doc.lastAutoTable.finalY + 5, getRentReviewAddressTable(contract.contacts)))
  doc.text(i18n.t("pdf.reviewHeading", {n: moment(reviewDate).format('DD.MM.YYYY')}), 10, doc.lastAutoTable.finalY + 7)
  doc.autoTable(getContactAutoTable(doc.lastAutoTable.finalY + 10, getAddressTable(contract.rentalAddress)))
  doc.setFont(undefined, 'normal')
}

function getContactAutoTable (startY, body, offset) {
  offset = offset || 0
  return {
    startY: startY,
    body: body,
    theme: 'plain',
    margin: { left: 10 + offset, right: 10 },
    styles: { cellPadding: 0.3, fontSize: 12 },
    columnStyles: {
      0: { cellWidth: 120, halign: 'left' },
      1: { halign: 'right' },
    },
  }
}

function addRentReviews (doc, reviews, yPosition) {
  if (!reviews || reviews.length === 0) return

  for (const review of reviews) {
    doc.setFont(undefined, 'bold')
    doc.text(review.paymentDomainName, 10, doc.lastAutoTable.finalY + 7)
    doc.setFont(undefined, 'normal')
    const calculation = (((review.usedIndex.costOfLivingIndex - review.basicIndex) / review.basicIndex) * review.basicRent).toFixed(4)
    const rows = [
      [i18n.t('pdf.reviewType'), review.rentReviewType],
      [i18n.t('pdf.rentReviewBasedOn'), review.rentReviewBasedOn],
      [i18n.t('rental_status.index_type'), i18n.t('pdf.index')],
      [i18n.t('create_rent_contract.base_index'), review.basicIndex],
      [i18n.t('rentReview.checkIndex'), review.usedIndex.costOfLivingIndex + ' (' + review.usedIndex.month + '/' + review.usedIndex.year + ')'],
      [null, null],
      [i18n.t('BasicRent'), humanizeCurrencySum(review.basicRent)],
      ... review.minimumIncrease ? [[i18n.t('create_rent_contract.minimum_increase'), review.minimumIncrease + '%']] : [],
      ... review.maximumIncrease ? [[i18n.t('create_rent_contract.maximum_increase'), review.maximumIncrease + '%']] : [],
      [null, null],
      [i18n.t('create_rent_contract.payment_review'), '(' + review.usedIndex.costOfLivingIndex + ' - ' + review.basicIndex + ') / ' + review.basicIndex + ' * ' + review.basicRent + ' = ' + calculation],
      [null, null],
      [i18n.t('rentReview.rentBeforeReview'), humanizeCurrencySum(review.netSum)],
      [i18n.t('rentReview.reviewAmount'), humanizeCurrencySum(review.reviewedNetSum - review.netSum)],
      [i18n.t('rentReview.rentAfterReview'), humanizeCurrencySum(review.reviewedNetSum)],
      [null, null],
      [i18n.t('VAT'), humanizeCurrencySum(review.reviewedTax)],
      [i18n.t('rentInTotal'), humanizeCurrencySum(review.reviewedGrossSum)],
    ]

    yPosition = doc.lastAutoTable.finalY + 10
    doc.autoTable({
      startY: yPosition,
      body: rows,
      theme: 'plain',
      margin: { left: 10, right: 10 },
      styles: { cellPadding: 0.3 },
      columnStyles: {
        0: { cellWidth: 60, halign: 'left' },
        1: { halign: 'right' },
      },
      pageBreak: 'avoid',
    })
  }
}

function addOverview (doc, rentReviews, payments, units, yPosition) {
  const table = []
  let taxless = 0
  let tax = 0
  let tot = 0

  if (rentReviews?.length > 0) {
    
    for (const review of rentReviews) {
      table.push(getOverviewTableRow(review, payments, units))
      taxless += review.reviewedNetSum
      tax += review.reviewedTax
      tot += review.reviewedGrossSum
    }
    table.push(getOverviewSummaryRow(humanizeCurrencySum(taxless), humanizeCurrencySum(tax), humanizeCurrencySum(tot)))
    yPosition = doc.lastAutoTable.finalY + 10
    doc.autoTable({
      startY: yPosition,
      head: getOverviewHeadRow(),
      body: table,
      theme: 'plain',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 40 },
        2: { cellWidth: 50 },
      },
      styles: {
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
        fontSize: 9
      },
      pageBreak: 'avoid',
    })
  }
}

function getOverviewHeadRow () {

  return [{ 
    payment: i18n.t("pdf.paymentType"),
    reviewType: i18n.t("pdf.reviewType"),
    space: i18n.t("leasing.brochure_units"),
    taxless: i18n.t("Exempt from taxation"),
    taxAmount: i18n.t("Vat amount"),
    total: i18n.t("Totals"),
  }]
}

function getOverviewTableRow (review, payments, units) {

  const payment = payments.contractPayments.find(payment => payment.id == review.rentPaymentId) || payments.separatePayments.find(payment => payment.id == review.rentPaymentId)
  const targetUnitCodes = units
    .filter(({ unitId, structureId }) => (payment?.targetUnits?.includes(Number(unitId)) || payment?.targetStructures?.includes(Number(structureId))))
    .map(u => u.unitName ? u.unitName : u.structureName)
    .join(', ')

  return [
    review.paymentDomainName,
    review.rentReviewType,
    payment?.wholeContractPayment ? i18n.t("create_rent_contract.single_payment_whole_contract") : targetUnitCodes,
    humanizeCurrencySum(review.reviewedNetSum),
    humanizeCurrencySum(review.reviewedTax),
    humanizeCurrencySum(review.reviewedGrossSum),
  ]
}

function getOverviewSummaryRow (taxless, tax, tot) {

  return [
    i18n.t("Totals"),
    "",
    "",
    taxless,
    tax,
    tot,
  ]
}

function humanizeCurrencySum (sum) {
  return sum !== undefined ? `${helpers.humanize.amount_long(Number(sum), 2).replace('.',',')} €` : ''
}

function getLandlordAndDateTable (doc, landlord) {
  const currentDate = moment().format('DD.MM.YYYY')
  return [
    [landlord?.name, `${i18n.t("pdf.rentReview")}`],
    [landlord?.address, currentDate],
    [`${landlord?.postal_code} ${landlord?.city}`],
  ]
}

function getTenantAndContactNumberTable (tenant, contractNumber) {
  return [[ tenant?.name , `${i18n.t("Contract number")}: ${contractNumber}`]]
}

function getRentReviewAddressTable (contacts) {
  return [
    [contacts?.rentIncreaseAddress],
    [`${contacts?.rentIncreasePostalCode} ${contacts?.rentIncreaseCity}`],
  ]
}

function getAddressTable (address) {
  return [
    [`${i18n.t("pdf.site")} ${address}`]
  ]
}
