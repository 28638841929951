<template>
  <!-- History dialog -->
  <v-dialog
    v-model="isShown"
    max-width="550"
    persistent
  >
    <BaseModal
      :is-loading="isLoading"
      @cancel="() => $emit('cancel')"
    >
      <template #title>
        {{ titleText }}
      </template>
      <template #content>
        <p
          v-for="event in sortedEvents"
          :key="event.id"
        >
          <b>{{ $t(event.event_type) }}:</b>
          {{ getHumanreadableDate(event.modified_date) }} {{ event.user }}
        </p>
      </template>
      <template #footer />
    </BaseModal>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex"
import humanize from '../../../helpers/humanize.js'
import BaseModal from "../../general/BaseModal.vue"

export default {
  name: "RentalProcessHistoryModal",
  components: { BaseModal },
  props: {
    rentalProcess: { type: Object, default: null },
  },
  emits: ["cancel"],
  data () {
    return {
      isLoading: false,
      isShown: false,
      events: [],
      // Highest is top most. If value is not defined, will default to 0
      eventOrder: {
        Luotu: 10,
        Päivitetty: 9,
        "Muutettu tilaa": 8,
        "Merkitty prospektiksi": 7,
        "Merkitty tarvekartoitukseen": 6,
        "Merkitty tarjousvaiheeseen": 5,
        "Merkitty sopimusneuvotteluihin": 4,
        "Merkitty voitetuksi": 3,
        "Merkitty ohi menneeksi": 2,
        "Kohdistettu sopimukseen": 1,
      },
    }
  },
  computed: {
    titleText () {
      if (this.rentalProcess) {
        return `${this.$t("prospect.history")}: ID ${
          this.rentalProcess.identifier
        }`
      }

      return this.$t("rentalProcess.identifier")
    },
    sortedEvents () {
      const eventsCopy = [...this.events]

      return eventsCopy.sort((a, b) => {
        let aOrder = this.eventOrder[a.event_type]

        if (!aOrder) {
          aOrder = 0
        }

        let bOrder = this.eventOrder[b.event_type]

        if (!bOrder) {
          bOrder = 0
        }

        return bOrder - aOrder
      })
    },
  },
  watch: {
    rentalProcess: async function (newValue) {
      if (newValue) {
        this.isShown = true
        this.isLoading = true

        this.events = await this.getProspectEvents({
          prospectId: newValue.id,
        })

        this.isLoading = false
      } else {
        this.isShown = false
      }
    },
  },
  methods: {
    ...mapActions("leasing", ["getProspectEvents"]),
    getHumanreadableDate (date) {
      return humanize.date(date, "time")
    },
  },
}
</script>

<style scoped>
.closed {
  border: 5px solid #4caf50;
}
</style>
