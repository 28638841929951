<template>
  <v-expansion-panels
    v-model="panels"
    class="my-2"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('create_rent_contract.additional_info_payments') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-textarea
          v-model="additionalInfo"
          maxlength="1000"
          counter="1000"
          @change="onAdditionalInfoUpdated"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  emits: ['onAdditionalInfoUpdated'],
  name: 'AdditionalInfoPayments',
  props: {
    contract: {
      type: Object,
      default: () => undefined
    },
  },
  data () {
    return {
      panels: [0],
      additionalInfo: this.contract?.additionalInfoPayments ?? undefined,
    }
  },
  methods: {
    onAdditionalInfoUpdated (value) {
      this.$emit("onAdditionalInfoUpdated", value)
    }
  }
}

</script>