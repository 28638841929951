<template>
  <ViewAndSelections
    :contract-level="contractLevel"
    parent-level="contract"
    :loading-as-prop="loading"
    @close-filter="showColumnsFilterDialog"
  >
    <template #buttons>
      <v-row
        class="toolbar dashboard overflow-x-auto ml-1 mr-1"
        :justify="isMobile ? 'start' : 'end'"
        no-gutters
      >
        <v-btn
          small
          outlined
          rounded
          :disabled="loading"
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
      </v-row>
    </template>
    <template
      #table="props"
    >
      <Table
        v-show="!loading"
        :rows="viewData"
        :headers="headers"
        :footers="debouncedFooters"
        :hide="true"
        :show-filter="showColumnsFilter"
        :document-type="exportAsDocument"
        :pagination.sync="pagination"
        :show-save-selection="true"
        :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
        :stored-views="props.props.storedViews.filter(sv => sv.level === contractLevel)"
        :show-confirm="props.props.confirmation"
        @exported="exporting = {}"
        @select_view="props.props.selectView"
        @save-selection="props.props.saveStoredView"
        @delete-view="props.props.deleteSelectedView"
      />
    </template>
  </ViewAndSelections>
</template>

<script>
import Table from './../Table.vue'
import ViewAndSelections from './ViewAndSelections.vue'
import { CorporationLinker } from '../../helpers/leasing/corporations.js'
import { getBooleanString } from '../../helpers/mappers/booleanString.js'
import { RentRollWidget } from '../../widgets/leasing/contracts/rentrollWidget.js'

const rentrollWidget = RentRollWidget()

export default {
  name: 'RentRollLevel',
  components: {
    Table,
    ViewAndSelections
  },
  props: {
    loading: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    corporations: { type: Array, default: () => []},
    contractLevel: { type: String, default: ''}
  },
  data () {
    return {
      showColumnsFilter: false,
      exportAsDocument: [],
      timer: null,
      headers: rentrollWidget.data.headers,
      footerDefinitions: rentrollWidget.data.footers,
      pagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['site_name'], 
      },
      exporting: {},
    }
  },
  computed: {
    corporationLinker () {
      return new CorporationLinker(this.corporations)
    },
    isMobile () {
      return visualViewport.width <= 760
    },
    viewData () {

      return this.data.map(item => {
        
        const corporation = this.corporationLinker.find({
          businessId: item.business_id, 
          contractNumber: item.contract_number,
          partyId: item.party_id
        })

        return {
          ...item,
          vat_status: getBooleanString(item.vat_responsibility),
          huom: getBooleanString (item.validity_notice),
          unitStatus: this.$t(item.unit_status),
          tenant_category: corporation?.tenant_category,
          credit_rating: corporation?.credit_rating,
          unit_code_time_alert: item.unit_code,
          unit_end_date_alert: item.unit_end_date
        }
      })
    }
  },
  methods: {
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    // Footers calculation function is debounced, so it doesn't get called on every keypress
    debouncedFooters (headers, data) {
      clearTimeout(this.timer)
      const footerSums = []
      const timeout = 500
      this.timer = setTimeout(() => {      
        const footerValues = this.footerDefinitions
        const calculatedFooterValues = {}
        calculatedFooterValues.site_name = this.$t('Total')
        footerValues.forEach(footer => {
          const value = footer.value
          let result = null
          switch (footer.type) {
            case 'sum': {
              result = data.map(r => isNaN(r[value]) ? 0 : Number(r[value]))
                .reduce((acc, cur) => acc + cur, 0).toFixed(2)
              break
            }
            case 'weightedAverage': {
              const weight = footer.weight
              const dataWeights = data.map(r => 
                ({
                  value: isNaN(r[value]) ? 0 : Number(r[value]),
                  weight: isNaN(r[weight]) ? 0 : Number(r[weight]) 
                })
              )
              const [sum, weightSum] = dataWeights.reduce((acc, curr) => {
                if (curr.value) {
                  acc[0] += curr.value * curr.weight
                  acc[1] += curr.weight
                }
                return acc
              }, [0, 0])
              result = weightSum ? (sum/weightSum).toFixed(2) : null
              break
            }
            case 'average': {
              const sum = data.map(r => isNaN(r[value]) ? 0 : Number(r[value]))
                .reduce((acc, cur) => acc + cur, 0).toFixed(2)
              const itemCount = data.filter(item => item[value] != null).length
              result = itemCount ? sum / itemCount : null 
              break
            }
            default: {
              return null
            }
          }
          if (result) {
            calculatedFooterValues[value] = result
          }
        })
        footerSums.push(calculatedFooterValues)
      }, timeout);
      return footerSums
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType
      
      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('rent_roll')
    },
  } 
}
</script>

<style>
</style>