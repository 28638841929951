<template>
  <div>
    <v-dialog
      v-model="showEditDialog"
      max-width="550"
      persistent
    >
      <v-card>
        <BaseModal
          @cancel="showEditDialog = false"
        >
          <template #title>
            {{ title }}
          </template>
          <template #content>
            <v-form ref="form">
              <v-container
                class="px-2"
              >
                <v-row
                  v-for="field in copyFields"
                  :key="field.id"
                >
                  <v-autocomplete
                    v-if="field.editType === 'Select'"
                    id="id"
                    v-model="field.value"
                    :items="field.items"
                    :label="$t(field.label)"
                    item-text="label"
                    item-value="id"
                    class="form-field"
                    clearable
                  />
                  <v-text-field 
                    v-if="field.editType === 'Text'"
                    v-model="field.value"
                    :label="$t(field.label)"
                    :maxlength="field.maxLength"
                    class="form-field"
                  />
                </v-row>
              </v-container>
            </v-form>
          </template>
          <template #footer>
            <v-btn
              :disabled="invalid"
              :loading="saving"
              type="submit"
              rounded
              depressed
              color="primary"
              @click="save"
            >
              {{ $t('Save') }}
            </v-btn>
          </template>
        </BaseModal>
      </v-card>
    </v-dialog>
    <div class="inline-block pb-1">
      <v-icon @click="showEditDialog = true">
        edit
      </v-icon>
      <span class="px-2 font-weight-bold">{{ title }}:</span>
      <span>{{ valuesAsString }}</span>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'
import lodash from 'lodash'

export default {
  emits: ['save'],
  name: 'EditField',
  components: {
    BaseModal,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    values: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      copyFields: [],
      showEditDialog: false,
      invalid: false,
      saving: false,
    }
  },
  computed: {
    valuesAsString () {
      if (!this.values) {
        return ''
      }
      return this.fields.map(field => {
        if (field.editType === 'Text') {
          return this.values[field.id]
        }
        if (field.editType === 'Select') {
          return field.items.find(item => item.id ===this.values[field.id])?.label
        }
        return ''
      }).filter(Boolean).join(', ')
    }
  },
  watch: {
    showEditDialog: {
      handler: function (value) {
        if (value) {
          this.open()
        }
      }
    }
  },
  mounted () {
    this.open()
  },
  methods: {
    async open () {
      this.copyFields = lodash.cloneDeep(this.fields)
    this.copyFields.forEach(field => field.value = this.values ? this.values[field.id] : null)
    },
    save () {
      this.saving = true
      this.$emit('save', this.copyFields)
      this.saving = false
      this.showEditDialog = false
    }
  }
}
</script>

<style scoped>
</style>