<template>
  <div
    v-if="units"
    id="marketinfomodifyform"
  >
    <BaseModal
      @cancel="emit"
    >
      <template #title>
        {{ $t('Edit marketing information') }}
      </template>
      <template #content>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div
            v-if="units.length > 0"
          >
            <v-card
              v-show="groupUpdate && units.length > 1"
              elevation="0"
            >
              <table
                class="v-datatable v-data-table v-table them--light table-overflow"
              >
                <thead>
                  <th class="column">
                    {{ $t('Group update') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    :style="field.style"
                    class="column"
                  >
                    {{ $t(field.header) }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <th class="column">
                      {{ $t('New values') }}
                    </th>
                    <GenericModifyField
                      v-for="field in filteredFields"
                      :key="field.id"
                      v-model="groupModifiedFields[field.id]"
                      :field-type="field.fieldType"
                      :rows="field.rows"
                      :field-clearable="field.clearable"
                      :items="field.items"
                      :value="groupModifiedFields[field.id]"
                    />
                  </tr>
                  <tr>
                    <th>{{ '' }}</th>
                    <td
                      v-for="field in filteredFields"
                      :key="field.value"
                      style="border: 0 !important;"
                    >
                      <v-btn
                        outlined
                        rounded
                        block
                        @click="setToAllFields(field)"
                      >
                        {{ $t('Set to all') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card>
            <v-card
              elevation="0"
            >
              <table class="v-datatable v-data-table v-table them--light table-overflow">
                <thead>
                  <th class="column">
                    {{ $t('Unit') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    :style="field.style"
                    class="column"
                  >
                    {{ $t(field.header) }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="unit in units"
                    :key="unit.id"
                  >
                    <th
                      class="column py-4"
                    >
                      {{ unit.unit_code }}
                    </th>
                    <GenericModifyField
                      v-for="field in filteredFields"
                      :key="unit.id + field.id"
                      v-model="modifiedUnits[unit.id][field.id]"
                      :field-type="field.fieldType"
                      :field-length="field.fieldLength"
                      :rows="field.rows"
                      :field-clearable="field.clearable"
                      :items="field.items"
                      :value="modifiedUnits[unit.id][field.id]"
                    />
                  </tr>
                </tbody>
              </table>
            </v-card>
          </div>
        </v-form>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <columns-chooser
          v-model="visibleColumns"
          :stored-view-parent="'facilitymanagement'"
          :stored-view-level="'marketing_info_modify_form'"
          :headers="editableFields"
          :show-save-selection="true"
          :small="false"
          header-value="value"
          header-text="header"
        />
        <v-btn
          v-if="units.length > 1"
          text
          outlined
          rounded
          @click="groupUpdate = !groupUpdate"
        >
          {{ $t('Group update') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          rounded
          outlined
          @click="emit"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          :disabled="!valid || isSaving"
          rounded
          depressed
          color="primary"
          @click="validate"
        >
          {{ $t('Save') }}
          <v-progress-circular
            v-if="isSaving"
            size="16"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import GenericModifyField from './GenericModifyField.vue'
import { mapGetters, mapState } from 'vuex'
import humanize from '../../helpers/humanize.js'
import moment from 'moment'
import BaseModal from '../general/BaseModal.vue'
import ColumnsChooser from '../../components/general/ColumnsChooser.vue'
export default {
  name: 'MarketInfoModifyForm',
  components: {
    GenericModifyField,
    ColumnsChooser,
    // eslint-disable-next-line vue/no-unused-components
    BaseModal,
  },
  props: {
    disabled: { type: Boolean, default: null },
    units: { type: Array, default: null },
    unitUsages: { type: Array, default: null },
    unitExtensibilities: { type: Array, default: null },
    unitDivisibilities: { type: Array, default: null },
    rentalAttributes: { type: Array, default: null },
    technicalAttributes: { type: Array, default: null },
    unitCondition: { type: Array, default: null },
    moveReadiness: { type: Array, default: null },
    waterMeter: { type: Array, default: null },
    electricityMeter: { type: Array, default: null },
    inPopup: Boolean,
  },
  emits: ['close', 'childToParent', 'update'],
  data () {
    return {
      groupUpdate: false,
      groupModifiedFields: {},
      modifiedUnits: {},
      valid: true,
      isSaving: false,
      childMessage: null,
      currentVisibility: false,
      rentingTabActive: false,
      visibleColumns: [],
    }
  },
  computed: {
    ...mapGetters('app', ['definitionById']),
    ...mapState('app', ['currentDate']),
    translatedRentStates () {
      return this.unitRentStates
        .filter(rent => rent.id !== 0)
        .map(rent => {
          return {
            label: this.getDefinitionLabel(rent.id),
            id: rent.id
          }
        })
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1
        })
    },
    fields () {

      return [
        {
          header: 'Usage types',
          fieldType: 'multi_select',
          id: 'usage_types',
          items: this.unitUsages,
          show: true,
          style: { 'min-width': '22em' }
        },
        {
          header: 'Unit extensibility',
          fieldType: 'dropdown',
          clearable: true,
          id: 'unit_extensibility',
          items: this.unitExtensibilities,
          show: true,
          style: { 'min-width': '22em' }
        },
        {
          header: 'Unit divisibility',
          fieldType: 'dropdown',
          clearable: true,
          id: 'unit_divisibility',
          items: this.unitDivisibilities,
          show: true,
          style: { 'min-width': '22em' }
        },
        {
          header: 'Unit detail info',
          fieldType: 'text_area',
          rows: 2,
          fieldLength: 4000,
          id: 'unit_detail_info',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Renting attributes',
          fieldType: 'multi_select',
          id: 'renting_attributes',
          items: this.rentalAttributes,
          show: true,
          style: { 'min-width': '28em' }
        },
        {
          header: 'Technical attributes',
          fieldType: 'multi_select',
          id: 'technical_attributes',
          items: this.technicalAttributes,
          show: true,
          style: { 'min-width': '22em' }
        },
        {
          header: 'Unit condition',
          fieldType: 'dropdown',
          clearable: true,
          id: 'unit_condition',
          items: this.unitCondition,
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Move readiness',
          fieldType: 'dropdown',
          clearable: true,
          id: 'move_readiness',
          items: this.moveReadiness,
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Advertising phrase',
          fieldType: 'custom_length_limited_text_field',
          id: 'advertising_phrase',
          fieldLength: 60,
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Introduction',
          fieldType: 'text_area',
          rows: 2,
          fieldLength: 2000,
          id: 'introduction',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Video link',
          fieldType: 'custom_length_limited_text_field',
          fieldLength: 120,
          id: 'video_link',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Panorama link',
          fieldType: 'custom_length_limited_text_field',
          fieldLength: 120,
          id: 'panorama_link',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Water meter',
          fieldType: 'dropdown',
          clearable: true,
          id: 'water_meter',
          items: this.waterMeter,
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Electricity meter',
          fieldType: 'dropdown',
          clearable: true,
          id: 'electricity_meter',
          items: this.electricityMeter,
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Other utilities',
          fieldType: 'custom_length_limited_text_field',
          fieldLength: 200,
          id: 'other_utilities',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Campaign active',
          fieldType: 'boolean',
          id: 'campaign_active',
          show: true,
          style: { 'min-width': '5em' }
        },
        {
          header: 'Campaign last date',
          fieldType: 'date',
          id: 'campaign_last_date',
          show: true,
          style: { 'min-width': '10em' }
        },
        {
          header: 'Campaign description',
          fieldType: 'limited_text_field',
          id: 'campaign_description',
          show: true,
          style: { 'min-width': '20em' }
        },
      ].map((field) => {
        field.value = field.id
        return field
      });
    },
    editableFields () {
      return this.fields.filter(field => field.show)
    },
    filteredFields () {
      return this.fields.filter(field => field.show && this.visibleColumns.includes(field.value))
    },
  },
  watch: {
    units: {
      deep: true,
      immediate: true,
      async handler (oldVal) {
        this.modifiedUnits = {}
        oldVal.forEach(element => {
          this.$set(this.modifiedUnits, element.id, {
            usage_types: element.usage_types,
            unit_extensibility: element.unit_extensibility,
            unit_divisibility: element.unit_divisibility,
            unit_detail_info: element.unit_detail_info,
            technical_attributes: element.technical_attributes,
            renting_attributes: element.renting_attributes,
            unit_condition: element.unit_condition,
            move_readiness: element.move_readiness,
            advertising_phrase: element.advertising_phrase,
            introduction: element.introduction,
            video_link: element.video_link,
            panorama_link: element.panorama_link,
            water_meter: element.water_meter,
            electricity_meter: element.electricity_meter,
            other_utilities: element.other_utilities,
            campaign_active: element.campaign_active,
            campaign_last_date: element.campaign_last_date,
            campaign_description: element.campaign_description,
            dismantle_date: moment(this.$store.state.app.currentDate).format(
              'YYYY-MM-DD'
            ),
            description: element.description
          })
        })
      }
    },
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
    renting: {
      handler () {
        this.rentingTabActive = this.renting
      }
    }
  },
  mounted () {
    this.setVisibilities()
  },
  methods: {
    emit () {
      this.$emit('childToParent', false)
      this.$emit('update')
      this.close()
      this.$refs.form.reset()
    },
    close () {
      this.currentVisibility = false
      this.groupModifiedFields = {}
      this.groupUpdate = false
      this.$emit('close')
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'date')
    },
    hasActiveLink (id) {
      return this.partyLinks.find(link => link.id_parent === id) !== undefined
    },
    getUnitLink (unit) {
      return this.partyLinks.find(link => link.id_parent === unit)
    },
    getDefinitionLabel (id) {
      const def = this.definitionById(id)
      if (def) {
        return this.$t(def.label)
      } else if (typeof id === 'string') {
        return this.$t(id)
      } else {
        return this.$t('Undefined')
      }
    },
    setToAllFields (field) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedUnits)) {
          this.modifiedUnits[key][field.id] = JSON.parse(
            JSON.stringify(this.groupModifiedFields[field.id])
          )
        }
      })
    },
    async validate () {
      if(this.checkIfAllCampaignFieldsAreFilled() === false) {
        alert(this.$t('Campaign must have all fields filled'))
        return
      }
      this.isSaving = true
      await this.patch()
      this.isSaving = false
      this.$emit('close')
    },
    async patch () {
      for (const unit in this.modifiedUnits) {
        const unitObject = this.units.find(e => {
          return e.id === parseInt(unit)
        })
        const time = this.currentDate.toISOString().slice(0, 10)
        let AdditionalInfoFields = ['usage_types', 'unit_extensibility', 'unit_divisibility', 'unit_detail_info', 'technical_attributes',
          'renting_attributes', 'unit_condition', 'move_readiness', 'water_meter', 'electricity_meter', 'other_utilities',
          'campaign_active', 'campaign_last_date', 'campaign_description']
        const modifiedUnitsCopy = JSON.parse(JSON.stringify(this.modifiedUnits))

        if(modifiedUnitsCopy[unit]['campaign_last_date'] === undefined) {
          modifiedUnitsCopy[unit]['campaign_last_date'] = null
        }

         //Array values in modified units have to be converted to strings so that jsonpatch is formed properly for those changes
        Object.keys(modifiedUnitsCopy[unit]).forEach(key =>{
          if(Array.isArray(modifiedUnitsCopy[unit][key])) {
            modifiedUnitsCopy[unit][key] = modifiedUnitsCopy[unit][key].join(", ")
          }
        })

        //Separate unit into those that are sent as unit patch...
        const unitCopyForUnit = Object.fromEntries(Object.entries(unitObject)
        .filter(entry => !AdditionalInfoFields.includes(entry[0])))
        //...and those that are sent as additional info patch
        const unitCopyForAdditionalInfo = Object.fromEntries(Object.entries(unitObject)
        .filter(entry => AdditionalInfoFields.includes(entry[0])))

       //Convert arrays in existing additional information to strings for jsonpatch
        Object.keys(unitCopyForAdditionalInfo).forEach(key =>{
          if(Array.isArray(unitCopyForAdditionalInfo[key])) {
            unitCopyForAdditionalInfo[key] = unitCopyForAdditionalInfo[key].join(", ")
          }
        })

        const unitObserverForUnit = this.$jsonpatch.observe(unitCopyForUnit)
        const unitObserverForAdditionalInfo = this.$jsonpatch.observe(unitCopyForAdditionalInfo)

        Object.keys(unitCopyForUnit).forEach(key => {
          if (modifiedUnitsCopy[unit][key] !== undefined) {
            unitCopyForUnit[key] = modifiedUnitsCopy[unit][key]
          }
        })

        Object.keys(unitCopyForAdditionalInfo).forEach(key => {
          if (modifiedUnitsCopy[unit][key] !== undefined) {
            unitCopyForAdditionalInfo[key] = modifiedUnitsCopy[unit][key]
          }
        })

        const unitPatch = this.$jsonpatch.generate(unitObserverForUnit)
        const additionalInfoPatch = this.$jsonpatch.generate(unitObserverForAdditionalInfo)

        if (unitPatch.length) {
          await this.$rambollfmapi.units.patch(unit, time, unitPatch)
        }
        if (additionalInfoPatch.length) {
          await this.$rambollfmapi.units.additionalInformation().patch(unit, time, additionalInfoPatch)
        }
    }
      this.$emit('update')
    },
    checkIfAllCampaignFieldsAreFilled () {
      for (const unit in this.modifiedUnits) {
        if(this.modifiedUnits[unit]['campaign_active']) {
          if(this.modifiedUnits[unit]['campaign_last_date'] === null ||
          this.modifiedUnits[unit]['campaign_last_date'] === undefined ||
          this.modifiedUnits[unit]['campaign_description'] === null ||
          this.modifiedUnits[unit]['campaign_description'] === "") {
          return false
          }
        }
      }
      return true
    },
    setVisibilities () {
      this.visibleColumns = []
      this.fields.forEach(field => {
        this.visibleColumns.push(field.value)
      })
    },
  }
}
</script>
<style scoped>
.column {
  vertical-align: middle;
}
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.v-datatable tbody tr td v-text-field {
  width: 100px;
}
.v-datatable tbody tr td {
  vertical-align: 0%;
}
</style>
