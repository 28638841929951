<template>
  <div>
    <v-dialog
      v-model="permitDialog"
      max-width="550"
    >
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          {{ $t('Building permit') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="currentPermit.permitNumber"
            :placeholder="$t('Permit number')"
          />
          <v-text-field
            v-model="currentPermit.title"
            :placeholder="$t('Title')"
          />
          <v-textarea
            v-model="currentPermit.description"
            :placeholder="$t('Description')"
          />
          <Search
            :endpoint="$store.state.app.apiEndpoint + 'documents/'"
            :placeholder="$t('Document')"
            :clear-after-select="false"
            no-title
            @select="onDocumentSelect"
          >
            <template
              v-slot:row="{result}"
            >
              <v-icon>description</v-icon>&nbsp;
              <span v-if="result.title">{{ result.title }}</span>
              <span v-if="result.description">, {{ result.description }}</span>
            </template>
          </Search>
          <v-text-field
            v-model="currentPermit.finalInspection"
            :label="$t('Final inspection')"
            type="date"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            rounded
            text
            @click="cancel"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            v-if="currentPermit.idBuildingPermit !== undefined"
            rounded
            depressed
            color="warning"
            @click="remove(currentPermit.idBuildingPermit)"
          >
            {{ $t('Delete') }}
          </v-btn>
          <v-btn
            v-if="currentPermit.idBuildingPermit !== undefined"
            rounded
            depressed
            color="primary"
            @click="update"
          >
            {{ $t('Update') }}
          </v-btn>
          <v-btn
            v-else
            rounded
            depressed
            color="primary"
            @click="save"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :headers="headers"
      :items="buildingPermits"
      class="elevation-0"
    >
      <template
        v-slot:item="props"
      >
        <tr>
          <td
            v-for="header in headers"
            :key="header.value"
            @click="showPermitDialog('edit', props.item)"
            v-html="Sanitize(getValueWithUnit(props.item[header.value], header.format))"
          />
        </tr>
      </template>
      <template slot="actions-prepend">
        <v-btn
          small
          outlined
          :disabled="disabled"
          @click="showPermitDialog('new')"
        >
          {{ $t('New permit') }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import helpers from '../helpers'
import Search from '../components/Search.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Search
  },
  props: ['buildingCode', 'disabled'],
  data () {
    return {
      headers: [
        { text: this.$t('Permit number'), value: 'permitNumber', format: '' },
        { text: this.$t('Title'), value: 'title', format: '' },
        { text: this.$t('Description'), value: 'description', format: '' },
        { text: this.$t('Final inspection'), value: 'finalInspection', format: 'Date' }
      ],
      buildingPermits: [],
      permitDialog: false,
      currentPermit: {
        idDocument: null,
        permitNumber: '',
        title: '',
        description: '',
        finalInspection: new Date()
      },
      documents: [],
      menu: false
    }
  },
  computed: {
  ...mapGetters('app', ['dataTableHeaderDefinitions']),
  },
  watch: {
    buildingCode: function () {
      this.updateList()
    }
  },
  mounted () {
    this.updateList()
  },
  methods: {
    onDocumentSelect (response) {
      this.currentPermit.idDocument = response.results.idDocument
    },
    getValueWithUnit (value, format) {
      return helpers.format.formatData(value, format)
    },
    async remove (id) {
      await this.$rambollfmapi.buildings.buildingpermits(this.buildingCode).delete(id)
      this.updateList()
      this.permitDialog = false
    },
    async update () {
      await this.$rambollfmapi.buildings.buildingpermits(this.buildingCode).update(this.currentPermit.idBuildingPermit, this.currentPermit)
      this.updateList()
      this.permitDialog = false
    },
    async save () {
      await this.$rambollfmapi.buildings.buildingpermits(this.buildingCode).post(this.currentPermit)
      this.updateList()
      this.permitDialog = false
    },
    cancel () {
      this.permitDialog = false
    },
    showPermitDialog (mode, permit) {
      this.$log.info('Showing permit', permit)
      if (typeof permit !== 'undefined' && permit !== null) {
        this.currentPermit = {
          idBuilding: permit.idBuilding,
          idBuildingPermit: permit.idBuildingPermit,
          idDocument: permit.idDocument,
          permitNumber: permit.permitNumber,
          title: permit.title,
          description: permit.description,
          finalInspection: new Date(permit.finalInspection).toISOString().substr(0, 10)
        }
      } else {
        this.currentPermit = {
          idDocument: null,
          permitNumber: '',
          title: '',
          description: '',
          finalInspection: (new Date()).toISOString().substr(0, 10)
        }
      }
      this.permitDialog = true
    },
    async updateList () {
      this.buildingPermits = await this.$rambollfmapi.buildings.buildingpermits(this.buildingCode).list()
    },
    Sanitize (text) {
      return helpers.format.sanitize(text)
    }
  }
}
</script>
<style>
</style>
