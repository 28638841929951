<template>
  <v-menu
    v-if="sets ? sets.length > 1 : false"
  >
    <template #activator="{ on }">
      <v-btn
        outlined
        small
        right
        icon
        style="background: rgba(255, 255, 255, 0.8) !important"
        v-on="on"
      >
        <span v-if="setButtonText && setButtonText.length > 0">
          {{ setButtonText }}
        </span>
        <v-icon v-else>
          menu
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="set in sets"
        :key="set.title"
        :class="{ active: currentSet === set.title }"
        @click="$emit('onSetChange', set)"
      >
        <v-list-item-title>{{ set.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  emits: ['onSetChange'],
  components: {},
  props: {
    sets: { type: Array, default: null },
    currentSet: { type: String, default: null},
    setButtonText: { type: String, default: null}
  }
}
</script>

<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
</style>
