<template>
  <div>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <filtered-lazy-table
          v-if="showTableDataDialog"
          :widget-id="widgetId"
          :title="title"
          :source="data.source"
          :search-criteria="searchCriteria"
          :headers="data.headers"
          :custom-styles="data.customStyles"
          :custom-formatters="data.customFormatters"
          :custom-filter-content="data.customFilterContent"
          :links="data.links"
          :icons="data.icons"
          :count="count"
          :table-footers="tableFooters"
          :show-dialog="showTableDataDialog"
          mobile-breakpoint="0"
          @close="showTableDataDialog = false"
          @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
        />
      </v-card>
    </v-dialog>
    <lazy-table
      v-if="!isFullTable"
      :source="data.source"
      :search-criteria="searchCriteria"
      :pagination.sync="pagination"
      :headers="data.headers"
      :custom-styles="data.customStyles"
      :custom-formatters="data.customFormatters"
      :custom-pagination="customPagination"
      :links="data.links"
      :icons="data.icons"
      :count="count"
      :data="data"
      :filters="filters"
      mobile-breakpoint="0"
      @openTableDataDialog="showTableDataDialog = true"
      @passCount="passCount"
      @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
      @changePproperty="$emit('changePproperty', $event)"
      @filterChange="handleFilterChange"
    />
    <filtered-lazy-table
      v-else
      :widget-id="widgetId"
      :title="title"
      :source="data.source"
      :search-criteria="searchCriteria"
      :headers="data.headers"
      :custom-styles="data.customStyles"
      :custom-formatters="data.customFormatters"
      :links="data.links"
      :icons="data.icons"
      :count="count"
      :table-footers="tableFooters"
      :hide="true"
      :items-per-page="50"
      :trigger-get-all-data="triggerGetAllData"
      :custom-pagination="customPagination"
      :custom-filter-content="data.customFilterContent"
      mobile-breakpoint="0"
      @close="showTableDataDialog = false"
      @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
      @getAllFilteredData="$emit('getAllFilteredData', $event)"
    >
      <template #table-buttons="props">
        <slot
          :get-all-filtered-data="props.getAllFilteredData" 
          name="table-buttons"
        />
      </template>
    </filtered-lazy-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LazyTable from '../general/LazyTable.vue'
import FilteredLazyTable from '../general/FilteredLazyTable.vue'
export default {
  emits: ['triggerCustomEvents', 'changePproperty', 'getAllFilteredData'],
  components: {
    LazyTable,
    FilteredLazyTable
  },
  props: {
    widgetId: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    headers: {
      type: Array,
      default: function () {
        return []
      }
    },
    isFullTable: {
      type: Boolean,
      default: false
    },
    date: {
      type: Date,
      default: undefined
    },
    itemsPerPage: {
      type: Number,
      default: 100
    },
    customPagination: {
      type: Object,
      default: function () {
        return undefined
      }
    },
    triggerGetAllData: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      pagination: {
        page: 1,
        itemsPerPage: this.customPagination?.itemsPerPage ?? 5 ,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortBy: this.customPagination?.sortBy ?? [],
        sortDesc: this.customPagination?.sortDesc ?? []
      },
      showTableDataDialog: false,
      selectedView: null,
      storedView: [],
      searchCriteria: {
        siteIds: [],
        buildingIds: [],
        cemeteryIds: [],
        time: null
      },
      count: 0,
      tableFooters: [],
      externalOrganizations: [],
      filters: {},
    }
  },
  computed: {
    ...mapState('app', [
      'siteIds',
      'sites',
      'buildingIds',
      'buildings',
      'cemeteries',
      'currentDate',
      'buildingRecursionLoadingState',
      'siteRecursionLoadingState'
    ]),
    ...mapState('sites', [
      'currentSiteId'
    ]),
    availableStoredViews () {
      return this.storedViews.filter(sv => sv.level === this.data.widgetLevel).map(sv => { return sv.name })
    }
  },
  watch: {
    selectedView: function (view) {
      this.storedView = view ? this.storedViews.filter(sv => sv.level === this.contractLevel && sv.id === view.id)[0] : null
    },
    showAlert: function (boolean) {
      if (boolean) {
        setTimeout(() => {
          this.showAlert = false
        }, 4000)
      }
    },
    sites: {
      handler: function (sites) {
        if (sites.length && (this.buildings.length || this.cemeteries.length)) {
          this.getSearchCriteria()
        }
      },
      deep: true
    },
    siteIds: {
      handler: function (siteIds) {
        if (siteIds.sites.length) {
          if (!this.siteRecursionLoadingState) {
            this.getSearchCriteria()
          }
        }
      },
      deep: true
    },
    buildings: function (buildings){
      if (this.sites.length && (buildings.length || this.cemeteries.length)) {
        this.getSearchCriteria()
      }
    },
    buildingIds: {
      handler: function (buildingIds) {
        if (buildingIds.buildings.length){
          if (!this.buildingRecursionLoadingState) {
            this.getSearchCriteria()
          }
        }
      },
      deep: true
    },
    cemeteries: function (cemeteries) {
      if (this.sites.length && (this.buildings.length || cemeteries.length)) {
        this.getSearchCriteria()
      }
    },
    currentDate: function (newDate) {
      this.getSearchCriteria()
    },
    date: function () {
      this.getSearchCriteria()
    },
    triggerGetAllData: function () {
      this.getSearchCriteria()
    }
  },
  async mounted () {
    await this.$rambollfmapi.userOrganizations.getExternal()
      .then(res => {
        this.externalOrganizations = res
    })
    if (this.sites.length && (this.buildings.length || this.cemeteries.length)) {
      this.getSearchCriteria()
    }
    else if (this.siteIds.sites.length) {
      this.getSearchCriteria()
    }
  },
  methods: {
    selectView (view) {
      if (view) {
        this.selectedView = this.availableStoredViews.find(v => v === view.name)
      } else {
        this.selectedView = null
      }
    },
    getSearchCriteria () {
      if (this.$route.name.startsWith('sites')) {
        this.searchCriteria = {
          siteIds: [this.currentSiteId],
          buildingIds: this.buildings
            .filter(b => b.id_site === this.currentSiteId)
            .map(x => x.id_building),
          cemeteryIds: this.cemeteries
            .filter(c => c.idSite === this.currentSiteId)
            .map(x => x.id),
          time: this.currentDate
        }
      } else if (this.$route.name.startsWith('billing')) {
        this.searchCriteria = {
          siteIds: this.sites.length ? 
              this.sites.map(x => x.id_site) : 
              this.siteIds.sites, 
          buildingIds: this.buildings.length ? 
              this.buildings.filter(b => this.sites.find(s => s.id_site === b.id_site)).map(x => x.id_building) : 
              this.buildingIds.buildings, 
          cemeteryIds: this.cemeteries.map(x => x.id),
          time: this.date ?? new Date()
        }
      } else {
        this.searchCriteria = {
          siteIds: this.sites.length ? 
              this.sites.map(x => x.id_site) : 
              this.siteIds.sites, 
          buildingIds: this.buildings.length ? 
              this.buildings.filter(b => this.sites.find(s => s.id_site === b.id_site)).map(x => x.id_building) : 
              this.buildingIds.buildings, 
          cemeteryIds: this.cemeteries.map(x => x.id),
          time: this.currentDate
        }
      }
    },
    passCount (count) {
      this.count = count
    },
    handleFilterChange (event) {
      this.filters = {}
      if (event.value != null) {
        this.filters[event.property] = event.value
      }
    }
  }
}
</script>

<style scoped>
</style>