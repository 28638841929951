<template> 
  <div>
    <Alert
      :show="showAlert"
      :result="alertResult"
      :message="alertMessage"
      :timeout="4000"
    />
    <v-dialog
      v-if="showNewRepairModal"
      v-model="showNewRepairModal"
      persistent
      width="90%"
    >
      <v-card>
        <NewRepairModal
          :repair-to-edit="repairToEdit"
          :building="selectedBuilding"
          @close="closeNewRepairModal"
        />
      </v-card>
    </v-dialog>
    <div
      id="technicalValues"
    >
      <h1 class="d-sr-only">
        {{ $t('technicalValues') }}
      </h1>

      <BaseView>
        <template #selections>
          <v-select
            v-model="selectedBuilding"
            :items="buildingsForCurrentSite"
            :item-text="(b) => b.building_code + ' ' + b.building_name"
            return-object
            :label="$t('Building')"
          />
        </template>
        <template #main>    
          <v-row>
            <v-col
              lg="4"
              md="5"
              cols="12"
            >
              <v-expansion-panels
                :value="[ 0 ]"
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t("technicalValues.current") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row v-if="isLoadingTechnicalValues || isLoadingSiteData">
                      <v-spacer />
                      <v-col class="shrink">
                        <v-progress-circular
                          size="64"
                          indeterminate
                          color="primary"
                          style="margin: 3em"
                        />
                      </v-col>
                      <v-spacer />
                    </v-row>
                    <div v-else>
                      <MetadataForm
                        :metadata="technicalValueFormMetadata"
                        v-model="currentTechnicalValues"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col
              lg="8"
              md="7"
              cols="12"
              align-self="start"
            >
              <CdsAlert
                v-if="!canCalculateTechnicalValue"
                class="alert"
                size="large"
                type="danger"
                :description="`${$t('alertDescription.buildingYearMissing')}`"
                :title="`${$t('alertTitle.buildingYearMissing')}`"
              />
              <div
                v-for="widget in widgets"
                :key="widget.id"
                class="widget"
              >
                <DynamicWidget
                  v-if="canCalculateTechnicalValue"
                  :id="widget.id"
                  :type="widget.type"
                  :data="widget.data"
                  :title="$t(widget.id)"
                  :loading="widget.loading"
                  @open="widget.maximized = $event"
                >
                  <template #table-buttons="props">
                    <v-btn
                      v-if="widget.id === 'site.technical_values_repairs' && hasApplicationPermissionByName('KOHTEIDEN_MUOKKAUS')"
                      :loading="widget.loading"
                      :disabled="widget.loading"
                      small
                      outlined
                      rounded
                      @click="createRepair(props)"
                    >
                      {{ $t('repair.create') }}
                    </v-btn>
                  </template>
                  <template 
                    v-if="widget.id === 'site.technical_values_repairs' && hasApplicationPermissionByName('KOHTEIDEN_MUOKKAUS')"
                    #customComponent="{ item }"
                  >
                    <v-icon
                      right
                      @click="modifyRepair(item)"
                    >
                      edit
                    </v-icon>
                  </template>
                </DynamicWidget>
              </div>
            </v-col>
          </v-row>
          <v-row />
        </template>
      </BaseView>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MetadataForm from '../components/MetadataForm.vue'
import DynamicWidget from '../components/DynamicWidget.vue'
import NewRepairModal from '../components/NewRepairModal.vue'
import Alert from '../components/Alert.vue'
import BaseView from '../components/general/BaseView.vue'
import helpers from '../helpers'
import CdsAlert from '../components/CircleDesignSystem/CdsAlert.vue'

export default {
  name: 'TechnicalValues',
  components: {
    MetadataForm,
    DynamicWidget,
    NewRepairModal,
    Alert,
    BaseView,
    CdsAlert,
  },
  data () {
    return {
      selectedBuilding: undefined,
      isLoadingTechnicalValues: false,
      currentTechnicalValues: undefined,
      repairToEdit: undefined,
      showNewRepairModal: false,
      showAlert: false,
      alertResult: undefined,
      alertMessage: undefined,
      canCalculateTechnicalValue: true,
      technicalValueFields: {
        // unitArea: { format: 'Area' },
        // year: {},
        siteIdentifier: {},
        siteName: {},
        buildingCode: {},
        buildingAddress: {},
        buildingPurposeOfUse: {},
        buildingStatus: {},
        buildingUnitArea: { format: 'Area' },
        buildingYear: {},
        purchasePrice: { format: "Money" },
        indexIncreasedPrice: { format: "Money" },
        indexIncreasedPricePerSquare: { format: "MoneyPerSquareInt" },
        technicalValue: { format: "Money" },
        technicalValuePerSquare: { format: "MoneyPerSquareInt" },
        conditionPercentage: { format: "PercentageFromDecimal" },

        repairDebt75: { format: "Money" },
        repairDebt75PerSquare: { format: "MoneyPerSquareInt" },
        adjustedRepairCost: { format: "Money" },
        
        baseTechnicalValue: { format: "Money" },
        depreciatedTechnicalValue: { format: "Money", groupLast: true  },
        depreciationRatio: { format: "PercentageFromDecimal" },
        depreciationPeriod: {},
        // cumulativeTechnicalDepreciation: { format: "PercentageFromDecimal" }, // Disabled by customer request (Helsinki)
        depreciationPercentage: { format: "PercentageFromDecimal", groupLast: true },
        repairAdjustmentMultiplier: {},
        initialRepairCost: { format: "Money" },
        initialRepairCostPerSquare: { format: "MoneyPerSquareInt" },
        // adjustedRepairPercentage: { format: "PercentageFromDecimal" }, // Disabled by customer request (Helsinki)
        // technicalCompletionYear: {},
        // yearlyDepreciationPercentage: { format: "PercentageFromDecimal" },
        // yearlyDepreciation: { format: "Money" },
        // baseConstantDepreciation: { format: "Money" }, // Disabled by customer request (Helsinki)
        // constantDepreciation: { format: "Money" }, // Disabled by customer request (Helsinki)
        repairNeed60: { format: "Money", groupLast: true  },
        repairNeed60PerSquare: { format: "MoneyPerSquareInt" },
        // yearlyDepreciationPerSquare: { format: "Money" },
        investmentsPerSquare: { format: "MoneyPerSquareInt" },
        investments: { format: "Money" },
      },
      widgets: [
        {
          id: 'site.technical_values_linechart',
          name: 'technicalvalueslinechart',
          type: 'LineChart',
          loading: true,
          data: {
            items: [],
            lines: [],
            xAxis: {
              label: this.$t('technicalValues.year'),
              field: 'year',
            },
            yAxis: {
              label: this.$t('technicalValues.technicalValue') + ' €',
              min: 0,
              maxTicksLimit: 10,
              callback: function (value) {
                return helpers.humanize.amount_long(value, 0);
              },
            },
            options: {
              tooltips: {
                callbacks: {
                  label: (ctx, data) => {
                    let dataSet = data.datasets[ctx.datasetIndex]
                    let total = Math.max(...dataSet.data.map(d => Number(d.y)).filter(v => !Number.isNaN(v)))
                    let percentage = ((Number(ctx.value) / total) * 100).toFixed(0)
                    if (dataSet.label === 'Tekninen arvo') {
                      return dataSet.label + ': ' + helpers.humanize.amount_long(ctx.yLabel, 0) + ' € (' + percentage + ' %)'
                    }
                    return dataSet.label + ': ' + helpers.humanize.amount_long(ctx.yLabel, 0) + ' €'
                  }
                },
              },
              annotation: {
                annotations: [
                  {
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: "0",
                    borderColor: "red",
                  }
                ]
              },
              scales: {
                yAxes: [
                  {
                    type: 'linear',
                    position: 'right',
                    ticks: {
                      max: 100,
                      min: 0,
                      maxTicksLimit: 6,
                      autoSkip: false,
                    },
                    grace: '25%',
                  }
                ]
              }
            }
          }
        },
        {
          id: 'site.technical_values_repairs',
          name: 'technicalvaluerepairs',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: 'repair.repairYear', value: 'repairDate', alignRight: true },
              { text: 'repair.originalRepairCost', value: 'originalRepairCost', format: "Money" },
              { text: 'repair.repairCost', value: 'repairCost', format: "Money" },
              { text: 'repair.indexIncreasedRepairCost', value: 'indexIncreasedRepairCost', format: "Money" },
              { text: 'technicalValues.technicalValue', value: 'technicalValue', format: "Money" },
              { text: 'technicalValues.depreciationPeriod', value: 'depreciationPeriod', alignRight: true },
              { text: 'technicalValues.depreciationRatio', value: 'depreciationRatio', format: "PercentageFromDecimal" },
              { text: 'repair.repairType', value: 'repairTypeId', format: "Translation" },
              { text: 'repair.description', hideOverflow: true, value: 'description' },
              { text: 'repair.isTotalRepair', value: 'isTotalRepair', format: "Array" },
              { text: 'technicalValues.yearlyDepreciationPercentage', value: 'yearlyDepreciationPercentage', format: "PercentageFromDecimal" },
              { text: 'technicalValues.yearlyDepreciation', value: 'yearlyDepreciation', format: "Money" },
              { text: 'technicalValues.constantDepreciation', value: 'constantDepreciation', format: "Money" },
              { text: 'technicalValues.cumulativeTechnicalDepreciation', value: 'cumulativeTechnicalDepreciation', format: "PercentageFromDecimal" },
              { text: this.$t('Actions'), value: 'customComponent' }
            ],
            customFormatters: {
              repairDate: (row) => {
                let date = new Date(row.repairDate)
                return isNaN(date) ? "" : date.getFullYear();
              },
              isTotalRepair: (row) => {
                return helpers.format.formatData(row.isTotalRepair, 'Boolean')
              },
            },
            items: [],
            hideTotalRow: true,
            customFilterContent: {
              isTotalRepair: [
                { text: this.$t('No'), value: 'false' },
                { text: this.$t('Yes'), value: 'true' },
              ],
            },
          }
        },
      ]
    }
  },
  computed: {
    ...mapState('app', [ 'currentDate' ]),
    ...mapGetters('app', [ 'hasApplicationPermissionByName', 'definitionLabelById' ]),
    ...mapState('sites', [ 'isLoadingSiteData' ]),
    ...mapGetters('sites', [ 'buildingsForCurrentSite', 'currentSite' ]),
    siteId () {
      return Number(this.$route.params.id_site)
    },
    technicalValueFormMetadata () {
      return this.buildingsForCurrentSite?.length > 0 && this.selectedBuilding ? this.technicalValueFields : {}
    },
  },
  watch: {
    selectedBuilding: {
      deep: true,
      handler: function (building) {
        if (building) {
          this.getPageData(building)
        }
      },
    },
    buildingsForCurrentSite: function (buildings) {
      if (!buildings || buildings.length == 0) {
        return
      }
      if (!this.selectedBuilding) {
        this.selectedBuilding = buildings[0]
      } else {
        let selectedBuildingVersion = this.buildingsForCurrentSite.filter(b => b.id_building === this.selectedBuilding.id_building)?.[0]
        this.selectedBuilding = selectedBuildingVersion ? { ...selectedBuildingVersion } : undefined
      }
    },
    showAlert: function (boolean) {
      if (boolean) {
        setTimeout(() => {
          this.showAlert = false
        }, 4000)
      }
    },
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('TEKNISET_ARVOT')) {
      this.$router.push({ name: 'sites.info'})
    }
    if (this.buildingsForCurrentSite?.length > 0) {
      this.selectedBuilding = this.buildingsForCurrentSite[0]
    }
    for (const key in this.technicalValueFields) {
      this.technicalValueFields[key] = {
        ...this.getDefaultMetadata(),
        ...this.technicalValueFields[key],
        label: "technicalValues." + key,
      }
    }
  },
  methods: {
    async getPageData (building) {
      this.currentTechnicalValues = undefined
      this.canCalculateTechnicalValue = true
      if(this.selectedBuilding?.completed_year === undefined || this.selectedBuilding?.completed_year === null || this.selectedBuilding?.completed_year === ''){
          this.canCalculateTechnicalValue = false
          return
        }
      this.getBuildingTechnicalValues(building.id_building)
      this.loadWidgets(building.id_building)
    },
    async getBuildingTechnicalValues (idBuilding) {
      if (idBuilding) {
        this.isLoadingTechnicalValues = true
        const technicalValues = await this.$rambollfmapi.technicalValues.building.get(idBuilding, this.currentDate)
        this.currentTechnicalValues = technicalValues
        if (technicalValues !== undefined) {
          this.currentTechnicalValues = {
            ...technicalValues,
            siteName: this.currentSite?.name,
            siteIdentifier: this.currentSite?.site_identifier,
            buildingCode: this.selectedBuilding?.building_code,
            buildingAddress: this.selectedBuilding?.address,
            buildingPurposeOfUse: this.definitionLabelById(this.selectedBuilding?.building_exact_usage),
            buildingStatus: this.definitionLabelById(this.selectedBuilding?.life_cycle),
            buildingUnitArea: this.selectedBuilding?.building_net_floor_area,
            buildingYear: this.selectedBuilding?.completed_year,
          }
        }
        this.isLoadingTechnicalValues = false
      }
    },
    getDefaultMetadata () {
      return {
        isShown: true,
        category: "None",
      }
    },
    async loadWidgets (idBuilding, widgetId = null) {
      this.widgets.forEach(widget => {
        if (widgetId != null && widgetId !== widget.id) {
          return
        }
        if (this.selectedBuilding) {
          widget.loading = true
          this.$rambollfmapi.reports.post(widget.name, [ idBuilding ], this.currentDate)
            .then((result) => this.setWidgetData(widget, result))
        }
      });
    },
    setWidgetData (updatedWidget, result) {
      let widget = this.widgets.find(w => w.id == updatedWidget.id)

      if (widget.id === 'site.technical_values_linechart') {
        widget.data.options.annotation.annotations[0].value = result.technicalValues
          .map(v => v.year)
          .indexOf(new Date().getFullYear())
        widget.data.items = result.technicalValues
        let lineProps = {
          xField: 'year',
          dashed: true,
          pointRadius: 0,
        }
        widget.data.lines = [
          {
            xField: 'year',
            yField: 'technicalValue',
            label: this.$t('technicalValues.technicalValue'),
          },
          {
            yValue: result.condition75Price,
            label: this.$t('technicalValues.condition75'),
            color: '#FFA500',
            ...lineProps
          },
          {
            yValue: result.condition30Price,
            color: '#FF0000',
            label: this.$t('technicalValues.condition30'),
            ...lineProps
          },
          {
            yValue: result.indexIncreasedPrice,
            color: '#32CD32',
            label: this.$t('technicalValues.purchasePrice'),
            ...lineProps
          }
        ]
      } else if (widget.id === 'site.technical_values_repairs') {
        widget.data.items = result.map((item) => {
          return {
            ...item,
            repairTypeId: this.definitionLabelById(item.repairTypeId)
          }
        })
      }
      else {
        widget.data.items = result
      }
      
      widget.loading = false
    },
    createRepair () {
      this.repairToEdit = undefined
      this.showNewRepairModal = true
    },
    modifyRepair (repair) {
      this.repairToEdit = repair
      this.showNewRepairModal = true
    },
    closeNewRepairModal (withUpdate = false) {
      this.showNewRepairModal = false
      this.repairToEdit = undefined
      if (withUpdate) {
        this.setSuccessAlert(this.$t('repair.saveSuccess'))
        if (this.selectedBuilding) {
          this.getPageData(this.selectedBuilding)
        }
      }
    },
    setSuccessAlert (alertMessage) {
      this.showAlert = true
      this.alertResult = 'success'
      this.alertMessage = alertMessage
    },
  }
}
</script>
<style scoped>
.w-fit-content {
  width: fit-content;
}
</style>