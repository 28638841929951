<template>
  <div>
    <!-- Modals -->
    <v-row
      id="site-rental-contract"
    >
      <v-col cols="6">
        <sortable-widget-layout :widgets="widgetsLeft" />
      </v-col>
      <v-col cols="6">
        <sortable-widget-layout :widgets="widgetsRight" />
      </v-col>
      <v-col
        lg="12"
        md="12"
        sm="12"
        cols="12"
      >
        <Contracts
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET') && hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')"
          :site-id="currentSiteId"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SortableWidgetLayout from '../components/widgets/SortableWidgetLayout.vue'
import LeasingWidgets from '../widgets/leasing'
import Contracts from './Contracts.vue'

export default {
  name: 'SiteRentalContracts',
  components: {
    SortableWidgetLayout,
    Contracts
  },
  data () {
    return {
      // Widgets
      widgetsLeft: [LeasingWidgets.OccupancyRates()],
      widgetsRight: [LeasingWidgets.EndingContractsGraph()]
    }
  },
  computed: {
    ...mapState('sites', ['currentSiteId', 'loadedReports', 'loadedBuildings']),
    ...mapState('app', ['currentDate', 'userInfo', 'sites']),

    ...mapGetters('sites', ['reportForCurrentSite', 'currentSite']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('leasing', ['leasingWidgetDataForCurrentSite']),

    widgets () {
      return this.widgetsLeft.concat(this.widgetsRight)
    }
  },
  watch: {
    currentDate: function (newDate) {
      const date = this.$store.state.app.currentDate
      if (
        new Date(newDate).toISOString().substr(0, 10) !==
        date.toISOString().substr(0, 10)
      ) {
        this.initChanges()
        this.updateWidgetData()
      }
    },
    loadedBuildings: {
      handler: async function () {
        // Make a copy of the currentSite
        this.$log.info('Current site changed. updating site info...')
        await this.initView()
      },
      deep: true,
    },
    loadedReports: function () {
      this.updateWidgetData()
    },
  },
  async mounted () {
    if (!this.hasApplicationPermissionByName('KOHDE_VUOKRASOPLEHTI')) {
      this.$router.push({ name: 'sites.info' })
    }

    await this.initView()
  },
  methods: {
    ...mapActions('sites', ['loadSiteRentalReports']),
    async initView () {
      if (this.currentSite) {
        this.loadSiteRentalReports({
          siteId: this.currentSiteId,
          widgets: this.widgets,
        })
        await this.updateWidgetData()
        this.$store.commit('app/setSortableWidgets', false)
      }
    },
    updateWidgetData () {
      this.widgets.forEach((w, idx) => {
        if (w.data.report !== undefined) {
          // if report is defined in widget, use the data from store
          w.data.items = this.reportForCurrentSite(w.data.report)
          if (w.data.items === null || w.data.items === undefined) {
            w.data.items = []
          } else {
            w.loading = false
          }
        } else if (w.data.source !== undefined && w.data.source !== null) {
          // if source is defined in widget, use the data from component
          w.data.items = this[w.data.source]
          w.loading = false
        }
        // Set metadata
        if (w.data.metadataSource !== null) {
          w.data.metadata = this[w.data.metadataSource]
        } else if (
          w.data.metadataLabels !== null &&
          w.data.metadataLabels !== undefined
        ) {
          w.data.metadata = {}
          w.data.metadataLabels.forEach((label) => {
            w.data.metadata[label.value] = {
              isShown: true,
              category: w.data.category ? w.data.category : 'None',
              isDefinition: label.isDefinition ? label.isDefinition : false,
              format: label.format ? label.format : 'None',
            }
          })
        }
        if (w.data.lineReport != null) {
          w.data.lineItems = this.reportForCurrentSite(w.data.lineReport)
        }
      })
    },
  },
}
</script>
<style scoped>
#site-rental-contracts {
  padding: 0.5em;
  padding-top: 0;
}

#site-rental-contracts > .col {
  padding: 0.5em;
  padding-top: 0;
}

#site-rental-contracts > .col > div:not(:first-child) > .v-expansion-panel {
  margin-top: 0.5em;
}

#site-rental-contracts .subpanel {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: auto;
}

#site-rental-contracts .subpanel:not(:first-child) {
  margin-top: 0.5em;
}

#site-rental-contracts .subpanel:last-child {
  margin-bottom: 0.5em;
}

#site-rental-contracts .widget {
  padding-top: 1em;
}
</style>
