<template>
  <v-row
    class="dashboard"
  >
    <v-col
      lg="9"
      cols="12"
    >
      <SituationWidgets
        :data="situationData"
        :party-links="partyLinks"
        :loading="widgetLoading('situation')"
        :lower-bound-quarter-dates="lowerBoundQuarterDates"
        :inspection-year="inspectionYear"
        @onUpdate="getQuarterData"
      />
    </v-col>
    <v-col
      v-for="widget in widgets"
      :key="widget.id"
      :lg="widget.small ? 3 : 6"
      :md="widget.small ? 6 : 12"
      cols="12"
    >
      <DynamicWidget
        :type="widget.type"
        :title="$t(widget.id)"
        :data="widget.data"
        :loading="widgetLoading(widget.id)"
        :maximized="widget.maximized"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SituationWidgets from '../SituationWidgets.vue'
import LeasingWidgets from '../../../widgets/leasing'
import DynamicWidget from '../../DynamicWidget.vue'
import moment from 'moment'
import _debounce from 'lodash/debounce'
import humanize from '../../../helpers/humanize.js'
import { getProspectType, getProspectLastActiveDate } from '../../../helpers/leasing/prospect.js'
import { loadOccupancyRates, loadOccupancyRateProgress } from '../../../helpers/leasing/occupancyRates.js'
import { calculateTotal } from '../../../helpers/leasing/situationAndTarget.js'
import { isInQuarterSelection } from '../../../helpers/leasing/contracts.js'
import { transformToProspect } from '../../../helpers/leasing/rentalprocess.js'

export default {
  name: 'GeneralSituation',
  components: {
    SituationWidgets,
    DynamicWidget,
  },
  props: {
    lowerBoundQuarterDates: { type: Array, default: () => [] },
    upperBoundQuarterDates: { type: Array, default: () => [] },
    inspectionYear: {
      type: Number,
      default: 0
    },
    partyLinks: { type: Array, default: () => []},
    partyLinksLoading: { type: Boolean, default: false }
  },
  data () {
    return {
      widgets: [],
      situationData: { area: {}, rentalType: {}, marketRent: {} },
      quarterRentalStatuses: [],
      futureQuarterRentalStatuses: [],
      quarterRentalStatusesLoading: false,
    }
  },
  computed: {
    ...mapState('leasing', [
      'futureRentalStatuses',
      'rentalStatuses',
      'situationAndTargetData',
      'leaseGoals',
      'prospects',
      'currentRentalStatusesLoading',
      'futureRentalStatusesLoading',
      'situationAndTargetLoading',
      'leaseGoalsLoading',
      'prospectsLoading',
      'leasingUsersLoading',
      'leasingUsers',
      'newContracts',
      'newContractsLoading',
      'closedContracts',
      'closedContractsLoading',
      'quarterlyUsage',
      'quarterlyUsageLoading',
      'pastRentalStatuses',
      'pastRentalStatusesLoading',
      'prospectEvents',
      'prospectEventsLoading',
      'capitalOccupancyLoading',
      'capitalOccupancy',
      'occupancyRateProgress',
      'occupancyRateProgressLoading'
    ]),
    ...mapState('app', ['sites', 'sitesLoading', 'currentDate', 'buildings']),
    ...mapState('rentingGoal', ['goals', 'goalsLoading']),
    ...mapState('carpark', ["carparkOccupancyRates", "carparkOccupancyRatesLoading", "carParksAndParkingZonesWithRentLoading", 'newCarparkContracts', 'newCarparkContractsLoading']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionById']),
    ...mapGetters('rentingGoal', ['getDefaultGoals']),
    ...mapState('rentalProcess', ['rentalProcesses', 'rentalProcessLoading']),

    generalSituationLoading () {
      return this.sitesLoading ||
            this.currentRentalStatusesLoading ||
            this.futureRentalStatusesLoading ||
            this.situationAndTargetLoading ||
            this.prospectsLoading ||
            this.leaseGoalsLoading ||
            this.closedContractsLoading ||
            this.quarterlyUsageLoading ||
            this.newContractsLoading ||
            this.leasingUsersLoading ||
            this.partyLinksLoading ||
            this.prospectEventsLoading ||
            this.carparkOccupancyRatesLoading ||
            this.carParksAndParkingZonesWithRentLoading ||
            this.newCarparkContractsLoading
    },
    siteUsageDefinitions () {
      return this.definitionsByGroupLabel('site.purposeofuse')
        .concat(this.definitionsByGroupLabel('unit.usage'))
    },
    siteIds () {
      return this.sites.map((site) => site.id_site)
    },
    transformedProcesses () { 
      return this.rentalProcesses.map(process => transformToProspect(process))
    }
  },
  watch: {
    generalSituationLoading (newLoadingStatus) {
      if (!newLoadingStatus) {
        this.loadWidgetData()
      }
    },
    rentalStatuses () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData()
      }
    },
    futureRentalStatuses () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData()
      }
    },
    prospects () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData()
      }
    },
    rentalProcesses () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData()
      }
    },
    sites () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData()
      }
    },
    leaseGoals () {
      if (!this.generalSituationLoading) {
        this.situationAndTarget()
      }
    },
    situationAndTargetData () {
      if (!this.generalSituationLoading) {
        this.situationAndTarget()
      }
    },
    newContracts () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData(['situation'])
      }
    },
    newCarparkContracts () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData(['situation'])
      }
    },
    // If inspection year or quarter changes we call debounced function for getting rental-statuses
    lowerBoundQuarterDates () {
      this.getQuarterDataDebounced(this)
    },
    closedContracts () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData(['leasing.leasing_progress'])
      }
    },
    quarterlyUsage () {
      if (!this.generalSituationLoading) {
        this.loadWidgetData(['leasing.leasing_progress'])
      }
    },
    goals () {
      let start = new Date(moment(this.currentDate).utc(true)
        .startOf('year'))
      const siteIds = this.sites.map(site => site.id_site)
      if (this.lowerBoundQuarterDates.length > 0) {
        start = this.lowerBoundQuarterDates[0]
      }
      this.getLeaseGoals({ siteIds, time: start })
    },
    partyLinks () {
      if (!this.generalSituationLoading) {
        this.situationAndTarget()
      }
    },
    quarterRentalStatusesLoading (newStatus) {
      if (!newStatus) {
        this.loadWidgetData(['leasing.occupancy_rates'])
      }
    },
    buildings () {
      this.getQuarterData()
      this.fetchCarParksAndParkingZonesWithRent()
    },
    carparkOccupancyRates () {
      this.loadWidgetData(["leasing.occupancy_rates"])
    },
    capitalOccupancyLoading (newValue) {
      if (!newValue) {
        this.loadWidgetData(["leasing.occupancy_rates"])
      }
    },
    occupancyRateProgressLoading (newValue) {
      if (!newValue) {
        this.loadWidgetData(["leasing.occupancy_rate_progress"])
      }
    }
  },
  mounted () {
    this.widgets = [    
      LeasingWidgets.OccupancyRates(), 
      ...LeasingWidgets.GeneralSituation()
    ]
    this.fetchCarParksAndParkingZonesWithRent()
    this.getLocations()
    this.loadWidgetData()
    this.getQuarterData()
  },
  beforeDestroy () {
    if (this.lowerBoundQuarterDates.length > 0) {
      this.getQuarterlyUsage(this.currentDate)
    }
  },
  methods: {
    ...mapActions('leasing', [
      'getQuarterlyUsage',
      'getLeaseGoals',
      'getSituationAndTargetData',
      'getNewContracts',
      'getCapitalOccupancy',
      'getOccupancyRateProgress'
    ]),
    ...mapActions('rentingGoal', ['fetchAllGoals']),
    ...mapActions("carpark", ["fetchCarparkOccupancyRates", "fetchCarParksAndParkingZonesWithRent", "getNewCarparkContracts"]),
    ...mapActions('rentalProcess', ['getLocations']),
    loadWidgetData (chosenWidgets) {
      let widgetIds = this.widgets.map(widget => widget.id)
      if (chosenWidgets != null) {
        widgetIds = widgetIds.filter(widgetId => chosenWidgets.includes(widgetId))
      }

      widgetIds.forEach((id) => {
        const widget = this.widgets.find(widget => widget.id === id)
        widget.data.items = this.dataForWidget(widget.id)
        // widget second items set
        if (widget.data.lineItems) {
          widget.data.lineItems = this.extraDataForWidget(widget.id)
        }
      })
      if (chosenWidgets == null || chosenWidgets.includes('situation')) {
        this.situationAndTarget()
      }
    },
    widgetLoading (id) {
      switch (id) {
        case 'situation':
          return this.generalSituationLoading || this.goalsLoading || this.leaseGoalsLoading || this.rentalProcessLoading
        case 'leasing.occupancy_rates':
          return this.generalSituationLoading || this.carparkOccupancyRatesLoading || this.capitalOccupancyLoading
        case 'leasing.leasing_progress':
        case 'leasing.most_leased_by_site':
          return this.generalSituationLoading || this.rentalProcessLoading
        case 'leasing.most_terminated_by_site':
          return this.generalSituationLoading || this.pastRentalStatusesLoading || this.rentalProcessLoading
        case 'leasing.occupancy_rate_progress':
            return this.occupancyRateProgressLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }
        let { rentalStatuses } = this
        let futureStatuses = this.futureRentalStatuses
        switch (id) {
          case 'leasing.occupancy_rates': {
            if (this.lowerBoundQuarterDates.length > 0) {
              rentalStatuses = this.quarterRentalStatuses
              futureStatuses = this.futureQuarterRentalStatuses
            }
            const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
            return loadOccupancyRates(futureStatuses, rentalStatuses, this.carparkOccupancyRates, this.sites, rentalDefs, this.currentDate, this.capitalOccupancy)
          }
          case 'leasing.leasing_progress':
            return this.leasingProgressData(this.closedContracts, this.transformedProcesses, this.pastRentalStatuses, this.rentalStatuses, this.futureRentalStatuses)
          case 'leasing.most_leased_by_site':
            return this.mostLeasedBySite(this.closedContracts, this.transformedProcesses)
          case 'leasing.most_terminated_by_site':
            return this.mostTerminatedBySite(this.rentalStatuses, this.pastRentalStatuses)
          case 'leasing.occupancy_rate_progress':
            return loadOccupancyRateProgress(this.occupancyRateProgress)
          default:
            return []
        }
      } catch (err) {
        this.$log.error(`Error trying to process data for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    extraDataForWidget (id) {
      switch (id) {
        case 'leasing.leasing_progress':
          return this.quarterlyOccupancyRates(this.quarterlyUsage)
        default:
          return []
      }
    },
    contractData (rentalStatuses, prospects, leasingUsers) {
      // We should group the prospects by contract id for more efficient search later on:
      const prospectObjects = prospects.reduce((acc, cur) => {

        const { contract_numbers } = cur

        if(!contract_numbers || contract_numbers.length === 0){
          return acc
        } 

        contract_numbers.forEach(contractNumber => {
          if(!acc[contractNumber]){
            acc[contractNumber] = cur
          }
        })


        return acc
      }, {})

      const rows = []

      const siteIds = this.sites.map(site => site.id_site)

      const siteFiltered = rentalStatuses.filter(rs => siteIds.includes(rs.siteId))

      const filtered = this.filterContractsByQuarters(siteFiltered, 'signature_date', this.lowerBoundQuarterDates, this.upperBoundQuarterDates).filter(rs => new Date(rs.signature_date) < new Date(this.currentDate))

      filtered.forEach((rs) => {
        // Valid rental statuses always contains contract numbers. Let's skip others
        if (!rs.contractNumber) {
          return
        }

        // There may be multiple contracts here with different spaces. We should check for duplicates and
        // calcualte correct are for whole rental. Worst case N^2 implementation here.
        const index = rows.findIndex((row) => row.contractNumber === rs.contractNumber)

        const marketRentTotal = this.getMarketRent(rs)

        if (index >= 0) {
          rows[index].total_area += rs.area
          rows[index].units.push({ id: rs.unitId, unit_code: rs.unitName })
          // Update total market_rent
          rows[index].market_rent_total = rows[index].market_rent_total + marketRentTotal
          return
        }

        const rental_type_translated = this.definitionById(rs.defTypeOfRenting)?.label

        // Constructing new row.
        const newRow = {
          // Widget first values
          signature_date: rs.signature_date,
          total_area: rs.area,
          market_rent_total: marketRentTotal,
          siteName: rs.siteName,
          tenant: rs.curPartyName,
          prospect_type_icon: '',
          startDate: rs.curPartyStartDate,

          // Additional behind button.
          validity: '',
          endDate: rs.curPartyEndDate,
          contractFirstPossibleEndDate: rs.contractFirstPossibleEndDate,
          site_classification: '',

          // Additional details
          rental_type: rs.defTypeOfRenting, // Will be replaced later by prospect data if necessary.
          rental_type_translated,
          contractNumber: rs.contractNumber,
          id_site: rs.siteId,
          units: [{ id: rs.unitId, unit_code: rs.unitName }],
          id_tenant: rs.curPartyId,
          unitUsage: rs.unitUsage,
          tenant_corporation: rs.tenant_corporation,
          no_renegotiations: rs.no_renegotiations,
          contractId: rs.contractId,
          business_id: rs.business_id,
          // Type of renting button stuff
          defTypeOfRenting: rs.defTypeOfRenting,
          unitId: rs.unitId,
          curUnitPartyId: rs.curUnitPartyId,
        }

        if (rs.validity === 'määräaikainen') {
          newRow.validity = this.$t('Fixed term')
        } else if (rs.validity === 'toistaiseksi voimassaoleva') {
          newRow.validity = this.$t('Permanent')
        }

        const siteClassification = this.definitionById(parseInt(rs.site_classification))

        if (siteClassification) {
          newRow.site_classification = this.$t(siteClassification.label)
        }

        // We have two types of linkings for prospects. Links which have been made from prospect and
        // links which have been made of prospects called: kohdistettu. In this widget we will use latter
        const prospect = prospectObjects[rs.contractNumber]

        if (prospect) {
          newRow.id_prospect = prospect.id_prospect
          newRow.id_process = prospect.id_process
          newRow.prospect_description = prospect.prospect_description

          const typeInfo = getProspectType(prospect)

          newRow.rental_type = typeInfo.typeText
          newRow.rental_type_translated = this.$t(typeInfo.typeText)
          newRow.prospect_type_icon = typeInfo.typeIcon

          const firstResponsible = leasingUsers.find((item) => item.id_user === prospect.owner)
          if (firstResponsible) {
            newRow.first_responsible = firstResponsible.name
          }
          const secondaryResponsible = leasingUsers.find((item) => item.id_user === prospect.secondaryOwner)
          if (secondaryResponsible) {
            newRow.secondary_responsible = secondaryResponsible.name
          }
        }

        rows.push(newRow)
      })

      rows.sort((a, b) => new Date(b.signature_date) - new Date(a.signature_date))
      return rows
    },
    stripTimeFromDate (date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    validateDate (lowerBoundQuarterDates, date) {
      if (lowerBoundQuarterDates.length === 0) {
        return false
      }
      const startDate = new Date(lowerBoundQuarterDates[0])
      return date > startDate
    },
    situationAndTarget () {
      const today = new Date()
      const year = today.getFullYear()
      const todayWithoutTime = this.stripTimeFromDate(today)
      const currentDateWithoutTime = this.stripTimeFromDate(this.currentDate)
      const datesAreSame = currentDateWithoutTime.getTime() === todayWithoutTime.getTime()

      const { endingContracts, endingCarparkContracts, freeUnitsEnd } = this.situationAndTargetData

      const hideWidgets = this.inspectionYear !== null && year !== this.inspectionYear ||
                          !datesAreSame ||
                          this.upperBoundQuarterDates.length > 0 || 
                          this.lowerBoundQuarterDates.length > 0

      const dateIsInPast = currentDateWithoutTime < todayWithoutTime || 
                           (this.inspectionYear !== null && year > this.inspectionYear) ||
                           this.validateDate(this.lowerBoundQuarterDates, todayWithoutTime)

      if (this.generalSituationLoading) {
        return
      }

      // Rental definitions required for later 
      const permittedStatuses = ['rentable', 'rentable.koy', 'notrentable.repairs' ]
      const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
      const rentableDefs = rentalDefs
        .filter((x) => permittedStatuses.includes(x.label))
        .map((x) => x.id)

      const closedProcesses = this.transformedProcesses.filter((process) => process.contract_numbers && process.contract_numbers.length > 0)

      // Grouping the prospects for faster lookup later...
      const groupedProcesses = this.transformedProcesses.reduce((acc, process) => {
        if (acc.has(process.id)) {
          return acc
        }

        acc.set(process.id, process)
        return acc
      }, new Map())

      let endedRentalStatuses = []
      let endedCarparkContracts = []
      let predictionEndingRentalStatuses = []
      let predictionEndingCarParkRentalStatuses = []
      let newRentalStatuses = []
      let newCarparkContracts = []
      let currentFreeRentalStatuses = []

      let targetCoefficiency = 0


      // Dates needed
      let startDate = new Date(this.currentDate)
      if (this.lowerBoundQuarterDates.length > 0) {
        startDate = new Date(this.lowerBoundQuarterDates[0])
      }
      const startOfYear = new Date(moment(startDate).startOf('year'))
      const endOfYear = new Date(moment(startDate).endOf('year'))

      if (this.lowerBoundQuarterDates.length > 0) {
        endedRentalStatuses =  endingContracts.filter(rs => rs.curPartyEndDate != null && isInQuarterSelection(rs.curPartyEndDate, this.lowerBoundQuarterDates, this.upperBoundQuarterDates))
        predictionEndingRentalStatuses = endingContracts.filter(rs => rs.curPartyEndDate != null && isInQuarterSelection(rs.curPartyEndDate, this.lowerBoundQuarterDates, this.upperBoundQuarterDates))
        endedCarparkContracts = endingCarparkContracts.filter(rs => isInQuarterSelection(rs.end_date, this.lowerBoundQuarterDates, this.upperBoundQuarterDates))
        predictionEndingCarParkRentalStatuses = endingCarparkContracts.filter(rs => isInQuarterSelection(rs.end_date, this.lowerBoundQuarterDates, this.upperBoundQuarterDates))
        newRentalStatuses =  this.newContracts.filter(rs => isInQuarterSelection(rs.signature_date, this.lowerBoundQuarterDates, this.upperBoundQuarterDates) || ( rs.signature_date === null && isInQuarterSelection(rs.start_date, this.lowerBoundQuarterDates, this.upperBoundQuarterDates)))
        newCarparkContracts = this.newCarparkContracts.filter(rs => isInQuarterSelection(rs.signature_date, this.lowerBoundQuarterDates, this.upperBoundQuarterDates) || ( rs.signature_date === null && isInQuarterSelection(rs.start_date, this.lowerBoundQuarterDates, this.upperBoundQuarterDates)))
        currentFreeRentalStatuses = freeUnitsEnd.filter(rs => rs.curPartyId === 0 && rentableDefs.includes(rs.unitStatus) && rs.nextPartyId === 0)
        targetCoefficiency += this.lowerBoundQuarterDates.reduce((acc, curr, index) => acc += moment(this.upperBoundQuarterDates[index]).diff(moment(this.lowerBoundQuarterDates[index]), 'years', true), 0)
      }
      // If no time selection is active, filter out ended statuses after current date
      else {
        endedRentalStatuses = endingContracts.filter(rs => rs.curPartyEndDate != null && new Date(rs.curPartyEndDate) <= startDate && new Date(rs.curPartyEndDate) >= startOfYear)
        predictionEndingRentalStatuses = endingContracts.filter(rs => new Date(rs.curPartyEndDate) > new Date(this.currentDate))
        endedCarparkContracts = endingCarparkContracts.filter(rs => new Date(rs.end_date) <= startDate && new Date(rs.end_date) >= startOfYear)
        predictionEndingCarParkRentalStatuses = endingCarparkContracts.filter(rs => new Date(rs.end_date) > new Date(this.currentDate))
        newRentalStatuses = this.newContracts.filter(rs => new Date(rs.signature_date) < new Date(this.currentDate) || ( rs.signature_date === null && new Date(rs.start_date) < new Date(this.currentDate)))
        newCarparkContracts = this.newCarparkContracts.filter(rs => ( new Date(rs.signature_date) < new Date(this.currentDate) && new Date(rs.signature_date) > startOfYear ) || ( rs.signature_date === null && new Date(rs.start_date) < new Date(this.currentDate) && new Date(rs.start_date) > startOfYear))
        currentFreeRentalStatuses = this.rentalStatuses.filter(rs => rs.curPartyId === 0 && rentableDefs.includes(rs.unitStatus) && rs.nextPartyId === 0)
        targetCoefficiency = 1
      }
      // Filter out sites
      const siteIds = this.sites.filter(item => item.ownership_status === 'Omistuksessa').map((site) => site.id_site)

      endedRentalStatuses = endedRentalStatuses.filter((rs) => siteIds.includes(rs.siteId))
      predictionEndingRentalStatuses = predictionEndingRentalStatuses.filter((rs) => siteIds.includes(rs.siteId))
      endedCarparkContracts = endedCarparkContracts.filter((rs) => siteIds.includes(rs.id_site))
      predictionEndingCarParkRentalStatuses = predictionEndingCarParkRentalStatuses.filter((rs) => siteIds.includes(rs.id_site))
      newRentalStatuses = newRentalStatuses.filter((rs) => siteIds.includes(rs.siteId))
      newCarparkContracts = newCarparkContracts.filter((rs) => siteIds.includes(rs.id_site))
      currentFreeRentalStatuses = currentFreeRentalStatuses.filter((rs) => siteIds.includes(rs.siteId))
      

      const areaData = {
        all: {},
        renegotiations: {},
        renewals: {},
        allRenegotiations: {},
        new: {},
      }
      const rentalTypeData = {
        all: {},
        renegotiations: {},
        renewals: {},
        allRenegotiations: {},
        new: {},
      }

      // 1. Calculate three largest classes from sites for each type

      // New negotiations 4 largest classes
      const currentFreeSiteClassifications = this.calculateClasses(this.sites, this.goals, 'new_negotiation')
      currentFreeSiteClassifications.sort((a, b) => b.area - a.area)
      this.addLargestClasses(
        currentFreeSiteClassifications,
        areaData.new,
        4
      )

      // Renegotiations 4 largest classes
      const renewalsClassifications = this.calculateClasses(this.sites, this.goals, 'renewal')
      renewalsClassifications.sort((a, b) => b.area - a.area)
      this.addLargestClasses(
        renewalsClassifications,
        areaData.renewals,
        4
      )

      // Renewals 4 largest classes
      const renegotiationsClassifications = this.calculateClasses(this.sites, this.goals, 'renegotiation')
      renegotiationsClassifications.sort((a, b) => { return b.area - a.area })
      this.addLargestClasses(
        renegotiationsClassifications,
        areaData.renegotiations,
        4
      )

      // All renegotiations 4 largest classes
      const allRenegotiationsClassifications = this.calculateClasses(this.sites, this.goals)
      allRenegotiationsClassifications.sort((a, b) => b.area - a.area)
      this.addLargestClasses(
        allRenegotiationsClassifications,
        areaData.allRenegotiations,
        4
      )

      // Total 4 largest classes
      const allClassifications = []
      currentFreeSiteClassifications
        .concat(renewalsClassifications)
        .concat(renegotiationsClassifications)
        .forEach((item) => {
          const found = allClassifications.find((classification) => classification.class === item.class)
          if (found == null) {
            allClassifications.push({ class: item.class, area: item.area, marketRent: item.marketRent })
          } else {
            found.area += item.area
            found.marketRent += item.marketRent
          }
        })

      allClassifications.sort((a, b) => b.area - a.area)
      this.addLargestClasses(allClassifications, areaData.all, 4)
      // Add target subarrays
      const targetDistributionArea = {}
      for (const property in areaData.all) {
        targetDistributionArea[property] = {
          new: [],
          renegotiations: [],
          renewals: [],
        }
      }
      targetDistributionArea.others = { new: [], renegotiations: [], renewals: [] }


      // Initialize datapoints.
      this.initDatapoints(areaData, rentalTypeData, allClassifications, renegotiationsClassifications, renewalsClassifications, currentFreeSiteClassifications, allRenegotiationsClassifications)

      // Ending contract distribution by classification
      const endingFixedConfirmedDistributionArea = {}
      const terminatedPermanentConfirmedDistributionArea = {}
      const endingFixedPredictionDistributionArea = {}
      const terminatedPermanentPredictionDistributionArea = {}
      const endingFixedConfirmedDistributionMarketRent = {}
      const terminatedPermanentConfirmedDistributionMarketRent = {}
      const endingFixedPredictionDistributionMarketRent = {}
      const terminatedPermanentPredictionDistributionMarketRent = {}


      // Add confirmed ended contract data from endedRentalStatuses.
      endedRentalStatuses.forEach(rentalStatus => {

        let contractProspect = null
        if (rentalStatus.id_prospect_renegotiations) {
          contractProspect = groupedProcesses.get(rentalStatus.id_prospect_renegotiations)
        }

        if (rentalStatus.validity === 'toistaiseksi voimassaoleva') {
          // So let's transform all these to lists.
          areaData.all.terminated_permanent.confirmed.push({ rentalStatus, prospect: contractProspect })
          areaData.all.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.terminated_permanent.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })

          areaData.new.terminated_permanent.confirmed.push({ rentalStatus, prospect: contractProspect })
          areaData.new.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.new.terminated_permanent.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.new.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })

          // Also add to distribution object
          this.addDistributionData(
            terminatedPermanentConfirmedDistributionArea,
            rentalStatus.site_classification,
            rentalStatus
          )
          this.addDistributionData(
            terminatedPermanentConfirmedDistributionMarketRent,
            rentalStatus.site_classification,
            rentalStatus
          )
        } else if (rentalStatus.validity === 'määräaikainen') {
          areaData.all.ending_fixed.confirmed.push({ rentalStatus, prospect: contractProspect })
          areaData.all.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.ending_fixed.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })

          areaData.new.ending_fixed.confirmed.push({ rentalStatus, prospect: contractProspect })
          areaData.new.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.new.ending_fixed.confirmed.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.new.ending_total.confirmed.push({ rentalStatus, prospect: contractProspect })

          // Also add to distribution object
          this.addDistributionData(
            endingFixedConfirmedDistributionArea,
            rentalStatus.site_classification,
            rentalStatus
          )
          this.addDistributionData(
            endingFixedConfirmedDistributionMarketRent,
            rentalStatus.site_classification,
            rentalStatus
          )
        }
      })

      endedCarparkContracts.forEach(contract => {
        if (contract.validity === 'toistaiseksi voimassaoleva') {
          areaData.all.terminated_permanent.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          areaData.all.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.all.terminated_permanent.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.all.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })

          areaData.new.terminated_permanent.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          areaData.new.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.new.terminated_permanent.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.new.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })

        } else if (contract.validity === 'määräaikainen') {
          areaData.all.ending_fixed.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          areaData.all.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.all.ending_fixed.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.all.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })

          areaData.new.ending_fixed.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          areaData.new.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.new.ending_fixed.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.new.ending_total.confirmed.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          
        }
      })

      predictionEndingRentalStatuses.forEach(rentalStatus => {

        const classification = rentalStatus.site_classification

        const contractProspect = closedProcesses.find((prospect) => prospect.contract_numbers.includes(rentalStatus.contractNumber))

        let renegotiationProspect = null

        if (rentalStatus.id_prospect_renegotiations) {
          renegotiationProspect = groupedProcesses.get(rentalStatus.id_prospect_renegotiations)
        }

        // Prediction from ending contracts
        if (
          rentalStatus.curPartyEndDate != null &&
          new Date(rentalStatus.curPartyEndDate) <= endOfYear &&
          new Date(rentalStatus.curPartyEndDate) > new Date(this.currentDate) &&
          !dateIsInPast
        ) {
          if (rentalStatus.validity === 'toistaiseksi voimassaoleva') {
            areaData.renewals.terminated_permanent.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            areaData.all.terminated_permanent.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            areaData.allRenegotiations.terminated_permanent.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            this.addDistributionData(
              terminatedPermanentPredictionDistributionArea,
              classification,
              rentalStatus,
              contractProspect
            )
            this.addDistributionData(
              terminatedPermanentPredictionDistributionMarketRent,
              classification,
              rentalStatus,
              contractProspect
            )

            rentalTypeData.all.terminated_permanent.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            rentalTypeData.renewals.terminated_permanent.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            rentalTypeData.allRenegotiations.terminated_permanent.prediction.push({ rentalStatus, prospect: renegotiationProspect })
          } else if (
            rentalStatus.validity === 'määräaikainen' &&
            new Date(rentalStatus.curPartyEndDate) <= endOfYear &&
            new Date(rentalStatus.curPartyEndDate) > new Date(this.currentDate) &&
            !dateIsInPast
          ) {
            areaData.renegotiations.ending_fixed.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            areaData.all.ending_fixed.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            areaData.allRenegotiations.ending_fixed.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            this.addDistributionData(
              endingFixedPredictionDistributionArea,
              classification,
              rentalStatus,
              contractProspect
            )
            this.addDistributionData(
              endingFixedPredictionDistributionMarketRent,
              classification,
              rentalStatus,
              contractProspect
            )

            rentalTypeData.all.ending_fixed.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            rentalTypeData.renegotiations.ending_fixed.prediction.push({ rentalStatus, prospect: renegotiationProspect })
            rentalTypeData.allRenegotiations.ending_fixed.prediction.push({ rentalStatus, prospect: renegotiationProspect })
          }
          areaData.all.ending_total.prediction.push({ rentalStatus, prospect: contractProspect })
          areaData.allRenegotiations.ending_total.prediction.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.ending_total.prediction.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.allRenegotiations.ending_total.prediction.push({ rentalStatus, prospect: contractProspect })
        }
      })
      predictionEndingCarParkRentalStatuses.forEach(contract => {

        if (
          contract.contract_end_date != null &&
          new Date(contract.contract_end_date) <= endOfYear &&
          new Date(contract.contract_end_date) > new Date(this.currentDate) &&
          !dateIsInPast
        ) {
          if (contract.validity === 'toistaiseksi voimassaoleva') {
            areaData.renewals.terminated_permanent.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            areaData.all.terminated_permanent.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            areaData.allRenegotiations.terminated_permanent.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })

            rentalTypeData.all.terminated_permanent.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            rentalTypeData.renewals.terminated_permanent.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            rentalTypeData.allRenegotiations.terminated_permanent.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          } else if (
            contract.validity === 'määräaikainen' &&
            new Date(contract.contract_end_date) <= endOfYear &&
            new Date(contract.contract_end_date) > new Date(this.currentDate) &&
            !dateIsInPast
          ) {
            areaData.renegotiations.ending_fixed.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            areaData.all.ending_fixed.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            areaData.allRenegotiations.ending_fixed.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })

            rentalTypeData.all.ending_fixed.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            rentalTypeData.renegotiations.ending_fixed.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
            rentalTypeData.allRenegotiations.ending_fixed.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          }
          areaData.all.ending_total.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          areaData.allRenegotiations.ending_total.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })

          rentalTypeData.all.ending_total.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
          rentalTypeData.allRenegotiations.ending_total.prediction.push({ rentalstatus: null, carparkContract: contract, prospect: null })
        }
      })


      newRentalStatuses.forEach(rentalStatus => {

        const classification = rentalStatus.site_classification

        const contractProspect = closedProcesses.find((process) => process.contract_info.find((info) => info.contractNumber === rentalStatus.contractNumber)?.contractSigningDate === rentalStatus.signature_date)
        if (contractProspect) {
          if (
            contractProspect.rental_contract_validity ===
            'toistaiseksi voimassaoleva'
          ) {
            this.addDataByClass(
              areaData.renewals,
              'confirmed',
              classification,
              rentalStatus,
              contractProspect
            )
            areaData.renewals.total.confirmed.push({ rentalStatus, prospect: contractProspect })
            this.addDataByClass(
              areaData.allRenegotiations,
              'confirmed',
              classification,
              rentalStatus,
              contractProspect
            )
            areaData.allRenegotiations.total.confirmed.push({ rentalStatus, prospect: contractProspect })

            rentalTypeData.all.renewals.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.renewals.renewals.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.allRenegotiations.renewals.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.allRenegotiations.total.confirmed.push({ rentalStatus, prospect: contractProspect })
          } else if (
            contractProspect.rental_contract_validity === 'määräaikainen'
          ) {
            this.addDataByClass(
              areaData.renegotiations,
              'confirmed',
              classification,
              rentalStatus,
              contractProspect
            )
            areaData.renegotiations.total.confirmed.push({ rentalStatus, prospect: contractProspect })
            this.addDataByClass(
              areaData.allRenegotiations,
              'confirmed',
              classification,
              rentalStatus,
              contractProspect
            )
            areaData.allRenegotiations.total.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.all.renegotiations.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.renegotiations.renegotiations.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.allRenegotiations.renegotiations.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.allRenegotiations.total.confirmed.push({ rentalStatus, prospect: contractProspect })
          } else {
            rentalTypeData.new.new.confirmed.push({ rentalStatus, prospect: contractProspect })
            rentalTypeData.all.new.confirmed.push({ rentalStatus, prospect: contractProspect })
            this.addDataByClass(
              areaData.new,
              'confirmed',
              classification,
              rentalStatus,
              contractProspect
            )
            areaData.new.total.confirmed.push({ rentalStatus, prospect: contractProspect })
          }
          rentalTypeData.all.total.confirmed.push({ rentalStatus, prospect: contractProspect }) 
        }

        this.addDataByClass(
          areaData.all,
          'confirmed',
          classification,
          rentalStatus,
          contractProspect
        )
        areaData.all.total.confirmed.push({ rentalStatus, prospect: contractProspect })
      })
      
      newCarparkContracts.forEach(contract => {
        const classification = contract.site_classification
        const contractProspect = closedProcesses.find((process) => process.contract_info.find((info) => info.contractNumber === contract.contract_number)?.contractSigningDate === contract.signature_date)
        if (contractProspect) {

          if (
            contractProspect.rental_contract_validity === 'toistaiseksi voimassaoleva'
          ) {
            this.addDataByClass(
              areaData.renewals,
              'confirmed',
              classification,
              null,
              null,
              null,
              contract
            )
            areaData.renewals.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            this.addDataByClass(
              areaData.allRenegotiations,
              'confirmed',
              classification,
              null,
              null,
              null,
              contract
            )
            areaData.allRenegotiations.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })

            rentalTypeData.all.renewals.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.renewals.renewals.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.allRenegotiations.renewals.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.allRenegotiations.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
          } else if (
            contractProspect.rental_contract_validity === 'määräaikainen'
          ) {
            this.addDataByClass(
              areaData.renegotiations,
              'confirmed',
              classification,
              null,
              null,
              null,
              contract
            )
            areaData.renegotiations.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            this.addDataByClass(
              areaData.allRenegotiations,
              'confirmed',
              classification,
              null,
              null,
              null,
              contract
            )
            areaData.allRenegotiations.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })

            rentalTypeData.all.renegotiations.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.renegotiations.renegotiations.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.allRenegotiations.renegotiations.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.allRenegotiations.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
          } else {
            rentalTypeData.new.new.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            rentalTypeData.all.new.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
            this.addDataByClass(
              areaData.new,
              'confirmed',
              classification,
              null,
              null,
              null,
              contract
            )
            areaData.new.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
          }
          rentalTypeData.all.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })     
        }
          
        this.addDataByClass(
          areaData.all,
          'confirmed',
          classification,
          null,
          null,
          null,
          contract
        )
        areaData.all.total.confirmed.push({ rentalStatus: null, prospect: null, carparkContract: contract })
          
      })

      // Add target data
      if (!hideWidgets) {
        currentFreeRentalStatuses.forEach(rentalStatus => {
          const classification = rentalStatus.site_classification
          const contractProspect = closedProcesses.find((prospect) => prospect.contract_numbers.includes(rentalStatus.contractNumber))
          this.addDataByClass(
            areaData.new,
            'currentFree',
            classification,
            rentalStatus,
            contractProspect,
          )
          areaData.new.total.currentFree.push({ rentalStatus, prospect: contractProspect })
          this.addDataByClass(
            areaData.all,
            'currentFree',
            classification,
            rentalStatus,
            contractProspect,
          )
          areaData.all.total.currentFree.push({ rentalStatus, prospect: contractProspect })

          rentalTypeData.new.new.currentFree.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.new.currentFree.push({ rentalStatus, prospect: contractProspect })
          rentalTypeData.all.total.currentFree.push({ rentalStatus, prospect: contractProspect })
        })
      }
      const validStages = ['4','5','6','7']
      // Prediction data from active prospects
      let predictionAllProcesses = this.transformedProcesses.filter((prospect) => prospect.sites.length > 0 && prospect.status !== 'Inactive' && validStages.includes(prospect.stage.charAt(0)))

      
      if (hideWidgets) {
        predictionAllProcesses = []
      }

      for (let i = 0, n = predictionAllProcesses.length; i < n; i++) {
        const process = predictionAllProcesses[i]

        // Filter out prospects based on time selection
        const lastDate = this.upperBoundQuarterDates.length > 0 ? this.upperBoundQuarterDates[this.upperBoundQuarterDates.length - 1] : this.currentDate
        const events = this.prospectEvents.filter(event => event.id_prospect === process.id)
        const prospectLastActiveDate = getProspectLastActiveDate(process, events)

        if (prospectLastActiveDate && prospectLastActiveDate < lastDate) {
          continue
        }

        // Calculate prospect area based on success likelihood
        const factor = 1    

        const statusSite = this.sites.find((site) => site.id_site === process.sites[0].id_site)
        let classification = null
        if (statusSite) {
          classification = statusSite.site_classification

          this.addDataByClass(
            areaData.all,
            'prediction',
            classification,
            null,
            process,
            factor
          )
          areaData.all.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })

          rentalTypeData.all.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
          if (
            process.rental_contract_validity === 'toistaiseksi voimassaoleva'
          ) {
            this.addDataByClass(
              areaData.renewals,
              'prediction',
              classification,
              null,
              process,
              factor
            )
            areaData.renewals.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            this.addDataByClass(
              areaData.allRenegotiations,
              'prediction',
              classification,
              null,
              process,
              factor
            )
            areaData.allRenegotiations.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })

            rentalTypeData.renegotiations.renegotiations.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.all.renegotiations.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.allRenegotiations.renegotiations.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.allRenegotiations.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
          } else if (process.rental_contract_validity === 'määräaikainen') {
            this.addDataByClass(
              areaData.renegotiations,
              'prediction',
              classification,
              null,
              process,
              factor
            )
            areaData.renegotiations.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            this.addDataByClass(
              areaData.allRenegotiations,
              'prediction',
              classification,
              null,
              process,
              factor
            )
            areaData.allRenegotiations.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })

            rentalTypeData.renewals.renewals.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.all.renewals.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.allRenegotiations.renewals.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.allRenegotiations.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
          } else {
            this.addDataByClass(
              areaData.new,
              'prediction',
              classification,
              null,
              process,
              factor
            )
            areaData.new.total.prediction.push({ rentalStatus: null, prospect: process, weight: factor })

            rentalTypeData.new.new.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
            rentalTypeData.all.new.prediction.push({ rentalStatus: null, prospect: process, weight: factor })
          }
        }
      }

      // Add start of year targets
      if (this.leaseGoals && this.lowerBoundQuarterDates.length === 0) {
        areaData.renewals.startTarget =
          this.leaseGoals.renewalsArea
        areaData.renegotiations.startTarget =
          this.leaseGoals.renegotiationsArea
        areaData.allRenegotiations.startTarget =
          this.leaseGoals.renewalsArea +
          this.leaseGoals.renegotiationsArea
        areaData.new.startTarget = this.leaseGoals.newArea
        areaData.all.startTarget =
          this.leaseGoals.renewalsArea +
          this.leaseGoals.renegotiationsArea  +
          this.leaseGoals.newArea
        areaData.all.startCurrentFree = this.leaseGoals.currentFreeArea
        areaData.new.startCurrentFree = this.leaseGoals.currentFreeArea

        rentalTypeData.renewals.startTarget =
          this.leaseGoals.renewalsArea
        rentalTypeData.renegotiations.startTarget =
          this.leaseGoals.renegotiationsArea
        rentalTypeData.allRenegotiations.startTarget =
          this.leaseGoals.renewalsArea  +
          this.leaseGoals.renegotiationsArea
        rentalTypeData.new.startTarget = this.leaseGoals.newArea
        rentalTypeData.all.startTarget =
          this.leaseGoals.renewalsArea  +
          this.leaseGoals.renegotiationsArea  +
          this.leaseGoals.newArea
        rentalTypeData.all.startCurrentFree = this.leaseGoals.currentFreeArea
        rentalTypeData.new.startCurrentFree = this.leaseGoals.currentFreeArea
      }

      // Make ending contract distribution data into arrays, sort and finally compress to 4 classes
      let endingFixedConfirmedSubArrayArea = Object.keys(endingFixedConfirmedDistributionArea)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(endingFixedConfirmedDistributionArea[key]),
          }
        })
        .sort((prev, next) => next.value - prev.value)

      if (endingFixedConfirmedSubArrayArea.length > 3) {
        const endingFixedConfirmedOthers = endingFixedConfirmedSubArrayArea
          .slice(3)
          .reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        endingFixedConfirmedSubArrayArea = endingFixedConfirmedSubArrayArea.slice(0, 3)
        endingFixedConfirmedSubArrayArea.push(endingFixedConfirmedOthers)
      }

      let terminatedPermanentConfirmedSubArrayArea = Object.keys(terminatedPermanentConfirmedDistributionArea)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(terminatedPermanentConfirmedDistributionArea[key]),
          }
        })
        .sort((prev, next) => next.value - prev.value)

      if (terminatedPermanentConfirmedSubArrayArea.length > 3) {
        const terminatedPermanentConfirmedOthers =
          terminatedPermanentConfirmedSubArrayArea.slice(3).reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        terminatedPermanentConfirmedSubArrayArea =
          terminatedPermanentConfirmedSubArrayArea.slice(0, 3)
        terminatedPermanentConfirmedSubArrayArea.push(terminatedPermanentConfirmedOthers)
      }

      let endingFixedPredictionSubArrayArea = Object.keys(endingFixedPredictionDistributionArea)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(endingFixedPredictionDistributionArea[key]),
          }
        })
        .sort((prev, next) => next.value - prev.value)
      if (endingFixedPredictionSubArrayArea.length > 3) {
        const endingFixedPredictionOthers = endingFixedPredictionSubArrayArea
          .slice(3)
          .reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        endingFixedPredictionSubArrayArea = endingFixedPredictionSubArrayArea.slice(
          0,
          3
        )
        endingFixedPredictionSubArrayArea.push(endingFixedPredictionOthers)
      }

      let terminatedPermanentPredictionSubArrayArea = Object.keys(terminatedPermanentPredictionDistributionArea)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(terminatedPermanentPredictionDistributionArea[key]),
          }
        })
        .sort((prev, next) => next.value - prev.value)
      if (terminatedPermanentPredictionSubArrayArea.length > 3) {
        const terminatedPermanentPredictionOthers =
          terminatedPermanentPredictionSubArrayArea.slice(3).reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        terminatedPermanentPredictionSubArrayArea =
          terminatedPermanentPredictionSubArrayArea.slice(0, 3)
        terminatedPermanentPredictionSubArrayArea.push(terminatedPermanentPredictionOthers)
      }

      // Make ending contract distribution data into arrays, sort and finally compress to 4 classes
      let endingFixedConfirmedSubArrayMarketRent = Object.keys(endingFixedConfirmedDistributionMarketRent)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(endingFixedConfirmedDistributionMarketRent[key], { value: "market_rent" }),
          }
        })
        .sort((prev, next) => next.value - prev.value)
      if (endingFixedConfirmedSubArrayMarketRent.length > 3) {
        const endingFixedConfirmedOthers = endingFixedConfirmedSubArrayMarketRent
          .slice(3)
          .reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        endingFixedConfirmedSubArrayMarketRent = endingFixedConfirmedSubArrayMarketRent.slice(0, 3)
        endingFixedConfirmedSubArrayMarketRent.push(endingFixedConfirmedOthers)
      }

      let terminatedPermanentConfirmedSubArrayMarketRent = Object.keys(terminatedPermanentConfirmedDistributionMarketRent)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(terminatedPermanentConfirmedDistributionMarketRent[key], { value: "market_rent" }),
          }
        })
        .sort((prev, next) => next.value - prev.value)
      if (terminatedPermanentConfirmedSubArrayMarketRent.length > 3) {
        const terminatedPermanentConfirmedOthers =
          terminatedPermanentConfirmedSubArrayMarketRent.slice(3).reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        terminatedPermanentConfirmedSubArrayMarketRent =
          terminatedPermanentConfirmedSubArrayMarketRent.slice(0, 3)
        terminatedPermanentConfirmedSubArrayMarketRent.push(terminatedPermanentConfirmedOthers)
      }

      let endingFixedPredictionSubArrayMarketRent = Object.keys(endingFixedPredictionDistributionMarketRent)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(endingFixedPredictionDistributionMarketRent[key], { value: "market_rent" }),
          }
        })
        .sort((prev, next) => next.value - prev.value)
      if (endingFixedPredictionSubArrayMarketRent.length > 3) {
        const endingFixedPredictionOthers = endingFixedPredictionSubArrayMarketRent
          .slice(3)
          .reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        endingFixedPredictionSubArrayMarketRent = endingFixedPredictionSubArrayMarketRent.slice(
          0,
          3
        )
        endingFixedPredictionSubArrayMarketRent.push(endingFixedPredictionOthers)
      }

      let terminatedPermanentPredictionSubArrayMarketRent = Object.keys(terminatedPermanentPredictionDistributionMarketRent)
        .map((key) => {
          return {
            text: this.definitionById(parseInt(key)).label,
            value: calculateTotal(terminatedPermanentPredictionDistributionMarketRent[key], { value: "market_rent" }),
          }
        })
        .sort((prev, next) => next.value - prev.value)
      if (terminatedPermanentPredictionSubArrayMarketRent.length > 3) {
        const terminatedPermanentPredictionOthers =
          terminatedPermanentPredictionSubArrayMarketRent.slice(3).reduce(
            (acc, curr) => {
              acc.value += curr.value
              return acc
            },
            { text: this.$t('leasing.other'), value: 0 }
          )
        terminatedPermanentPredictionSubArrayMarketRent =
          terminatedPermanentPredictionSubArrayMarketRent.slice(0, 3)
        terminatedPermanentPredictionSubArrayMarketRent.push(terminatedPermanentPredictionOthers)
      }

      areaData.all.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      areaData.all.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }
      areaData.new.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }
      areaData.new.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      areaData.renegotiations.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      areaData.renewals.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }
      areaData.allRenegotiations.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      areaData.allRenegotiations.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }

      rentalTypeData.all.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      rentalTypeData.all.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }
      rentalTypeData.new.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }
      rentalTypeData.new.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      rentalTypeData.renegotiations.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      rentalTypeData.renewals.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }
      rentalTypeData.allRenegotiations.ending_fixed.subArrays = {
        confirmed: endingFixedConfirmedSubArrayArea,
        prediction: endingFixedPredictionSubArrayArea,
      }
      rentalTypeData.allRenegotiations.terminated_permanent.subArrays = {
        confirmed: terminatedPermanentConfirmedSubArrayArea,
        prediction: terminatedPermanentPredictionSubArrayArea,
      }

      // Add target data
      this.addTargetData(this.sites, areaData, rentalTypeData, targetDistributionArea)

      this.situationData = { area: areaData, rentalType: rentalTypeData, targetCoefficiency }
    },
    calculateClasses (sites, goals, type = null) {
      const values = []
      sites.forEach(site => {
        let goal = goals.get(site.id_site)
        let area = 0
        if (!goal) {
          goal = this.getDefaultGoals
        }
        if (type) {
          area = goal ? goal[type] : 0
        } else {
          area = (goal.renegotiation ?? 0) + (goal.renewal ?? 0)
        }
        const found = values.find((item) => item.class === site.site_classification)
        if (found == null) {
          values.push({
            class: site.site_classification,
            area,
          })
        } else {
          found.area += area
        }
      })
      return values
    },
    addLargestClasses (data, object, amount) {
      const colors = ['#4865ff', '#50d5ff', '#28cbb0', '#89f27d']
      for (let i = 0, n = Math.min(data.length, amount); i < n; i++) {
        object[data[i].class] = {
          order: i,
          text: this.definitionById(data[i].class).label,
          classifications: [data[i].class],
          color: colors[i],
          confirmed: [],
          prediction: [],
          target: [],
          currentFree: [],
        }
      }
    },
    addDataByClass (object, type, classification, rentalStatus = null, prospect = null, weight = null, carparkContract = null) {
      const found = object[classification]

      // If we have top four classification.
      if (found) {
        // No need to worry about type property. This is initialized in initDataObjects
        object[classification][type].push({ rentalStatus, prospect, weight, carparkContract })
        return
      }

      // Otherwise it belongs to others category.
      if (object.others[type]) {
        object.others[type].push({ rentalStatus, prospect, weight, carparkContract })
      } else {
        object.others[type] = [{ rentalStatus, prospect, weight, carparkContract }]
      }
    },
    addTargetData (sites, areaData, rentalTypeData, distribution) {
      const targets = []
      // Collect data by classification
      sites.forEach(site => {
        const classification = site.site_classification;
        const goal = this.goals.get(site.id_site)
        if (goal) {
          let found = targets.find(item => item.classification === classification)
          if (!found) {
            found = { classification, new_negotiation: goal.new_negotiation ?? 0, renegotiation: goal.renegotiation ?? 0, renewal: goal.renewal ?? 0 }
            targets.push(found)
          }
          else {
            found.new_negotiation += goal.new_negotiation ?? 0
            found.renegotiation += goal.renegotiation ?? 0
            found.renewal += goal.renewal ?? 0
          }
        }
      })

      targets.forEach(target => {
        const classification = target.classification

        // Renegotiation targets
        this.addDataByClass(

          areaData.renegotiations,
          'target',
          classification,
          { area: target.renegotiation }
        )

        areaData.renegotiations.total.target.push({ rentalStatus: { area: target.renegotiation }})

        this.addDataByClass(
          areaData.all,
          'target',
          classification,
          {area: target.renegotiation }
        )
        areaData.all.total.target.push({ rentalStatus: { area: target.renegotiation }})
        this.addDataByClass(
          areaData.allRenegotiations,
          'target',
          classification,
          {area: target.renegotiation }
        )
        areaData.allRenegotiations.total.target.push({ rentalStatus: { area: target.renegotiation }})

        this.addDataByClass(
          distribution,
          'renegotiations',
          classification,
          {area: target.renegotiation }
        )

        rentalTypeData.all.renegotiations.target.push({ rentalStatus: { area: target.renegotiation }})
        rentalTypeData.all.total.target.push({ rentalStatus: { area: target.renegotiation }})
        rentalTypeData.allRenegotiations.renegotiations.target.push({ rentalStatus: { area: target.renegotiation }})
        rentalTypeData.allRenegotiations.total.target.push({ rentalStatus: { area: target.renegotiation }})
        rentalTypeData.renegotiations.renegotiations.target.push({ rentalStatus: { area: target.renegotiation }})


        // Renewal targets
        this.addDataByClass(
          areaData.renewals,
          'target',
          classification,
          { area: target.renewal }
        )
        areaData.renewals.total.target.push({ rentalStatus: { area: target.renewal }})
        this.addDataByClass(
          areaData.all,
          'target',
          classification,
          { area: target.renewal }
        )
        areaData.all.total.target.push({ rentalStatus: { area: target.renewal }})
        this.addDataByClass(
          areaData.allRenegotiations,
          'target',
          classification,
          { area: target.renewal }
        )
        areaData.allRenegotiations.total.target.push({ rentalStatus: { area: target.renewal }})

        this.addDataByClass(
          distribution,
          'renewals',
          classification,
          { area: target.renewal }
        )

        rentalTypeData.all.renewals.target.push({ rentalStatus: { area: target.renewal }})
        rentalTypeData.all.total.target.push({ rentalStatus: { area: target.renewal }})
        rentalTypeData.allRenegotiations.renewals.target.push({ rentalStatus: { area: target.renewal }})
        rentalTypeData.allRenegotiations.total.target.push({ rentalStatus: { area: target.renewal }})
        rentalTypeData.renewals.renewals.target.push({ rentalStatus: { area: target.renewal }})

        // New negotiation targets
        this.addDataByClass(
          areaData.new,
          'target',
          classification,
          { area: target.new_negotiation }
        )
        areaData.new.total.target.push({ rentalStatus: { area: target.new_negotiation }})
        this.addDataByClass(
          areaData.all,
          'target',
          classification,
          { area: target.new_negotiation }
        )
        areaData.all.total.target.push({ rentalStatus: { area: target.new_negotiation }})
        // Also add data for targetDistribution
        this.addDataByClass(
          distribution,
          'new',
          classification,
          { area: target.new_negotiation }
        )

        rentalTypeData.all.new.target.push({ rentalStatus: { area: target.new_negotiation }})
        rentalTypeData.all.total.target.push({ rentalStatus: { area: target.new_negotiation }})
        rentalTypeData.new.new.target.push({ rentalStatus: { area: target.new_negotiation }})
      
      Object.keys(distribution).forEach((item) => {
        const itemNew = calculateTotal(distribution[item].new)
        const itemRenegotiations = calculateTotal(distribution[item].renegotiations)
        const itemRenewals = calculateTotal(distribution[item].renewals)

        areaData.all[item].subArrays = {
          target: [
            {
              text: this.$t('Totals'),
              value: itemNew + itemRenegotiations + itemRenewals,
            },
            {
              text: this.$t('leasing.new_negotiation'),
              value: itemNew,
            },
            {
              text: this.$t('leasing.all_renegotiations'),
              value: itemRenegotiations + itemRenewals,
            },
            {
              text: this.$t('leasing.renegotiations_fixed'),
              value: itemRenegotiations,
            },
            {
              text: this.$t('leasing.renewals_permanent'),
              value: itemRenewals,
            },
          ],
          confirmed: [],
          prediction: [],
          currentFree: [],
        }
      })
      })

    },

    addDistributionData (object, classification, rentalStatus = null, prospect = null, weight = null) {
      const found = object[classification]

      if (found) {
        object[classification].push({ rentalStatus, prospect, weight })
      } else {
        object[classification] = [{ rentalStatus, prospect, weight }]
      }
    },
    getQuarterDataDebounced: _debounce((that) => that.getQuarterData(), 1000),
    getQuarterData () {
      // No selection
      if (this.upperBoundQuarterDates.length === 0) {
        this.getQuarterlyUsage(this.currentDate)
        this.fetchAllGoals(this.currentDate)
        this.fetchCarparkOccupancyRates()
        this.getCapitalOccupancy(this.currentDate)
        this.getOccupancyRateProgress({time: this.currentDate})
      // Quarter, period and year selections
      } else {
        const endDate = this.upperBoundQuarterDates[this.upperBoundQuarterDates.length - 1]

        this.getQuarterRentalStatuses(endDate)
        this.getQuarterlyUsage(endDate)
        this.fetchCarparkOccupancyRates(null, endDate)
        this.getCapitalOccupancy(endDate)
        this.getOccupancyRateProgress({time: endDate})
      }
      if (this.lowerBoundQuarterDates.length > 0) {
        this.getSituationAndTargetData({siteIds: this.siteIds, startDate: this.lowerBoundQuarterDates[0], endDate: this.upperBoundQuarterDates[this.upperBoundQuarterDates.length - 1]})
        this.getLeaseGoals({ siteIds: this.siteIds, time: this.lowerBoundQuarterDates[0] })
        this.getNewContracts(this.lowerBoundQuarterDates[0])
        this.getNewCarparkContracts(this.lowerBoundQuarterDates[0])
        this.fetchAllGoals(this.lowerBoundQuarterDates[0])
      }
      else {
        const startOfYear = new Date(moment(this.currentDate).utc(true).startOf('year'))
        this.getSituationAndTargetData({siteIds: this.siteIds, startDate: startOfYear})
        this.getLeaseGoals({ siteIds: this.siteIds, time: startOfYear})
        this.getNewContracts(startOfYear)
        this.getNewCarparkContracts(startOfYear)
      }
    },
    async getQuarterRentalStatuses (time) {
      this.quarterRentalStatusesLoading = true
      const api = this.$rambollfmapi

      const buildingIds = this.getBuildingIds()
      if (buildingIds.length > 0) {
        this.quarterRentalStatuses = await api.reports.post('rentalstatus', buildingIds, time)
        this.futureQuarterRentalStatuses = await api.reports.post('futurerentalstatus', buildingIds, time)
      }
      this.quarterRentalStatusesLoading = false
    },
    async getBuildingIds () {
      let { buildings } = this

      if (buildings.length === 0) {
        const api = this.$rambollfmapi
        buildings = await api.buildings.list()
      }

      return buildings.map(b => b.id_building)
    },
    filterContractsByQuarters (rentalStatuses, field, lowerBounds, upperBounds) {
      const filtered = []
      // If lower bound quarters exists, filter by quarters
      if (lowerBounds.length > 0) {
        rentalStatuses.forEach(rs => {
          const matched = lowerBounds.some((bound, i) => {
            return new Date(rs[field]) >= bound && new Date(rs[field]) <= upperBounds[i]
          });

          if (matched) {
            filtered.push(rs)
          }
        })
      } else {
        const year = lowerBounds[0]?.getFullYear() || new Date().getFullYear()

        const startOfYear = new Date(moment().set('year', year)
          .startOf('year'))
        const endOfYear = new Date(moment().set('year', year)
          .endOf('year'))

        rentalStatuses.forEach(rs => {
          let matched = false
          matched = new Date(rs[field]) > startOfYear && new Date(rs[field]) < endOfYear
          if (matched) {
            filtered.push(rs)
          }
        })
      }
      return filtered
    },
    leasingProgressData (closedContracts, prospects, pastRentalStatuses, currentRentalStatuses, futureRentalStatuses) {
      // We should group the prospects by contract id for more efficient search later on:
      const prospectObjects = prospects.reduce((acc, cur) => {

        const { contract_numbers } = cur

        if(!contract_numbers || contract_numbers.length === 0){
          return acc
        } 

        contract_numbers.forEach(contractNumber => {
          if(!acc[contractNumber]){
            acc[contractNumber] = cur
          }
        })


        return acc
      }, {})

      const siteIds = this.sites.filter(item => item.ownership_status === 'Omistuksessa').map(item => item.id_site)
      const siteFiltered = closedContracts.filter(rs => siteIds.includes(rs.siteId))
      const contractRows = []
      // Sort rentalstatuses to new rows based on contract numbers
      siteFiltered.forEach(rs => {
        const index = contractRows.findIndex((row) => row.contractNumber === rs.contractNumber)

        if (index >= 0) {
          contractRows[index].units.push({ id: rs.unitId, unit_code: rs.unitName })
          return
        }

        const newRow = {
          signature_date: rs.signature_date,
          total_area: 0,
          total_rent: 0,
          siteName: rs.siteName,
          tenant: rs.curPartyName,
          rental_type: '', // Will be filled later
          contractNumber: rs.contractNumber,
          id_site: rs.siteId,
          units: [{ id: rs.unitId, unit_code: rs.unitName }],
          contractId: rs.contractId,
        }

        const prospect = prospectObjects[rs.contractNumber]
        // If we have prospect for this contract, let's add the area and euros from there to the renting info. 
        if (prospect) {
          newRow.id_prospect = prospect.id_prospect

          const typeInfo = getProspectType(prospect)

          newRow.rental_type = typeInfo.typeText
          newRow.rental_type_translated = this.$t(typeInfo.typeText)

          const index = prospect.contract_info.findIndex(ci => ci.contractNumber === rs.contractNumber)
          if (index >= 0) {
            newRow.total_area = prospect.contract_info[index].reportableArea || 0
            newRow.total_rent = prospect.contract_info[index].capitalRent || 0
          } else {
            newRow.total_area = 0
            newRow.total_rent = 0
          }
        }

        contractRows.push(newRow)
      })

      const selectedYear = this.upperBoundQuarterDates[0]?.getUTCFullYear() || new Date(this.currentDate).getFullYear()

      const data = []
      let date = moment(this.currentDate)
      let quarter = 1
      let year = selectedYear
      let dateString = date.format('D.M.YYYY')
      // If inspection year is current year and no quarters are selected, show data up to today
      if (this.upperBoundQuarterDates.length === 0) {
        date = moment(this.currentDate)
        quarter = date.get('quarter')
      // Otherwise show data up to chosen last quarter
      } else {
        date.set('year', year)
        if (this.upperBoundQuarterDates.length > 0) {
          const month = this.upperBoundQuarterDates[this.upperBoundQuarterDates.length - 1].getUTCMonth()
          date.set('month', month)
          date.endOf('month')
        } else {
          date.endOf('year')
        }
        quarter = date.get('quarter')
        dateString = `Q${quarter} ${year}`
      }
      // Last bar data
      const startOfQuarter = moment(this.currentDate)
      startOfQuarter.set('year', year)
      startOfQuarter.set('quarter', quarter)
      startOfQuarter.startOf('quarter')
      let quarterData = {
        quarter: dateString,
        startDate: new Date(startOfQuarter),
        endDate: new Date(date),
        rentedNew: 0,
        rentedRenegotiations: 0,
        rentedRenewals: 0,
        marketRentNew: 0,
        marketRentRenegotiations: 0,
        marketRentRenewals: 0,
        contractsNew: 0,
        contractsRenegotiations: 0,
        contractsRenewals: 0,
        endedPermanentArea: 0,
        endedPermanentMarketRent: 0,
        endedPermanentContractsSet: new Set(),
        endedPermanentContracts: 0,
        endedFixedTermArea: 0,
        endedFixedTermMarketRent: 0,
        endedFixedTermContractsSet: new Set(),
        endedFixedTermContracts: 0,
      }
      data.push(quarterData)

      const endOfQuarter = moment()

      // Add nine quarters
      for (let i = 0; i < 9; i++) {
        quarter--
        if (quarter === 0) {
          quarter = 4
          year--
        }

        endOfQuarter.set('year', year)
        endOfQuarter.set('quarter', quarter)
        endOfQuarter.endOf('quarter')
        startOfQuarter.set('year', year)
        startOfQuarter.set('quarter', quarter)
        startOfQuarter.startOf('quarter')
        quarterData = {
          quarter: `Q${endOfQuarter.get('quarter')} ${endOfQuarter.get('year')}`,
          startDate: new Date(startOfQuarter),
          endDate: new Date(endOfQuarter),
          rentedNew: 0,
          rentedRenegotiations: 0,
          rentedRenewals: 0,
          marketRentNew: 0,
          marketRentRenegotiations: 0,
          marketRentRenewals: 0,
          contractsNew: 0,
          contractsRenegotiations: 0,
          contractsRenewals: 0,
          endedPermanentArea: 0,
          endedPermanentMarketRent: 0,
          endedPermanentContractsSet: new Set(),
          endedPermanentContracts: 0,
          endedFixedTermArea: 0,
          endedFixedTermMarketRent: 0,
          endedFixedTermContractsSet: new Set(),
          endedFixedTermContracts: 0
        }
        data.unshift(quarterData)
      }

      contractRows.forEach(rs => {
        const signatureDate = new Date(rs.signature_date)
        const foundQuarter = data.find(item => item.startDate <= signatureDate && signatureDate <= item.endDate)
        if (foundQuarter != null) {
          this.addPositiveQuarterData(rs, foundQuarter)
        }
      })

      const endedContracts = pastRentalStatuses.concat(currentRentalStatuses).concat(futureRentalStatuses).filter(rs => siteIds.includes(rs.siteId) && rs.validity !== null)
      endedContracts.forEach(rs => {
        const endDate = new Date(rs.curPartyEndDate)
        const foundQuarter = data.find(item => item.startDate <= endDate && endDate <= item.endDate)
        if (foundQuarter != null) {
          this.addNegativeQuarterData(rs, foundQuarter)
        }
      })
      data.forEach(qd => {
        qd.endedFixedTermContracts = - qd.endedFixedTermContractsSet.size
        qd.endedPermanentContracts = - qd.endedPermanentContractsSet.size
      })
      return data
    },
    addPositiveQuarterData (rentalStatus, quarterData) {
      if (rentalStatus.rental_type === 'leasing.renegotiations') {
        quarterData.rentedRenegotiations += rentalStatus.total_area
        quarterData.marketRentRenegotiations += rentalStatus.total_rent
        quarterData.contractsRenegotiations++
      } else if (rentalStatus.rental_type === 'leasing.renewal') {
        quarterData.rentedRenewals += rentalStatus.total_area
        quarterData.marketRentRenewals += rentalStatus.total_rent
        quarterData.contractsRenewals++
      } else if (rentalStatus.rental_type === 'leasing.new_negotiation') {
        quarterData.rentedNew += rentalStatus.total_area
        quarterData.marketRentNew += rentalStatus.total_rent
        quarterData.contractsNew++
      }
    },
    addNegativeQuarterData (rentalStatus, quarterData) {
      const area = rentalStatus.area
      const marketRent = area * (rentalStatus.market_rent ?? 0)
      if (rentalStatus.validity === 'toistaiseksi voimassaoleva') {
        quarterData.endedPermanentArea -= area
        quarterData.endedPermanentMarketRent -= marketRent
        quarterData.endedPermanentContractsSet.add(rentalStatus.contractNumber)
      }
      else if (rentalStatus.validity === 'määräaikainen') {
        quarterData.endedFixedTermArea -= area
        quarterData.endedFixedTermMarketRent -= marketRent
        quarterData.endedFixedTermContractsSet.add(rentalStatus.contractNumber)
      }
    },
    quarterlyOccupancyRates (quarterlyUsage) {
      const data = []
      const siteIds = this.sites.filter(item => item.ownership_status === 'Omistuksessa').map(item => item.id_site)
      quarterlyUsage.forEach((usage, index) => {
        // Last quarter is special case in terms of dateString
        let dateString = ''

        if (index === 0 && (this.lowerBoundQuarterDates.length === 0)) {
          dateString = moment(usage.date).format('D.M.YYYY')
        } else {
          const quarterNumber = moment(usage.date).get('quarter')
          const year = moment(usage.date).get('year')
          dateString = `Q${quarterNumber} ${year}`
        }
        const occupancyRates = usage.site_occupancy_rates.filter(or => siteIds.includes(or.id_site))
        let rented = 0
        let total = 0
        occupancyRates.forEach(siteOccupancyRate => {
          rented += siteOccupancyRate.unit_usage_occupancy_rates.reduce((acc, curr) => {
            return acc + curr.rented_area
          }, 0)
          total += siteOccupancyRate.unit_usage_occupancy_rates.reduce((acc, curr) => {
            return acc + curr.total_area
          }, 0)
        })
        data.unshift({
          quarter: dateString,
          rented: humanize.thousand_separator(100 * rented / total, 2),
          marketRent: humanize.thousand_separator(100 * rented / total, 2),
          contracts: humanize.thousand_separator(100 * rented / total, 2),
        })
      })
      return data
    },
    getMarketRent (rs) {
      let { area } = rs

      let marketRent = 0

      if (!area) {
        area = 0
      }

      if (rs.market_rent) {
        marketRent = rs.market_rent
      }

      return area * marketRent
    },
    createDataObject (order, text, color = null, showGraph = false) {
      const object = {
        order,
        text,
        color,
        confirmed: [],
        prediction: [],
        target: [],
        currentFree: [],
        showGraph,
      }
      return object
    },
    initDatapoints (areaData, rentalTypeData, allClassifications, renegotiationsClassifications, renewalsClassifications, currentFreeSiteClassifications, allRenegotiationsClassifications) {
      let highestOrder = 0
      highestOrder = Math.min(allClassifications.length, 4)
      const allClassificationsIds = allClassifications.map(item => item.class)
      // Get rest of the classifications ids for others-category
      const otherClassifications = allClassifications.slice(4).map(item => item.class)
      // Add all data points, set null where not shown

      areaData.all.others = this.createDataObject(highestOrder, 'leasing.other', '#3f3f3f')
      areaData.all.others.classifications = otherClassifications

      areaData.all.total = this.createDataObject(highestOrder + 1, 'Totals', '#707070')
      areaData.all.total.classifications = allClassificationsIds

      areaData.all.ending_fixed = this.createDataObject(highestOrder + 2, 'leasing.ending_fixed', '#bf1ef8')

      areaData.all.terminated_permanent = this.createDataObject(highestOrder + 3, 'leasing.terminated_permanent', '#ff0000')

      areaData.all.ending_total = this.createDataObject(highestOrder + 4, 'leasing.ending_total')

      areaData.all.net_total = this.createDataObject(highestOrder + 5, 'leasing.net_total', '#ff0000')

      rentalTypeData.all.new = this.createDataObject(1, 'leasing.new_negotiation', '#4caf50', true)
      rentalTypeData.all.renegotiations = this.createDataObject(2, 'leasing.renegotiations', '#2A74B5', true)
      rentalTypeData.all.renewals = this.createDataObject(3, 'leasing.renewal', '#f389d6', true)
      rentalTypeData.all.total = this.createDataObject(4, 'Totals', '#707070', false)
      rentalTypeData.all.total.classifications = allClassificationsIds
      rentalTypeData.all.ending_fixed = this.createDataObject(5, 'leasing.ending_fixed', '#bf1ef8', true)
      rentalTypeData.all.terminated_permanent = this.createDataObject(6, 'leasing.terminated_permanent', '#ff0000', true)
      rentalTypeData.all.ending_total = this.createDataObject(7, 'leasing.ending_total', false)
      rentalTypeData.all.net_total = this.createDataObject(8, 'leasing.net_total', '#ff0000', false)

      highestOrder = Math.min(renegotiationsClassifications.length, 4)

      areaData.renegotiations.others = this.createDataObject(highestOrder, 'Other', '#3f3f3f')

      areaData.renegotiations.total = this.createDataObject(highestOrder + 1, 'Totals', '#707070')
      areaData.renegotiations.total.classifications = allClassificationsIds

      areaData.renegotiations.ending_fixed = this.createDataObject(highestOrder + 2, 'leasing.ending_fixed', '#bf1ef8')

      rentalTypeData.renegotiations.renegotiations = this.createDataObject(1, 'leasing.renegotiations', '#2A74B5', true)
      rentalTypeData.renegotiations.ending_fixed = this.createDataObject(2, 'leasing.ending_fixed', '#bf1ef8', true)

      areaData.renegotiations.startTarget, rentalTypeData.renegotiations.startTarget = 0

      highestOrder = Math.min(renewalsClassifications.length, 4)

      areaData.renewals.others = this.createDataObject(highestOrder, 'Other', '#3f3f3f')

      areaData.renewals.total = this.createDataObject(highestOrder + 1, 'Totals', '#707070')
      areaData.renewals.total.classifications = allClassificationsIds

      areaData.renewals.terminated_permanent = this.createDataObject(highestOrder + 2, 'leasing.terminated_permanent', '#ff0000')

      rentalTypeData.renewals.renewals = this.createDataObject(3, 'leasing.renewal', '#f389d6', true)
      rentalTypeData.renewals.terminated_permanent = this.createDataObject(3, 'leasing.terminated_permanent', '#ff0000', true)

      areaData.renewals.startTarget, rentalTypeData.renewals.startTarget  = 0

      highestOrder = Math.min(currentFreeSiteClassifications.length, 4)

      areaData.new.others = this.createDataObject(highestOrder, 'Other', '#3f3f3f')

      areaData.new.total = this.createDataObject(highestOrder + 1, 'Totals', '#707070')
      areaData.new.total.classifications = allClassificationsIds

      areaData.new.ending_fixed = this.createDataObject(highestOrder + 2, 'leasing.ending_fixed', '#bf1ef8')

      areaData.new.terminated_permanent = this.createDataObject(highestOrder + 3, 'leasing.terminated_permanent', '#ff0000')

      areaData.new.ending_total = this.createDataObject(highestOrder + 4, 'leasing.ending_total')

      areaData.new.net_total = this.createDataObject(highestOrder + 5, 'leasing.net_total', '#ff0000')

      rentalTypeData.new.new = this.createDataObject(1, 'leasing.new_negotiation', '#4caf50', true)
      rentalTypeData.new.ending_fixed = this.createDataObject(2, 'leasing.ending_fixed', '#bf1ef8', true)
      rentalTypeData.new.terminated_permanent = this.createDataObject(3, 'leasing.terminated_permanent', '#ff0000', true)
      rentalTypeData.new.ending_total = this.createDataObject(4, 'leasing.ending_total', false)
      rentalTypeData.new.net_total = this.createDataObject(5, 'leasing.net_total', '#ff0000', false)

      areaData.new.startTarget, rentalTypeData.new.startTarget = 0
      areaData.new.startCurrentFree, rentalTypeData.new.startCurrentFree = 0

      highestOrder = Math.min(allRenegotiationsClassifications.length, 4)

      areaData.allRenegotiations.others = this.createDataObject(highestOrder, 'Other', '#3f3f3f')

      areaData.allRenegotiations.total = this.createDataObject(highestOrder + 1, 'Totals', '#707070')
      areaData.allRenegotiations.total.classifications = allClassificationsIds

      areaData.allRenegotiations.ending_fixed = this.createDataObject(highestOrder + 2, 'leasing.ending_fixed', '#bf1ef8')

      areaData.allRenegotiations.terminated_permanent = this.createDataObject(highestOrder + 3, 'leasing.terminated_permanent', '#ff0000')

      areaData.allRenegotiations.ending_total = this.createDataObject(highestOrder + 4, 'leasing.ending_total')

      rentalTypeData.allRenegotiations.renegotiations = this.createDataObject(2, 'leasing.renegotiations', '#2A74B5', true)
      rentalTypeData.allRenegotiations.renewals = this.createDataObject(3, 'leasing.renewal', '#f389d6', true)
      rentalTypeData.allRenegotiations.total = this.createDataObject(4, 'Totals', '#707070', false)
      rentalTypeData.allRenegotiations.total.classifications = allClassificationsIds
      rentalTypeData.allRenegotiations.ending_fixed = this.createDataObject(5, 'leasing.ending_fixed', '#bf1ef8', true)
      rentalTypeData.allRenegotiations.terminated_permanent = this.createDataObject(6, 'leasing.terminated_permanent', '#ff0000',  true)
      rentalTypeData.allRenegotiations.ending_total = this.createDataObject(7, 'leasing.ending_total', false)

      areaData.allRenegotiations.startTarget, rentalTypeData.allRenegotiations.startTarget = 0
    },

    mostLeasedBySite (closedContracts, prospects) {
      const prospectObjects = prospects.reduce((acc, cur) => {

        const { contract_numbers } = cur

        if(!contract_numbers || contract_numbers.length === 0){
          return acc
        } 

        contract_numbers.forEach(contractNumber => {
          if(!acc[contractNumber]){
            acc[contractNumber] = cur
          }
        })


        return acc
      }, {})

      const siteIds = this.sites.map(item => item.id_site)
      let filteredContracts = closedContracts.filter(rs => siteIds.includes(rs.siteId))
      // Filter contracts by quarter
      filteredContracts = this.filterContractsByQuarters(filteredContracts, 'signature_date', this.lowerBoundQuarterDates, this.upperBoundQuarterDates)
      const contractRows = []
      // Sort rentalstatuses to new rows based on contract numbers
      filteredContracts.forEach(rs => {
        const index = contractRows.findIndex((row) => row.contractNumber === rs.contractNumber)

        if (index >= 0) {
          contractRows[index].units.push({ id: rs.unitId, unit_code: rs.unitName })
          return
        }
        const newRow = {
          signature_date: rs.signature_date,
          total_area: 0,
          total_rent: 0,
          siteName: rs.siteName,
          tenant: rs.curPartyName,
          rental_type: '', // Will be filled later
          contractNumber: rs.contractNumber,
          id_site: rs.siteId,
          units: [{ id: rs.unitId, unit_code: rs.unitName }],
          contractId: rs.contractId,
        }
        const prospect = prospectObjects[rs.contractNumber]

        if (prospect) {
          newRow.id_prospect = prospect.id_prospect

          const typeInfo = getProspectType(prospect)

          newRow.rental_type = typeInfo.typeText

          const index = prospect.contract_info.findIndex(ci => ci.contractNumber === rs.contractNumber)
          if (index >= 0) {
            newRow.total_area = prospect.contract_info[index].reportableArea || 0
            newRow.total_rent = prospect.contract_info[index].capitalRent || 0
          } else {
            newRow.total_area = 0
            newRow.total_rent = 0
          }
        }

        contractRows.push(newRow)
      })

      return contractRows
    },
    mostTerminatedBySite (rentalStatuses, pastRentalStatuses) {
      const siteIds = this.sites.map(item => item.id_site)
      const filteredContracts = rentalStatuses
        .concat(pastRentalStatuses)
        .filter(rs => siteIds.includes(rs.siteId))

      // Uudet irtisanomiset (oletus)
      //    ne toistaiseksi voimassa olevat sopimukset, joihin tullut päättymispäivämäärä (sen mukaan mitä irtisanomiset widgetissä)
      // rs with termination_date property set

      // Päättyneet sopimukset
      //    Tässä näkymässä palkki jaettu väreittäin kahteen osaan; Määräaikaiset ja toistaiseksi voimassa olevat
      // rs with curPartyEndDate property set and in the selected time range.
      const data = filteredContracts.map(rs => {
        const {
          siteName,
          area,
          validity,
          market_rent: marketRent,
          termination_date: terminationDate,
          curPartyEndDate,
        } = rs

        // First set will filter by this value.
        let terminated = false

        const lowerCasedValidity = validity ? validity.toLowerCase() : null

        if (lowerCasedValidity === 'toistaiseksi voimassaoleva' && terminationDate !== null) {
          terminated = isInQuarterSelection(terminationDate, this.lowerBoundQuarterDates, this.upperBoundQuarterDates)
        }

        // Second set will filter by this value
        let ended = false

        // Only until further notice or fixed term contracts.
        if ((lowerCasedValidity === 'toistaiseksi voimassaoleva' || lowerCasedValidity === "määräaikainen") && curPartyEndDate) {
          ended = isInQuarterSelection(curPartyEndDate, this.lowerBoundQuarterDates, this.upperBoundQuarterDates)
        }

        // Forming the stackByValue.
        let rentalType = null

        if (lowerCasedValidity === 'toistaiseksi voimassaoleva') {
          rentalType = "Permanent"
        } else if (lowerCasedValidity === "määräaikainen") {
          rentalType = "Fixed term"
        }

        const totalRent = area * (marketRent ?? 0)

        return {
          siteName,
          terminated,
          ended,
          rentalType,
          total_area: area,
          total_rent: totalRent,
        }
      })

      return data
    },
  },
}
</script>

<style scoped></style>
