<template>
  <v-dialog
    v-model="addAllocationCode"
    width="550"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        fab
        small
        outlined
        color="blue"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </template>

    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ $t('New allocation code') }}
      </template>
      <template #content>
        <v-form
          v-model="isFormValid"
          @submit.prevent="addCode"
        >
          <v-text-field
            v-model="inputName"
            :label="$t('Name')"
            :rules="[rules.counter, rules.uniqueName, rules.required]"
            class="form-fields"
            required
            counter
            maxlength="20"
          />
        </v-form>
      </template>
      <template #footer>
        <v-btn
          rounded
          type="submit"
          color="primary"
          depressed
          :disabled="!isFormValid"
          @click="addCode()" 
        >
          {{ $t('Add') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>
<script>
import BaseModal from '.././general/BaseModal.vue'
export default {
  emits: ['addCode'],
  components: {
    BaseModal,
  },
  props: {
    allocationCodes: {
      default () { return [] },
      type: Array
    }
  },
  data () {
    return {
      allocationCode: null,
      addAllocationCode: false,
      inputName: "",
      isFormValid: false,
      rules: {
        counter: value => value.length <= 20 || this.$t('Max name length 20'),
        required: value => !!value || this.$t('Required'),
        uniqueName: value => {
          let found = false
          this.allocationCodes.forEach(el => {
            if(el.label === value) found = true
          })
          return !found
          || this.$t('Unique name')
        }

      }
    }
  },
 methods: {
    addCode (level)
    {
      this.addAllocationCode = false
      this.$emit('addCode', this.inputName)
      this.inputName = ""
    },
    cancel () {
      this.addAllocationCode = false
      this.inputName = ""
    }
  }
}
</script>