<template>
  <v-btn
    :class="[`c-btn c-btn--${size} c-btn--${type}`, {'c-btn--hideText': hideText}]"
    :disabled="disabled || loading"
    :block="block"
    :size="size"
    rounded
    depressed
    :outlined="withOutline"
    @click="handleClick"
  >
    <v-icon
      v-if="iconLeft && !loading"
      :class="hideText ? 'only-icon' : ''"
      :left="!hideText"
      :size="size === 'small' ? 16 : 24"
    >
      {{ iconLeft }}
    </v-icon>
    <span
      v-if="!loading"
      :class="[{'d-sr-only': hideText}, {'link-text': type === 'link'}]"
    >
      <slot />
    </span>
    <span
      v-else
      class="c-btn__loading"
    >
      <v-progress-circular
        :size="20"
        :width="3"
        :aria-label="loadingText"
        indeterminate
      />
      <span
        class="c-btn__loading-text"
        :class="[{'d-sr-only': hideText}]"
      >
        {{ loadingText }}
      </span>
    </span>
    <v-icon
      v-if="iconRight && !loading"
      :class="hideText ? 'only-icon' : ''"
      :right="!hideText"
    >
      {{ iconRight }}
    </v-icon>
  </v-btn>
</template>
<script>
    export default {
        name: 'CdsButton',
        props: {
            type: {
                type: String,
                default: 'primary'
            },
            size: {
                type: String,
                default: 'medium'
            },
            iconRight: {
                type: String,
                default: ''
            },
            iconLeft: {
                type: String,
                default: ''
            },
            hideText: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            loadingText: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            block: {
                type: Boolean,
                default: false
            },
            withOutline: {
                type: Boolean,
                default: false
            },
        },
        emits: ['click'],
        methods: {
            handleClick () {
                this.$emit('click', event);
            }
        }
    }
</script>

<style lang="scss" scoped>

// Size
.c-btn--small {
    height: 24px !important;
    font-size: 12px;
    padding: 0 12px !important;
}

.c-btn--medium {
    height: 36px !important;
    font-size: 14px;
}

.c-btn--large {
    height: 56px !important;
    font-size: 16px;
}

// If HideText is true
.c-btn--hideText {
    aspect-ratio: 1 / 1;
    min-width: 0 !important;
}

// Type
.c-btn--primary {
    color: var(--r-color-white) !important;
    background: var(--c-color-accent) !important;
}

.c-btn--accent {
    color: var(--r-color-white) !important;
    background: var(--r-color-ocean-100) !important;
}

.c-btn--secondary {
    color: var(--r-color-ocean-100);
    background: var(--r-color-primary-20) !important;
}

.c-btn--tertiary {
    color: var(--r-color-grey-100);
    background: var(--r-color-grey-10) !important;
}

.c-btn--link {
    color: var(--c-color-accent);
    background: var(--r-color-white) !important;
    text-transform: none !important;
}

.c-btn__link-text {
    text-decoration: underline;
}

.c-btn--danger {
    color: var(--r-color-danger-100);
    background: var(--r-color-danger-20) !important;
}

.c-btn--success {
    color: var(--r-color-success-100);
    background: var(--r-color-success-40) !important;
}

</style>