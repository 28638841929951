<template>
  <v-row
    class="dashboard"
  >
    <v-col
      v-for="widget in widgets"
      :key="widget.id"
      :lg="widget.small ? 3 : 6"
      lg="12"
      cols="12"
    >
      <DynamicWidget
        v-if="widget.id != null"
        :type="widget.type"
        :title="$t(widget.id)"
        :data="widget.data"
        :loading="widgetLoading(widget.id)"
        :maximized="widget.maximized"
      />
    </v-col>
  </v-row>
</template>
<script>

import { mapState, mapGetters } from 'vuex'
import DynamicWidget from '../../DynamicWidget.vue'
import { LeasePlan } from '../../../widgets/leasing/leasePlan.js'
import { calculateUsageRate } from '../../../helpers/leasing/occupancyRates.js'

const leasePlanWidgetData = (sites, portfolios, rolesBySite, summariesBySite) => {

  return sites.map(site => {

    const fund = portfolios.find(portfolio => portfolio.id === site.portfolio)
    const roles = rolesBySite[site.id_site]
    let leasingSummaries = {}
    let usageRates = { }

    if (summariesBySite[site.id_site]) {
      leasingSummaries = summariesBySite[site.id_site]
      usageRates = calculateUsageRate(leasingSummaries)
    }

    return {
      ...leasingSummaries,
      ...site,
      ...usageRates,
      fund: fund?.label,
      siteManager: roles ? roles.Kohdevastaava : null,
      employeeLiability: roles ? roles['Vuokravastuu (työntekijä)'] : null,
      partnerLiability: roles ? roles['Vuokrausvastuu (yhteistyökumppani)'] : null,
    }
  })
}

export default {
  name: 'LeasePlan',
  components: {
    DynamicWidget,
  },
  props: {
    partyLinks: {
      type: Array,
      default: () => []
    },
    partyLinksLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      widgets: [LeasePlan()]
    }
  },
  computed: {
    ...mapState('app', [
      'sites',
      'sitesLoading',
      'portfolios'
    ]),
    ...mapState('leasing', [
      'leasingSummaries',
      'leasingSummariesLoading',
    ]),
    ...mapGetters('app', ['definitionsByGroupLabel']),
    // Form lookup for each party role by site id.
    rolesBySite () {

      const roles = [...this.definitionsByGroupLabel('role'), ...this.definitionsByGroupLabel('site.responsibility')]

      const rolesBySite = this.partyLinks.reduce((acc, link) => {

        const def = roles.find(role => role.id === link.idDefinition)

        if (!def) {
          return acc
        }

        let roleObj = acc[link.idSite]

        if (!roleObj) {
          roleObj = {}
          acc[link.idSite] = roleObj
        }

        if (!roleObj[def.label]) {
          roleObj[def.label] = link.partyName
        } else {
          roleObj[def.label] += `, ${link.partyName}`
        }

        return acc

      }, {})

      return rolesBySite
    },
    summariesBySite () {
      return this.leasingSummaries.reduce((acc, cur) => {
        acc[cur.id_site] = cur
        return acc
      }, {})
    }

  },
  watch: {
    sites () {
      this.loadWidgetData()
    },
    partyLinks () {
      this.loadWidgetData()
    },
    leasingSummaries () {
      this.loadWidgetData()
    }
  },
  mounted () {
    this.loadWidgetData()
  },
  methods: {
    loadWidgetData () {
      const { widgets } = this
      widgets.forEach(widget => {
        // First load any extra data widget might need (dynamic sets/headers, extra data)
        if (widget.data.dynamicHeaders) {
          widget.data.headers = this.dataHeadersForWidget(widget.id)
        }
        if (widget.data.dynamicSets) {
          widget.data.sets = this.dataSetsForWidget(widget.id)
        }
        if (widget.data.lineItems) {
          widget.data.lineItems = this.extraDataForWidget(widget.id)
        }
        if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }

        widget.data.items = this.dataForWidget(widget.id)
        // Append extra filter options by source data
        if(widget.data.dynamicFilterOptions && widget.data.items.length) {
          widget.data.filter.options = this.filterOptionsForWidget(widget.id, widget.data)
        }
      })
    },
    legendForWidget (id) {
      try {
        switch (id) {
          default:
            return null
        }
      } catch (err) {
        this.$log.error(`Error trying to process filters for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return null
      }
    },
    widgetLoading (id) {
      switch (id) {
        case 'leasing.lease_plan':
          return this.sitesLoading || this.partyLinksLoading || this.leasingSummariesLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }

        switch (id) {
          case 'leasing.lease_plan':
            return leasePlanWidgetData(this.sites, this.portfolios, this.rolesBySite, this.summariesBySite)
          default:
            return []
        }
      } catch (err) {
        this.$log.error(`Error trying to process data for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    dataHeadersForWidget (id) {
      try {
        switch (id) {
          default:
            return {}
        }
      } catch (err) {
        this.$log.error(`Error trying to process headers for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return {}
      }
    },
    dataSetsForWidget (id) {
      try {
        switch (id) {
          default:
            return []
        }
      } catch (err) {
        this.$log.error(`Error trying to process sets for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    extraDataForWidget (id) {
      try {
        switch (id) {
          default:
            return []
        }
      } catch (err) {
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    filterOptionsForWidget (widgetId, widgetData) {
      try {
        switch (widgetId) {
          case 'leasing.lease_plan': {
            let itemsFromFilteredColumn = widgetData.items.reduce((uniqueValues, currentItem) => {
              if(currentItem[widgetData.filter.header] && !uniqueValues.includes(currentItem[widgetData.filter.header]))
              {
                uniqueValues.push(currentItem[widgetData.filter.header])
              }
              return uniqueValues
            }, [])
            const filterOptions = [{ text: 'prospect.show_all', value: null }].concat(itemsFromFilteredColumn.sort().map(i => {
                return { text: i, value: i }
            }))
            return filterOptions
          } 
          default:
            return []
        }
      } catch (err) {
        this.$log.error(`Error trying to process custom filter options for widget: "${widgetId}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    }
  }
}
</script>
<style scoped>
</style>
