import { i18n } from '../../../plugins/i18n.js'

export function refundableGuarantees (refundOptions, contractStates) {  
  return {
    id: "contract.refundable_guarantees",
    type: "LazyTable",
    loading: true,
    data: {
      headers: [
        {
          text: "Contract number",
          value: "contractNumber",
          event: "showCreateRentContractModal",
        },
        { text: "tenantName", value: "tenantName" },
        { text: "Target name", value: "siteNames", event: "goToSite" },
        { text: "Landlord contact person", value: "landlordContactPerson" },
        { text: "Contract status", value: "contractStatus", format: "Array" },
        { text: "contract.contract end date", value: "contractEndDate", format: "Date" },
        { text: "GuaranteeType", value: "guaranteeType" },
        { text: "GuaranteeDescription", value: "guaranteeDescription" },
        { text: "GuaranteeSum", value: "guaranteeSum", format: "Euro" },
        {
          text: "contract.guarantee_refund_actions",
          value: "refundAction",
          type: "dropdown",
          choices: () => {
            return [
              ...refundOptions.map((option) => option.label)
            ]
          },
          format: "Array",
          dataField: "defRefundAction"
        }
      ],
      filter: {
        property: 'defRefundAction',
        options: [
          ...refundOptions.map((option) => { return { text: option.label, value: option.id }}),
          { text: i18n.t("Show all"), value: null }
        ]
      },
      customFormatters: {
        contractStatus: (row) => {
          return i18n.t(row.contractStatus)
        },
        refundAction: (row) => {
          return refundOptions.find(option => option.id === row.defRefundAction)?.label || null
        }
      },
      source: "contractrefundableguarantees",
      items: [],
      triggerGetAllData: 0,
      customPagination: {
        sortBy: ["contract.contract end date"],
        sortDesc: [false]
      },
      customFilterContent: {
        contractStatus: contractStates.map((state) => ({
          text: i18n.t(state.name),
          value: state.name
        })),
        refundAction: refundOptions.map((option) => ({
          text: option.label,
          value: option.id
        }))
      },
    },
  }
}
