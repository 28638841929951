import helpers from "../../helpers"
import { getRentalProcessTitle } from '../../helpers/leasing/rentalprocess.js'
import { i18n } from '../../plugins/i18n.js'

export const rentingWidget = () => ({
  id: "leasing.renting",
  type: "ObjectTable",
  data: {
    headers: [
      { text: "leasing.renting_stage", value: "stage" }, // Vuokrauksen vaihe
      { text: "Name", value: "rentingProcessTitle" }, // Vuokrauksen nimi
      { text: "Area", value: "area", format: "Area" }, //Pinta-ala
      { text: "Tenant corporation", value: "tenant" }, //Vuokralaiskonserni
      {
        text: 'Sites',
        value: 'sites',
        leasing: true,
        multiple: true,
        sort: helpers.table.sortByObjectName, // Custom sorter for sites
      }, //Kohteet
      { text: "leasing.responsible1", value: "primaryUser" }, // 1. Vuokravastuullinen
      { text: "leasing.responsible2", value: "primaryUser2" }, // 2. Vuokravastuullinen
      { text: "leasing.assetManager", value: "assetManager" }, // Asset Manager / kohdevastaava
      { text: "leasing.estimatedStart", value: "estimatedStart", format: "Date" }, // Sopimuksen arvioitu alku pvm
      { text: "leasing.estimatedSign", value: "estimated_signature_date", format: "MonthAndYear" }, // Ennustettu allekirjoitusajankohta
      { text: "rental_process.estimated_modification_cost", value: "estimated_modification_cost", format: "Currency" }, // Arvio muutostyökustannuksista
      { text: "leasing.estimatedValue", value: "estimated_value_impact", format: "Currency" }, // Ennustettu arvovaikutus
    ],
    browseHeaders: [
      { text: "leasing.renting_stage", value: "stage" }, // Vuokrauksen vaihe
      { text: "Name", value: "rentingProcessTitle", leasing: true}, // Vuokrauksen nimi
      { text: "Area", value: "area", format: 'Area' }, //Pinta-ala
      { text: "Tenant corporation", value: "tenant", leasing: true }, //Vuokralaiskonserni
      {
        text: 'Sites',
        value: 'sites',
        leasing: true,
        multiple: true,
        sort: helpers.table.sortByObjectName, // Custom sorter for sites
      }, //Kohteet
      { text: "leasing.responsible1", value: "primaryUser" }, // 1. Vuokravastuullinen
      { text: "leasing.responsible2", value: "primaryUser2" }, // 2. Vuokravastuullinen
      { text: "leasing.assetManager", value: "assetManager" }, // Asset Manager / kohdevastaava
      { text: "leasing.estimatedStart", value: "estimatedStart", format: "Date" }, // Sopimuksen arvioitu alku pvm
      { text: "leasing.estimatedSign", value: "estimated_signature_date", format: "MonthAndYear" }, // Ennustettu allekirjoitusajankohta
      { text: "rental_process.estimated_modification_cost", value: "estimated_modification_cost", format: "Currency" }, // Arvio muutostyökustannuksista
      { text: "leasing.estimatedValue", value: "estimated_value_impact", format: "Currency" }, // Ennustettu arvovaikutus
      { text: "leasing.market_rent_total", value: "totalMarketRent", format: 'Currency' }, // Markkinavuokra yht. €/kk
      { text: "prospect.location_municipalities", value: "municipalities" }, // Sijaintikunnat
      { text: "Usage types", value: "usageTypes" }, // Käyttötyyppi
      { text: "prospect.lead_origin", value: "leadOrigin" }, // Liidin alkuperä
      { text: "leasing.contractModel", value: "contractModel" }, // Sopimusmalli
      { text: "ContractType", value: "type" }, // Sopimustyyppi
      { text: "leasing.createdDay", value: "createdDay", format: "Date" }, // Luotu pvm
      { text: "leasing.lastUpdatedDay", value: "updatedDay", format: "Date" }, // Viimeksi päivitetty pvm
    ],
    footers: [
      { text: i18n.t("Total") },
      {},
      { value: 'area', format: 'Area' },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      { value: 'estimated_value_impact', format: 'Currency' },
    ],
    browseFooters: [
      {},
      { value: 'area', format: 'Area' },
      {},
      {},
      { value: 'totalMarketRent', format: 'Euro' },
      {},
      {},
      {},
      {},
      {},
      { value: 'estimated_value_impact', format: 'Currency' },
      { value: 'totalMarketRent', format: 'Currency' }
    ],
    dynamicFilterOptions: true,
    filter: {
      header: "leasing.renting_stage",
      type: 'equal',
      options: [
        { text: 'prospect.show_all', value: null },
      ],
      defaultFilter: {
        text: 'prospect.show_all',
        value: null,
      },
    },
    items: [],
    itemsPerPage: 10,
  },
  large: true,
  maximized: [true],
  loading: true,
})

export const toRentingWidgetData = (rentingProcesses, users, getDefinitionLabelById, municipalities) => {
  return rentingProcesses.map((process) => {
    const primaryUser = users.find((user) => (user.id_user === process.owner))
    const primaryUser2 = users.find((user) => (user.id_user === process.secondaryOwner))
    const usageTypes = process.usage_types.map(u => getDefinitionLabelById(u)).join(', ')
    const processMunicipalities = process.cities.map(c => municipalities.find(m => m.id === c)?.municipality).join(', ')
    const sites = process.sites.reduce((acc, site, index) => {
      acc[index] = { id_site: site.id_site, name: site.siteName };
      return acc;
    }, {});
    return {
      ...process,
      id_process: process.id,
      stage: process.status === "Inactive" ? i18n.t(process.status) : i18n.t(process.stage),
      type: i18n.t('leasing.new_negotiation'),
      rentingProcessTitle: getRentalProcessTitle(process),
      primaryUser: primaryUser?.name,
      area: process?.area_preference ? `${process?.area_preference}` : '',
      tenant: process.corporation,
      sites: { ...sites },
      totalMarketRent: process.sites.reduce((acc, cur) => acc += cur.siteUnitMarketRent, 0),
      primaryUser2: primaryUser2?.name,
      estimatedStart: process.estimated_completion,
      municipalities: processMunicipalities,
      contractModel: getDefinitionLabelById(process.def_contract_model),
      usageTypes: usageTypes,
      leadOrigin: getDefinitionLabelById(process.lead_origin),
      createdDay: process.created,
      updatedDay: process.last_modified,
      assetManager: process.asset_managers.map(am => am.name).join(', ')
    }
  })
}