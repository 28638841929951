<template>
  <div>
    <v-dialog
      v-model="partialBuildingDialog"
      persistent
      max-width="550"
    >
      <BaseModal
        @cancel="cancel"
      >
        <template #title>
          {{ $t('Partial building') }}
        </template>
        <template #content>
          <v-text-field
            v-model="currentPartialBuilding.yearOfConstruction"
            :placeholder="$t('Year of construction')"
          />
          <v-select
            v-model="currentPartialBuilding.defPurposeOfUse"
            :value="currentPartialBuilding.defPurposeOfUse"
            :items="purposesOfUse"
            item-text="label"
            item-value="id"
            :placeholder="$t('Purpose of use')"
          />
          <v-text-field
            v-model="currentPartialBuilding.grossArea"
            :placeholder="$t('Gross area')"
          />
          <v-text-field
            v-model="currentPartialBuilding.ageDiscount"
            :placeholder="$t('Age discount')"
          />
        </template>
        <template #footer>
          <v-btn
            v-if="currentPartialBuilding.idPartialBuilding !== undefined"
            rounded
            depressed
            color="warning"
            @click="remove(currentPartialBuilding.idPartialBuilding)"
          >
            {{ $t('Delete') }}
          </v-btn>
          <v-btn
            v-if="currentPartialBuilding.idPartialBuilding !== undefined"
            rounded
            depressed
            color="primary"
            @click="update"
          >
            {{ $t('Update') }}
          </v-btn>
          <v-btn
            v-else
            rounded
            depressed
            color="primary"
            @click="save"
          >
            {{ $t('Save') }}
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :headers="headers"
      :items="partialBuildings"
      class="elevation-0"
    >
      <template
        v-slot:item="props"
      >
        <td
          v-for="header in headers"
          :key="header.value"
          @click="showPartialBuildingDialog('edit', props.item)"
          v-html="Sanitize(getValueWithUnit(props.item[header.value], header.format))"
        />
      </template>
      <template slot="actions-prepend">
        <v-btn
          small
          outlined
          :disabled="disabled"
          @click="showPartialBuildingDialog('new')"
        >
          {{ $t('New partial building') }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import helpers from '../helpers'
import { mapGetters } from 'vuex'
import BaseModal from './general/BaseModal.vue'
export default {
  components: { BaseModal},
  props: ['buildingCode', 'disabled'],
  data () {
    return {
      headers: [
        { text: this.$t('Year of construction'), value: 'yearOfConstruction', format: '' },
        { text: this.$t('Purpose of use'), value: 'purposeOfUse', format: '' },
        { text: this.$t('Gross area'), value: 'grossArea', format: 'Area' },
        { text: this.$t('Age discount'), value: 'ageDiscount', format: 'Percentage' }
      ],
      purposesOfUse: [],
      partialBuildings: [],
      partialBuildingDialog: false,
      currentPartialBuilding: {
        yearOfConstruction: '',
        defPurposeOfUse: 0,
        grossArea: 0,
        ageDiscount: 0
      },
      menu: false
    }
  },
  computed: {
  ...mapGetters('app', ['dataTableHeaderDefinitions']),
  },
  watch: {
    buildingCode: function () {
      this.updateList()
    }
  },
  async mounted () {
    this.purposesOfUse = await this.$rambollfmapi.definitions.list('building.purposeofuse')
    this.updateList()
  },
  methods: {
    getValueWithUnit (value, format) {
      return helpers.format.formatData(value, format)
    },
    async remove (id) {
      await this.$rambollfmapi.buildings.partialbuildings(this.buildingCode).delete(id)
      this.updateList()
      this.partialBuildingDialog = false
    },
    async update () {
      await this.$rambollfmapi.buildings.partialbuildings(this.buildingCode).update(this.currentPartialBuilding.idPartialBuilding, this.currentPartialBuilding)
      this.updateList()
      this.partialBuildingDialog = false
    },
    async save () {
      await this.$rambollfmapi.buildings.partialbuildings(this.buildingCode).post(this.currentPartialBuilding)
      this.updateList()
      this.partialBuildingDialog = false
    },
    cancel () {
      this.partialBuildingDialog = false
    },
    showPartialBuildingDialog (mode, partialBuilding) {
      if (typeof partialBuilding !== 'undefined' && partialBuilding !== null) {
        this.currentPartialBuilding = {
          idBuilding: partialBuilding.idBuilding,
          idPartialBuilding: partialBuilding.idPartialBuilding,
          yearOfConstruction: partialBuilding.yearOfConstruction,
          defPurposeOfUse: partialBuilding.defPurposeOfUse,
          grossArea: partialBuilding.grossArea,
          ageDiscount: partialBuilding.ageDiscount
        }
      } else {
        this.currentPartialBuilding = {
          yearOfConstruction: '',
          defPurposeOfUse: null,
          grossArea: 0,
          ageDiscount: 0
        }
      }
      this.partialBuildingDialog = true
    },
    async updateList () {
      const partialBuildings = await this.$rambollfmapi.buildings.partialbuildings(this.buildingCode).list()
      this.partialBuildings = partialBuildings.map(b => {
        const pou = this.purposesOfUse.find(p => p.id === b.defPurposeOfUse)
        if (typeof pou !== 'undefined') {
          b.purposeOfUse = pou.label
        }
        return b
      })
    },
    Sanitize (text) {
      return helpers.format.sanitize(text)
    }
  }
}
</script>
<style>
</style>
