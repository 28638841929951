<template>
  <v-dialog
    v-model="showDefaultSearchDialog"
    max-width="550"
    persistent
  >
    <BaseModal
      @cancel="closeWithoutSaving"
    >
      <template #title>
        {{ $t('Save default search') }}
      </template>
      <template #content>
        <v-text-field
          v-model="nameOfSelection"
          :placeholder="$t('Default search name')"
          required
          class="form-field"
        />
        <br>
        <v-col
          v-if="hasApplicationPermissionByName('VAKIOHAKU_LUONTI')"
          class="section-textfield"
        >
          <h4 class="text--secondary">
            {{ $t('Search type') + ':' }}
          </h4>
          <v-radio-group
            :value="selectedPrivacy"
            row
            @change="(e) => changePrivacy(e)"
          >
            <v-radio
              v-for="selection in privacySelections"
              :key="selection.id"
              :label="$t(selection.label)"
              :value="selection.id"
            />
          </v-radio-group>
        </v-col>
      </template>
      <template #footer>
        <v-btn
          text
          rounded
          class="primary"
          @click="save"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>  
</template>
<script>
import BaseModal from './general/BaseModal.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import ToggleAll from './general/ToggleAll.vue'

export default {
  emits: ['close', 'save'],
  name: 'DefaultSearchDialog',
  components: {
    BaseModal,
    ToggleAll
  },
  props: {
    showDialog: {
      default: () => {
        return false
      },
      type: Boolean
    },
    saving: {
      default: () => {
        return false
      },
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName'])
  },
  data () { 
    return {
      showDefaultSearchDialog: false,
      nameOfSelection: null,
      privacySelections: [
        { 
          id: 1,
          label: 'default-search.private'
        },
        {
          id: 2,
          label: 'default-search.public'
        }
      ],
      selectedPrivacy: 1
    }
  },
  watch: {
    showDialog: {
      handler: function (value) {
        this.showDefaultSearchDialog = value
      }
    }
  },
  methods: {
    closeWithoutSaving () {
      this.nameOfSelection = null
      this.selectedPrivacy = 1
      this.$emit('close')
    },
    changePrivacy (value) {
      this.selectedPrivacy = value
    },
    save () {
      this.$emit('save', this.nameOfSelection, true, this.selectedPrivacy == 1)
    }
  }
}
</script>
