import { i18n } from '../plugins/i18n.js'

export default { 
  businessIdentifierCheckNumber (businessIdentifierBeginPart) {
    if (businessIdentifierBeginPart.length != 7) {
      return -1
    }
    let sum = 0
    let multipliers = [7,9,10,5,8,4,2]
    for(let i=0; i < 7; i++) {
      let iThCharacter = businessIdentifierBeginPart.charAt(i)
      if (iThCharacter < '0' || iThCharacter > '9') {
        return -1
      }
      sum += multipliers[i] * parseInt(iThCharacter)
    }
    let modulus = sum % 11
    if (modulus === 1) {
      return -1
    }
    if (modulus === 0) {
      return 0
    }
    return 11 - modulus
  },
  getBusinessIdentifierError (businessIdentifierProposal, seekMessage = false) {
    if (businessIdentifierProposal === null || businessIdentifierProposal === undefined) {
      return ""
    }
    const splittedInput = businessIdentifierProposal.split("-");
    const nbr = splittedInput[0] + splittedInput[1];
    if (splittedInput[1] === undefined || businessIdentifierProposal.length !== 9 || 
      Object.keys(splittedInput[1]).length !== 1 || splittedInput[2] !== undefined || 
      businessIdentifierProposal.indexOf('.') >= 0) {
      return (seekMessage ? 
        i18n.t("You can make business identifier query to YTJ only with full business identifier") + ". " : "") +
        i18n.t('Business ID must be in the form of 1234567-8')
    } else if (isNaN(nbr)) {
      return i18n.t("Business ID contains letters")
    } else if (this.businessIdentifierCheckNumber(splittedInput[0]) + "" !== splittedInput[1]) {
      return i18n.t("Business ID check number does not match")
    }
    return ""
  },
  twoOrMoreOptionsMessage (results) {
    return i18n.t('Make your query more accurate. With your query was found') + 
      ' ' + results.length + ' ' + i18n.t('results') + ":"
  }
}