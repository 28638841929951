<template>
  <BaseModal
    @cancel="cancel"
  >
    <template #title>
      {{ $t('Download markings') }}
    </template>
    <template #content>
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          class="no-padding"
        >
          <v-expansion-panel-header>
            <div
              style="margin-bottom: 0.35rem !important"
            >
              <span 
                class="header.class"
                align-center
                justify-center
              >{{ $t('Markings') }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="rows"
              class="elevation-0"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
            >
              <template
                v-slot:item="props"
              >
                <tr>
                  <td>{{ props.item.dateString }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>
                    <v-icon @click="downloadMarkup(props.item.id)">
                      get_app
                    </v-icon>
                    <v-icon @click="deleteMarkup(props.item.id)">
                      delete
                    </v-icon>
                  </td>
                </tr>
              </template>
              <template
                v-slot:footer.page-text="item"
              >
                <v-btn
                  v-if="item.itemsLength > 5"
                  small
                  outlined
                  rounded
                  @click="markingsShowAll()"
                >
                  {{
                    options.itemsPerPage === 5 ? $t('Show all') : $t('Show less')
                  }}
                </v-btn>
                {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                {{ item.itemsLength.toLocaleString('fi-FI') }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <template #footer />
  </BaseModal>
</template>
<script>
import BaseModal from '../../components/general/BaseModal.vue'
export default {
  emits: ['close', 'load-markups'],
  name: 'MarkupManagementForm',
  components: { BaseModal },
  props: {
    floorId: {
      type: Number,
      default: null
    },
    open: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      headers: [
        { text: this.$t('Date'), value: 'dateString' },
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Actions'), value: 'actions' },
      ],
      rows: [],
      filesCount: 0,
      paginated: true,
      options: {
        itemsPerPage: 5,
        sortBy: ['dateString'],
        sortDesc: [ true ]
      },
    }
  },
  watch: {
    open: {
      immediate: true,
      handler: function (isOpened) {
        if (isOpened) {
          this.getStoredViews()
        }
      }
    },
  },
  methods: {
    async getStoredViews () {
      this.rows = []
      const storedViews = await this.$rambollfmapi.floors.v3markups().storedview(parseInt(this.floorId))
      storedViews.forEach(sv => {
        this.rows.push({
          id: sv.id,
          dateString: sv.extraString,
          name: sv.name
        })
      })
    },
    markingsShowAll () {
      if (this.options.itemsPerPage === 5) {
        this.options.itemsPerPage = -1
      } else {
        this.options.itemsPerPage = 5
      }
    },
    deleteMarkup (id) {
      if (confirm(this.$t('marking_delete_confirmation'))) {
      this.$rambollfmapi.floors.v3markups().delete(id)
      this.$emit('close')
      }
    },
    downloadMarkup (id) {
      this.$emit('load-markups', id)
    },
    cancel () {
      this.$emit('close')
    }
  }
}
</script>
