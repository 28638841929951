<template>
  <BaseModal
    @cancel="$emit('close')"
  >
    <template #title>
      {{ $t('Warning') }}
    </template>
    <template #content>
      <h3>
        {{ $t('Save changes before leaving page?') }}
      </h3>
    </template>
    <template #hideCancel />
    <template #footer>
      <v-btn
        rounded
        outlined
        text
        class="error"
        style="color:white"
        @click="$emit('ignore')"
      >
        {{ $t('Ignore') }}
      </v-btn>
      <v-btn
        rounded
        depressed
        color="primary"
        @click="$emit('save')"
      >
        {{ $t('Save') }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'
export default {
  emits: ['close', 'ignore', 'save'],
  name: 'WarningDialog',
  components: { BaseModal },
  methods: {
  }
}

</script>
<style>
</style>
