<template>
  <v-row
    no-gutters
  >
    <v-dialog
      v-model="addAreaDialog"
      persistent
      max-width="550"
    >
      <AriaLive />
      <BaseModal
        @cancel="cancel()"
      >
        <template #title>
          {{ $t('Add site area') }}
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <template
              v-if="
                buildingsForCurrentSite.length > 0 &&
                  addAreaDialog === true
              "
            >
              <div
                v-for="building in buildingsForCurrentSite"
                :key="building.building_code"
              >
                {{
                  building.building_code +
                    ' ( ' +
                    building.building_name +
                    ' )'
                }}
                <div v-if="building.has_dwg">
                  {{ $t('Has dwgs') }}
                </div>
                <v-text-field
                  v-model.number="
                    buildingData[building.building_code].new_gross_area
                  "
                  class="form-field"
                  number
                  step="any"
                  :label="$t('Gross area')"
                  :readonly="
                    buildingData[building.building_code].has_any_dwgs || buildingData[building.building_code].floors.length > 1
                  "
                />
                <v-text-field
                  v-model.number="
                    buildingData[building.building_code]
                      .new_total_facility_area
                  "
                  class="form-field"
                  number
                  step="any"
                  :label="$t('Net floor area')"
                  :readonly="
                    buildingData[building.building_code].has_any_dwgs || buildingData[building.building_code].floors.length > 1
                  "
                />
                <v-autocomplete
                  v-model="buildingData[building.building_code].new_tenant"
                  small
                  :items="parties"
                  clearable
                  item-text="name"
                  item-value="id"
                  class="form-field"
                  :label="$t('Tenant')"
                  :placeholder="$t('Tenant')"
                  menu-props="offsetY"
                  :readonly="
                    buildingData[building.building_code].has_any_dwgs ||
                      !(
                        0 +
                        buildingData[building.building_code]
                          .new_total_facility_area >
                        0
                      ) ||
                      !(
                        0 +
                        buildingData[building.building_code].new_gross_area >
                        0
                      )
                  "
                />
                <v-row
                  v-if="!buildingData[building.building_code].has_any_dwgs"
                  align="center"
                  justify="end"
                >
                  <div class="text-xs-end">
                    <v-col>
                      {{
                        $t('PDF pictures saved')
                      }}
                        &nbsp;
                    </v-col>
                  </div>
                  <div class="text-xs-end">
                    <v-col>
                      <v-checkbox
                        v-model="buildingData[building.building_code].new_pdf"
                        :disabled="
                          buildingData[building.building_code].has_any_dwgs ||
                            !(
                              0 +
                              buildingData[building.building_code]
                                .new_total_facility_area >
                              0
                            ) ||
                            !(
                              0 +
                              buildingData[building.building_code].new_gross_area >
                              0
                            )
                        "
                        :false-value="0"
                        :true-value="1"
                      />
                    </v-col>
                  </div>
                </v-row>
              </div>
            </template>
          </v-form>
        </template>
        <template #footer>
          <v-btn
            :disabled="!valid || saving"
            text
            rounded
            class="primary"
            @click="save"
          >
            <span v-if="!saving">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <v-spacer />
    <v-btn
      v-if="disabled === false"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      :disabled="disableSiteAreaButton"
      @click="showAddAreasDialog()"
    >
      <span>{{ $t('Add site area') }}</span>
    </v-btn>
  </v-row>
</template>

<script>
import BaseModal from './general/BaseModal.vue'
import { mapGetters, mapState } from 'vuex'
import AriaLive from '../components/AriaLive.vue'

export default {
  emits: ['saved'],
  components: {
    BaseModal,
    AriaLive
  },
  data () {
    return {
      saving: false,
      valid: true,
      noSpaceData: false,
      disabled: true,
      addAreaDialog: false,
      buildingData: {},
      parties: [],
      disableSiteAreaButton: true
    }
  },
  computed: {
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    ...mapGetters('app', [
      'definitionById',
      'definitionByLabel',
      'definitionsByGroupLabel'
    ]),
    ...mapState('app', ['units']),
    activeTab () {
      const tab = this.$store.state.sites.siteTabs
      this.$log.info('tab', tab)
      if (tab[0].location === 'sites.info') {
        return true
      }
      return false
    },
    calendarDate () {
      return this.$store.state.app.currentDate
    },
    ...mapState('app', ['units'])
  },
  watch: {
    buildingsForCurrentSite: {
      handler: async function (newData, oldData) {
        this.$log.info('*** buildingsForCurrentSite updated', oldData, newData)
        await this.loadBuildingData()
        this.$log.info('*** new buildingData', this.buildingData)
      },
      deep: true
    }
  },
  async mounted () {
    const userGroups = await this.$rambollfmapi.accounts.currentgroups()
    userGroups.forEach(group => {
      if (
        group.applicationPermissions.find(app => {
          return app.id === '04.02.06'
        })
      ) {
        this.disabled = false
      }
    })
    if (this.activeTab === true) {
      this.$log.info('***sites.info tab active')
      await this.loadBuildingData()
    }
  },
  methods: {
    async loadBuildingData () {
      this.noSpaceData = await this.$rambollfmapi.spaces.noData()

      for (const building of this.buildingsForCurrentSite) {
        this.$set(this.buildingData, building.building_code, {
          id: building.id_building,
          has_any_dwgs: false,
          current_gross_area: null,
          current_total_facility_area: null,
          new_gross_area: null,
          new_total_facility_area: null,
          current_tenant: null,
          new_tenant: null,
          dummy_unit: null,
          floors: [],
          current_pdf: false,
          new_pdf: false
        })

        const floors = await this.$rambollfmapi.buildings
          .floors(building.building_code)
          .list(this.calendarDate)

        if (floors.length < 1) {
          continue
        }

        this.buildingData[building.building_code].floors = floors
        this.buildingData[building.building_code].has_any_dwgs = floors.some(
          f => f.has_dwg === true || f.has_xml === true
        )
        this.buildingData[
          building.building_code
        ].new_gross_area = floors.reduce(
          (sum, floor) => sum + floor.gross_area,
          0
        )
        this.buildingData[
          building.building_code
        ].current_gross_area = this.buildingData[
          building.building_code
        ].new_gross_area

        const firstFloor = this.buildingData[
          building.building_code
        ].floors.find(f => f.floor_name === '1.KERROS')

        if (typeof firstFloor === 'undefined') {
          continue
        }

        const units = await this.$rambollfmapi.floors
          .units(firstFloor.id, this.calendarDate) 
          .list()

        if (units.length < 1) {
          continue
        }
        this.buildingData[building.building_code].new_pdf = units[0].pdf
        this.buildingData[
          building.building_code
        ].new_total_facility_area = units.reduce(
          (sum, unit) => sum + unit.area,
          0
        )
        this.buildingData[
          building.building_code
        ].current_total_facility_area = this.buildingData[
          building.building_code
        ].new_total_facility_area

        this.buildingData[
          building.building_code
        ].current_pdf = this.buildingData[
          building.building_code
        ].new_pdf
        const dummyUnit = units.find(u => u.unit_code === 'F1-DB')
        this.buildingData[building.building_code].dummy_unit = dummyUnit

        if (this.buildingData[building.building_code].dummy_unit == null) {
          continue
        }

        const tenants = await this.$rambollfmapi.units
          .partyLinks(dummyUnit.id)
          .list(new Date(), false)

        // Get building tenant data
        if (tenants != null && tenants.length > 0) {
          this.buildingData[building.building_code].current_tenant =
            tenants[0].id_party
          this.buildingData[building.building_code].new_tenant =
            tenants[0].id_party
        }
      }
      this.parties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })

      for (const building in this.buildingData) {
        if (!this.buildingData[building].has_any_dwgs) {
          this.disableSiteAreaButton = false
          break
        }
      }
    },
    showAddAreasDialog () {
      this.addAreaDialog = true
    },
    cancel () {
      this.addAreaDialog = false
    },
    async save () {
      if (this.saving) {
        return
      }
      this.saving = true

      for (const building in this.buildingData) {
        let floor = null
        let unit = null
        const timeStamp = new Date()

        if (
          this.buildingData[building].current_gross_area !==
          this.buildingData[building].new_gross_area
        ) {
          if (this.buildingData[building].current_gross_area == null) {
            if (this.buildingData[building].floors.length < 1) {
              const defFloor = {
                floor_name: '1.KERROS',
                shortname: null,
                floor_number: 1,
                gross_area: this.buildingData[building].new_gross_area,
                start_date: timeStamp.toISOString().substr(0, 10)
              }

              const floorToSave = JSON.parse(JSON.stringify(defFloor))
              floor = await this.$rambollfmapi.buildings
                .floors(building)
                .post(floorToSave)
              this.buildingData[building].floors.push(floor)
              this.$store.dispatch('app/addAriaLive', this.$t('aria_live.space_added_window_closed'))
            }
          } else if (this.buildingData[building].floors.length === 1) {
            const floorPatch = [
              {
                op: 'replace',
                path: 'gross_area',
                value: this.buildingData[building].new_gross_area
              }
            ]
            await this.$rambollfmapi.floors.purePatch(
              this.buildingData[building].floors[0].id,
              this.buildingData[building].floors[0].version,
              floorPatch
            )
            this.buildingData[building].current_gross_area = this.buildingData[
              building
            ].new_gross_area
            floor = this.buildingData[building].floors[0]
          } else {
            // More than one floor in building. It shouldn't be possible to update gross areas using this dialog
            continue
          }
        } else {
          floor = this.buildingData[building].floors[0]
        }

        const units = await this.$rambollfmapi.floors.units(floor.id).list()
        if (
          (this.buildingData[building].current_total_facility_area !==
          this.buildingData[building].new_total_facility_area) ||
          (this.buildingData[building].current_pdf !==
          this.buildingData[building].new_pdf)
        ) {
        if (units.length < 1) {
            // Create new unit if none exist
            const defUnit = {
              id: null,
              unit_code: 'F1-DB',
              version: null,
              floor_id: floor.id,
              name: null,
              area: this.buildingData[building].new_total_facility_area,
              not_rentable: this.definitionsByGroupLabel(
                'unit.notrentable'
              ).find(def => def.label === 'rentable').id,
              start_date: timeStamp,
              pdf: this.buildingData[building].new_pdf ? 1 : 0
            }

            const unitToSave = JSON.parse(JSON.stringify(defUnit))
            unit = await this.$rambollfmapi.units.post(unitToSave)

            // Create space data if needed
            if (this.noSpaceData === false) {
              const defSpace = {
                name: '101-DB',
                id_floor: floor.id,
                area: 0,
                start_date: timeStamp
              }
              const spaceToSave = JSON.parse(JSON.stringify(defSpace))

              const newSpace = await this.$rambollfmapi.spaces.post(spaceToSave)

              // Assign the created space to previously created unit
              const spaces = []
              spaces.push(newSpace)
              await this.$rambollfmapi.units.assignSpaces(
                unit.id,
                spaces.map(space => space.id)
              )
            }
          } else if (units.length === 1) {
            const unitPatch = [
              {
                op: 'replace',
                path: 'area',
                value: this.buildingData[building].new_total_facility_area
              }
            ]
            unitPatch.push({
              op: 'replace',
              path: 'pdf',
              value: this.buildingData[building].new_pdf ? 1 : 0
            })
            await this.$rambollfmapi.units.purePatch(
              units[0].id,
              units[0].version,
              unitPatch
            )

            this.buildingData[
              building
            ].current_total_facility_area = this.buildingData[
              building
            ].new_total_facility_area
            this.buildingData[
              building
            ].current_pdf = this.buildingData[
              building
            ].new_pdf
            unit = units[0]
          } else {
            // More than one unit in floor. It shouldn't be possible to update areas using this dialog
            continue
          }
        } else {
          unit = units[0]
        }

        if (
          unit != null &&
          this.buildingData[building].current_tenant !==
            this.buildingData[building].new_tenant
        ) {
          if (this.buildingData[building].current_tenant == null) {
            // If there is no existing tenant, create new one
            const newTenant = {
              id: 0,
              id_party: this.buildingData[building].new_tenant,
              id_annotation: this.definitionByLabel('Tenant').id,
              start_date: timeStamp,
              group: 3,
              order: 0
            }

            await this.$rambollfmapi.units
              .partyLinks(unit.id)
              .post(unit.id, newTenant)
          } else {
            // TODO: Get all unit tenants
            const existingTenants = await this.$rambollfmapi.units
              .partyLinks(unit.id)
              .list(timeStamp, false)

            if (this.buildingData[building].new_tenant == null) {
              // Delete all unit tenants
              for (const tenant of existingTenants) {
                await this.$rambollfmapi.units
                  .partyLinks(unit.id)
                  .delete(unit.id, tenant.id)
              }
            } else {
              const unitTenantPatch = [
                {
                  op: 'replace',
                  path: 'id_party',
                  value: this.buildingData[building].new_tenant
                }
              ]
              await this.$rambollfmapi.units
                .partyLinks(unit.id)
                .purePatch(
                  unit.id,
                  existingTenants[0].id,
                  existingTenants[0].version,
                  unitTenantPatch
                )
            }
          }
          this.buildingData[building].current_tenant = this.buildingData[
            building
          ].new_tenant
        }
      }

      this.addAreaDialog = false
      this.saving = false
      this.$refs.form.resetValidation()
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
      this.$emit('saved', true)
    }
  }
}
</script>
<style scoped>
</style>
