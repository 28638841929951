<template>
  <div id="purposes-of-use">
    <v-row
      class="layout-white fill-height flex-column"
    >
      <v-toolbar
        flat
        tile
      >
        <v-toolbar-title>
          <h1>{{ $t('Purposes of use') }}</h1>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items v-if="hasApplicationPermissionByName('SIS_KAYTTOTARK_MUOKKAUS')">
          <v-row>
            <v-btn
              icon
              :class="{selected: editing}"
              @click="toggleEditing()"
            >
              <v-icon>edit</v-icon>
              <span class="d-sr-only">{{ $t('Modify') }}</span>
            </v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-row>
    <v-row>
      <!-- Purpose of use list -->
      <v-col
        class="ma-3"
        sm="12"
        md="6"
      >
        <v-expansion-panels
          :value="[0]"
          multiple
        >
          <v-expansion-panel
            class="no-padding"
            expand
          >
            <v-expansion-panel-header>
              {{ $t("Purposes of use") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-if="isLoading"
                style="text-align: center; padding: 2em;"
              >
                <v-progress-circular
                  size="64"
                  indeterminate
                  color="primary"
                />
              </div>
              <v-data-table
                v-else
                :header-props="dataTableHeaderDefinitions"
                :headers="purposeOfUseHeaders"
                :items="purposesCopy"
                class="elevation-1"
                :options.sync="options"
                mobile-breakpoint="0"
                :footer-props="{
                  itemsPerPageOptions: [],
                }"
                :no-data-text="$t('No data available')"
              >
                <template
                  v-slot:item="props"
                >
                  <tr>
                    <td
                      v-for="header in purposeOfUseHeaders"
                      :key="header.value"
                    >
                      <div v-if="header.value === 'rgb'">
                        <ColorPicker
                          v-if="editing"
                          v-model="props.item[header.value]"
                          :label="`${$t('Color')}: ${props.item.title}`"
                        />
                        <div
                          v-else
                          style="width:auto; height:2em"
                          :style="{ background: `rgb(${props.item[header.value]})` }"
                        />
                        <span class="d-sr-only">{{ `${$t('Color')}: rgb(${props.item[header.value]})` }}</span>
                      </div>
                      <div
                        v-else-if="header.value === 'defMainUsage'"
                      >
                        <v-select
                          v-if="editing"
                          v-model="props.item[header.value]"
                          :items="definitionsByGroupLabel('space.main_usage')"
                          :label="`${$t('Main usage')}`"
                          class="borderless"
                          item-text="label"
                          item-value="id"
                        />
                        <div
                          v-else-if="!editing || header.edit !== true"
                          :class="{ 'col-align-right': isRightAligned(header.format) }"
                        >
                          {{ definitionLabelById(props.item[header.value]) }}
                        </div>
                      </div>
                      <div
                        v-else-if="header.value === 'selectable'"
                      >
                        <div
                          v-if="editing"
                          style="display: flex; justify-content: center;"
                          @click="handlePurposeUsage(props.item)"
                        >
                          <v-checkbox
                            v-model="props.item[header.value]"
                            disabled
                            class="mt-0 pt-0"
                          />
                        </div>
                        <div
                          v-else
                          :class="{ 'col-align-right': isRightAligned(header.format) }"
                        >
                          <span>{{ getUsageActivity(props.item[header.value]) }}</span>
                        </div>
                      </div>
                      <div
                        v-else-if="!editing || header.edit !== true"
                        :class="{ 'col-align-right': isRightAligned(header.format) }"
                      >
                        {{ getValue(header.value, props.item[header.value]) }}
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="props.item[header.value]"
                          style="margin: 0"
                          :label="`${$t(header.text)}: ${props.item.title}`"
                          append-icon="edit"
                          class="borderless"
                          type="number"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template
                  v-slot:footer.page-text="item"
                >
                  {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                  {{ item.itemsLength.toLocaleString('fi-FI') }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row
          justify="end"
          style="right: 0; bottom: 0; position: sticky; z-index: 1"
        >
          <v-btn
            v-if="editing && patch.length > 0"
            rounded
            :disabled="patch.length === 0 || isCurrentlySaving"
            class="primary"
            @click="saveChanges"
          >
            {{ $t('Save changes') }}
            <v-progress-circular
              v-if="isCurrentlySaving"
              size="16"
              indeterminate
              color="primary"
            />
          </v-btn>&nbsp;
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helpers from '../helpers'
import { mapActions, mapGetters, mapState } from 'vuex'
import ColorPicker from '../components/ColorPicker.vue'

export default {
  name: 'PurposesOfUse',
  components: {
    ColorPicker
  },
  metaInfo () {
    return {
      title: `${this.$t('Purposes of use')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      options: {
        sortDesc: [false],
        page: 1,
        itemsPerPage: -1,
        sortBy: [],
        totalItems: 0
      },
      isLoading: true,
      isCurrentlySaving: false,
      purposeOfUseSelectionDialog: false,
      editing: false,
      purposesCopy: null,
      purposesObserver: null,
      patch: []
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions','definitionsByGroupLabel', 'definitionLabelById', 'hasApplicationPermissionByName']),
    ...mapState('internalRent', ['purposesOfUse','defMainUsage']),
      purposeOfUseHeaders () {
          return [
              {
                  text: this.$t('Purpose of use'),
                  value: 'title'
              },
              { text: this.$t('Code'), value: 'code' },
              {
                  text: this.$t('Main usage'),
                  value: 'defMainUsage',
                  fieldtype: 'dropdown',
                  items: this.definitionsByGroupLabel('space.main_usage'),
                  edit: true                  

              },
              {
                  text: this.$t('Capital multiplier'),
                  value: 'capitalMultiplier',
                  edit: true
              },
              {
                  text: this.$t('Maintenance multiplier'),
                  value: 'maintenanceMultiplier',
                  edit: true
              },
              {
                  text: this.$t('Additional cost (€/m2)'),
                  value: 'additionalCost',
                  edit: true
              },
              { text: this.$t('Color'), value: 'rgb', edit: true },
              { text: this.$t('In use'), value: 'selectable', edit: true}
          ]
      },

    defMainUsages () {
      return [...new Set(this.purposesCopy.map(use => use.defMainUsage))]
    },
    edited () {
      return false
    }
  },
  watch: {
    purposesCopy: {
      deep: true,
      handler: function () {
        this.patch = this.$jsonpatch.generate(this.purposesObserver)
      }
    }
  },
  mounted: async function () {
    await this.getPurposes()
    this.purposesCopy = JSON.parse(JSON.stringify(this.purposesOfUse))
    this.purposesObserver = this.$jsonpatch.observe(this.purposesCopy)
    this.isLoading = false
  },
  methods: {
    ...mapActions('internalRent', ['getPurposes', 'updatePurposes']),
    async saveChanges () {
      this.isCurrentlySaving = true
      const asyncCalls = []
      // Purposes are patched by id, so we compare with the original instance and store the async call from each patch
      for (let i = 0; i < this.purposesCopy.length; ++i) {
        const changes = this.$jsonpatch.compare(
          this.purposesOfUse[i],
          this.purposesCopy[i]
        )
        if (changes.length > 0) {
          const params = { purposeId: this.purposesOfUse[i].id, data: changes }
          asyncCalls.push(this.updatePurposes(params))
        }
      }
      await Promise.all(asyncCalls)
      await this.getPurposes()
      this.clearChanges()
    },
    clearChanges () {
      this.purposesCopy = JSON.parse(JSON.stringify(this.purposesOfUse))
      this.purposesObserver = this.$jsonpatch.observe(this.purposesCopy)
      this.editing = false
      this.purposeOfUseSelectionDialog = false
      this.isCurrentlySaving = false
    },
    toggleSelectionDialog () {
      this.purposeOfUseSelectionDialog = !this.purposeOfUseSelectionDialog
    },
    toggleEditing () {
      this.editing = !this.editing
      if (!this.editing) {
        this.clearChanges()
      }
    },
    countBydefMainUsage (usage) {
      const allPurposesByUsage = this.purposesOfUse.filter(
        use => use.defMainUsage === usage
      )
      const allCount = allPurposesByUsage.length
      const selectedCount = allPurposesByUsage.filter(x => x.selectable).length
      return '(' + selectedCount + '/' + allCount + ')'
    },
    getPurposesBydefMainUsage (usage) {
      return this.purposesCopy.filter(use => use.defMainUsage === usage)
    },
    getValue (header, exampleValue) {
      if (header === 'defMainUsage') {
          return this.exampleValue
      }
      return exampleValue
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    handlePurposeUsage (purposeOfUsageItem) {
      purposeOfUsageItem.selectable = !purposeOfUsageItem.selectable
    },
    getUsageActivity (activity) {
      return activity ? this.$t('Yes') : this.$t('No')
    }
  }
}
</script>

<style scoped>
</style>
