!<template>
  <ViewAndSelections
    contract-level="ESR_carparks"
    parent-level="contract"
    :loading-as-prop="loading"
    @close-filter="showColumnsFilterDialog"
  >
    <template #buttons>
      <v-row
        class="toolbar dashboard overflow-x-auto ml-1 mr-1"
        :justify="isMobile ? 'start' : 'end'"
      >
        <v-btn
          small
          outlined
          rounded
          :disabled="loading"
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
        <ImportButton
          v-if="importing"
          :additional-date-format="true"
          :headers="headers"
          :required-fields="getRequiredFields()"
          :disabled="loading"
          format="windows-1252"
          @dataUpdate="saveFile"
        />
      </v-row>
      <v-dialog
        v-if="dataUpdateDialog"
        v-model="dataUpdateDialog"
        persistent
        max-width="600"
      >
        <ContractImportModal
          :loading="loading"
          :headers="headers"
          :items="carparksWithStatuses"
          :results="importResults"
          :identifier="importIdentifier"
          :required-fields="requiredFields"
          :new-contracts-errors="newContractsErrors"
          :updating-errors="updatingErrors"
          :is-saving-new-contracts="isSavingNewContracts"
          :is-saving-changes="isSavingChangedContracts"
          @resetChanges="resetImportChanges"
          @saveContracts="saveContracts"
          @changeSavingChanges="$event => isSavingChangedContracts = $event"
          @refreshData="$emit('refreshData')"
        />
      </v-dialog>
    </template>
    <template
      #table="props"
    >
      <Table
        v-show="!loading"
        :rows="viewData"
        :headers="headers"
        :hide="true"
        :show-filter="showColumnsFilter"
        :document-type="exportAsDocument"
        :pagination.sync="pagination"
        :show-save-selection="true"
        :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
        :stored-views="props.props.storedViews.filter(sv => sv.level === 'ESR_carparks')"
        :show-confirm="props.props.confirmation"
        @exported="exporting = {}"
        @select_view="props.props.selectView"
        @save-selection="props.props.saveStoredView"
        @delete-view="props.props.deleteSelectedView"
      />
    </template>
  </ViewAndSelections>
</template>

<script>
import { mapState } from 'vuex'
import Table from '../Table.vue'
import ImportButton from '../ImportButton.vue'
import ViewAndSelections from './ViewAndSelections.vue'
import ContractImportModal from './ContractImportModal.vue'
import moment from 'moment'
import { getBooleanString } from '../../helpers/mappers/booleanString.js'

export default {
  name: 'EsrCarparksLevel',
  components: {
    Table,
    ImportButton,
    ViewAndSelections,
    ContractImportModal
  },
  props: {
    loading: { type: Boolean, default: false },
    carparks: { type: Array, default: () => [] },
    importing: { type: Boolean, default: false },
  },
  emits: ['refreshData'],
  data () {
    return {
      showColumnsFilter: false,
      exportAsDocument: [],
      requiredFields: ['carpark_code_long', 'contract_number', 'business_id', 'start_date'],

      pagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['siteName']
      },

      dataUpdateDialog: false,
      importResults: null,
      importIdentifier: null,

      newContractsErrors: [],
      updatingErrors: [],
      isSavingChangedContracts: false,
      isSavingNewContracts: false,
      exporting: {},
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    isMobile () {
      return visualViewport.width <= 760
    },
    headers () {
      return [
        {
          text: this.$t('Site'),
          value: 'siteName',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('Site identifier'),
          value: 'site_identifier',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('Building name'),
          value: 'building_name',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('Floor'),
          value: 'floor',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('Carpark code long'),
          value: 'carpark_code_long',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Nro'),
          value: 'carparksString',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('Contract area'),
          value: 'contract_area',
          format: 'Area',
          type: undefined,
          editable: true
        },
        {
          value: 'contractInfo',
          text: this.$t('Contract details'),
          format: '',
          editable: true
        },
        {
          text: this.$t('Contract number'),
          value: 'contract_number',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('VAT-responsibility'),
          value: 'vat_responsibility',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Type'),
          value: 'types',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('Tenant'),
          value: 'tenant',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Business ID'),
          value: 'business_id',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract party'),
          value: 'contract_party',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Validity'),
          value: 'validity',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract date'),
          value: 'signature_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract start date'),
          value: 'start_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract first possible end date'),
          value: 'first_possible_end_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract end date'),
          value: 'end_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.tenant_first_possible_end_date'),
          value: 'tenant_first_possible_end_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Agreement notice period (Tenant)'),
          value: 'tenant_notice',
          format: 'Number',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.landlord_first_possible_end_date'),
          value: 'landlord_first_possible_end_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Agreement notice period (Lanlord)'),
          value: 'landlord_notice',
          format: 'Number',
          editable: true
        },
        {
          text: this.$t('Validity notice'),
          value: 'validity_note',
          editable: true
        },
        {
          value: 'additional_info',
          text: this.$t('leasing.additional_fixed_term_info'),
          format: '',
          editable: true
        },
        {
          text: this.$t('Carpark.area'),
          value: 'area',
          format: 'Area',
          type: undefined,
          ignoreHeader: true
        },
        {
          text: this.$t('leasing.contract_type'),
          value: 'contract_type',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Industry'),
          value: 'industry',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract status'),
          value: 'contract_status',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('haltia.Maksuissa poikkeavat voimassaoloajat'),
          value: 'payments_different_validity_periods',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Capital rent'),
          value: 'capital_rent',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Maintenance rent'),
          value: 'maintenance_rent',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.maintenance_rent_equalization'),
          value: 'maintenance_rent_equalization',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.changeover_rent'),
          value: 'changeover_rent',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.separate_allowances'),
          value: 'separate_allowances',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.discounts'),
          value: 'discounts',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Discounts start date'),
          value: 'discounts_start_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Discounts end date'),
          value: 'discounts_end_date',
          format: 'Date',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('leasing.lease_responsible'),
          value: 'rental_responsibility',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Contract turnover tieup'),
          value: 'contract_turnover_tieup',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Turnover tied rent'),
          value: 'turnover_related_rent',
          format: 'Euro',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Turnover percentage'),
          value: 'turnover_percentage',
          format: 'Percentage',
          type: undefined,
          editable: true
        },
        {
          value: 'turnoverRelatedInfo',
          text: this.$t('Turnover related info'),
          format: '',
          editable: true,
        },
        {
          text: this.$t('BasicRent'),
          value: 'basic_rent',
          format: 'Euro',
          type: undefined,
          editable: true
        },
         {
          text: this.$t('rental_status.rent_review_type'),
          value: 'rent_review_type',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.index_type'),
          value: 'index_type',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.basic_index_point_number'),
          value: 'basic_index_point_number',
          format: 'Number',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.review_months'),
          value: 'review_months',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.latest_index_year'),
          value: 'latest_index_year',
          format: 'Number',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.latest_index_month'),
          value: 'latest_index_month',
          format: 'Number',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.latest_index_point_number'),
          value: 'latest_index_point_number',
          format: 'Number',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('rental_status.minimum_increase'),
          value: 'minimum_increase',
          format: 'Percentage',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('haltia.Kiinteä korotus (prosentti)'),
          value: 'fixed_increase',
          format: 'Percentage',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('Review basis'),
          value: 'check_based',
          type: undefined,
          editable: true
        },
        {
          text: this.$t('haltia.Vuokrantarkistus alkaa'),
          value: 'rentcheck_start_date',
          format: 'Date',
          type: undefined,
          editable: true
        }
      ]
    },
    carparksWithStatuses () {
      return this.addStatuses(this.carparks)
    },
    viewData () {
      return this.getCarparkContractData()
    }
  },
  methods: {
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType

      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('esr_carparks')
    },

    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },

    getCarparkContractData () {
      const data = this.addStatuses(this.carparks)

      const rows = []

      data.filter(d => new Date(d.start_date) <= new Date(this.currentDate)).forEach(d => {
        if (d.contract_number) {
          const index = rows.findIndex(row => row.contract_number === d.contract_number)
          if (index >= 0) {
            const buildingIndex = rows[index].carparkCodes.findIndex(b => b.buildingCode === d.building_code)
            if (buildingIndex >= 0) {
              rows[index].carparkCodes[buildingIndex].carparks.push(d.carpark_code)
            } else {
              rows[index].carparkCodes.push({buildingCode: d.building_code, carparks: [d.carpark_code]})
            }


            if(d.carpark_code_long && d.carpark_code_long !== ""){
              rows[index].carparkCodesLong.push(d.carpark_code_long)
            }
            if (d.type && rows[index].types.findIndex(type => type === d.type) < 0) {
              rows[index].types.push(d.type)
            }

            if (d.area) rows[index].area += d.area
          } else {
            const newRow = {...d}
            newRow.carparkCodes = [{buildingCode: d.building_code, carparks: [d.carpark_code]}]
            newRow.carparkCodesLong = [d.carpark_code_long]
            newRow.types = [d.type]
            rows.push(newRow)
          }
        }
      })

      rows.forEach(row => {
        row.carparksString = row.carparkCodes.reduce((acc, curr, index) => {
          let text = acc
          if (index > 0) {
            text += '<br/>'
          }
          if (curr.buildingCode) {
            text += `<b>${curr.buildingCode}: </b>`
          }
          return `${text}${curr.carparks.join(', ')}`
        }, '')

        row.carpark_code_long = row.carparkCodesLong.join(",\n")

        // Set start and end date from contract data (not contract party data)
        row.start_date = row.contract_start_date
        row.end_date = row.contract_end_date

      })
      return rows
    },

    getRequiredFields () {
      return this.headers.filter(header => this.requiredFields.includes(header.value)).map(header => header.text)
    },

    addStatuses (items) {
      return items.map(rs => ({
        ...rs,
        vat_responsibility: getBooleanString(rs.vat_responsibility),
        contract_turnover_tieup: getBooleanString(rs.contract_turnover_tieup),
        payments_different_validity_periods: getBooleanString(rs.payments_different_validity_periods),
        validity_note: getBooleanString(rs.validity_note),
        siteName: rs.site_name
      }))
    },
    async saveFile (results) {
      this.dataUpdateDialog = true
      this.importResults = results
      this.importIdentifier = 'carpark_code_long'
    },
    resetImportChanges () {
      this.dataUpdateDialog = false
      this.isSavingChangedContracts = false,
      this.isSavingNewContracts = false
      this.newContractsErrors = []
      this.updatingErrors = []
    },
    async saveContracts (newContracts, contractChanges, callback) {
      if (newContracts.length > 0 ) {
        await this.saveNewContracts(newContracts)
      }
      await this.saveChangedContracts(contractChanges)
      callback()
    },
    async saveChangedContracts (data) {
      this.isSavingChangedContracts = true
      let errors = []
      try {
        errors = await this.$rambollfmapi.carparks.contracts.put(data)
        if (errors.name === 'Error') {
          throw Error
        }
        this.updatingErrors = this.handleErrors(errors)
      } catch (err) {
        // In case of connection error, we add error for each data row
        data.forEach(() => this.updatingErrors.push({label: '', value: this.$t('dataimport.unexpected_connection_error')}))
      }
      this.isSavingChangedContracts = false
    },
    async saveNewContracts (data) {
      this.isSavingNewContracts = true
      let errors = []
      try {
        errors = await this.$rambollfmapi.carparks.contracts.post(data)
        if (errors.name === 'Error') {
          throw Error
        }
        this.newContractsErrors = this.handleErrors(errors)
      } catch (err) {
        // In case of connection error, we add error for each data row
        data.forEach(() =>  this.newContractsErrors.push({label: '', value: this.$t('dataimport.unexpected_connection_error')}))
      }
      this.isSavingNewContracts = false
    },
    handleErrors (errors) {
      if (errors.length > 0) {
        const startDate = this.headers.find(header => header.value === 'start_date').text
        const endDate = this.headers.find(header => header.value === 'end_date').text
        const contractNumber = this.headers.find(header => header.value === 'contract_number').text
        const carparkCodeLong = this.headers.find(header => header.value === 'carpark_code_long').text
        const businessId = this.headers.find(header => header.value === 'business_id').text
        return errors.map( err => {
          const errObject = { type: err.label, identifier: `${err.carparkCodeLong ?? ''}`}
          switch (err.label) {
            case 'Overlapping': {
              const start = err.startDate ? moment(new Date(err.startDate)).format('DD.MM.YYYY') : '-'
              const end = err.endDate ? moment(new Date(err.endDate)).format('DD.MM.YYYY') : '-'
              return {
                ...errObject,
                value: `${err.contractNumber}, ${startDate}: ${start}, ${endDate}: ${end}`,
                label: `${err.newContract ? this.$t("dataimport.new_contract") : ""}${contractNumber}`
              }
            }
            case 'PartyNotFound': {
              return {
                ...errObject,
                value: err.value + ' ' + this.$t('dataimport.party_not_found'),
                label: businessId
              }
            }
            case 'BusinessIdMissing': {
              return {
                ...errObject,
                value: err.value + ' ' + this.$t('dataimport.business_id_missing'),
                label: businessId
              }
            }
            case 'CarparkNotFound': {
              return {
                ...errObject,
                value: this.$t('dataimport.carpark_not_found'),
                label: carparkCodeLong
              }
            }
            case 'UnknownError': {
              return {
                ...errObject,
                value: `${err.contractNumber} ${err.value}`,
                label: contractNumber
              }
            }
            case 'LinkError': {
              return {
                ...errObject,
                value: err.carparkCodeLong + '. ' + this.$t('dataimport.party_linking_error'),
                label: carparkCodeLong
              }
            }
            case 'PartyCouldNotBeCreated': {
                return {
                ...errObject,
                value: this.$t('dataimport.party_creation_error'),
              }
            }
            case 'CarparkAllreadyStarted': {
              const start = err.startDate ? moment(new Date(err.startDate)).format('DD.MM.YYYY') : '-'
                return {
                ...errObject,
                value: `${err.contractNumber} - ${this.$t(err.value)} ${start}`,
                label: startDate
              }
            }
            default: {
              return {
                ...errObject,
                value: this.$t('dataimport.unexpected_connection_error'),
                label: ''
              }
            }
          }
        })
      }
    },
  }
}
</script>

<style>
</style>