<template>
  <v-dialog
    v-model="addAllocationDiag"
    persistent
    width="550"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        fab
        small
        outlined
        color="blue"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </template>

    <BaseModal
      @cancel="cancel()"
    >
      <template #title>
        {{ $t('New object') }}
      </template>
      <template #content>
        <v-text-field
          v-model="inputName"
          :label="$t('Name')"
          :rules="[rules.required, rules.counter]" 
          class="form-fields"
          required
          counter
          maxlength="40"
        />
        <v-select
          v-show="allocation !== 1"
          v-model="inputParent"
          :label="$t('Parent level')"
          :items="parents.filter(p => p.level === (allocation - 1))"
          item-text="label"
          item-value="label"
          class="form-fields"
          required
        />
      </template>
      <template #footer>
        <v-btn
          rounded
          text
          class="primary"
          @click="addNode(allocation)" 
        >
          {{ $t('Add') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>
<script>
import BaseModal from '.././general/BaseModal.vue'

export default {
  emits: ['addNode'],
  components: {
    BaseModal,
  },
  props: {
    allocation: {
      default: 0,
      type: Number
    },
    parents: {
      default () { return [] },
      type: Array
    }
  },
  
  data () {
    return {
      inputName: "",
      inputParent: null,
      dialog: false,
      addAllocationDiag: false,
      rules: {
        required: value => !!value || this.$t('Required'),
        counter: value => value.length <= 40 || this.$t('Max name length 40'),
      }
    }
  },
  
  methods: {
    addNode (level)
    {
      this.addAllocationDiag = false
      this.$emit('addNode', this.inputName, this.inputParent, level)
      this.inputName = ""
      this.inputParent = null
    },
    cancel () {
      this.addAllocationDiag = false
      this.inputName = ""
      this.inputParent = null
    }
  }
}
</script>