<template>
  <div id="status-portfolios">
    <v-dialog
      v-model="statusPortfolioEditDialog"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-row
          v-if="statusPortfolioEditDialog"
          class="flex-column"
        >
          <v-col>
            <BaseModal
              @cancel="closeStatusPortfolioEditDialog(false)"
            >
              <template #title>
                <v-container v-if="editing">
                  {{ $t('portfolios.edit_status_portfolio') }}
                </v-container>
                <v-container v-else>
                  {{ $t('portfolios.new_status_portfolio') }}
                </v-container>
              </template>
              <template #content>
                <v-form
                  ref="portfolioForm"
                  lazy-validation
                >
                  <br>
                  <v-col cols="12">
                    <v-row
                                            
                      justify="center"
                      align="center"
                    >
                      <v-col
                        v-if="!editing"
                        cols="6"
                      >
                        <v-text-field
                          v-model="newName"
                          :label="$t('portfolios.new_status_portfolio_name')"
                          @change="changeNameOrRentType('name')"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-if="portfolios.length > 0 && editing"
                          v-model="selectedStatusPortfolio"
                          :items="sortedPortfolios"
                          :label="$t('portfolios.select_status_portfolio')"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          @change="setSelectedStatusPortfolio()"
                        />
                      </v-col>
                      <v-col
                        v-if="selectedStatusPortfolio"
                        cols="6"
                      >
                        <v-text-field
                          v-model="newName"
                          :label="$t('portfolios.rename_status_portfolio_name')"
                          @change="changeNameOrRentType('name')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="selectedStatusPortfolio || !editing">
                    <br>
                    <br>
                    <v-col class="section-textfield">
                      <strong class="text--secondary">
                        {{ $t('portfolios.counting_basis') }}
                      </strong>
                    </v-col>
                    <v-container>
                      <v-col cols="12">
                        <v-row
                          class="align-right"
                          
                          justify="start"
                        >
                          <v-col cols="5">
                            <v-switch
                              v-model="grossRent"
                              :label="$t('portfolios.gross_rent')"
                              disabled
                              class="shrink-switch"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-container>
                    <br>
                    <v-col class="section-textfield">
                      <strong class="text--secondary">
                        {{ $t('portfolios.selected_spaces') }}
                      </strong>
                    </v-col>
                    <br>
                    <v-col class="filters">
                      <v-expansion-panels
                        :value="[0]"
                        multiple
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            {{ $t('Filters') }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="3">
                                <v-text-field
                                  v-model="filterName"
                                  :label="$t('Site name')"
                                />
                              </v-col>
                              <v-col cols="3">
                                <v-select
                                  v-model="filterSelected"
                                  :items="statusIconValues"
                                  :label="$t('portfolios.selected_to_status_portfolio')"
                                  clearable
                                />
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  v-model="filterPostOffice"
                                  :label="$t('Post office')"
                                />
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  v-model="filterPortfolio"
                                  :label="$t('Portfolio')"
                                />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-expansion-panels
                      :value="[0]"
                      multiple
                    >                 
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('Information') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table
                            :options.sync="options"
                            :header-props="dataTableHeaderDefinitions"
                            :headers="tableHeaders"
                            :items="processedSites"
                            :footer-props="{
                              itemsPerPageOptions: [],
                            }"
                            :no-data-text="$t('No data available')"
                            class="elevation-0 full-width"
                          >
                            <template #item="props">
                              <tr>
                                <td>
                                  <v-switch
                                    :input-value="props.item.status !== 'None'"
                                    :color="props.item.status === 'Partial' ? 'orange' : undefined"
                                    :disabled="props.item.disabled"
                                    :label="$t('portfolios.in_status_portfolio')"
                                    hide-details
                                    @change="toggleSelection(props.item)"
                                  />
                                </td>
                                <td>
                                  <v-row>
                                    <v-row
                                      :class="{ 'reserved': props.item.reserved }"
                                      align="center"
                                    >
                                      {{ props.item.name }}
                                      <v-icon
                                        v-if="props.item.reserved"
                                        class="ml-2 reserved"
                                        small
                                      >
                                        new_releases
                                      </v-icon>
                                    </v-row>
                                    <v-row
                                      class="shrink flex-column"
                                    >
                                      <v-btn
                                        icon
                                        @click="toggleSite(props.item.idSite)"
                                      >
                                        <span v-if="isSiteOpen(props.item.idSite)">
                                          <v-icon>keyboard_arrow_up</v-icon>
                                          <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                                        </span>
                                        <span v-else>
                                          <v-icon>keyboard_arrow_down</v-icon>
                                          <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                                        </span>
                                      </v-btn>
                                    </v-row>
                                  </v-row>
                                </td>
                                <td>
                                  <right-status :right="props.item.status" />
                                </td>
                                <td>{{ props.item.postOffice }}</td>
                                <td>{{ props.item.portfolio }}</td>
                                <td>{{ props.item.selectedSpaces }}</td>
                                <td>{{ props.item.totalSpaces }}</td>
                              </tr>
                              <template v-if="isSiteOpen(props.item.idSite)">
                                <template v-for="building in props.item.buildings">
                                  <tr                              
                                    :key="building.type + building.id"
                                    class="hierarchy-item"
                                  >
                                    <td>
                                      <v-switch
                                        :input-value="building.status !== 'None'"
                                        :color="building.status === 'Partial' ? 'orange' : undefined"
                                        :disabled="building.disabled"
                                        :label="$t('portfolios.building_status')"
                                        hide-details
                                        @change="toggleSelection(props.item, building)"
                                      />
                                    </td>
                                    <td>
                                      <v-row>
                                        <v-row
                                          :class="{ 'reserved': building.reserved }"
                                          align="center"
                                        >
                                          <v-icon
                                            :class="{ 'reserved': building.reserved }"
                                            medium
                                          >
                                            subdirectory_arrow_right
                                          </v-icon>
                                          {{ building.name }}
                                          <v-icon
                                            v-if="building.reserved"
                                            class="ml-2 reserved"
                                            small
                                          >
                                            new_releases
                                          </v-icon>
                                        </v-row>
                                        <v-row
                                          class="shrink flex-column"
                                        >
                                          <v-btn
                                            icon
                                            @click="toggleBuilding(building.id)"
                                          >
                                            <span v-if="isBuildingOpen(building.id)">
                                              <v-icon>keyboard_arrow_up</v-icon>
                                              <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                                            </span>
                                            <span v-else>
                                              <v-icon>keyboard_arrow_down</v-icon>
                                              <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                                            </span>
                                          </v-btn>
                                        </v-row>
                                      </v-row>
                                    </td>
                                    <td>
                                      <right-status :right="building.status" />
                                    </td>
                                    <td />
                                    <td />
                                    <td>{{ building.selectedSpaces }}</td>
                                    <td>{{ building.totalSpaces }}</td>
                                  </tr>
                                  <template v-if="isBuildingOpen(building.id)">
                                    <template v-for="floor in building.floors">
                                      <tr                                  
                                        :key="floor.type + floor.id"
                                        class="hierarchy-item"
                                      >
                                        <td>
                                          <v-switch
                                            :input-value="floor.status !== 'None'"
                                            :color="floor.status === 'Partial' ? 'orange' : undefined"
                                            :disabled="floor.disabled"
                                            :label="$t('portfolios.floor_status')"
                                            hide-details
                                            @change="toggleSelection(props.item, building.code, floor)"
                                          />
                                        </td>
                                        <td>
                                          <v-row>
                                            <v-row
                                              :class="{ 'reserved': floor.reserved }"
                                              align="center"
                                            >
                                              <v-icon
                                                :class="{ 'reserved': floor.reserved }"
                                                class="ml-2"
                                              >
                                                subdirectory_arrow_right
                                              </v-icon>
                                              {{ floor.name }}
                                              <v-icon
                                                v-if="floor.reserved"
                                                class="ml-2 reserved"
                                                small
                                              >
                                                new_releases
                                              </v-icon>
                                            </v-row>
                                            <v-row
                                              class="shrink flex-column"
                                            >
                                              <v-btn
                                                icon
                                                @click="toggleFloor(floor.id)"
                                              >
                                                <span v-if="isFloorOpen(floor.id)">
                                                  <v-icon>keyboard_arrow_up</v-icon>
                                                  <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                                                </span>
                                                <span v-else>
                                                  <v-icon>keyboard_arrow_down</v-icon>
                                                  <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                                                </span>
                                              </v-btn>
                                            </v-row>
                                          </v-row>
                                        </td>
                                        <td>
                                          <right-status :right="floor.status" />
                                        </td>
                                        <td />
                                        <td />
                                        <td>{{ floor.selectedSpaces }}</td>
                                        <td>{{ floor.totalSpaces }}</td>
                                      </tr>
                                      <template v-if="isFloorOpen(floor.id)">
                                        <tr
                                          v-for="space in floor.spaces"                              
                                          :key="space.type + space.id"
                                          class="hierarchy-item"
                                        >
                                          <td>
                                            <v-switch
                                              :input-value="space.status !== 'None'"
                                              :disabled="space.reserved"
                                              :label="$t('portfolios.space_status')"
                                              hide-details
                                              @change="toggleSelection(props.item, building.code, floor.id, space)"
                                            />
                                          </td>
                                          <td>
                                            <v-row>
                                              <v-row
                                                :class="{ 'reserved': space.reserved }"
                                                align="center"
                                              >
                                                <v-icon
                                                  :class="{ 'reserved': space.reserved }"
                                                  class="ml-4"
                                                  small
                                                >
                                                  subdirectory_arrow_right
                                                </v-icon>
                                                {{ space.name }}
                                                <v-icon
                                                  v-if="space.reserved"
                                                  class="ml-2 reserved"
                                                  small
                                                >
                                                  new_releases
                                                </v-icon>
                                              </v-row>
                                            </v-row>
                                          </td>
                                          <td>
                                            <right-status :right="space.status" />
                                          </td>
                                        </tr>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                            <template
                              v-slot:footer.page-text="item"
                            >
                              {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                              {{ item.itemsLength.toLocaleString('fi-FI') }}
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <br>
                      <v-row
                        align="end"
                        justify="end"
                      />
                    </v-expansion-panels>
                  </v-col>
                </v-form>
              </template>
              <template
                #footer
              >
                <v-btn
                  :disabled="checkDeleteDisabled"
                  rounded
                  depressed
                  color="error"
                  @click="deleteThisPortfolio(selectedStatusPortfolio)"
                >
                  {{ $t('Delete') }}
                </v-btn>
                <v-btn
                  :disabled="checkSavingDisabled"
                  rounded
                  depressed
                  color="primary"
                  @click="savePortfolioChanges"
                >
                  {{ $t('Save') }}
                </v-btn>
              </template>
            </BaseModal>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-row
      class="layout-white flex-column"
    >
      <v-toolbar
        flat
        tile
      >
        <v-toolbar-title>
          <h1>{{ $t('Status portfolios') }}</h1>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items v-if="hasApplicationPermissionByName('SIS_TILASALKUT_MUOKKAUS')">
          <v-btn
            icon
            @click="openStatusPortfolioEditDialog()"
          >
            <v-icon>edit</v-icon>
            <span class="d-sr-only">{{ $t('portfolios.edit_status_portfolio') }}</span>
          </v-btn>
          <v-btn
            icon
            @click="openNewStatusPortfolioDialog()"
          >
            <v-icon>add_circle_outline</v-icon>
            <span class="d-sr-only">{{ $t('portfolios.new_status_portfolio') }}</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-row>
    <v-col
      class="ma-3"
      sm="12"
      md="6"
    >
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          class="no-padding"
        >
          <v-expansion-panel-header>
            {{ $t("Status portfolio") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="isLoading"
              style="text-align: center; padding: 2em;"
            >
              <v-progress-circular
                size="64"
                indeterminate
                color="primary"
              />
            </div>
            <v-data-table
              v-else
              :header-props="dataTableHeaderDefinitions"
              :headers="statusPortfolioHeaders"
              :items="portfoliosTableData"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
              class="elevation-0"
              mobile-breakpoint="0"
            >
              <template
                v-slot:item="props"
              >
                <tr>
                  <td
                    v-for="header in statusPortfolioHeaders"
                    :key="header.value"
                  >
                    <div :class="{ 'col-align-right': isRightAligned(header.format) }">
                      {{ getValue(props.item[header.value]) }}
                    </div>
                  </td>
                </tr>
              </template>
              <template
                v-slot:footer.page-text="item"
              >
                {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                {{ item.itemsLength.toLocaleString('fi-FI') }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </div>
</template>
<script>
import helpers from '../helpers'
import { mapActions, mapGetters, mapState } from 'vuex'
import RightStatus from '../components/RightStatus.vue'
import BaseModal from '../components/general/BaseModal.vue'

export default {
  name: 'StatusPortfolios',
  components: {
    RightStatus: RightStatus,
    BaseModal,
  },
  metaInfo () {
    return {
      title: `${this.$t('Status portfolios')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      isLoading: true,
      selectedStatusPortfolio: null,
      newName: undefined,
      grossRent: true,
      squareBasedRent: false,
      openStatusPortfolio: {
        name: null,
        grossRent: null,
        spaces: []
      },
      newStatusPortfolio: {
        name: null,
        grossRent: true,
        spaces: []
      },
      portfoliosTableData: [],
      statusPortfolioEditDialog: false,
      statusPortfolioHeaders: [
        {
          text: this.$t('Status portfolio'),
          value: 'name'
        },
        { text: this.$t('Buildings'), value: 'buildings' },
        { text: this.$t('portfolios.gross_rent'), value: 'grossRent' }
      ],
      options: {
        sortDesc: [false],
        page: 1,
        itemsPerPage: 30,
        totalItems: 0
      },
      valid: false,
      nameOrRentTypeChanged: false,
      editing: false,
      filterName: '',      
      filterSelected: null,
      filterPostOffice: '',
      filterPortfolio: '',
      statusIconValues: [
        { text: this.$t('rightlevel.Full'), value: 'Full' },
        { text: this.$t('rightlevel.Partial'), value: 'Partial' },
        { text: this.$t('rightlevel.None'), value: 'None' }
      ],
      tableHeaders: [
        { 
          text: this.$t('Select'),
          sortable: false
        },
        {
          text: this.$t('Site name'),
          value: 'name'
        },
        {
          text: this.$t('portfolios.selected_to_status_portfolio'),
          value: 'selected'
        },
        {
          text: this.$t('Post office'),
          value: 'postOffice'
        },
        {
          text: this.$t('Portfolio'),
          value: 'portfolio'
        },
        {
          text: this.$t('Valittuja huoneita'),
          value: 'selectedSpaces'
        },
        {
          text: this.$t('Huoneita yht.'),
          value: 'totalSpaces'
        }
      ],
      openSites: [],
      openBuildings: [],
      openFloors: [],
      spaces: [],
      portfolioSpaces: [],
      reservedSpaces: [],
      reservedSites: [],
      reservedBuildings: [],
      reservedFloors: [],
      floors: [],
      tableItems: []
    }
  },
  computed: {
    ...mapGetters('app', ['definitionLabelById', 'dataTableHeaderDefinitions', 'hasApplicationPermissionByName']),
    ...mapState('internalRent', ['portfolios', 'portfolio']),
    ...mapState('app', ['buildings', 'sites']),
    sortedPortfolios () {
      return this.portfolios.concat().sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
    },
    processedSites () {
      let sites = [...this.tableItems]

      if (this.filterName !== '') {
        sites = sites.filter(x => x.name.toLowerCase().includes(this.filterName.toLowerCase()))
      }

      if (this.filterSelected) {
        sites = sites.filter(x => {
          return x.status === this.filterSelected
        })
      }

      if (this.filterPostOffice !== '') {
        sites = sites.filter(x => {
          return x.postOffice.toLowerCase().includes(this.filterPostOffice.toLowerCase())
        })
        
      }

      if (this.filterPortfolio !== '') {
        sites = sites.filter(x => x.portfolio.toLowerCase().includes(this.filterPortfolio.toLowerCase()))
      }

      return sites
    },
    checkPortfolioChanges () {
      // original space list
      const a = this.openStatusPortfolio.spaces
      a.sort((a, b) => a < b ? -1 : 1)
      // modified space list
      const b = this.portfolioSpaces
      b.sort((a, b) => a < b ? -1 : 1)
      if (a.length !== b.length) {
        return true
      }
      if (JSON.stringify(a) !== JSON.stringify(b)) {
        return true
      }
      return false
    },
    checkDeleteDisabled () {
      if (this.openStatusPortfolio.spaces.length > 0) {
        return true
      }
      if (this.selectedStatusPortfolio === null) {
        return true
      }
      if (!this.editing) {
        return true
      }
      return false
    },
    checkSavingDisabled () {
      if (this.newName) {
        // prevent naming the portfolio with same name than any existing portfolio
        return this.portfolios.map(portfolio => portfolio.name.toLowerCase()).includes(this.newName.toLowerCase())
      }
      if (this.editing) {
        return !this.checkPortfolioChanges && this.openStatusPortfolio.grossRent === this.grossRent && !this.newName
      } 
      return true     
    }
  },
  watch: {},
  mounted: async function () {
    await this.getPortfolios()
    await this.getBuildings()
    this.floors = await this.$rambollfmapi.floors.list({ query: { nodwgdata: true } })
    this.spaces = await this.$rambollfmapi.spaces.list()
    await this.portfolioTableData()
    this.isLoading = false
  },
  methods: {
    ...mapActions('internalRent', [
      'getPortfolios',
      'getPortfolio',
      'saveNewPortfolio',
      'saveNewNameOrRentType',
      'deletePortfolio'
    ]),
    ...mapActions('app', ['getBuildings']),
    openStatusPortfolioEditDialog () {
      this.editing = true
      if (this.portfolios.length < 1) {
        this.openStatusPortfolio = this.newStatusPortfolio
      }
      this.statusPortfolioEditDialog = true
    },
    async openNewStatusPortfolioDialog () {
      this.editing = false
      this.portfolioSpaces = []
      this.openStatusPortfolio = this.newStatusPortfolio
      this.getPortfolios()
      await this.initSites()
      this.statusPortfolioEditDialog = true
    },
    closeStatusPortfolioEditDialog (saved) {
      if(!saved && this.checkPortfolioChanges) {
        if(!confirm(this.$t('Unsaved changes'))) {
          return
        }
      }
      this.openStatusPortfolio = this.newStatusPortfolio
      this.portfolioSpaces = [],
      this.grossRent = true,
      this.squareBasedRent = false,
      this.selectedStatusPortfolio = null
      this.newName = undefined
      this.nameOrRentTypeChanged = false
      this.statusPortfolioEditDialog = false
      this.editing = false
      this.openSites = []
      this.openBuildings = []
      this.openFloors = []
      this.reservedSpaces = []
      this.reservedSites = []
      this.reservedBuildings = []
      this.reservedFloors = []
      this.tableItems = []
    },
    confirmRentChanges (message) {
      var r = confirm(message)
      if (r === true) {
        return true
      }
      return false
    },
    confirmBuildingChanges (message) {
      var r = confirm(message)
      if (r === true) {
        return true
      }
      return false
    },
    async savePortfolioChanges () {
      if (this.$refs.portfolioForm.validate()) {
        const portfolio = {
          ...this.openStatusPortfolio,
          spaces: this.portfolioSpaces,
          ...this.grossRent
        }
        if (this.editing) {
          if (portfolio.id) {
            if (this.nameOrRentTypeChanged) {
              const confirmMessage = this.$t(
                'portfolios.calculation_changed_verification'
              )
              // construct the params object for name or rent type change
              const params = {
                id: portfolio.id,
                data: [
                  {
                    value: this.newName ? this.newName : portfolio.name,
                    path: '/name',
                    op: 'replace'
                  },
                  { value: this.grossRent, path: '/grossRent', op: 'replace' }
                ]
              }
              // If portfolio has fed values hit the confirm message to to change the calculation
              // else just patch the change (portfolio name)
              if (
                ((this.portfolio.yearExpenseBudgets &&
                  this.portfolio.yearExpenseBudgets.length > 0) ||
                  (this.portfolio.yearExpenseRealizations &&
                    this.portfolio.yearExpenseRealizations.length > 0)) &&
                this.grossRent !== this.openStatusPortfolio.grossRent
              ) {
                if (this.confirmRentChanges(confirmMessage)) {
                  await this.saveNewNameOrRentType(params)
                } else {
                  return
                }
              } else {
                await this.saveNewNameOrRentType(params)
              }
            }
            if (this.checkPortfolioChanges) {
              const confirmMessage = this.$t('portfolios.verify_spaces_transfer')
              // construct the params object for building change
              const params = {
                id: portfolio.id,
                data: [
                  {
                    value: portfolio.spaces,
                    path: '/spaces',
                    op: 'replace'
                  },
                  { value: this.grossRent, path: '/grossRent', op: 'replace' }
                ]
              }
              if (this.confirmBuildingChanges(confirmMessage)) {
                await this.saveNewNameOrRentType(params)
              } else {
                return
              }
            }
            await this.getPortfolios()
            await this.portfolioTableData()
            this.closeStatusPortfolioEditDialog(true)
          }
        }
        else {
          const params = {
            id: 0,
            name: this.newName ? this.newName : portfolio.name,
            spaces: portfolio.spaces,
            grossRent: this.grossRent
          }

          await this.saveNewPortfolio(params)
          await this.getPortfolios()
          await this.portfolioTableData()
          this.closeStatusPortfolioEditDialog(true)
        }
      }
    },
    async deleteThisPortfolio (portfolioId) {

      if (!confirm(this.$t('Confirm portfolio deletion') + '\n' + this.$t('All portfolio spaces will be lost'))) {
        return 
      }

      await this.deletePortfolio(portfolioId)
      await this.getPortfolios()
      await this.portfolioTableData()
      this.closeStatusPortfolioEditDialog(true)
    },
    async setSelectedStatusPortfolio () {
      this.openSites = []
      this.openBuildings = []
      this.openFloors = []
      this.newName = undefined
      this.openStatusPortfolio = this.portfolios.find(statusPortfolio => {
        return statusPortfolio.id === this.selectedStatusPortfolio
      })
      this.portfolioSpaces = [...this.openStatusPortfolio.spaces]
      this.grossRent = this.openStatusPortfolio.grossRent
      this.squareBasedRent = !this.openStatusPortfolio.grossRent
      await this.initSites()
      await this.getPortfolio({ portfolioId: this.openStatusPortfolio.id })
    },
    setReservedSpaces () {
      const reservedSpaces = []
      const reservedBuildings = new Set()
      const reservedFloors = new Set()
      const reservedSites = new Set()

      if (this.editing) {
        this.portfolios.filter(portfolio => portfolio.id !== this.selectedStatusPortfolio)
          .map(x => x.spaces)
          .forEach(x => reservedSpaces.push(...x))
      } else {
       this.portfolios.map(x => x.spaces).forEach(x => reservedSpaces.push(...x))
      }
      this.reservedSpaces = reservedSpaces
      this.spaces.forEach(x => {
        if (reservedSpaces.includes(x.id)) {
          reservedBuildings.add(x.building_code)
          reservedFloors.add(x.id_floor)
        }
      })
      this.reservedBuildings = [...reservedBuildings]
      this.reservedFloors = [...reservedFloors]
      this.reservedBuildings.forEach(buildingCode => {
        const foundBuilding = this.buildings.find(building => building.building_code === buildingCode)
        if (foundBuilding) {
          reservedSites.add(foundBuilding.id_site)
        }
      })
      this.reservedSites = [...reservedSites]
      
    },
    changeNameOrRentType (changed) {
      this.nameOrRentTypeChanged = true
    },
    portfolioTableData () {
      const portfolios = this.portfolios.map(portfolio => {
        const name = portfolio.name
        const buildings = [] 

        portfolio.buildings.map(buildingCode => {
          this.buildings.forEach(b => {
            if (b.building_code === buildingCode) {
              buildings.push(b.building_name)
            }
          })
        })

        const grossRent = portfolio.grossRent

        return { name, buildings, grossRent }
      })
      this.portfoliosTableData = portfolios
    },
    getValue (exampleValue) {
      if (typeof exampleValue === 'boolean') {
        return exampleValue ? 'X' : ''
      }
      if (Array.isArray(exampleValue)) {
        exampleValue = exampleValue.join(', ')
      }

      return exampleValue
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    selectedStatus (spacesCount, selectedCount) {
      if (selectedCount === 0) {
        return 'None'
      } else if (selectedCount < spacesCount) {
        return 'Partial'
      }
      return 'Full'
    },
    toggleSite (siteId) {
      if (this.isSiteOpen(siteId)) {
              this.openSites = this.openSites.filter(x => x !== siteId)
      } else {
        this.openSites.push(siteId)
      }
    },
    toggleBuilding (buildingId) {
       if (this.isBuildingOpen(buildingId)) {
        this.openBuildings = this.openBuildings.filter(x => x !== buildingId)
      } else {
        this.openBuildings.push(buildingId)
      }
    },
    toggleFloor (floorId) {
       if (this.isFloorOpen(floorId)) {
        this.openFloors = this.openFloors.filter(x => x !== floorId)
      } else {
        this.openFloors.push(floorId)
      }
    },
    isSiteOpen (siteId) {
      return this.openSites.includes(siteId)
    },
    isBuildingOpen (buildingId) {
      return this.openBuildings.includes(buildingId)
    },
    isFloorOpen (floorId) {
      return this.openFloors.includes(floorId)
    },
    toggleSelection (item, buildingItem = undefined, floorItem = undefined, spaceItem = undefined) {
      const tempItem = {...item}
      const spaceIds = []
      const originalStatus = spaceItem ? spaceItem.status : floorItem ? floorItem.status : buildingItem ? buildingItem.status : item.status
      const status = originalStatus === 'None' ? 'Full' : 'None'
      let selectedCount = 0
      const buildingCode = floorItem ? buildingItem : buildingItem ? buildingItem.code : undefined
      const floorId = spaceItem ? floorItem : floorItem ? floorItem.id : undefined
      if (!buildingItem) {
        // site switch selection
        tempItem.buildings.forEach(building => {
          let selectedSpaceCount = 0
          building.floors.forEach(floor => {
            let selectedSpaces = 0
            floor.spaces.forEach(space => {
              if(!space.reserved) {
                space.status = status
                spaceIds.push(space.id)
                selectedSpaces += space.status === 'Full' ? 1 : 0
              }
            })
            floor.selectedSpaces = selectedSpaces
            floor.status =this.selectedStatus(floor.totalSpaces, floor.selectedSpaces)
            selectedSpaceCount += floor.selectedSpaces
          })
          building.selectedSpaces = selectedSpaceCount
          building.status = this.selectedStatus(building.totalSpaces, building.selectedSpaces)
          selectedCount += building.selectedSpaces
        })
      } else {
        // building, floor or space switch clicked
        for (let building of tempItem.buildings) {
          // calculate selected spaces only for the building that has changed
          if(building.code === buildingCode) {
            let selectedSpaceCount = 0
            for (let floor of building.floors) {
              if(!floorItem || floor.id === floorId) {
                let selectedSpaces = 0  
                for (let space of floor.spaces) {
                  if (!spaceItem) {
                    if(!space.reserved) {
                      space.status = status
                      spaceIds.push(space.id)
                      selectedSpaces += space.status === 'Full' ? 1 : 0
                    }
                  } else if (space.id === spaceItem.id) {
                      space.status = status
                      spaceIds.push(space.id)
                      selectedSpaces = floor.selectedSpaces + (space.status === 'Full' ? 1 : -1)
                  }
                }
                floor.selectedSpaces = selectedSpaces
                floor.status = this.selectedStatus(floor.totalSpaces, floor.selectedSpaces)
              }
              selectedSpaceCount += floor.selectedSpaces
            }
            building.selectedSpaces = selectedSpaceCount
            building.status = this.selectedStatus(building.totalSpaces, building.selectedSpaces)
          }
          selectedCount += building.selectedSpaces
        }
      }
      tempItem.selectedSpaces = selectedCount
      tempItem.status = this.selectedStatus(tempItem.totalSpaces, tempItem.selectedSpaces)
      
      this.modifySpaceSelection(spaceIds, originalStatus)
      this.tableItems = this.sortSites([...this.tableItems.filter(site => site.idSite !== item.idSite), tempItem])
    },
    modifySpaceSelection (spaceIds, currentSelection) {
      if (currentSelection === 'None') {
        this.portfolioSpaces.push(...spaceIds)
      } else {
        this.portfolioSpaces = this.portfolioSpaces.filter(x => !spaceIds.includes(x))
      }
    },
    async initSites () {
      this.setReservedSpaces()
      const buildings = []
      const floors = []
      const sites = []
      // build table content from bottom up for better performance
      this.spaces.forEach(space => {
        if (space.building_code && space.id_floor) {
          const newSpace = {
            type: 'space',
            id: space.id,
            name: space.name,
            status: this.portfolioSpaces.includes(space.id) ? 'Full' : 'None',
            reserved: this.reservedSpaces.includes(space.id)
          }
          const index = floors.findIndex(floor => floor.id === space.id_floor)
          if (index < 0) {
            const found = this.floors.find(floor => floor.id === space.id_floor)
            if (found) {
              const newFloor = {
                type: 'floor',
                id: space.id_floor,
                name: found.floor_name,
                spaces: [newSpace],
                totalSpaces: 1,
                selectedSpaces: newSpace.status === 'Full' ? 1 : 0,
                code: space.building_code,
                reserved: this.reservedFloors.includes(space.id_floor)
              }
              floors.push(newFloor)
            }
          } else {
            floors[index].spaces.push(newSpace)
            floors[index].totalSpaces += 1
            floors[index].selectedSpaces += newSpace.status === 'Full' ? 1 : 0 
          }
        }
      })

      floors.forEach(floor => {
        floor.status = this.selectedStatus(floor.totalSpaces, floor.selectedSpaces)
        let disabled = true
        for (const space of floor.spaces) {
          if (!space.reserved) {
            disabled = false
            break
          }
        }
        floor.disabled = disabled
        const index = buildings.findIndex(building => building.code === floor.code)
        if (index < 0) {
            const found = this.buildings.find(building => building.building_code === floor.code)
            if (found) {
              const newBuilding = {
                type: 'building',
                id: found.id_building,
                idSite: found.id_site,
                name: found.building_name,
                code: floor.code,
                floors: [floor],
                totalSpaces: floor.totalSpaces,
                selectedSpaces: floor.selectedSpaces,
                reserved: this.reservedBuildings.includes(floor.code)
              }
              buildings.push(newBuilding)
            }
        } else {
            buildings[index].floors.push(floor)
            buildings[index].totalSpaces += floor.totalSpaces
            buildings[index].selectedSpaces += floor.selectedSpaces 
        }
      })

      buildings.forEach(building => {
        building.status = this.selectedStatus(building.totalSpaces, building.selectedSpaces)
        let disabled = true
        for (const floor of building.floors) {
          if (!floor.disabled) {
            disabled = false
            break
          }
        }
        building.disabled = disabled
        const index = sites.findIndex(site => site.idSite === building.idSite)
        if (index < 0) {
            const found = this.sites.find(site => site.id_site === building.idSite)
            if (found) {
              const newSite = {
                type: 'site',
                name: found.name,
                status: undefined,
                postOffice: found.post_office ? found.post_office : '',
                portfolio: this.definitionLabelById(found.portfolio),
                idSite: building.idSite,
                buildings: [building],
                totalSpaces: building.totalSpaces,
                selectedSpaces: building.selectedSpaces,
                reserved: this.reservedSites.includes(building.idSite)
              }
              sites.push(newSite)
            }
        } else {
            sites[index].buildings.push(building)
            sites[index].totalSpaces += building.totalSpaces
            sites[index].selectedSpaces += building.selectedSpaces
        }
      })
      sites.forEach(site => {
        site.status = this.selectedStatus(site.totalSpaces, site.selectedSpaces)
        let disabled = true
        for (const building of site.buildings) {
          if (!building.disabled) {
            disabled = false
            break
          }
        }
        site.disabled = disabled
      })
      this.tableItems = this.sortSites(sites)
    },
    sortSites (sites) {
      return sites.sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
    }
  }
}
</script>
<style scoped>
.reserved {
  color: lightgray;
}
</style>
