import RambollFMAPI from '../api/rambollfm.js'

const levels = {
  1: { value: "organizationCustomers" },
  2: { value: "organizations" },
  3: { value: "businessUnits" },
  4: { value: "domains" },
  5: { value: "costCenters" },
}

export default {
  namespaced: true,
  state: {
    organizationCustomers: [],
    organizations: [],
    businessUnits: [],
    domains: [],
    costCenters: [],

    loading: {
      organizationCustomers: false,
      organizations: false,
      businessUnits: false,
      domains: false,
      costCenters: false,
    },
  },
  getters: {
    anyLevelLoading (state) {
      return Object.values(state.loading).reduce((acc, cur) => acc || cur)
    },
    getCostCentersByOrganizationCustomer: (state) => (id) => {
      const organizationIds = state.organizations
        .filter((org) => org.id_parent === id)
        .map((org) => org.id)

      const businessUnitIds = state.businessUnits
        .filter((unit) => organizationIds.includes(unit.id_parent))
        .map((unit) => unit.id)

      const domainIds = state.domains
        .filter((domain) => businessUnitIds.includes(domain.id_parent))
        .map((domain) => domain.id)

      const costCenters = state.costCenters.filter((costCenter) =>
        domainIds.includes(costCenter.id_parent)
      )

      return costCenters
    },
  },
  mutations: {
    setLevelData (state, data) {
      const { level, items } = data
      state[level] = items
    },
    setLevelLoading (state, data) {
      const { level, loading } = data
      state.loading = {
        ...state.loading,
        [level]: loading,
      }
    },
  },
  actions: {
    async fetchLevel ({ commit, rootState }, level) {
      commit("setLevelLoading", { level, loading: true })
      try {
        const {
          app: { apiEndpoint, apiToken },
        } = rootState

        const api = new RambollFMAPI(apiEndpoint, apiToken)
        const items = await api.organizations[level]().list()

        commit("setLevelData", { level, items })
      } catch (err) {
        throw Error(err)
      } finally {
        commit("setLevelLoading", { level, loading: false })
      }
    },
    async fetchAllLevels ({ dispatch }) {
      Object.values(levels).forEach((level) =>
        dispatch("fetchLevel", level.value)
      )
    },
  },
}
