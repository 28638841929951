<template>
  <BaseView>
    <template #selections>
      <h1 class="text-h4">
        {{ $t('Cost types') }}
      </h1>
    </template>
    <template #buttons>
      <v-tooltip left>
        <template #activator="{ on }">
          <div v-on="on">
            <CdsButton
              icon-left="edit"
              :type="edit ? 'primary' : 'tertiary'"
              hide-text
              @click="onClickEdit"
            >
              {{ $t('Modify') }}
            </CdsButton>
          </div>
        </template>
        <span>{{ $t('Modify') }}</span>
      </v-tooltip>
    </template>
    <template #main>
      <v-row>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <CostTypeTable
            :data="maintenanceCharges"
            :loading="loading"
            :hide-edit="true"
            :edit="edit"
            @updateItems="data => maintenanceCharges = data"
            @savedUpdate="updateData"
            @pushId="pushId"
          />
        </v-col>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <CostTypeTable
            :data="tripleNetRent"
            :loading="loading"
            :hide-edit="true"
            :edit="edit"
            @updateItems="data => tripleNetRent = data"
            @savedUpdate="updateData"
            @pushId="pushId"
          />
        </v-col>
      </v-row>
    </template>
  </BaseView>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import BaseView from '../components/general/BaseView.vue'
import CdsButton from '../components/CircleDesignSystem/CdsButton.vue'
import CostTypeTable from '../components/InternalRent/CostTypeTable.vue'

export default {
  name: 'StatusPortfolios',
  components: { CostTypeTable, BaseView, CdsButton },
  metaInfo () {
    return {
      title: `${this.$t('Cost types')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      tripleNetRent: {
        allDataIds: [],
        title: this.$t('internalrent.triple_net_rent'),
        headers: [
          {
            text: this.$t('internalrent.cost_variety'),
            value: 'name',
            editable: true
          },
          {
            text: this.$t('In use'),
            value: 'systemDefault',
            editable: false,
            switch: true
          }
        ],
        footers: [],
        items: [],
        sortBy: [],
        sortDesc: [true],
        itemsPerPage: 17
      },
      maintenanceCharges: {
        allDataIds: [],
        title: this.$t('internalrent.maintenance_charges'),
        headers: [
          {
            text: this.$t('internalrent.cost_variety'),
            value: 'name',
            editable: true
          },
          {
            text: this.$t('In use'),
            value: 'systemDefault',
            editable: false,
            switch: true
          }
        ],
        footers: [],
        items: [],
        sortBy: [],
        sortDesc: [true],
        itemsPerPage: 17
      },
      edit: false,
      loading: true
    }
  },
  computed: {
    ...mapState('internalRent', ['expenses'])
  },
  mounted: async function () {
    if (!this.expenses.length) {
      await this.getExpenses()
    }
    this.setExpenseData()
    this.loading = false
  },
  methods: {
    ...mapActions('internalRent', ['getExpenses']),
    async setExpenseData () {
      this.tripleNetRent.items = this.expenses.filter(
        expense => expense.tripleNet
      )
      this.maintenanceCharges.items = this.expenses.filter(
        expense => !expense.tripleNet
      )
      this.maintenanceCharges.allDataIds = []
      this.tripleNetRent.allDataIds = []
      this.expenses.forEach(expense => {
        this.maintenanceCharges.allDataIds.push(expense.id)
        this.tripleNetRent.allDataIds.push(expense.id)
      })
    },
    async updateData () {
      this.edit = false
      this.loading = true
      await this.getExpenses()
      await this.setExpenseData()
      this.loading = false
    },
    onClickEdit () {
      this.edit ? this.updateData() : this.edit = true
    },
    pushId (id) {
      this.maintenanceCharges.allDataIds.push(id)
      this.tripleNetRent.allDataIds.push(id)
    },
  }
}
</script>
