<template>
  <v-row
    id="rambollviewer"
    :class="{ fullscreen: isFullscreen }"
    no-gutters
  >
    <v-dialog
      v-model="tenantModifyDialog"
      max-width="1100"
      persistent
    >
      <v-card>
        <TenantModifyForm
          v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN')"
          :unit="selectedUnitsFromViewer[0]"
          :parties="parties"
          :disabled="readonly"
          :building-code="buildingData.building_code"
          @update="updateCurrent()"
          @close="tenantModifyDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="carparkTenantDialog"
      persistent
      max-width="1000"
    >
      <v-card>
        <CarparkTenantForm
          v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN') && carparkTenantDialog"
          :carparks="selectedCarSpacesFromViewer"
          :tenants="carparkTenants"
          :parties="parties"
          @close="carparkTenantDialog = false"
          @change="refreshCarSpaces()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="carParkModifyDialog"
      persistent
      max-width="1000"
    >
      <v-card>
        <CarParkModifyForm
          :car-parks="selectedCarSpacesFromViewer"
          @update="updateFloorData"
          @close="carParkModifyDialog = false"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="zoomLevelSelectionDialogVisible"
      :width="printDocumentType === 'unit-brochure' ? '190mm' : '174mm'"
      persistent
    >
      <ZoomLevelSelection
        v-if="zoomLevelSelectionDialogVisible"
        :session-token="sessionToken"
        :models="models"
        :floor-id="floorId"
        :selected-units="selectedElementLayer === '1' ? selectedUnitForPrintingSpaces : selectedUnitsFromViewer"
        :selected-spaces="selectedSpacesForPrinting"
        :hidden-layers="hiddenLayers"
        :zoom-selection-active="zoomLevelSelectionDialogVisible"
        :document-type="printDocumentType"
        @close="closeZoomLevelSettingDialog"
        @openPrinting="openPrinting"
      />
    </v-dialog>
    <!-- main view -->
    <!-- end of dialogs -->
    <div
      class="col-xs-12"
    >
      <aside>
        <div
          class="info shrink"
        >
          <v-row
            style="background-color: white"
            class="navigation flex-nowrap"
            no-gutters
          >
            <v-col>
              <v-btn
                :class="{ 'v-btn--active': tab === 'siteInformation' }"
                text
                @click="selectTab('siteInformation')"
              >
            &nbsp;{{ $t('Site information') }}&nbsp;
              </v-btn>
              <v-btn
                v-if="hasApplicationPermissionByName('ULKOINENVUOKRAUS')"
                :class="{ 'v-btn--active': tab === 'renting' }"
                text
                @click="selectTab('renting')"
              >
                &nbsp;{{ $t('Renting') }}&nbsp;
              </v-btn>
              <v-btn
                v-if="hasApplicationPermissionByName('MARKKINOINTI')"
                :class="{ 'v-btn--active': tab === 'marketing' }"
                text
                @click="selectTab('marketing')"
              >
                &nbsp;{{ $t('Marketing') }}&nbsp;
              </v-btn>
              <v-btn
                v-if="hasApplicationPermissionByName('AUTOPAIKKA')"
                :class="{ 'v-btn--active': tab === 'carparks' }"
                text
                @click="selectTab('carparks')"
              >
                &nbsp;{{ $t('Car parks') }}&nbsp;
              </v-btn>
            </v-col>
          </v-row>
          <div
            class="info-window"
          >
            <!-- SITE BOX AS EXPAND -->
            <div
              v-if="tab === 'siteInformation' || tab === 'carparks'"
              class="info-panel"
            >
              <v-expansion-panels>
                <v-expansion-panel v-if="buildingData">
                  <v-expansion-panel-header>
                    <v-row
                      align="center"
                      justify="start"
                      no-gutters
                    >
                      <v-icon>location_on</v-icon>
                      {{ $t('Site') + ':' + ' ' + currentSite.name }}
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <MetadataForm
                      :metadata="siteMetadata"
                      v-model="currentSite"
                      :disabled="!hasWritePermission"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- BUILDING BOX AS EXPAND -->
            <div
              v-if="tab === 'siteInformation' || tab === 'carparks'"
              class="info-panel"
            >
              <v-expansion-panels v-if="buildingData">
                <v-expansion-panel v-if="buildingData">
                  <v-expansion-panel-header>
                    <v-row
                      align="center"
                      justify="start"
                      no-gutters
                    >
                      <v-icon>home</v-icon>
                      {{ $t('Building') + ':' + ' ' + buildingData.building_name }}
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <MetadataForm
                      :metadata="metadata.building"
                      v-model="buildingData"
                      :disabled="!hasWritePermission"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- FLOOR BOX AS EXPAND -->
            <div
              v-if="tab === 'siteInformation' || tab === 'carparks'"
              class="info-panel"
              style="margin-top: 0.5em"
            >
              <v-expansion-panels v-if="buildingData && selectedFloor && estateDwgActive === false">
                <v-expansion-panel
                  v-if="buildingData && selectedFloor && estateDwgActive === false"
                >
                  <v-expansion-panel-header>
                    <v-row
                      align="center"
                      justify="start"
                      no-gutters
                    >
                      <v-icon>height</v-icon>
                      {{ $t('Floor') + ':' + ' ' + selectedFloor.floor_name }}
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <MetadataForm
                      :metadata="metadata.floor"
                      v-model="selectedFloor"
                      :disabled="!hasWritePermission"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- CARPARK BOX -->
            <CarParkBox
              v-if="tab === 'carparks' && carSpaces.length > 0"
              :selected-car-spaces-from-viewer="selectedCarSpacesFromViewer"
              :carpark-contracts="carparkContracts"
              :tenant-info="carparkTenants"
              :building-code="buildingCode"
              :building-carparks-summary="buildingCarparksSummary"
              @modify-tenants="carparkTenantDialog = true"
              @modify-carparks="carParkModifyDialog = true"
              @update="updateFloorData(); updateEstateData()"
            />

            <!-- CARPARK BOX EMPTY -->
            <div
              v-else-if="tab === 'carparks'"
              class="info-panel"
            >
              <v-expansion-panels
                :value="[]"
                multiple
                disabled
                style="margin-top: 0.5em"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row
                      align="center"
                      justify="start"
                      no-gutters
                    >
                      <v-icon>directions_car</v-icon>
                      {{ $t('Floor does not have carparks') }}
                    </v-row>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <!-- OUTDOOR BOX -->
            <OutDoorBox
              v-if="tab === 'siteInformation'"
              :selected-outdoors-from-viewer="selectedOutdoorsFromViewer"
              :outdoors-visibility-rights="outdoorsVisibilityRights"
              :readonly="readonly"
              :show="show"
              @update="refreshOutdoors"
            />
            <VegetationBox
              v-if="tab === 'siteInformation'"
              :metadata="metadata"
              :selected-vegetation-from-viewer="selectedVegetationFromViewer"
              :show="show"
            />
            <!-- UNIT BOX -->
            <UnitBox
              v-if="tab !== 'carparks'"
              :selected-tab="tab"
              :parties="parties"
              :readonly="readonly || !hasApplicationPermissionByName('HUONEISTO_MUOKKAUS')"
              :show="show"
              :selected-spaces-from-viewer="selectedSpacesFromViewer"
              :selected-unit-for-printing-spaces="selectedUnitForPrintingSpaces"
              :selected-units-from-viewer="selectedUnitsFromViewer"
              :unit-party-links="unitPartyLinks"
              :unit-visibility-rights="unitVisibilityRights"
              :contract-visibility-rights="contractVisibilityRights"
              :renting="tab === 'renting'"
              :layer="selectedElementLayer"
              :building-data="buildingData"
              :metadata="metadata"
              :selected-floor="selectedFloor"
              :current-site="currentSite"
              :selected-prospect="selectedProspect"
              :selected-process="selectedProcess"
              :units="units"
              :contracts="contracts"
              :contract-links="contractLinks"
              :spaces="spaces"
              @tenantModify="tenantModifyDialog = true"
              @update="updateFloorData"
              @print="openZoomLevelSettingDialog"
              @setProspect="setProspect"
              @setProcess="setProcess"
              @loadParties="loadParties"
            />

            <!-- SPACE BOX -->
            <SpaceBox
              v-if="tab === 'siteInformation'"
              :app-rights="appRights"
              :customizable-fields="customizableFields"
              :customized-values="customizedValues"
              :costcenters="costcenters"
              :parties="parties"
              :party-in-some-link="partyInSomeLink"
              :selected-spaces-from-viewer="selectedSpacesFromViewer"
              :selected-units-from-viewer="selectedUnitsFromViewer"
              :spaces="spaces"
              :readonly="readonly"
              :selected-floor="selectedFloor"
              :show="show"
              :space-party-links="spacePartyLinks"
              :space-visibility-rights="spaceVisibilityRights"
              :unit-party-links="unitPartyLinks"
              :units="units"
              :space-shares="spaceShares"
              @update="updateFloorData"
            />

            <!-- CATEGORIES -->
            <template v-if="selectedCategory !== null">
              <h2 style="margin-left: 0.5em; margin-top: 1em">
                <v-icon
                  v-if="selectedCategoryOptions.length > 0"
                  @click="toggleCategoryOptions()"
                >
                  check_box
                </v-icon>
                <v-icon
                  v-else
                  @click="toggleCategoryOptions()"
                >
                  check_box_outline_blank
                </v-icon>
                <v-icon>format_paint</v-icon>
                {{ $t(getCategoryShortName(selectedCategory.name)) }}
              </h2>
              <v-expansion-panels
                v-for="option in sortedCategoryOptions"
                :key="option.key"
                style="margin-top: 0.5em"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :expand-icon="getCategoryExtraData(option).data.length > 0 ? undefined: ''"
                    :disabled="!getCategoryExtraData(option).data.length > 0"
                  >
                    <v-row
                      align="center"
                      justify="start"
                      no-gutters
                    >
                      <v-icon
                        v-if="isCategoryOptionEnabled(option)"
                        :color="option.rgba"
                        @click="toggleCategoryOption(option)"
                        @click.stop
                      >
                        check_box
                      </v-icon>
                      <v-icon
                        v-else
                        :color="option.rgba"
                        @click="toggleCategoryOption(option)"
                        @click.stop
                      >
                        check_box_outline_blank
                      </v-icon>
                      <div>
                        <div
                          v-for="name in getCategoryOptionName(option, selectedCategory)"
                          :key="name"
                        >
                          {{ name }}
                        </div>
                      </div>
                    </v-row>
                    <v-row
                      justify="end"
                      style="margin-right: 0.5em"
                      no-gutters
                    >
                      <span
                        v-if=" selectedCategory.unit === 'm2' "
                        style="margin-left: 0.5em; text-transform: lowercase"
                      >{{ getValueWithUnit(option.value, 'Area') }}</span>
                      <span
                        v-else-if=" selectedCategory.unit.includes('m2') "
                        style="margin-left: 0.5em; text-transform: lowercase"
                      >{{ getValueWithSpecialAreaUnit(option.value, selectedCategory.unit) }}</span>
                      <span
                        v-else
                        style="margin-left: 0.5em; text-transform: lowercase"
                      >{{ option.value + ' ' + selectedCategory.unit }}</span>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    :hide-actions="!(getCategoryExtraData(option).data.length > 0)"
                  >
                    <MetadataForm
                      v-if="getCategoryExtraData(option).data.length > 0"
                      :metadata="MetadataForCategoryOption(option)"
                      :data="getDataForCategoryOption(option)"
                      :disabled="!hasWritePermission"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row
                justify="end"
                align="end"
              >
                <v-btn
                  :disabled="!selectedCategoryOptions.length"
                  text
                  outlined
                  align-end
                  rounded
                  @click="selectFromFloorPlan()"
                >
                  {{ $t('Select from floor plan') + ' (' + selectedCategoryOptions.length + ')' }}
                </v-btn>
              </v-row>
            </template>
          </div>
        </div>
      </aside>
      <!-- VIEWER -->
      <v-col
        v-if="noFloors"
        style="text-align: center; padding: 100px"
      >
        <div
          class="text-h5"
          style="margin-bottom: 2em"
        >
          {{ $t('No dwg available for the current date') }}
        </div>
      </v-col>
      <div v-else-if="!printOpen && !printOfferOpen">
        <v-row
          v-show="buildingData"
          no-gutters
        >
          <v-col class="view">
            <V3Viewer
              v-if="!buildingIfcActive"
              ref="v3viewer"
              :session-token="sessionToken"
              :models="models"
              :floor-id="floorId"
              :selected-spaces="selectedSpaces"
              :selected-units="selectedUnits"
              :selected-car-spaces="selectedCarSpaces"
              :selected-outdoors="selectedOutdoors"
              :selected-vegetation="selectedVegetation"
              :hidden-layers="hiddenLayers"
              :selected-category="selectedCategory"
              :add-text-perm="addTextPerm"
              :space-text-data="selectedElementLayer === '1' ? spaceTextData : []"
              :selected-layer-from-parent="selectedElementLayerFromTab"
              :click-to-zoom="zoomDirection"
              background-color="0.984,0.984,0.984,1"
              @model-loaded="modelLoaded"
              @zoom-changed="zoomDirection = 0"
              @select-elements="selectElements"
              @layer-changed="clearSelections"
              @ready="viewerReady"
              @print="printCurrentDrawing"
              @tool-activated="toolActive = true"
              @tool-deactivated="toolActive = false"
            />

            <V3IfcViewer
              v-else-if="buildingIfcActive"
              :session-token="sessionToken"
              :models="ifcModels"
              :available-models="availableIfcModels"
              :floor="selectedFloor"
              :category="selectedCategory"
              background-color="0.984,0.984,0.984,1"
              @model-loaded="modelLoaded"
              @floor-updated="isLoading = false"
            />

            <div
              v-if="selectedFloor === null"
              class="toolbar toolbar-info"
            />
            <div
              v-if="!isLoading"
              class="toolbar"
            >
              <div
                v-if="!buildingIfcActive"
                class="toolbar-top-left"
              >
                <CdsButton
                  :aria-label="$t('Increase the zoom level')"
                  type="tertiary"
                  icon-left="add"
                  :hide-text="true"
                  :with-outline="true"
                  @click="zoomMap('plus')"
                />
                <CdsButton
                  :aria-label="$t('Reduce the zoom level')"
                  type="tertiary"
                  icon-left="remove"
                  :hide-text="true"
                  :with-outline="true"
                  @click="zoomMap('minus')"
                />
              </div>
              <v-row
                v-if="!toolActive"
                align="center"
                justify="center"
                style="padding-right: 75px"
                class="mx-4"
                no-gutters
              >
                <v-col
                  v-if="isFloorUpdating"
                >
                  <v-progress-linear :indeterminate="true" />
                </v-col>
                <v-col
                  v-for="category in filteredCategories"
                  :key="category.name"
                >
                  <v-btn
                    :id="category.criteria_table + ': ' + category.name"
                    :color="selectedCategory === category ? 'primary' : ''"
                    block
                    text
                    rounded
                    outlined
                    @click="changeCategory(category)"
                  >
                    <span style="overflow: hidden; text-overflow: ellipsis">
                      {{ $t(getCategoryShortName(category.name)) }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col
                  v-for="layer in (selectedElementLayer === '1' ? toggleableLayers : toggleableLayers.filter(layer => !layer.istextlayer))"
                  :key="layer.name"
                >
                  <v-btn
                    :color="isVisibleLayer(layer) ? 'primary' : ''"
                    block
                    text
                    rounded
                    outlined
                    @click="toggleLayer(layer)"
                  >
                    <v-icon>layers</v-icon>
                    <span style="overflow: hidden; text-overflow: ellipsis">{{
                      translateToggleLayer(layer.name)
                    }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row
              v-if="!toolActive"
              class="toolbar-top-right mt-2 flex-column"
              align="center"
            >
              <v-btn
                large
                outlined
                icon
                @click="isFullscreen = !isFullscreen"
              >
                <span v-if="!isFullscreen">
                  <v-icon>
                    fullscreen
                  </v-icon>
                  <span class="d-sr-only">{{ $t('FullscreenMode') }}</span>
                </span>
                <span v-else>
                  <v-icon>
                    fullscreen_exit
                  </v-icon>
                  <span class="d-sr-only">{{ $t('ExitFullscreenMode') }}</span>
                </span>
              </v-btn>
              <v-btn
                v-if="hasApplicationPermissionByName('POHJAKUVA_LATAUS')"
                :disabled="!selectedFloor && !selectedEstate"
                large
                outlined
                icon
                @click="downloadCurrentDrawing"
              >
                <span v-if="!isDownloading">
                  <v-icon>
                    cloud_download
                  </v-icon>
                  <span class="d-sr-only">{{ $t('Download') }}</span>
                </span>
                <v-progress-circular
                  v-else
                  :indeterminate="downloadingProgress.retrievalProgress === 0"
                  :value="downloadingProgress.retrievalProgress"
                  small
                  color="primary"
                />
              </v-btn>
              <v-btn
                :disabled="ifcModels.length === 0"
                :input-value="buildingIfcActive"
                large
                outlined
                icon
                @click="toggleIfc()"
              >
                <v-icon>3d_rotation</v-icon>
                <span class="d-sr-only">{{ $t('3dRotation') }}</span>
              </v-btn>
            </v-row>
          </v-col>
          <v-col
            class="floorselect shrink"
          >
            <v-row
              v-if="floors.length > 0"
              class="fill-height flex-column"
              justify="center"
              no-gutters
            >
              <v-col
                v-for="floor in floors.slice().reverse()"
                :key="floor.name"
                class="shrink"
              >
                <v-btn
                  :disabled="!floor.enabled"
                  :class="{
                    'selected-floor': floor === selectedFloor && !estateDwgActive,
                  }"
                  fab
                  text
                  @click="selectFloor(floor)"
                >
                  {{ getFloorShortname(floor) }}
                </v-btn>
              </v-col>
              <v-col
                v-if="selectedEstate !== null && selectedEstate.has_dwg"
                class="shrink"
              >
                <v-btn
                  :class="{ 'selected-floor': estateDwgActive }"
                  fab
                  text
                  @click="activateEstateDwg"
                >
                  A
                </v-btn>
              </v-col>
              <v-col
                v-if="buildingIfcActive"
                class="shrink"
              >
                <v-btn
                  :class="{
                    'selected-floor': buildingIfcActive && selectedFloor === null,
                  }"
                  fab
                  text
                  @click="
                    buildingIfcActive = true
                    selectedFloor = null
                    selectedCategory = null
                    layers = []
                  "
                >
                  <v-icon>home</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-else-if="floors.length === 0"
              class="fill-height flex-column"
              justify="center"
            >
              <v-col
                v-if="selectedEstate !== null && selectedEstate.has_dwg"
                class="shrink"
              >
                <v-btn
                  :class="{ 'selected-floor': estateDwgActive }"
                  fab
                  text
                  @click="activateEstateDwg"
                >
                  A
                </v-btn>
              </v-col>
            </v-row>
            <v-progress-circular
              v-else
              style="height: 100%; width: 100%"
              indeterminate
              color="primary"
            />
          </v-col>
        </v-row>
      </div>
      <v-col
        v-else-if="printOfferOpen"
        class="grow"
      >
        <v-col class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            size="128"
            color="primary"
            class="no-print"
          />
        </v-col>
        <PrintUnitBrochure
          :session-token="sessionToken"
          :models="models"
          :floor-id="floorId"
          :selected-units="selectedUnitsFromViewer"
          :hidden-layers="hiddenLayers"
          :camera="v3camera"
          :site="currentSite"
          :building="buildingData"
          :floor="selectedFloor"
          @close="closePrint"
        />
      </v-col>
      <v-col
        v-else
        class="grow"
      >
        <v-col class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            size="128"
            color="primary"
            class="no-print"
          />
        </v-col>
        <PrintPreviewer
          :session-token="sessionToken"
          :models="models"
          :floor-id="floorId"
          :selected-units="selectedElementLayer === '1' ? selectedUnitForPrintingSpaces : selectedUnitsFromViewer"
          :selected-spaces="selectedSpacesForPrinting"
          :hidden-layers="hiddenLayers"
          :camera="v3camera"
          :site="currentSite"
          @close="closePrint"
        />
      </v-col>
      <div
        v-if="buildingData === null"
        class="loading"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
    </div>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import humanize from '../../helpers/humanize.js'
import V3Viewer from './V3Viewer.vue'
import V3IfcViewer from './V3IfcViewer.vue'
import format from '../../helpers/format.js'
import saveAs from './saveAs.js'
import moment from 'moment'
import translations from './translations.js'
import TenantModifyForm from './TenantModifyForm.vue'
import CarparkTenantForm from './CarparkTenantForm.vue'
import MetadataForm from '../../components/MetadataForm.vue'
import CarParkBox from './CarParkBox.vue'
import OutDoorBox from './OutDoorBox.vue'
import VegetationBox from './VegetationBox.vue'
import SpaceBox from './SpaceBox.vue'
import UnitBox from './UnitBox.vue'
import PrintPreviewer from './PrintPreviewer.vue'
import CarParkModifyForm from './CarParkModifyForm.vue'
import PrintUnitBrochure from './PrintUnitBrochure.vue'
import ZoomLevelSelection from './ZoomLevelSelection.vue'
import CdsButton from '../../components/CircleDesignSystem/CdsButton.vue'
// Vue.directive('on').keyCodes.control = 17
export default {
  name: 'RambollFMViewer',
  components: {
    V3Viewer: V3Viewer,
    TenantModifyForm: TenantModifyForm,
    CarparkTenantForm,
    V3IfcViewer,
    MetadataForm,
    CarParkBox,
    OutDoorBox,
    VegetationBox,
    SpaceBox,
    UnitBox,
    PrintPreviewer,
    CarParkModifyForm,
    PrintUnitBrochure,
    ZoomLevelSelection,
    CdsButton
  },
  props: {
    sessionToken: {
      default: '',
      type: String
    },
    buildingCode: {
      default: '',
      type: String
    },
    apiEndpoint: {
      default: 'https://api3.rambollfm.fi/v3.1',
      type: String
    },
    currentDate: {
      default: new Date(),
      type: Date
    },
    buildingData: {
      default: null,
      type: Object || null
    },
    readonly: {
      default: false,
      type: Boolean
    },
    floorFromParent: {
      default: null,
      type: Object
    },
    estateDwgOnly: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      floorId: 0,
      layers: [],
      floors: [],
      spaces: [],
      units: [],
      categories: [],
      layerGroups: [],
      carSpaces: [],
      outdoors: [],
      vegetation: [],
      contracts: [],
      contractLinks: [],
      carparkContracts: [],
      selectedCategory: null,
      selectedCategoryExtraData: null,
      selectedFloor: null,
      selectedEstate: null,
      selectedCategoryOptions: [],
      selectedSpaces: [],
      selectedUnits: [],
      selectedSpacesFromViewer: [],
      selectedUnitsFromViewer: [],
      selectedSpacesForPrinting: [],
      selectedUnitForPrintingSpaces: [],
      selectedCarSpaces: [],
      selectedCarSpacesFromViewer: [],
      selectedOutdoors: [],
      selectedOutdoorsFromViewer: [],
      selectedVegetation: [],
      selectedVegetationFromViewer: [],
      spaceVisibilityRights: {},
      unitVisibilityRights: {},
      outdoorsVisibilityRights: {},
      contractVisibilityRights: {},
      zoomDirection: 0,
      models: [],
      disabledLayers: [],
      visibleLayers: [],
      hiddenLayers: [],
      show: {
        spaceCommentModify: false,
        categoryOptions: {},
        layerOptions: [],
        unitInfo: 0,
        spaceInfo: 0,
        carparkInfo: 0,
        outdoorInfo: 0,
        vegetationInfo: 0,
      },
      parties: [],
      unitPartyLinks: [],
      spacePartyLinks: [],
      carparkTenants: [],
      partyInSomeLink: [],
      selectedUnit: null,
      isLoading: true,
      tenantModifyDialog: false,
      carparkModifyDialog: false,
      carparkTenantDialog: false,
      selectedSpaceForCommentModify: null,
      modifyComment: null,
      purposesOfUse: [],
      customizableFields: [],
      customizedValues: [],
      costcenters: [],
      modelLayers: null,
      isUpdating: false,
      isFloorUpdating: true,
      isEstateUpdating: true,
      isFullscreen: false,
      selectedElementQueue: [],
      selectedCategoryQueue: null,
      selectedElementLayer: '1',
      appRights: {
        addUnit: false,
        moveSpace: false,
        showDevices: false,
        editDevices: false,
      },
      estateDwgActive: false,
      isDownloading: false,
      downloadingProgress: {
        retrievalProgress: 0
      },
      viewerApi: null,
      buildingIfcActive: false,
      availableIfcModels: [],
      tab: 'siteInformation',
      addTextLayers: [
        {
          order: 1,
          name: 'Space number',
          value: 'name',
          alwaysVisible: true,
          onByDefault: true
        },
        {
          order: 2,
          name: 'Purpose of use',
          value: 'id_purpose',
          onByDefault: true
        },
        {
          order: 3,
          name: 'Clarified purpose of use',
          value: 'clarified_purpose_of_use'
        },
        {
          order: 4,
          name: 'Area',
          value: 'area'
        },
        {
          order: 5,
          name: 'Space name',
          value: 'space_name'
        },
        {
          order: 6,
          name: 'Personnel',
          value: 'attached_number_of_personnel'
        },
        {
          order: 7,
          name: 'Personnel lkm',
          value: 'number_of_personnel'
        }
      ],
      selectedAddTextLayers: [],
      spaceTextData: [],
      spaceShares: null,
      printOpen: false,
      v3camera: undefined,
      selectedElementLayerFromTab: null,
      carParkModifyDialog: false,
      printOfferOpen: false,
      zoomLevelSelectionDialogVisible: false,
      printDocumentType: null,
      noFloors: false,
      selectedProspect: null,
      selectedProcess: null,
      loadingFailed: false,
      categorySelectedSpaces: [],
      spaceUnitLinks: [],
      buildingCarparksSummary: null,
      toolActive: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'definitionById',
      'definitionLabelById',
      'definitionsByGroupLabel'
    ]),
    ...mapState('sites', ['siteMetadata', 'buildingMetadata']),
    ...mapState('app', ['purposeZones', 'userGroups', 'settings']),
    ...mapGetters('sites', ['currentSite', 'hasWritePermission']),
    ...mapGetters('app', ['hasApplicationPermissionByName','applicationPermissions']),
    ifcModels () {
      return this.availableIfcModels.map(m => {
        return {
          ...m,
          name: 'ifc_' + this.buildingData.building_code + '_' + m.discipline,
          url:
            // 'https://api3.rambollfm.fi/v3.1/buildings/' +
            // 'https://circle.rambollfm.fi/buildings/' +
            process.env.VUE_APP_RAMBOLLFM_API_ENDPOINT +
            'buildings/' +
            this.buildingCode +
            '/ifc?customer_id=' +
            this.$store.state.app.userInfo.customer_id +
            '&discipline=' +
            m.discipline +
            '&v=' +
            m.lastModified.substr(0, 16)
        }
      })
    },
    filteredCategories () {
      let elementLayerName = ''
      if (this.selectedElementLayer === '1') {
        elementLayerName = 'huone'
      } else if (this.selectedElementLayer === '2') {
        elementLayerName = 'hsto'
      } else if (this.selectedElementLayer === '101' && this.estateDwgActive) {
        elementLayerName = 'autopaikka_kii'
      } else if (this.selectedElementLayer === '101' && !this.estateDwgActive) {
        elementLayerName = 'autopaikka_ker'
      } else if (this.selectedElementLayer === '102') {
        elementLayerName = 'ulkoalue'
      } else if (this.selectedElementLayer === '106') {
        elementLayerName = 'kasvialue'
      }

      switch (this.tab) {
        case 'siteInformation': {
          const siteInformationCategories = this.categories.filter(cat => cat.tab === null && cat.criteria_table === elementLayerName)
          return siteInformationCategories
        }
        case 'renting': {
          const rentingCategories = this.categories.filter(cat => cat.tab === 'Renting' && cat.criteria_table === elementLayerName)
          return rentingCategories
        }
        case 'marketing': {
          const marketingCategories = this.categories.filter(cat => cat.tab === 'Marketing' && cat.criteria_table === elementLayerName)
          return marketingCategories
        }
        case 'carparks': {
          if(this.carSpaces.length === 0) {
            return
          }
          const carparkCategories = this.categories.filter(cat => cat.tab === 'CarParks' && cat.criteria_table === elementLayerName)
          return carparkCategories
        }
        default:
          return this.categories.filter(
          cat => cat.criteria_table === elementLayerName
        )
      }
      /* SOLVE THIS */
      // let result = this.categories.filter(
      //   cat =>
      //     cat.criteria_table === elementLayerName &&
      //     !tabCategories.includes(cat.name)
      // )
      // return result
    },
    toggleableLayers () {
      var layers = this.layers.filter(
        l => l.isonofflayer && this.disabledLayers.indexOf(l) < 0
      )
      if (this.addTextPerm) {
        this.addTextLayers.forEach(l => {
          const right = this.spaceVisibilityRights[l.value]
          if ((right && right.isShown) || l.alwaysVisible) {
            const addTextLayer = {
              isonofflayer: true,
              name: l.value,
              show_by_default: l.onByDefault ? l.onByDefault : false,
              istextlayer: true,
              order: l.order
            }
            layers.push(addTextLayer)
          }
        })
      }
      return layers
    },
    sortedCategoryOptions () {
      return this.selectedCategory.options.slice(0).sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    metadata () {
      const metadata = {
        building: {},
        floor: {},
        vegetation: {},
        carpark: {},
        unit: {},
        space: {}
      }
      const metadataLabels = {
        building: [],
        floor: [],
        carpark: ['type', 'heating', 'tenant'],
        vegetation: ['code', 'count', 'scientific_name', 'name']
      }

      this.setBuildingAndFloorMetaLabels(metadataLabels)

      Object.keys(metadataLabels).map(array => {
        metadataLabels[array].map(label => {
          metadata[array][label] = {
            isShown: true,
            category: 'None',
            format: this.getMetaFormat(label)
          }
        })
      })
      return metadata
    },
    addTextPerm () {
      return this.hasApplicationPermissionByName('KERROS_ADDTEXT')
    },
    isAdmin () {
      const userGroup = this.userGroups[0]
      if (userGroup != null) {
        return userGroup.group.isAdmin && userGroup.group.groupId === 1
      }
      return false
    },
    excludedSpaces () {
      return this.isAdmin ? [] : this.spaces.filter(s => s.is_shared_space)
    }
  },
  watch: {
    buildingCode: function () {
      if (this.buildingCode) {
        this.$rambollfmapi.buildings.models(this.buildingCode).then(res => {
          this.availableIfcModels = res
        })
        this.isLoading = true
        this.floorId = 0

        this.loadFloors(this.buildingCode)
        this.loadEstate(this.buildingData.id_estate)
        this.loadCarparkSummary(this.buildingCode)

        if (this.estateDwgActive === true) {
           this.viewEstatePlan()
        }
      }
    },
    isFloorUpdating: function () {
      // Makes sure that the element data gets loaded since spaces and units are empty at the start of page load.
      if (
        this.isFloorUpdating === false &&
        this.selectedElementQueue &&
        this.selectedElementQueue.length > 0
      ) {
        this.selectElements(
          this.selectedElementQueue[0].layer,
          this.selectedElementQueue
        )
        this.selectedElementQueue = []
      }
    },
    isEstateUpdating: function () {
      if (
        this.isEstateUpdating === false &&
        this.selectedElementQueue &&
        this.selectedElementQueue.length > 0
      ) {
        this.selectElements(
          this.selectedElementQueue[0].layer,
          this.selectedElementQueue
        )
        this.selectedElementQueue = []
      }
    },
    currentDate: async function () {
      this.noFloors = false
      await this.loadFloors(this.buildingCode)
      await this.loadEstate(this.buildingData.id_estate)
      this.loadCarparkSummary(this.buildingCode)

      if (this.estateDwgActive !== true) {
        this.updateFloorData()
      } else {
        this.viewEstatePlan()
        this.updateEstateData()
      }
    },
    floorId: {
      handler: function (val) {
        if (val > 0) {
          this.updateFloorData()
        }
      }
    },
    floorFromParent: {
      handler: function () {
        this.selectedFloor = this.floorFromParent
        this.updateFloorData()
        }
    },
    selectedFloor: {
      handler: function (floor) {
        if (floor !== null) {
          this.models = [] // clear javascript array
          if (floor === null) {
            this.categories = []
            this.floorId = 0
            return
          }

          this.$store.commit('app/setRequestItems', {
            level: 'floors',
            items: [floor.id]
          })

          const updated = moment(floor.dwg_updated).format('YYYYMMDDHHmmss')
          const startDateFormatted = moment(floor.start_date).add(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss')

          // Hardcoded, because GET /floors/:id/dwg can only be used with API endpoints
          // that are publicly visible to Vector.io model processing servers
          // So far, there are two cases when dwg url should be reprocessed:
          // 1) When there are changes in dwg file (timestamp of last change shows in floor.dwg_updated)
          // 2) When reprocessing is triggered manually because of processing problems (floor.v3dwgversion changes)
          var dwgurl =
            'https://api3.rambollfm.fi/v3.1/floors/' +
            Number(floor.id) +
            '/dwg?customer_id=' +
            this.$store.state.app.userInfo.customer_id +
            '&v=' +
            Number(floor.v3dwgversion) +
            '&lastUpdate=' +
            updated +
            '&time=' +
            startDateFormatted
          if (floor.has_dwg) {
            this.models.push({
              name: 'floor_' + floor.id.toString(),
              url: dwgurl,
              backendModelId: floor.model_id
            })
          }

          this.floorId = floor.id
        }
      }
    },
    selectedCategoryOptions: {
      handler: function (options) {
        if (this.selectedCategory && options !== null) {
          const critTable = this.selectedCategory.criteria_table
          const optionsWithColor = []
          options.forEach(category => {
            category.elements.forEach(element => {
              const item = {
                identifier: element,
                color: category.color,
                criteriaTable: critTable
              }
              optionsWithColor.push(item)
            })
          })

          if (critTable === 'huone') {
            this.selectedSpaces = optionsWithColor
          } else if (critTable === 'hsto') {
            this.selectedUnits = optionsWithColor
          } else if (
            critTable === 'autopaikka_ker' ||
            critTable === 'autopaikka_kii'
          ) {
            this.selectedCarSpaces = optionsWithColor
          } else if (critTable === 'ulkoalue') {
            this.selectedOutdoors = optionsWithColor
          } else if (critTable === 'kasvialue') {
            this.selectedVegetation = optionsWithColor
          }
        }
      },
      deep: true
    },
    estateDwgActive: {
      handler: function (boolean) {
        if (boolean) {
          this.viewEstatePlan()
          this.updateEstateData()
        } else {
          this.updateFloorData()
        }
      },
      deep: true
    },
    selectedAddTextLayers: function () {
      this.refreshSpaceTexts()
    }
  },
  async mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions('app', ['getPurposeZones']),
    ...mapActions('leasing', ['getProspects',]),
    ...mapActions('rentalProcess', ['getRentalProcess',]),
    ...mapActions('device', ['loadDevices']),
    selectFloor (floor) {
      // Prevent floor change if there is already model loading OR if we select the same floor
      if (this.isLoading || floor.id === this.floorId)
        return
      this.loadingFailed = false
      this.isLoading = true
      this.selectedFloor = floor
      this.selectedCategory = null
      this.selectedCategoryOptions = null
      this.estateDwgActive = false
    },
    toggleIfc () {
      this.buildingIfcActive = !this.buildingIfcActive
      // this.selectedFloor = null
      this.selectedCategory = null
      this.layers = []
      this.categories = []
      this.updateFloorData()
    },
    setBuildingAndFloorMetaLabels (metaLabels) {
      metaLabels.building.push('building_name2')
      if (this.buildingMetadata.building_gross_area !== undefined
          && this.buildingMetadata.building_gross_area.isShown) {
        metaLabels.building.push('building_gross_area')
        metaLabels.floor.push('gross_area')
      }
      if (this.buildingMetadata.building_net_floor_area !== undefined &&
          this.buildingMetadata.building_net_floor_area.isShown) {
        metaLabels.building.push('building_net_floor_area')
        metaLabels.floor.push('net_floor_area')
      }
      if (this.buildingMetadata.building_net_floor_area_from_space !== undefined &&
          this.buildingMetadata.building_net_floor_area_from_space.isShown) {
        metaLabels.building.push('building_net_floor_area_from_space')
        metaLabels.floor.push('net_floor_area_from_space')
      }
      if (this.buildingMetadata.building_net_room_area !== undefined &&
          this.buildingMetadata.building_net_room_area.isShown) {
        metaLabels.building.push('building_net_room_area')
        metaLabels.floor.push('net_floor_room_area')
      }
      if (this.buildingMetadata.other_floor_area !== undefined &&
          this.buildingMetadata.other_floor_area.isShown) {
        metaLabels.building.push('other_floor_area')
        metaLabels.floor.push('other_floor_area')
      }
      if (this.buildingMetadata.rentable_floor_area !== undefined &&
          this.buildingMetadata.rentable_floor_area.isShown) {
        metaLabels.building.push('rentable_floor_area')
        metaLabels.floor.push('rentable_floor_area')
      }
      if (this.buildingMetadata.rented_floor_area !== undefined
          && this.buildingMetadata.rented_floor_area.isShown) {
        metaLabels.building.push('rented_floor_area')
        metaLabels.floor.push('rented_floor_area')
      }
      if (this.buildingMetadata.free_rentable_area !== undefined &&
          this.buildingMetadata.free_rentable_area.isShown) {
        metaLabels.building.push('free_rentable_area')
        metaLabels.floor.push('free_floor_area')
      }
    },
    printCurrentDrawing () {
      // We use the implementation from the viewer component
      var text = ''
      if (this.selectedCategory !== null) {
        var title =
          this.$t('Status') + ' ' +
          moment(this.currentDate).format('DD.MM.YYYY') + '\n' +
          this.$t(this.getCategoryShortName(this.selectedCategory.name)) +
          '\n' +
          this.buildingData.building_name
        if (this.selectedFloor) {
          title += '\n(' + this.selectedFloor.floor_name + ')'
        } else if (this.selectedEstate) {
          title += '\n(' + this.$t('Estate').toUpperCase() + ')'
        }
        this.viewerApi.print({
          title: title,
          legend: this.selectedCategoryOptions
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map(c => {
              return {
                text:
                  this.$t(c.name) +
                  ' (' +
                  (this.selectedCategory.unit !== null &&
                  this.selectedElementLayer === '101'
                    ? this.getValueWithUnit(c.value, 'Amount')
                    : this.getValueWithUnit(c.value, 'Area')) +
                  ') ',
                color: {
                  r: c.color.split(',')[0] / 255,
                  g: c.color.split(',')[1] / 255,
                  b: c.color.split(',')[2] / 255
                }
              }
            })
        })
      } else if (
        this.selectedSpacesFromViewer.length > 0 &&
        this.selectedElementLayer === '1'
      ) {
        this.selectedSpacesFromViewer.map(space => {
          text += space.name + ' (' + space.area + ' m\u00B2) \n '
        }
        )
        const spaceAreaSum = this.selectedSpacesFromViewer.reduce((sum, space) => sum + space.area, 0)
        //text += ' \n ' + this.$t('Total') + '\n ' + ' (' + spaceAreaSum + ' m\u00B2)'
        text += this.$t('Total') + ' (' + spaceAreaSum + ' m\u00B2)'
        this.viewerApi.print({
          title: this.$t('Selected spaces'),
          legend: [
            {
              text: text,
              color: {
                r: 0,
                g: 0.6156,
                b: 0.8784
              }
            }
          ]
        })
      } else if (
        this.selectedUnitsFromViewer.length > 0 &&
        this.selectedElementLayer === '2'
      ) {
        this.selectedUnitsFromViewer.map(unit => {
          text += unit.unit_code + ', '
        })
        text = text.slice(0, -2)
        this.viewerApi.print({
          title: this.$t('Selected units'),
          legend: [
            {
              text: text,
              color: {
                r: 0,
                g: 0.6156,
                b: 0.8784
              }
            }
          ]
        })
      } else if (
        this.selectedCarSpacesFromViewer.length > 0 &&
        this.selectedElementLayer === '101'
      ) {
        this.selectedCarSpacesFromViewer.map(carspace => {
          text += carspace.nro + ', '
        })
        text = text.slice(0, -2)
        this.viewerApi.print({
          title: this.$t('Selected carspaces'),
          legend: [
            {
              text: text,
              color: {
                r: 0,
                g: 0.6156,
                b: 0.8784
              }
            }
          ]
        })
      } else if (
        this.selectedOutdoorsFromViewer.length > 0 &&
        this.selectedElementLayer === '102'
      ) {
        this.selectedOutdoorsFromViewer.map(outdoor => {
          text += outdoor.id_outdoor.toString() + ', '
        })
        text = text.slice(0, -2)
        this.viewerApi.print({
          title: this.$t('Selected outdoors'),
          legend: [
            {
              text: text,
              color: {
                r: 0,
                g: 0.6156,
                b: 0.8784
              }
            }
          ]
        })
      }else if (
        this.selectedVegetationFromViewer.length > 0 &&
        this.selectedElementLayer === '106'
      ) {
        this.selectedVegetationFromViewer.map(vegetation => {
          text += vegetation.id_plantarea.toString() + ', '
        })
        text = text.slice(0, -2)
        this.viewerApi.print({
          title: this.$t('Selected vegetation'),
          legend: [
            {
              text: text,
              color: {
                r: 0,
                g: 0.6156,
                b: 0.8784
              }
            }
          ]
        })
      } else {
        this.viewerApi.print({})
      }
    },
    viewerReady (viewerApi) {
      // Viewer ready, member methods available. See V3Viewer api definition
      this.viewerApi = viewerApi
    },
    downloadCurrentDrawing () {
      this.isDownloading = true
      this.downloadingProgress.retrievalProgress = 0
      const self = this
        if (this.selectedFloor) {
            this.$rambollfmapi.floors
                .dwg(this.selectedFloor.id, false, this.downloadingProgress)
                .then(res => {
                    const filename = res.headers['content-disposition'].match(
                        /filename=(.*);/
                    )[1]

                    self.isDownloading = false
                    self.downloadingProgress.retrievalProgress = 100
                    const blob = new Blob([res.data], { type: 'octet/stream' })
                    saveAs(blob, filename)
                })
                .catch(() => {
                    self.downloadingProgress.retrievalProgress = 100
                })
        } else if (this.selectedEstate) {
            this.$rambollfmapi.estates
                .dwg(this.selectedEstate.estate_id, false, this.downloadingProgress)
                .then(res => {
                    const filename = res.headers['content-disposition'].match(
                        /filename=(.*);/
                    )[1]

                    self.isDownloading = false
                    self.downloadingProgress.retrievalProgress = 100
                    const blob = new Blob([res.data], { type: 'octet/stream' })
                    saveAs(blob, filename)
                })
                .catch(() => {
                    self.downloadingProgress.retrievalProgress = 100
                })
        }
    },
    MetadataForCategoryOption (option) {
      const metadata = {}
      const headers = Object.keys(this.selectedCategoryExtraData[option.key])

      headers.forEach(header => {
        metadata[header] = {
          isShown: true,
          category: 'None'

        }
      })

      return metadata
    },
    getDataForCategoryOption (option) {
      const data = {}
      const headers = Object.keys(this.selectedCategoryExtraData[option.key])

      headers.forEach(header => {
        if (header === 'VAT-responsibility') {
          data[header] = this.getVATStatus(
            this.selectedCategoryExtraData[option.key][header]
          )
        } else if (header === 'Validity notice') {
          data[header] =
            this.selectedCategoryExtraData[option.key][header] === '1'
              ? this.$t('Considerations')
              : '-'
        } else if (
          header === 'Rented Actual area' ||
          header === 'Contract area'
        ) {
          data[header] =
            this.selectedCategoryExtraData[option.key][header] === '0'
              ? '-'
              : Number(this.selectedCategoryExtraData[option.key][header])
        } else {
          data[header] = this.selectedCategoryExtraData[option.key][header]
        }
      })

      return data
    },
    translateToggleLayer (value) {
      // Bit of an hack solution as this always returns the second translation value which atm is finnish
      if (typeof translations[value] !== 'undefined') {
        return Object.values(translations[value])[1].toString()
      } else if (this.addTextLayers.find(layer => layer.value === value)) {
        return this.$t(
          this.addTextLayers.find(layer => layer.value === value).name
        )
      }
      return value
    },
    getFloorShortname (floor) {
      if (floor.shortname !== null) {
        return floor.shortname
      }
      return floor.floor_number
    },
    getValueWithUnit (value, formatting) {
      return format.formatData(value, formatting)
    },
    getValueWithSpecialAreaUnit (value, unit) {
      if (!this.settings.useVariousAreaUnits) {
        return this.getValueWithUnit(value, 'Area')
      }
      // usage of amount_long taken from format.js, formatData, formatter === 'Area' section
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + " " + unit.slice(0, -1) + '²'
    },
    changeCategory (newCategory) {
      this.selectedCategoryOptions = []
      this.selectedCategoryExtraData = null
      if (newCategory !== this.selectedCategory) {
        // filter out empty categories
        newCategory.options = newCategory.options.filter(option => option.elements.length > 0)
        this.selectedCategory = newCategory
        const keys = newCategory.options.map(o => {
          return o.key
        })
        if (newCategory.extra_data !== null) {
          this.$rambollfmapi.floors
            .extra(
              this.floorId,
              newCategory.name,
              newCategory.extra_data,
              this.currentDate,
              keys
            )
            .then(res => {
              this.selectedCategoryExtraData = res
            })
        }
      } else {
        this.selectedCategory = null
      }
      this.toggleCategoryOptions()
    },

    isVisibleLayer (layer) {
      return this.visibleLayers.map(v => v.name).indexOf(layer.name) >= 0
    },
    isCategoryOptionEnabled (option) {
      return this.selectedCategoryOptions.indexOf(option) >= 0
    },
    getLayers (layerId) {
      if (this.modelLayers === null) {
        return []
      }
      // Layer ID can be like ABTEST* => * is wildcard
      const hasWildcard = layerId.indexOf('*') >= 0
      if (hasWildcard) {
        const regex = new RegExp(layerId.replace(/\*/, '.*'), 'gi')
        return this.modelLayers.filter(l => l.match(regex) !== null)
      } else {
        return this.modelLayers.indexOf(layerId) < 0 ? [] : [layerId]
      }
    },
    toggleLayer (layer) {
      const existingVisibleLayerIndex = this.visibleLayers
        .map(l => l.name)
        .indexOf(layer.name)
      if (existingVisibleLayerIndex >= 0) {
        this.visibleLayers.splice(existingVisibleLayerIndex, 1)
        if (layer.layer_ids) {
          this.hiddenLayers.push(
            ...layer.layer_ids.reduce((acc, cur) => {
              acc.push(...this.getLayers(cur))
              return acc
            }, [])
          )
        } else if (layer.istextlayer) {
          this.selectedAddTextLayers = this.selectedAddTextLayers.filter(
            l => l.name !== layer.name
          )
        }
      } else {
        this.visibleLayers.push(layer)
        if (layer.layer_ids) {
          const removed = this.hiddenLayers.reduce((acc, cur, idx) => {
            if (
              layer.layer_ids
                .reduce((acc, cur) => {
                  acc.push(...this.getLayers(cur))
                  return acc
                }, [])
                .indexOf(cur) >= 0
            ) {
              acc.push(idx)
            }
            return acc
          }, [])
          this.hiddenLayers = this.hiddenLayers.filter((l, idx) => {
            return removed.indexOf(idx) < 0
          })
        } else if (layer.istextlayer) {
          this.selectedAddTextLayers.push(layer)
        }
      }
      this.selectedAddTextLayers = this.selectedAddTextLayers.sort((a, b) =>
        a.order < b.order ? -1 : 1
      )
    },
    updateDisabledLayers () {
      const layerIdToLayer = this.layers.reduce((acc, cur) => {
        cur.layer_ids.forEach(layerId => {
          acc[layerId] = cur
        })
        return acc
      }, {})

      const enabledLayerIds = this.modelLayers === null ? [] : this.modelLayers
      const enabledLayers = enabledLayerIds
        .map(lid => {
          return layerIdToLayer[lid]
        })
        .filter(l => typeof l !== 'undefined')

      let disabledLayers = []
      this.layers.forEach(layer => {
        if (enabledLayers.map(l => l.name).indexOf(layer.name) < 0) {
          disabledLayers.push(layer)
        }
      })

      // remove duplicates
      disabledLayers = disabledLayers.filter((layer, pos) => {
        return disabledLayers.map(l => l.name).indexOf(layer.name) === pos
      })
      this.disabledLayers = disabledLayers
    },
    selectElements (layer, elements, drawingType = "dwg") {
      if (this.isFloorUpdating) {
        this.selectedElementQueue = elements
        return
      }
      if (typeof elements === 'undefined') {
        if (!this.printOpen && !this.printOfferOpen && !this.zoomLevelSelectionDialogVisible) {
          this.selectedSpacesFromViewer = []
          this.selectedUnitsFromViewer = []
          this.selectedCarSpacesFromViewer = []
          this.selectedOutdoorsFromViewer = []
          this.selectedVegetationFromViewer = []
        }
        return
      }
      // We need to create list of selected sections (spaces and units). Filter out duplicates
      let selectedSectionIds = null
      selectedSectionIds = elements
          .map(e => e.element_id)
          .filter((id, pos, list) => list.indexOf(id) === pos)

      // Next provide list of selected spaces
      let selectedSpaces = []
      let selectedUnits = []
      let selectedCarSpaces = []
      let selectedOutdoors = []
      let selectedVegetation = []

      if (layer === '1') {
        selectedSpaces = selectedSectionIds
            .map(id => this.spaces.find(s => s.name === id))
            .filter(s => s !== undefined)
            .sort((a, b) => a.name - b.name)
      } else if (layer === '2') {
        selectedUnits = selectedSectionIds
          .map(id => this.units.find(s => s.unit_code === id))
          .filter(s => s !== undefined)
          .sort((a, b) => a.id - b.id)
      } else if (layer === '101') {
        selectedCarSpaces = selectedSectionIds
          .map(id => this.carSpaces.find(c => c.id_carspace.toString() === id))
          .filter(c => c !== undefined)
          .sort((a, b) => a.nro - b.nro)
      } else if (layer === '102') {
        selectedOutdoors = selectedSectionIds
          .map(id => this.outdoors.find(o => o.id_outdoor.toString() === id))
          .filter(o => o !== undefined)
          .sort((a, b) => a.name - b.name)
      } else if (layer === '106') {
        selectedVegetation = selectedSectionIds
          .map(id => this.vegetation.find(o => o.id_plantarea.toString() === id))
          .filter(o => o !== undefined)
          .sort((a, b) => a.name - b.name)
      }

      this.selectedSpacesFromViewer = selectedSpaces
      this.selectedCarSpacesFromViewer = selectedCarSpaces
      this.selectedOutdoorsFromViewer = selectedOutdoors
      this.selectedVegetationFromViewer = selectedVegetation

      // Find distinct units that belong to selected space and select them
      const unitIds = [
        ...new Set(
          selectedSpaces.map(space => {
            return space.id_unit
          })
        )
      ]

      unitIds.forEach(unitId => {
        const foundUnit = this.units.find(unit => {
          return unit.id === unitId
        })
        if (
          foundUnit !== undefined &&
          selectedUnits.find(unit => unit === foundUnit) === undefined
        ) {
          selectedUnits.push(foundUnit)
        }
      })
      this.selectedUnitsFromViewer = selectedUnits

      if (this.selectedElementLayer === '1' && this.selectedUnitsFromViewer) {
        this.selectedUnitForPrintingSpaces = []
        const foundUnit = this.selectedUnitsFromViewer.find(unit => unit.id === this.selectedSpacesFromViewer[0].id_unit)
        if (foundUnit) {
          this.selectedUnitForPrintingSpaces.push(foundUnit)
        }
      }

      this.$store.commit('app/setRequestItems', {
        level: 'units',
        items: selectedUnits.map(unit => unit.id)
      })
      this.$store.commit('app/setRequestItems', {
        level: 'spaces',
        items: selectedSpaces.map(space => space.id)
      })
      this.$store.commit('app/setRequestItems', {
        level: 'carparks',
        items: selectedCarSpaces.map(carpark => carpark.id_carspace)
      })
      this.$store.commit('app/setRequestItems', {
        level: 'outdoors',
        items: selectedOutdoors.map(outdoor => outdoor.id_outdoor)
      })
      this.$store.commit('app/setRequestItems', {
        level: 'vegetation',
        items: selectedVegetation.map(vegetation => vegetation.id_plantarea)
      })
    },
    async modelLoaded (data) {
      this.loadingFailed = false
      if (data.layers !== undefined) {
        this.modelLayers = data.layers // finally we got layers!
        for (var prop in data.dwgSpaceData) {
          this.layerGroups.push(prop)
        }
        this.layers = await this.$rambollfmapi.layers.list()
        this.initLayers()
        this.updateDisabledLayers()
      }

      this.isLoading = false
    },
    modelFailed () {
      this.isLoading = false
      this.loadingFailed = true
    },
    deleteSelections () {
      this.selectedSpacesFromViewer = []
    },
    toggleLayerOption (name) {
      const pos = this.show.layerOptions.indexOf(name)
      if (pos < 0) {
        this.show.layerOptions.push(name)
      } else {
        this.show.layerOptions.splice(pos, 1)
      }
    },
    toggleCategoryOption (option) {
      const pos = this.selectedCategoryOptions.indexOf(option)
      if (pos < 0) {
        this.selectedCategoryOptions.push(option)
      } else {
        this.selectedCategoryOptions.splice(pos, 1)
      }
    },
    toggleCategoryOptions () {
      if (
        this.selectedCategoryOptions.length === 0 &&
        this.selectedCategory !== null
      ) {
        this.selectedCategory.options.forEach(option => {
          this.selectedCategoryOptions.push(option)
        })
      } else {
        this.selectedCategoryOptions = []
      }
    },
    initLayers () {
      // define visible layers
      this.layers.forEach(layer => {
        if (layer.isonofflayer && layer.show_by_default) {
          this.visibleLayers.push(layer)
        }
        if (
          layer.isonofflayer &&
          !layer.show_by_default &&
          this.modelLayers !== null
        ) {
          this.hiddenLayers.push(
            ...layer.layer_ids.reduce((acc, cur) => {
              acc.push(...this.getLayers(cur))
              return acc
            }, [])
          )
        }
      })
      if (this.toggleableLayers !== undefined) {
        this.toggleableLayers
        .filter(l => l.istextlayer && l.show_by_default)
        .forEach(layer => {
          if (!this.selectedAddTextLayers.find(l => l.name === layer.name)) {
            this.visibleLayers.push(layer)
            this.selectedAddTextLayers.push(layer)
          }
        })
      }

    },
    getCategoryExtraData (cat) {
      if (
        this.selectedCategoryExtraData !== null &&
        this.selectedCategoryExtraData[cat.key] !== undefined
      ) {
        const data = []
        const headers = Object.keys(this.selectedCategoryExtraData[cat.key])

        headers.forEach(header => {
          data.push({
            header: header,
            value: this.selectedCategoryExtraData[cat.key][header]
          })
        })
        return { title: cat.name, data: data }
      } else {
        return { title: cat.name, data: [] }
      }
    },
    viewEstatePlan () {
      this.$store.commit('app/setRequestItems', {
        level: 'estate',
        items: [this.buildingData.id_estate]
      })
      this.models = []

      const updated = moment(this.selectedEstate.dwg_updated).format(
        'YYYYMMDDhhmmss'
      )
      const startDateFormatted = moment(this.selectedEstate.start_date).add(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss')

      var dwgurl =
        'https://api3.rambollfm.fi/v3.1/estates/' +
        Number(this.buildingData.id_estate) +
        '/dwg?customer_id=' +
        this.$store.state.app.userInfo.customer_id +
        '&v=' +
        Number(this.selectedEstate.v3dwgversion) +
        '&lastUpdate=' +
        updated +
        '&time=' +
        startDateFormatted
      this.$log.info('*** dwgurl', dwgurl)

      this.models.push({
        name: 'estate_' + this.buildingData.id_estate.toString(),
        url: dwgurl,
        backendModelId: this.buildingData.model_id_estate
      })
    },
    updateFloorData () {
      // Retrieve floor data and produce categories
      if (typeof this.floorId !== 'undefined' && this.floorId > 0) {
        this.isFloorUpdating = true
        // When updating we store the selected category
        if (this.selectedCategory) {
          this.selectedCategoryQueue = this.selectedCategory.name
        }

        this.spaceTextData = []

        this.refreshUnits()
        this.refreshSpaces()
        this.refreshCarSpaces()

        this.categories = []
        // #TODO Create selection to choose between space and unit layers if both are present
        this.$rambollfmapi.floors
          .categories(this.floorId, 'Space', this.currentDate)
          .then(res => {
            this.categories = res
            // create list of sub categories
            const allCategoryOptions = this.categories.reduce((acc, cur) => {
              if (typeof acc[cur.name] === 'undefined') {
                acc[cur.name] = []
              }
              acc[cur.name] = [...acc[cur.name], ...cur.values]
              // a bit inefficient but should work (remove duplicates)
              acc[cur.name] = acc[cur.name].filter((opt, pos) => {
                return acc[cur.name].map(c => c.name).indexOf(opt.name) === pos
              })
              acc[cur.name] = acc[cur.name].map(opt => {
                if (typeof opt.rgba === 'undefined') {
                  const color = opt.color.split(',')
                  opt.rgba = 'rgba(' + color + ', 1)'
                }
                return opt
              })

              return acc
            }, {})

            // fix categories
            this.categories = this.categories.map(c => {
              c.options = allCategoryOptions[c.name]
              return c
            })

            // Retrieve the formar category
            if (this.selectedCategoryQueue) {
              this.changeCategory(
                this.categories.find(
                  cat => cat.name === this.selectedCategoryQueue
                )
              )
              this.selectedCategoryQueue = null
            }
          })
          .catch(error => this.$log.info(error))
        this.isFloorUpdating = false
      }
    },
    updateEstateData () {
      this.isEstateUpdating = true

      if (this.selectedCategory) {
        this.selectedCategoryQueue = this.selectedCategory.name
      }

      this.refreshCarSpaces()
      this.refreshOutdoors()
      this.refreshVegetation()
      // If we are viewing estate dwg, we'll retrieve the estate categories
      this.categories = []
      // #TODO Create selection to choose between space and unit layers if both are present
      this.$rambollfmapi.estates
        .categories(
          this.buildingData.id_estate,
          'ParkingSpace',
          this.currentDate
        )
        .then(res => {
          this.categories = res
          // create list of sub categories
          const allCategoryOptions = this.categories.reduce((acc, cur) => {
            if (typeof acc[cur.name] === 'undefined') {
              acc[cur.name] = []
            }
            acc[cur.name] = [...acc[cur.name], ...cur.values]
            // a bit inefficient but should work (remove duplicates)
            acc[cur.name] = acc[cur.name].filter((opt, pos) => {
              return acc[cur.name].map(c => c.name).indexOf(opt.name) === pos
            })
            acc[cur.name] = acc[cur.name].map(opt => {
              if (typeof opt.rgba === 'undefined') {
                const color = opt.color.split(',')
                opt.rgba = 'rgba(' + color + ', 1)'
              }
              return opt
            })

            return acc
          }, {})

          // fix categories
          this.categories = this.categories.map(c => {
            c.options = allCategoryOptions[c.name]
            return c
          })

          // Retrieve the formar category
          if (this.selectedCategoryQueue) {
            this.changeCategory(
              this.categories.find(
                cat => cat.name === this.selectedCategoryQueue
              )
            )
            this.selectedCategoryQueue = null
          }
        })
        .catch(error => this.$log.info(error))
      this.isEstateUpdating = false
    },
    refreshSelectedUnits () {
      // We need to create list of selected sections (spaces and units). Filter out duplicates
      const selectedSectionIds = this.selectedUnitsFromViewer.map(e => e.id)

      const selectedUnits = selectedSectionIds
        .map(id => this.units.find(s => s.id === id))
        .filter(s => s !== undefined)
        .sort((a, b) => a.id - b.id)

      this.selectedUnitsFromViewer = selectedUnits
    },
    refreshSelectedSpaces () {
      const selectedSectionIds = this.selectedSpacesFromViewer.map(e => e.id)

      const selectedSpaces = selectedSectionIds
        .map(id => this.spaces.find(s => s.id === id))
        .filter(s => s !== undefined)
        .sort((a, b) => a.name - b.name)

      this.selectedSpacesFromViewer = selectedSpaces
    },
    refreshSelectedCarSpaces () {
      const selectedSectionIds = this.selectedCarSpacesFromViewer.map(
        e => e.id_carspace
      )
      const selectedCarSpaces = selectedSectionIds
        .map(id => this.carSpaces.find(c => c.id_carspace === id))
        .filter(c => c !== undefined)
        .sort((a, b) => a.nro - b.nro)

      this.selectedCarSpacesFromViewer = selectedCarSpaces
    },
    refreshSelectedOutdoors () {
      const selectedSectionIds = this.selectedOutdoorsFromViewer.map(
        e => e.id_outdoor
      )

      const selectedOutdoors = selectedSectionIds
        .map(id => this.outdoors.find(o => o.id_outdoor === id))
        .filter(o => o !== undefined)
        .sort((a, b) => a.name - b.name)

      this.selectedOutdoorsFromViewer = selectedOutdoors
    },
    refreshSelectedVegetation () {
      const selectedSectionIds = this.selectedVegetationFromViewer.map(
        e => e.id_plantarea
      )
      const selectedVegetation = selectedSectionIds
        .map(id => this.vegetation.find(v => v.id_plantarea.ToString() === id))
        .filter(v => v !== undefined)
        .sort((a, b) => a.id_plantarea - b.id_plantarea)

      this.selectedVegetationFromViewer = selectedVegetation
    },
    clearSelections (layer) {
      this.selectedSpaces = []
      this.selectedSpacesFromViewer = []
      this.selectedUnits = []
      this.selectedUnitsFromViewer = []
      this.selectedCarSpaces = []
      this.selectedCarSpacesFromViewer = []
      this.selectedOutdoors = []
      this.selectedOutdoorsFromViewer = []
      this.selectedCategory = null
      this.selectedCategoryExtraData = []
      this.selectedCategoryOptions = []
      this.selectedVegetation = []
      this.selectedVegetationFromViewer = []
      this.selectedElementLayer = layer
    },
    async refreshUnits () {
      this.units = await this.$rambollfmapi.floors.units(this.floorId, this.currentDate).extra()
      await this.refreshSelectedUnits()
      this.unitPartyLinks = await this.$rambollfmapi.floors.units(this.floorId, this.currentDate).partyLinks()
      this.contracts = await this.$rambollfmapi.floors.contractrows(this.floorId, this.currentDate)
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')
        || this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
        this.contractLinks = await this.$rambollfmapi.floors.units(this.floorId, this.currentDate).contracts()
      }
    },
    async refreshSpaces () {
      this.spaces = await this.$rambollfmapi.floors.spaces(this.floorId, this.currentDate).list()
      this.refreshSpaceTexts()
      this.refreshSelectedSpaces()

      if (this.hasApplicationPermissionByName('SISAINENVUOKRAUS')) {
        this.spaceShares = await this.$rambollfmapi.internalrent.shares().list(this.spaces.map(space => { return space.id }), this.$store.state.app.currentDate)
      }

      this.customizedValues = await this.$rambollfmapi.customizabledatafields.spaces(this.spaces.map(space => { return space.id }))

      this.spacePartyLinks = await this.$rambollfmapi.floors.spaces(this.floorId, this.currentDate).partyLinks()
      this.partyInSomeLink = await this.$rambollfmapi.reports.post('GetPartyIdsOfSpaceLinks', [])
    },
    async refreshCarSpaces () {
      if (this.estateDwgActive) {
        this.carSpaces = await this.$rambollfmapi.carparks.estate(this.buildingData.id_estate, this.currentDate)
        this.refreshSelectedCarSpaces()
        this.carparkTenants = await this.$rambollfmapi.carparks.tenants().estate(this.buildingData.id_estate, this.currentDate)
      } else {
        this.carSpaces = await this.$rambollfmapi.carparks.floor(this.floorId, this.currentDate)
        this.refreshSelectedCarSpaces()

        if (this.floorId !== null)
          this.carparkTenants = await this.$rambollfmapi.carparks.tenants().floor(this.floorId, this.currentDate)
      }
      this.loadCarparkSummary(this.buildingCode)
    },
    async refreshOutdoors () {
      this.outdoors = await this.$rambollfmapi.estates.outdoors(this.buildingData.id_estate).list()
      this.refreshSelectedOutdoors()
    },
    async refreshVegetation () {
      this.vegetation = await this.$rambollfmapi.estates.vegetation(this.buildingData.id_estate).list()
      this.refreshSelectedVegetation()
    },
    async loadFloors (buildingCode) {
      this.loadingFailed = false

      let floors = await this.$rambollfmapi.buildings
        .floors(buildingCode)
        .list(this.currentDate)

      floors = floors.sort((a, b) => {
        return a.floor_number - b.floor_number > 0
      })
      floors = floors.map(f => {
        if (f.has_dwg) {
          f.enabled = true
        } else {
          f.enabled = false
        }
        return f
      })
      this.floors = floors

      this.units = await this.$rambollfmapi.buildings.units(buildingCode, this.currentDate)

      if (!this.estateDwgActive) {
        let floorNumber = 1
        if (this.selectedFloor !== null) {
          floorNumber = this.selectedFloor.floor_number
        }
        this.selectedFloor = null

        this.floors.forEach(elem => {
          if (
            this.selectedFloor === null &&
            elem.has_dwg === true &&
            elem.floor_number >= floorNumber
          ) {
            this.selectedFloor = elem
            this.floorId = elem.id
          }
        })
        if (this.selectedFloor === null) {
          this.floors.forEach(elem => {
            if (
              this.selectedFloor === null &&
              elem.has_dwg === true &&
              elem.floor_number < floorNumber
            ) {
              this.selectedFloor = elem
              this.floorId = elem.id
            }
          })
        }
      }
    },
    async loadEstate (estateId) {
      const estate = await this.$rambollfmapi.estates.get(estateId, this.currentDate)
      if (estate.name === 'Error') {
        this.selectedEstate = null
        if (this.floors < 1) {
          this.noFloors = true
        }
      } else {
        this.selectedEstate = estate
        this.updateEstateData()
        if (this.estateDwgOnly) {
          this.isFloorUpdating = false
        }
      }
    },
    updateCurrent () {
      if (!this.estateDwgActive) {
        this.updateFloorData()
      } else {
        this.updateEstateData()
      }
    },
    async initialize () {
      // If building code is defined, retrieve all data required for the viewer
      if (typeof this.buildingCode === 'undefined' || this.buildingCode === '') {
        return
      }
      // retrieve layers
      this.$rambollfmapi.layers.list().then(res => {
        this.layers = res
        this.initLayers()
        this.updateDisabledLayers()
      })

      this.$rambollfmapi.buildings.models(this.buildingCode).then(res => {
        this.availableIfcModels = res
      })

      // Retrieve visibility rights
      this.spaceVisibilityRights = await this.$rambollfmapi.spaces.metadata()
      this.unitVisibilityRights = await this.$rambollfmapi.units
        .extra()
        .metadata()
      this.contractVisibilityRights = await this.$rambollfmapi.contracts.contract.metadata()
      this.outdoorsVisibilityRights = await this.$rambollfmapi.estates.outdoors(undefined).metadata()

      // Retrieve estate data
      await this.loadEstate(this.buildingData.id_estate)
      // Retrieve building floors and sort them. Retrieve building units in same call
      this.loadFloors(this.buildingCode)

      this.$rambollfmapi.organizations
        .costCenters()
        .list()
        .then(res => {
          this.costcenters = res.map(cc => ({
            id: cc.id,
            name: `${cc.code} - ${cc.name}`,
            allocation_rule: cc.allocation_rule,
            code: cc.code
          }))
        })
      if (this.purposeZones.length < 1) {
        this.getPurposeZones()
      }

      // Retrieve definitions for purpose of use, cleaning areas and fire areas
      this.$rambollfmapi.definitions
        .purposesofuse()
        .then(res => {
          this.purposesOfUse = res
        })
        .catch(error => this.$log.info(error))

      this.$rambollfmapi.customizabledatafields
        .get()
        .then(res => {
          this.customizableFields = res.items
        })
        .catch(error => this.$log.info(error))

      this.$rambollfmapi.parties
        .list()
        .then(res => {
          this.parties = res
        })
        .catch(error => this.$log.info(error))

      // User rights
      this.applicationPermissions.forEach(app => {
        if (app.id === '04.03.01') {
          this.appRights.addUnit = true
        }
        if(app.id === '04.04.07') {
          this.appRights.moveSpace = true
        }
        if (app.id === '04.17') {
          this.loadDevices(this.currentSite.id_site)
          this.appRights.showDevices = true
          if (app.accessLevel === 1) {
            this.appRights.editDevices = true
          }
        }
      })

      if (this.estateDwgOnly) {
        this.activateEstateDwg()
      }

      this.loadCarparkSummary(this.buildingCode)
    },
    getCategoryOptionName (option, category) {
      // Category options that come with the substring of 'translationkey.' will be translated.
      if (option.name.includes('translationkey.')) {
        return [this.$t(option.name.replace('translationkey.', ''))]
      }

      if (this.getCategoryShortName(category.name) === 'Rental Status') {
        // TODO: calculate contract length and return rent status name accordingly
        // eg. contract ending within 3, 6, 9 or 12 months
        let name = this.$t(option.name)
        if (option.name === 'Empty') {
          if (
            this.selectedElementLayer === '1' ||
            this.selectedElementLayer === '2'
          ) {
            name = this.$t('Vacant units')
          }
        }
        return [name]
      } else if (this.getCategoryShortName(category.name) === 'Units') {
        let optionName = this.$t(option.name)
        let parts = optionName.split(' ')
        if (parts.length > 2) {
          let remainingParts = parts.slice(2).join(' ')
          if (parts[0] != remainingParts) {
            return [parts[0], parts[1], remainingParts]
          }
          else {
            return [parts[0], parts[1]]
          }
        } else {
          return parts
        }
      } else {
        return [this.$t(option.name)]
      }
    },
    getVATStatus (status) {
      return status === 1 ? this.$t('Yes') : this.$t('No')
    },
    refreshSpaceTexts () {
      if (this.selectedAddTextLayers.length > 0) {
        var data = []
        this.spaces.forEach(space => {
          var spaceText = ''
          this.selectedAddTextLayers.forEach(layer => {
            if (space[layer.name] !== null && space[layer.name] !== undefined) {
              if (
                spaceText.length > 0 &&
                layer.name !== 'attached_number_of_personnel'
              ) {
                spaceText += '\n'
              }
              if (layer.name === 'id_purpose') {
                spaceText += this.purposesOfUse.find(
                  pou => pou.id === space.id_purpose
                ).title
              } else if (layer.name === 'attached_number_of_personnel') {
                this.spacePartyLinks
                  .filter(spl => spl.id_parent === space.id)
                  .forEach(link => {
                    if (spaceText.length > 0) {
                      spaceText += '\n'
                    }
                    spaceText += this.parties.find(p => p.id === link.id_party)
                      .name
                  })
              } else if (layer.name === 'area') {
                spaceText += space[layer.name] + 'm\u00B2'
              } else if (layer.name === 'number_of_personnel') {
                if (space.number_of_workstations > 0) {
                  spaceText += space[layer.name] + ' ' + this.$t('Personnel lyh')
                }
              }
              else {
                spaceText += space[layer.name]
              }
            } else {
              spaceText += '\n'
            }
          })
          for (var x = 0; x < (this.addTextLayers.length - this.selectedAddTextLayers.length); x++) {
              spaceText += '\n'
          }
          if (spaceText.length > 0) {
            data.push({
              name: space.name,
              text: spaceText
            })
          }
        })
        //Causes an error if the data is changed from nothing to nothing because of watchers
        if(!(data.length === 0 && this.spaceTextData.length === 0))
        this.spaceTextData = data
      } else {
        this.spaceTextData = []
      }
    },
    zoomMap (value) {
      if (value === 'plus') {
        this.zoomDirection = 0.4
      } else if (value === 'minus') {
        this.zoomDirection = -0.4
      }
    },
    getCategoryShortName (name) {
      name = name.split(' (')[0]
      return name
    },
    async openZoomLevelSettingDialog (type) {
      if(this.selectedElementLayer === '1' && this.selectedSpacesFromViewer.length > 0) {
        const selectedSpaceId = this.selectedSpacesFromViewer[0].id
        const foundSpacesInUnit = await this.$rambollfmapi.units.getSpacesInUnit(selectedSpaceId, this.currentDate)
        if(foundSpacesInUnit) {
          this.selectedSpacesForPrinting = foundSpacesInUnit
        }
      }

      this.zoomLevelSelectionDialogVisible = true
      this.printDocumentType = type
    },
    closeZoomLevelSettingDialog () {
      this.zoomLevelSelectionDialogVisible = false
      this.printDocumentType = null
      this.selectedProspect = null
    },
    async openPrinting (zoomLevelSelectionCamera) {
      this.v3camera = zoomLevelSelectionCamera
      this.zoomLevelSelectionDialogVisible = false
      if (this.printDocumentType === 'rental-contract') {
        this.printOpen = true
      } else if (this.printDocumentType === 'unit-brochure') {
        this.printOfferOpen = true
      } else {
        this.$store.dispatch('error/addError', 'err.undefined_print_type')
      }
    },
    async closePrint (type) {
      const unitIds = this.selectedUnitsFromViewer.map(unit => unit.id)
      if (type === 'rental-contract') {
        for (const unit of this.selectedUnitsFromViewer) {
          await this.$rambollfmapi.units.printLog().post(unit.id, 'Contract')
        }
      }
      if (type === 'unit-brochure') {
        for (const unit of this.selectedUnitsFromViewer) {
          await this.$rambollfmapi.units.printLog().post(unit.id, 'Offer')
        }
      }
      // If prospect was chosen, log print to prospects also
      if (this.selectedProspect != null) {
          const unitProspectIds = []
          this.selectedProspect.sites.forEach(site => {
            site.units.forEach(unit => {
              if (unitIds.includes(unit.id_unit)) {
                unitProspectIds.push(unit.idProspectUnit)
              }
            })
          })
          if (type === 'rental-contract') {
            const data = {
              type: 1,
              units: unitProspectIds,
              downloaded: this.currentDate,
              id_prospect: this.selectedProspect.id_prospect
            }
            await this.$rambollfmapi.leasing.prospects.printout(data)
            this.selectedProspect = null
            this.getProspects()
          }
          if (type === 'unit-brochure') {
            const data = {
              type: 0,
              units: unitProspectIds,
              downloaded: this.currentDate,
              id_prospect: this.selectedProspect.id_prospect
            }
            await this.$rambollfmapi.leasing.prospects.printout(data)
            this.selectedProspect = null
            this.getProspects()
          }
      }
      this.refreshUnits()
      this.printOpen = false
      this.printOfferOpen = false
    },
    selectTab (tab) {
      this.tab = tab
      if (tab === 'carparks') {
        this.selectedElementLayerFromTab = '101'

        // Fetch carpark contracts only if needed. This makes time to page smaller.
        if (this.carparkContracts.length === 0) {
          this.fetchCarparkContracts()
        }
      }
      else if (tab === 'renting' && this.hasApplicationPermissionByName('LEASING')) {
        this.loadRentalProcesses()
      }
      else {
        this.selectedElementLayerFromTab = null
      }
    },
    activateEstateDwg () {
      this.selectedFloor = null
      this.floorId = null
      this.selectedCategory = null
      this.selectedCategoryOptions = null
      this.buildingIfcActive = false
      this.estateDwgActive = true
    },
    selectFromFloorPlan () {
      const selectedLayer = this.selectedElementLayer
      // Activate multiselect mode
      this.$refs.v3viewer.enterSelectionMode()
      let elementsFromSelectedCategoryOptions = []
      this.selectedCategoryOptions.map(option => { option.elements.map(element => { elementsFromSelectedCategoryOptions.push(element) }) })
      this.$refs.v3viewer.selectElementsFromSelectedCategories(selectedLayer, elementsFromSelectedCategoryOptions)
    },
    getMetaFormat (label) {
      if(label.endsWith('area'))
      {
        return 'Area'
      }
      else if (label.includes('area')) {
        return 'Area'
      }
      else
      {
        return 'None'
      }
    },
    setProspect (prospect) {
      this.selectedProspect = prospect
    },
    setProcess (process) {
      this.selectedProcess = process
    },
    loadParties () {
      this.$rambollfmapi.parties
        .list()
        .then(res => {
          this.parties = res
        })
        .catch(error => this.$log.info(error))
    },
    fetchCarparkContracts () {
      if (this.buildingData && this.buildingData.id_building) {
        this.$rambollfmapi.reports.post(
          'carparkcontracts',
          [this.buildingData.id_building],
          this.currentDate
        ).then(res => { this.carparkContracts = res })
      }
    },
    loadCarparkSummary (buildingCode) {
      this.buildingCarparksSummary = null
      this.$rambollfmapi.carparks.buildingSummary(buildingCode, this.currentDate)
        .then(response => {
          if (response.name !== 'Error') {
            this.buildingCarparksSummary = response
          }
        })
    },
    loadRentalProcesses () {
      this.getRentalProcess({siteId: this.currentSite.id_site})
    }
  }
}

/* eslint-enable */
</script>
<style scoped>
.content {
  height: 100%;
  border: 2px solid #ebeef8;
  border-top: 1px solid #ebeef8;
  border-bottom: 1px solid #ebeef8;
  margin: 2px !important;
}

#rambollviewer {
  max-width: 95%;
  z-index: 1;
}

#rambollviewer .toolbar {
  position: absolute;
  top: 1em;
  background: none;
  left: 0;
  width: 100%;
  padding: 1em;
}
#rambollviewer .toolbar .col {
  padding-left: 0.4em;
  padding-right: 0.4em;
}
#rambollviewer .toolbar-info {
  text-align: center;
  font-size: 16pt;
}
#rambollviewer .toolbar .v-btn.v-btn--outlined {
  border: 2px solid #dce0ee !important;
  background: white !important;
}
#rambollviewer .toolbar .v-btn.v-btn--outlined.primary--text {
  background: #dce0ee !important;
}
#rambollviewer .toolbar-top-right {
  position: absolute;
  top: 1em;
  right: 2em;
}
#rambollviewer .toolbar-top-right .v-btn {
  background: white;
}
#rambollviewer .toolbar-top-left {
  max-width: 10pt;
  margin-right: 20pt;
  min-height: 20pt;
}
#rambollviewer .toolbar-top-left .v-btn {
  margin: var(--r-spacing-xxxs) !important;
}
#rambollviewer .view {
  min-height: 700px;
  max-height: 800px;
  background-color: #fbfbfb;
  border-radius: 2em 0 0 2em;
  padding: 3em;
  position: relative;
}
#rambollviewer .floorselect {
  background-color: #ececec;
  border-radius: 0 3em 3em 0;
  padding: 1em;
  min-width: 100px;
}
#rambollviewer .floorselect .theme--light.v-btn {
  background-color: white;
  font-weight: bold;
  border: 2px solid var(--c-color-accent);
  font-size: 16pt;
  color: var(--c-color-accent);
}
#rambollviewer .floorselect .v-btn--disabled {
  border: 0 !important;
  border: 2px solid #ddd !important;
  color: #111;
}
#rambollviewer .info {
  min-width: 500px;
  max-width: 500px;
  background: white !important;
}
#rambollviewer .info-box .header {
  margin: 2px;
  border: none;
}
#rambollviewer .info-box#solid .tile:last-child {
  margin: 2px;
  border-bottom: 2px solid #ebeef8;
  border-bottom-left-radius: 1.2em !important;
  border-bottom-right-radius: 1.2em !important;
}
#rambollviewer .info-box .tile:last-child {
  margin: 2px;
}
#rambollviewer .info-box .tile .tile-content {
  font-weight: bold;
  padding-left: 0.5em;
  padding-right: 0.5em;
  width: 10em;
  word-break: break-word;
  min-height: 40px;
  height: 100%;
}
#rambollviewer.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: white;
  padding: 0.5em;
}
#rambollviewer.fullscreen .view {
  height: calc(100vh - 1em) !important;
  max-height: 100vh !important;
}
#rambollviewer.fullscreen .info-window {
  overflow: auto;
  height: calc(98vh - 1em) !important;
  max-height: 98vh !important;
}
.progressBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88%;
  width: 95%;
  position: absolute;
  pointer-events: none;
}
.viewerLoadingProgress {
  position: absolute;
  width: 80%;
}
.v-alert {
  padding: 6px;
  border-radius: 2em;
}
.overlay-toolbar {
  border-radius: 1em;
  width: calc(100% - 2em);
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 1em;
  margin: 1em;
}
.legend {
  position: absolute;
  top: 8em;
  left: 1em;
}
.legend-box {
  border-radius: 1em;
  background: rgba(0, 0, 0, 0.7);
  /*background: rgba(255,255,255,0.9);*/
  color: white;
  margin-top: 0.5em;
}
.legend-text {
  font-size: 10pt;
}
.legend-color {
  min-width: 32px;
}
.rounded {
  border-radius: 2em !important;
}
.rounded .v-card {
  border-radius: 2em !important;
  padding: 1em;
}
.selected-floor {
  border: 1px solid var(--c-color-accent);
  background: var(--c-color-accent) !important;
  color: white !important;
}
.description-btn {
  background-color: #ebeef8;
  color: var(--r-color-mountain-100);
  border-radius: 0;
  margin: 0;
  height: 100%;
}
.inner-expansion-container {
  margin: 0.2em;
}
.inner-expansion >>> .v-expansion-panel__header {
  border-radius: 1.3em;
}
.inner-expansion
  >>> .v-expansion-panel__container--active
  .v-expansion-panel__header {
  border-radius: 0;
  border-top-left-radius: 1.3em;
  border-top-right-radius: 1.3em;
}
.v-list__tile__content.tile-content.option-content {
  background-color: #eeeff5;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  margin-right: 0;
}
</style>
<style>
#rambollviewer .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
  height: 100%;
}
#rambollviewer
  .v-list--dense
  .v-list__tile:not(.v-list__tile--avatar)
  .v-list__tile__content {
  min-height: 40px;
  font-weight: bold;
}
#rambollviewer .align-right {
  align-items: end;
}
.info-window {
  padding: 0.5em;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
.info-panel {
  margin-top: 0.5em;
  width: 100%;
}
.col-xs-12 {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 760px) {
  .col-xs-12 {
    flex-direction: column-reverse;
  }
}
</style>
