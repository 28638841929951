<template>
  <div
    v-if="items.length > 0"
    class="c-list"
  >
    <div class="c-list__header">
      <slot name="header" />
    </div>
    <v-list two-line>
      <v-list-item
        v-for="(item) in items"
        :key="item.id"
      >
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ item.headline }}
          </v-list-item-subtitle>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle class="text--primary">
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="flex-row align-center">
          <slot name="actions" />
          <v-btn
            icon
            color="tertiary"
            :aria-label="`${$t('Modify')} ${item.title}`"
            @click="$emit('editItem', item)"
          >
            <v-icon>
              edit
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="tertiary"
            :aria-label="`${$t('Delete')} ${item.title}`"
            @click="$emit('deleteItem', item)"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div class="c-list__footer">
      <slot name="footer" />
    </div>
  </div>
  <CdsAlert
    v-else
    icon="info"
    :title="emptyText"
    size="large"
  >
    <slot name="emptyContent" />
  </CdsAlert>
</template>
<script>
import CdsAlert from '../CircleDesignSystem/CdsAlert.vue'
export default {
  name: 'CList',
  components: {
    CdsAlert,
  },
  props: {
    items: {
      type: Array,
      default: undefined,
      required: true
    },
    emptyText: {
      type: String,
      default: ''
    },
  },
  emits: ['editItem', 'deleteItem'],
}
</script>
<style scoped>
.c-list {
  border: 1px solid var(--r-color-grey-10);
  margin: var(--r-spacing-xs) 0;
  border-radius: var(--r-spacing-xs);
}
.c-list .v-list {
  border-radius: var(--r-spacing-xs);
}
.c-list .v-list-item:hover {
  background: var(--r-color-grey-5);
}
.c-list__header:not(:empty) {
  padding: var(--r-spacing-s) var(--r-spacing-m) var(--r-spacing-xxs);
}
.c-list__footer:not(:empty) {
  padding: var(--r-spacing-xxs) var(--r-spacing-m) var(--r-spacing-s);
}
</style>
