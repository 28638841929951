!<template>
  <div>
    <!-- Allocation info dialog -->
    <v-dialog
      v-model="showInfoDialog"
      persistent
      max-width="750"
    >
      <v-card class="success-dialog pb-3">
        <v-btn
          large
          icon
          right
          absolute
          style="margin-top: 0.8em"
          @click="closeInfoDialog"
        >
          <v-icon color="white">
            close
          </v-icon>
        </v-btn>
        <v-card-title />
        <v-card-text class="px-15 pb-10">
          <p class="text-center text-h6 font-weight-bold">
            <br><br>
            <span class="white--text">
              {{ $t("prospect.allocation_info_1") }}
            </span>
            <br>
            <span class="white--text">
              {{ $t("prospect.allocation_info_2") }}
            </span>
            <br>
            <span>
              <v-icon
                large
                class="success-dialog"
              >
                sentiment_satisfied_alt
              </v-icon>
            </span>
            <br>
            <span class="white--text">
              {{ $t("prospect.allocation_info_3") }}
            </span>
            <br>
            <span class="white--text text-subtitle-1 font-weight-regular">
              {{ $t("prospect.allocation_info_4") }}
            </span>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>    
    <!-- Closed successfully info dialog -->
    <v-dialog
      v-model="showSuccessDialog"
      persistent
      width="750"
    >
      <v-card class="success-dialog pb-3">
        <v-btn
          large
          icon
          right
          absolute
          style="margin-top: 0.8em"
          @click="closeSuccessDialog"
        >
          <v-icon color="white">
            close
          </v-icon>
        </v-btn>
        <v-card-title />
        <v-card-text class="px-15 pb-10">
          <p class="text-center text-h6 font-weight-bold">
            <span>
              <v-icon
                size="150"
                class="success-dialog"
              >
                sentiment_satisfied_alt
              </v-icon>
            </span>
            <br>
            <span class="white--text">
              {{ $t("prospect.closed_success_1") }}
            </span>
            <br><br>
            <span class="white--text font-weight-regular">
              {{ $t("prospect.closed_success_2") }}
            </span>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showIgnoreConfirmation"
      persistent
      width="750"
    >
      <BaseModal
        @cancel="showIgnoreConfirmation = false"
      >
        <template #title>
          {{ $t('prospect.ignore_report_confirmation_title') }}?
        </template>
        <template #content>
          <p class="text-center text-subtitle text-subtitle-1 font-weight-bold">
            {{ $t("prospect.ignore_report_confirmation_text") }}?
          </p>
        </template>
        <template #footer>
          <v-btn
            rounded
            depressed
            color="primary"
            @click="allocateWithConfirm()"
          >
            {{ $t('Continue') }}
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>    
    <!-- View for setting the prospect to await allocation -->
    <div v-if="!hideAllocationDialog">
      <v-card
        v-if="status !== 'AwaitingAllocation'"
      >
        <BaseModal
          @cancel="close"
        >
          <template #title>
            {{ $t('prospect.success') }}
          </template>
          <template #content>
            <p class="text-center  text-subtitle-1">
              <span class="font-weight-bold">
                {{ $t('prospect.success_body_1') }}
                <br>
                {{ $t('prospect.success_body_2') }}
              </span>
              <br>
              <br>
              <span class="font-italic">
                {{ $t('prospect.contract_signing_reminder') }}
              </span>
            </p>
          </template>
          <template #hideCancel>
            <p />
          </template>
          <template #footer>
            <v-btn
              text
              rounded
              outlined
              class="no-transform-buttons"
              @click="close"
            >
              {{ $t('prospect.contract_still_open') }}
            </v-btn>
            <v-btn
              text
              rounded
              class="primary no-transform-buttons"
              :loading="loading"
              @click="setProspectStatus(3)"
            >
              {{ $t('Yes').toUpperCase() + ', ' + $t('prospect.contract_imminent').toLowerCase() }}
            </v-btn>
          </template>
        </BaseModal>
      </v-card>
      <!-- View for allocating the prospect to a contract -->
      <v-card v-else>
        <BaseModal
          @cancel="close"
        >
          <template #title>
            {{ $t('prospect.allocate_prospect') }}
          </template>
          <template #content>
            <h3 class="text-center pb-7">
              {{ $t('prospect.prospect_allocation_to_contract') }}
            </h3>
            <p class="text-center  text-subtitle-1 font-italic">
              {{ $t('prospect.prospect_allocation_selection_info') }}
            </p>
            <v-select
              v-model="selectedContract"
              :items="contracts"
              return-object
              item-text="label"
              item-value="contractId"
              :label="$t('Rental contract')"
              class="custom"
            >
              <template #selection="{ item }">
                {{ formatContractRow(item) }}
              </template>
              <template #item="{ item }">
                {{ formatContractRow(item) }}
              </template>
            </v-select>
            <v-checkbox
              v-model="ignore"
              hide-details
            >
              <template
                #label
              >
                <div class="ma-0 pa-0">
                  {{ $t('prospect.allocation_ignore') }}
                  <InfoBall
                    :info="$t('prospect.allocation_no_report_info')"
                    right
                  />
                </div>
              </template>
            </v-checkbox>
          </template>
          <template #footer>
            <v-btn
              rounded
              depressed
              color="primary"
              :loading="loading"
              :disabled="selectedContract == null"
              @click="confirmAllocation()"
            >
              {{ $t('Allocate') }}
            </v-btn>
          </template>
        </BaseModal>
      </v-card>
    </div>
  </div>
</template>

<script>
import InfoBall from './../InfoBall.vue'
import { mapActions } from 'vuex'
import helpers from '../../helpers'
import BaseModal from '../general/BaseModal.vue'

export default {
  emits: ['close', 'update'],
  name: 'AllocationModal',
  components: {
    BaseModal,
    InfoBall
  },
  props: {
    prospectId: {type: Number, default: null},
    prospectStatus: {type: String, default: ""},
    isShown: {type: Boolean, default: false},
    siteIds: {type: Array, default: () => []}
  },
  data () {
    return {
      showInfoDialog: false,
      showSuccessDialog: false,
      hideAllocationDialog: false,
      selectedContract: null,
      rentalStatusesForSites: [],
      status: "",
      loading: false,
      ignore: false,
      showIgnoreConfirmation: false,
      allocationContractsLoading: false
    }
  },
  computed: {
    contracts () {
      // Filter out duplicate contracts
      const contractNumbers = this.rentalStatusesForSites.map(contract => contract.contractNumber)
      const uniqueContractNumbers = new Set(contractNumbers)
      const uniqueContractNumbersArray = [...uniqueContractNumbers]
      return uniqueContractNumbersArray.map(contractNumber => this.rentalStatusesForSites.find(item => item.contractNumber === contractNumber))
    }
  },
  watch: {
    // Simple watcher to reset data values when the modal is hidden.
    isShown: async function (newValue) {
      if(!newValue) {
        this.reset()
      } 
      else {
        if (this.prospectStatus === 'AwaitingAllocation') {
          this.status = this.prospectStatus
          const time = new Date()
          time.setDate(time.getDate() - 180)
          this.allocationContractsLoading = true
          this.rentalStatusesForSites = await this.getRentalStatusesSignedAfter({prospectId: this.prospectId, time: time})
          this.allocationContractsLoading = false
        }
      }
    },
    // Info dialog is not persitent, so we have to activate close when it's closed by clicking outside of it.
    showInfoDialog: function (newValue) {
      if(!newValue) {
        this.close()
      }
    },
    showSuccessDialog: function (newValue) {
      if(!newValue) {
        this.close()
      }
    },
  },
  async mounted () {
    if (this.prospectStatus === 'AwaitingAllocation') {
      this.status = this.prospectStatus
      const time = new Date()
      time.setDate(time.getDate() - 180)
      this.allocationContractsLoading = true
      this.rentalStatusesForSites = await this.getRentalStatusesSignedAfter({prospectId: this.prospectId, time: time})
      this.allocationContractsLoading = false
    }
  },
  methods: {
    ...mapActions('leasing', ['changeProspectStatus', 'getRentalStatusesSignedAfter']),
    // Resets the components data values
    reset () {
      Object.assign(this.$data, this.$options.data());
    },
    close () {
      this.$emit('close')
    },
    async setProspectStatus (newStatus) {
      this.loading = true
      await this.changeProspectStatus({prospectId: this.prospectId, data: {state: newStatus, reason: null}})
      this.loading = false
      this.$emit('update')
      this.hideAllocationDialog = true
      this.showInfoDialog = true;
      this.status = this.prospectStatus
    },
    closeInfoDialog () {
        this.showInfoDialog = false;
    },
    closeSuccessDialog () {
        this.showSuccessDialog = false
    },
    formatData (item, format) {
      return helpers.format.formatData(item, format)
    },
    async allocateProspect () {
      this.loading = true
      await this.changeProspectStatus({prospectId: this.prospectId, data: {state: 2, contract_number: this.selectedContract.contractNumber, ignore: this.ignore}})
      this.loading = false
      this.$emit('update')
      this.hideAllocationDialog = true
      this.showSuccessDialog = true;
      this.status = this.prospectStatus
    },
    formatContractRow (item) {
      return item.contractNumber + ", " + item.curPartyName + ", " + this.formatData(item.signature_date, 'Date') + ", "  + this.formatData(item.contract_area, 'Area') + ", " +  item.siteName
    },
    confirmAllocation () {
      if (this.ignore) {
        this.showIgnoreConfirmation = true
      }
      else {
        this.allocateProspect()
      }
    },
    allocateWithConfirm () {
      this.showIgnoreConfirmation = false
      this.$nextTick(() => this.allocateProspect())
    }
  }
}
</script>

<style scoped>
.success-dialog {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.no-transform-buttons {
  text-transform: none;
}
.custom {
  height: auto !important;
}
.custom ::v-deep .v-select__selections {
  padding-top: 10px !important;
}
</style>