<template>
  <div id="expenseTypesAndCostCenters">
    <v-dialog
      v-if="allocationDialog"
      v-model="allocationDialog"
      max-width="800"
      persistent
    >
      <ValuationDialog
        :items="costAreaData.items"
        :portfolio-id="portfolio.id"
        @close="openAllocationDialog('', false)"
      />
    </v-dialog>
    <v-row
      v-if="portfolio"
      class="dashboard"
    >
      <v-col
        cols="12"
      >
        <ExpenseTable
          :data="costAreaData"
          :edit="allocationDialog"
          :cost-center-editing="costCenterEditing"
          :no-save-btn="true"
          :loading="loading"
          @editing="openAllocationDialog"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="fill-height"
      align="center"
      justify="center"
    >
      <v-col cols="3">
        <h3>{{ $t('internalrent.select_portfolio') }}</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExpenseTable from './ExpenseTable.vue'
import ValuationDialog from './ValuationDialog.vue'
import { mapActions, mapGetters, mapState  } from 'vuex'

export default {
  name: 'CostCenters',
  components: {
    ExpenseTable,
    ValuationDialog
  },
  data () {
    return {
      costAreaData: {
        title: this.$t('Cost areas'),
        headers: [
          {
            text: this.$t('Cost center'),
            value: 'code',
            editable: false
          },
          {
            text: this.$t('Cost center name'),
            value: 'name',
            editable: false
          },
          {
            text: this.$t('internalrent.allocated_for_spaces'),
            value: 'allocate_to_spaces',
            editable: false
          },
          {
            text: this.$t('internalrent.zero_allocation'),
            value: 'zero_allocation',
            editable: false
          },
          {
            text: this.$t('internalrent.ignore'),
            value: 'zero_expense',
            editable: false
          }
        ],
        footers: [],
        items: [],
        sortBy: [],
        sortDesc: [ true ],
        itemsPerPage: 30
      },
      allocationDialog: false,
      loading: true
    }
  },
  computed: {
    ...mapState('internalRent', [
      'costcenters',
      'portfolio',
      'rules'
    ]),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    costCenterEditing () {
      return this.hasApplicationPermissionByName('SIS_KUSTPAIK_MUOKKAUS')
    }
  },
  watch: {
    portfolio: async function () {
      this.loading = true
      if (this.portfolio) {
        await this.getCostcenters(this.portfolio.id)
        await this.getRules(this.portfolio.id)
        this.createCostAreaData()
        this.loading = false
      }
    }
  },
  async mounted () {
    if (this.portfolio) {
      await this.getCostcenters(this.portfolio.id)
      await this.getRules(this.portfolio.id)
      this.createCostAreaData()
      this.loading = false
    }
  },
  methods: {
    ...mapActions('internalRent', [
      'getCostcenters',
      'getPortfolio',
      'getRules',
      'updateRules'
    ]),
    openAllocationDialog (id, openAllocation) {
      this.allocationDialog = openAllocation
    },
    createCostAreaData () {
      this.costAreaData.items = this.costcenters
        .filter(costcenter => costcenter.id !== 0)
        .map(item => {
          const rule = this.rules.find(rule => rule.idCostcenter === item.id)
          if (rule) {
            item.rule = rule
            item.allocate_to_spaces = rule.rule === 'AllocateToSpaces'
            item.zero_allocation = rule.rule === 'ZeroAllocation'
            item.zero_expense = rule.rule === 'ZeroExpense'
          } else {
            item.rule = {
              idCostcenter: item.id,
              rule: 'None',
              allowModification: true,
              spaceRules: []
            }
            item.allocate_to_spaces = false
            item.zero_allocation = false
            item.zero_expense = false
          }
          return item
        })
    }
  }
}
</script>

<style>
</style>
