import RambollFMAPI from '../api/rambollfm.js'

export default {
  namespaced: true,
  state: {
    contractTemplates: [],
  },
  getters: {
    getContractTemplatesByOutRent: (state) => (isOutRent) => {
      return state.contractTemplates.filter(template => template.isOutRent === isOutRent)
    },
    getContractTemplateById: (state) => (templateId) => {
      return state.contractTemplates.find(template => template.id === templateId)
    },
    isTemplateFieldVisible: () => (fieldCode, contractTemplate) => {
      if (contractTemplate == undefined || contractTemplate == null)
      {
         return true
      }
      let contractFieldsWithCode = contractTemplate.fields.filter(field => field.code == fieldCode)
      if (contractFieldsWithCode.length == 1)
      {
        return contractFieldsWithCode[0].isVisible == true
      }
      return true
    },
  },
  mutations: {
    setContractTemplates (state, {templates}) {
      state.contractTemplates = templates
    }
  },
  actions: {
    async initContractTemplates ({ state, commit }) {
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const templates = await api.contracts.contract.templates()
        commit('setContractTemplates', { templates })
    }
  }
}
