import RambollFMAPI from '../api/rambollfm.js'

function requestSucceeded (result) {
  if (result.isAxiosError) {
    return false
  }
  return true
}

function patchData (data, id, field, value) {
  const modifiedData = data.map((item) => {
    if(item.id === id){
      return {...item, [field]: value}
    }
    return item
  })
  return modifiedData
}

export default {
  namespaced: true,
  state: {
    rentalProcessLoading: false,
    shownRentalProcess: null,
    rentalProcesses: [],
    locations: [],
  },
  mutations: {
    setLocations (state, data) {
      state.locations = data
    },
    setShownRentalProcess (state, data) {
      state.shownRentalProcess = data
    },
    setRentalProcesses (state, data) {
      state.rentalProcesses = data
    },
    changeRentalProcessLoading (state, data) {
      state.rentalProcessLoading = data
    },
    setContactPersons (state, data) {
      const { persons } = data
        state.shownRentalProcess.contact_persons = persons
    },
    setMFilesIds (state, data) {
      state.shownRentalProcess.mfiles_ids = data 
    },
    removeRentalProcess (state, data) {
      state.rentalProcesses = state.rentalProcesses.filter(obj => obj.id !== data)
    },
  },
  actions: {
    async openRentalProcess ({commit, dispatch, rootState, state}, params) {
      const { id } = params
      const time = rootState.app.currentDate
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)

      await dispatch('getLocations')

      const rentalProcess = state.rentalProcesses.find(process => process.id === id)
      if (rentalProcess) {
        commit('setShownRentalProcess', rentalProcess)
        return
      }

      const result = await api.leasing.rentalProcesses.get(time, id)
      if (result.length === 1) {
        commit('setShownRentalProcess', result[0])
      }
    },
    async updateRentalProcess ({commit, rootState, state}, params) {
      const {rentalProcess} = params
      commit('changeRentalProcessLoading', true)
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.put(rentalProcess)

      if (requestSucceeded(result)) {
        let exists = false

        const modifiedProcesses = state.rentalProcesses.map((process) => {
          if(process.id === result.id){
            exists = true
            return result
          }

          return process
        })
        
        if(!exists){
          modifiedProcesses.push(result)
        }

        commit('setShownRentalProcess', result)
        commit('setRentalProcesses', modifiedProcesses)
      }

      commit('changeRentalProcessLoading', false)
    },
    async getRentalProcess ({commit, rootState}, params) {
      const { id, siteId } = params

      commit('changeRentalProcessLoading', true)

      const time = rootState.app.currentDate
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.get(time, id, siteId)
      commit('setRentalProcesses', result)
      commit('changeRentalProcessLoading', false)
    },
    async getLocations ({commit, rootState}) {
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.location.get()
      commit('setLocations', result)
    },
    async deleteRentalProcess ({commit, rootState}, params) {
      const { id } = params
      commit('changeRentalProcessLoading', true)
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      await api.leasing.rentalProcesses.delete(id)
      commit('removeRentalProcess', id)
      commit('setShownRentalProcess', null)
      commit('changeRentalProcessLoading', false)
    },
    async patchRentalProcess ({commit, state, rootState}, params) {
      const {id, patch} = params
      commit('changeRentalProcessLoading', true)
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.patch(id, patch)

      if (requestSucceeded(result)) {
        const modifiedProcesses = state.rentalProcesses.map((process) => {

          if(process.id === result.id){
            return result
          }

          return process
        })

        commit('setShownRentalProcess', result)
        commit('setRentalProcesses', modifiedProcesses)
      }
      commit('changeRentalProcessLoading', false)
    },
    async updateContactPersons ({commit, state, rootState}, params) {
      const {id, persons} = params

      commit('changeRentalProcessLoading', true)
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.contactPerson.put(id, persons)

      if (requestSucceeded(result)) {
        commit('setContactPersons', {persons: result, rentalProcessId: id})
        const modifiedProcesses = patchData(state.rentalProcesses, id, 'contact_persons', result)
        commit('setRentalProcesses', modifiedProcesses)
      } 

      commit('changeRentalProcessLoading', false)
    },
    async deleteContactPersons ({commit, state, rootState}, params) {
      const {rentalProcessId} = params
      commit('changeRentalProcessLoading', true)
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.contactPerson.delete(rentalProcessId)
      if (requestSucceeded(result)) {
        commit('setContactPersons', {persons: [], rentalProcessId})
        const modifiedProcesses = patchData(state.rentalProcesses, rentalProcessId, 'contact_persons', [])
        commit('setRentalProcesses', modifiedProcesses)
      } 
      commit('changeRentalProcessLoading', false)
    },
    async updateMFilesIds ({commit, state, rootState}, params) {
      const {id, mFilesIds } = params

      commit('changeRentalProcessLoading', true)
      const api = new RambollFMAPI(rootState.app.apiEndpoint, rootState.app.apiToken)
      const result = await api.leasing.rentalProcesses.mFileIds.put(id, mFilesIds)

      if (requestSucceeded(result)) {
        commit('setMFilesIds', result)
        
        const modifiedProcesses = patchData(state.rentalProcesses, id, 'mfiles_ids', result)
        commit('setRentalProcesses', modifiedProcesses)
      } 

      commit('changeRentalProcessLoading', false)
    },
  },
  getters: {
    filteredRentalProcesses: (state, getters, rootState, rootGetters) => {
      let allProcesses = state.rentalProcesses

      // Show only user processes
      let filteredProcesses = allProcesses.filter(process => (process.owner === rootState.app.userInfo.id || process.secondaryOwner === rootState.app.userInfo.id))
      // Filter out processes with sites the current user doesn't have access to
      filteredProcesses = filteredProcesses.filter(process => {
        for (let i = 0; i < process.sites.length; i++) {
          if (rootState.app.sites.find(sitesItem => sitesItem.id_site == process.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })

      // Also check if site filters are active and filter out processes with no sites if this is the case
      if (rootGetters['app/siteFiltersByTypes']('all').length > 0) {
        filteredProcesses = filteredProcesses.filter(process => process.sites.length !== 0)
      }
      
      return filteredProcesses
    },
  },
}