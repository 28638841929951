import V3Wrapper from '../modules/v3viewer/v3wrapper.js'

class V3GraveyardWrapper extends V3Wrapper {
  graveCache = {}
  loadedModels = []

  modelOptions (backendModelId, coordinateSystem, flyTo = 0) {
    return {
      rotationZ: 0,
      lighting: { mode: 'unlit' },
      scaling: [1, 1, 1],
      modelCoordinateSystem: coordinateSystem,
      backendModelId: backendModelId,
      flyTo: flyTo,
      reduceZFight: true
    }
  }

  async loadGraveyardModel (model) {
    var that = this
    return await this.v3.addModel(
      model.projectId,
      model.url,
      this.modelOptions(model.backendModelId, model.coordinateSystem, model.flyTo),
      undefined,
      async loadedData => {
        if(!loadedData) {
          return
        }
        if (loadedData.error) {
          return loadedData.error
        }
        const loadedModel = loadedData[0]
        loadedModel.name = model.name
        that.loadedModels.push(loadedModel)
      }
    )
  }

  fillGraveCache (projectId) {
    this.v3.searchElements(projectId, 
      [['Properties.Pset_ElementInfo.Selectable.value', 'yes']],
      elements => {
        elements.forEach(element => {
          const layer = element.Properties.Pset_ElementInfo.Layer.value
          const splitLayer = layer.split('(£)')
          if (splitLayer.length > 1) {
            const id = Number(splitLayer[1])
            this.graveCache[id] = element.InternalId
          }
          this.graveCache[layer] = element.InternalId
        })
      }
    )
  }

  async setCategoryColors (graves, color) {
    for (const grave of graves) {
      if (this.graveCache.hasOwnProperty(grave)) {
        this.v3.setElementColor(this.graveCache[grave], color)
      }
    }
  }

  setElementColor (id, color) {
    this.v3.setElementColor(this.graveCache[id], color)
  }

  resetElementColor (id) {
    this.v3.resetElementColor(this.graveCache[id])
  }

  flyToAndSelectGrave (grave, distance, lengthInSeconds) {
    if (this.graveCache.hasOwnProperty(grave)) {
      this.v3.flyToElement(
        this.graveCache[grave],
        0,
        180,
        distance,
        lengthInSeconds
      )
      this.v3.selectElementByInternalId(this.graveCache[grave])
    }
  }

  /**
   * Rectangle selection only gives out internal ids, so with this we can fetch the proper
   * layer names that are used elsewhere.
   * @param {Array} internalIds 
   * @returns {Array} list of found layer names
   */
  getIdsFromCacheByInternalId (internalIds) {
    return internalIds.map(id => {
      return Object.keys(this.graveCache).find(key => {
        return this.graveCache[key].elementId === id.elementId &&
        this.graveCache[key].projectId === id.projectId
      })
    })
  }
}

export default V3GraveyardWrapper