import { i18n } from '../plugins/i18n.js'
export default {
  startHeaders () {
    return [
      { text: i18n.t('Site name'), value: 'siteName', dialog: 1 },
      { text: i18n.t('Building name'), value: 'buildingName', dialog: 1 },
      { text: i18n.t('Project number'), value: 'projectNumber', dialog: 1 },
      { text: i18n.t('Category'), value: 'taskCategory', dialog: 1 },
      { text: i18n.t('Code'), value: 'taskCode', dialog: 1 },
      { text: i18n.t('Task name'), value: 'taskName', dialog:1 },
      { text: i18n.t('Status'), value: 'implementationStatus', dialog: 3, format: 'Status' },
      { text: i18n.t('taskBasicInfo.taskSource'), value: 'taskSource', dialog: 3 },
      { text: i18n.t('Budget'), value: 'budget', format: 'Money', dialog: 2 },
      { text: i18n.t('Spent budget'), value: 'budgetSpent', format: 'Money', dialog: 2 },
      { text: i18n.t('taskEsg.currency'), value: 'currency', dialog: 3 },
      { text: i18n.t('taskBudgeting.isInOriginalBusinessPlan'), value: 'isInOriginalBusinessPlan', dialog: 3, format: 'Boolean' },
      { text: i18n.t('Implementation start'), value: 'implementationStart', dialog: 3, format: 'Time' },
      { text: i18n.t('Duration (mths)'), value: 'duration', dialog: 3 }
    ]
  },
  endHeaders () {
    return [
      { text: i18n.t('Responsibility'), value: 'responsibility', dialog: 4, editable: true, object: 'responsibilitys' },
      { text: i18n.t('Designer'), value: 'designer', dialog: 4, editable: true, object: 'designers' },
      { text: i18n.t('Project manager'), value: 'builder', dialog: 4, editable: true, object: 'builders' },
      { text: i18n.t('Supervisor'), value: 'supervisor', dialog: 4, editable: true, object: 'supervisors' },
      { text: i18n.t('Contractor'), value: 'contractor', dialog: 4, editable: true, object: 'contractors' },
      { text: i18n.t('Comment'), value: 'latestComment', dialog: 10},
      { text: i18n.t('Task instruction'), value: 'taskDescription', dialog: 1 },
      { text: i18n.t('Acknowledgement'), value: 'finished', dialog: 6 }
    ]
  },
  startFooters () {
    return [
      { headerValue: 'siteName' },
      { headerValue: 'buildingName' },
      { headerValue: 'projectNumber' },
      { headerValue: 'taskCategory' },
      { headerValue: 'taskCode' },
      { headerValue: 'taskName' },
      { headerValue: 'implementationStatus' },
      { headerValue: 'taskSource' },
      { value: 'budget', format: '' },
      { value: 'budgetSpent', format: '' },
      { headerValue: 'currency' },
      { headerValue: 'isInOriginalBusinessPlan' },
      { headerValue: 'implementationStart' },
      { headerValue: 'duration' },
    ]
  },
  endFooters () {
    return [
      { headerValue: 'responsibility', },
      { headerValue: 'designer',},
      { headerValue: 'builder', },
      { headerValue: 'supervisor', },
      { headerValue: 'contractor', },
      { headerValue: 'latestComment', },
      { headerValue: 'taskDescription', },
      { headerValue: 'finished', }
    ]
  },
  months () {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      ''
    ]
  },
  statusMappings () {
    return {
      0: 'New',
      1: 'Planned',
      2: 'Started',
      3: 'Acknowledge',
      4: 'Removed',
      5: 'In survey',
      6: 'Done',
      7: 'Not implemented',
    }
  },
  esgHeaders () {
    return [
      { text: i18n.t('taskEsg.esgCode'), value: 'esgCode', dialog: 3 },
      { text: i18n.t('taskEsg.production'), value: 'production', dialog: 3, format: 'Number' },
      { text: i18n.t('taskEsg.energySavings'), value: 'energySavings', dialog: 3, format: 'Number' },
      { text: i18n.t('taskEsg.co2Reduction'), value: 'eco2Reduction', dialog: 3, format: 'Number' },
      { text: i18n.t('taskEsg.monetarySavings'), value: 'monetarySavings', dialog: 3, format: 'Number' },
      { text: i18n.t('taskEsg.paybackPeriod'), value: 'paybackPeriod', dialog: 3, format: 'Number' },
      { text: i18n.t('taskEsg.yieldOnTotalInvestment'), value: 'investmentYield', dialog: 3, format: 'Number' },
      { text: i18n.t('taskEsg.energyEfficiency'), value: 'energyEfficiency', dialog: 3, format: 'Boolean' },
      { text: i18n.t('taskEsg.euTaxonomy'), value: 'euTaxonomy', dialog: 3, format: 'Booolean' },
      { text: i18n.t('taskEsg.sbti'), value: 'sbti', dialog: 3, format: 'Booolean' },
      { text: i18n.t('taskEsg.gbCertification'), value: 'gbCertification', dialog: 3, format: 'Booolean' },
      { text: i18n.t('taskEsg.gresb'), value: 'gresb', dialog: 3, format: 'Booolean' },
    ]
  },
  esgFooters () {
    return [
      { headerValue: 'esgCode' },
      { headerValue: 'production' },
      { headerValue: 'energySavings' },
      { headerValue: 'eco2Reduction' },
      { headerValue: 'monetarySavings' },
      { headerValue: 'paybackPeriod' },
      { headerValue: 'investmentYield' },
      { headerValue: 'energyEfficiency' },
      { headerValue: 'euTaxonomy' },
      { headerValue: 'sbti' },
      { headerValue: 'gbCertification' },
      { headerValue: 'gresb' },
    ]
  },
  booleanMappings () {
    return {
      true: 'Yes',
      false: 'No'
    }
  },
  taskEsgCodes () {
    return [
      { text: i18n.t('taskEsg.energy'), value: 1 },
      { text: i18n.t('taskEsg.climateRisk'), value: 2 },
      { text: i18n.t('taskEsg.refridgerants'), value: 3 },
      { text: i18n.t('taskEsg.water'), value: 4 },
      { text: i18n.t('taskEsg.waste'), value: 5 },
      { text: i18n.t('taskEsg.biodiversity'), value: 6 },
      { text: i18n.t('taskEsg.governance'), value: 7 },
      { text: i18n.t('taskEsg.indoorAirQuality'), value: 8 },
      { text: i18n.t('taskEsg.safety'), value: 9 },
      { text: i18n.t('taskEsg.propertyServicesForUsers'), value: 10 },
      { text: i18n.t('taskEsg.accessibility'), value: 11 },
      { text: i18n.t('taskEsg.other'), value: 12 },
    ]
  },
  taskSourceCodes () {
    return [
      { text: i18n.t('taskEsg.dueDiligence'), value: 1 },
      { text: i18n.t('taskEsg.externalConsultant'), value: 2 },
      { text: i18n.t('taskEsg.propertyManager'), value: 3 },
      { text: i18n.t('taskEsg.assetManager'), value: 4 },
    ]
  },
  taskCurrencies () {
    return [
      { text: i18n.t('currency.EUR'), value: 1 },
      { text: i18n.t('currency.DKK'), value: 2 },
      { text: i18n.t('currency.NOK'), value: 3 },
      { text: i18n.t('currency.SEK'), value: 4 },
    ]
  }
}
