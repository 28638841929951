<template>
  <div
    class="grave-card"
  >
    <v-expansion-panels
      v-if="!loading && mappedGraves.length"
      :value="panelValue"
      multiple
    >
      <v-expansion-panel
        v-for="grave in mappedGraves"
        :key="grave.id"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>gradient</v-icon>
            {{ grave.grave_identifier }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :metadata="graveMetadata"
            :value="getGraveData(grave)"
          />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="grave.deceased.length > 0">
          <div
            class="grave-card"
          >
            <v-expansion-panels
              :value="[]"
              multiple
            >
              <v-expansion-panel
                v-for="deceased in grave.deceased"
                :key="deceased.id"
              >
                <v-expansion-panel-header>{{ deceased.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MetadataForm
                    :metadata="deceasedMetadata"
                    :value="deceased"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="grave.activeServices.length > 0">
          <div
            class="grave-card"
          >
            <v-expansion-panels
              :value="[]"
              multiple
            >
              <v-expansion-panel
                v-for="service in grave.activeServices"
                :key="service.service_identifier + '-' + service.service_start_date"
              >
                <v-expansion-panel-header>{{ service.service_description }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MetadataForm
                    :value="service"
                    :metadata="serviceMetadata"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MetadataForm from '../MetadataForm.vue'
export default {
  components: {
    MetadataForm
  },
  props: {
    ids: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      graves: [],
      graveMetadata: {},
      deceasedMetadata: {},
      serviceMetadata: {}
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    panelValue () {
      if (this.graves.length === 1) {
        return [0]
      }
      return []
    },
    inspectionDate () {
      return new Date(this.currentDate)
    },
    mappedGraves () {
      return this.graves.map(grave => {
        return {
          ...grave,
          activeServices: grave.services.filter(service => {
            if (new Date(service.service_start_date) <= this.inspectionDate) {
              if (service.service_end_date === null || new Date(service.service_end_date) >= this.inspectionDate) {
                return true
              }
            }
            return false
          })
        }
      })
    }
  },
  watch: {
    ids: {
      handler: async function (val) {
        this.loading = true
        this.graves = await this.$rambollfmapi.graveyards.graves.list(val)
        this.loading = false
      }
    }
  },
  async mounted () {
    this.graveMetadata = await this.$rambollfmapi.graveyards.graves.metadata()
    this.deceasedMetadata = await this.$rambollfmapi.graveyards.deceased.metadata()
    this.serviceMetadata = await this.$rambollfmapi.graveyards.services.metadata()
  },
  methods: {
    getGraveData (grave) {
      const linkedPhotos = grave.linked_photos.map(photo => {
        return {
          value: photo.filename,
          type: 'Hyperlink',
          url: photo.publicId ? 'https://api3.rambollfm.fi/v3.1/photos/public/' + photo.publicId : null
        }
      })
      const linkedDocuments = grave.linked_documents.map((doc) => {
        return {
          value: doc.filename,
          type: 'DocHyperlink',
          docId: doc.idDocument
        }
      })
      grave.linked_photos = linkedPhotos
      grave.linked_documents = linkedDocuments
      return grave
    }
  }
}
</script>

<style scoped>
.v-expansion-panels {
  margin-bottom: 0.2em;
}
.grave-card {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
</style>