export function siteDevices () {
  return {
    id: 'site.devices',
    type: 'DataTable',
    data: {
      headers: [
        { text: 'Building', value: 'building_code' },
        { text: 'Floor', value: 'floor_name' },
        { text: 'Space', value: 'space_name' },
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type_string', format: 'Translation' },
        { text: 'Count', value: 'count' },
        { text: 'Responsibility', value: 'responsibility_string', format: 'Translation' },
        { text: 'Additional info', value: 'additional_info', hideOverflow: true },
      ],
      footers: [ {}, {}, {}, {}, {}, {}, {}, {} ],
      items: [],
      report: 'GetSiteDevices',
    },
    loading: true,
    maximized: [true]
  }
}