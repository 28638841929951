<template>
  <div>
    <v-dialog
      v-if="showConfirmationModal"
      v-model="showConfirmationModal"
      persistent
      eager
      width="60%"
    >
      <v-card>
        <ContractConfirmTerminationModal
          :contract-id="contractId"
          :termination-id="terminationId"
          :termination-date="new Date(terminationDate)"
          @close="closeConfirmationModal"
          @terminateContract="terminateContract"
          @cancelTerminateContract="cancelTerminateContract"
        />
      </v-card>
    </v-dialog>
    <BaseModal
      @cancel="close()"
    >
      <template #title>
        {{ $t('contractTermination.terminationOrRescission') }}
      </template>
      <template #content>
        <ValidationObserver ref="observer">
          <v-form>
            <h3>
              {{ $t('contractTermination.terminationOrRescissionInfo') }}
            </h3>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="formatDate(contractStartDate)"
                  :label="$t('new_handinvoice.contract_startdate')"
                  class="form-fields mt-4"
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="formatDate(contractEndDate)"
                  :label="$t('new_handinvoice.contract_enddate')"
                  class="form-fields mt-4"
                  disabled
                />
              </v-col>
            </v-row>
            <v-menu
              offset-y
              :close-on-content-click="false"
              :readonly="contract && contract.isDraft === false"
              min-width="0"
            >
              <template #activator="{ on }">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required|terminationDate: ${contractStartDate}, ${terminationDate}`"
                >
                  <v-text-field
                    v-model="terminationDateFormatted"
                    :label="$t('contract.contract end date')"
                    :aria-label="$t('contract.contract end date') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                    :hint="$t('date_format')"
                    :disabled="isDisabled"
                    class="form-fields mt-4"
                    prepend-icon="calendar_today"
                    required
                    :error-messages="errors[0]"
                    @blur="terminationDate = parseDate(terminationDateFormatted)"
                    v-on="on"
                  />
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="terminationDate"
                no-title
                scrollable
                first-day-of-week="1"
                :locale="$i18n.locale"
                :allowed-dates="allowedTerminationDates"
              />
            </v-menu>
            <v-row>
              <v-col
                cols="3"
                class="d-flex align-center"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-radio-group
                    v-model="terminationType"
                    mandatory
                    :disabled="isDisabled"
                    class="pl-2 font-weight-bold mt-4"
                    :error-messages="errors[0]"
                  >
                    <template #label>
                      <div>{{ $t('pdf.Contract') }}</div>
                    </template>
                    <v-radio
                      v-for="type in terminationTypes"
                      :key="type.id"
                      :label="$t(type.label)"
                      :value="type.id"
                      default
                      class="font-weight-regular px-2"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col
                cols="3"
                class="d-flex align-center"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-radio-group
                    v-model="terminatingParty"
                    :disabled="isDisabled"
                    mandatory
                    class="pl-2 font-weight-bold mt-4"
                    :error-messages="errors[0]"
                  >
                    <template #label>
                      <div>{{ $t('contractTermination.terminatingParty') }}</div>
                    </template>
                    <v-radio
                      default
                      :label="$t('Landlord')"
                      :value="landlordId"
                      class="font-weight-regular px-2"
                    />
                    <v-radio
                      default
                      :label="$t('Tenant')"
                      :value="tenantId"
                      class="font-weight-regular px-2"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="description"
                >
                  <v-textarea
                    v-model="terminationDescription"
                    class="form-fields mt-8"
                    counter="2000"
                    :label="$t('Reasoning')"
                    :disabled="isDisabled"
                    :error-messages="errors[0]"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <ContractSignatories
              :landlord-id="landlordId"
              :tenant-id="tenantId"
              :other-tenant-id="otherTenantId"
              :id-landlord-contact-person="idLandlordContactPerson"
              :landlord-signatory="landlordSignatory"
              :tenant-signatories="tenantSignatories"
              :tenant-type="tenantType"
              :first-tab-tenant-type-change="firstTabTenantTypeChange"
              :all-parties="allParties"
              :settings="settings"
              :is-disabled="isDisabled || (settings.length > 0 && !settings.canUseElectronicSigning)"
              :is-out-rent="isOutRent"
              :can-use-electronic-signing="settings.canUseElectronicSigning"
              :request-state="requestState"
              :is-electronic-signing="isElectronicSigning"
              @updated="onSignatoriesUpdated"
            />

            <h3 class="mt-4">
              {{ $t('create_rent_contract.download_terminationDocumentTitle') }}
            </h3>

            <CdsButton
              type="primary"
              icon-left="download"
              @click="$emit('terminationPdf', { terminationDateFormatted, terminationDescription, type: 'preview' })"
            >
              {{ $t('create_rent_contract.download_terminationDocument') }}
            </CdsButton>
          </v-form>
        </ValidationObserver>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-col
          class="d-flex justify-space-between"
        >
          <CdsButton
            v-if="isDisabled && electricalSigningRequestGuidNotEmpty"
            type="danger"
            @click="showConfirmationModal = true"
          >
            {{ $t('contractTermination.cancelTermination') }}
          </CdsButton>
          <CdsButton
            type="tertiary"
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </CdsButton>
          <CdsButton
            v-if="!isDisabled"
            type="accent"
            @click="openConfirmationModal()"
          >
            {{ $t('Continue') }}
          </CdsButton>
        </v-col>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { i18n } from '../../../../plugins/i18n.js'
import BaseModal from '../../../general/BaseModal.vue'
import ContractConfirmTerminationModal from './ContractConfirmTerminationModal.vue'
import ContractSignatories from './ContractSignatories.vue'
import CdsButton from '../../../../components/CircleDesignSystem/CdsButton.vue'

extend('terminationDate', {
  message: i18n.t("create_rent_contract.invalid_start_date"),
  params: ['contractStartDate', 'terminationDate'],
  validate: (_, { contractStartDate, terminationDate }) => {
    const startDate = new Date(contractStartDate)
    startDate.setHours(0, 0, 0, 0)
    const newDate = new Date(terminationDate)
    newDate.setHours(0, 0, 0, 0)
    return newDate > startDate
  }
});

extend('description', {
  params: ['contractStartDate', 'terminationDate'],
  validate: (value) => {
    return !value || value.length <= 2000
  }
});

export default {
  name: "ContractTerminationModal",
  components: {
    BaseModal,
    ValidationObserver,
    ValidationProvider,
    ContractConfirmTerminationModal,
    CdsButton,
    ContractSignatories,
  },
  props: {
    contract: {
      type: Object,
      default: () => undefined
    },
    termination: {
      type: Object,
      default: () => undefined
    },
    contractStartDate: {
      type: Date,
      default: () => undefined
    },
    contractEndDate: {
      type: Date,
      default: () => undefined
    },
    contractId: {
      type: Number,
      default: undefined
    },
    allParties: {
      type: Array,
      default: () => []
    },
    settings: {
      type: Object,
      default: () => undefined
    },
    idLandlordContactPerson: {
      type: Number,
      default: undefined
    },
    otherTenantId: {
      type: Number,
      default: undefined
    },
    tenantType: {
      type: String,
      default: undefined
    },
    firstTabTenantTypeChange: {
      type: Boolean,
      default: false
    },
    forceUpdateTerminationData: {
      type: Boolean,
      default: false
    },
    isOutRent: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'terminationPdf', 'getUpdatedData'],
  data () {
    return {
      signatureMethod: 'eSignature',
      isElectronicSigning: true,
      landlordSignatory: undefined,
      tenantSignatories: [],
      terminationDate: undefined,
      terminationType: undefined,
      terminationTypeId: undefined,
      terminatingParty: undefined,
      terminatingPartyId: undefined,
      terminationDescription: undefined,
      showConfirmationModal: false,
      landlordId: undefined,
      tenantId: undefined,
      terminationTypes: [],
      terminationDateFormatted: undefined,
      historyEvents : {
        contractTerminated: 7,
        terminationCanceled: 8,
      },
      requestState: undefined,
      electricalSigningRequestGuidNotEmpty: false
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel']),
    terminationData () {
      return {
        idContract: this.contractId,
        endDate: this.terminationDate,
        defTerminationType: this.terminationType,
        terminatingParty: this.terminatingParty,
        Description: this.terminationDescription,
        landlordSignatory: this.landlordSignatory,
        tenantSignatories: this.tenantSignatories,
        isElectronicSigning: this.isElectronicSigning,
      }
    },
    isDisabled () {
      return this.termination?.id && this.termination?.id > 0
    },
    terminationId () {
      return this.termination?.id
    },
  },
  watch: {
    terminationDate () {
      this.terminationDateFormatted = this.formatDate(this.terminationDate)
    },
    termination () {
      this.updateTerminationData()
    },
    forceUpdateTerminationData () {
      this.updateTerminationData()
    }
  },
  mounted () {
    this.landlordId = this.contract.landlordId
    this.tenantId = this.contract.tenantId
    this.terminationTypes = this.definitionsByGroupLabel('contract.terminationType').sort((a, b) => a.id - b.id)

    this.updateTerminationData()
  },
  methods: {
    updateTerminationData () {
      if (this.termination) {
        let signingInformation = this.termination.signingInformation
        let landlordSigningInformations = []
        let tenantSigningInformations = []
        if (signingInformation !== null) {
          landlordSigningInformations = signingInformation.filter(s => s.isLandlord === true)
          tenantSigningInformations = signingInformation.filter(s => s.isLandlord === false)
        }
        this.terminationDate = this.termination.endDate
        this.terminationType = this.termination.defTerminationType
        this.terminationTypeId = this.termination.defTerminationTypeId
        this.terminatingParty = this.termination.terminatingParty
        this.terminatingPartyId = this.termination.terminatingPartyId
        this.terminationDescription = this.termination.description
        this.requestState = this.termination.terminationState
        this.isElectronicSigning = this.termination.isElectronicSigning
        this.electricalSigningRequestGuidNotEmpty =
            this.termination.isElectronicSigning &&
            this.termination.terminationRequestGuid !== '00000000-0000-0000-0000-000000000000' &&
            this.termination.terminationRequestGuid !== '' &&
            this.termination.terminationRequestGuid !== null &&
            this.termination.terminationRequestGuid !== undefined

        this.landlordSignatory = landlordSigningInformations.length > 0 ?
          landlordSigningInformations.map(s => ({
              'signed': s.isSigned,
              'signatoryDate': s.signedDate,
              'id': s.signerId
             })
          )[0] : undefined

        this.tenantSignatories = tenantSigningInformations.length > 0 ?
          tenantSigningInformations.map(s => (
             {
              'signed': s.isSigned,
              'signatoryDate': s.signedDate,
              'id': s.signerId
             }
            )
          ) : undefined
      }
    },
    async cancelTerminateContract () {
      await this.$rambollfmapi.contracts.contractTermination.cancelTermination(this.contractId)
      await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.terminationCanceled, [this.contractId], null)
      this.$emit('getUpdatedData')
      this.closeConfirmationModal()
      this.close()
    },
    close () {
      this.$emit('close')
    },
    closeConfirmationModal () {
      this.showConfirmationModal = false
    },
    async openConfirmationModal () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.showConfirmationModal = true
      }
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    allowedTerminationDates (date) {
      const startDate = new Date(this.contractStartDate)
      startDate.setHours(0, 0, 0, 0)
      const newDate = new Date(date)
      newDate.setHours(0, 0, 0, 0)
      return newDate > startDate
    },
    onSignatoriesUpdated (data) {
      this.landlordSignatory = data.landlordSignatory
      this.tenantSignatories = data.tenantSignatories
      this.signedContract = data.signedContract
      this.isElectronicSigning = data.isElectronicSigning
      this.tenantsHasEmails = data.tenantsHasEmails
      this.landlordHasEmail = data.landlordHasEmail
    },
    async terminateContract () {
      this.$emit(
        'terminateContract',
        {
          terminationData: this.terminationData,
          terminationDateFormatted: this.terminationDateFormatted,
          terminationDescription: this.terminationDescription,
        }
      )
      this.closeConfirmationModal()
      this.close()
    },
  }
}
</script>

<style scoped>
</style>