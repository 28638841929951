<template>
  <div style="position: relative">
    <v-tooltip
      v-if="currentInfo"
      top
      max-width="400"
    >
      <template #activator="{ on }">
        <v-btn
          absolute
          left
          icon
          v-on="on"
        >
          <v-icon>
            info
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t(currentInfo) }}</span>
    </v-tooltip>
    <v-menu
      v-if="data.itemSets"
      left
    >
      <template #activator="{ on }">
        <v-btn
          outlined
          text
          small
          absolute
          class="no-transform"
          v-on="on"
        >
          <span
            v-for="(value, index) in data.itemSets"
            :key="value.text"
            :class="{ highlighted: value === currentItemSet}"
          >
            {{ $t(value.text) }}
            <span v-if="index !== data.itemSets.length - 1">/</span>
          </span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="itemSet in data.itemSets"
          :key="itemSet.value"
          :class="{ 'active': currentItemSet ? currentItemSet.value === itemSet.value : false}"
          @click="selectItemSet(itemSet)"
        >
          <v-list-item-title>{{ $t(itemSet.subtitle ? itemSet.subtitle : itemSet.text) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      v-if="data.sets"
      left
    >
      <template #activator="{ on }">
        <v-btn
          outlined
          small
          absolute
          right
          icon
          style="background: rgba(255, 255, 255, 0.8) !important"
          v-on="on"
        >
          <v-icon>menu</v-icon>
          <span class="d-sr-only">{{ $t('Menu') }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="set in sets"
          :key="set.title"
          :class="{ 'active': currentSet === set}"
          @click="selectSet(set)"
        >
          <v-list-item-title>{{ set.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div ref="graph">
      <svg
        v-if="radians !== null && GraphData !== null && data.items.length > 0"
        class="line"
      >
        <line
          :x1="getLinePosition.x1"
          :y1="getLinePosition.y1"
          :x2="getLinePosition.x2"
          :y2="getLinePosition.y2"
          :stroke="data.lineColor"
          stroke-width="5"
        />
      </svg>
      <PieChart
        v-if="GraphData !== null && (data.items.length > 0 || data.purposeZones || typeof data === 'object')"
        :key="updateChart"
        :data="addItemSetToData(GraphData)"
        :options="pieOptions"
        class="graph"
        style="min-height: 400px"
      />
      <div
        v-else
        class="alternative-text"
      >
        {{ data.alternativeText }}
      </div>
      <div 
        v-if="data.summaryText"
        class="pa-0 text-center"
        style="height: 35px"
      >
        <span class="text-subtitle-1 font-weight-bold">
          {{ data.summaryText + ': ' + getSummaryValue() }}
        </span>
      </div>
    </div>
    <DetailsModal
      :is-shown="detailsModalOpen"
      :data="modalData"
      @onClose="closeDetailsModal"
    />
  </div>
</template>
<script>
import helpers from '../../helpers'
import PieChart from '../PieChart.vue'
import DetailsModal   from './../DetailsModal.vue'
export default {
  emits: ['change-pie-graph-item-set'],
  components: {
    PieChart: PieChart,
    DetailsModal
  },
  props: {
    data: {
      type: [Array, Object],
      default: null
    },
    defaultItemSet: { type: Object, default: null}
  },
  data () {
    return {
      componentWidth: 0,
      componentHeight: 0,
      radians: null,
      currentSet: null,
      updateChart: 0,
      currentItemSet: null,
      detailsModalOpen: false,
      modalData: null
    }
  },
  computed: {
    currentInfo () {

      if(this.currentSet && this.currentSet.info){
        return this.currentSet.info
      }

      if(this.data && this.data.info){
        return this.data.info
      }

      return null

    },
    sets: function () {
      // Check for sets
      if (this.data.sets.length === 0) {
        return []
      }
      return this.data.sets.filter(set => {
        const param = set.parameters.length > 0 ? set.parameters[0] : null

        // count is an exception as it does not require parameters
        if (param === null && set.method === 'count') {
          return true
        }

        const data = this.data.items.length > 0 ? this.data.items[0] : null

        if (data === null || typeof data[param] === 'undefined') {
          return false
        }
        return true
      })
    },
    objects: function () {
      if (Array.isArray(this.data.items)) {
        if (this.currentSet !== null) {
          // If set is defined we do the following:
          // 1. First, we group the data by using groupBy field
          // 2. Now the grouped data contains an object in which keys are values found using the groupBy field
          // 3. For each group, data is aggregated using the provided 'method'. It can be 'count' or 'sum' at this point
          // 3.1. Aggregation uses the 'method' with 'parameters'. The values are extracted using the parameter fields into tuples
          // 3.2. The current method 'count' is basically the length of the parameter array
          // 3.3. The current method 'sum' is just summing the array values together. Each tuple contains only one value.
          // 4. Now we have an object where each grouped value has the result and can be fed to the graph

          const { filterFunction, filterEmpty } = this.currentSet

          let filteredItems = this.data.items

          if(filterFunction){
            filteredItems = filteredItems.filter((item) => filterFunction(item))
          }


          const groupedData = filteredItems.reduce((acc, cur) => {
            // group
            const key = cur[this.currentSet.groupBy]
            if (typeof key === 'undefined' || key === null) {
              return acc
            }

            if (typeof acc[key] === 'undefined') {
              acc[key] = []
            }
            acc[key].push(cur)

            return acc
          }, {})

          const items = Object.keys(groupedData).reduce((acc, key) => {
            const dataSet = groupedData[key]
            const paramValues = dataSet.map(d => {
              const values = []
              this.currentSet.parameters.forEach(param => {
                values.push(d[param])
              })
              return values
            })
            const method = this.currentSet.method

            if (method === 'count') {
              acc[key] = paramValues.length
            } else if (method === 'sum') {
              acc[key] = paramValues.reduce((acc, cur) => acc + cur[0], 0)
            }

            return acc
          }, {})

          if (filterEmpty) {
            return Object.entries(items).reduce((acc, [key, value]) => {
              if (value) {
                acc[key] = value
              }
              return acc
            }, {})
          }

          return items
        } 
        else if (this.data.purposeZones) {
          
          const dataItems = Object.values(this.data.items)
          let totalPercentage = 0 // variable to store the total percentage amount

          const grouped = dataItems.reduce((acc, cur) => {
            const key = cur['purpose_zone_name']
            if (typeof key === 'undefined' || key === null) {
              return acc
            }

            if (typeof acc[key] === 'undefined') {
              acc[key] = []
            }
            acc[key].push(cur)
            totalPercentage += cur.purpose_zone_percentage

            return acc
          }, {})

          const division = totalPercentage / 100 // Divide the totalPercentage with 100 in order to get the value to divide the summed percentages with

          const items = Object.keys(grouped).reduce((acc,key) => {
            const set = grouped[key]
            const vals = set.map(s => {
              const temp = []
              temp.push(s.purpose_zone_percentage)
              return temp
            })
            
            acc[key] = vals.reduce((acc,cur) => acc + cur[0], 0) / division // sum percentages and divide them by division variable

            return acc
          }, {})
          return items 
        }
        else 
        {
          // No sets defined. Using the default approach (counting)
          return this.data.items.reduce((acc, cur) => {
            const dataField = this.data.field
            if (cur[dataField] === null) {
              return acc
            }
            if (typeof acc[cur[dataField]] === 'undefined') {
              acc[cur[dataField]] = 0
            }
            acc[cur[dataField]] += 1

            return acc
          }, {})
        }
      } else if (this.data.items === null || this.data.items === undefined) {
        return []
      } else {
        return this.data.items
      }
    },
    GraphData: function () {
      const objectKeysArray = [
        ...Object.keys(this.objects).map(key => {
          return this.$t(key)
        })
      ]
      return {
        labels: objectKeysArray,
        title: this.currentSet ? this.currentSet.title : null,
        datasets: [
          {
            label: this.data.label,
            backgroundColor: this.data.colors ? this.getCustomColors() : this.getThemeColorArray(objectKeysArray.length),
            hoverBackgroundColor: this.data.colors ? this.getCustomColors() : this.getThemeColorArray(objectKeysArray.length),
            borderColor: 'white',
            data: [
              ...Object.keys(this.objects).map(
                key => this.objects[key] /* .count */
              )
            ]
          }
        ]
      }
    },
    pieOptions () {
      const unitArea = this.$t("Unit area")
      const zonesActive = this.data.purposeZones
      const showAreaTooltip = this.data.showAreaTooltip
      const showPercentages = this.data.showPercentages
      const showDatasetNameAsTitle = this.data.showDatasetNamesAsTitle
      const showContractAreaTooltip = this.data.showContractAreaTooltip
      const format = this.currentSet?.format ?? null
      // For creating custom labels, label generation function is defined in widget's data-object
      const customLabels = this.data.customLabelFunction != null

      const additionalData = []

      if (zonesActive) {
        const dataItems = Object.values(this.data.items)
        const grouped = dataItems.reduce((acc, cur) => {
            const key = cur['purpose_zone_name']
            if (typeof key === 'undefined' || key === null) {
              return acc
            }

            if (typeof acc[key] === 'undefined') {
              acc[key] = []
            }
            acc[key].push(cur)

            return acc
        }, {})

        Object.keys(grouped).forEach(key => {
          const set = grouped[key]
          const vals = set.map(s => {
            const temp = []
            temp.push(s.purpose_zone_area)
            return temp
          })
          const value = vals.reduce((acc,cur) => acc + cur[0], 0)
          additionalData.push(value)
        })
      }
      

      return {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 1,
        cutoutPercentage: this.data.fullPie ? 0 : 40,
        title: {
          display: showDatasetNameAsTitle,
        },
        legend: {
          labels: {
            fontColor: 'black',
            fontSize: 12,
            boxWidth: 20,
            ...(customLabels? {generateLabels: this.data.customLabelFunction(format)} : {})
          },
          align: 'start',
          position: 'bottom'
        },
        layout: {
          padding: {
            top: 20,
            left: 0,
            right: 0,
            bottom: this.data.summaryText ? 0 : 20
          }
        },
        tooltips: {
          callbacks: {
            afterLabel: function (tooltipItem, data) {
              if (zonesActive) {
                return unitArea + ": " + 
                helpers.humanize.thousand_separator(
                  additionalData[tooltipItem.index], 1
                ) + ' m\u00B2'
              }
              else if (showPercentages) {
                let total = data.datasets[tooltipItem.datasetIndex].data.reduce((curr, acc) => acc + curr, 0)
                let rounded = ((data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / total) * 100).toFixed(2)
                return rounded + ' %'

              }
              else if (showContractAreaTooltip) {
                return data.title + ': ' +
                helpers.humanize.thousand_separator(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
              }

              return null
            },
            // Only round the decimals if the data has any decimals
            label: function (tooltipItem, data) {
              if(data.itemSet?.format)
              {
                return data.labels[tooltipItem.index] + ": " +  
                helpers.humanize.thousand_separator(data.datasets[tooltipItem.datasetIndex]
                .data[tooltipItem.index],1) + " " + helpers.format.unitByFormat(data.itemSet.format)
              }
              const percentage = zonesActive ? " %" : ""
              const areaTooltip = showAreaTooltip ? " m\u00B2" : ""
              if(showContractAreaTooltip) {
                return data.labels[tooltipItem.index]
              } else if (
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] %
                  1 !==
                0
              ) {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  helpers.humanize.thousand_separator(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ],1
                  ) + percentage + areaTooltip 
                )
              } else {
                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  helpers.humanize.thousand_separator(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ]
                  ) + percentage + areaTooltip
                )
              }
            }
          }
        },
        onClick: this.graphClick,
        onHover: this.graphHover
      }
    },
    getLinePosition : function () 
    {
      let lineLength = 120
      let yOffset = -10
      if(this.componentWidth === 0 && this.componentHeight === 0)
      {
        return { x1: 0, y1: 0, x2: 0, y2: 0 }
      }

      //Adding the -10 to the y-coordinate positions the line closer to middle
      //The extra vertical length is caused by the color headers below the graph
      //ref to the actual height would require separating the graph from the headers
      return  { 
        x1: this.componentWidth / 2, 
        y1: this.componentHeight / 2 + yOffset, 
        x2: this.componentWidth / 2 + lineLength * Math.sin(this.radians),
        y2: this.componentHeight / 2 + yOffset + lineLength * Math.cos(this.radians)
      }
    }
  },
  watch: {
    GraphData: {
      // this watch ensures with the key assigned to PieChart that it'll update all the way to the end with data updates
      deep: true,
      handler () {
        this.updateChart++
      }
    }
  },
  mounted: function () {
    if(this.data.lineRotationInRadians !== undefined)
    {
      this.radians = this.data.lineRotationInRadians
    }
    //This is done with 1ms timeout so that the graph ref is accessible
    //Otherwise the graph middle line will not render when changing tabs
    setTimeout(() => {
      this.componentWidth = this.$refs.graph.clientWidth
      this.componentHeight = this.$refs.graph.clientHeight
    }, 1)

    window.addEventListener('resize', this.handleResize)
    if (typeof this.data.sets !== 'undefined') {
      this.selectSet(this.data.sets[0])
    }
    if (this.data.itemSets?.length > 0) {
      if(this.defaultItemSet) {
        this.currentItemSet = this.data.itemSets.find(x => x.value === this.defaultItemSet.value)
      }
      if(!this.currentItemSet) {
        this.currentItemSet = this.data.itemSets[0]
      }
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    selectSet (set) {
      this.currentSet = set
    },
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${177 + 27 * index}, 82%, 72%)`
    },
    getCustomColors () {
      let colors = []
      Object.keys(this.objects).forEach(key => {
        colors.push(this.data.colors[key])
      })
      return colors;
    },
    handleResize () {
      this.componentWidth = this.$refs.graph.clientWidth
      this.componentHeight = this.$refs.graph.clientHeight
    },
    selectItemSet (itemSet) {
      this.currentItemSet = itemSet
      this.$emit('change-pie-graph-item-set', {itemSet, id: this.widgetId})
    },
    addItemSetToData (data) {
      if(this.currentItemSet) {
      data.itemSet = this.currentItemSet
      }
      return data
    },
    closeDetailsModal () {
      this.detailsModalOpen = false
      this.modalData = null
    },
    graphClick (event, chartElement) {
      // If details headers are given, set modal data and open it
      // Also check if click happens in a slice and not elsewhere in the graph
      if ((this.data.detailsHeaders || this.currentSet.detailsHeaders) && chartElement.length > 0) {
        const index = chartElement[0]._index
        const label = chartElement[0]._chart.data.labels[index]
        const key = this.currentSet.groupBy
        const items = this.data.items.filter(item => this.$t(item[key]) === label)

        let headers = []
        let footers = []
        let rowsPerPage = this.data.detailsRowsPerPage ?? 5
        // If headers are a simple array, use headers and footers as is
        if (this.data.detailsHeaders) {
          headers = this.data.detailsHeaders
          footers = this.data.detailsFooters
        }
        // If headers are not given, check for headers in sets
        else {
          headers = this.currentSet.detailsHeaders ?? []
          footers = this.currentSet.detailsFooters ?? []
        }

        this.modalData = { 
          title: label,
          items,
          headers,
          footers,
          rowsPerPage
        }
        this.detailsModalOpen = true
      }
    },
    graphHover (event, chartElement) {
      if (this.data.detailsHeaders || this.currentSet.detailsHeaders) {
        event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      }
    },
    getSummaryValue () {
      if (this.data.summaryText && this.currentSet) {
        let summaryValue = 0
        const summaryItem = this.data.items.find(item => item.id === 'summary')
        if (summaryItem) {
          this.currentSet.parameters.forEach(p => {
            summaryValue += summaryItem[p]
          })
          return helpers.format.formatData(summaryValue, summaryItem.format)
        }
      }
      return null
    }
  }
}
</script>
<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.line {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.alternative-text {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 400px;
}
.highlighted {
  color: var(--c-color-accent) !important;
}
.no-transform {
  text-transform: none;
}
</style>