<template>
  <v-tooltip
    v-if="status.tooltip"
    top
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :color="status.color"
        v-bind="attrs"
        icon
        class="pa-0 ma-0 mr-2"
        :aria-label="status.tooltip"
        v-on="on"
      >
        <v-icon :color="status.color">
          {{ status.icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ status.tooltip }}</span>
  </v-tooltip>
  <v-icon
    v-else
    :color="status.color"
  >
    {{ status.icon }}
  </v-icon>
</template>
<script>
import moment from 'moment'
import { i18n } from '../plugins/i18n.js'
import { mapGetters } from 'vuex'
export default {
  name: 'StatusIcon',
  props: {
    data: {
      default: null
    },
    field: {
      type: Object,
      default: null
    },
    hasDwgs: {
      type: Boolean,
      default: false
    },
    managedByIntegration: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('app', ['factaDisabled']),
    status: function () {
      moment.locale('fi')
      const targetTime = moment(this.data)
      const fieldTime = moment(this.field.data)

      const fifteenMonthsAgo = moment()
        .subtract(15, 'months')

      const twelveMonthsAgo = moment()
        .subtract(12, 'months')

      const nineMonthsAgo = moment()
        .subtract(9, 'months')

      const sixMonthsAgo = moment()
        .subtract(6, 'months')

      const threeMonthsAgo = moment()
        .subtract(3, 'months')

      /* const nineMonthsToGo = moment()
        .add(9, 'months')

      const threeMonthsToGo = moment()
        .add(3, 'months') */

      const sixMonthsToGo = moment()
        .add(6, 'months')

      const twelveMonthsToGo = moment()
        .add(12, 'months')

      const fifteenMonthsToGo = moment()
        .add(15, 'months')

      const twentyFourMonthsToGo = moment()
        .add(24, 'months')

      /* console.log(
                      '9monthsToGo:',
                      nineMonthsToGo,
                      '6monthsTogo:',
                      sixMonthsToGo,
                      '3monthstoGo',
                      threeMonthsToGo,
                      '9monthsAgo:',
                      nineMonthsAgo,
                      '12monthsAgo',
                      twelveMonthsAgo,
                      '24monthsToGo',
                      twentyFourMonthsToGo
                    ) */

      /* this.$log.debug('data:', this.data, 'field: ', this.field) */
      switch (this.field.name) {
        case 'unit_rental_status':
          if (this.data === this.$t('rentable')) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (this.data === this.$t('rentable.koy')) {
            return { color: 'warning', icon: 'error' }
          } else if (
            this.data === this.$t('notrentable.koy.public') ||
                                this.data === this.$t('notrentable.koy.technical')
          ) {
            return {}
          } else {
            return { color: 'error', icon: 'cancel' }
          }
        case 'vat_status':
          if (this.data === this.$t('Undefined')) {
            return { color: 'error', icon: 'cancel' }
          } else if (this.data === this.$t('Not VAT responsible')) {
            return { color: 'warning', icon: 'error' }
          } else if (this.data === this.$t('Yes, VAT responsible')) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else {
            return {}
          }
        case 'tenant':
          if (this.data) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else {
            return { color: 'error', icon: 'cancel' }
          }
        case 'contract_number':
          if (this.data) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else {
            return { color: 'error', icon: 'cancel' }
          }
        case 'rental_status': // TODO definitions for icon
          if (
            this.data ===
                                this.$t('Rented') +
                                ' (' +
                                this.$t('Valid over 12 months') +
                                ')' ||
                                this.data ===
                                this.$t('Rented') + ' (' + this.$t('Valid over 9 months') + ')'
          ) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (
            this.data ===
                                this.$t('Rented') + ' (' + this.$t('Valid over 6 months') + ')' ||
                                this.data ===
                                this.$t('Rented') + ' (' + this.$t('Valid over 3 months') + ')'
          ) {
            return {
              color: 'warning',
              icon: 'error'
            }
          } else if (
            this.data ===
                                this.$t('Rented') + ' (' + this.$t('Valid less than 3 months') + ')'
          ) {
            return { color: 'error', icon: 'cancel' }
          } else {
            return {}
          }
        case 'rental_process_status': // TODO definitions for icon
          return {}
          /* case 'contract_end_date':
                          if (
                            targetTime.isSameOrAfter(
                              moment(nineMonthsToGo, 'DD.MM.YYYY'),
                              'day'
                            )
                          ) {
                            return {
                              color: 'success',
                              icon: 'check_circle'
                            }
                          } else if (
                            targetTime.isBetween(
                              moment(threeMonthsToGo, 'DD.MM.YYYY'),
                              moment(nineMonthsToGo, 'DD.MM.YYYY'),
                              'day'
                            )
                          ) {
                            return { color: 'warning', icon: 'error' }
                          } else {
                            return { color: 'error', icon: 'cancel' }
                          } */
        case 'market_rent_updated_date':
          if (
            targetTime.isSameOrAfter(moment(nineMonthsAgo, 'DD.MM.YYYY'), 'day')
          ) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (
            targetTime.isBetween(
              moment(twelveMonthsAgo, 'DD.MM.YYYY'),
              moment(nineMonthsAgo, 'DD.MM.YYYY'),
              'day'
            )
          ) {
            return { color: 'warning', icon: 'error' }
          } else {
            return { color: 'error', icon: 'cancel' }
          }
        case 'maintenance_charges_estimation_updated_date':
          if (
            targetTime.isSameOrAfter(moment(nineMonthsAgo, 'DD.MM.YYYY'), 'day')
          ) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (
            targetTime.isBetween(
              moment(twelveMonthsAgo, 'DD.MM.YYYY'),
              moment(nineMonthsAgo, 'DD.MM.YYYY'),
              'day'
            )
          ) {
            return { color: 'warning', icon: 'error' }
          } else {
            return { color: 'error', icon: 'cancel' }
          }
        case 'online_marketing': // TODO definitions for icon
          if (this.data === this.$t('Allowed')) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (
            this.data === this.$t('Disallowed') ||
                                this.data === this.$t('Disallowed for fixed period')
          ) {
            return { color: 'error', icon: 'cancel' }
          } else {
            return { color: 'warning', icon: 'error' }
          }
        case 'online_publishing_date': // TODO definitions for icon
          return {}
        case 'unit_version_end_date':
          if (
            targetTime.isSameOrAfter(
              moment(twentyFourMonthsToGo, 'DD.MM.YYYY'),
              'day'
            )
          ) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (
            targetTime.isBetween(
              moment(sixMonthsToGo, 'DD.MM.YYYY'),
              moment(twentyFourMonthsToGo, 'DD.MM.YYYY'),
              'day'
            )
          ) {
            return { color: 'warning', icon: 'error' }
          } else if (
            targetTime.isBefore(moment(sixMonthsToGo, 'DD.MM.YYYY'), 'day')
          ) {
            return { color: 'error', icon: 'cancel' }
          } else {
            return {}
          }
        case 'net_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'net_floor_area_from_space':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'net_room_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'other_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'common_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'rentable_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'rented_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'free_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'gross_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'building_gross_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'building_net_room_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'building_net_floor_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'total_floor_area':
          if (!this.hasDwgs || (!this.factaDisabled && this.managedByIntegration)) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'official_gross_area':
        case 'official_leasable_area':
        case 'official_floor_area':
          return {
            color: 'error',
            icon: 'cancel'
          }
        case 'estate_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
          case 'right_to_build':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
          case 'used_right_to_build':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
          case 'buildingRightLeft':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
          case 'pending_right_to_build':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'free_rentable_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'space_area':
          if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'building_net_floor_area_from_space':
         if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'empty_floor_area':
         if (!this.hasDwgs) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.hasDwgs) {
            return { color: 'success', icon: 'check_circle' }
          } else {
            return {}
          }
        case 'contract_attachment_printing_time':
          if (this.data && targetTime.isBetween(
            threeMonthsAgo, 
            moment())) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (this.data && targetTime.isBetween(sixMonthsAgo, threeMonthsAgo)) {
            return {
              color: 'warning',
              icon: 'error'
            }
          } else {
            return {
              color: 'error',
              icon: 'cancel'
            }
          }
        case 'general_rating':
          if ((this.field.data > 0 && this.field.data <= 69) || (this.field.data === null)) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.field.data >= 70 && this.field.data <= 84) {
            return {
              color: 'warning',
              icon: 'error'
            }
          } else if (this.field.data >= 85 && this.field.data <= 100) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else {
            return {}
          }
        case 'general_rating_site': case 'general': case 'audit_tenant': case 'outside_areas': case 'facade': case 'roof': case 'inner_spaces': case 'other_spaces':
        case 'lvi_technics': case 'electric_transport': case 'scheduled_maintenance': case 'estate_system': case 'loading_areas':
          if ((this.field.data >= 0 && this.field.data <= 69) || (this.field.data === null)) {
            return {
              color: 'error',
              icon: 'cancel'
            }
          } else if (this.field.data >= 70 && this.field.data <= 84) {
            return {
              color: 'warning',
              icon: 'error'
            }
          } else if (this.field.data >= 85 && this.field.data <= 100) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else {
            return {}
        }
        case 'report_date':
          if (
            fieldTime.isSameOrAfter(moment(nineMonthsAgo, 'DD.MM.YYYY'), 'day')
          ) {
            return {
              color: 'success',
              icon: 'check_circle'
            }
          } else if (
            fieldTime.isBefore(moment(nineMonthsAgo, 'DD.MM.YYYY'), 'day') && fieldTime.isSameOrAfter(moment(fifteenMonthsAgo, 'DD.MM.YYYY'), 'day')
          ) {
            return { 
              color: 'warning',
              icon: 'error'
              }
          } else {
            return { 
              color: 'error',
              icon: 'cancel'
            }
          }
        case 'projectState':
          if (this.field.data && this.field.data.length > 0) {
            if (this.field.data[0] === '4' ||
            this.field.data[0] === '5' ||
            this.field.data[0] === '6') {
              return {
                color: 'success',
                icon: 'check_circle'
              }
            }
          }
          return {
              color: 'warning',
              icon: 'error'
            }
          case 'damageState':
            if (this.field.data === 'Vahinkoilmoitus ratkaistu, loppuun käsitelty' ||
            this.field.data === 'Ei toimenpiteitä, loppuun käsitelty') {
              return {
                color: 'success',
                icon: 'check_circle'
              }
            } else if (this.field.data === 'Vakuutusvälittäjällä käsittelyssä' ||
            this.field.data === 'Vahinkoilmoitus tehty') {
              return {
                color: 'warning',
                icon: 'error'
              }
            }
            return {
              color: 'error',
              icon: 'cancel'
            }
          case 'adjustmentState':
            if (this.field.data === 'Valmis') {
              return {
                color: 'success',
                icon: 'check_circle'
              }
            } else if (this.field.data === 'Toteutuksessa') {
              return {
                color: 'warning',
                icon: 'error'
              }
            } else if (this.field.data === 'Keskeytetty / Ei toteutettu') {
              return {
                color: 'error',
                icon: 'cancel'
              }
            }
            return {}
          case 'costEstimate':
            if (this.field.data <= 15000) {
              return {
              color: 'success',
              icon: 'check_circle'
            }
            }
            return {
              color: 'warning',
              icon: 'error'
            }
          case 'unit_condition':
            'Tyydyttävä'
            if (this.data === 'Tyydyttävä') {
                return {
                  color: 'warning',
                  icon: 'error'
                }
            } else if (this.data === 'Raakatila (odottaa vuokralaissuunnittelua)') {
              return {
                color: 'error',
                icon: 'cancel'
              }
            }
            return {}
          case 'costcenter_code':
            if (this.data) {
              return {
                color: 'success',
                icon: 'check_circle'
              }
            } else {
              return {
                color: 'error',
                icon: 'cancel'
              }
            }
          case 'allocation_code':
            if (this.field.data === 'subItemFail') {
              return {
                color: 'warning',
                icon: 'error'
              }
            } else if (this.data) {
              return {
                color: 'success',
                icon: 'check_circle'
              }
            } else {
              return {
                color: 'error',
                icon: 'cancel'
              }
            }
            case 'agreement_end_date':
              if (
                fieldTime.isSameOrBefore(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'error', icon: 'cancel' }
              } else if (
                fieldTime.isBetween(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  moment(twelveMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'warning', icon: 'error' } 
              } else {
                return {
                  color: 'success',
                  icon: 'check_circle'
                }
              }
            case 'certification_expires':
              if (
                fieldTime.isSameOrBefore(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'error', icon: 'cancel' }
              } else if (
                fieldTime.isBetween(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  moment(fifteenMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'warning', icon: 'error' } 
              } else {
                return {
                  color: 'success',
                  icon: 'check_circle'
                }
              }
            case 'curPartyStartDate':
              if (fieldTime.isAfter(moment().endOf('day'))) 
              {
                return {
                  icon: 'warning',
                  color: 'orange'
                } 
              }
              else 
              {
                return {}
              }
            case 'temporaryBuildingPermitDate': 
              if (
                fieldTime.isSameOrBefore(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'error', icon: 'cancel' }
              } else if (
                fieldTime.isBetween(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  moment(twelveMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'warning', icon: 'error' } 
              } else {
                return {
                  color: 'success',
                  icon: 'check_circle'
                }
              }
              case 'temporaryUnitPermitDate': 
              if (
                fieldTime.isSameOrBefore(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'error', icon: 'cancel' }
              } else if (
                fieldTime.isBetween(
                  moment(sixMonthsToGo, 'DD.MM.YYYY'),
                  moment(twelveMonthsToGo, 'DD.MM.YYYY'),
                  'day'
                )
              ) {
                return { color: 'warning', icon: 'error' } 
              } else {
                return {
                  color: 'success',
                  icon: 'check_circle'
                }
              }
              case 'taskDate': 
              if (fieldTime < moment()) {
                return { color: 'error', icon: 'cancel', tooltip: i18n.t('alert.is_late') }
              }
        default:
          return {}
      }
    }
  }
}
</script>
