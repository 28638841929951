<template>
  <v-row
    :class="{
      'graveyard-viewer-fullscreen': fullscreen
    }"
    class="graveyard-viewer"
    no-gutters
  >
    <div
      :id="containerId"
    />
    <div
      class="header-container shrink"
    >
      <v-row
        style="background-color: white"
        class="navigation shrink tab-bar"
        no-gutters
      >
        <v-col>
          <v-btn
            :class="{ 'v-btn--active': tab === 'siteInformation' }"
            depressed
            @click="selectTab('siteInformation')"
          >
            {{ $t('Site information') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-card
        v-show="tab === 'siteInformation'"
        class="info-card elevation-0"
      >
        <site-box 
          class="gravecard"
        />
        <grave-box
          :ids="selectedElements.graves"
        />
        <category-headers
          v-if="!v3loading && loadedCategoryData[selectedCategory]"
          :category-name="selectedCategoryName"
          :category-data="loadedCategoryData[selectedCategory]"
          class="gravecard"
          @select-grave="flyToGrave"
          @toggle-header-color="toggleHeaderColor"
          @select-all="toggleAllColors(true)"
          @deselect-all="toggleAllColors(false)"
        /> 
      </v-card>
    </div>
    <v-row
      v-if="!v3loading"
      class="category-container"
      justify="center"
      no-gutters
    >
      <v-col
        v-for="category in categories"
        :key="category.value"
      >
        <v-btn
          v-if="webScreen"
          :disabled="categoryLoading"
          :class="{'active-category': selectedCategory == category.value}"
          text
          rounded
          outlined
          @click="selectCategory(category.value)"
        >
          {{ category.text }}
        </v-btn>
      </v-col>
      <v-col
        v-if="!webScreen"
      >
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              icon
              class="map-action-btn"
              v-on="on"
            >
              <v-icon>menu</v-icon>
              <span class="d-sr-only">{{ $t('Menu') }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="category in categories"
              :key="category.value"
              :class="{ 'active': selectedCategory === category.value}"
              @click="selectCategory(category.value)"
            >
              <v-list-item-title>{{ category.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      v-if="v3loading || categoryLoading || colorsLoading"
      class="viewer-loading-container no-pointer-events flex-column"
    >
      <v-progress-circular
        :size="120"
        style="margin: auto"
        color="primary"
        indeterminate
      />
    </v-row>
    <v-row
      v-if="!v3loading"
      class="toolset-container flex-column"
    >
      <!-- FULLSCREEN TOGGLE -->
      <v-row
        justify="end"
      >
        <v-btn
          icon
          text
          class="elevation-0 map-action-btn"
          @click="fullscreen = !fullscreen"
        >
          <v-icon v-if="fullscreen">
            fullscreen_exit
          </v-icon>
          <v-icon v-else>
            fullscreen
          </v-icon>
        </v-btn>
      </v-row>
      <!-- SECTION SEARCH -->
      <v-row
        justify="end"
        style="padding-top: 2em"
      >
        <v-btn
          v-if="selectedAction !== 'SectionSearch'"
          icon
          text
          class="elevation-0 map-action-btn"
          @click="selectedAction = 'SectionSearch'"
        >
          <v-icon>
            directions_run
          </v-icon>
        </v-btn>
        <v-autocomplete
          v-if="selectedAction === 'SectionSearch'"
          :label="$t('Graveyard sections')"
          :single-line="true"
          :items="sections"
          style="elevation-0"
          autofocus
          auto-select-first
          item-text="name"
          item-value="id"
          class="white-background"
          prepend-icon="directions_run"
          clearable
          @blur="selectedAction = null"
          @change="flyToSection"
        />
      </v-row>
      <!-- DECEASED SEARCH -->
      <deceased-search
        :selected-action="selectedAction"
        :cemetery-ids="cemeteryIds"
        :active="selectedAction === 'DeceasedSearch'"
        @select="selectedAction = 'DeceasedSearch'"
        @deselect="selectedAction = null"
        @found-grave="flyToGrave"
      />
      <!-- MAP STYLE DROPDOWN -->
      <v-row
        justify="end"
      >
        <v-btn
          v-if="selectedAction !== 'MapStyle'"
          :icon="selectedMapStyle === 'OnlyGraveyard'"
          text
          rounded
          class="elevation-0 map-action-btn"
          style="background-color: white"
          @click="selectedAction = 'MapStyle'"
        >
          <v-icon>terrain</v-icon>
          <span v-if="selectedMapStyle !== 'OnlyGraveyard'">{{ selectedMapStyleName }}</span>
        </v-btn>
        <v-select
          v-if="selectedAction === 'MapStyle'"
          v-model="selectedMapStyle"
          :items="mapStyleSelections"
          autofocus
          auto-select-first
          class="white-background"
          prepend-icon="terrain"
          open-on-clear
          @blur="selectedAction = null"
          @change="changeMapStyle"
        />
      </v-row>
      <v-row
        justify="end"
      >
        <v-btn
          text
          rounded
          icon
          class="elevation-0 map-action-btn"
          @click="printCurrentDrawing"
        >
          <v-icon v-if="!isPrinting">
            print
          </v-icon>
          <v-progress-circular
            v-else
            indeterminate
            small
            color="primary"
          />
        </v-btn>
      </v-row>
      <!-- Multiselect buttons -->
      <v-row
        justify="start"
        class="multiselects"
      >
        <v-col class="grow">
          <v-btn
            :input-value="isInSelectionMode"
            :icon="!selectScreen"
            inline-flex
            text
            rounded
            outlined
            active-class="mode-active"
            @click="toggleSelectionMode"
          >
            <v-icon>add</v-icon>
            {{ selectScreen ? "Monivalinta" : '' }}
          </v-btn>
          <!-- Area selection commented out as it has some id resolving issues -->
          <v-btn
            :input-value="isInRectangleSelectionMode"
            :icon="!selectScreen"
            inline-flex
            text
            rounded
            outlined
            active-class="mode-active"
            @click="rectangleSelect"
          >
            <v-icon>crop</v-icon>
            {{ selectScreen ? "Aluevalinta" : '' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import scriptjs from 'scriptjs'
import V3GraveyardWrapper from '../helpers/v3graveyardwrapper.js'
import DeceasedSearch from '../components/GraveyardMap/DeceasedSearch.vue'
import CategoryHeaders from '../components/GraveyardMap/CategoryHeaders.vue'
import SiteBox from '../components/GraveyardMap/SiteBox.vue'
import GraveBox from '../components/GraveyardMap/GraveBox.vue'
import format from '../helpers/format.js'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import helpers from '../helpers'
export default {
  components: {
    DeceasedSearch,
    CategoryHeaders,
    SiteBox,
    GraveBox
  },
  data () {
    return {
      res: null,
      v3wrapper: null,
      containerId: 'v3container',
      projectId: 'CircleCemeteries',
      mapStyles: {
        Road: 'MapStyleRoad',
        Satellite: 'MapStyleSatellite',
        'Satellite and 3D': 'MapStyleSatelliteAnd3D',
        None: 'MapStyleNone'
      },
      mapStyleSelections: [
        {
          text: this.$t('Only graveyard map'),
          value: 'OnlyGraveyard'
        },
        {
          text: this.$t('Road map'),
          value: 'Road'
        },
        {
          text: this.$t('Satellite image'),
          value: 'Satellite'
        }
      ],
      categories: [
        {
          text: this.$t('Vacant graves amount'),
          value: 'VacantGraves'
        },
        {
          text: this.$t('Cemetery services'),
          value: 'GraveServices'
        },
        {
          text: this.$t('Precursory reservations'),
          value: 'PrecursoryReservations'
        }
      ],
      loadedCategoryData: {},
      selectedMapStyle: 'OnlyGraveyard',
      selectedCategory: null,
      selectedGraves: [],
      cemeteries: [],
      sections: [],
      graves: [],
      deceased: [],
      selectedAction: null,
      v3loading: true,
      categoryLoading: false,
      colorsLoading: false,
      fullscreen: false,
      isInSelectionMode: false,
      isInRectangleSelectionMode: false,
      retangleSelection: [],
      tab: 'siteInformation',
      selectedElements: {
        graves: []
      },
      elementLayers: {
        '201': 'graves'
      },
      isPrinting: false,
      currentGraveModels: [],
      currentSectionModels: [],
      defaultColor: [0.7, 0.7, 0.7, 1],
      defaultSelectionColor: [0, 0.6156, 0.8784, 1.0],
      elementColors: [],
      localVektorioEnv: null
    }
  },
  computed: {
    ...mapGetters('sites', ['cemeteriesForCurrentSite', 'currentSite' ]),
    ...mapGetters('app', ['definitionById', 'definitionsByGroupLabel']),
    ...mapState('app', ['currentDate' ]),
    v3ApiToken () {
      return this.$cookies.get('v3ViewerToken')
    },
    v3cssUrl () {
      var LocalEnv = this.localVektorioEnv
      if(!LocalEnv)
      {
        return process.env.VUE_APP_V3_CSS_URL
      }
      return LocalEnv == "Azure" ? process.env.VUE_APP_V3_CSS_URL : process.env.VUE_APP_V3_CSS_URL_GCP
    },
    v3scriptUrl () {
      var LocalEnv = this.localVektorioEnv
      if(!LocalEnv)
      {
        return process.env.VUE_APP_V3_SCRIPT_URL
      }
      return LocalEnv == "Azure" ? process.env.VUE_APP_V3_SCRIPT_URL : process.env.VUE_APP_V3_SCRIPT_URL_GCP
    },
    mapStyleNames () {
      return Object.keys(this.mapStyles)
    },
    siteId () {
      return Number(this.$route.params.id_site)
    },
    selectedMapStyleName () {
      const mapStyle = this.mapStyleSelections.find(x => x.value === this.selectedMapStyle)
      return mapStyle.text
    },
    cemeteryIds () {
      return this.cemeteriesForCurrentSite.map(x => x.id)
    },
    selectedCategoryName () {
      const category = this.categories.find(x => x.value === this.selectedCategory)
      if (category) {
        return category.text
      }
      return ''
    },
    selectedGraveIds () {
      return [1, 2, 3, 4]
    },
    webScreen () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    selectScreen () {
      return this.$vuetify.breakpoint.smAndUp
    },
  },
  watch: {
  },
  async mounted () {
    if (!VueCookies.isKey('v3ViewerToken')) {
      // get v3ViewerToken only if it has not been fetched before
      await this.getVektorioToken()
    }
    if(window.location.hostname.includes('localhost'))
    {
      this.localVektorioEnv = await this.$rambollfmapi.accounts.vektorio.localenvironment.get()
    }
    await this.loadSite(this.siteId)

    const self = this
    this.currentGraveModels = []
    this.currentSectionModels = []

    for (const cemetery of this.cemeteriesForCurrentSite.filter(x => x.modelId)) {

      this.currentGraveModels.push({
        projectId: this.projectId,
        name: cemetery.name,
        url: '',
        backendModelId: cemetery.modelId,
        coordinateSystem: cemetery.coordinateSystem, 
        flyTo: 0
      })
      const sectionsForCemetery = await this.$rambollfmapi.graveyards.cemeteries.sections(cemetery.id)
      this.sections = this.sections.concat(sectionsForCemetery)
    }

    for (const section of this.sections.filter(x => x.modelId)) {
      // Get the related cemetery
      const cemetery = this.cemeteriesForCurrentSite.find(x => x.id === section.cemeteryId)

      this.currentSectionModels.push({
        projectId: this.projectId,
        name: section.name,
        url: '',
        backendModelId: section.modelId,
        coordinateSystem: cemetery.coordinateSystem, 
        flyTo: 2
      })
    }

    // bootstrap V3 viewer
    if (typeof window.V3 === 'undefined' || typeof window.V3 !== 'function') {
      scriptjs(this.v3scriptUrl, () => {
        self.initialize()
      })
    } else {
      self.initialize()
    }
  },
  methods: {
    ...mapActions('sites', ['loadSite']),
    async initialize () {
      const v3options = {
        containerId: this.containerId,
        selectedSpaceColor: [0, 0.6156, 0.8784, 1.0],
        sessionToken: this.v3ApiToken,
        mapStyle: this.mapStyles.None,
        backgroundColor: [0.984, 0.984, 0.984, 1],
        onSelection: this.onSelection
      }
      const configuration = {
        shadows: false,
        ambientOcclusion: false,
        showProperties: false,
        contextMenu: false,
        projectIconUrl: '',
        projectIconScale: 0.5,
        lowerDetailsWhileMoving: false,
        lowerResolutionWhileMoving: false,
        enableMeasuring: true,
        automaticModelAlignment: true
      }

      this.v3wrapper = new V3GraveyardWrapper(window.V3, v3options, configuration)
      for (const model of this.currentGraveModels) {
        await this.v3wrapper.loadGraveyardModel(model)
      }
      for (const model of this.currentSectionModels) {
        await this.v3wrapper.loadGraveyardModel(model)
      }
      // For some reason model load callback is called before whole model is loaded, so we wait half second before filling cache
      setTimeout(() => {
        this.v3wrapper.fillGraveCache(this.projectId)
        this.v3wrapper.setSelectionIgnoredItems(this.projectId)
      }, 500)
      this.v3 = this.v3wrapper.v3
      this.v3loading = false

      document.getElementById(v3options.containerId + 'SelectionRectangle').style.position = 'fixed'
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? def.label : this.$t('Undefined')
    },
    rectangleSelect () {
      this.isInRectangleSelectionMode = true
      this.v3wrapper.beginRectangleSelection()
    },
    toggleSelectionMode () {
      if (this.isInSelectionMode) {
        this.leaveSelectionMode()
      } else {
        this.enterSelectionMode()
      }
    },
    enterSelectionMode () {
      this.v3.setMultiselect(true) // TODO olisiko wrapperin parempi pitää kirjaa näistä
      this.isInSelectionMode = true
    },
    leaveSelectionMode () {
      this.v3.setMultiselect(false) // TODO olisiko wrapperin parempi pitää kirjaa näistä
      this.isInSelectionMode = false
    },
    onSelection (element, hitPoint, multiSelectElements) {
      // Clear selections for all element layers
      for (var key of Object.keys(this.selectedElements)) {
        this.selectedElements[key] = []
      }

      // Single selection
      if (typeof element !== 'undefined' && !this.isInSelectionMode) {
        this.addSelectedIdFromLayer(element.Properties.Pset_ElementInfo.Layer.value)
      }

      // Multi-selection
      if (typeof multiSelectElements !== 'undefined' && this.isInSelectionMode) {
        for (var e of multiSelectElements) {
          this.addSelectedIdFromLayer(e.Properties.Pset_ElementInfo.Layer.value)
        }
      }

      // Rectangle selection
      if (typeof multiSelectElements !== 'undefined' && this.isInRectangleSelectionMode) {
        const ids = this.v3wrapper.getIdsFromCacheByInternalId(multiSelectElements.map(x => x.InternalId))
        for (const idStr of ids) {
          const id = Number(idStr)
          this.selectedElements.graves.push(id)
        }
      }

      this.isInRectangleSelectionMode = false
    },
    addSelectedIdFromLayer (layer) {
      const splitLayer = layer.split('(£)')
      if (splitLayer.length > 1) {
        const id = Number(splitLayer[1])
        const elementLayer = splitLayer[0]
        this.selectedElements[this.elementLayers[elementLayer]].push(id)
      }
    },
    flyToSection (event) {
      this.selectedAction = null
      const section = this.sections.find(x => x.id === event)
      const selectedModel = this.v3wrapper.loadedModels.find(m => m.name == section.name)
      this.v3.flyToModel(this.projectId, selectedModel.modelHandle, 0, 180, 0, 3)
    },
    getCemeteryName () {
      if (this.cemeteriesForCurrentSite.length === 1){
        return this.cemeteriesForCurrentSite[0].name
      }
    },
    flyToGrave (event) {
      this.v3wrapper.flyToAndSelectGrave(event, 50, 1)
    },
    changeMapStyle (style) {
      this.selectedAction = null
      if (style === 'OnlyGraveyard') {
        this.v3wrapper.setMapStyle(this.mapStyles.None, false, [1,1,1,1])
      } else if (style === 'Road') {
        this.v3wrapper.setMapStyle(this.mapStyles.Road, false, [1,1,1,1])
      } else if (style === 'Satellite') {
        this.v3wrapper.setMapStyle(this.mapStyles.Satellite, false, [1,1,1,1])
      }
    },
    async selectCategory (value) {
      this.resetSectionColors()
      if (this.selectedCategory === value) {
        this.selectedCategory = null
      } else {
        this.selectedCategory = value
        this.categoryLoading = true
        // We store the category data, so we first try to see wether the data has already been loaded
        // incase it has not been loaded we fetch it from the API.
        if (!this.loadedCategoryData[value]) {
          const data = await this.$rambollfmapi.graveyards.categories.get(value, {
            siteId: this.siteId
          })
          // $set for reactivity purposes, since they are used in the dom.
          this.$set(this.loadedCategoryData, value, data)
          for (let i = 0; i < this.loadedCategoryData[value].length; ++i) {
            this.$set(this.loadedCategoryData[value][i], 'show', true)
          }
        }
        this.colorCategories()
        this.categoryLoading = false
      }
    },
    toggleHeaderColor (header) {
      const foundHeader = this.loadedCategoryData[this.selectedCategory].find(x => x.value === header)
      foundHeader.show = !foundHeader.show
      this.colorCategories()
    },
    toggleAllColors (show) {
      for (const header of this.loadedCategoryData[this.selectedCategory]) {
        header.show = show
      }
      this.colorCategories()
    },
    async colorCategories () {
      this.colorsLoading = true
      this.elementColors = []
      this.resetSectionColors()
      if (this.loadedCategoryData[this.selectedCategory]) {
        for (const header of this.loadedCategoryData[this.selectedCategory].filter(x => x.show)) {
          // This might take a while, so by using setTimeout we won't completely freeze the UI
          setTimeout(() => {
            const color = [...header.rgb.split(',').map(x => x / 255), 1]
            this.v3wrapper.setCategoryColors(header.graves.map(x => x.graveId), color)
            header.graves.forEach(g => {
              this.elementColors.push({id: g.graveId, color: color})
            });
          }, 500)
        }
      }
      this.colorsLoading = false
    },
    resetSectionColors () {
      this.v3.resetElementColors(this.projectId)
    },
    selectTab (tab) {
      if (this.tab === tab) {
        this.tab = ''
      } else {
        this.tab = tab
      }
    },
    print (options) {
      this.isPrinting = true
      const self = this
      
      this.v3.exportToPdf(this.projectId, undefined, options, function (
        error,
        url
      ) {
        if (typeof error === 'undefined' || error === null) {
          window.open(url, '_blank')
        }
        self.isPrinting = false
      })
    },
    countNewLines (str) {
      const matches = str.match(/\n/g);
      return matches ? matches.length : 0;
    },
    sortByBurialDate (deceased) {
      deceased.sort((a,b) => {
        return new Date(b.burial_date) - new Date(a.burial_date)
      })
    },
    wrapText (text, lineLength) {
      const words = text.split(' ');
      const lines = [];
      let currentLine = '';

      words.forEach((word) => {
          if (currentLine.length + word.length + 1 > lineLength) {
              if (currentLine.length > 0) {
                  lines.push(currentLine);
              }
              currentLine = word;
          } else {
              if (currentLine.length > 0) {
                  currentLine += ' ';
              }
              currentLine += word;
          }
      });

      if (currentLine.length > 0) {
          lines.push(currentLine);
      }

      return lines.join('\n') + '\n';
    },
    generateText (graves) {
      const lineLength = 27
      let text = ''
      if(graves === null || graves.length === 0) {
        return ''
      }
      if (graves.length > 1) {
        graves.forEach(grave => {
          text += grave.grave_identifier + '\n'
        })
        text = text.slice(0,-2)
        return text
      }

      graves.forEach(grave => {
        text += this.wrapText(grave.grave_identifier + ',', lineLength)
        text += '   Hallinta-aika: ' + grave.possession_start_year + ' - ' + grave.possession_end_year + ',\n' +
        '   Haudan koko: ' + grave.width + 'm x ' + grave.height + 'm,\n'
        text += this.wrapText('   Tyyppi: ' + this.getDefinitionLabelById(grave.grave_type) + ',', lineLength)
        text += this.wrapText('   Hautalaji: ' + this.getDefinitionLabelById(grave.grave_sort), lineLength)
        
        if (grave.services) {
          if(grave.services.length === 1) {
            text += '------- HOITO -------' + '\n'  
          } else if (grave.services.length > 1) {
            text += '------- HOIDOT -------' + '\n'
          }
          grave.services.forEach(service => {
            text += this.wrapText(service.service_description, lineLength)
            text += '   Kesto: ' + helpers.humanize.date(service.service_start_date, 'year') + ' - ' + helpers.humanize.date(service.service_end_date, 'year') + ',\n'
          })
        }
        
        if (grave.deceased) {
          if(grave.deceased.length === 1) {
            text += '------- VAINAJA -------' + '\n'  
          } else if (grave.deceased.length > 1) {
            this.sortByBurialDate(grave.deceased)
            text += '------ VAINAJAT ------' + '\n'
          }

          let deceasedToAdd = ''
          let deceasedCount = grave.deceased.length
          grave.deceased.forEach(dead => {
            deceasedToAdd += this.wrapText(dead.name, lineLength)
            if (deceasedCount <= 6) {
              deceasedToAdd += '   Syntymäaika: ' + helpers.humanize.date(dead.birth_date, 'date') + ',\n' +
              '   Kuolinaika: ' + helpers.humanize.date(dead.death_date, 'date') + ',\n' +
              '   Hautauspäivä: ' + helpers.humanize.date(dead.burial_date, 'date') + ',\n'
              deceasedToAdd += '   Hautapaikka: '
              deceasedToAdd += dead.grave_area ? dead.grave_area + ',\n' : '-,\n'
              deceasedToAdd += '   Sija: '
              deceasedToAdd += dead.placement ? dead.placement + '\n' : '-\n'
            }
            if ((this.countNewLines(deceasedToAdd) + this.countNewLines(text)) < 41 ) {
              text += deceasedToAdd
              deceasedToAdd = ''
            }
          })
        }
      })
      text = text.slice(0,-1)
      return text
    },
    async printCurrentDrawing () {
      // We use the implementation from the viewer component
      this.isPrinting = true
      if (this.selectedCategory !== null) {
        let title =
          this.$t('Status') + ' ' +
          moment(this.currentDate).format('DD.MM.YYYY') + '\n' +
          this.$t(this.selectedCategoryName) +
          '\n' +
          this.currentSite.name

        const options = {
          legend: {
            title: title,
            values: this.loadedCategoryData[this.selectedCategory]
              .filter(c => c.show)
              .sort((a, b) => (a.text < b.text ? -1 : 1))
              .map(c => {
                return {
                  text:
                    this.$t(c.text) +
                    ' (' + format.formatData(c.count, 'Amount') + ') ',
                  color: {
                    r: c.rgb.split(',')[0] / 255,
                    g: c.rgb.split(',')[1] / 255,
                    b: c.rgb.split(',')[2] / 255
                  }
                }
              })
          }
        }
        this.print(options)
      } else if (
        this.selectedElements.graves.length > 0
      ) {
        const graves = await this.$rambollfmapi.graveyards.graves.list(this.selectedElements.graves)
        let text = this.generateText(graves)

        const options = {
          legend: {
            title: this.$t('Selected graves')+
              '\n' +
              this.currentSite.name,
            values: [
              {
                text: text,
                color: {
                  r: 0,
                  g: 0.6156,
                  b: 0.8784
                }
              }
            ]
          }
        }
        const currentColors = []
        this.selectedElements.graves.forEach(g => {
          currentColors.push(
            this.elementColors.find(e => e.id == g) ?? { id: g }
          )
          
          this.v3wrapper.setElementColor(g, this.defaultSelectionColor)
        })
        await this.print(options)
        
        currentColors.forEach(color => {
          if (typeof color.color != 'undefined') {
            this.v3wrapper.setElementColor(color.id, color.color)
          } else {
            this.v3wrapper.resetElementColor(color.id)
          }
        })
      } else {
        this.print({})
      }
    },
    async getVektorioToken () {
      try {
        const v3ViewerToken = await this.$rambollfmapi.accounts.vektorio.session.get()
        if (v3ViewerToken.name && v3ViewerToken.name === 'Error') {
          throw new Error(v3ViewerToken)
        }
        VueCookies.set('v3ViewerToken', v3ViewerToken, '1m', null, null, process.env.NODE_ENV !== 'development', 'Strict')
      } catch (error) {
        this.$log.error(error)
        if (VueCookies.isKey('v3ViewerToken')) {
          VueCookies.remove('v3ViewerToken')
        }
      }
    }
  }
}
</script>

<style scoped>
.graveyard-viewer {
  position: fixed;
  width: 100%;
  top: 169px;
  bottom: 0;
}
.graveyard-viewer-fullscreen {
  top: 0;
  height: 100%;
}
.map-action-btn {
  border: 1px solid #8b8b8b;
  background-color: white;
}
.viewer-loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
}
.header-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
  left: 0em;
  width: 27em;
  top: 0em;
  bottom: 0em;
}
.category-container {
  position: fixed;
  width: fit-content;
  right: 80px;
  padding-top: 2em;
}
.toolset-container {
  position: fixed;
  right: 2em;
  padding-top: 2em;
}
.info-container {
  position: absolute;
  left: 0em;
  top: 0em;
}
.white-background {
  background-color: white;
}
.active-category {
  background-color: #dce0ee !important;
  color: #1976d2 !important;
}
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.no-pointer-events {
  pointer-events: none;
}
.mode-active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.multiselects {
  position: fixed;
  bottom: 0.5em;
  left: 40%;
}
.info-card {
  overflow-y: auto;
  pointer-events: auto;
  border-radius: 0;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  z-index: 1;
}
.tab-bar {
  pointer-events: auto;
}
.gravecard {
  padding: 0.5em;
}
@media screen and (max-width: 540px) {
  .category-container {
    top: unset;
    bottom: 18em;
    right: 0.5em;
  }
  .toolset-container {
    padding-top: 0;
    bottom: 0.5em;
    right: 0.5em;
  }
  .multiselects {
    left: 5%;
  }
}
@media screen and (max-width: 740px) and (orientation: landscape) {
  .graveyard-viewer {
    position: relative;
    top: 1em;
  }
  .graveyard-viewer-fullscreen {
    position: fixed;
  }
  .category-container {
    position: absolute;
  }
  .toolset-container {
    position: absolute;
  }
}
</style>
