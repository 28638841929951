<template>
  <UnitModifyForm
    :in-popup="true"
    :units="modificableUnits"
    :unit-usages="unitUsages"
    :unit-rent-states="unitRentStates"
    :party-links="unitPartyLinks"
    :dismantleable="selectedSpacesFromViewer.length > 0"
    :disabled="readonly"
    :renting="rentingTabActive"
    :apartments-active="apartmentsActive"
    :unit-apartments="unitApartments"
    :unit-kitchens="unitKitchens"
    :unit-balconies="unitBalconies"
    :unit-additional-infos="unitAdditionalInfos"
    :unit-visibility-rights="unitVisibilityRights"
    :target-goal="targetGoal"
    :right-for-ti-expense="rightForTIExpense"
    @update="postMessageUpdate"
    @close="close"
  />
</template>
<script>
import { mapMutations } from 'vuex'
import UnitModifyForm from '../modules/v3viewer/UnitModifyForm.vue'

export default {
  name: 'UnitModifyPopup',
  components: {
    UnitModifyForm: UnitModifyForm
  },
  data: function () {
    return {
      unitModifyDialog: false,
      marketingModifyDialog: false,
      unitRentStates: [],
      unitUsages: [],
      rentingTabActive: false,
      savingFailed: false,
      unitApartments: [],
      unitKitchens: [],
      unitBalconies: [],
      unitAdditionalInfos: [],
      unitMarketingInfo: [],
      modifiedUnits: [],
      unitPartyLinks: [],
      modificableUnits: [],
      selectedSpacesFromViewer: [],
      readonly: true,
      apartmentsActive: false,
      unitVisibilityRights: {},
      rightForTIExpense: false,
      targetGoal: []
    }
  },
  computed: {},
  mounted () {
    this.setPopupOpen(true)
    window.addEventListener('message', this.receiveMessage)
    window.opener.postMessage("READY_UNIT", "*");
  },
  methods: {
    ...mapMutations('app', ['setPopupOpen']),
    receiveMessage (event) {
      if (event.data.modificableUnits) {
        this.unitModifyDialog =  event.data.unitModifyDialog,
        this.marketingModifyDialog = event.data.marketingModifyDialog
        this.unitRentStates = event.data.unitRentStates
        this.unitUsages = event.data.unitUsages
        this.rentingTabActive = event.data.rentingTabActive
        this.savingFailed = event.data.savingFailed
        this.unitApartments = event.data.unitApartments
        this.unitKitchens = event.data.unitKitchens
        this.unitBalconies = event.data.unitBalconies
        this.unitAdditionalInfos = event.data.unitAdditionalInfos
        this.unitMarketingInfo = event.data.unitMarketingInfo
        this.modifiedUnits = event.data.modifiedUnits
        this.unitPartyLinks = event.data.unitPartyLinks
        this.modificableUnits = event.data.modificableUnits
        this.selectedSpacesFromViewer = event.data.selectedSpacesFromViewer
        this.readonly = event.data.readonly,
        this.apartmentsActive = event.data.apartmentsActive
        this.unitVisibilityRights = event.data.unitVisibilityRights
        this.rightForTIExpense = event.data.rightForTIExpense,
        this.targetGoal = event.data.targetGoal
      }
    },
    postMessageUpdate () {
      window.opener.postMessage("UPDATE", "*");
      window.close()
    },
    close () {
      this.unitModifyDialog = false
      window.close()
    }
  }
}
</script>
<style scoped>
</style>


