<template>
  <div>
    <v-btn
      text
      rounded
      outlined
      color="blue"
      @click="showVisibleColumnsDialog = true"
    >
      {{ $t('Choose visible columns') }}
    </v-btn>
    <v-dialog
      :value="showVisibleColumnsDialog"
      max-width="1000"
      persistent
    >
      <BaseModal
        @cancel="setShowVisibleColumnsDialogEdit(false)"
      >
        <template #title>
          {{ $t('Chosen columns') }}
        </template>
        <template #content>
          <v-row>
            <v-col
              v-for="header in sortedHeaders"
              :key="header[header.value]"
              cols="6"
              sm="4"
              md="3"
              style="overflow-x:auto"
            >
              <v-checkbox
                v-model="visibleHeadersEdit"
                :label="`${$t(header[headerText])}`"
                :value="header[headerValue]"
              />
            </v-col>
          </v-row>
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <div
            style="overflow-x:auto; overflow-y:hidden; direction: rtl"
          >
            <v-row 
              v-if="!showSaveSelectionDialog"
              style="min-width:850px; direction: ltr;"
              no-gutters
            >
              <v-select
                v-if="showSaveSelection"
                v-model="selectedViewName"
                :label="$t('Stored views')"
                :items="storedViewsForWidget"
                item-text="name"
                item-value="name"
                clearable
                :disabled="storedViewsForWidget.length === 0"
                style="margin-bottom: 5px"
              />
              <v-btn
                v-if="selectedViewName"
                text
                rounded
                outlined
                color="error"
                @click.stop="deleteView"
              >
                {{ $t('Delete view') }}
              </v-btn>
              <v-spacer />
              <v-btn
                text
                rounded
                outlined
                @click.stop="selectAll"
              >
                {{ $t('Choose all') }}
              </v-btn>
              <v-btn
                text
                rounded
                outlined
                @click.stop="clearSelections"
              >
                {{ $t('Remove all') }}
              </v-btn>
              <v-btn
                v-if="showSaveSelection"
                class="primary"
                depressed
                rounded
                :disabled="visibleHeadersEdit.length < 1"
                @click.stop="showSaveSelectionDialog = true"
              >
                {{ $t('Save selection') }}
              </v-btn>
              <v-btn
                text
                rounded
                outlined
                :disabled="visibleHeadersEdit.length < 1"
                @click.stop="setShowVisibleColumnsDialogEdit(false)"
              >
                {{ $t('Show') }}
              </v-btn>
            </v-row>
            <v-row 
              v-else
              style="min-width:850px; direction: ltr;"
            >
              <v-text-field
                v-if="showSaveSelection"
                v-model="nameOfSelection"
                :placeholder="$t('Give a name for selection')"
                autofocus
                clearable
                style="border: 0px; margin-bottom: 10px"
              />
              <v-spacer />
              <v-btn
                v-if="showSaveSelection"
                center
                outlined
                rounded
                text
                @click.stop="
                  showSaveSelectionDialog = false
                  needsConfirm = false
                "
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                v-if="!needsConfirm"
                :class="
                  nameOfSelection && nameOfSelection.length > 0 ? 'primary' : null
                "
                depressed
                rounded
                :disabled="!nameOfSelection || nameOfSelection.length < 1"
                @click.stop="saveSelection"
              >
                {{ $t('Save') }}
              </v-btn>
              <v-btn
                v-else
                class="primary"
                rounded
                depressed
                @click.stop="confirmSaveSelection"
              >
                {{ $t('Confirm') }}
              </v-btn>
              <v-btn
                text
                rounded
                outlined
                @click.stop="setShowVisibleColumnsDialogEdit(false)"
              >
                {{
                  $t('Ok')
                }}
              </v-btn>
            </v-row>
          </div>
        </template>
      </BaseModal>
      <Alert
        :y="null"
        :show="showAlert"
        :result="actionResult"
        :message="alertMessage"
      />
    </v-dialog>
  </div>
</template>
<script>
import Alert from '../Alert.vue'
import BaseModal from './BaseModal.vue'
import { mapActions, mapState } from 'vuex'
export default {
  emits: ['input'],
  components: {
    Alert,
    BaseModal,
  },
  props: {
    small: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => []
    },
    storedViewParent: {
      type: String,
      default: null
    },
    storedViewLevel: {
      type: String,
      default: null
    },
    headers: {
      default: () => {
        return []
      },
      type: Array        
    },
    showSaveSelection: {
      default: () => {
        return false
      },
      type: Boolean
    },
    headerText: {
      default: () => {
        return 'text'
      },
      type: String
    },
    headerValue: {
      default: () => {
        return 'value'
      },
      type: String
    }
  },
  data () {
    return {
      showSaveSelectionDialog: false,
      selectedViewName: null,
      showVisibleColumnsDialog: false,
      visibleHeadersEdit: [],
      nameOfSelection: null,
      needsConfirm: false,
      actionResult: null,
      showAlert: false,
      alertMessage: null
    }
  },
  computed: {
    ...mapState('app', [ 'storedViews' ]),
    sortedHeaders () {
      return [...this.headers].sort((a, b) =>
        this.$t(a[this.text]).localeCompare(this.$t(b[this.text]))
      )
    },
    storedViewsForWidget () {
      return this.storedViews.filter(sv => sv.level === this.storedViewLevel)
    },
    headerValues () {
      return this.headers.map(x => x[this.headerValue])
    }
  },
  watch: {
    selectedView: function (selectedView) {
      this.selectedViewMethod(selectedView)
    }, 
    selectedViewName: function (name) {
      if (name) {
        this.selectedView = this.storedViewsForWidget.find(sw => sw.name === name)
        this.selectedViewMethod(this.selectedView)
      } else {
        this.selectedView = null
      }
    },
    value: {
      deep: true,
      handler: function (newValues, oldValues) {
        if ( newValues !== oldValues) {
          this.visibleHeadersEdit = JSON.parse(JSON.stringify(newValues))
        }
      }
    }
  },
  async mounted () {
    await this.getStoredViews(this.storedViewParent)
    this.visibleHeadersEdit = JSON.parse(JSON.stringify(this.headerValues))
  },
  methods: {
    ...mapActions('app', [
      'getStoredViews'
    ]),
    selectedViewMethod (selectedView){
      if (typeof selectedView !== 'undefined' && selectedView !== null) {
        this.visibleHeadersEdit = this.headers
          .filter(dh =>
            selectedView.stored_fields.find(sf => sf.field === dh.value)
          )
          .map(dh => {
            return dh.value
          })
        this.nameOfSelection = selectedView.name
        this.selectedViewName = selectedView.name
      } else {
        this.visibleHeadersEdit = this.headers.map(dh => {
          return dh.value
        })
        this.nameOfSelection = null
      }
    },
    exit: function () {
      this.showVisibleColumnsDialog = false
      this.$emit("input", this.headerValues)
    },
    setShowVisibleColumnsDialogEdit: function (set){
      this.showVisibleColumnsDialog = false
      this.$emit("input", this.visibleHeadersEdit)
    },
    selectAll () {
      this.visibleHeadersEdit = JSON.parse(JSON.stringify(this.headerValues))
    },
    clearSelections () {
      this.visibleHeadersEdit = []
    },
    async saveSelection () {
        const data = {
        parent_level: this.storedViewParent,
        level: this.storedViewLevel,
        name: this.nameOfSelection
      }
      this.confirmation = false
      if (this.storedViewsForWidget.find(sv => sv === this.nameOfSelection) && !confirm) {
        this.confirmation = true
        this.actionResult = 'warning'
        this.alertMessage = this.$t('Warning') + ': ' + this.nameOfSelection + ' ' + this.$t('will be overwritten')
        this.storedView.stored_fields = this.visibleHeadersEdit.map(h => {
          return {
            field: h,
            id_storedview: this.storedView.id
          }
        })
      } else {
        try {
          await this.$rambollfmapi.storedviews.put(data, this.visibleHeadersEdit)
          this.showColumnsFilter = false
          this.actionResult = 'success'
          this.alertMessage = this.$t('Save success')
        } catch (error) {
          this.actionResult = 'error'
          this.alertMessage = this.$t('Error while saving')
        }
      }
      this.showAlert = true
      if (this.actionResult === 'success') {
        await this.getStoredViews(this.storedViewParent)
        this.showSaveSelectionDialog = false
        this.selectedViewName = this.nameOfSelection
      }
    },
    async deleteView () {
      this.selectedViewName = null
      try {
        await this.$rambollfmapi.storedviews.delete(this.selectedView.id)
        this.actionResult = 'success'
        this.alertMessage = this.$t('Succesfull delete')
        this.selectedView = null
      } catch (error) {
        this.actionResult = 'error'
        this.alertMessage = this.$t('Unsuccesfull delete')
      }
      await this.getStoredViews(this.storedViewParent)
      this.showAlert = true
    }
  }
}
</script>
<style scoped>
</style>