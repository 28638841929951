<template>
  <div>
    <v-list
      v-if="model"
      dense
    >
      <v-list-item
        v-for="field in fields"
        :key="field.name"
        class="litem"
        :class="{
          tile: field.editType !== 'TextArea' || !multiline,
          'tile tile-full-height': field.editType === 'TextArea' && multiline,
        }"
      >
        <v-list-item-content class="tile-content">
          <template v-if="field.editType === 'DropDown' && field.name === 'id_purpose'">
            <v-autocomplete
              v-model="model[field.name]"
              clearable
              :items="selectablePurposes()"
              :label="$t(getFieldName(field.name))"
              item-text="title"
              item-value="id"
            />
          </template>
          <v-text-field
            v-if="field.editType === 'TextField' && !disabled"
            v-model="model[field.name]"
            :label="$t(getFieldName(field.name))"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />

          <DateField
            v-else-if="field.editType === 'Date' && !disabled"
            v-model="model[field.name]"
            class="borderless"
            append-icon="edit"
            :label="$t(getFieldName(field.name))"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />

          <NumericField
            v-else-if="
              field.editType === 'Number' && field.type !== 'Boolean' && !disabled && !field.name.startsWith('id_purpose_zone')
            "
            v-model="model[field.name]"
            :label="$t(getFieldName(field.name))"
            :field="field"
            :placeholder="model[field.name]"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />

          <v-select
            v-else-if="field.editType === 'Checkbox' && !disabled"
            v-model="model[field.name]"
            small
            :label="$t(getFieldName(field.name))"
            :items="checkboxItems"
            item-text="text"
            item-value="value"
            class="borderless"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />

          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && field.name !== 'id_purpose' && field.name !== 'cleaning_area' && field.name !== 'cleaning_area_low'"
            v-model="model[field.name]"
            :label="$t(getFieldName(field.name))"
            small
            :items="
              getDefinitionOptionsById(field.definitionGroupId, field.isRequired)
            "
            item-text="label"
            item-value="id"
            :disabled="getDefinitionOptionsById(field.definitionGroupId, field.isRequired).length===0"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />

          <v-select
            v-else-if="field.editType === 'DropDown' && !disabled && (field.name == 'cleaning_area' || field.name == 'cleaning_area_low')"
            v-model="model[field.name]"
            :label="$t(getFieldName(field.name))"
            small
            :items="cleaningAreaChoices"
            item-text="description"
            item-value="name"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />

          <template
            v-else-if="field.name.startsWith('id_purpose_zone')"
          >
            <SpacePurposeZones
              :metadata="metadata"
              @input="setPurposes"
            />
          </template>

          <template v-else-if="field.editType === 'TextArea' && field.category !== 'Description' && !disabled">
            <h4>{{ $t(getFieldName(field.name)) }}</h4>
            <v-textarea
              v-model="model[field.name]"
              :single-line="field.category === 'Description'"
              class="borderless"
              :label="$t(getFieldName(field.name))"
              hide-details

              :disabled="disabled"
              :rows="getRows()"
              @change="$emit('change', { model, field, value: model[field.name] })"
            />
          </template>

          <v-textarea
            v-else-if="field.editType === 'TextArea' && !disabled"
            v-model="model[field.name]"
            :single-line="field.category === 'Description'"
            class="borderless"
            :label="$t(getFieldName(field.name))"
            hide-details
            :disabled="disabled"
            :rows="getRows()"
            @change="$emit('change', { model, field, value: model[field.name] })"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters} from 'vuex'
import SpacePurposeZones from './SpacePurposeZones.vue'
import Alert from '../components/Alert.vue'
import NumericField from '../components/NumericField.vue'
import DateField from '../components/DateField.vue'
export default {
  components: {
    Alert,
    NumericField,
    DateField,
    SpacePurposeZones
  },
  props: {
    metadata: {
      type: Object,
      default: null
    },
    value: {
      type: [Array, Object],
      default: null
    },
    category: {
      type: String,
      default: 'None'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'input'],
  data () {
    return {
      checkboxItems: [
        { text: this.$t('Yes'), value: true },
        { text: this.$t('No'), value: false },
        { text: this.$t('Undefined'), value: null }
      ],
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      localMeta: null
    }

  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById']),
    ...mapState('internalRent', ['purposesOfUse']),
    ...mapState('app', ['cleaningAreas']),
    cleaningAreaChoices () {
      return this.cleaningAreas
    },
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    fields () {
      if (this.localMeta === null) {
        return []
      }
      return Object.keys(this.localMeta)
        .map(key => {
          return { ...this.localMeta[key], name: key }
        })
        .filter(v => v.editType !== 'None')
        .filter(v => v.category === this.category)
        .filter(v => v.name !== 'name')
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1
          }
          if (a.order > b.order) {
            return 1
          }
          return 0
        })
    }
  },
  watch: {
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  mounted: async function () {
    this.getPurposes
    await this.getCleaningAreas()
    this.localMeta = JSON.parse(JSON.stringify(this.metadata))
    this.removeFields()
  },
  methods: {
    ...mapActions('internalRent', ['getPurposes']),
    ...mapActions('app', ['getCleaningAreas']),
    onlyNumeric (value) {
      if (value !== '') {
        if (!/^\d*(\.\d{1,2})?$/.test(value)) {
          this.indicatorRunning = true
          this.indicatorMessage = this.$t('Field accepts only numeric values')
          this.operationResult = 'error'
          this.showIndicator = true
        }
      }
    },
    getFieldName: function (field) {
      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)

      if (Object.keys(this.model).indexOf('unit_code') > -1) {
        if (field === 'Id') {
          field = this.$t('Unit') + ' ' + field
        }
      }
      if (Object.keys(this.model).indexOf('rental_type') > -1) {
        if (field === 'Market rent car space') {
          field = field.replace('car', '€/car')
        }
      }
      if (field === 'Area') {
          field = this.$t('Space area')
      } else if (field === 'Cleaning area') {
          field = 'Cleaning layer'
      } else if (field === 'Cleaning area low') {
          field = 'Cleaning layer low'
      }
      return field
    },
    getDefinitionOptionsById (definitionId, isRequired) {
      if (definitionId === 0) {
        return []
      }
      var defs = this.definitionsByGroupId(definitionId).map(d => {
        return {
          id: d.id,
          annotationGroup: d.annotationGroup,
          label: this.$t(d.label)
        }
      })

      if (isRequired === false) {
        defs.unshift({
          id: null,
          annotationGroup: null,
          label: this.$t('undefined')
        })
      }

      return defs
    },
    update () {},
    getRows () {
      if (this.multiline) {
        return 5
      } else {
        return 1
      }
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    removeFields ()
    {
        // leave only one purposezone to reserve space, actual fields inserted on SpacePurposeZones (if zones exist)
        if (this.localMeta.id_purpose_zone1.editType!=='None' || this.localMeta.id_purpose_zone2.editType!=='None' || this.localMeta.id_purpose_zone3.editType!=='None')
        {
          this.localMeta.id_purpose_zone1.editType='Number'
          this.localMeta.id_purpose_zone2.editType='None'
          this.localMeta.id_purpose_zone3.editType='None'
        }
    },
    setPurposes (levels)
    {
      this.model.id_purpose_zone1 = levels.zone1
      this.model.id_purpose_zone2 = levels.zone2
      this.model.id_purpose_zone3 = levels.zone3
    },
    selectablePurposes () {
      let a = this.purposesOfUse.slice().filter(x => x.selectable)
      return a
    }
  }
}
</script>
<style scoped>
.litem {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
</style>