<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ confirmationTitle }}
    </template>
    <template #content>
      <v-row>
        <v-col cols="12">
          <div
            v-if="isLoading"
            class="is-loading"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
          <p class="description font-weight-medium">
            {{ confirmationText }}
          </p>
        </v-col>
      </v-row>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-col
        class="d-flex justify-space-between"
      >
        <CdsButton
          type="tertiary"
          @click="$emit('close')"
        >
          {{ $t('Cancel') }}
        </CdsButton>
        <CdsButton
          v-if="terminationId"
          type="accent"
          :disabled="isLoading"
          @click="cancelTerminateContract"
        >
          {{ $t('contractTermination.cancelTermination') }}
        </CdsButton>
        <CdsButton
          v-else
          :type="hasSentInvoices ? 'danger' : 'accent'"
          :disabled="isLoading"
          @click="terminateContract"
        >
          {{ $t('contractTermination.terminateContract') }}
        </CdsButton>
      </v-col>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../../general/BaseModal.vue'
import CdsButton from '../../../../components/CircleDesignSystem/CdsButton.vue'

export default {
  name: "ContractConfirmTerminationModal",
  components: {
    BaseModal,
    CdsButton,
  },
  props: {
    contractId: {
      type: Number,
      default: undefined
    },
    terminationDate: {
      type: Date,
      default: () => undefined
    },
    terminationId: {
      type: Number,
      default: undefined
    },
  },
  emits: ['close', 'terminateContract', 'cancelTerminateContract'],
  data () {
    return {
      hasSentInvoices: undefined,
      onlyHandAndCreditInvoices: undefined
    }
  },
  computed: {
    isLoading () {
      return typeof(this.hasSentInvoices) === 'undefined'
    },
    confirmationTitle () {
      return this.terminationId ? this.$t('contractTermination.confirmCancelTerminationTitle') : this.$t('contractTermination.terminationOrRescission')
    },
    confirmationText () {
      const hasInvoicesText = this.isLoading ? ''
        : this.hasSentInvoices
          ? 'contractTermination.hasSentInvoices'
          : 'contractTermination.noSentInvoices'
      const onlyHandOrCreditInvoicesText = this.onlyHandAndCreditInvoices
        ? ' ' + this.$t('contractTermination.onlyHandOrCreditInvoices')
        : ''
      return this.terminationId ? this.$t('contractTermination.confirmCancelTerminationText') : this.$t(hasInvoicesText) + onlyHandOrCreditInvoicesText
    },
  },
  mounted () {
    this.getSentInvoices()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async getSentInvoices () {
      const sentInvoices = await this.$rambollfmapi.invoices.getSentInvoices(this.contractId, this.terminationDate)
      this.hasSentInvoices = sentInvoices?.length > 0
      this.onlyHandAndCreditInvoices = sentInvoices?.length > 0 && sentInvoices.filter(i => i.invoiceType === 'Normal')?.length === 0
    },
    terminateContract () {
      this.$emit('terminateContract')
    },
    cancelTerminateContract () {
      this.$emit('cancelTerminateContract')
    },
  }
}
</script>

<style scoped>
.is-loading {
  position: absolute;
  inset: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.description {
  min-height: 3em;
}
</style>