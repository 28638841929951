import RambollFMAPI from '../api/rambollfm.js'
import { i18n } from '../plugins/i18n.js'
import moment from 'moment'
import loglevel from 'loglevel'
import store from '../store/store.js'
import { loadOccupancyRates, loadOccupancyRateProgress } from '../helpers/leasing/occupancyRates.js'
import { endingContractsGraph } from '../helpers/leasing/contracts.js'
import { rentableAreaUsageRate } from '../helpers/leasing/rentableAreaUsageRate.js'
import { getApartmentsForSites } from '../helpers/apartments.js'
import lodash from 'lodash'


function GetUniqueSetByField (existingSet, newSet, field) {
  const currentSet = existingSet.map(b => b[field])
  return newSet.filter(b => {
    return !currentSet.includes(b[field])
  })
}

function GetDefinitionLabelById (rootGetters, key) {
  const def = rootGetters['app/definitionById'](key)
  if (typeof def === 'undefined' || def === null) {
    return i18n.t('Undefined')
  }
  return def.label !== null ? def.label : i18n.t('Undefined')
}

function EmptyUnits (rentalStatus, rentableDefs) {
  return rentalStatus.filter(rs => rentableDefs.includes(rs.unitStatus) && rs.curPartyId === 0)
    .map(rs => {
      var apartmentType = ''
      if (rs.apartment) {
        apartmentType += rs.apartment
      }
      if (rs.kitchen) {
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + rs.kitchen
        } else {
          apartmentType += rs.kitchen
        }
      }
      if (rs.sauna) {
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + i18n.t('short.sauna')
        } else {
          apartmentType += i18n.t('short.sauna')
        }
      }
      if (rs.balcony && rs.balcony !== i18n.t('No')) {
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + rs.balcony
        } else {
          apartmentType += rs.balcony
        }
      }
      if (rs.additional_info_1) {
        const info = rs.additional_info_1
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + info
        } else {
          apartmentType += info
        }
      }
      if (rs.additional_info_2) {
        const info = rs.additional_info_2
        if (apartmentType.length > 0) {
          apartmentType += ' + ' + info
        } else {
          apartmentType += info
        }
      }
      return {
        building_name: rs.buildingName,
        siteId: rs.siteId,
        unitId: rs.unitId,
        unit_name: rs.unitName,
        unit_area: rs.area,
        unit_class: rs.unitUsage,
        tenant: rs.partyName,
        start_date: rs.curPartyStartDate,
        next_tenant_name: rs.nextPartyName,
        next_tenant_start_date: rs.nextPartyStartDate,
        previous_tenant_name: rs.prevPartyName,
        previous_tenant_end_date: rs.prevPartyEndDate,
        siteIdentifier: rs.siteId,
        unitIdentifier: rs.unitId,
        apartment_type: apartmentType
      }
    })
}

function RentalUnits (rentalStatus, rentableDefs) {
  var totalRentableAreaByUnit = 0
  rentalStatus.forEach(s => {
    totalRentableAreaByUnit += s.area
  })
  return rentalStatus.filter(vd => rentableDefs.includes(vd.unitStatus))
    .map(rs => {
      var vatArea = 0
      var notVatArea = 0
      var resp = i18n.t('No')
      if (rs.vat > 0) {
        vatArea = rs.area
        resp = i18n.t('Yes')
      } else {
        notVatArea = rs.area
      }
      return {
        building_name: rs.buildingName,
        siteId: rs.siteId,
        unitId: rs.unitId,
        unit_name: rs.unitName,
        unit_class: rs.unitUsage,
        tenant: rs.curPartyName,
        start_date: rs.curPartyStartDate,
        vat_responsibility: resp,
        unit_area_vat: vatArea,
        unit_area_not_vat: notVatArea,
        total_rentable_area_by_unit: totalRentableAreaByUnit,
        percentage_vat: Number((vatArea / totalRentableAreaByUnit) * 100),
        percentage_not_vat: Number((notVatArea / totalRentableAreaByUnit) * 100),
      }
    })
}

function SpacesWithWorkstations (workstations) {
  return workstations.map(w => {
    w.vacant_workstations = w.tyoPLkm - w.varattuLkm
    w.net_room_area = w.huonala
    w.building_name = w.rakennusNimi
    w.workstation_count = w.tyoPLkm
    w.use = w.kaytto
    w.space_name = w.hnro
    return w
  }).filter(x => x.vacant_workstations !== 0)
}

function UnitAreasByOwnership (rentalStatus, rentalDefs, rentableDefs, notrentableDefs) {
  const estateDefs = rentalDefs.filter(x => x.label.indexOf('koy') > -1).map(x => x.id)
  const constructionDefs = rentalDefs.filter(x => x.label.indexOf('repairs') > -1).map(x => x.id)
  const technicalDef = rentalDefs.find(x => x.label.indexOf('technical') > -1).id
  const publicDef = rentalDefs.find(x => x.label.indexOf('public') > -1).id
  const otherDef = rentalDefs.find(x => x.label.indexOf('other') > -1).id
  const notRentableDef = rentalDefs.find(x => x.label === 'notrentable').id

  return rentalStatus.reduce((acc, cur) => {
    let index = 0
    if (estateDefs.includes(cur.unitStatus)) {
      index = 1
    }
    if (rentableDefs.includes(cur.unitStatus)) {
      acc[index].leasable_area += cur.area
      if (cur.curPartyId > 0) {
        acc[index].leased_area += cur.area
      } else {
        acc[index].free_area += cur.area
      }
    } else if (notrentableDefs.includes(cur.unitStatus)) {
      if (cur.unitStatus === technicalDef) {
        acc[index].technical_area += cur.area
      } else if (cur.unitStatus === publicDef) {
        acc[index].public_area += cur.area
      } else if (cur.unitStatus === notRentableDef) {
        acc[index].notrentable_area += cur.area
      } else if (constructionDefs.includes(cur.unitStatus)) {
        acc[index].construction_area += cur.area
      } else if (cur.unitStatus === otherDef) {
        acc[index].other_area += cur.area
      } else {
        acc[index].unknown_area += cur.area
      }
    } else {
      acc[index].unknown_area += cur.area
    }
    return acc
  }, [{
    owner: 'Osakkeilla omistetut huoneistot',
    leasable_area: 0,
    leased_area: 0,
    free_area: 0,
    other_area: 0,
    technical_area: 0,
    public_area: 0,
    construction_area: 0,
    notrentable_area: 0,
    unknown_area: 0
  }, {
    owner: 'KOY:n omistamat huoneistot',
    leasable_area: 0,
    leased_area: 0,
    free_area: 0,
    other_area: 0,
    technical_area: 0,
    public_area: 0,
    construction_area: 0,
    notrentable_area: 0,
    unknown_area: 0
  }
  ])
}

function LtmpYearOverview (data, site) {
  if (data && data.length > 0) {
    // There should only be data for a single site in the array.
    const sums = data[0].yearlySums
    // We add the area data, so that it can be used later in the widget to
    // divide the sums.
    sums.forEach(sum => sum.area = site.net_floor_area)
    return sums
  } else {
    return []
  }
}

function RentalContracts (rentalStatus) {
  const rc = rentalStatus.reduce((acc, cur) => {
    const key = cur.buildingName + cur.curPartyName + cur.contractNumber
    if (acc[key] === undefined) {
      acc[key] = {
        buildingName: cur.buildingName,
        partyName: cur.curPartyName,
        area: 0,
        unitName: [],
        start_date: cur.curPartyStartDate,
        end_date: cur.curPartyEndDate,
        contractNumber: cur.contractNumber
      }
    }
    acc[key].area += cur.area
    acc[key].unitName.push(cur.unitName)
    return acc
  }, {})
  return Object.keys(rc).map(contracts => {
    const val = rc[contracts]
    return val
  })
}

function UnitVacanciesByUsage (rentalStatus, rentableDefs, notrentableDefs) {
  return Object.values(rentalStatus.reduce((acc, status) => {
    const usage = status.unitUsage
    if (acc[usage] === undefined) {
      acc[usage] = {
        usage: usage,
        totalArea: 0,
        estateArea: 0,
        leasedArea: 0,
        freeArea: 0
      }
    }

    acc[usage].totalArea += status.area
    if (status.curPartyId > 0) {
      acc[usage].leasedArea += status.area
    } else if (notrentableDefs.includes(status.unitStatus)) {
      acc[usage].estateArea += status.area
    } else if (rentableDefs.includes(status.unitStatus)) {
      acc[usage].freeArea += status.area
    }

    return acc
  }, {})).map(rs => {
    return {
      usage: rs.usage === null ? '-' : rs.usage,
      total_area: rs.totalArea,
      estate_area: rs.estateArea,
      leased_area: rs.leasedArea,
      free_area: rs.freeArea,
      vacancy_percentage: 100.00 - Number(
        (rs.leasedArea /
          (rs.totalArea - rs.estateArea)) * 100).toFixed(2),
      total_area_minus_estate_area: rs.totalArea - rs.estateArea,
      trend6m: 0
    }
  })
}

function ElectricityMetersByTenant (electricityMeterAreas) {
  const totalAreaByMeter = electricityMeterAreas.reduce((acc, cur) => {
    if (acc[cur.meter_number] === undefined) {
      acc[cur.meter_number] = 0
    }
    acc[cur.meter_number] += cur.space_area
    return acc
  }, {})

  return electricityMeterAreas.map(meter => {
    return {
      building_name: meter.building_name,
      meter_number: meter.meter_number,
      party_name: meter.party_name,
      total_area: totalAreaByMeter[meter.meter_number],
      area_for_tenant: meter.space_area,
      percentage_for_tenant: meter.space_area / totalAreaByMeter[meter.meter_number] * 100
    }
  })
}

function SpacesByPurposeOfUse (buildingUsages) {
  let wholeSpaceArea = 0
  const spacesPOU = buildingUsages.reduce((acc, cur) => {
    const key = cur.building_code + cur.space_use
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
        building_code: cur.building_code,
        building_name: cur.building_name,
        main_usage: cur.main_usage,
        space_use: cur.space_use,
        area: 0,
        percentage_of_space_area: 0
      }
    }
    acc[key].area += cur.area
    wholeSpaceArea += cur.area
    return acc
  }, {})

  return Object.keys(spacesPOU).map(spaces => {
    if (wholeSpaceArea > 0) {
      spacesPOU[spaces].percentage_of_space_area = spacesPOU[spaces].area / wholeSpaceArea * 100
    }
    const val = spacesPOU[spaces]
    return val
  })
}

function Units (rentalStatus, rentalDefs) {
  return rentalStatus.map(rs => {
    const status = rentalDefs.find(def => def.id === rs.unitStatus)
    rs.signatureDate = rs.signature_date
    rs.unit_status = status && status.label ? i18n.t(status.label) : i18n.t('undefined')
    rs.vat === 1
      ? (rs.vat_status = 'Kyllä')
      : rs.vat === 0
        ? (rs.vat_status = 'Ei')
        : (rs.vat_status = '-')
    rs.huom = rs.huom ? i18n.t('Considerations') : null
    return rs
  })
}

function FloorRentalStatus (rentalStatus, rentableDefs, notrentableDefs, floors) {
  const floorsData = rentalStatus.reduce((acc, cur) => {
    const index = cur.floorId
    const floor = floors.find(f => f.id === cur.floorId)
    if (acc[index] === undefined) {
      acc[index] = {
        building_name: cur.buildingName,
        floor_name: floor.floor_name,
        gross_area: floor.gross_area,
        net_floor_area: 0,
        other_floor_area: 0,
        rentable_floor_area: 0,
        rented_floor_area: 0,
        vacant_floor_area: 0
      }
    }
    acc[index].net_floor_area += cur.area
    if (rentableDefs.find(id => id === cur.unitStatus)) {
      acc[index].rentable_floor_area += cur.area
      acc[index].rented_floor_area += cur.curPartyId !== 0 ? cur.area : 0
      acc[index].vacant_floor_area += cur.curPartyId === 0 ? cur.area : 0
    } else if (notrentableDefs.find(id => id === cur.unitStatus)) {
      acc[index].other_floor_area += cur.area
    }
    acc[index].unit_name = cur.unitName
    return acc
  }, {})

  return Object.keys(floorsData).map(floor => {
    const val = floorsData[floor]
    return val
  })
}

function RentalStatusDescriptions (rentalStatus, notrentableDefs, rentableDefs) {
  const notrentableDefIds = notrentableDefs.map(def => def.id);
  const rentableDefIds = rentableDefs.map(def => def.id)
  // Dates for rental date comparisons
  const threeMonthsToGo = moment()
    .add(3, 'months')
    .calendar()
  const sixMonthsToGo = moment()
    .add(6, 'months')
    .calendar()
  const nineMonthsToGo = moment()
    .add(9, 'months')
    .calendar()
  const twentyFourMonthsToGo = moment()
    .add(24, 'months')
    .calendar()

  // Filter out units with notrentable definition status
  const rentalUnits = rentalStatus.filter(status => !notrentableDefIds.includes(status.unitStatus))

  // Add descriptions for rental status values to be shown in widgets.
  const unitStatuses = rentalUnits.map((val, i) => {
    const accessor = rentalUnits[i]
    if (notrentableDefIds.includes(accessor.unitStatus)) {
      accessor.unit_status = notrentableDefs.find(def => def.id === accessor.unitStatus).label
      accessor.rental_status_desc = 'Non rentable unit'
    } else if (rentableDefIds.includes(accessor.unitStatus)) {
      accessor.unit_status = rentableDefs.find(def => def.id === accessor.unitStatus).label
      if (accessor.curPartyId === 0) {
        if (accessor.nextPartyId > 0) {
          accessor.rental_status_desc = 'Empty, but reserved in future'
        } else {
          accessor.rental_status_desc = 'Empty'
        }
      } else {
        if (accessor.curPartyEndDate !== null) {
          const curPartyEndDate = moment(accessor.curPartyEndDate)
          if (curPartyEndDate.isBefore(threeMonthsToGo)) {
            accessor.rental_status_desc = 'Frees in less than 3 months'
          } else if (curPartyEndDate.isBefore(sixMonthsToGo)) {
            accessor.rental_status_desc = 'Frees in less than 6 months'
          } else if (curPartyEndDate.isBefore(nineMonthsToGo)) {
            accessor.rental_status_desc = 'Frees in less than 9 months'
          } else if (curPartyEndDate.isBefore(twentyFourMonthsToGo)) {
            accessor.rental_status_desc = 'Frees in less than 12 months'
          } else {
            accessor.rental_status_desc = 'Rented units'
          }
        } else {
          accessor.rental_status_desc = 'Rented units'
        }
      }
    }
    return accessor
  })
  return unitStatuses
}

function SharedAreas (vacantSpacesByCostcenters) {
  vacantSpacesByCostcenters.forEach(e => {
    e.shared_area = e.area * e.share / 100
  })
  return vacantSpacesByCostcenters
}

function SitePersonnel (personnelList) {
  return personnelList.map(pl => {
    pl.id = pl.id + pl.room
    return pl
  })
}

function PurposeZonesByBuildings (purposeZones) {

  // We make subitems for lower tier purpose zone levels
  var result = purposeZones.reduce((acc, cur) => {
    const key = cur.building_name + '_' + cur.ktv1
    if (typeof acc[key] === 'undefined') {
      acc[key] = {
      building: cur.building_name,
      purpose_zone_level: 'KTV1',
      purpose_zone_1: cur.ktv1,
      description: cur.ktv1,
      net_floor_area: 0,
      number_of_workstations: 0,
      htv: 0,
      external_person_count: 0,
      net_floor_area_by_htv: 0
      }
    }

    acc[key].net_floor_area += cur.net_floor_area
    acc[key].number_of_workstations += cur.number_of_workstations

    acc[key].showToggle = true
    if (typeof acc[key].subItems === 'undefined') {
      acc[key].subItems = {}
    }
    if (typeof acc[key].subItems[cur.ktv2] === 'undefined') {
      acc[key].subItems[cur.ktv2] = {
        building: cur.building_name,
        purpose_zone_level: 'KTV2',
        purpose_zone_1: cur.ktv1,
        purpose_zone_2: cur.ktv2 !== null ? cur.ktv2 : 'Ei määritelty',
        description: cur.ktv2 !== null ? cur.ktv2 : 'Ei määritelty',
        net_floor_area: 0,
        number_of_workstations: 0,
        htv: 0,
        external_person_count: 0

      }
    }

    acc[key].subItems[cur.ktv2].htv = cur.htv.toFixed(2)
    acc[key].subItems[cur.ktv2].net_floor_area += cur.net_floor_area
    acc[key].subItems[cur.ktv2].number_of_workstations += cur.number_of_workstations
    acc[key].subItems[cur.ktv2].net_floor_area_by_htv = cur.htv > 0 ? acc[key].subItems[cur.ktv2].net_floor_area / cur.htv : null

    acc[key].subItems[cur.ktv2].showToggle = true
    if (typeof acc[key].subItems[cur.ktv2].subItems === 'undefined') {
      acc[key].subItems[cur.ktv2].subItems = {}
    }
    if (typeof acc[key].subItems[cur.ktv2].subItems[cur.ktv3] === 'undefined') {
      acc[key].subItems[cur.ktv2].subItems[cur.ktv3] = {
        building: cur.building_name,
        purpose_zone_level: 'KTV3',
        purpose_zone_1: cur.ktv1,
        purpose_zone_2: cur.ktv2 !== null ? cur.ktv2 : 'Ei määritelty',
        purpose_zone_3: cur.ktv3 !== null ? cur.ktv3 : 'Ei määritelty',
        description: cur.ktv3 !== null ? cur.ktv3 : 'Ei määritelty',
        net_floor_area: 0,
        number_of_workstations: 0,
        htv: 0,
        external_person_count: 0
      }
    }

    acc[key].subItems[cur.ktv2].subItems[cur.ktv3].htv = cur.htv.toFixed(2)
    acc[key].subItems[cur.ktv2].subItems[cur.ktv3].net_floor_area += cur.net_floor_area
    acc[key].subItems[cur.ktv2].subItems[cur.ktv3].number_of_workstations += cur.number_of_workstations
    acc[key].subItems[cur.ktv2].subItems[cur.ktv3].net_floor_area_by_htv = cur.htv > 0 ? acc[key].subItems[cur.ktv2].subItems[cur.ktv3].net_floor_area / cur.htv : null


    return acc
  }, {})

  // Convert items and subitems to arrays
  return Object.keys(result).map(pz1 => {
    if (typeof result[pz1].subItems !== 'undefined') {
      result[pz1].subItems = Object.keys(result[pz1].subItems).map(pz2 => {
        if (typeof result[pz1].subItems[pz2].subItems !== 'undefined') {
          result[pz1].subItems[pz2].subItems = Object.keys(result[pz1].subItems[pz2].subItems).map(pz3 => {
            const pz3Val = result[pz1].subItems[pz2].subItems[pz3]
            return pz3Val
          })
        }
        const pz2Val = result[pz1].subItems[pz2]
        result[pz1].htv += Number(pz2Val.htv ?? 0)
        result[pz1].net_floor_area_by_htv += Number(pz2Val.net_floor_area_by_htv ?? 0)
        return pz2Val
      })
    }
    const val = result[pz1]
    return val
  })
}

function Tenants (report) {
  return report.filter(rs => rs.curPartyId > 0).map(rs => {
    var apartmentType = ''
    if (rs.apartment) {
      apartmentType += rs.apartment
    }
    if (rs.kitchen) {
      if (apartmentType.length > 0) {
        apartmentType += ' + ' + rs.kitchen
      } else {
        apartmentType += rs.kitchen
      }
    }
    if (rs.sauna) {
      if (apartmentType.length > 0) {
        apartmentType += ' + ' + i18n.t('short.sauna')
      } else {
        apartmentType += i18n.t('short.sauna')
      }
    }
    if (rs.balcony && rs.balcony !== i18n.t('No')) {
      if (apartmentType.length > 0) {
        apartmentType += ' + ' + rs.balcony
      } else {
        apartmentType += rs.balcony
      }
    }
    if (rs.additional_info_1) {
      const info = rs.additional_info_1
      if (apartmentType.length > 0) {
        apartmentType += ' + ' + info
      } else {
        apartmentType += info
      }
    }
    if (rs.additional_info_2) {
      const info = rs.additional_info_2
      if (apartmentType.length > 0) {
        apartmentType += ' + ' + info
      } else {
        apartmentType += info
      }
    }
    rs.apartmentType = apartmentType
    return rs
  })
}

function getSiteCapacities (report) {
  let unitList = []
  let combined = []
  // convert capacity to a column
  for (let i = 0 ; i < report.length ; i++)
  {
    let unit = report[i].capacity_unit
    let quantity = report[i].capacity_quantity
    delete report[i].capacity_unit
    report[i][unit] = quantity
    unitList.indexOf(unit) === -1 ? unitList.push(unit) : null
    delete report[i].capacity_quantity
  }
  // combine site capacities to single row
  combined [0] = report [0]
  for (let i = 0 ; i < report.length ; i++)
  {
    var key = Object.keys(report[i])[Object.keys(report[i]).length-1]
    var value = report[i][key]
    if (report[i].organization == combined.at(-1).organization && report[i].buildingIdentifier == combined.at(-1).buildingIdentifier)
    {
      combined.at(-1)[key] = value
    }
    else
    {
      combined.push(report[i])
    }
  }
  return combined
}
function RentalContractsByBuildings (rootGetters, report) {
  return report.map(item => {
    item.contract_type = GetDefinitionLabelById(rootGetters, item.contract_type)
    item.agreement_end_date = item.end_date
    return item
  })
}
function getSiteBreeamInUsePart1 (report) {
  return report.map(siteData => {
    return {
      part1_health_and_wellbeing: siteData.part1_health_and_wellbeing,
      part1_energy: siteData.part1_energy,
      part1_transport: siteData.part1_transport,
      part1_water: siteData.part1_water,
      part1_materials: siteData.part1_materials,
      part1_resources: siteData.part1_resources,
      part1_resilience: siteData.part1_resilience,
      part1_waste: siteData.part1_waste,
      part1_land_use_and_ecology: siteData.part1_land_use_and_ecology,
      part1_pollution: siteData.part1_pollution,
      part1_exemplary: siteData.part1_exemplary,
      part_overall_score: siteData.part1_overall_score,
      part_star_rating: siteData.part1_star_rating,
      certification_date: { text: 'certified_date', value: siteData.certification_date, format: 'Date' },
      certification_expires: { text: 'certification_expires', value: siteData.certification_expires, format: 'Date' },
      certification_version: { text: 'certification_version', value: siteData.certification_version, format: '' }
    }
  })
}

function getSiteBreeamInUsePart2 (report) {
  return report.map(siteData => {
    return {
      part2_management: siteData.part2_management,
      part2_health_and_wellbeing: siteData.part2_health_and_wellbeing,
      part2_energy: siteData.part2_energy,
      part2_water: siteData.part2_water,
      part2_materials: siteData.part2_materials,
      part2_resources: siteData.part2_resources,
      part2_resilience: siteData.part2_resilience,
      part2_land_use_and_ecology: siteData.part2_land_use_and_ecology,
      part2_pollution: siteData.part2_pollution,
      part2_exemplary: siteData.part2_exemplary,
      part_overall_score: siteData.part2_overall_score,
      part_star_rating: siteData.part2_star_rating,
      certification_date: { text: 'certified_date', value: siteData.certification_date, format: 'Date' },
      certification_expires: { text: 'certification_expires', value: siteData.certification_expires, format: 'Date' },
      certification_version: { text: 'certification_version', value: siteData.certification_version, format: '' }
    }
  })
}

function getBuildingDocumentsForCurrentSite (report) {
  if (typeof report !== 'undefined') {
    return report.map(doc => {
      return {
        instance_id: doc.instance_id,
        document_name: doc.document_name,
        file: doc.file,
        engineering_department: doc.engineering_department,
        type: doc.type,
        description: doc.description,
        date: doc.date,
        total_count: doc.total_count,
        building_name: doc.building_name
      }
    })
  } else {
    return null
  }
}

function getUserReports (userWidgets, availableReports) {
  return userWidgets.map(w => w.name).reduce((acc, cur) => {
    if (availableReports[cur]) {
      acc.push(...availableReports[cur])
    }
    return acc
  }, [])
  .filter((val, idx, arr) => arr.indexOf(val) === idx)
}

function getAlerts (report, rootState, rootGetters) {
  const alertUsers = rootState.app.alertUsers
  return report.map(a => ({
    ...a,
    createdByUserName: alertUsers.find(user => user.id === a.createdBy)?.name,
    alertReceiverNames: a.alertReceivers.map(id => alertUsers.find(user => user.id === id)?.name),
    alertCompletedName: alertUsers.find(user => user.id === a.completedBy)?.name,
    alertStatus: {
      id: a.alertStatus,
      label: GetDefinitionLabelById(rootGetters, a.alertStatus),
      color: getStatusColor(a, rootState.app.currentDate, rootGetters)
    }
  }))
}

function getStatusColor (alert, currentDate, rootGetters) {
  const notCompleted = GetDefinitionLabelById(rootGetters, alert.alertStatus) === 'alert.not_completed'
  const ready = GetDefinitionLabelById(rootGetters, alert.alertStatus) === 'alert.completed'

  if (alert.taskDate < moment(currentDate).format('YYYY-MM-DD') && !ready) {
    return 'danger'
  } else if (notCompleted) {
    return 'primary'
  } else if (ready) {
    return 'success'
  }

  return ''
}

function getAllMarketingRows (report) {
  return report.map(m => ({
    id: m.idMarketing,
    id_unit: m.idUnit,
    id_site: m.idSite,
    unit_code: m.unitCode,
    contract_numbers: m.contracts.map(c => ({
      contractNumber: c.sopimusnro,
      isOutRent: c.isOutRent,
      id: c.id,
    })),
    is_enabled: m.isEnabled,
    marketing: m.isEnabled ? 'marketing.switch.on' : 'marketing.switch.off',
    status: m.status,
    status_text: m.status_text,
    rent: m.rent === 0 ? undefined : m.rent,
    description: m.description,
    photos_count: m.photos.length,
    completed_year: m.completedYear,
    usage: m.usage,
    public_listing_url: m.publicListingUrl,
  }))
}

function getAllDiaries (report) {
  return  report.map(d =>({
    id: d.id,
    notification_date: d.notification_date,
    notifier: d.notifier,
    handling_date: d.handling_date,
    author: d.author,
    diary_case: d.diary_case,
    solution: d.solution,
    state: d.state,
    assignee: d.assignee,
  }))
}

function getContractTenants (report) {
  return  report.map(c =>({
    tenant: c.tenant,
    contract_number: c.contract_number,
    building: c.building,
    unit: c.unit,
    structure: c.structure,
    agreed_area: c.agreed_area,
    idContract: c.contract_id,
    outRent: c.is_out_rent,
  }))
}

export default {
  namespaced: true,
  state: {
    currentSiteId: undefined,
    // currentSite: undefined,
    loadedWidgetDataForSite: {},
    siteTabs: [],
    loadedSites: [],
    loadedBuildings: [],
    loadedCemeteries: [],
    loadedStructures: [],
    loadedEstates: [],
    loadedParties: [],
    loadedPartyLinks: [],
    loadedReports: [],
    loadedSpaces: [],
    siteMetadata: null,
    buildingMetadata: null,
    structureMetadata: null,
    estateMetadata: null,
    siteServices: null,
    apiEndpoint: null,
    apiToken: null,
    floorsForCurrentBuilding: [],
    floorsForCurrentSite: [],
    unitsForCurrentBuildingFloor: [],
    spacesForCurrentBuildingFloor: [],
    spaceReportForCurrentSite: [],
    sitePermissions: [],
    currentSiteLinks: [],
    allSitesLinks: [],
    sectionsForCurrentCemetery: [],
    gravesForCurrentSection: [],
    linkedSectionsAndGraves: [],
    isLoadingSiteData: false,
    buildingHierarchyInformations: [],
    customizableDataFields: [],
    siteSpaces: [],
  },
  getters: {
    dataForWidget: (state) => (widgetName, siteId) => {
      if (!Array.isArray(state.loadedWidgetDataForSite[siteId])) {
        return null
      }
      const found = state.loadedWidgetDataForSite.siteId.find(row => row.name === widgetName)

      if (found) {
        return found.data
      }
      return null
    },
    currentSite: (state) => {
      return state.loadedSites.find(s => s.id_site === state.currentSiteId)
    },
    reportForCurrentSite: (state) => (reportName) => {
      if (state.currentSiteId !== undefined) {
        const reports = state.loadedReports.filter(r => r.id_site === state.currentSiteId)
        if (reports.length > 0) {
          return reports[0].reports[reportName]
        }
      }
      return null
    },
    buildingsForCurrentSite: (state) => {
      return state.loadedBuildings.filter(b => b.id_site === state.currentSiteId)
    },
    cemeteriesForCurrentSite: (state) => {
      return state.loadedCemeteries.filter(g => g.idSite === state.currentSiteId)
    },
    structuresForCurrentSite: (state) => {
      return state.loadedStructures.filter(s => s.id_site === state.currentSiteId)
    },
    estatesForCurrentSite: (state) => {
      return state.loadedEstates.filter(e => e.id_site === state.currentSiteId)
    },
    partiesForCurrentSite: (state) => {
      return {
        parties: state.loadedParties.filter(e => e.id_site === state.currentSiteId),
        partyLinks: state.loadedPartyLinks.filter(e => e.id_site === state.currentSiteId)
      }
    },
    rentingForCurrentSite: (state) => {
      const renting = {}
      Object.assign(renting, state.loadedSites.find(s => s.id_site === state.currentSiteId))
      const parkingCompanyDef = store.getters['app/definitionByLabel']('Parkkiyhtiö')
      const parkingCompanyLink = state.loadedPartyLinks.find(
        link => parkingCompanyDef && link.id_site === state.currentSiteId && link.id_annotation === parkingCompanyDef.id
      )
      if (parkingCompanyLink) {
        const parkingCompany = state.loadedParties.find(party => party.id === parkingCompanyLink.id_party)
        if (parkingCompany) {
          renting.parking_company = parkingCompany.name
        }
      }
      return renting
    },
    floorsForCurrentBuilding: (state) => {
      return state.floorsForCurrentBuilding
    },
    floorsForCurrentSite: (state) => {
      return state.floorsForCurrentSite
    },
    unitsForCurrentBuildingFloor: (state) => {
      return state.unitsForCurrentBuildingFloor
    },
    spacesForCurrentBuildingFloor: (state) => {
      return state.spacesForCurrentBuildingFloor
    },
    publicLinkForPhoto: (state) =>  (publicId) => {
      return state.apiEndpoint + 'photos/public/' + publicId + '/'
    },
    sectionsForCurrentCemetery: (state) => {
      return state.sectionsForCurrentCemetery
    },
    linkedSectionsAndGraves: (state) => {
      return state.linkedSectionsAndGraves
    },
    gravesForCurrentSection: (state) => {
      return state.gravesForCurrentSection
    },
    buildingHierarchyInformations: (state) => {
      return state.buildingHierarchyInformations
    },
    siteSpaces: (state) => {
      return state.siteSpaces
    },
    customizableDataFields: (state) => {
      return state.customizableDataFields
    },
    hasWritePermission: (state) => {
      const permission = state.sitePermissions.find(p => Number(p.id) === Number(state.currentSiteId) )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    }
  },
  mutations: {
    addReports (state, reports) {
      let newReports = reports
      // remove existing
      const existing = state.loadedReports.findIndex(r => r.id_site === reports.id_site)
      if (existing >= 0) {
        newReports.reports = {...state.loadedReports[existing].reports, ...newReports.reports}
        state.loadedReports.splice(existing, 1)
      }
      // add new ones
      state.loadedReports.push(newReports)
    },
    addParties (state, { parties, partyLinks }) {
      const newParties = GetUniqueSetByField(state.loadedParties, parties, 'id')
      const newPartyLinks = GetUniqueSetByField(state.loadedPartyLinks, partyLinks, 'id')
      state.loadedParties.push(...newParties)
      state.loadedPartyLinks.push(...newPartyLinks)
    },
    setParties (state, { parties, partyLinks }) {
      state.loadedParties = parties
      state.loadedPartyLinks = partyLinks
    },
    setSpaces (state, { spaces }) {
      state.loadedSpaces = spaces
    },
    addBuildings (state, { buildings }) {
      buildings.forEach(b => {
        const existing = state.loadedBuildings.findIndex(lb => lb.id_building === b.id_building)
        if (existing >= 0) {
          state.loadedBuildings.splice(existing, 1)
        }
      })
      state.loadedBuildings.push(...buildings)
    },
    addCemeteries (state, { cemeteries }) {
      cemeteries.forEach(g => {
        const existing = state.loadedCemeteries.findIndex(lg => lg.id === g.id)
        if (existing >= 0) {
          state.loadedCemeteries.splice(existing, 1)
        }
      })
      state.loadedCemeteries.push(...cemeteries)
    },
    addStructures (state, { structures }) {
      structures.forEach(s => {
        const existing = state.loadedStructures.findIndex(ls => ls.id_structure === s.id_structure)
        if (existing >= 0) {
          state.loadedStructures.splice(existing, 1)
        }
      })
      state.loadedStructures.push(...structures)
    },
    addSite (state, { site }) {
      const existing = state.loadedSites.findIndex(s => s.id_site === site.id_site)
      if (existing >= 0) {
        state.loadedSites.splice(existing, 1)
      }
      state.loadedSites.push(site)
    },
    setSiteTabLocation (state, { siteId, location }) {
      const existing = state.siteTabs.findIndex(s => s.id_site === siteId)
      if (existing < 0) {
        state.siteTabs.push({
          id_site: siteId,
          location: location
        })
      } else {
        state.siteTabs[existing].location = location
      }
    },
    removeSite (state, { siteId }) {
      state.loadedSites = state.loadedSites.filter(s => s.id_site !== siteId)
      state.siteTabs = state.siteTabs.filter(s => s.id_site !== siteId)
    },
    resetBuildings (state) {
      state.loadedBuildings = []
    },
    resetEstates (state) {
      state.loadedEstates = []
    },
    resetStructures (state) {
      state.loadedStructures = []
    },
    addEstates (state, { estates }) {
      estates.forEach(e => {
        const existing = state.loadedEstates.findIndex(le => le.estate_id === e.estate_id)
        if (existing >= 0) {
          state.loadedEstates.splice(existing, 1)
        }
      })
      state.loadedEstates.push(...estates)
    },
    setWidgetData (state, { name, data, siteId }) {
      let names = name
      if (!Array.isArray(name)) {
        names = [name]
      }

      names.forEach(name => {

        if (!Array.isArray(state.loadedWidgetDataForSite[siteId])) {
          state.loadedWidgetDataForSite[siteId] = []
        }

        const existing = state.loadedWidgetDataForSite[siteId].findIndex(r => r.name === name)
        if (existing >= 0) {
          state.loadedWidgetDataForSite[siteId].splice(existing, 1)
        }

        state.loadedWidgetDataForSite[siteId].push({ name: name, data: data })
      })
    },

    setMetadata (state, {
      siteMetadata,
      buildingMetadata,
      structureMetadata,
      estateMetadata
    }) {
      state.siteMetadata = siteMetadata === undefined ? state.siteMetadata : siteMetadata
      state.buildingMetadata = buildingMetadata === undefined ? state.buildingMetadata : buildingMetadata
      state.structureMetadata = structureMetadata === undefined ? state.structureMetadata : structureMetadata
      state.estateMetadata = estateMetadata === undefined ? state.estateMetadata : estateMetadata
    },

    setCurrentSite (state, { siteId }) {
      // state.currentSite = state.loadedSites.find(s => s.id_site === siteId)
      state.currentSiteId = siteId
    },

    setSiteServices (state, { services }) {
      state.siteServices = services
    },

    setApiEndpoint (state, endpoint) {
      state.apiEndpoint = endpoint
    },
    setApiToken (state, token) {
      state.apiToken = token
    },
    setFloorsForCurrentBuilding (state, { floors }) {
      state.floorsForCurrentBuilding = floors
    },
    setFloorsForCurrentSite (state, { floors }) {
      state.floorsForCurrentSite = floors
    },
    setUnitsForCurrentBuildingFloor (state, { units }) {
      state.unitsForCurrentBuildingFloor = units
    },
    setSpacesForCurrentBuildingFloor (state, { spaces }) {
      state.spacesForCurrentBuildingFloor = spaces
    },
    setSitePermissions (state, permissions) {
      state.sitePermissions.push(...permissions)
    },
    setAllSitesLinks (state, siteLinks) {
      state.allSitesLinks.push(...siteLinks)
    },
    removeSiteLinksOfSite (state, siteId) {
      state.allSitesLinks = state.allSitesLinks.filter(sl => sl.siteId !== siteId);
    },
    setCurrentSiteLinks (state, siteLinks) {
      state.currentSiteLinks = siteLinks;
    },
    setSectionsForCurrentCemetery (state, { sections }) {
      state.sectionsForCurrentCemetery = sections
    },
    setLinkedSectionsAndGraves (state, { sectionsAndGraves }) {
      state.linkedSectionsAndGraves = sectionsAndGraves
    },
    setGravesForCurrentSection (state, { gravesbysection }) {
      state.gravesForCurrentSection = gravesbysection
    },
    setisLoadingSiteData (state, isLoadingSiteData) {
      state.isLoadingSiteData = isLoadingSiteData
    },
    setBuildingsHierarchyInformations (state, buildingInfos) {
      state.buildingHierarchyInformations = buildingInfos
    },
    setCustomizableDataFields ( state, fields ) {
      state.customizableDataFields = fields
    },
    setSiteSpaces ( state, spaces ) {
      state.siteSpaces = spaces
    }
  },
  actions: {
    async loadSiteReports ({ commit, rootState, rootGetters, state }, siteId) {
      const perfStart = performance.now()
      siteId = siteId ? siteId : state.currentSiteId

      loglevel.info('Current site id for widgets is ', siteId)

      // We need to know which report is used for which widget
      const availableReports = {
        'site.empty_rental_spaces': ['RentalStatus'],
        'site.rental_units': ['RentalStatus'],
        'site.free_spaces': ['Workstations'],
        'site.tenants': ['AllFutureAndCurrentRentalStatusVersions'],
        'site.unit_areas_by_ownership': ['RentalStatus'],
        'site.carparks': ['GetCarparkStatus'],
        'site.carparksandparkingzones': ['GetCarParksAndParkingZones'],
        'site.rental_contracts': ['RentalStatus'],
        'site.unit_vacancies_by_unit_usage': ['RentalStatus'],
        'site.electricity_meters': ['GetElectricityMetersForSite'],
        'site.spaces_by_purpose_of_use': ['BuildingUsages'],
        'site.vacant_spaces_from_costcenters': ['GetVacantSpacesByCostcenters'],
        'site.cost_areas': ['GetCostCenterAreasBySiteList'],
        'site.cost_areas_personnel': ['GetCostCenterAreasBySiteList'],
        'site.purpose_zone_status': ['GetPurposeZoneSiteStatus'],
        'site.units': ['RentalStatus'],
        'site.floor_areas': ['RentalStatus'],
        'site.purpose_of_use_zones_by_buildings': ['GetPurposeZonesByBuildings'],
        'site.spaces_with_ktv': ['GetSpaceInfoWithKTV'],
        'site.daily_personnel': ['GetDailyPersonnel'],
        'site.pwy_accumulation': ['GetPWYAccumulation'],
        'site.personnel_accumulation': ['GetPersonnelAccumulation'],
        'site.capacities': ['GetSiteCapacities'],
        'site.rental_contracts_by_organization': ['GetRentalContractsByOrganizations'],
        'site.pwy_accumulation_by_building': ['GetPWYAccumulationPerBuilding'],
        'site.personnel_accumulation_by_building': ['GetPersonnelAccumulationPerBuilding'],
        'site.breeam_in_use_part1': ['BreeamInUseCertifications'],
        'site.breeam_in_use_part2': ['BreeamInUseCertifications'],
        'site.apartments': ['GetApartmentsStatuses'],
        'site.marketing': ['GetAllMarketingRows'],
        'site.alerts': ['GetAlerts'],
        'site.diary': ['GetAllDiaries'],
        'site.contract_tenants': ['GetContractTenantsBySiteId'],
        'site.devices': ['GetSiteDevices'],
      }

      const availableProjectWiseReports = {
        'site.building_documents': ['BuildingDocumentsForCurrentSite']
      }

      const requiredGraveyardReports = {
      }

      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const { currentDate } = rootState.app

      const userWidgets = rootState.app.userWidgets.filter(w => w.name.startsWith('site'))
      userWidgets.forEach(w => {
        commit('setWidgetData', {
          name: w.name,
          data: null,
          siteId
        })
      })

      const allReports = getUserReports(userWidgets, availableReports)
      const allProjectWiseReports = getUserReports(userWidgets, availableProjectWiseReports)
      const allGraveyardReports = getUserReports(userWidgets, requiredGraveyardReports)

      const buildingCodes = state.loadedBuildings.filter(b => b.id_site === state.currentSiteId).map(b => b.building_code)
      const buildingIds = state.loadedBuildings.filter(b => b.id_site === siteId).map(b => b.id_building)
      const cemeteries = state.loadedCemeteries.filter(c => c.idSite === state.currentSiteId).map(c => c.id)
      const cemeteryIds = state.loadedCemeteries.filter(c => c.idSite === siteId).map(c => c.id)

      loglevel.info('Required reports are ', availableReports, ' buildings are ', buildingCodes)

      const reports = await api.reports.list(allReports, buildingIds, currentDate, buildingCodes, [siteId])
      const projectWiseReports = await api.reports.projectWise(allProjectWiseReports, buildingIds, currentDate, buildingCodes, [siteId])
      const graveyardReports = await api.graveyards.reports.list(allGraveyardReports, cemeteryIds, currentDate, cemeteries)

      // If a report has null value then there is some problem with the data received frm the API.
      Object.keys(reports).forEach(key => {
        if (reports[key] === null) {
          loglevel.error(`Report with name: ${key} received no data from the API.`)
          store.dispatch('error/addError', 'err.report_no_data')
          reports[key] = []
        }
      })
      const floors = await api.floors.list({ query: { site_id: siteId, time: currentDate } })
      const personnelList = await api.sites.personnel(siteId).list({ query: {time: currentDate }})

      const rentalDefs = this.getters['app/definitionsByGroupLabel']('unit.notrentable')
      const notrentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') > -1).map(x => x.id)
      const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)

      const reportsToCommit = {}

      // report data loaded. commit it to the store
      for (const w of userWidgets) {
        // We do special handling of data here
        try {
          const report = lodash.cloneDeep(reports)
          if (w.name === 'site.empty_rental_spaces') {
            const { RentalStatus } = report
            reportsToCommit.emptyUnits = EmptyUnits(RentalStatus, rentableDefs)
          } else if (w.name === 'site.rental_units') {
            const { RentalStatus } = report
            reportsToCommit.rentalUnits = RentalUnits(RentalStatus, rentableDefs)
          } else if (w.name === 'site.free_spaces') {
            const { Workstations } = report
            reportsToCommit.spacesWithWorkstations = SpacesWithWorkstations(Workstations)
          } else if (w.name === 'site.tenants') {
            const { AllFutureAndCurrentRentalStatusVersions } = report
            reportsToCommit.tenants = Tenants(AllFutureAndCurrentRentalStatusVersions)
          } else if (w.name === 'site.unit_areas_by_ownership') {
            const { RentalStatus } = report
            reportsToCommit.unitAreasByOwnership = UnitAreasByOwnership(RentalStatus, rentalDefs, rentableDefs, notrentableDefs)
          } else if (w.name === 'site.carparks') {
            const { GetCarparkStatus } = report
            reportsToCommit.carparkStatus = GetCarparkStatus
          } else if (w.name === 'site.carparksandparkingzones') {
            const { GetCarParksAndParkingZones } = report
            reportsToCommit.carparksandparkingzones = GetCarParksAndParkingZones
          } else if (w.name === 'site.rental_contracts') {
            const { RentalStatus } = report
            reportsToCommit.rentalContracts = RentalContracts(RentalStatus)
          } else if (w.name === 'site.unit_vacancies_by_unit_usage') {
            const { RentalStatus } = report
            reportsToCommit.unitVacanciesByUsage = UnitVacanciesByUsage(RentalStatus, rentableDefs, notrentableDefs)
          } else if (w.name === 'site.electricity_meters') {
            const { GetElectricityMetersForSite } = report
            reportsToCommit.electricityMetersByTenant = ElectricityMetersByTenant(GetElectricityMetersForSite)
          } else if (w.name === 'site.spaces_by_purpose_of_use') {
            const { BuildingUsages } = report
            reportsToCommit.spacesByPurposeOfUse = SpacesByPurposeOfUse(BuildingUsages)
          } else if (w.name === 'site.personnel') {
            reportsToCommit.sitePersonnel = SitePersonnel(personnelList)
          } else if (w.name === 'site.vacant_spaces_from_costcenters') {
            const { GetVacantSpacesByCostcenters } = report
            reportsToCommit.costcenterVacancies = SharedAreas(GetVacantSpacesByCostcenters)
          } else if (w.name === 'site.cost_areas' || w.name === 'site.cost_areas_personnel') {
            const { GetCostCenterAreasBySiteList } = report
            reportsToCommit.costCenterAreas = GetCostCenterAreasBySiteList
          } else if (w.name === 'site.purpose_zone_status') {
            const { GetPurposeZoneSiteStatus } = report
            reportsToCommit.purposeZoneStatus = GetPurposeZoneSiteStatus
          } else if (w.name === 'site.units') {
            const { RentalStatus } = report
            reportsToCommit.units = Units(RentalStatus, rentalDefs)
          } else if (w.name === 'site.floor_areas') {
            const { RentalStatus } = report
            reportsToCommit.floorRentalStatus = FloorRentalStatus(RentalStatus, rentableDefs, notrentableDefs, floors)
          } else if (w.name === 'site.spaces_with_ktv') {
            const { GetSpaceInfoWithKTV } = report
            reportsToCommit.spacesWithKTV = GetSpaceInfoWithKTV
          } else if (w.name === 'site.purpose_of_use_zones_by_buildings') {
            const { GetPurposeZonesByBuildings } = report
            reportsToCommit.PurposeZonesByBuildings = PurposeZonesByBuildings(GetPurposeZonesByBuildings)
          } else if (w.name === 'site.graveyard.upcoming_burials') {
            const { UpcomingBurialsForSite } = graveyardReports
            reportsToCommit.UpcomingBurialsForSite = UpcomingBurialsForSite
          } else if (w.name === 'site.graveyard.vacant_graves') {
            const { VacantGravesForSite } = graveyardReports
            reportsToCommit.VacantGravesForSite = VacantGravesForSite
          } else if (w.name === 'site.daily_personnel') {
            const { GetDailyPersonnel } = report
            reportsToCommit.DailyPersonnel = GetDailyPersonnel
          } else if (w.name === 'site.pwy_accumulation') {
            const { GetPWYAccumulation } = report
            reportsToCommit.PWYAccumulation = GetPWYAccumulation
          } else if (w.name === 'site.personnel_accumulation') {
            const { GetPersonnelAccumulation } = report
            reportsToCommit.PersonnelAccumulation = GetPersonnelAccumulation
          } else if (w.name === 'site.rental_contracts_by_organization') {
            const { GetRentalContractsByOrganizations } = report
            reportsToCommit.RentalContractsByOrganizations = RentalContractsByBuildings(rootGetters, GetRentalContractsByOrganizations)
          } else if (w.name === 'site.pwy_accumulation_by_building') {
            const { GetPWYAccumulationPerBuilding } = report
            reportsToCommit.PWYAccumulationPerBuilding = GetPWYAccumulationPerBuilding
          } else if (w.name === 'site.personnel_accumulation') {
            const { GetPersonnelAccumulation } = report
            reportsToCommit.PersonnelAccumulation = GetPersonnelAccumulation
          } else if (w.name === 'site.capacities') {
            const { GetSiteCapacities } = report
            reportsToCommit.SiteCapacities = getSiteCapacities(GetSiteCapacities)
          } else if (w.name === 'site.personnel_accumulation_by_building') {
            const { GetPersonnelAccumulationPerBuilding } = report
            reportsToCommit.PersonnelAccumulationPerBuilding = GetPersonnelAccumulationPerBuilding
          } else if (w.name === 'site.breeam_in_use_part1') {
            const { BreeamInUseCertifications } = report
            reportsToCommit.SiteBreeamInUsePart1Certifications = getSiteBreeamInUsePart1(BreeamInUseCertifications)
          } else if (w.name === 'site.breeam_in_use_part2') {
            const { BreeamInUseCertifications } = report
            reportsToCommit.SiteBreeamInUsePart2Certifications = getSiteBreeamInUsePart2(BreeamInUseCertifications)
          } else if (w.name === 'site.building_documents') {
            const { BuildingDocumentsForCurrentSite } = projectWiseReports
            reportsToCommit.BuildingDocumentsForCurrentSite = getBuildingDocumentsForCurrentSite(BuildingDocumentsForCurrentSite)
          } else if (w.name === 'site.apartments') {
            const { GetApartmentsStatuses } = report
            reportsToCommit.GetApartmentsStatuses = getApartmentsForSites(GetApartmentsStatuses)
          } else if (w.name === 'site.alerts') {
            const { GetAlerts } = report
            reportsToCommit.GetAlerts = getAlerts(GetAlerts, rootState, rootGetters)
          } else if (w.name === 'site.marketing') {
            const { GetAllMarketingRows } = report
            reportsToCommit.GetAllMarketingRows = getAllMarketingRows(GetAllMarketingRows)
          } else if (w.name === 'site.diary') {
            const { GetAllDiaries } = report
            reportsToCommit.GetAllDiaries = getAllDiaries(GetAllDiaries)
          } else if (w.name === 'site.contract_tenants') {
            const { GetContractTenantsBySiteId } = report
            reportsToCommit.GetContractTenants = getContractTenants(GetContractTenantsBySiteId)
          } else {
            // See if the widget gets it's data straight from the report and requires no special handling.
            const requiredReportForWidget = availableReports[w.name]
            if (requiredReportForWidget !== undefined && requiredReportForWidget.length > 0) {
              reportsToCommit[requiredReportForWidget] = report[requiredReportForWidget[0]]
            } else {
              const requiredGraveyardReportForWidget = requiredGraveyardReports[w.name]
              if (requiredGraveyardReportForWidget && requiredGraveyardReportForWidget.length > 0) {
                reportsToCommit[requiredGraveyardReportForWidget] = graveyardReports[requiredGraveyardReportForWidget[0]]
              }
            }
          }
        } catch (error) {
          loglevel.error('Error trying to process data for widget: "' + w.name + '"', error)
          store.dispatch('error/addError', 'err.report_data_handling_failed')
        }
      }

      loglevel.info('Reports to commit ', reportsToCommit)

      commit('addReports', {
        reports: reportsToCommit,
        id_site: siteId
      })

      loglevel.debug('Widget data loading took ' + (performance.now() - perfStart) + ' ms.')
    },
    async loadSiteRentalReports ({ commit, rootState, state }, { siteId, widgets }) {
      const perfStart = performance.now()
      if (siteId === undefined || siteId === null) {
        siteId = state.currentSiteId
      }

      // Straight fetch in the widget
      // Services(),                                  : site.services
      // TrafficCommunication(),                      : site.traffic_communication

      // Comes from report
      // EmptyRentalSpaces(),                         : site.empty_rental_spaces
      // UnitMarketRentStatus(),                      : site.unit_market_rent_status
      // CarParksAndParkingZonesWithRent(),           : site.carparksandparkingzoneswithrent

      // Formed in the site store as computed value
      // InformationForRentingFromMFilesCostcenter(), : site.information_for_renting_from_mfiles_costcenter


      // We need to know which report is used for which widget
      const availableReports = {
        'site.empty_rental_spaces': ['RentalStatus'],
        'site.carparksandparkingzoneswithrent': ['GetCarParksAndParkingZonesWithRent'],
        'site.unit_market_rent_status': ['RentalStatus'],
        'leasing.occupancy_rates': ['RentalStatus', 'FutureRentalStatus', 'GetSiteCarparks', 'GetSiteCarparksAreas', 'GetSiteCarparksPrivileges'],
        'site.leasing.ending_contracts_graph': ['RentalStatus', 'FutureRentalStatus', 'FutureOccupancyrates', 'PastRentalStatus']
      }

      const availableLeasingReports = {
        'leasing.occupancy_rates': ['OccupancyRatesTotal'],
        'site.leasing.occupancy_rate_progress': ['OccupancyRateProgress']
      }

      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      const userWidgets = rootState.app.userWidgets

      // Let's get only the widgets that are in the view currently
      const widgetsInView = userWidgets.filter(userWidget => widgets.some(widgetInView => widgetInView.id === userWidget.name))
      widgetsInView.forEach(w => {
        commit('setWidgetData', {
          name: w.name,
          data: null,
          siteId: siteId
        })
      })

      // Fetch the required reports. Only get unique reports
      const allReports = getUserReports(widgetsInView, availableReports)
      const leasingReportNames = getUserReports(widgetsInView, availableLeasingReports)

      const buildingCodes = state.loadedBuildings.filter(b => b.id_site === state.currentSiteId).map(b => b.building_code)
      const buildingIds = state.loadedBuildings.filter(b => b.id_site === siteId).map(b => b.id_building)
      const cemeteries = state.loadedCemeteries.filter(c => c.idSite === state.currentSiteId).map(c => c.id)
      const reports = await api.reports.list(allReports, buildingIds, currentDate, buildingCodes, cemeteries, null)
      let leasingReports = null;

      if (this.getters['app/hasApplicationPermissionByName']('LEASING')) {
        leasingReports = await api.leasing.reports.list(leasingReportNames, buildingIds, [siteId], currentDate);
        Object.keys(leasingReports).forEach(key => {
          if (leasingReports[key] === null) {
            loglevel.error('Report with name: ' + key + ' received no data from the API.')
            store.dispatch('error/addError', 'err.report_no_data')
            leasingReports[key] = []
          }
        })
      }
      else {
        leasingReports = leasingReportNames.reduce((acc,curr) => (acc[curr]=[],acc),{})
      }

      // If a report has null value then there is some problem with the data received frm the API.
      Object.keys(reports).forEach(key => {
        if (reports[key] === null) {
          loglevel.error('Report with name: ' + key + ' received no data from the API.')
          store.dispatch('error/addError', 'err.report_no_data')
          reports[key] = []
        }
      })

      const rentalDefs = this.getters['app/definitionsByGroupLabel']('unit.notrentable')
      const notrentableDefsWoutOther = rentalDefs.filter(x => x.label.indexOf('notrentable') > -1 && x.label.indexOf('other') === -1)
      const rentableDefsWOther = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1 || x.label === 'notrentable.other')
      const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1)

      const reportsToCommit = {}

      // report data loaded. commit it to the store
      for (const w of widgetsInView) {
        // We do special handling of data here
        try {
          if (w.name === 'site.empty_rental_spaces') {
            const { RentalStatus } = reports
            reportsToCommit.emptyUnits = EmptyUnits(RentalStatus, rentableDefs)
          } else if (w.name === 'site.carparksandparkingzoneswithrent') {
            const { GetCarParksAndParkingZonesWithRent } = reports
            reportsToCommit.carparksandparkingzoneswithrent = GetCarParksAndParkingZonesWithRent
          } else if (w.name === 'site.unit_market_rent_status') {
            const { RentalStatus } = reports
            reportsToCommit.rentalStatus = RentalStatusDescriptions(RentalStatus, notrentableDefsWoutOther, rentableDefsWOther)
          } else if (w.name === 'leasing.occupancy_rates'){
            const { RentalStatus, FutureRentalStatus, GetSiteCarparks, GetSiteCarparksAreas, GetSiteCarparksPrivileges } = reports
            const { OccupancyRatesTotal } = leasingReports

            const allCarparkReports = [...GetSiteCarparks, ...GetSiteCarparksAreas, ...GetSiteCarparksPrivileges]

            reportsToCommit.occupancyRates = loadOccupancyRates(
              FutureRentalStatus,
              RentalStatus,
              allCarparkReports,
              [{ id_site: siteId, ownership_status: "Omistuksessa" }],
              rentalDefs,
              currentDate,
              OccupancyRatesTotal
            )

          } else if (w.name === 'site.leasing.ending_contracts_graph') {
            const negotiationDefs = this.getters['app/definitionsByGroupLabel']('contract.negotiation_status')
            const { RentalStatus, FutureRentalStatus, PastRentalStatus, FutureOccupancyrates } = reports
            const rentalProcesses = await api.leasing.rentalProcesses.get(null, null, state.currentSiteId)
            reportsToCommit.endingContractsGraph = endingContractsGraph(RentalStatus, FutureRentalStatus, PastRentalStatus, rentalProcesses, currentDate, null, negotiationDefs)
            reportsToCommit.endingContractsGraphLine = rentableAreaUsageRate(FutureOccupancyrates)
          } else if (w.name === 'site.leasing.occupancy_rate_progress'){
            const { OccupancyRateProgress } = leasingReports
            reportsToCommit.occupancyRateProgress = loadOccupancyRateProgress(OccupancyRateProgress)
          } else {
            // See if the widget gets it's data straight from the report and requires no special handling.
            const requiredReportForWidget = availableReports[w.name]
            if (requiredReportForWidget !== undefined && requiredReportForWidget.length > 0) {
              reportsToCommit[requiredReportForWidget] = reports[requiredReportForWidget[0]]
            }
          }
        } catch (error) {
          loglevel.error('Error trying to process data for widget: "' + w.name + '"', error)
          store.dispatch('error/addError', 'err.report_data_handling_failed')
        }
      }

      loglevel.info('Reports to commit ', reportsToCommit)

      commit('addReports', {
        reports: reportsToCommit,
        id_site: siteId
      })

      loglevel.debug('Widget data loading took ' + (performance.now() - perfStart) + ' ms.')
    },
    async loadSiteAdditionalReports ({ commit, rootState, state }, siteId) {
      if (siteId === undefined || siteId === null) {
        siteId = state.currentSiteId
      }

      // We need to know which report is used for which widget
      let availableReports = {
        'site.estate_construction_projects': ['GetEstateConstructionProjects'],
        'site.damage_insurance_event': ['GetDamageAndInsuranceEvents'],
        'site.esg_energy_adjustment': ['GetEsgEnergyAdjustmentActions'],
        'site.falcony': ['GetAuditsReportsForSite']
      }

      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      const userWidgets = rootState.app.userWidgets.filter(w => w.name.startsWith('site'))
      userWidgets.forEach(w => {
        commit('setWidgetData', {
          name: w.name,
          data: null,
          siteId: siteId
        })
      })

      const allReports = getUserReports(userWidgets, availableReports)
      const buildingCodes = state.loadedBuildings.filter(b => b.id_site === state.currentSiteId).map(b => b.building_code)
      const buildingIds = state.loadedBuildings.filter(b => b.id_site === siteId).map(b => b.id_building)

      const reports = await api.reports.list(allReports, buildingIds, currentDate, buildingCodes)

      // If a report has null value then there is some problem with the data received frm the API.
      Object.keys(reports).forEach(key => {
        if (reports[key] === null) {
          loglevel.error('Report with name: ' + key + ' received no data from the API.')
          store.dispatch('error/addError', 'err.report_no_data')
          reports[key] = []
        }
      })
      const currentSite = this.getters['sites/currentSite']

      // Fimx data should only be fetched if the user has a specific application right,
      // since it always brings data for a single, specific, customer.
      if (this.getters['app/hasApplicationPermissionByName']('FIMX_PTS')) {
        const currentSiteIdentifier = currentSite.site_identifier
        reports.fimx = await api.reports.fimx([currentSiteIdentifier], currentDate)
      }

      const reportsToCommit = {}

      // report data loaded. commit it to the store
      for (const w of userWidgets) {
        // We do special handling of data here
        try {
          if (w.name === 'site.ltmp_year_overview_fimx') {
            const { fimx } = reports
            reportsToCommit.ltmpYearOverview = LtmpYearOverview(fimx, currentSite)
          } else {
            // See if the widget gets it's data straight from the report and requires no special handling.
            const requiredReportForWidget = availableReports[w.name]
            if (requiredReportForWidget !== undefined && requiredReportForWidget.length > 0) {
              reportsToCommit[requiredReportForWidget] = reports[requiredReportForWidget[0]]
            }
          }
        } catch (error) {
          loglevel.error('Error trying to process data for widget: "' + w.name + '"', error)
          store.dispatch('error/addError', 'err.report_data_handling_failed')
        }
      }

      commit('addReports', {
        reports: reportsToCommit,
        id_site: siteId
      })
    },
    async loadSite ({ commit, state, rootState }, siteId) {
      commit('setisLoadingSiteData', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      if (state.loadedBuildings.length > 0) {
        commit('resetBuildings')
      }
      if (state.loadedEstates.length > 0) {
        commit('resetEstates')
      }

      if (state.loadedStructures.length > 0) {
        commit('resetStructures')
      }

      if (state.siteMetadata === null) {
        const siteMetadata = await api.sites.metadata()
        const mainClass = Object.keys(siteMetadata).filter(prop => prop === 'main_class')
        if (mainClass.length === 1) {
          siteMetadata['main_class'].siteClassificationOptions =
            await api.buildings.buildingClassifications(1)
          siteMetadata['main_class'].siteClassificationOptions.forEach(
            sc => {sc.nimi = sc.code + " " + sc.nimi}
          )
        }
        commit('setMetadata', { siteMetadata })
      }

      const site = await api.sites.get(siteId, { query: { time: currentDate } })
      commit('addSite', { site })

      if (state.buildingMetadata === null) {
        const buildingMetadata = await api.buildings.metadata()
        const buildingClassifications = Object.keys(buildingMetadata).
          filter(prop => prop === 'building_class')
        if (buildingClassifications.length === 1) {
          buildingMetadata['building_class'].classificationOptions =
            await api.buildings.buildingClassifications()
          buildingMetadata['building_class'].classificationOptions.forEach(
            bc => {bc.nimi = bc.code + " " + bc.nimi}
          )
        }
        commit('setMetadata', { buildingMetadata })
      }

      const buildings = await api.buildings.list({ query: { siteId, time: currentDate } })
      buildings.forEach(b => {
        b['building_class'] = b['building_class_id']
      })
      commit('addBuildings', { buildings })

      const cemeteries = await api.graveyards.cemeteries.get({ query: { siteId, time: currentDate } })
      commit('addCemeteries', { cemeteries })

      if (state.estateMetadata === null) {
        const estateMetadata = await api.estates.metadata()
        commit('setMetadata', { estateMetadata })
      }

      const estates = await api.estates.list({ query: { siteId, time: currentDate } })
      commit('addEstates', { estates })

      if (state.structureMetadata === null) {
        const structureMetadata = await api.structures.reports().metadata()
        commit('setMetadata', { structureMetadata })
      }

      const structures = await api.structures.list({ query: {siteId, time: currentDate } })
      commit('addStructures', { structures })

      let parties = await api.sites.parties(siteId).list({ query: { siteId, time: currentDate, visibility_status: true } })
      let partyLinks = await api.sites.partyLinks(siteId).list({ query: { siteId, time: currentDate } })
      parties = parties.map(p => { p.id_site = siteId; return p })
      partyLinks = partyLinks.map(p => { p.id_site = siteId; return p })
      commit('setParties', { parties, partyLinks })

      const services = await api.sites.services(siteId).list(currentDate)
      commit('setSiteServices', { services })

      commit('setisLoadingSiteData', false)
    },
    async loadSiteParties ({ commit, state, rootState }, siteId) {
      siteId = siteId ? siteId : state.currentSiteId
      const currentDate = rootState.app.currentDate

      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      let parties = await api.sites.parties(siteId).list({ query: { time: currentDate, visibility_status: true } })
      let partyLinks = await api.sites.partyLinks(siteId).list({ query: { time: currentDate } })
      parties = parties.map(p => { p.id_site = siteId; return p })
      partyLinks = partyLinks.map(p => { p.id_site = siteId; return p })
      commit('setParties', { parties, partyLinks })
    },
    async loadSiteSpaces ({ commit, state, rootState }, siteId) {
      siteId = siteId ? siteId : state.currentSiteId
      const currentDate = rootState.app.currentDate
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const data = await api.spaces.siteSpaces(siteId, {query: { time: currentDate}})
      const customizabledata = await api.customizabledatafields.spaces(data.map(cdf => cdf.id))
      const customizableDataFields = await api.customizabledatafields.get()

      // Kaikkien muokattavien lisäkenttien kenttien arvojen haku jotka ovat käytössä
      const customFields = customizableDataFields.items.filter(cf => cf.enabled == true).map(cf => {
        return {
          text: cf.name,
          id: cf.id,
          values: cf.customizableDataFieldValues
        }
      })

      // Yksittäisen huoneen muokattavien lisäkenttien kentän arvon asetus kyseiselle huoneelle
      data.forEach(row => {
        customizabledata.forEach(cd => {
          if (row.id == cd.idSpace) {
            let customField = customFields.find(cf => cf.id == cd.idField)
            if (customField) {
              let customFieldValue = customField.values.find(cfv => cfv.id == cd.idValue)
              row[customField.text] = customFieldValue.value
            }
          }
        })
      })
      commit('setCustomizableDataFields', customizableDataFields)
      commit('setSiteSpaces', data)
    },
    async loadMetaData ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      if (state.siteMetadata === null) {
        const siteMetadata = await api.sites.metadata()
        commit('setMetadata', { siteMetadata })
      }

      if (state.buildingMetadata === null) {
        const buildingMetadata = await api.buildings.metadata()
        commit('setMetadata', { buildingMetadata })
      }

      if (state.structureMetadata === null) {
        const structureMetadata = await api.structures.reports().metadata()
        commit('setMetadata', { structureMetadata })
      }

      if (state.estateMetadata === null) {
        const estateMetadata = await api.estates.metadata()
        commit('setMetadata', { estateMetadata })
      }
    },
    async updateUnitRentalStatus ({ state, dispatch }, data) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.units.rentalStatus().put(data)
      await dispatch('loadSiteReports', state.currentSiteId)
    },
    async loadFloorsForCurrentBuilding ({ commit, state, rootState }, params ) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate
      const floors = await api.buildings.floors(params.buildingCode).list(currentDate)
      commit('setFloorsForCurrentBuilding', { floors })
    },
    async loadFloorsForCurrentSite ({ commit, state, rootState }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate
      const floors = await api.floors.list({ query: { site_id: params.siteId, time: currentDate } })
      commit('setFloorsForCurrentSite', { floors })
    },
    async loadUnitsForCurrentBuildingFloor ({ commit, state, rootState}, floorId ) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate
      const units = await api.floors.units(floorId).list(currentDate)
      commit('setUnitsForCurrentBuildingFloor', { units })
    },
    async loadSpacesForCurrentBuildingFloor ({ commit, state, rootState}, floorId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate
      const spaces = await api.floors.spaces(floorId).list(currentDate)
      commit('setSpacesForCurrentBuildingFloor', { spaces })
    },
    async getSitePermissions ({commit, state}) {
      if (!state.sitePermissions.find(p => p.id === state.currentSiteId)) {
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const permissions = await api.accounts.permissionsForSite(state.currentSiteId)
        commit('setSitePermissions', permissions)
      }
    },
      async loadSectionsForCurrentCemetery ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const sections = await api.graveyards.cemeteries.sections(params.cemeteryId.id)
      commit('setSectionsForCurrentCemetery', { sections })
    },
    async loadLinkedSectionsAndGraves ({ commit, state }, cemeteryId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const  sectionsAndGraves = await api.graveyards.cemeteries.sectionsAndGraves(cemeteryId)
      commit('setLinkedSectionsAndGraves', { sectionsAndGraves })
    },
    async loadGravesForCurrentSection ({ commit, state }, sectionId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const gravesbysection = await api.graveyards.cemeteries.gravesbysection(sectionId)
      commit('setGravesForCurrentSection', { gravesbysection })
    },
    async getSiteLinks ({ commit, state }, params) {
      if (params.forceUpdate || !state.allSitesLinks.find(sl => sl.siteId === state.currentSiteId)) {
        const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
        const siteLinks = await api.siteLinks.list(state.currentSiteId);
        if (params.forceUpdate)
           commit('removeSiteLinksOfSite', state.currentSiteId);
        commit('setAllSitesLinks', siteLinks)
      }
      const currentSiteLinks = state.allSitesLinks.filter(sl => sl.siteId === params.siteId);
      commit('setCurrentSiteLinks', currentSiteLinks);
    },
    async saveNewSiteLink ({ state }, siteLink) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      siteLink = {
        ...siteLink,
        "siteId": state.currentSiteId
      }
      await api.siteLinks.create(state.currentSiteId, siteLink)
    },
    async loadBuildingsHierarchyInformations ({ commit, state, rootState }, buildingIds) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const buildingsHierarchy = await api.buildings.hierarchyInfo(rootState.app.currentDate, buildingIds)
      commit('setBuildingsHierarchyInformations', buildingsHierarchy);
    }
  }
}
