<template>
  <v-expansion-panels
    v-if="carpark"
    class="my-2"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
        >
          <div
            style="text-align: right"
          >
            {{ $t('Basic information') }}
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="getMetadataForCarPark(carpark)"
          :value="getDataForCarpark(carpark)"
          @openContract="openContract"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'
import MetadataForm from '../../../components/MetadataForm.vue'

export default {
  name: 'CarparkBasicInfo',
  components: {
    MetadataForm
  },
  props: {
    carpark: { type: Object, default: null },
    tenant: { type: Object, default: null },
    buildingCode: { type: String, default: null },
    buildingCarparksSummary: { type: Object, default: null },
  },
  emits: ['openContract'],
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'hasApplicationPermissionByName']),
  },
  methods: {
    getTypeName (type) {

      const typeDefinitions = this.definitionsByGroupLabel('carpark.type')

      const typeDef = typeDefinitions.find(def => def.id === type)

      return typeDef ? typeDef.label : '-'
    },
    getRenter (renter) {

      const renterDefinitions = this.definitionsByGroupLabel('carpark.renter')

      const renterDef = renterDefinitions.find(def => def.id === renter)

      return renterDef ? renterDef.label : '-'

    },
    getDataForCarpark (carpark) {

      const renter = this.getRenter(carpark.renter)
      const type = this.getTypeName(carpark.type)

      if (carpark.rental_type === 'Single') {
        return {
          carpark_code_long: carpark.carpark_code_long,
          nro: carpark.nro,
          area: carpark.area,
          renter,
          type,
          contract_identifiers: carpark.contract_identifiers,
        }
      }

      if (carpark.rental_type === 'AreaRent') {
        return {
          carpark_code_long: carpark.carpark_code_long,
          nro: carpark.nro,
          renter,
          type,
          amount: carpark.amount,
          contract_identifiers: carpark.contract_identifiers,
        }
      }

      if (carpark.rental_type === 'Privilege') {
        return {
          carpark_code_long: carpark.carpark_code_long,
          nro: carpark.nro,
          renter,
          type,
          building: this.buildingCode,
          building_carparks_total: this.buildingCarparksSummary?.building_carparks_total,
          building_parking_rights_total: this.buildingCarparksSummary?.building_parking_rights_total,
          building_parking_rights_coefficient: this.buildingCarparksSummary?.building_parking_rights_coefficient,
          building_parking_rights_issued: this.buildingCarparksSummary?.building_parking_rights_issued,
          building_parking_rights_remaining: this.buildingCarparksSummary?.building_parking_rights_remaining,
          contract_identifiers: carpark.contract_identifiers,
        }
      }

      return {}
    },
    getMetadataForCarPark (carpark) {
      if (carpark.rental_type === 'Single') {
        return {
          carpark_code_long: { isShown: true, category: 'None' },
          nro: { isShown: true, category: 'None' },
          area: { isShown: true, category: 'None', format: 'Area' },
          renter: { isShown: true, category: 'None' },
          type: { isShown: true, category: 'None' },
          contract_identifiers: { isShown: this.hasContractInrentOrOutrentPermission(), category: 'None', type: 'Array' },
        }
      }
      if (carpark.rental_type === 'AreaRent') {
        return {
          carpark_code_long: { isShown: true, category: 'None' },
          nro: { isShown: true, category: 'None' },
          renter: { isShown: true, category: 'None' },
          type: { isShown: true, category: 'None' },
          contract_identifiers: { isShown: this.hasContractInrentOrOutrentPermission(), category: 'None', type: 'Array' },
          amount: { isShown: true, category: 'None' },
        }
      }
      if (carpark.rental_type === 'Privilege') {
        return {
          carpark_code_long: { isShown: true, category: 'None' },
          nro: { isShown: true, category: 'None' },
          renter: { isShown: true, category: 'None' },
          type: { isShown: true, category: 'None' },
          contract_identifiers: { isShown: this.hasContractInrentOrOutrentPermission(), category: 'None', type: 'Array' },
          building: { isShown: true, category: 'None' },
          building_carparks_total: { isShown: true, category: 'None', format: 'Amount' },
          building_parking_rights_total: { isShown: true, category: 'None', format: 'Amount' },
          building_parking_rights_coefficient: { isShown: true, category: 'None', format: 'Percentage' },
          building_parking_rights_issued: { isShown: true, category: 'None', format: 'Amount' },
          building_parking_rights_remaining: { isShown: true, category: 'None', format: 'Amount' },
        }
      }

      return {}
    },
    openContract (contractNumber) {
      this.$emit('openContract', contractNumber)
    },
    hasContractInrentOrOutrentPermission () {
      return this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') || this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS') ? true : false
    },
  }
}
</script>
<style scoped>
</style>
