<template>
  <div style="position: relative">
    <VerticalBarChart 
      v-if="GraphData !== null"
      class="graph"
      :data="GraphData"
      :options="barOptions"
      style="height: 340px"
    />
  </div>
</template>
<script>
import helpers from '../../helpers'
import VerticalBarChart from '../VerticalBarChart.vue'
import { mapState } from 'vuex'
export default {
 components: {
    VerticalBarChart
 },
 props:{
    data: {
      type: Object,
      default: null
    }
 },
 data () {
     const vm = this
     return {
         currentSet: null,
         budgetData: [],
         realizationData: [],
         barOptions: {
            title: {
                display: true,
                text: this.$t('Cost types'),
                position: 'bottom'
            },
            aspectRatio: 3,
            maintainAspectRatio: false,
            responsive: true,
            legend: {
                display: true,
                align: 'middle',
                position: 'top'
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].label + ' ' + vm.inspectionYear + 
                        ' : ' + helpers.humanize.thousand_separator(tooltipItem.value) + ' €'
                    }
                }
            },
            scales: {
                xAxes: {
                    ticks: {
                        display: false
                    }
                },
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#8b8b8b',
                            callback: function (value, index, values) {
                                return helpers.humanize.thousand_separator(value) + '€'
                            }
                        }
                    }
                ]
            }
         }
     }
 },
 computed: {
     ...mapState('internalRent', ['inspectionYear']),
     setLabels: function () {
         let labels = []
         if (Array.isArray(this.data.items)) {
             this.data.items.forEach(item => {
            if (item.thisYearBudget > 0 || item.thisYearRealisation > 0) {
                labels.push(item.name)
            }           
         })
         return [...new Set(labels)]
         }
         return labels
     },
     sets: function () {
         if (this.data.sets.length === 0) {
          return []
        }
        
        return this.data.sets.filter(set => {
            const param = set.parameters.length > 0 ? set.parameters[0] : null

            // count is an exception as it does not require parameters
            if (param === null && set.method === 'count') {
            return true
            }

            const data = this.data.items.length > 0 ? this.data.items[0] : null

            if (data === null || typeof data[param] === 'undefined') {
            return false
            }
            return true
        })
     },
     objects: function () {
         let result = {}
         if (Array.isArray(this.data.items)) {
             const groupedData = this.data.items.reduce((acc, cur) => {
             const key = cur['name']
             if (typeof key === 'undefined' || key === null) {
              return acc
             }

             if (typeof acc[key] === 'undefined') {
              acc[key] = []
             }

             acc[key].push(cur)

                return acc
            }, {})

            const items = Object.keys(groupedData).reduce((acc, key) => {
                const set = groupedData[key]
                if (set.length > 1) {
                    const paramVal = []
                    paramVal.push([set[0]['thisYearBudget'], set[1]['thisYearRealisation']])
                    acc[key] = paramVal
                }
                else if (set.length === 1) {
                    const paramVal = set.map(s => {
                        const values = []
                        values.push(s['thisYearBudget'], s['thisYearRealisation'])
                        return values
                    })
                    acc[key] = paramVal
                }

                return acc
            }, {})

            result = items
         }
         

         return result
     },
     GraphData: function () {
         return {
             labels: this.setLabels,
             datasets: this.getCostTypeSets()
         }
     }
 },
 methods: {
     getCostTypeSets () {
      const sets = []

      this.setLabels.forEach((l, idx )=> {
            Object.values(this.objects[l]).forEach(arr => {
                this.budgetData.push(arr[0])
                this.realizationData.push(arr[1])
            })
            
        })

        const budgets = {
                label: this.$t('Budget'),
                backgroundColor: this.getThemeColor(0),
                borderColor: 'white',
                data: this.budgetData,
                order: 2
            }
            const realizations = {
                label: this.$t('internalrent.realisation'),
                type: 'line',
                showLine: false,
                fill: true,
                pointBackgroundColor: this.getColorArray(),
                borderColor: this.getThemeColor(0 + this.setLabels.length),
                data: this.realizationData,
                pointRadius: 6,
                order: 1
            }

            sets.push(budgets)
            sets.push(realizations)

      return sets
    },
     getThemeColor (index) {
      return `hsl(${177 + 27 * index}, 82%, 72%)`
     },
     getColorArray () {
         let arr = []

         for (var i = 0; i < this.setLabels.length; i++) {
             arr.push(this.getThemeColor(0 + this.setLabels.length))
         }

         return arr
     }

 }
}
</script>