<template>
  <v-container>
    <v-row
      class="section fill-height mt-4"
    >
      <v-col>
        <h4 class="text--secondary">
          {{ $t('Project information') }}
        </h4>
      </v-col>
    </v-row>
    <v-row
      class="section fill-height mb-4"
    >
      <v-col cols="12">
        <v-text-field
          v-model="persons.responsibility"
          :label="$t('Responsibility')"
          :rules="[rules.projectPerson]"
          :readonly="task.finished"
          @change="$emit('personsChange', persons)"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="persons.designer"
          :label="$t('Designer')"
          :rules="[rules.projectPerson]"
          :readonly="task.finished"
          @change="$emit('personsChange', persons)"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="persons.builder"
          :label="$t('Project manager')"
          :rules="[rules.projectPerson]"
          :readonly="task.finished"
          @change="$emit('personsChange', persons)"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="persons.supervisor"
          :label="$t('Supervisor')"
          :rules="[rules.projectPerson]"
          :readonly="task.finished"
          @change="$emit('personsChange', persons)"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="persons.contractor"
          :label="$t('Contractor')"
          :rules="[rules.projectPerson]"
          :readonly="task.finished"
          @change="$emit('personsChange', persons)"
        />
      </v-col>
    </v-row>
    <v-spacer />
    <v-divider />
    <v-spacer />
  </v-container>
</template>
<script>
export default {
  emits: ['personsChange'],
  name: 'TaskProjectInformation',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    'task': Object,
    // eslint-disable-next-line vue/require-default-prop
    'rules': Object,
  },
  data () {
    return {
      persons: {
        designer: this.task.designer,
        builder: this.task.builder,
        supervisor: this.task.supervisor,
        contractor: this.task.contractor,
        responsibility: this.task.responsibility
      }
    }
  }
}
</script>
<style scoped>
.header {
  background: var(--c-color-accent) !important;
  color: #8b8b8b;
  padding-left: 0.5em;
  font-size: 1.5em;
}
.section-devider {
  padding-top: 2em;
  padding-bottom: 1em;
}
.grey-text {
  color: #8b8b8b;
  padding-left: 1em;
  padding-top: 0.5em;
}
.title-card {
  background: #d5e9f6;
}
</style>
