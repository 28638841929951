<template>
  <BaseModal
    @cancel="cancel"
  >
    <template #title>
      <div
        v-if="mode === 'new'"
      >
        {{ $t('Create document') }}
      </div>
      <div
        v-if="mode === 'update'"
      >
        {{ $t('Update document') }}
      </div>
      <div
        v-if="mode === 'version'"
      >
        {{ $t('Create document version') }}
      </div>
      <div
        v-if="mode === 'multiple'"
      >
        {{ $t('Document.AddMultipleDocuments') }}
      </div>
    </template>
    <template #content>
      <v-card
        v-if="isUploading || hasError || hasSuccess"
        style="align-items: center; justify-content: center"
        elevation="0"
      >
        <div
          v-show="isUploading"
          style="text-align: center; padding-bottom: 20px;"
        >
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="uploadProgressPercentage"
            :indeterminate="uploadProgressPercentage === 100"
            color="primary"
          >
            {{ uploadProgressPercentage }}
          </v-progress-circular>
        </div>
        <v-alert
          v-for="message in successMessages"
          :key="message.id"
          :value="hasSuccess"
          type="success"
          dismissible
        >
          {{ message.info }}
        </v-alert>
        <v-alert
          v-for="message in errorMessages"
          :key="message.id"
          :value="hasError"
          type="error"
          dismissible
        >
          {{ message.info }}
        </v-alert>
      </v-card>
      <v-card
        v-if="!isUploading"
        v-show="mode === 'new' || mode === 'update' || mode === 'multiple'"
        elevation="0"
      >
        <!-- Upload form -->
        <div v-if="mode">
          <input
            v-if="uploadDialog"
            ref="fileInput"
            type="file"
            style="display: none"
            accept="*"
            @change="onFilePicked"
          >
          <input
            v-if="uploadDialog"
            ref="filesInput"
            type="file"
            multiple="multiple"
            style="display: none"
            accept="*"
            @change="onFilesPicked"
          >
          <div v-show="mode === 'multiple'">
            <v-text-field
              class="form-field"
              :label="$t('Document.SelectMultipleFiles')"
              :value="rawFileName"
              required
              :placeholder="$t('Document.SelectMultipleFilesPlaceholder')"
              @click="onPickFiles"
            />
          </div>
          <div v-show="mode === 'new' || mode === 'update' ">
            <v-text-field
              v-model="newDocument.title"
              required
              :label="$t('Document name')"
              :hint="$t('Required')"
              persistent-hint
            />
          </div>
          <br>

          <div v-show="mode === 'new' || mode === 'update' || mode === 'multiple'">
            <v-textarea
              v-model="newDocument.description"
              :label="mode === 'multiple' ? $t('Document.DocumentsDescription') : $t('Document description')"
              :placeholder="mode === 'multiple' ? $t('Document.DocumentsDescriptionPlaceholder') : $t('Description of the document content')"
              hide-details
              class="mt-4"
            />
          </div>
          <br>

          <div v-show="mode === 'update' && newDocument.type === 'Facade Image'">
            <v-checkbox
              v-model="isFacadeImage"
              :label="'Kuva on osa kohdesivun karusellia'"
            />
          </div>

          <div v-show="mode === 'new' || mode === 'update' || mode === 'multiple'">
            <v-text-field
              v-model="newDocument.type"
              :readonly="type !== undefined && type !== ''"
              :label="mode === 'multiple' ? $t('Document.DocumentsClass') : $t('Document type')"
              :placeholder="$t('E.g. HVAC, Security')"
            />
          </div>
          <br>
          <div v-show="mode === 'new' || mode === 'update' || mode === 'multiple'">
            <v-combobox
              v-model="rawTags"
              multiple
              chips
              :label="mode === 'multiple' ? $t('Document.DocumentsTags') : $t('Tags')"
              deletable-chips
              :placeholder="$t('Tags')"
              :hide-details="true"
              item-text="name"
              item-value="name"
              return-object
              :items="availableTags"
            />
          </div>
          <br>
          <div v-show="mode === 'new' || mode === 'update' || mode === 'multiple'">
            <v-text-field
              v-model="newDocument.created"
              class="form-field"
              :label="$t('Document.dateOfCreation')"
              type="date"
              :rules="[rules.validCreatedDate]"
              clearable
            />
            <v-text-field
              v-model="newDocument.expires"
              class="form-field"
              :label="$t('Document.expiryDate')"
              type="date"
              :rules="[rules.validExpiresDate]"
              clearable
            />
          </div>
          <br>
        </div>
      </v-card>
      <v-divider v-if="!isUploading && mode !== 'version'" />
      <v-card
        v-if="!isUploading"
        v-show="mode === 'new' || mode === 'version'"
        elevation="0"
      >
        <div v-show="mode !== 'update'">
          <v-text-field
            class="form-field"
            :label="$t('File to be sent')"
            :value="rawFileName"
            required
            :placeholder="$t('Click here to select file from disk')"
            @click="onPickFile"
          />
        </div>
        <br>
        <div v-show="mode === 'version'">
          <v-textarea
            v-model="newDocument.versionDescription"
            :label="$t('Version changes')"
            required
            :placeholder="$t('Version changes')"
          />
        </div>
      </v-card>
      <v-divider
        v-if="!isUploading"
        v-show="mode === 'new' || mode === 'update'"
      />
      <v-card
        v-if="!isUploading && sites.length > 0"
        v-show="mode === 'new' || mode === 'update' || mode === 'multiple'"
        elevation="0"
      >
        <v-select
          v-if="cemeteriesForCurrentSite.length > 0"
          v-model="selectedOption"
          class="form-field"
          :items="[$t('Site'),$t('Cemetery')]"
          :label="$t('Site')+'/'+$t('Cemetery')"
        />
        <div v-if="selectedOption === $t('Site')">
          <v-text-field
            v-if="mode === 'new' || mode === 'update' || mode === 'multiple'"
            :value="site.name"
            readonly
            required
            class="form-field"
            :label="$t('Site')"
          />
          <br>
          <v-select
            v-model="newDocument.idBuilding"
            :disabled="buildingsForCurrentSite.length === 0"
            :label="cemeteriesForCurrentSite.length > 0 && selectedOption === $t('Site') || cemeteriesForCurrentSite.length === 0 ? $t('Building') : $t('Building') + '/' + $t('Cemetery')"
            hide-details
            clearable
            item-text="building_name"
            item-value="id_building"
            :items="buildingsForCurrentSite"
          />
          <v-select
            v-model="newDocument.idFloor"
            :disabled="!newDocument.idBuilding || floorsForCurrentBuilding === undefined || floorsForCurrentBuilding.length === 0"
            clearable
            class="form-field"
            :label="$t('Floor')"
            hide-details
            item-text="floor_name"
            item-value="id"
            :items="floorsForCurrentBuilding"
          />
          <v-select
            v-model="newDocument.idUnit"
            :disabled="!newDocument.idFloor || unitsForCurrentBuildingFloor === undefined || unitsForCurrentBuildingFloor && unitsForCurrentBuildingFloor.length === 0"
            clearable
            class="form-field"
            :label="$t('Unit')"
            hide-details
            item-text="unit_code"
            item-value="id"
            :items="unitsForCurrentBuildingFloor"
          />
          <v-select
            v-model="newDocument.idSpace"
            :disabled="!newDocument.idFloor || spacesForCurrentBuildingFloor === undefined && spacesForCurrentBuildingFloor && spacesForCurrentBuildingFloor.length === 0"
            clearable
            class="form-field"
            :label="$t('Space')"
            hide-details
            item-text="name"
            item-value="id"
            :items="newDocument.idFloor ? spacesForCurrentBuildingFloor : spacesForSelectedUnit"
          />
        </div>
        <div v-if="selectedOption === $t('Cemetery')">
          <v-select
            v-model="newDocument.idCemetery"
            :disabled="cemeteriesForCurrentSite.length === 0"
            :label="$t('Cemetery')"
            clearable
            class="form-field"
            item-text="name"
            item-value="id"
            :items="cemeteriesForCurrentSite"
          />
          <v-select
            v-model="newDocument.idSection"
            :disabled="cemeteriesForCurrentSite.length === 0"
            clearable
            class="form-field"
            :label="$t('Graveyard section')"
            hide-details
            item-text="name"
            item-value="id"
            :items="sectionsForCurrentCemetery"
          />
          <v-select
            v-model="newDocument.idGrave"
            :disabled="cemeteriesForCurrentSite.length === 0"
            clearable
            class="form-field"
            :label="$t('Grave')"
            hide-details
            item-text="graveIdentifier"
            item-value="id"
            :items="gravesForCurrentSection"
          />
          <br>
        </div>
      </v-card>
    </template>
    <template #footer>
      <v-btn
        :disabled="isUploading || hasFileValidatingError"
        text
        rounded
        class="primary"
        @click="uploadFile"
      >
        {{ buttonText }}
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from './general/BaseModal.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import moment from 'moment'
import document from '../helpers/document.js'

export default {
  name: 'FileUploadForm',
  components: { BaseModal },
  props: {
    site: { type: Object, default: null },
    sites: { type: Array, default: () => [] },
    document: { type: Object, default: null },
    type: { type: String, default: '' },
    mode: { type: String, default: 'new' },
  },
  emits: ['cancel', 'created', 'updated'],
  data () {
    return {
      uploadDialog: true,
      rawFile: '',
      rawFiles: [],
      rawTags: [],
      newDocument: {
        idBuilding: null,
        fileName: '',
        title: '',
        description: '',
        type: '',
        versionDescription: '',
        tags: [],
        created: null,
        expires: null,
        idCemetery: null,
        idSection: null,
        idGrave: null,
        idFloor: null,
        idUnit: null,
        idSpace: null
      },
      isUploading: false,
      uploadProgress: null,
      uploadProgressPercentage: 0,
      hasError: false,
      hasSuccess: false,
      hasFileValidatingError: false,
      isFacadeImage: true,
      successMessages: [],
      errorMessages: [],
      selectedOption: null,
      initEditing: false,
      rules: {
        validCreatedDate: createdDate => {
          if (this.newDocument.expires && createdDate && this.newDocument.expires < createdDate)  {
            return this.$t('Document.createdValidationError')
          } else {
            return true
          }
        },
        validExpiresDate: expiresDate => {
          if (this.newDocument.created && expiresDate && this.newDocument.created > expiresDate) {
            return this.$t('Document.expiresValidationError')
          } else {
            return true
          }
        }
      },
      spacesForSelectedUnit: []
    }
  },
  computed: {
      ...mapState('app', ['currentDate']),
      ...mapGetters('sites', ['currentSite', 'cemeteriesForCurrentSite', 'sectionsForCurrentCemetery', 'gravesForCurrentSection', 'buildingsForCurrentSite', 'floorsForCurrentBuilding', 'unitsForCurrentBuildingFloor', 'spacesForCurrentBuildingFloor']),
    availableTags () {
      return this.$store.state.app.tags
    },
    rawFileName () {
      if (this.rawFile !== null && this.rawFiles.length === 0) {
        return this.rawFile.name
      } else if (this.rawFiles.length > 0) {
        const rawFilesLength = this.rawFiles.length
        let joinedNames = ''
        this.rawFiles.forEach(function (file, index) {
          const string = '"' + file.name + '"'
          joinedNames += string
          if (index < rawFilesLength-1) {
            joinedNames += ", "
          }
        })
        return joinedNames
      } else {
        return ''
      }
    },
    buttonText () {
      if (this.mode === 'update') {
        return this.$t('Save')
      } else {
        return this.$t('Send')
      }
    }
  },
  watch: {
    type: function (type) {
      this.newDocument.type = type
    },
    document: function (newDoc) {
      if (typeof this.document !== 'undefined' && this.document !== null) {
        this.newDocument = JSON.parse(JSON.stringify(newDoc))
        this.rawTags = newDoc.tags ? this.document.tags : []
      }
    },
    'newDocument.idCemetery': async function (selectedCemeteryId) {
      if (selectedCemeteryId && !this.initEditing) {
        this.newDocument.idSection = null
        this.newDocument.idGrave = null
        this.setSectionsForCurrentCemetery([])
        this.setGravesForCurrentSection([])
        const cemeteryId = this.cemeteriesForCurrentSite.find(cemetery => cemetery.id === selectedCemeteryId)
        const params = { time: this.currentDate, cemeteryId: cemeteryId }
        await this.loadSectionsForCurrentCemetery(params)
      } else if (!selectedCemeteryId) {
        this.newDocument.idSection = null
        this.newDocument.idGrave = null
      }
    },
    'newDocument.idSection': async function (selectedSectionId) {
      if (selectedSectionId && !this.initEditing) {
        this.newDocument.idGrave = null
        this.setGravesForCurrentSection([])
        await this.loadGravesForCurrentSection(selectedSectionId)
      } else if (!selectedSectionId) {
        this.newDocument.idGrave = null
        this.setGravesForCurrentSection([])
      }
    },
    'newDocument.idGrave': function (selectedGraveId) {
      if (selectedGraveId && !this.initEditing) {
        this.gravesForCurrentSection.find(section => section.id === selectedGraveId)
      }
    },
    'newDocument.idBuilding': async function (selectedBuildingId) {
      if (selectedBuildingId && !this.initEditing) {
        this.newDocument.idFloor = null
        this.newDocument.idUnit = null
        this.newDocument.idSpace = null
        this.setFloorsForCurrentBuilding([])
        this.setUnitsForCurrentBuildingFloor([])
        this.setSpacesForCurrentBuildingFloor([])

        const buildingCode = this.buildingsForCurrentSite.find(building => building.id_building === selectedBuildingId).building_code
        const params = { time: this.currentDate, buildingCode: buildingCode }
        await this.loadFloorsForCurrentBuilding(params)
      } else if (!selectedBuildingId) {
        this.newDocument.idFloor = null
        this.newDocument.idUnit = null
        this.newDocument.idSpace = null
        this.setFloorsForCurrentBuilding([])
        this.setUnitsForCurrentBuildingFloor([])
        this.setSpacesForCurrentBuildingFloor([])
      }
    },
    'newDocument.idFloor': async function (selectedFloorId) {
      if(!selectedFloorId) {
        this.newDocument.idUnit = null
        this.newDocument.idSpace = null
        this.setUnitsForCurrentBuildingFloor([])
        this.setSpacesForCurrentBuildingFloor([])
      } else if (selectedFloorId && !this.initEditing) {
        this.newDocument.idUnit = null
        this.newDocument.idSpace = null
        this.setUnitsForCurrentBuildingFloor([])
        this.setSpacesForCurrentBuildingFloor([])
        await this.loadUnitsForCurrentBuildingFloor(selectedFloorId)
        await this.loadSpacesForCurrentBuildingFloor(selectedFloorId)
      }
    },
    'newDocument.idUnit': async function (selectedUnitId) {
      if (!selectedUnitId) {
        this.newDocument.idSpace = null
      } else if (selectedUnitId && !this.initEditing) {
        this.newDocument.idSpace = null
        this.spacesForSelectedUnit = this.spacesForCurrentBuildingFloor.filter(s => s.id_unit === selectedUnitId)
      }
    },
    selectedOption: function (selectedOption) {
      if (!this.initEditing && selectedOption == this.$t('Site')) {
        this.newDocument.idCemetery = null,
        this.newDocument.idSection = null,
        this.newDocument.idGrave = null
      } else if (!this.initEditing && selectedOption == this.$t('Cemetery')) {
        this.newDocument.idBuilding = null
        this.newDocument.idFloor = null
        this.newDocument.idUnit = null
        this.newDocument.idSpace = null
      }
    }
  },
  async mounted () {
    this.initEditing = true
    this.clearFields()
    this.hasSuccess = false
    const {idCemetery, idSection, idSite, idBuilding, idFloor } = this.document
    if (typeof this.document !== 'undefined' && this.document !== null) {
      this.newDocument = JSON.parse(JSON.stringify(this.document))
      if (this.newDocument.created !== null) {
        this.newDocument.created = moment(this.newDocument.created).format('YYYY-MM-DD')
      }
      if (this.newDocument.expires !== null) {
        this.newDocument.expires = moment(this.newDocument.expires).format('YYYY-MM-DD')
      }
      this.rawTags = this.newDocument.tags ? this.newDocument.tags : []
      this.newDocument.type = this.document.type
        ? this.document.type
        : this.type
    } else {
      this.newDocument.type = this.type
    }
    if (idSite && this.cemeteriesForCurrentSite.length === 0) {
      this.selectedOption = this.$t('Site')
    } else if (idSite && this.cemeteriesForCurrentSite.length > 0) {
      this.selectedOption = this.$t('Cemetery')
    }
    if (idCemetery) {
      const cemeteryId = this.cemeteriesForCurrentSite.find(cemetery => cemetery.id === idCemetery)
      const params = { time: this.currentDate, cemeteryId: cemeteryId }
      await this.loadSectionsForCurrentCemetery(params)
      this.selectedOption = this.$t('Cemetery')
    }
    if (idSection) {
      await this.loadGravesForCurrentSection(idSection)
    }
    if(idBuilding) {
      const buildingCode = this.buildingsForCurrentSite.find(building => building.id_building === idBuilding).building_code
        const params = { time: this.currentDate, buildingCode: buildingCode }
        await this.loadFloorsForCurrentBuilding(params)
    }
    if(idFloor) {
      await this.loadUnitsForCurrentBuildingFloor(idFloor)
      await this.loadSpacesForCurrentBuildingFloor(idFloor)
    }
    this.initEditing = false
  },
  methods: {
    ...mapActions('sites', ['loadSectionsForCurrentCemetery', 'loadGravesForCurrentSection', 'loadFloorsForCurrentBuilding', 'loadUnitsForCurrentBuildingFloor', 'loadSpacesForCurrentBuildingFloor']),
     ...mapMutations('sites', ['setSectionsForCurrentCemetery', 'setGravesForCurrentSection', 'setFloorsForCurrentBuilding', 'setUnitsForCurrentBuildingFloor', 'setUnitsForCurrentBuildingFloor', 'setSpacesForCurrentBuildingFloor']),
    cancel () {
      this.clearFields()
      this.$emit('cancel')
     },
    getCemeteryName () {
      if (this.cemeteriesForCurrentSite.length === 1){
        return this.cemeteriesForCurrentSite[0].name
      }
    },
    async uploadFile () {
      this.$log.info('Document to be uploaded:')
      this.$log.info(this.newDocument)

      if (this.newDocument.type === 'Facade Image') {
        if (this.rawFile.size > 307200) {
          this.$log.info('File size exceeds 300 KB')
          this.hasError = true
          this.errorMessages.push({id: new Date().getTime(),info: this.$t('File is too large. Maximum size is:') + ' 300 KB'})
          return
        }
      }

      if (this.newDocument.type === 'Request Attachment') {
        if (this.rawFile.size > 67108864) {
          this.$log.info('File size exceeds 64 MB')
          this.hasError = true
          this.errorMessages.push({id: new Date().getTime(),info: this.$t('File is too large. Maximum size is:') + ' 64 MB'})
          return
        }
      }

      this.hasSuccess = false
      this.hasError = false
      this.successMessages = []
      this.errorMessages = []
      this.isUploading = true
      // transform tags
      this.newDocument.tags = this.rawTags.map(t => {
        if (typeof t === 'string') {
          return {
            id: null,
            name: t
          }
        }
        return t
      })

      // add id
      if (this.site) {
        this.newDocument.idSite = this.site.id_site
      }
      this.$log.info('Input document', this.newDocument)
      if (this.mode === 'new') {
        const doc = await this.$rambollfmapi.documents.post(this.newDocument)

        this.$log.info('Added document', doc)

        if (typeof this.rawFile !== 'undefined') {
          try {
            const result = await this.$rambollfmapi.documents.upload(
              doc.idDocument,
              {
                fileData: this.rawFile,
                query: {
                  description: doc.description,
                  created: this.newDocument.created,
                  expires: this.newDocument.expires
                }
              },
              progress => {
                this.uploadProgressPercentage = progress.percentage
              }
            )
            if (result.name === 'AxiosError') {
              const error = new Error()
              error.name = 'FileSizeError'
              error.data = result.response.data
              throw error
            }
            else if (result.name === 'Error') {
              if (
                result.response &&
                result.response.data.indexOf('not allowed') >= 0
              ) {
                const error = new Error(result.response.data)
                error.name = 'UnallowedTypeError'
                throw error
            }
            else if ( result.response && result.response.data.indexOf('Invalid file signature') >= 0 ) {
              const error = new Error(result.response.data)
              error.name = 'InvalidSignatureError'
              throw error
            } else {
              throw new Error(result)
            }
          }
            this.clearFields()
            this.successMessages.push({id: new Date().getTime(), info: this.$t('Upload successful')})
            this.hasSuccess = true
            this.isUploading = false
            this.$emit('created', doc)
          } catch (error) {
            // remove the created doc
            await this.$rambollfmapi.documents.delete(doc.idDocument)
            this.$log.error('error', JSON.stringify(error))
            this.clearFields()
            // Specific error message for different errors
            if (error.name === 'UnallowedTypeError'|| error.name === 'InvalidSignatureError') {
                this.errorMessages.push({id: new Date().getTime(), info: this.$t('Upload failed') + ': ' + this.$t('Unallowed type')})
            } else if (error.name === 'FileSizeError') {
              this.errorMessages.push({ id: new Date().getTime(), info: this.$t('Filesize is too large. Maximum size is:') + ' 64 MB' })
            } else {
                this.errorMessages.push({id: new Date().getTime(),info: this.$t('Upload failed')})
            }

              if(this.errorMessages.length > 0) {
                this.hasError = true
              }
            this.isUploading = false
          }
        }
      } else if (this.mode === 'multiple') {

       for (let i = 0; i < this.rawFiles.length; i++) {
          this.newDocument.fileName = this.rawFiles[i].name
          this.newDocument.title = this.rawFiles[i].name

          const doc = await this.$rambollfmapi.documents.post(this.newDocument)
          this.$log.info('Added document', doc)

          if (typeof this.rawFiles[i] !== 'undefined') {
            try {
              const result = await this.$rambollfmapi.documents.upload(
                doc.idDocument,
                {
                  fileData: this.rawFiles[i],
                  query: {
                    description: doc.description,
                    created: this.newDocument.created,
                    expires: this.newDocument.expires
                  }
                },
                progress => {
                  this.uploadProgressPercentage = progress.percentage
                }
              )

              if (result.name === 'Error') {
                if (
                  result.response &&
                  result.response.data.indexOf('not allowed') >= 0
                ) {
                  const error = new Error(result.response.data)
                  error.name = 'UnallowedTypeError'
                  throw error
                }
              else if ( result.response && result.response.data.indexOf('Invalid file signature') >= 0 ) {
                const error = new Error(result.response.data)
                error.name = 'InvalidSignatureError'
                throw error
              } else {
                throw new Error(result)
              }
            }

            this.successMessages.push({id: new Date().getTime(), info: this.$t('Document.file') + ' ' + this.rawFiles[i].name + ' ' + this.$t('Document.uploadedSuccessfully')})

            this.hasSuccess = true

            if (i === this.rawFiles.length - 1) {
              this.clearDocumentFields()
              this.isUploading = false
              this.$emit('created')
            }

            } catch (error) {
              // remove the created doc
              await this.$rambollfmapi.documents.delete(doc.idDocument)
              this.$log.error('error', error)

              // Specific error message for different errors
              if (error.name === 'UnallowedTypeError'|| error.name === 'InvalidSignatureError') {
                this.errorMessages.push({id: new Date().getTime(), info: this.$t('Document.file') + ' ' + this.rawFiles[i].name + ' ' + this.$t('Document.uploadFailed') + '. ' + this.$t('Unallowed type')})
              } else {
                this.errorMessages.push({id: new Date().getTime(), info: this.$t('Document.file') + ' ' + this.rawFiles[i].name + ' ' + this.$t('Document.uploadFailed')})
              }

              this.hasError = true

              if (i === this.rawFiles.length - 1) {
              this.clearDocumentFields()
              this.isUploading = false
            }
            }
          }
       }

      } else if (this.mode === 'update') {
        const doc = await this.$rambollfmapi.documents.update(
          this.newDocument.idDocument,
          this.newDocument
        )

        this.clearFields()
        this.isUploading = false
        this.$emit('updated', doc)
      } else {
        // upload file version
        try {
          const result = await this.$rambollfmapi.documents.upload(
            this.newDocument.idDocument,
            {
              fileData: this.rawFile,
              query: {
                description: this.newDocument.versionDescription
              }
            },
            progress => {
              this.uploadProgressPercentage = progress.percentage
            }
          )
          if (result.name === 'Error') {
            if (
              result.response &&
              result.response.data.indexOf('not allowed') >= 0
            ) {
              const error = new Error(result.response.data)
              error.name = 'UnallowedTypeError'
              throw error
            }
          else if ( result.response && result.response.data.indexOf('Invalid file signature') >= 0 ) {
            const error = new Error(result.response.data)
            error.name = 'InvalidSignatureError'
            throw error
          } else {
              throw new Error(result)
            }
          }
          this.clearFields()
          this.hasSuccess = true
          this.isUploading = false
          this.$emit('updated')
        } catch (error) {
          // remove the created doc
          this.$log.error('error', error)

          if (error.name === 'UnallowedTypeError'|| error.name === 'InvalidSignatureError') {
            this.errorMessages.push({id: new Date().getTime(), info: this.$t('Upload failed') + ': ' + this.$t('Unallowed type')})
          } else {
            this.errorMessages.push({id: new Date().getTime(), info: this.$t('Upload failed')})
          }

          if(this.errorMessages.length > 0) {
            this.hasError = true
          }
          this.isUploading = false
        }
      }
    },
    clearFields () {
      this.clearUploadingData()
      this.clearDocumentFields()
    },
    clearUploadingData () {
      this.hasError = false
      this.hasSuccess = false
      this.hasFileValidatingError = false
      this.successMessages = []
      this.errorMessages = []
      this.uploadProgressPercentage = 0
      this.isUploading = false
    },
    clearDocumentFields () {
      this.rawFile = null
      this.rawFiles = []
      this.rawTags = []
      this.newDocument = {
        title: '',
        description: '',
        type: '',
        versionDescription: '',
        tags: [],
        created: null,
        expires: null,
        idBuilding: null,
        idSite: null,
        idCemetery: null,
        idSection: null,
        idGrave: null,
        idFloor: null,
        idUnit: null,
        idSpace: null
      }
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      // uploads only one file at a time
      const files = event.target.files
      this.rawFile = files[0]
      if (this.rawFile.size > 576716800) {
        this.hasError = true
        this.errorMessages.push({id: new Date().getTime(), info: this.$t('File is too large. Maximum size is:') + ' 550 MB'})
        this.rawFile = null
      } else {
        this.newDocument.fileName = this.rawFile.name
      }
    },
    onPickFiles () {
      this.rawFiles = []
      this.$refs.filesInput.click()
    },
    onFilesPicked (e) {
      //upload multiple files
      this.clearUploadingData()

      for (let i = 0; i < e.target.files.length; i++) {
        if (typeof e.target.files[i] !== undefined) {
          this.rawFiles.push(e.target.files[i])
        }
      }

      // Limit 100
      if (this.rawFiles.length > 100) {
        this.hasError = true
        this.hasFileValidatingError = true
        this.errorMessages.push({id: new Date().getTime(), info: this.$t('Too many files. Maximum amount is 100 at time.')})
      }

      // Maximum file size
      for (let i = 0; i < this.rawFiles.length; i++) {
        if (this.rawFiles[i].size > 576716800) {
        this.hasError = true
        this.hasFileValidatingError = true
        this.errorMessages.push({id: new Date().getTime(), info: e.target.files[i].name + ': ' + this.$t('File is too large. Maximum size is:') + ' 550 MB'})
        }
      }

      // FileType validation
      const wrongFileType = []
      const acceptableFileTypes = document.acceptableFileTypes()
      this.rawFiles.forEach(file => {
        const extension = '.' + file.name.split('.').pop().toLowerCase()
        if (!acceptableFileTypes.includes(extension)) {
          const found = wrongFileType.find(e => e === extension)
          if (!found) {
            wrongFileType.push(extension)
          }
        }
      })

      if (wrongFileType.length > 0) {
        this.hasError = true
        this.hasFileValidatingError = true
        this.errorMessages.push({id: new Date().getTime(), info: this.$t('Document.fileTypeValidationError') + " " + wrongFileType.join(', ')})
      }

    }
  }
}
</script>
<style scoped>
</style>
