<template>
  <div class="kanban-container">
    <table class="kanban-container-table">
      <tr>
        <td
          v-for="dataGroup in dataGroups"
          :key="dataGroup.id"
          class="kanban-container-column"
        >
          <kanban-widget-column
            :data-group="dataGroup"
            @click="$emit('click', $event)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import helpers from "../../../helpers"
import KanbanWidgetColumn from "./KanbanWidgetColumn.vue"
export default {
  emits: ['click'],
  components: { KanbanWidgetColumn },
  props: {
    // Contains
    // data.headers
    // data.items
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    filter: {
      type: Number,
      default: null,
    },
    generalSearch: {
      type: String,
      default: null,
    },
  },
  data () {
    return {}
  },
  computed: {
    dataGroups () {
      const headers = this.data.headers || []
      let items = this.data.items || []

      if (this.filter) {
        items = items.filter(
          (item) =>
            !helpers.tableFilter.filterRow(this.filter, this.data.filter, item)
        )
      }

      return headers.map((header, index) => {
        let filteredItems = items

        if (header.selector) {
          filteredItems = filteredItems.filter((item) => header.selector(item))
        }

        if (this.generalSearch && this.generalSearch.length > 0) {
          filteredItems = filteredItems.filter((item) =>
            this.generalFilter(header, item, this.generalSearch)
          )
        }

        return {
          id: header.text + index, // Ensure unique id. Can be overriden if necessary
          header,
          items: filteredItems,
        }
      })
    },
  },
  methods: {
    generalFilter (header, item, search) {
      const itemValue = item[header.title]

      if (itemValue) {
        return itemValue.toLowerCase().includes(search.toLowerCase())
      }

      return false
    },
  },
}
</script>
<style scoped>
.kanban-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.kanban-container-table {
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.kanban-container-column {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
