<template>
  <div>
    <v-tab-item
      :key="1"
    >
      <v-dialog
        v-model="showAllowAutomaticBillingModal"
        persistent
        width="75%"
      >
        <v-card>
          <AllowAutomaticBillingModal
            :visible="showAllowAutomaticBillingModal"
            @saved="CloseAllowAutomaticBillingModal($event)"
            @close="showAllowAutomaticBillingModal = false, automaticBillingAllowed =! automaticBillingAllowed"
          />
        </v-card>
      </v-dialog>
      <v-form
        ref="form1"
        class="pt-2 pb-6"
      >
        <ValidationObserver ref="validationObserver">
          <div
            v-if="currencyList.length > 1"
          >
            <h3 class="form-headers">
              {{ $t('Currency') }}
            </h3>
            <v-row
              align="center"
            >
              <v-col 
                cols="12"
                md="6"
              >
                <v-select
                  v-model="currencyId"
                  :label="$t('Currency')"
                  :items="currencyList"
                  item-text="code"
                  item-value="id"
                  @change="onDataChanged()"
                >
                  <template #selection="{ item }">
                    {{ item.code }}
                  </template>
                  <template #item="{ item }">
                    <v-list-item-avatar color="blue lighten-5">
                      <v-icon 
                        v-if="item.icon"
                        color="blue darken-1"
                      >
                        {{ item.icon }}
                      </v-icon>
                      <span
                        v-else
                        style="font-size:90%; color:#1E88E5; font-weight:bold;"
                      >  
                        {{ item.symbol }} 
                      </span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.code }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <br>
          </div>
          <h3 class="form-headers">
            {{ $t('create_rent_contract.billing_specifications') }}
          </h3>
          <v-row
            align="center"
          >
            <v-col 
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${isOutRent ? 'required' : ''}`"
              >
                <v-select
                  v-model="defBillingAddress"
                  :items="billingAddressTypes"
                  item-value="id"
                  required
                  :error-messages="errors[0]"
                  class="form-fields mt-4 ml-2"
                  :label="$t('create_rent_contract.billing_address')"
                  @change="onDataChanged()"
                >
                  <template #selection="{ item, index }">
                    {{ item.label ? $t(`${item.label}`) : "" }}
                  </template>
                  <template #item="{item, index}">
                    {{ $t(`${item.label}`) }}
                  </template>
                </v-select>  
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="defRentIncreaseLettersAddress"
                :items="billingAddressTypes"
                item-value="id"
                class="form-fields mt-4 ml-2"
                :label="$t('create_rent_contract.increase_letter_address')"
                @change="onDataChanged()"
              >
                <template #selection="{ item, index }">
                  {{ item.label ? $t(`${item.label}`) : "" }}
                </template>
                <template #item="{item, index}">
                  {{ $t(`${item.label}`) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="isContractSpecificBillingAddress"
            align="center"
          >
            <v-row
              align="center"
              
              class="mb-4"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="contractSpecificName"
                  type="text"
                  :label="$t('create_rent_contract.contract_specific_name')"
                  class="form-fields mt-4 ml-2"
                  @input="onDataChanged()"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="contractSpecificBusinessId"
                  type="text"
                  :label="$t('create_rent_contract.contract_specific_business_id')"
                  class="form-fields mt-4 ml-2"
                  @input="onDataChanged()"
                />
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-text-field
                v-model="contractSpecificAddress"
                type="text"
                :label="$t('create_rent_contract.contract_specific_address')"
                class="form-fields mt-4 ml-2"
                @input="onDataChanged()"
              />
            </v-col>
            <v-row
              align="center"
              
              class="mb-4"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="contractSpecificPostalCode"
                  type="text"
                  :label="$t('create_rent_contract.contract_specific_postal_code')"
                  class="form-fields mt-4 ml-2"
                  @input="onDataChanged()"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="contractSpecificPostalCity"
                  type="text"
                  :label="$t('create_rent_contract.contract_specific_city')"
                  class="form-fields mt-4 ml-2"
                  @input="onDataChanged()"
                />
              </v-col>
            </v-row>
          </v-row>
          <v-row
            align="center"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="billingCycleLength"
                :items="getBillingPeriods()"
                item-text="text"
                item-value="id"
                class="form-fields mt-4 ml-2"
                :label="$t('create_rent_contract.billing_cycle_length')"
                :readonly="contract && contract.isDraft === false"
                @change="onChangeBillingCycleLength()"
              />
            </v-col>
            <v-col 
              cols="12"
              md="3"
            >
              <v-select
                v-if="billingCycleLength > 1"
                v-model="firstBillingMonth"
                :items="getYearValues()"
                item-text="text"
                item-value="id"
                class="form-fields mt-4 ml-2"
                :label="$t('create_rent_contract.first_billing_month')"
                :readonly="contract && contract.isDraft === false"
                @change="onDataChanged()"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-checkbox
                v-if="billingCycleLength > 1"
                v-model="isRetrospectiveBilling"
                class="form-fields mt-8 ml-4"
                :label="$t('create_rent_contract.retrospective_billing')"
                :readonly="contract && contract.isDraft === false"
                @change="onDataChanged()"
              />	
            </v-col>
          </v-row>
          <br>
          <h3 class="form-headers">
            {{ $t('create_rent_contract.billing') }}
          </h3>
          <v-text-field
            v-if="defBillingAddress !== definitionByLabel('BillingAddressTypeTenanAddress').id"
            v-model="ovtCode"
            type="text"
            :label="$t('create_rent_contract.ovt_code')"
            class="form-fields mt-4 ml-2"
            @input="onDataChanged()"
          />
          <v-text-field
            v-model="payerReference"
            type="text"
            :label="$t('create_rent_contract.payer_reference')"
            class="form-fields mt-4 ml-2"
            @input="onDataChanged()"
          />
          <v-checkbox
            v-model="automaticBillingAllowed"
            class="form-fields mt-2 ml-2"
            :label="$t('create_rent_contract.automatic_billing_allowed')"
            @change="ChangeAutomaticBillingAllowed()"
          />
          <ValidationProvider
            v-slot="{ errors }"
            :rules="`${isOutRent && automaticBillingAllowed ? 'required' : ''}`"
          >
            <v-text-field
              v-if="automaticBillingAllowed"   
              v-model="automaticBillingReasoning"
              type="text"
              :label="$t('create_rent_contract.automatic_billing_reasoning')"
              class="form-fields mt-0 ml-2"
              required
              :error-messages="errors[0]"
              @input="onDataChanged()"
            />
          </ValidationProvider>
        </ValidationObserver>
      </v-form>
    </v-tab-item>
  </div>
</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import AllowAutomaticBillingModal from './AllowAutomaticBillingModal.vue'

export default {
  emits: ['isPageValidChanged', 'updated'],
  name: "RentContractBillingTab",
  components: {
    ValidationProvider,
    ValidationObserver,
    AllowAutomaticBillingModal
  },
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    currencyList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return (
      {
        defBillingAddress: undefined,
        defRentIncreaseLettersAddress: undefined,
        billingCycleLength: 1,
        firstBillingMonth: null,
        isRetrospectiveBilling: false,
        ovtCode: undefined,
        payerReference: undefined,
        contractSpecificName: undefined,
        contractSpecificBusinessId: undefined,
        contractSpecificAddress: undefined,
        contractSpecificPostalCode: undefined,
        contractSpecificPostalCity: undefined,
        automaticBillingAllowed: false,
        automaticBillingReasoning: undefined,
        currencyId: undefined,
        billingPeriods: [
          1, 3, 4, 6, 12
        ],
        showAllowAutomaticBillingModal: undefined,
        allowAutomaticBillingData : null,
        requiredField: [
          v => v != undefined && v.trim() != ""
        ]
      }
    )
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionByLabel']),
    billingAddressTypes () {
      return this.definitionsByGroupLabel('rent_contracts.billing_address_type')
    },
    isContractSpecificBillingAddress () {
      return this.defBillingAddress === this.definitionByLabel('BillingAddressTypeContractSpecific').id || this.defRentIncreaseLettersAddress === this.definitionByLabel('BillingAddressTypeContractSpecific').id;
    },
  },
  watch: {
    async isActive () {
      const observer = this.$refs.validationObserver;
      this.isPageValid = await observer?.validate() ?? false
      this.$emit("isPageValidChanged", this.isPageValid)
    },
    contract () {
      if (this.contract) {
       this.updateDataFromExistingContract()
      }
    },
  },
  mounted () {
    this.defBillingAddress = this.defBillingAddress ?? this.definitionByLabel('BillingAddressTypeTenanAddress').id
    this.defRentIncreaseLettersAddress = this.defRentIncreaseLettersAddress ?? this.definitionByLabel('BillingAddressTypeTenanAddress').id
    if (this.currencyId === undefined) {
      this.currencyId = this.currencyList[0]?.id
    }
    this.onDataChanged()
    
  },
  methods: {
    updateDataFromExistingContract () {
      this.defBillingAddress = this.contract.defBillingAddress
      this.defRentIncreaseLettersAddress = this.contract.defRentIncreaseLettersAddress
      this.billingCycleLength = this.contract.billingCycleLength
      this.firstBillingMonth = this.contract.firstBillingMonth
      this.isRetrospectiveBilling = this.contract.isRetrospectiveBilling
      this.ovtCode = this.contract.ovtCode
      this.payerReference = this.contract.payerReference
      this.contractSpecificName = this.contract.contractSpecificName
      this.contractSpecificBusinessId = this.contract.contractSpecificBusinessId
      this.contractSpecificAddress = this.contract.contractSpecificAddress
      this.contractSpecificPostalCode = this.contract.contractSpecificPostalCode
      this.contractSpecificPostalCity = this.contract.contractSpecificPostalCity
      this.automaticBillingAllowed = this.contract.automaticBillingAllowed
      this.automaticBillingReasoning = this.contract.automaticBillingReasoning
      this.currencyId = this.contract.currencyId
    },
    async onChangeBillingCycleLength () {
      if (this.billingCycleLength > 1 && this.firstBillingMonth === null) {
        this.firstBillingMonth = 1
      }
      if (this.billingCycleLength === 1 && this.firstBillingMonth !== null) {
        this.firstBillingMonth = null
      }
      this.onDataChanged()
    },
    async onDataChanged () {
      const data = {
        defBillingAddress: this.defBillingAddress,
        defRentIncreaseLettersAddress: this.defRentIncreaseLettersAddress,
        billingCycleLength: this.billingCycleLength,
        firstBillingMonth: this.firstBillingMonth,
        isRetrospectiveBilling: this.isRetrospectiveBilling,
        ovtCode: this.ovtCode,
        payerReference: this.payerReference,
        contractSpecificName: this.contractSpecificName,
        contractSpecificBusinessId: this.contractSpecificBusinessId,
        contractSpecificAddress: this.contractSpecificAddress,
        contractSpecificPostalCode: this.contractSpecificPostalCode,
        contractSpecificPostalCity: this.contractSpecificPostalCity,
        automaticBillingAllowed: this.automaticBillingAllowed,
        automaticBillingReasoning: this.automaticBillingReasoning,
        allowAutomaticBillingData: this.allowAutomaticBillingData,
        currencyId: this.currencyId,
      }
      this.$emit('updated', data)
    },
    getBillingPeriods () {
      return this.billingPeriods.map(value => {
        const text = value > 0 ? `${value}` : ""
        return { text, id: value }
      })
    },
    getYearValues () {
      return [1,2,3,4,5,6,7,8,9,10,11,12].map(value => {
        const text = value > 0 ? `${value}` : ""
        return { text, id: value }
      })
    },
    CloseAllowAutomaticBillingModal (data) {
      this.showAllowAutomaticBillingModal = false
      this.allowAutomaticBillingData = data
      this.onDataChanged()
    },
    ChangeAutomaticBillingAllowed () {
      if (this.automaticBillingAllowed == false) {
        this.automaticBillingReasoning = undefined
        this.showAllowAutomaticBillingModal = true
      } else {
        this.onDataChanged()
      }
    },
  }
}
</script>

<style scoped>
.form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
.v-input--checkbox {
  display: inline-flex;
}
</style>