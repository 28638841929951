import { AndFilterController } from './AndFilterController.js'
import { OrFilterController } from './OrFilterController.js'
import { SitePartyFilter, isValidSitePartyFilter } from './filters/SitePartyFilter.js'
import { SiteDefaultFilter, isValidSiteDefaultFilter } from './filters/SiteDefaultFilter.js'

/**
 * Site filter controller. Constructs and runs filter list based on the filter type.
 */
export class SiteFilterController {
  /**
    * Initialize filter
    * @param {Object[]} sites - List of circle sites
    * @param {Object} siteFilters - Filters object. Normally app.siteFilters
    * @param {Object} params - Additional params
    * @param {String[]} params.skipFilters - Filters which are skipped.
    * @param {Object[]} params.allSitesParties - List of available site parties for the party filter
    * @param {String} params.filterType - Type of the filter. Defaults to and filter. "OR" = Or filter.
    */
  constructor (sites, siteFilters, params) {
    this.siteFilters = siteFilters
    this.params = params
    this.skipFilters = params && params.skipFilters

    if (params?.filterType === 'OR') {
      this.filterController = new OrFilterController(sites, 'id_site')
    } else {
      this.filterController = new AndFilterController(sites)
    }
  }

  /**
   * Populates internal filter controller based on the siteFilters object provided in constructor
   */
  createFilters () {

    Object.entries(this.siteFilters).forEach(([filterName, filter]) => {

      if (this.skipFilters && this.skipFilters.includes(filterName)) {
        return
      }

      if (isValidSitePartyFilter(filterName, filter)) {
        // Partyfilters are all combined to one filter. To make AND-filter work, we need to separate them
        const separatedFilters = Object.entries(filter).map(([key, value]) => ({ [key]: value}))
        separatedFilters.forEach(f => {
          this.filterController.addFilter(new SitePartyFilter(filterName, f, this.params))
        })
      } else if (isValidSiteDefaultFilter(filterName, filter)) {
        this.filterController.addFilter(new SiteDefaultFilter(filterName, filter, this.params))
      }
    })
  }

  /**
   * Returns filtered sites. Sites are provided in constructor
   * @returns Filtered sites list
   */
  filter () {
    this.createFilters()
    return this.filterController.filter()
  }
}

export const filterTypes = () => {
  return [
    'AND',
    'OR',
  ]
}