<template>
  <div>
    <v-dialog
      v-if="showCreateRentContractModal"
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          :contract-id="contractId"
          :out-rent="true"
          @handleSave="onCreateRentContractModalClosed()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="commercialModifyDialog"
      max-width="1200"
      persistent
    >
      <v-card>
        <CommercialInfoModifyForm
          :items="selectedCommercialInfoItems"
          :header="$t('Carpark')"
          :fields="commercialFields"
          :info-text="hasApplicationPermissionByName('MARKKINOINTI') ? infoText() : ''"
          @close="commercialModifyDialog = false"
          @popup="onPopup"
          @patch="onPatch"
          @update="onUpdate"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="hasApplicationPermissionByName('VSOP_POHJAKUVA_VAJAAKAYTTO')"
      v-model="vacancyRateModifyDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <GeneralModifyForm
          :items="vacancyRateAssumptionItems"
          :header="$t('Carpark')"
          title="Edit vacancy rate assumption"
          :fields="vacancyRateAssumptionFields"
          @close="vacancyRateModifyDialog = false"
          @popup="onPopup"
          @patch="onPatch"
          @update="onUpdate"
        />
      </v-card>
    </v-dialog>

    <v-expansion-panels
      v-if="selectedCarSpacesFromViewer.length"
      style="margin-top: 0.5em"
      :value="selectedCarSpacesFromViewer.length ? [0] : null"
      multiple
    >
      <CarparkDetails
        v-if="selectedCarSpacesFromViewer.length === 1"
        :carpark="selectedCarSpacesFromViewer[0]"
        :carpark-contracts="carparkContracts"
        :tenant-info="tenantInfo"
        :building-code="buildingCode"
        :building-carparks-summary="buildingCarparksSummary"
        @openContract="openContract"
      />
      <v-expansion-panel
        v-else-if="selectedCarSpacesFromViewer.length > 1"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>directions_car</v-icon>
            <span> {{ $t('Car parks') }}</span>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            dense
            class="info-box"
          >
            <v-list-item class="tile">
              <v-list-item-content
                class="tile-content"
              >
                Valinta
              </v-list-item-content>
              <v-list-item-content class="tile-content">
                {{
                  selectedCarSpacesFromViewer.length + ' ' + $t('carparks')
                }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div
            v-for="cp in selectedCarSpacesFromViewer"
            :key="cp.id_carspace"
            class="inner-expansion-container"
          >
            <CarparkDetails
              class="inner-expansion"
              :carpark="cp"
              :carpark-contracts="carparkContracts"
              :tenant-info="tenantInfo"
              :building-code="buildingCode"
              :building-carparks-summary="buildingCarparksSummary"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--EDIT MENU -->
    <v-row
      v-if="selectedCarSpacesFromViewer.length > 0"
      id="carparkModifyButton"
      align="end"
      justify="space-between"
    >
      <v-col
        v-if="hasApplicationPermissionByName('VUOKRAUS_VLAINEN')"
        cols="12"
      >
        <v-btn
          text
          outlined
          rounded
          @click="$emit('modify-tenants')"
        >
          <v-icon>euro_symbol</v-icon>{{ $t('Edit tenants') }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn
          v-if="!readonly"
          text
          outlined
          rounded
          align-end
          @click="$emit('modify-carparks')"
        >
          <v-icon>edit</v-icon>
          {{ carparkEditText(selectedCarSpacesFromViewer) }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          v-if="!readonly && hasApplicationPermissionByName('LEASING')"
          text
          rounded
          outlined
          @click="commercialModifyDialog = true"
        >
          <v-icon>edit</v-icon>
          {{ $t('Edit commercial information') }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          v-if="!readonly && hasApplicationPermissionByName('VSOP_POHJAKUVA_VAJAAKAYTTO')"
          text
          rounded
          outlined
          @click="vacancyRateModifyDialog = true"
        >
          <v-icon>edit</v-icon>
          {{ $t('Edit vacancy rate assumption') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommercialInfoModifyForm from '../../components/FacilityManagement/CommercialInfoModifyForm.vue'
import { mapGetters, mapState } from 'vuex'
import CarparkDetails from './components/CarparkDetails.vue'
import CreateRentContractModal from '../../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'
import GeneralModifyForm from '../../components/FacilityManagement/GeneralModifyForm.vue'

export default {
  emits: ['modify-tenants', 'modify-carparks', 'update'],
  name: 'CarParkBox',
  components: {
    CommercialInfoModifyForm,
    CarparkDetails,
    CreateRentContractModal,
    GeneralModifyForm,
  },
  props: {
    selectedCarSpacesFromViewer: { type: Array, default: null },
    carparkContracts: { type: Array, default: null },
    tenantInfo: { type: Array, default: null },
    buildingCode: { type: String, default: null },
    buildingCarparksSummary: { type: Object, default: null }
  },
  data () {
    return {
      commercialModifyDialog: false,
      vacancyRateModifyDialog: false,
      state: false,
      showCreateRentContractModal: false,
      contractId: undefined,
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('app', ['settings']),
    readonly () {
      return !this.hasApplicationPermissionByName('AUTOPAIKKA_MUOKKAUS')
    },
    vacancyRateAssumptionItems () {
      return this.selectedCarSpacesFromViewer.map(carspace => ({
        id: carspace.id_carspace,
        item_code: carspace.nro,
        vacancy_rate_assumption: carspace.vacancy_rate_assumption
      }))
    },
    vacancyRateAssumptionFields () {
      return [
        {
          header: 'Vacancy rate assumption',
          fieldType: 'number_field',
          id: 'vacancy_rate_assumption',
          value: 'vacancy_rate_assumption',
          show: true,
          style: { 'min-width': '30em' },
          rules: [
            value => value >= 0 && value <= 100 || this.$t('Value must be positive and less than 100')
          ]     
        },
      ]
    },
    selectedCommercialInfoItems () {
      return this.selectedCarSpacesFromViewer.map(carspace => ({
        ...carspace,
        id: carspace.id_carspace,
        item_code: carspace.nro
      }))
    },
    commercialFields () {
      let rentTargetHeader = 'Carpark rent target single'
      let marketRentHeader = 'Market rent carpark'
      // Change commercial info headers if only carpark areas are selected
      const rentalTypes = [...new Set(this.selectedCommercialInfoItems.map(carpark => carpark.rental_type))]
      if (rentalTypes.length === 1) {
        if (rentalTypes[0] === 'AreaRent')  {
          rentTargetHeader = 'Sum of rent target'
          marketRentHeader = 'Market rent €/car space'
        }
      }
      let fields = [
        {
          header: marketRentHeader,
          fieldType: 'number_field',
          id: 'market_rent',
          value: 'market_rent',
          show: true,
          style: { 'min-width': '10em' },     
        },
        {
          header: rentTargetHeader,
          fieldType: 'number_field',
          id: 'rent_target',
          value: 'rent_target',
          show: true,
          style: { 'min-width': '10em' },
          rules: [
            value => value >= 0 || this.$t('Value must be positive')
          ]
        },
        {
          header: 'Lease plan',
          fieldType: 'text_area',
          id: 'lease_plan',
          value: 'lease_plan',
          show: true,
          style: { 'min-width': '22em' },
        },
      ]
      return fields
    },
  },
  methods: {
    async onPatch (item, time, itemPatch) {
      await this.$rambollfmapi.carparks.patch(item, time, itemPatch);
    },
    onUpdate () {
      this.$emit('update')
    },
    onPopup (_, path, componentName) {
      const w = document.getElementById(componentName).clientWidth
      const h = document.getElementById(componentName).clientHeight + 1

      const width = window.innerWidth ??
        document.documentElement.clientWidth ??
        screen.width

      const height = window.innerHeight ??
        document.documentElement.clientHeight ??
        screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom
      const top = (height - h) / 2 / systemZoom

      const baseURL = window.location.href.replace(this.$route.path, '')
      window.addEventListener('message', this.receivePopupMessage)
      this.popupWindow = window.open(
        `${baseURL}/${path}/`,
        `myWindow${new Date()}`,
        `width=${w}, height=${h}, top=${top}, left=${left}`
      )
    },
    infoText () {
      const customer = this.settings.commercialInfoCustomer ?? ""
      switch (customer) {
        case "EQ":
          return this.$t('Carpark_commercial_data_modify_info_eq')
        default:
          return this.$t('Carpark_commercial_data_modify_info_basic')
      }
    },
    carparkEditText (selectedCarSpacesFromViewer) {
      if (!selectedCarSpacesFromViewer || selectedCarSpacesFromViewer.length === 0) {
        return ''
      }

      const [{ rental_type: rentalType }] = selectedCarSpacesFromViewer

      if (rentalType === 'Privilege') {
        return this.$t('Edit parking privileges')
      } else if (rentalType === 'AreaRent') {
        return this.$t('Edit carpark area')
      }

      return this.$t('Edit car parks')
    },
    async openContract (contract) {
      this.contractId = contract.id_contract
      this.showCreateRentContractModal = true
    },
    onCreateRentContractModalClosed () {
      this.contractId = undefined
      this.showCreateRentContractModal = false
    },
  }
}
</script>
<style scoped>
</style>
