import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store.js'
import Dashboard from './views/Dashboard.vue'
import FacilityManagement from './views/FacilityManagement.vue'
import GraveyardMap from './views/GraveyardMap.vue'
import Documents from './views/Documents.vue'
import Reports from './views/Reports.vue'
import Building from './views/Building.vue'
import Sites from './views/Sites.vue'
import SiteInfo from './views/SiteInfo.vue'
import Settings from './views/Settings.vue'
import Parties from './views/Parties.vue'
import UserControl from './views/UserControl.vue'
import ServiceRequests from './views/ServiceRequests.vue'
import UsersPerGroup from './components/UsersPerGroup.vue'
import UserGroups from './views/UserGroups.vue'
import CostCenters from './views/CostCenters.vue'
import LongTermPlanning from './views/LongTermPlanning.vue'
import ServiceRequestsWindow from './views/ServiceRequestsWindow.vue'
import ServiceRequestWindow from './views/ServiceRequestWindow.vue'
import InternalRent from './views/InternalRent.vue'
import StatusPortfolios from './views/StatusPortfolios.vue'
import PurposesOfUse from './views/PurposesOfUse.vue'
import CostTypes from './views/CostTypes.vue'
import AdditionalDataFields from './views/AdditionalDataFields.vue'
import Leasing from './views/Leasing.vue'
import PortfolioManagement from './views/PortfolioManagement.vue'
import Photos from './views/Photos.vue'
import SpaceModifyPopup from './views/SpaceModifyPopup.vue'
import UnitModifyPopup from './views/UnitModifyPopup.vue'
import MarketInfoModifyPopup from './views/MarketInfoModifyPopup.vue'
import CommercialInfoModifyPopup from './views/CommercialInfoModifyPopup.vue'
import Contracts from './views/Contracts.vue'
import Contractlayers from './views/Contractlayers.vue'
import AdditionalInfo from './views/AdditionalInfo.vue'
import BuildingRatings from './views/BuildingRatings.vue'
import BudgetAndExpenses from './views/BudgetAndExpenses.vue'
import PersonnelAllocation from './views/PersonnelAllocation.vue'
import MarketingInfo from './views/MarketingInfo.vue'
import Renting from './views/Renting.vue'
import RentingProcesses from './views/RentingProcesses.vue'
import RentalContracts from './views/RentalContracts.vue'
import Billing from './views/Billing.vue'
import Esg from './views/Esg.vue'
import TechnicalValues from './views/TechnicalValues.vue'
import AccessibilityStatement from './views/AccessibilityStatement.vue'
import ReleasesView from './views/ReleasesView.vue'
import { i18n } from './plugins/i18n.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/building/:buildingCode',
      name: 'building',
      component: Building
    },
    {
      path: '/documents',
      name: 'alldocuments',
      component: Documents
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports
    },
    {
      path: '/contracts',
      name: 'contracts',
      component: Contracts,
    },
    {
      path: '/contractlayers',
      name: 'contractlayers',
      component: Contractlayers,
      children: [
        {
          path: '/contractlayers/rentingout',
          name: 'contractlayers.renting_out',
          component: Contractlayers
        },
        {
          path: '/contractlayers/rentingin',
          name: 'contractlayers.renting_in',
          component: Contractlayers
        },
        {
          path: '/contractlayers/dashboard',
          name: 'contractlayers.dashboard',
          component: Contractlayers
        }
      ]
    },
    {
      path: '/personnelallocations',
      name: 'personnel_allocations',
      component: PersonnelAllocation
    },
    {
      path: '/sites',
      name: 'sites',
      component: Sites,
      children: [
        {
          path: '/sites/:id_site',
          name: 'sites.info',
          component: SiteInfo,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/documents',
          name: 'sites.documents',
          component: Documents,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/facilitymanagement/',
          name: 'sites.facilitymanagement',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          // component: () => import(/* webpackChunkName: "about" */ './views/FacilityManagement.vue')
          component: FacilityManagement,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: 'sites/:id_site/graveyardmap/',
          name: 'sites.graveyardmap',
          component: GraveyardMap,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/photos',
          name: 'sites.photos',
          component: Photos,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/budgetandexpenses',
          name: 'sites.budgetAndExpenses',
          component: BudgetAndExpenses,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/additional',
          name: 'sites.additionalInfo',
          component: AdditionalInfo,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/buildingRatings',
          name: 'sites.buildingRatings',
          component: BuildingRatings,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/esg',
          name: 'sites.esg',
          component: Esg,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/renting',
          name: 'sites.renting',
          component: Renting,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/rentingprocesses',
          name: 'sites.rentingprocesses',
          component: RentingProcesses,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/rentalcontracts',
          name: 'sites.rentalcontracts',
          component: RentalContracts,
          meta: {
            setSiteLocation: true
          }
        },
        {
          path: '/sites/:id_site/technicalvalues',
          name: 'sites.technicalValues',
          component: TechnicalValues,
          meta: {
            setSiteLocation: true
          }
        },
      ]
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      children: [
        {
          path: '/settings/parties',
          name: 'settings.parties',
          component: Parties
        },
        {
          path: '/settings/users',
          name: 'settings.users',
          component: UserControl
        },
        {
          path: '/settings/usergroups/:id_group?',
          name: 'settings.usergroups',
          component: UserGroups
        },
        {
          path: '/settings/usersPerGroup',
          name: 'settings.usersPerGroup',
          component: UsersPerGroup
        },
        {
          path: '/settings/costcenters',
          name: 'settings.costcenters',
          component: CostCenters
        },
        {
          path: '/settings/statusportfolios',
          name: 'settings.statusportfolios',
          component: StatusPortfolios
        },
        {
          path: '/settings/additionaldatafields',
          name: 'settings.additionaldatafields',
          component: AdditionalDataFields
        },
        {
          path: '/settings/purposesofuse',
          name: 'settings.purposesofuse',
          component: PurposesOfUse
        },
        {
          path: '/settings/costtypes',
          name: 'settings.costtypes',
          component: CostTypes
        },
        {
          path: '/settings/servicerequests',
          name: 'settings.servicerequests',
          component: ServiceRequests
        },
        {
          path: '/settings/portfoliomanagement',
          name: 'settings.portfoliomanagement',
          component: PortfolioManagement
        }
      ]
    },
    {
      path: '/',
      name: 'home'
    },
    {
      path: '/servicerequestswindow/:id_request/:extra',
      name: 'ServiceRequestsWindow',
      component: ServiceRequestsWindow
    },
    {
      path: '/servicerequestwindow/:request_type/:extra',
      name: 'ServiceRequestWindow',
      component: ServiceRequestWindow
    },
    {
      path: '/spacemodify',
      name: 'SpaceModifyPopup',
      component: SpaceModifyPopup
    },
    {
      path: '/unitmodify',
      name: 'UnitModifyPopup',
      component: UnitModifyPopup
    },
    {
      path: '/marketinfomodify',
      name: 'MarketInfoModifyPopup',
      component: MarketInfoModifyPopup
    },
    {
      path: '/commercialinfomodify',
      name: 'CommercialInfoModifyPopup',
      component: CommercialInfoModifyPopup
    },
    {
      path: '/internalrent',
      name: 'internalrent',
      component: InternalRent,
      children: [
          {
          path: '/internalrent/dashboard',
          name: 'internalrent.dashboard',
          component: InternalRent
        },
        {
          path: '/internalrent/:id_portfolio/statusportfolio',
          name: 'internalrent.status_portfolio',
          component: InternalRent
        },
        {
          path: '/internalrent/:id_portfolio/costcenters',
          name: 'internalrent.cost_centers',
          component: InternalRent
        },
        {
          path: '/internalrent/:id_portfolio/budgetingandrealisation',
          name: 'internalrent.budgeting_and_realisation',
          component: InternalRent
        },
        {
          path: '/internalrent/allocation',
          name: 'internalrent.allocation',
          component: InternalRent
        }
      ]
    },
    {
      path: '/planning',
      name: 'planning',
      component: LongTermPlanning,
      children: [
        {
          path: '/planning/dashboard',
          name: 'planning.dashboard',
          component: LongTermPlanning
        },
        {
          path: '/planning/tasklist',
          name: 'planning.tasklist',
          component: LongTermPlanning
        }
      ]
    },
    {
      path: '/renting',
      name: 'renting',
      component: Leasing,
      children: [
        {
          path: '/renting/ownactive',
          name: 'renting.own_active',
          component: LongTermPlanning
        },
        {
          path: '/renting/generalsituation',
          name: 'renting.general_situation',
          component: LongTermPlanning
        },
        {
          path: '/renting/prospects',
          name: 'renting.prospects',
          component: LongTermPlanning
        },
        {
          path: '/renting/rentingprocess',
          name: 'renting.renting_process',
          component: LongTermPlanning
        },
        {
          path: '/renting/renegotiationsandrenewals',
          name: 'renting.renegotiations_and_renewals',
          component: LongTermPlanning
        },
        {
          path: '/renting/rentableunits',
          name: 'renting.rentable_units',
          component: LongTermPlanning
        },
        {
          path: '/renting/leaseplan',
          name: 'renting.lease_plan',
          component: LongTermPlanning
        },
        {
          path: '/renting/customers',
          name: 'renting.customers',
          component: LongTermPlanning
        },
      ]
    },
    {
      path: '/marketinginfo',
      name: 'marketing_information',
      component: MarketingInfo
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing
    },
    {
      path: '/accessibilitystatement',
      name: 'accessibilityStatement',
      component: AccessibilityStatement
    },
    {
      path: '/releases',
      name: 'releases',
      component: ReleasesView
    },
  ],
  scrollBehavior () {
    // always scroll to top
    return { x: 0, y: 0 }
  },
})

// For now this is only for updating the tabs for different sites to show the proper page:
// info, facilitymanagment or documents.
// If/when the need comes for a more robust middleware implementation, this url has some
// pretty good info: https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
router.beforeEach((to, from, next) => {
  if (to.meta.setSiteLocation) {
    store.commit('sites/setSiteTabLocation', { siteId: Number(to.params.id_site), location: to.name })
  }
  if (to.meta.setTabLocation) {
    store.commit('sites/setTabLocation', { tab: Number(to.params.id_site), location: to.name })
  }

  return next()
})

router.afterEach((to) => {
  const page = i18n.t(to.name)
  store.dispatch('app/addAriaLive', i18n.t('aria_live.current_page', {n: page}))
})

export default router
