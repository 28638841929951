<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ $t('Edit tenants') }}
    </template>
    <template #content>
      <v-form
        v-if="carparks"
        ref="form"
      >
        <v-row
          justify="center"
          align="center"
        >
          <v-row
            justify="space-around"
            class="flex-column"
          >
            <h3 class="text-body font-weight-bold">
              {{ $t('Start date for the changes') }}
            </h3>
          </v-row>
          <v-row>
            <v-text-field
              v-model="startDate"
              type="date"
              @change="checkDate()"
            />
          </v-row>
        </v-row>
        <br>
        <!-- SINGLE -->
        <template v-if="getCarparksOfType('Single').length > 0">
          <h2 class="text-body font-weight-bold">
            {{ $t('Single') }}
          </h2>
          <table class="v-datatable v-data-table v-table them--light table-overflow">
            <thead>
              <th class="column">
                {{ $t('Car park number') }}
              </th>
              <th class="column">
                {{ $t('Tenant and parking spots') }}
              </th>
              <th class="column">
                {{ $t('Reserved / spots total') }}
              </th>
            </thead>
            <tbody>
              <tr
                v-for="carpark in getCarparksOfType('Single')"
                :key="carpark.id_carspace"
              >
                <th>{{ carpark.nro }}</th>
                <td>
                  <v-row
                    align="space-between"
                    justify="end"
                  >
                    <v-row>
                      <v-row
                        v-for="tenant in tenantsForCarpark[
                          carpark.id_carspace
                        ]"
                        :key="tenant.id"
                      >
                        <v-autocomplete
                          v-model="tenant.idTenant"
                          :items="parties"
                          item-text="name"
                          item-value="id"
                          style="width: 20em"
                        />
                        <v-spacer />
                        <v-text-field
                          v-model="tenant.amount"
                          readonly
                          type="number"
                          :placeholder="'-'"
                          style="width: 5em"
                        />
                        <v-btn
                          icon
                          class="row-icon"
                          @click="
                            clearTenantRowForCarpark(
                              carpark.id_carspace,
                              tenant.id
                            )
                          "
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-btn
                        :disabled="tenantsForCarpark[carpark.id_carspace].length !== 0"
                        icon
                        :class="tenantsForCarpark[carpark.id_carspace].length == 0 ? 'py-0' : 'row-icon'"
                        @click="addTenantRowForCarpark(carpark.id_carspace)"
                      >
                        <v-icon>
                          add
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-row>
                </td>
                <td>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-row
                      class="sum-column grow flex-column"
                    >
                      {{ amountAssignedForCarpark(carpark.id_carspace) }} /
                      {{ carpark.amount }}
                    </v-row>
                    <v-row
                      v-if="
                        amountAssignedForCarpark(carpark.id_carspace) >
                          carpark.amount
                      "
                      class="shrink flex-column"
                    >
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >
                            error
                          </v-icon>
                        </template>
                          
                        <span>{{ $t('Maximum number of carparks exceeded') }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <!-- AREA RENT -->
        <template v-if="getCarparksOfType('AreaRent').length > 0">
          <h2 class="text-body font-weight-bold">
            {{ $t('AreaRent') }}
          </h2>
          <table class="v-datatable v-data-table v-table them--light table-overflow">
            <thead>
              <th class="column">
                {{ $t('Car park number') }}
              </th>
              <th class="column">
                {{ $t('Tenant and parking spots') }}
              </th>
              <th class="column">
                {{ $t('Reserved / spots total') }}
              </th>
            </thead>
            <tbody>
              <tr
                v-for="carpark in getCarparksOfType('AreaRent')"
                :key="carpark.id_carspace"
              >
                <th>{{ carpark.nro }}</th>
                <td>
                  <v-row
                    align="space-between"
                    justify="end"
                  >
                    <v-row>
                      <v-row
                        v-for="tenant in tenantsForCarpark[
                          carpark.id_carspace
                        ]"
                        :key="tenant.id"
                      >
                        <v-autocomplete
                          v-model="tenant.idTenant"
                          :items="parties"
                          item-text="name"
                          item-value="id"
                          style="width: 20em"
                        />
                        <v-spacer />
                        <v-text-field
                          v-model="tenant.amount"
                          type="number"
                          :placeholder="'-'"
                          style="width: 5em"
                        />
                        <v-btn
                          icon
                          class="row-icon"
                          @click="
                            clearTenantRowForCarpark(
                              carpark.id_carspace,
                              tenant.id
                            )
                          "
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-btn
                        icon
                        :class="tenantsForCarpark[carpark.id_carspace].length == 0 ? 'py-0' : 'row-icon'"
                        @click="addTenantRowForCarpark(carpark.id_carspace)"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-row>
                  </v-row>
                </td>
                <td>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-row
                      class="sum-column grow flex-column"
                    >
                      {{ amountAssignedForCarpark(carpark.id_carspace) }} /
                      {{ carpark.amount }}
                    </v-row>
                    <v-row
                      v-if="
                        amountAssignedForCarpark(carpark.id_carspace) >
                          carpark.amount
                      "
                      class="shrink flex-column"
                    >
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >
                            error
                          </v-icon>
                        </template>
                          
                        <span>{{ $t('Maximum number of carparks exceeded') }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <!-- PRIVILEGE -->
        <template v-if="getCarparksOfType('Privilege').length > 0">
          <h2 class="text-body font-weight-bold">
            {{ $t('Privilege') }}
          </h2>
          <table class="v-datatable v-data-table v-table them--light table-overflow">
            <thead>
              <th class="column">
                {{ $t('Car park number') }}
              </th>
              <th class="column">
                {{ $t('Tenant and parking privileges') }}
              </th>
              <th class="column">
                {{ $t('Reserved / privileges total') }}
              </th>
            </thead>
            <tbody>
              <tr
                v-for="carpark in getCarparksOfType('Privilege')"
                :key="carpark.id_carspace"
              >
                <th>{{ carpark.nro }}</th>
                <td>
                  <v-row
                    align="space-between"
                    justify="end"
                  >
                    <v-row>
                      <v-row
                        v-for="tenant in tenantsForCarpark[
                          carpark.id_carspace
                        ]"
                        :key="tenant.id"
                      >
                        <v-autocomplete
                          v-model="tenant.idTenant"
                          :items="parties"
                          item-text="name"
                          item-value="id"
                          style="width: 20em"
                        />
                        <v-spacer />
                        <v-text-field
                          v-model="tenant.amount"
                          type="number"
                          :placeholder="'-'"
                          style="width: 5em"
                        />
                        <v-btn
                          icon
                          class="row-icon"
                          @click="
                            clearTenantRowForCarpark(
                              carpark.id_carspace,
                              tenant.id
                            )
                          "
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-btn
                        icon
                        :class="tenantsForCarpark[carpark.id_carspace].length == 0 ? 'py-0' : 'row-icon'"
                        @click="addTenantRowForCarpark(carpark.id_carspace)"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-row>
                  </v-row>
                </td>
                <td>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-row
                      class="sum-column grow flex-column"
                    >
                      {{ amountAssignedForCarpark(carpark.id_carspace) }} /
                      {{ carpark.max_parking_rights }}
                    </v-row>
                    <v-row
                      v-if="
                        amountAssignedForCarpark(carpark.id_carspace) >
                          carpark.max_parking_rights
                      "
                      class="shrink flex-column"
                    >
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >
                            error
                          </v-icon>
                        </template>
                          
                        <span>{{ $t('Maximum number of carparks exceeded') }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </v-form>
    </template>
    <template #footer>
      <v-btn
        :disabled="!allowSaving || isSaving"
        rounded
        depressed
        color="primary"
        @click="save()"
      >
        {{ $t('Save') }}
        <v-progress-circular
          v-if="isSaving"
          size="16"
          indeterminate
          color="primary"
        />
      </v-btn>
    </template>
  </BaseModal>
</template>
<script>
// import moment from 'moment'
import { mapState } from 'vuex'
import BaseModal from '../../components/general/BaseModal.vue'
export default {
  emits: ['close', 'change'],
  name: 'CarparkTenantForm',
  components: { BaseModal },
  props: {
    carparks: Array,
    tenants: Array,
    parties: Array
  },
  data () {
    return {
      tenantsForCarpark: {},
      startDate: this.formattedDate(new Date()),
      groupUpdate: false,
      isSaving: false,
      valid: true
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    allowSaving () {
      for (const carpark of this.carparks) {
        if (this.tenantsForCarpark[carpark.id_carspace].find(link => link.idTenant === null)) {
          return false
        }
        const assignedAmount = this.amountAssignedForCarpark(carpark.id_carspace)
        if (carpark.rental_type === 'Privilege') {
          if (assignedAmount > carpark.max_parking_rights) {
            return false
          }
        } else {
          if (assignedAmount > carpark.amount) {
            return false
          }
        }
      }
      return true
    }
  },
  watch: {
    carparks: {
      deep: true,
      immediate: true,
      async handler (oldVal, newVal) {
        this.tenantsForCarpark = {}
        for (const park of this.carparks) {
          const foundTenants = this.tenants.filter(x => {
            return x.idCarpark === park.id_carspace && (x.endDate === null || new Date(x.endDate) >= this.currentDate)
          })
          this.$set(this.tenantsForCarpark, park.id_carspace, JSON.parse(JSON.stringify(foundTenants)))
        }
      }
    }
  },
  methods: {
    checkDate () {
      const parsed = new Date(this.startDate)
      if (parsed instanceof Date && !isNaN(parsed.getTime())) {
        return
      }
      this.startDate = this.formattedDate(new Date())
    },
    formattedDate (date) {
      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - (offset * 60 * 1000))
      return date.toISOString().split('T')[0]
    },
    getCarparksOfType (type) {
      return this.carparks.filter(cp => cp.rental_type === type)
    },
    close () {
      this.$emit('close')
      this.isSaving = false
      this.$refs.form.reset()
    },
    async save () {
      let savingDate = new Date()
      if (this.startDate !== null) {
        savingDate = new Date(this.startDate)
      }
      this.isSaving = true
      for (const carpark of Object.keys(this.tenantsForCarpark)) {
        await this.$rambollfmapi.carparks.tenants().put(carpark, savingDate, this.tenantsForCarpark[carpark])
      }
      this.startDate = null
      this.isSaving = false
      this.$emit('change')
      this.$emit('close')
    },
    addTenantRowForCarpark (idCarpark) {
      // Create a fake id that is unique among the links for the specific carpark. This id is only used as a key.
      let keyId = Math.max(...this.tenantsForCarpark[idCarpark].map(x => x.id)) + 1
      if (keyId < 1) {
        keyId = 1
      }
      this.tenantsForCarpark[idCarpark].push({
        amount: 1,
        endDate: null,
        id: keyId,
        idCarpark: idCarpark,
        idTenant: null,
        startDate: new Date()
      })
    },
    clearTenantRowForCarpark (idCarpark, idLink) {
      const index = this.tenantsForCarpark[idCarpark].findIndex(item => {
        return item.id === idLink
      })
      this.tenantsForCarpark[idCarpark].splice(index, 1)
    },
    amountAssignedForCarpark (idCarpark) {
      const count = this.tenantsForCarpark[idCarpark].map(x => Number(x.amount)).reduce((acc, cur) => {
        return acc + cur
      }, 0)
      return count
    }
  }
}
</script>
<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.row-icon {
  padding-top: 1em;
  color: var(--c-color-accent);
}
tbody tr th .icon-column {
  padding: 0 !important;
}
.sum-column {
  font-weight: bold;
  text-align: center;
}
</style>
