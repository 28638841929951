<template>
  <v-row
    no-gutters
  >
    <v-col
      v-for="building in ltpBuildings"
      :key="building.id_building"
    >
      <v-btn
        block
        rounded
        outlined
        style="margin-left: 0px"
        :class="[buildingFilter.includes(building.building_code) ? '' : 'building-selected']"
        @click="$emit('filter', building.building_code)"
      >
        {{ building.building_name }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  emits: ['filter'],
  name: 'BuildingButtons',
  props: ['buildingFilter'],
  computed: {
    ...mapState('ltp', ['ltpBuildings'])
  }
}
</script>

<style>
</style>
