<template>
  <div class="rights-outline">
    <div v-if="!isAdmin">
      <h2 class="mb-1">
        {{ $t('Dynamic site permissions') }}
      </h2>
      <p>{{ $t('dynamicbuildingpermissions.info') }}</p>
    </div>
    <v-row
      v-if="!isAdmin"
      class="mb-4"
    >
      <v-col
        v-for="group in dynamicRightsGroups"
        :key="group.label"
        cols="3"
      >
        <v-autocomplete
          v-model="dynamicBuildingPermissions[group.items]"
          :items="autocompleteItems[group.items]"
          item-text="name"
          multiple
          small-chips
          hide-details
          deletable-chips
          return-object
          :label="$t(group.label)"
          class="dynamic-rights-autocomplete"
        >
          <template #selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              :input-value="data.selected"
              :disabled="data.disabled"
              class="v-chip--select-multi"
              close
              @click="toggleAccessLevel(group.items, data.index)"
              @click:close="removeDynamicPermission(group.items, data.index)"
              @click.stop
              @input="data.parent.selectItem(data.item)"
            >
              <v-icon v-if="userGroup.dynamicBuildingPermissions[group.items][data.index].accessLevel === 0">
                visibility
              </v-icon>
              <v-icon v-if="userGroup.dynamicBuildingPermissions[group.items][data.index].accessLevel === 1">
                edit
              </v-icon>
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <hr v-if="!isAdmin">
    <strong class="mb-2">
      {{ $t('Site permissions') }}
    </strong>
    <div
      v-if="!isRegularAdmin"
      class="mb-4"
    >
      <CdsButton
        type="tertiary"
        class="selection_button"
        @click="addRightsToVisible(null)"
      >
        {{ $t('Remove all permissions') }}
      </CdsButton>
      <CdsButton
        type="tertiary"
        class="selection_button"
        @click="addRightsToVisible(0)"
      >
        {{ $t('Add read permissions to all') }}
      </CdsButton>
      <CdsButton
        type="tertiary"
        class="selection_button"
        @click="addRightsToVisible(1)"
      >
        {{ $t('Add write permissions to all') }}
      </CdsButton>
    </div>
    <div>
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          :value="[true]"
          expand
        >
          <v-expansion-panel-header>
            {{ $t('Filters') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="filterName"
                  :label="$t('Site name')"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="filterRight"
                  clearable
                  :items="statusIconValues"
                  :label="$t('Permission')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="filterEstateManager"
                  :label="$t('Estate manager')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="filterEvaluator"
                  :label="$t('Evaluator')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="filterPortfolio"
                  :label="$t('Portfolio')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="filterRentalLiabilities"
                  :label="$t('Rental liability')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-expansion-panels
      :value="[0]"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('Information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="siteIds.sites.length <= 500">
          <v-data-table
            :header-props="dataTableHeaderDefinitions"
            :options.sync="options"
            :headers="processedHeaders"
            :items="processedSites"
            class="elevation-0 full-width"
            mobile-breakpoint="0"
            :footer-props="{
              itemsPerPageOptions: [],
            }"
            :no-data-text="$t('No data available')"
            :loading-text="$t('Loading. Please wait...')"
          >
            <template #header.lock_info_ball="{ header }">
              <InfoBall
                :info="header.info"
                top
              />
            </template>
            <template #item="props">
              <tr>
                <td>
                  <span
                    v-for="hierarchyItem in getItemsForSite(props.item.idSite)"
                    :key="hierarchyItem.Id"
                  >
                    <v-icon v-if="hierarchyItem.accessLevel === 0">
                      visibility
                    </v-icon>
                    <v-icon v-else-if="hierarchyItem.accessLevel === 1">
                      edit
                    </v-icon>
                    <v-icon v-else>
                      visibility_off
                    </v-icon>
                  </span>
                </td>
                <td>
                  <v-row
                    class="flex-nowrap"
                    align="center"
                  >
                    <v-col>
                      {{ props.item.name }}
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        rounded
                        @click="toggleSite(props.item.idSite)"
                      >
                        <span v-if="isSiteOpen(props.item.idSite)">
                          <v-icon>keyboard_arrow_up</v-icon>
                          <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                        </span>
                        <span v-else>
                          <v-icon>keyboard_arrow_down</v-icon>
                          <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <right-status :right="props.item.right" />
                </td>
                <td v-if="dynamicRightsSelected" />
                <td>{{ props.item.estateManager }}</td>
                <td>{{ props.item.evaluator }}</td>
                <td>{{ props.item.portfolio }}</td>
                <td>{{ props.item.rentalLiability }}</td>
                <td>
                  <span v-if="siteDiffersFromRule(props.item)">
                    <v-icon color="warning">
                      warning
                    </v-icon>
                    <span class="d-sr-only">{{ $t('Warnings') }}</span>
                  </span>
                </td>
              </tr>
              <template v-if="isSiteOpen(props.item.idSite)">
                <tr
                  v-for="hierarchyItem in getItemsForSite(props.item.idSite)"
                  :key="hierarchyItem.type + hierarchyItem.id"
                  class="hierarchy-item"
                >
                  <td>
                    <v-btn
                      :disabled="isRegularAdmin"
                      icon
                      @click="incrementHierarchyRight(hierarchyItem.type, hierarchyItem.id, hierarchyItem.name, hierarchyItem.dynamicRightLocked)"
                    >
                      <span v-if="hierarchyItem.accessLevel === 0">
                        <v-icon>
                          visibility
                        </v-icon>
                        <span class="d-sr-only">{{ `${$t('View')}` }}</span>
                      </span>
                      <span v-else-if="hierarchyItem.accessLevel === 1">
                        <v-icon>
                          edit
                        </v-icon>
                        <span class="d-sr-only">{{ `${$t('Edit')}` }}</span>
                      </span>
                      <span v-else>
                        <v-icon>
                          visibility_off
                        </v-icon>
                        <span class="d-sr-only">{{ `${$t('Hidden')}` }}</span>
                      </span>
                    </v-btn>
                  </td>
                  <td>
                    <v-icon v-if="hierarchyItem.type === 'building'">
                      home
                    </v-icon>
                    <v-icon v-if="hierarchyItem.type === 'structure'">
                      cabin
                    </v-icon>
                    <v-icon v-if="hierarchyItem.type === 'cemetery'">
                      fence
                    </v-icon>
                    {{ hierarchyItem.name }}
                  </td>
                  <td>
                    <right-status :right="hierarchyItem.right" />
                  </td>
                  <td v-if="dynamicRightsSelected && hierarchyItem.accessLevel !== -1">
                    <v-icon
                      v-if="siteIsLocked(hierarchyItem)"
                      @click="toggleLock(hierarchyItem)"
                    >
                      lock
                    </v-icon>
                    <v-icon
                      v-else
                      @click="toggleLock(hierarchyItem)"
                    >
                      lock_open
                    </v-icon>
                  </td>
                </tr>
              </template>
            </template>
            <template
              #footer.page-text="item"
            >
              {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
              {{ item.itemsLength.toLocaleString('fi-FI') }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
          <v-data-table
            :header-props="dataTableHeaderDefinitions"
            :headers="processedHeaders"
            :options.sync="options"
            :items="processedSites"
            :server-items-length="sitesAmount"
            class="elevation-0 full-width"
            mobile-breakpoint="0"
            :footer-props="{
              itemsPerPageOptions: [],
            }"
            :no-data-text="$t('No data available')"
            :loading="dataLoading"
            :loading-text="$t('Loading. Please wait...')"
            @update:page="updatePage"
          >
            <template #header.lock_info_ball="{ header }">
              <InfoBall
                :info="header.info"
                top
              />
            </template>
            <template #item="props">
              <tr>
                <td>
                  <span
                    v-for="hierarchyItem in getItemsForSite(props.item.idSite)"
                    :key="hierarchyItem.Id"
                  >
                    <span v-if="hierarchyItem.accessLevel === 0">
                      <v-icon>
                        visibility
                      </v-icon>
                      <span class="d-sr-only">{{ `${$t('View')}` }}</span>
                    </span>
                    <span v-else-if="hierarchyItem.accessLevel === 1">
                      <v-icon>
                        edit
                      </v-icon>
                      <span class="d-sr-only">{{ `${$t('Edit')}` }}</span>
                    </span>
                    <span v-else>
                      <v-icon>
                        visibility_off
                      </v-icon>
                      <span class="d-sr-only">{{ `${$t('Hidden')}` }}</span>
                    </span>
                  </span>
                </td>
                <td>
                  <v-row
                    class="flex-nowrap"
                    align="center"
                  >
                    <v-col>
                      {{ props.item.name }}
                    </v-col>
                    <v-col
                      cols="auto"
                    >
                      <v-btn
                        icon
                        @click="toggleSite(props.item.idSite)"
                      >
                        <span v-if="isSiteOpen(props.item.idSite)">
                          <v-icon>keyboard_arrow_up</v-icon>
                          <span class="d-sr-only">{{ `${$t('Show less')}` }}</span>
                        </span>
                        <span v-else>
                          <v-icon>keyboard_arrow_down</v-icon>
                          <span class="d-sr-only">{{ `${$t('Show more')}` }}</span>
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <right-status :right="props.item.right" />
                </td>
                <td v-if="dynamicRightsSelected" />
                <td>{{ props.item.estateManager }}</td>
                <td>{{ props.item.evaluator }}</td>
                <td>{{ props.item.portfolio }}</td>
                <td>{{ props.item.rentalLiability }}</td>
                <td>
                  <v-icon
                    v-if="siteDiffersFromRule(props.item)"
                    color="warning"
                  >
                    warning
                  </v-icon>
                </td>
              </tr>
              <template v-if="isSiteOpen(props.item.idSite)">
                <tr
                  v-for="hierarchyItem in getItemsForSite(props.item.idSite)"
                  :key="hierarchyItem.type + hierarchyItem.id"
                  class="hierarchy-item"
                >
                  <td>
                    <v-btn
                      :disabled="isRegularAdmin"
                      icon
                      @click="incrementHierarchyRight(hierarchyItem.type, hierarchyItem.id, hierarchyItem.name, hierarchyItem.dynamicRightLocked)"
                    >
                      <span v-if="hierarchyItem.accessLevel === 0">
                        <v-icon>
                          visibility
                        </v-icon>
                        <span class="d-sr-only">{{ `${$t('View')}` }}</span>
                      </span>
                      <span v-else-if="hierarchyItem.accessLevel === 1">
                        <v-icon>
                          edit
                        </v-icon>
                        <span class="d-sr-only">{{ `${$t('Edit')}` }}</span>
                      </span>
                      <span v-else>
                        <v-icon>
                          visibility_off
                        </v-icon>
                        <span class="d-sr-only">{{ `${$t('Hidden')}` }}</span>
                      </span>
                    </v-btn>
                  </td>
                  <td>
                    <v-icon v-if="hierarchyItem.type === 'building'">
                      home
                    </v-icon>
                    <v-icon v-if="hierarchyItem.type === 'structure'">
                      cabin
                    </v-icon>
                    <v-icon v-if="hierarchyItem.type === 'cemetery'">
                      fence
                    </v-icon>
                    {{ hierarchyItem.name }}
                  </td>
                  <td>
                    <right-status :right="hierarchyItem.right" />
                  </td>
                  <td v-if="dynamicRightsSelected && hierarchyItem.accessLevel !== -1">
                    <v-icon
                      v-if="siteIsLocked(hierarchyItem)"
                      @click="toggleLock(hierarchyItem)"
                    >
                      lock
                    </v-icon>
                    <v-icon
                      v-else
                      @click="toggleLock(hierarchyItem)"
                    >
                      lock_open
                    </v-icon>
                  </td>
                </tr>
              </template>
            </template>
            <template
              #footer.page-text="item"
            >
              <slot name="table-buttons" />
              <v-select
                v-model="options.itemsPerPage"
                :label="$t('Choose rows per page')"
                :items="getPaginationArray(item ? item.itemsLength : 0)"
                class="mt-4"
                item-text="text"
                item-value="value"
                @change="onRowsPerPageChange"
              />
              {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
              {{ item.itemsLength.toLocaleString('fi-FI') }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import RightStatus from './RightStatus.vue'
import InfoBall from './InfoBall.vue'
import CdsButton from './CircleDesignSystem/CdsButton.vue';
export default {
  components: {
    RightStatus,
    InfoBall,
    CdsButton
  },
  model: {
    prop: 'userGroup',
    event: 'input'
  },
  props: {
    adminUserGroup: {
      type: Object,
      default: () => null
    },
    userGroup: {
      type: Object,
      default: () => null
    },
    organizations: {
      type: Array,
      default: () => []
    }
  },
  emits: ['modify', 'input'],
  data () {
    return {
      filterName: '',
      totalCount: -1,
      filterRight: null,
      filterOrganization: '',
      filterEstateManager: '',
      filterEvaluator: '',
      filterPortfolio: '',
      filterRentalLiabilities: '',
      //sitesForUserGroup: [],
      dataLoading: false,
      groupHierachies: [],
      sites: [],
      allSites: [],
      buildings: [],
      structures: [],
      cemeteries: [],
      options: {
        itemsPerPage: 10,
        sortBy: ['name'],
        page: 1
      },
      openSites: [],
      dynamicRightsGroups: [
        {
          label: 'Estate manager',
          items: 'estateManagers'
        },
        {
          label: 'Evaluator',
          items: 'evaluators'
        },
        {
          label: 'Portfolio',
          items: 'portfolios'
        },
        {
          label: 'Rental liability',
          items: 'rentalLiabilities'
        },
      ],
      autocompleteItems: {
        portfolios: [],
        evaluators: [],
        estateManagers: [],
        rentalLiabilities: []
      },
      selectedItems: {
        portfolios: [],
        evaluators: [],
        estateManagers: [],
        rentalLiabilities: []
      },
      statusIconValues: [
        { text: this.$t('rightlevel.Full'), value: 'Full' },
        { text: this.$t('rightlevel.Partial'), value: 'Partial' },
        { text: this.$t('rightlevel.None'), value: 'None' }
      ],
      tableHeaders: [
        {
          text: this.$t('Actions'),
          sortable: false
        },
        {
          text: this.$t('Site name'),
          value: 'name'
        },
        {
          text: this.$t('Permission'),
          value: 'right'
        },
        {
          text: "",
          info: this.$t('Dynamic rights lock info'),
          value: 'lock_info_ball',
          sortable: false
        },
        {
          text: this.$t('Estate manager'),
          value: 'estateManager'
        },        
        {
          text: this.$t('Evaluator'),
          value: 'evaluator'
        },
        {
          text: this.$t('Portfolio'),
          value: 'portfolio'
        },
        {
          text: this.$t('Rental liability'),
          value: 'rentalLiability'
        },
        {
          text: this.$t('Warnings'),
          sortable: false,
        }
      ],
      modifiedRights: { 
        sites: [],
        buildings: [],
        structures: [],
        cemeteries: []
      },
      sitesAmount: 0,
      buildingsBySites: {}
    }
  },
  computed: {
    dynamicBuildingPermissions: {
      get () {
        return this.userGroup.dynamicBuildingPermissions;
      },
      set (value) {
        this.$emit('input', { ...this.userGroup, dynamicBuildingPermissions: value });
      }
    },
    ...mapGetters('app', ['definitionLabelById', 'dataTableHeaderDefinitions']),
    ...mapState('app', ['availableSiteParties', 'siteIds', 'currentDate']),
    isAdmin () {
      return this.userGroup.groupId === this.adminUserGroup.groupId
    },
    isSuperAdmin () {
      return this.$store.state.app.superAdmin
    },
    groupIsSuperAdmin () {
      return this.userGroup.groupId === 1
    },
    isRegularAdmin () {
      return !this.isSuperAdmin && this.isAdmin
    },
    dynamicRightsSelected () {
      return Object.values(this.userGroup.dynamicBuildingPermissions).some(x => x.length > 0)
    },
    processedHeaders () {
      let headersToReturn = this.tableHeaders;
      if(!this.dynamicRightsSelected)
      {
        headersToReturn = headersToReturn.filter(x => x.value !== 'lock_info_ball')
      }
      //if (this.groupIsSuperAdmin) {
      return headersToReturn
      //} 
    },
    sitesForUserGroup () {
      // If a super admin is editing an organization admin
      if (this.isAdmin && this.isSuperAdmin) {
        // All sites should be selectable for admin-usergroup
        return this.sites
      } else {
        // Only get sites from the permissions of the usergroups admin
        const siteIds = this.allSites.map(x => Number(x.id)) //this.adminUserGroup.sitePermissions.map(x => Number(x.id))
        return this.sites.filter(x => siteIds.includes(x.id_site))
      }
    },
    processedSites () {
      let sites = this.sitesForUserGroup.map(x => {
        return {
          name: x.name,
          right: this.rightStatusSite(x.id_site),
          portfolio: this.definitionLabelById(x.portfolio),
          idSite: x.id_site,
          evaluator: x.evaluator,
          estateManager: this.getEstateManagerString(x.id_site),
          rentalLiability: this.getRentalLiabilityString(x.id_site)
        }
      })

      if (this.filterName !== '') {
        sites = sites.filter(x => x.name.toLowerCase().includes(this.filterName.toLowerCase()))
      }

      if (this.filterRight) {
        sites = sites.filter(x => x.right === this.filterRight)
      }

      if (this.filterEstateManager !== '') {
        sites = sites.filter(x => this.getEstateManagerString(x.idSite).toLowerCase().includes(this.filterEstateManager.toLowerCase()))
      }

      if (this.filterEvaluator !== '') {
        sites = sites.filter(x => x.evaluator.toLowerCase().includes(this.filterEvaluator.toLowerCase()))
      }

      if (this.filterPortfolio !== '') {
        sites = sites.filter(x => x.portfolio.toLowerCase().includes(this.filterPortfolio.toLowerCase()))
      }

      if (this.filterRentalLiabilities !== '') {
        sites = sites.filter(x => this.getRentalLiabilityString(x.idSite)
          .toLowerCase()
          .includes(this.filterRentalLiabilities.toLowerCase()))
      }
      return sites
    },
    getAllResults () {
      // should site amount be under 500, get all site permits. Otherwise, get results per page
      return this.siteIds.sites.length <= 500
    }
  },
  watch: {
    sites: {
      immediate: true,
      deep: true,
      handler (newValue) {
        this.getAutocompleteItems()
      }
    },
    filterName: {
      deep: true,
      handler: function (newValue) {
        if (!this.getAllResults) {
          if (this.timeout) {
            clearTimeout(this.timeout); 
          }
          this.timeout = setTimeout(() => {
            this.updatePage(1)
          }, 800)
        }
      }
    }
  },
  async mounted () {
    this.dataLoading = true
    await this.getPermitsPerPage()
    await this.getSites()
    this.getAutocompleteItems()
    this.dataLoading = false
  },
  methods: {
    async getPermitsPerPage () {
      const groupId = this.userGroup.groupId
      // should site amount be under 500, get all site permits. Otherwise, get results per page
      if (this.getAllResults) {
        this.allSites = await this.$rambollfmapi.permissions.sites().perPage(groupId)
        this.buildings = await this.$rambollfmapi.permissions.buildings().perPage(groupId)
        // generate object from sites
        this.buildingsBySites = this.getObjectFromArray(this.buildings)
        this.structures = await this.$rambollfmapi.permissions.structures().perPage(groupId)
        this.cemeteries = await this.$rambollfmapi.permissions.cemeteries().perPage(groupId)
      } else {
        this.allSites = await this.$rambollfmapi.permissions.sites().getAll(this.options.page - 1, this.options.itemsPerPage, this.filterName.toLowerCase())
        const sitesCount = await this.$rambollfmapi.permissions.sites().getAll(null, null, this.filterName.toLowerCase())
        this.sitesAmount = sitesCount.length
        var idList = this.allSites.map(x => Number(x.id))
        const params = { query: { siteids: idList } }
        this.buildings = await this.$rambollfmapi.permissions.buildings().preview(groupId, params)
        // generate object from sites for better performance
        this.buildingsBySites = this.getObjectFromArray(this.buildings)
        this.structures = await this.$rambollfmapi.permissions.structures().preview(groupId, params)
        this.cemeteries = await this.$rambollfmapi.permissions.cemeteries().preview(groupId, params)
      }
    },
    getObjectFromArray (buildingArray)
    {
      // generate object from sites for better performance
      const sitesObj = {}
      buildingArray.forEach(b => {
          if (!sitesObj[b.idSite]) {
            sitesObj[b.idSite] = []
          }
          sitesObj[b.idSite].push(b)
        })
      return sitesObj
    },
    async updatePage (value) {
      this.options.page = value
      this.dataLoading = true
      await this.getPermitsPerPage()
      await this.getSites()
      this.dataLoading = false
    },
    async getSites () {
      if (this.getAllResults) {
        this.sites = await this.$rambollfmapi.sites.groups().getAll(this.currentDate)
      } else {
        this.sites = await this.$rambollfmapi.sites.groups().perPage(this.currentDate, this.options.page - 1, this.options.itemsPerPage, this.filterName.toLowerCase())
      }
    },
    getAutocompleteItems () {
      this.autocompleteItems.portfolios = [...new Set(this.sitesForUserGroup.map(x => x.portfolio))]
        .filter(x => x)
        .map(x => ({
          name: this.definitionLabelById(x),
          id: x,
          accessLevel: 0
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      this.autocompleteItems.evaluators = [...new Set(this.sitesForUserGroup.map(x => x.evaluator))]
        .filter(x => x !== null && x !== '')
        .map(x => ({
          name: x,
          id: x,
          accessLevel: 0
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const estateManagers = this.availableSiteParties.filter(x => x.annotationLabel === 'Kiinteistökohteiden johtaja')

      this.autocompleteItems.estateManagers = estateManagers.map(x => ({
        name: x.name,
        id: x.id,
        accessLevel: 0
      })).sort((a, b) => a.name.localeCompare(b.name))

      const rentalLiabilities = this.availableSiteParties.filter(x => x.annotationLabel === 'Vuokrausvastuu (yhteistyökumppani)')

      this.autocompleteItems.rentalLiabilities = rentalLiabilities.map(party => {

        const { name, id } = party
        return {
          name,
          id,
          accessLevel: 0
        }
      }).sort((a, b) => a.name.localeCompare(b.name))

    },
    getHierarchyItemsForSite (type, siteId) {
      const accessors = {
        building: 'buildings',
        structure: 'structures',
        cemetery: 'cemeteries'
      }
      let items = []
      if (type !== 'building') {
        items = this[accessors[type]].filter(x => x.idSite === siteId)
      } else {
        // fetch buildings quicker from object
        items = this.buildingsBySites[siteId] ?? []
      }
      return items.map(x => {
        return {
          type: type,
          id: x.id,
          name: x.name,
          right: x.accessLevel >= 0 ? 'Full' : 'None',
          accessLevel: x.accessLevel,
          dynamicRightLocked: type === 'building' ? x.dynamicRightLocked : null
        }
      })
    },
    getItemsForSite (siteId) {
      const buildingsForSite = this.getHierarchyItemsForSite('building', siteId)
      const structuresForSite = this.getHierarchyItemsForSite('structure', siteId)
      const cemeteriesForSite = this.getHierarchyItemsForSite('cemetery', siteId)
      return [ ...buildingsForSite, ...structuresForSite, ...cemeteriesForSite ]
    },
    toggleSite (siteId) {
      if (this.isSiteOpen(siteId)) {
        this.openSites = this.openSites.filter(x => x !== siteId)
      } else {
        this.openSites.push(siteId)
      }
    },
    isSiteOpen (siteId) {
      return this.openSites.includes(siteId)
    },
    rightStatusSite (siteId) {
      const hierarchy = {
        building: this.buildingsBySites[siteId] ?? [],
        structure: this.structures.filter(x => x.idSite === siteId),
        cemetery: this.cemeteries.filter(x => x.idSite === siteId)
      }
      let totalHierarchy = 0
      let hierarchyWithRight = 0

      for(const key in hierarchy) {
        for(const item of hierarchy[key]) {
          totalHierarchy += 1
          if (item.accessLevel >= 0) {
            hierarchyWithRight += 1
          }
        }
      }

      if (totalHierarchy === 0) {
        return 'None'
      } else if (totalHierarchy === hierarchyWithRight) {
        return 'Full'
      } else if (hierarchyWithRight === 0) {
        return 'None'
      } else {
        return 'Partial'
      }
    },
    addRightsToVisible (accessLevel) {
      for (const site of this.processedSites) {
        const items = this.getItemsForSite(site.idSite)
        for (const item of items) {
          if (accessLevel === null) {
            this.removeUserGroupHierarchyRight(item.type, item.id)
          } else {
            this.addUserGroupHierarchyRight(item.type, item.id, item.name, accessLevel, item.dynamicRightLocked)
          }
        }
      }
    },
    findUserGroupHierarchyRight (type, id, admin = false) {
      let found = null
      if (type === 'building') {
        found = this.buildings.find(x => String(x.id) === id)
      } else if (type === 'structure') {
        found = this.structures.find(x => String(x.id) === id)
      } else if (type === 'cemetery') {
        found = this.cemeteries.find(x => String(x.id) === id)
      }
      return found
    },
    incrementHierarchyRight (type, id, name, lock = undefined) {
      const currentRight = this.findUserGroupHierarchyRight(type, id)
      const accessLevel = currentRight !== undefined ? currentRight.accessLevel : null

      if (accessLevel === 0) {
        this.addUserGroupHierarchyRight(type, id, name, 1, lock)
      } else if (accessLevel === 1) {
        this.addUserGroupHierarchyRight(type, id, name, -1, lock)
      } else {
        this.addUserGroupHierarchyRight(type, id, name, 0, lock)
      }
    },
    addUserGroupHierarchyRight (type, id, name, accessLevel, lock) {
      this.removeUserGroupHierarchyRight(type, id)

      if (type === 'building') {
        const building = this.buildings.find(b => b.id === id)
        building.accessLevel = accessLevel
        building.dynamicRightLocked = lock !== undefined ? lock : 1
        this.modifiedRights.buildings = this.modifiedRights.buildings.filter(b => b.id !== id)
        this.modifiedRights.buildings.push(building)
      } else if (type === 'structure') {
        const structure = this.structures.find(s => s.id === id)
        structure.accessLevel = accessLevel
        this.modifiedRights.structures = this.modifiedRights.structures.filter(s => s.id !== id)
        this.modifiedRights.structures.push(structure)
      } else if (type === 'cemetery') {
        const cemetery = this.cemeteries.find(c => c.id === id)
        cemetery.accessLevel = accessLevel
        this.modifiedRights.cemeteries = this.modifiedRights.cemeteries.filter(c => c.id !== id)
        this.modifiedRights.cemeteries.push(cemetery)
      }
      this.addSitePermissions()
      this.$emit('modify', this.modifiedRights)
    },
    removeUserGroupHierarchyRight (type, id) {
      if (type === 'building') {
        const building = this.buildings.find(b => b.id === id)
        building.accessLevel = -1
        this.modifiedRights.buildings = this.modifiedRights.buildings.filter(p => p.id !== id)
        this.modifiedRights.buildings.push(building)
      } else if (type === 'structure') {
        const structure = this.structures.find(s => s.id === id)
        structure.accessLevel = -1
        this.modifiedRights.structures = this.modifiedRights.structures.filter(p => p.id !== id)
        this.modifiedRights.structures.push(structure)
      } else if (type === 'cemetery') {
        const cemetery = this.cemeteries.find(c => c.id === id)
        cemetery.accessLevel = -1
        this.modifiedRights.cemeteries = this.modifiedRights.cemeteries.filter(p => p.id !== id)
        this.modifiedRights.cemeteries.push(cemetery)
      }
      this.addSitePermissions()
      this.$emit('modify', this.modifiedRights)
    },
    addSitePermissions () {
      const buildingSites = this.modifiedRights.buildings.map(x => {
        return {
          id: x.idSite,
          accessLevel: x.accessLevel
        }
      })
      const structureSites = this.modifiedRights.structures.map(x => {
        return {
          id: x.idSite, //this.structures.find(c => c.id === x.id).idSite,
          accessLevel: x.accessLevel
        }
      })
      const cemeterySites = this.modifiedRights.cemeteries.map(x => {
        return {
          id: x.idSite,
          accessLevel: x.accessLevel
        }
      })
      const siteAccessLevels = [...buildingSites, ...structureSites, ...cemeterySites]
      const siteIds = [...new Set(siteAccessLevels.map(x => x.id))]
      const sitePermissions = siteIds.map(id => {
        return {
          id: id,
          accessLevel: siteAccessLevels.filter(sal => sal.id === id)
            .map(sal => sal.accessLevel)
            .sort()[0]
        }
      })
      this.modifiedRights.sites = sitePermissions
    },
    siteDiffersFromRule (site) {
      const buildings = this.getHierarchyItemsForSite('building', site.idSite)

      const allPortfolioRules = this.userGroup.dynamicBuildingPermissions['portfolios']
      const allEvaluatorRules = this.userGroup.dynamicBuildingPermissions['evaluators']
      const allEstateManagerRules = this.userGroup.dynamicBuildingPermissions['estateManagers']
      const allRentalLiabilities = this.userGroup.dynamicBuildingPermissions['rentalLiabilities']

      // If there are no rules then it cannot differ.
      if (
        allPortfolioRules.length === 0 &&
        allEvaluatorRules.length === 0 &&
        allEstateManagerRules.length === 0 &&
        allRentalLiabilities.length === 0
      ) {
        return false
      }

      const portfolioAccessLevel = allPortfolioRules.filter(rule => rule.name === site.portfolio).map(x => x.accessLevel)
      if (portfolioAccessLevel && buildings.find(building => portfolioAccessLevel.includes(building.accessLevel))) {
        return false
      }

      const evaluatorAccessLevel = allEvaluatorRules.filter(rule => rule.name === site.evaluator).map(x => x.accessLevel)
      if (evaluatorAccessLevel && buildings.find(building => evaluatorAccessLevel.includes(building.accessLevel))) {
        return false
      }

      const estateManagerAccessLevel = allEstateManagerRules.filter(rule => rule.name === site.region).map(x => x.accessLevel)
      if (estateManagerAccessLevel && buildings.find(building => estateManagerAccessLevel.includes(building.accessLevel))) {
        return false
      }

      const rentalLiabilityAccessLevel = allRentalLiabilities.filter(rule => rule.name === site.region).map(x => x.accessLevel)
      if (rentalLiabilityAccessLevel && buildings.find(building => rentalLiabilityAccessLevel.includes(building.accessLevel))) {
        return false
      }

      return true
    },
    siteIsLocked (hierarchyItem) {
      return hierarchyItem.dynamicRightLocked === 0 ? false : true
    },
    toggleLock (hierarchyItem) {
      if (hierarchyItem.type !== 'building') {
        return
      }

      this.addUserGroupHierarchyRight(hierarchyItem.type, hierarchyItem.id, hierarchyItem.name, hierarchyItem.accessLevel, hierarchyItem.dynamicRightLocked === 0 ? 1 : 0)
    },
    toggleAccessLevel (groupItem, index) {
      const newPermissions = { ...this.userGroup.dynamicBuildingPermissions };
      newPermissions[groupItem][index].accessLevel = newPermissions[groupItem][index].accessLevel === 0 ? 1 : 0;
      this.$emit('input', { ...this.userGroup, accessLevel: newPermissions });
    },
    removeDynamicPermission (item, index) {
      const newPermissions = { ...this.userGroup.dynamicBuildingPermissions };
      newPermissions[item].splice(index, 1);
      this.$emit('input', { ...this.userGroup, dynamicBuildingPermissions: newPermissions });
    },
    getEstateManagerString (siteId) {
      var siteEstateManagers = this.availableSiteParties.filter(x => x.annotationLabel == 'Kiinteistökohteiden johtaja' && x.sites.includes(siteId))
      if (siteEstateManagers.length > 0)
      {
        siteEstateManagers = siteEstateManagers.map(x => {
          return x.name
        })
        var EstateManagerString = siteEstateManagers.join(", ")
        return EstateManagerString
      }
      return ""
    },
    getRentalLiabilityString (siteId) {
      const siteRentalLiabilities = this.availableSiteParties.filter(x => x.annotationLabel === 'Vuokrausvastuu (yhteistyökumppani)' && x.sites.includes(siteId))

      if (siteRentalLiabilities.length > 0) {
        return siteRentalLiabilities.map(x => x.name).join(', ')
      }

      return ''
    },
    getPaginationArray (itemsLength) {
      const pagArray = [10, 100]
      if (itemsLength > 1000) {
        pagArray.push(1000)
      }
      if (itemsLength < 5000) {
        pagArray.push(itemsLength)
      }
      return pagArray
    },
    async onRowsPerPageChange (val) {
      this.options.itemsPerPage = val
      await this.updatePage(1)
    }
  }
}
</script>

<style scoped>
.hierarchy-item {
  background-color: #f0f0f0;
}

.full-width {
  width: 100%;
}

.dynamic-rights-autocomplete {
  min-height: 12em !important;
}

.rights-outline {
  border: 1px solid var(--c-color-accent);
  border-radius: 1.5em;
  width: 100%;
  padding: 1em;
}

@media screen and (max-width: 600px) {
  .selection_button {
    width: 100%;
  }
}
</style>