<template>
  <div>
    <slot name="buttons" />
    <div
      v-if="!loading"
      class="viewSelection"
    >
      <v-select
        v-model="selectedView"
        :label="$t('Stored views')"
        :items="availableStoredViews"
        item-text="name"
        item-value="id"
        clearable
        :disabled="availableStoredViews.length === 0"
        class="ml-4 mb-2"
      >
        <template
          v-slot:selection="data"
        >
          {{ data.item.name }}
        </template>
        <template
          v-slot:item="data"
          class="storedViewSelection"
        >
          {{ getViewShortName(data.item.name) }}
          <v-spacer />
          <v-icon
            v-if="data.item.private || hasApplicationPermissionByName('VAKIOHAKU_LUONTI')"
            :style="{ 'margin-right': '0.4em' }"
            @click="deleteSelectedView(data.item.id)"
          >
            delete
          </v-icon>
        </template>
      </v-select>
    </div>
    <slot
      name="table"
      :props="{saveStoredView, storedViews, storedView, selectView, deleteSelectedView, confirmation}"
    />
    <div
      v-if="loading"
      style="text-align: center; padding: 100px"
    >
      <div
        class="text-h5"
        style="margin-bottom: 2em"
      >
        {{ $t('Gathering information. Wait a second...') }}
      </div>
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </div>
    <Alert
      :y="null"
      :show="showAlert"
      :result="actionResult"
      :message="alertMessage"
    />
  </div>
</template>

<script>
import Alert from '../../components/Alert.vue'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  emits: ['close-filter'],
    name: 'ViewAndSelections',
    components: {
        Alert,
    },
    props: {
        contractLevel: {
            default: () => 'AllContracts',
            type: String,
        },
        parentLevel: {
            default: () => 'contract',
            type: String,
        },
        loadingAsProp: {
          default: false,
          type: Boolean
        }
    },
    data () {
        return {
            confirmation: false,
            storedView: null,
            selectedView: null,
            actionResult: null,
            showAlert: false,
            alertMessage: null,
            privacy: null
        }
    },
    computed: {
        ...mapState('app', ['storedViews']),
        ...mapState('haltia', ['haltiaRentalStatusesLoading', 'futureHaltiaRentalStatusesLoading']),
        ...mapGetters('app', ['hasApplicationPermissionByName']),
        availableStoredViews () {
            return this.storedViews.filter(sv => sv.level === this.contractLevel)
        },
        loading () {
            return this.haltiaRentalStatusesLoading || this.futureHaltiaRentalStatusesLoading || this.loadingAsProp
        },
    },
    watch: {
        contractLevel () {
            this.selectedView = null
        },
        showAlert: function (boolean) {
            if (boolean) {
                setTimeout(() => {
                this.showAlert = false
                }, 4000)
            }
        },
        selectedView: function (view) {
            this.storedView = view ? this.storedViews.filter(sv => sv.level === this.contractLevel && sv.id === view)[0] : null
        }
    },
    mounted () {
        this.refreshData()
    },
    methods: {
        ...mapActions('app', ['getStoredViews']),
        async refreshData () {
            await this.getStoredViews(this.parentLevel)
        },
        selectView (view) {
            if (view) {
                this.selectedView = this.availableStoredViews.find(v => v.name === view.name).id
            } else {
                this.selectedView = null
            }
        },
        async saveStoredView (name, selectedHeaders, confirm, privacy = true) {
            this.confirmation = false
            const exists = this.availableStoredViews.includes(name)
            if (exists && !confirm) {
                this.confirmation = true
                this.actionResult = 'warning'
                this.alertMessage = this.$t('Warning') + ': ' + name + ' ' + this.$t('will be overwritten')
                this.storedView.stored_fields = selectedHeaders.map(h => {
                return {
                    field: h,
                    id_storedview: this.storedView.id
                }
              })
            } else {
                try {
                  var rename = false
                  if (exists) {
                    // Modify existing
                    await this.$rambollfmapi.storedviews.put(this.storedView.id, selectedHeaders)
                  } else {
                    // Create new        
                    const data = {
                        parent_level: this.parentLevel,
                        level: this.contractLevel,
                        name: name,
                        privacy: privacy
                    }
                    
                    await this.$rambollfmapi.storedviews.post(data, selectedHeaders).then(res => {
                      if (res == 'Name already exist') {
                        rename = true
                      }
                    })
                  }
                  if (rename == false) {
                    this.$emit("close-filter")
                    this.actionResult = 'success'
                    this.alertMessage = this.$t('Save success')
                    this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
                  } else {
                    this.actionResult = 'warning'
                    this.alertMessage = this.$t('Name already in use')
                  }
                } catch (error) {
                  this.actionResult = 'error'
                  this.alertMessage = this.$t('Error while saving')
                }
            }
            this.showAlert = true
            if (this.actionResult === 'success') {
                await this.getStoredViews(this.parentLevel)
                this.selectedView = this.availableStoredViews.find(view => view.name === name).id
            }
        },
        async deleteSelectedView (id) {
          const view = this.availableStoredViews.find(x => x.id === id)
          if (confirm(this.$t('storedview.deletion') + ' ' + view.name + '?' + '\n' + (view.private ? this.$t('storedview.deletion.private') : this.$t('storedview.deletion.public')))) {
            try {
                await this.$rambollfmapi.storedviews.delete(id)
                this.actionResult = 'success'
                this.alertMessage = this.$t('Succesfull delete')
                this.selectedView = null
            } catch (error) {
                this.actionResult = 'error'
                this.alertMessage = this.$t('Unsuccesfull delete')
            }
            await this.getStoredViews(this.parentLevel)
            this.showAlert = true
          }
        },
        getViewShortName (s) {
            const breakpoint = this.$vuetify.breakpoint
            var max = 0
            if (breakpoint.xl) {
                max = 40
            } else if (breakpoint.lg) {
                max = 20
            } else if (breakpoint.md) {
                max = 10
            } else if (breakpoint.sm) {
                max = 5
            } else if (breakpoint.xs) {
                max = 2
            }

            if (s.length > max) {
                return s.substring(0, max) + '...'
            }
            return s
        }
    }
}
</script>
<style scoped>
.storedViewSelection {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: clip;
  max-width: 100%;
  background-color: "blue";
}
.viewSelection {
  width: 25%;
}

@media screen and (max-width: 600px) {
  .viewSelection {
    width: 100%;
    padding-right: 5px;
  }
}
</style>