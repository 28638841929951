<template>
  <BaseModal
    @cancel="close"
  >
    <template #title>
      {{ $t('Adopt user') }}
    </template>
    <template #content>
      <div
        v-if="isLoading"
        style="text-align: center; padding: 2em;"
      >
        <v-progress-circular
          size="64"
          indeterminate
          color="primary"
        />
      </div>
      <div
        v-else
      >
        <div
          v-for="(org, index) in organizations" 
          :key="org.id"
        >
          <v-toolbar
            flat
            tile
            class="bg-gray"
          >
            <v-toolbar-title>
              {{ index+1 }}. {{ org.name }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items />
          </v-toolbar>
          <v-list
            dense
            class="pt-0"
          >
            <v-list-item
              v-for="ug in userGroups.filter(item => org.groups.find(el => el.id == item.groupId))"
              :key="ug.groupId"
              @click="changeUsergroupTo(ug.groupId)"
            >
              <v-list-item-icon>
                <v-icon>group</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ ug.groupName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </template>
    <template #footer />
  </BaseModal>
</template>

<script>
import VueCookies from 'vue-cookies'
import BaseModal from './general/BaseModal.vue'

export default {
  emits: ['close'],
  name: 'AdoptUsergroupForm',
  components: { BaseModal, },
  props: {
    visible: {
      default: () => {
        return true
      },
      type: Boolean
    }
  },
  data () {
    return {
      isLoading: false,
      organizations: [],
      userGroups: []
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getOrganizations()
    this.isLoading = false
  },
  methods: {
    async getOrganizations () {
      let orgs = await this.$rambollfmapi.userOrganizations.list()
      let usergroups = await this.$rambollfmapi.accounts.usergroups.get()
      orgs = orgs.map((item) => {
        item.groups = usergroups.filter(g => g.id_userorganization == item.id)
        return item
      })
      this.organizations = orgs;
      this.userGroups = await this.$rambollfmapi.permissions.groups().list()
    },
    async changeUsergroupTo (usergroupId) {
      this.isLoading = true
      // Create token with single usergroup for this user
      let result = await this.$rambollfmapi.accounts.loginAsUsergroup(usergroupId)

      // Store real session data for later
      VueCookies.set('realToken', VueCookies.get('token'), '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      VueCookies.set('realRefreshToken', VueCookies.get('refreshToken'), '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      VueCookies.set('realExpires', VueCookies.get('tokenExpires'), '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      VueCookies.set('realv3ViewerToken')

      // Replace current session with temporary session that has different usergroup
      let data = result.data
      VueCookies.set('token', data.token, new Date(new Date().getTime() + 60 * 60 * 1000), null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      VueCookies.set('refreshToken', data.refreshToken, '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      VueCookies.set('tokenExpires', data.expiration, '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')

      window.location = '/dashboard'
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
