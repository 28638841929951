<template>
  <div>
    <v-dialog
      v-model="showEditDialog"
      max-width="550"
      persistent
    >
      <v-card>
        <BaseModal
          @cancel="showEditDialog = false"
        >
          <template #title>
            {{ title }}
          </template>
          <template #content>
            <v-form ref="form">
              <v-container
                v-for="(item, index) in copyItems"
                :key="index"
                class="px-2 py-5"
              >
                <v-row
                  v-for="field in fields"
                  :key="field.id"
                >
                  <v-autocomplete
                    v-if="field.editType === 'Select'"
                    id="id"
                    v-model="item[field.id]"
                    :items="field.items"
                    :label="$t(field.label)"
                    item-text="label"
                    item-value="id"
                    clearable
                  />
                  <v-text-field 
                    v-if="field.editType === 'Text'"
                    v-model="item[field.id]"
                    :label="$t(field.label)"
                    :maxlength="field.maxLength"
                    class="ma-2"
                  />
                </v-row>
                <v-btn
                  text
                  rounded
                  outlined
                  class="mt-5 ma"
                  color="error"
                  depressed
                  @click="() => deleteItem(index)"
                >
                  {{ $t('Delete') }}
                </v-btn>
              </v-container>
              <v-btn
                v-if="copyItems.length < maxInstances"
                text
                elevation="0"
                color="primary"
                @click="addNew"
              >
                <v-icon left>
                  add
                </v-icon>
                {{ $t('Add new') }}
              </v-btn>
            </v-form>
          </template>
          <template #footer>
            <v-btn
              :disabled="invalid"
              :loading="saving"
              type="submit"
              rounded
              depressed
              color="primary"
              @click="save"
            >
              {{ $t('Save') }}
            </v-btn>
          </template>
        </BaseModal>
      </v-card>
    </v-dialog>
    <div class="inline-block pb-1">
      <v-icon
        v-if="!editDisabled"
        @click="showEditDialog = true"
      >
        edit
      </v-icon>
      <v-tooltip
        v-else
        bottom
      >
        <template #activator="{ on }">
          <v-icon
            v-on="on"
          >
            visibility
          </v-icon>
        </template>
        <span> {{ editDisabledInfo }}</span>
      </v-tooltip>
      <span class="px-2 font-weight-bold">{{ title }}:</span>
      <v-menu
        v-for="(item, index) in items"
        :key="index"
        bottom
        offset-y
      >
        <template #activator="{ on }">
          <v-chip
            class="mx-2"
            v-on="on"
          >
            {{ item[titleField.id] }}
          </v-chip>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item
              v-for="field in fields.filter(field => item[field.id])"
              :key="field.id"
            >
              <v-list-item-subtitle class="d-flex justify-start">
                {{ item[field.id] }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'
import lodash from 'lodash'

export default {
  emits: ['save'],
  name: 'MultipleEditField',
  components: {
    BaseModal,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    maxInstances: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      default: () => []
    },
    editDisabled: {
      type: Boolean,
      default: false
    },
    editDisabledInfo: {
      type: String,
      default: "" 
    }
  },
  data () {
    return {
      copyItems: [],
      showEditDialog: false,
      invalid: false,
      saving: false,
    }
  },
  computed: {
    titleField () {
      return this.fields.find(field => field.title)
    }
  },
  watch: {
    showEditDialog: {
      handler: function (value) {
        if (value) {
          this.open()
        }
      }
    }
  },
  mounted () {
    this.open()
  },
  methods: {
    async open () {
      if (this.items.length !== 0) {
        this.copyItems = lodash.cloneDeep(this.items)
      }
    },
    save () {
      this.saving = true
      this.$emit('save', this.copyItems)
      this.saving = false
      this.showEditDialog = false
    },
    addNew () {
      const object = this.fields.reduce((acc, field) => {
        acc[field.id] = null
        return acc
      }, {})
      this.copyItems.push(object)
    },
    deleteItem (index) {
      this.copyItems.splice(index, 1)
    },
    valuesAsString (item) {
      return this.fields.map(field => {
        if (field.editType === 'Text') {
          return item[field.id]
        }
        if (field.editType === 'Select') {
          return field.items.find(fieldItem => fieldItem.id === item[field.id])?.label
        }
        return ''
      }).filter(Boolean).join(', ')
    }
  }
}
</script>

<style scoped>
</style>