<template>
  <!--v-col>Tasklist</v-col-->
  <TaskListTable
    :data="data"
    :type="type"
    :loading="loading"
    :edit="edit"
    @handleDialog="handleTaskDialog"
    @continue="$emit('continue')"
    @handleDialogForComments="handleCommentDialog"
  />
</template>

<script>
import TaskListTable from './TaskListTable.vue'
import helpers from '../../helpers'
import { mapState, mapGetters } from 'vuex'

export default {
  emits: ['continue', 'taskDialogHandling', 'commentDialogHandling'],
  name: 'Tasklist',
  components: {
    TaskListTable: TaskListTable
  },
  props: {
    edit: Boolean
  },
  data () {
    return {
      data: {
        title: this.$t('LTP Tasks'),
        headers: [],
        years: [],
        items: [],
        footers: [],
        source: 'utilization',
        sortBy: ['siteName', 'taskCode'],
        sortDesc: [false, false],
        rowsPerPage: 19
      },
      loading: true,
      type: 'DataTable',
    }
  },
  computed: {
    ...mapState('app', ['buildings', 'sites']),
    ...mapState('ltp', [
      'searchedTasks',
      'loadingStatus',
      'yearRange',
      'selectedYear',
      'columnFilter'
    ]),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    esgPermission () {
      return this.hasApplicationPermissionByName('PTS_ESG_TEHTAVAT')
    }
  },
  watch: {
    loadingStatus: function () {
      if (!this.loadingStatus) {
        this.taskTableData()
      } else {
        this.loading = true
      }
    },
    columnFilter: function () {
      if (!this.loadingStatus) {
        this.loading = true
        this.taskTableData()
      }
    }
  },
  async mounted () {
    if (!this.loadingStatus) {
      this.taskTableData()
    }
  },
  methods: {
    taskTableData () {
      const items = this.searchedTasks.map(task => {
        const building = this.buildings.find(
          building => building.building_code === task.buildingId
        )
        const buildingName = building.building_name
        const siteName = this.sites.find(
          site => site.id_site === building.id_site
        ).name

        const start = new Date(task.implementationStart)
        const end = new Date(task.implementationEnd)

        // The impmelentation timespan will include the implementation month as well. Hence, the one month will be added by default.
        let duration = end.getMonth() - start.getMonth() + 1

        // Count full years
        if (start.getFullYear() < end.getFullYear()) {
          const years = end.getFullYear() - start.getFullYear()
          duration = duration + years * 12
        }

        let esgCode = null
        if (task.esgCodeId) {
          esgCode = helpers.ltpConstants.taskEsgCodes().find(c => c.value === task.esgCodeId).text
        }

        let taskSource = null
        if (task.taskSourceId) {
          taskSource = helpers.ltpConstants.taskSourceCodes().find(c => c.value === task.taskSourceId).text
        }

        let currency = null
        if (task.currencyId) {
          currency = helpers.ltpConstants.taskCurrencies().find(c => c.value === task.currencyId).text
        }

        let paybackPeriod = null
        let investmentYield = null
        if (task.budget > 0 && task.monetarySavings && task.monetarySavings > 0) {
          paybackPeriod = task.budget / task.monetarySavings
          investmentYield = task.monetarySavings / task.budget
        }
        return { ...task, buildingName, duration, siteName, paybackPeriod, investmentYield, currency, esgCode, taskSource }
      })

      const years = []
      for (let i = 0; i < this.yearRange; i++) {
        if (this.columnFilter.length > 0) {
          if (this.columnFilter.includes(`year${i}`)) {
            years.push({
              text: this.selectedYear + i,
              value: `year${i}`,
              isYear: true,
              year: this.selectedYear + i,
              dialog: 3,
            })
          }
        } else {
          years.push({
            text: this.selectedYear + i,
            value: `year${i}`,
            isYear: true,
            year: this.selectedYear + 1,
            dialog: 3,
          })
        }
      }
      let yearsFooter = [
        { value: 'year0', format: '' },
        { value: 'year1', format: '' },
        { value: 'year2', format: '' },
        { value: 'year3', format: '' },
        { value: 'year4', format: '' },
        { value: 'year5', format: '' },
        { value: 'year6', format: '' },
        { value: 'year7', format: '' },
        { value: 'year8', format: '' },
        { value: 'year9', format: '' },
      ]
      if (this.yearRange < 10) {
        yearsFooter = yearsFooter.slice(0, this.yearRange)
      }

      // reset headers and add new headers
      const headers = this.esgPermission ?
        [
          ...helpers.ltpConstants.startHeaders(),
          ...helpers.ltpConstants.esgHeaders(),
          ...years,
          ...helpers.ltpConstants.endHeaders()
        ] :
        [ 
          ...helpers.ltpConstants.startHeaders(),
          ...years,
          ...helpers.ltpConstants.endHeaders()
        ]
      const footers = this.esgPermission ?
        [
          ...helpers.ltpConstants.startFooters(),
          ...helpers.ltpConstants.esgFooters(),
          ...yearsFooter,
          ...helpers.ltpConstants.endFooters()
        ] :
        [ 
          ...helpers.ltpConstants.startFooters(),
          ...yearsFooter,
          ...helpers.ltpConstants.endFooters()
        ]  
      // apply column filter if it exists
      if (this.columnFilter.length > 0) {
        this.data.headers = headers.filter(header => {
          return this.columnFilter.includes(header.value)
        })
        this.data.footers = footers.filter(footer => {
          return this.columnFilter.includes(footer.headerValue) || this.columnFilter.includes(footer.value)
        })
      } else {
        this.data.headers = headers
        this.data.footers = footers
      }

      this.data.footers[0] = { text: this.$t('Total') }

      this.data.years = years
      this.data.items = items
      this.loading = false
    },
    handleTaskDialog (step, dialogOpen, task) {
      this.$emit('taskDialogHandling', step, dialogOpen, task)
    },
    handleCommentDialog (dialogOpen, task) {
      this.$emit('commentDialogHandling', dialogOpen, task)
    }
  }
}
</script>

<style scoped>
</style>
