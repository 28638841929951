<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid }"
  >
    <BaseModal
      :has-error="hasError"
      :error-message="$t(errorMessage)"
      @cancel="close()"
    >
      <template #title>
        {{ $t('site.new_site') }}
      </template>
      <template #content>
        <v-form
          ref="form"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :rules="`required`"
          >
            <v-autocomplete
              v-model="newSite.municipality"
              :items="municipalities.data"
              :label="$t('Municipality')"
              :loading="municipalities.fetching"
              :error-messages="errors[0]"
              :hint="$t('Required')"
              persistent-hint
              item-text="municipality"
              item-value="id"
              class="form-field"
              required
            />
          </ValidationProvider>

          <v-autocomplete
            v-model="newSite.portfolio"
            :label="$t('Portfolio')"
            :items="portfoliosWithNull"
            item-text="label"
            item-value="id"
            class="form-field mt-10"
          />

          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              v-model="newSite.name"
              :label="$t('Name')"
              :error-messages="errors[0]"
              :hint="$t('Required')"
              persistent-hint
              class="form-field"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="newSite.site_identifier"
              :label="$t('Site identifier')"
              :error-messages="$t(errors[0]) || errorMessage === 'site.site_identifier_error' ? $t(errorMessage) : ''"
              class="form-field mt-8"
              @change="resetError"
            />
          </ValidationProvider>
        </v-form>
      </template>
      <template #footer>
        <CdsButton
          type="accent"
          :disabled="invalid"
          :loading="saving"
          :loading-text="$t('site.creating_site')"
          @click="onCreateSite"
        >
          {{ $t('site.create_site') }}
        </CdsButton>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>
<script>
import BaseModal from './general/BaseModal.vue'
import { mapState, } from 'vuex'
import { ValidationProvider, ValidationObserver, } from 'vee-validate'
import CdsButton from './CircleDesignSystem/CdsButton.vue'

export default {
  emits: ['close'],
  name: 'AddSite',
  components: {
    BaseModal,
    CdsButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    portfolios: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      saving: false,
      hasError: false,
      errorMessage: null,
      createSiteDialog: true,
      municipalities: {
        fetching: false,
        data: []
      },
      newSite: {
        municipality: null,
        portfolio: null,
        name: null,
        site_identifier: '',
        visibility_status: null,
        start_date: new Date().toISOString().split('T')[0]
      }
    }
  }, 
  computed: {
    ...mapState('app', ['currentDate',]),
    portfoliosWithNull () {
      const nullPortfolio = {
        label: this.$t('Undefined'),
        id: null
      }
      return [nullPortfolio, ...this.portfolios]
    },
  },
  mounted () {
    this.getMunicipalities()
  },
  methods: {
    resetError (){
      this.hasError = false
      this.errorMessage = ''
    },
    async getMunicipalities () {
      this.municipalities.fetching = true
      this.municipalities.data = await this.$rambollfmapi.municipalities.list()
      this.municipalities.fetching = false
    },
    close () {
      this.saving = false
      this.createSiteDialog = false
      this.hasError = false
      this.errorMessage = null
      this.$refs.form.reset()
      this.$emit('close')
    },
    async onCreateSite () {
      this.saving = true
      this.hasError = false
      this.newSite.start_date = new Date(this.currentDate).toISOString().split('T')[0]
      this.newSite.visibility_status = 1

      const toSave = JSON.parse(JSON.stringify(this.newSite))
      const result = await this.$rambollfmapi.sites.create(toSave)

      if (result?.id_site) {
        this.$router.push(`/sites/${result.id_site}`)
        this.close()
      } else {
        this.hasError = true
        this.errorMessage = result.response.data.message
        this.saving = false
        this.$log.error(result)
      }
    },
  }
}
</script>
<style scoped>
</style>


