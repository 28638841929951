<template>
  <v-row
    id="site"
    class="layout-white flex-column"
  >
    <v-col class="body-text">
      <div>
        <v-expansion-panels
          v-for="userGroup in userGroups"
          :key="userGroup.groupId"
          :value="[0, 1]"
          multiple
        >
          <v-expansion-panel
            class="no-padding"
          >
            <v-expansion-panel-header>
              <v-icon>group</v-icon>
              {{ userGroup.groupName }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :header-props="dataTableHeaderDefinitions"
                :headers="tableHeaders"
                :items="filterUsers(userGroup.groupName)"
                class="elevation-0"
                :no-data-text="$t('No users in group')"
                :footer-props="{
                  itemsPerPageOptions: [],
                }"
              >
                <template
                  v-slot:item="props"
                >
                  <tr>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.username }}</td>
                    <td>{{ props.item.email }}</td>
                    <td>{{ props.item.telephone }}</td>
                    <td>{{ props.item.company }}</td>
                    <td>{{ props.item.validUntil }}</td>
                    <!-- Kommentoitu piiloon koska ei olla varmoja
                halutaanko pois täältä
                <td>{{props.item.title}}</td>-->
                  </tr>
                </template>
                <template
                  v-slot:footer.page-text="item"
                >
                  {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
                  {{ item.itemsLength.toLocaleString('fi-FI') }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      userGroups: [],
      users: [],
      tableHeaders: [
        { text: this.$t('Name'), value: 'name', width: '300px' },
        { text: this.$t('Username'), value: 'username', width: '300px' },
        { text: this.$t('Email'), value: 'email', width: '300px' },
        { text: this.$t('Telephone'), value: 'telephone', width: '300px' },
        { text: this.$t('Company'), value: 'company', width: '300px' },
        { text: this.$t('Valid until'), value: 'validUntil', width: '300px' }
        // Kommentoitu piiloon koska ei olla varmoja halutaanko poistaa täältä
        // { text: this.$t('Title personnel'), value: 'title', width: '300px' }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions']),
    customerId () {
      return this.$store.state.app.userInfo.customer_id
    }
  },
  mounted () {
    this.getUsers()
    this.getUserGroups()
  },
  methods: {
    reload () {
      this.getUsers()
    },
    async getUsers () {
      this.users = await this.$rambollfmapi.accounts.list()
      Object.entries(this.users).forEach(function (key, index, value) {
        const date = moment(String(key['1'].validUntil)).format('DD.MM.YYYY')
        key['1'].validUntil = date
      })
    },
    async getUserGroups () {
      this.userGroups = await this.$rambollfmapi.permissions.groups().list()
    },
    filterUsers: function (filter) {
      const filteredUsers = this.users
        .filter(user =>
          user.userGroups.some(userGroup => userGroup.groupName === filter)
        )
        .map(user => {
          // return Object.assign({}, user, {userGroups : user.userGroups.filter(userGroup => userGroup.groupName === filter)})
          return {
            ...user,
            userGroups: user.userGroups.filter(
              userGroup => userGroup.groupName === filter
            )
          }
        })
      return filteredUsers
    }
  }
}
</script>
<style>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}

.form-fields {
  margin-top: 20px !important;
}

.checkbox-width {
  min-width: 25% !important;
}

.noPadding {
  padding: 0;
  margin: 8px;
}

.fullscreen-settings {
  padding: 0px;
  height: 100%;
  width: 100%;
  right: 0;
  top: -111px;
  position: absolute;
  background-color: white;
}

.layout-white {
  background-color: white;
}
</style>
