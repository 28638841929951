<template>
  <v-main>
    <v-dialog 
      v-model="showStatusPortfolioSelectionDialog"
      persistent
      max-width="850"
    >
      <StatusPortfolioSelection
        v-if="showStatusPortfolioSelectionDialog"
        :portfolios="sortedPortfolios"
        :multiple-select="tabLocation === 'internalrent.dashboard'"
        :selected-portfolios="filteredPortfolios"
        @cancel="showStatusPortfolioSelectionDialog = false"
        @updatePortfolioSelections="updatePortfolioSelections"
      />
    </v-dialog>
    <nav :aria-label="$t('SubMenu')" >
      <v-row no-gutters>
        <v-col>
          <v-tabs
            class="pl-5"
            show-arrows
            cols="auto"
          >
            <v-tab
              :to="{ name: 'internalrent.dashboard' }"
              exact
              @click="updateTabLocation"
            >
              {{ $t('Dashboard') }}
            </v-tab>
            <template>
              <v-tooltip
                top
                :disabled="!disableView"
              >
                <template #activator="{ on }">
                  <div 
                    class="d-flex"
                    v-on="on"
                  >
                    <v-tab
                      :disabled="disableView"
                      exact
                      :to="{name: 'internalrent.budgeting_and_realisation', params: {id_portfolio: filteredPortfolios[0] ?? 0}}"
                      @click="updateTabLocation"
                    >
                      {{ $t('internalrent.budgeting_and_realisation') }}
                    </v-tab>
                  </div>
                </template>
                <span v-if="disableView">{{ $t('View restriction') }}</span>
              </v-tooltip>
            </template>

            <v-tab
              v-if="hasApplicationPermissionByName('SIS_KULUKOHDISTUS')"
              :to="{ name: 'internalrent.allocation' }"
              exact
              @click="updateTabLocation"
            >
              {{ $t('Allocations') }}
            </v-tab>

            <template>
              <v-tooltip
                top
                :disabled="!disableView"
              >
                <template #activator="{ on }">
                  <div 
                    class="d-flex"
                    v-on="on"
                  >
                    <v-tab
                      :disabled="disableView"
                      exact
                      :to="{name: 'internalrent.cost_centers', params: {id_portfolio: filteredPortfolios[0] ?? 0}}"
                      @click="updateTabLocation"
                    >
                      {{ $t('internalrent.cost_centers') }}
                    </v-tab>
                  </div>
                </template>
                <span v-if="disableView">{{ $t('View restriction') }}</span>
              </v-tooltip>
            </template>
          </v-tabs>
        </v-col>
      </v-row>
      <v-divider />
    </nav>
    <BaseView>
      <template #selections>
        <h1 class="d-sr-only">
          {{ $t('Internal Rent') }}
        </h1>
        <v-select
          v-model="selectedMonth"
          :label="$t('internalrent.inspection_month')"
          :items="months"
          item-text="text"
          item-value="value"
          class="date-select mr-2"
          :disabled="tabLocation === 'internalrent.budgeting_and_realisation'"
        >
          <template #selection="{ item }">
            <span v-if="tabLocation === 'internalrent.budgeting_and_realisation'">{{ $t('Whole year') }}</span>
            <span v-else>{{ item.text }}</span>
          </template>
        </v-select>

        <v-select
          v-model="selectedYear"
          :label="$t('internalrent.inspection_year')"
          :items="inspectionYears"
          item-text="text"
          item-value="value"
          class="date-select"
        />
      </template>
      <template #buttons> 
        <CdsButton
          icon-left="work_outline"
          type="accent"
          @click="showStatusPortfolioSelectionDialog = true"
        >
          <span
            v-if="filteredPortfolios.length > 1"
            class="pr-1"
          >
            {{ filteredPortfolios.length }}
          </span>
          <span>
            {{ selectedPortfoliosAmount }}
          </span>
        </CdsButton>
      </template>
    </BaseView>
    <div class="pa-4">
      <Dashboard
        v-if="tabLocation === 'internalrent.dashboard'"
        :filtered-portfolios="filteredPortfolios"
      />
      <StatusPortfolio v-if="tabLocation === 'internalrent.status_portfolio' && portfolio" />
      <CostCenters v-if="tabLocation === 'internalrent.cost_centers' && portfolio" />
      <Allocation v-if="tabLocation === 'internalrent.allocation'" />
      <BudgetingAndRealisation
        v-if="tabLocation === 'internalrent.budgeting_and_realisation' && portfolio"
        :inspection-year="selectedYear"
      />
    </div>
  </v-main>
</template>
<script>
import Dashboard from '../components/InternalRent/Dashboard.vue'
import StatusPortfolio from '../components/InternalRent/StatusPortfolio.vue'
import CostCenters from '../components/InternalRent/CostCenters.vue'
import BudgetingAndRealisation from '../components/InternalRent/BudgetingAndRealisation.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import StatusPortfolioSelection from '../components/InternalRent/StatusPortfolioSelection.vue'
import Allocation from '../components/InternalRent/Allocation.vue'
import CdsButton from '../components/CircleDesignSystem/CdsButton.vue'
import BaseView from '../components/general/BaseView.vue'

export default {
  name: 'InternalRent',
  components: {
    Dashboard,
    StatusPortfolio,
    CostCenters,
    BudgetingAndRealisation,
    StatusPortfolioSelection,
    Allocation,
    CdsButton,
    BaseView,
  },
  metaInfo () {
    return {
      title: `${this.$t('Internal Rent')} ·`,
    }
  },
  data () {
    return {
      selectedYear: null,
      inspectionYears: [],
      selectedMonth: {},
      months: [
        { text: this.$t('Whole year'), value: 0},
        { text: this.$t('January'), value: 1 },
        { text: this.$t('February'), value: 2 },
        { text: this.$t('March'), value: 3 },
        { text: this.$t('April'), value: 4 },
        { text: this.$t('May'), value: 5 },
        { text: this.$t('June'), value: 6 },
        { text: this.$t('July'), value: 7 },
        { text: this.$t('August'), value: 8 },
        { text: this.$t('September'), value: 9 },
        { text: this.$t('October'), value: 10 },
        { text: this.$t('November'), value: 11 },
        { text: this.$t('December'), value: 12 }
      ],
      filteredPortfolios: [], // Array to store portfolio selections
      showStatusPortfolioSelectionDialog: false,
      loaded: false,
      tabLocation: []
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    ...mapState('internalRent', ['portfolio', 'portfolios', 'inspectionYear', 'inspectionMonths']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    sortedPortfolios () {
      return this.portfolios.slice().sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
    },
    disableView () {
      // computed value used to control when budget button is disabled
      if (this.filteredPortfolios.length === 1) {
        return false
      }
      return true
    },
    selectedPortfoliosAmount () {
      if (this.filteredPortfolios.length > 1) {
        return this.$t('internalrent.selected_portfolios')
      } else if (this.filteredPortfolios.length === 1) {
        const portfoliosName = this.sortedPortfolios.find(portfolio => portfolio.id === this.filteredPortfolios[0]).name
        return portfoliosName
      } else {
        return this.$t('internalrent.select_portfolio')
      }
    },
    fromAllocationCode () {
      return this.hasApplicationPermissionByName('SIS_KULUKOHDISTUS') ? true : false
    }
  },
  watch: {
    selectedYear: function (value) {
      this.setInspectionYear(value)
    },
    selectedMonth: function (value) {
      if (value !== 0) {
        this.setInspectionMonths([value])
        this.setAllocationMonth(value - 1)
      } else {
        this.setInspectionMonths(this.months.map(x => x.value).filter(x => x !== 0))
      }
    },
    inspectionYear: function (value) {
      if (this.filteredPortfolios.length === 1) {
        this.getPortfolio({
          portfolioId: this.filteredPortfolios[0],
          current_year: value,
          from_allocation_code: this.fromAllocationCode
        })
      }
    },
    filteredPortfolios: function () {
      if (this.filteredPortfolios.length === 1) {
        this.getPortfolio({
          portfolioId: this.filteredPortfolios[0],
          current_year: this.inspectionYear,
          from_allocation_code: this.fromAllocationCode
        })
      }
    },
    fromAllocationCode: function () {
      if (this.filteredPortfolios.length === 1) {
        this.getPortfolio({
          portfolioId: this.filteredPortfolios[0],
          current_year: this.inspectionYear,
          from_allocation_code: this.fromAllocationCode
        })
      }
    },
    tabLocation: function (tab) {
      this.setSelectedView(tab)
    }
  },
  async mounted () {
    const currentYear = this.currentDate.getFullYear()
    this.inspectionYears = [
      { text: `${currentYear - 1}`, value: currentYear - 1 },
      { text: `${currentYear}`, value: currentYear },
      { text: `${currentYear + 1}`, value: currentYear + 1 },
      { text: `${currentYear + 2}`, value: currentYear + 2 }
    ]
    this.selectedMonth = this.currentDate.getMonth() + 1
    await this.getPortfolios()
    await this.getExpenses()
    this.updateTabLocation()
    if (this.filteredPortfolios.length == 0) {
      // Select all portfolios by default when comin to Internal Rent view
      this.portfolios.forEach( portfolio => this.filteredPortfolios.push(portfolio.id) )
    }
    this.selectedYear = currentYear
    this.loaded = true
    this.setSelectedView(this.tabLocation)
  },
  methods: {
    ...mapActions('internalRent', [
      'getPortfolio',
      'getPortfolios',
      'getExpenses'
    ]),
    ...mapMutations('internalRent', [
      'setInspectionMonths',
      'setAllocationMonth',
      'setInspectionYear',
      'setSelectedView'
    ]),
    changeYear (year) {
      this.selectedYear = year
    },
    updatePortfolioSelections (selectedPortfolios) {
      this.showStatusPortfolioSelectionDialog = false
      this.filteredPortfolios = selectedPortfolios
    },
    updateTabLocation () {
      // Initialize first tab to be open
      if (this.$router.history.current.name === 'internalrent') {
        this.$router.push({name: 'internalrent.dashboard'})
      }
      if (this.$router.history.current.params.id_portfolio) {
        this.filteredPortfolios = []
        this.filteredPortfolios.push(Number(this.$router.history.current.params.id_portfolio))
      } 
      this.tabLocation = this.$router.history.current.name
    }
  }
}
</script>
<style scoped>
.date-select {
  max-width: 15em;
}
</style>
