import { render, staticRenderFns } from "./TaskEsg.vue?vue&type=template&id=21ba681e&scoped=true"
import script from "./TaskEsg.vue?vue&type=script&lang=js"
export * from "./TaskEsg.vue?vue&type=script&lang=js"
import style0 from "./TaskEsg.vue?vue&type=style&index=0&id=21ba681e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ba681e",
  null
  
)

export default component.exports