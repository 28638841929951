<template>
  <v-select
    v-model="model"
    :class="selectedClass"
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    @change="$emit('change', model)"
  >
    <template #selection="{ item }">
      {{ item.code }}
    </template>
    <template #item="{ item }">
      <v-list-item-avatar color="blue lighten-5">
        <v-icon 
          v-if="item.icon"
          color="blue darken-1"
        >
          {{ item.icon }}
        </v-icon>
        <span
          v-else
          style="font-size:90%; color:#1E88E5; font-weight:bold;"
        >  
          {{ item.symbol }} 
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.code }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
export default {
  emits: ['change'],
  name: 'CurrencyField',
  props: {
    value: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    selectedClass: {
      type: String,
      default: null
    },
    itemText: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
      this.model = this.value
      }
    }
  },
}

</script>