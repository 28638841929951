<template>
  <div>
    <div class="text-h5 grey lighten-2">
      <v-btn
        large
        icon
        absolute
        right
        style="margin-top: 0.8em"
        @click="closeWindow();"
      >
        <v-icon>close</v-icon>
        <span class="d-sr-only">{{ $t('Close') }}</span>
      </v-btn>
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
        style="width:80%"
      >
        {{ $t(requestTypes[selectedRequest.id_type].title) + ': ' + selectedRequest.title }}
      </v-card-title>
    </div>
    <v-row
      class="navigation pt-2"
      no-gutters
    >
      <v-col>
        <template v-for="tab in tabs">
          <v-btn
            v-if="tab.show()"
            :key="tab.id"
            :class="{ active: activeTab === tab.id}"
            class="float-left"
            large
            text
            @click="activeTab = tab.id"
          >
            <v-icon>{{ tab.icon }}</v-icon>
            {{ tab.label }}
          </v-btn>
        </template>
      </v-col>
    </v-row>
    <v-card-text v-if="activeTab === 0">
      <v-row
        align="end"
        justify="end"
        no-gutters
      >
        <v-menu v-if="newVersion === null && canUserEdit">
          <template #activator="{ on }">
            <CdsButton
              v-if="canUserEdit"
              type="tertiary"
              v-on="on"
            >
              {{ $t('Update service request') }}
            </CdsButton>
          </template>
          <v-list>
            <v-list-item
              v-for="state in availableStates"
              :key="state.id"
              @click="setNewVersionState(state.id)"
            >
              <v-list-item-title>{{ state.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <div>
        <v-timeline dense>
          <v-timeline-item
            v-if="newVersion"
            :color="states[newVersion.state].color"
            :icon="states[newVersion.state].icon"
            icon-color="black"
          >
            <v-card>
              <v-card-title class="message-box-title">
                <h3 align-end>
                  {{ states[newVersion.state].label }}
                </h3>
              </v-card-title>
              <v-card-text class="message-box-container">
                <v-textarea
                  v-model="newVersion.comment"
                  :rules="counter400"
                  class="message-box"
                  autofocus
                  counter="400"
                />
              </v-card-text>
              <v-row
                align="end"
                justify="end"
                no-gutters
              >
                <CdsButton
                  type="tertiary"
                  @click="newVersion = null"
                >
                  {{ $t('Cancel') }}
                </CdsButton>
                <CdsButton
                  type="accent"
                  :disabled="commentSavingDisabled"
                  :loading="saving"
                  :loading-text="$t('Loading...')"
                  @click="saveNew"
                >
                  {{ $t('Save') }}
                </CdsButton>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-for="event in timeline"
            :key="event.id"
            :color="states[event.state].color"
            :icon="states[event.state].icon"
            icon-color="black"
          >
            <v-card>
              <v-card-title>
                <v-row
                  justify="space-between"
                  no-gutters
                >
                  <h3>
                    {{ states[event.state].label }}
                  </h3>
                  <span>{{ getHumanReadableDateTime(event.time) }}</span>
                </v-row>
              </v-card-title>
              <v-card-text>{{ event.text }}</v-card-text>
              <v-row
                align="end"
                justify="end"
                class="pr-4 pb-2"
                no-gutters
              >
                -{{ event.creator }}
              </v-row>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-card-text>
    <v-card-text v-if="activeTab === 1">
      <template v-if="selectedRequestDocuments">
        <v-list>
          <v-list-item
            v-for="doc in selectedRequestDocuments"
            :key="doc.idDocument"
            two-line
          >
            <v-list-item-avatar>
              <v-icon>file_copy</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ doc.filename }}</v-list-item-title>
              <v-list-item-subtitle>{{ doc.type }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <CdsButton
                type="primary"
                size="small"
                @click="download(doc)"
              >
                {{ $t('Open') }}
              </CdsButton>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-card-text>
    <v-card-text v-if="activeTab === 2">
      <template v-if="selectedRequestLinks">
        <div
          v-for="linkType in linkTypes"
          :key="linkType.id"
        >
          <template
            v-if="selectedRequestLinks[linkType.accessor] && selectedRequestLinks[linkType.accessor].length > 0"
          >
            <v-col class="section-textfield">
              <h4 class="text--secondary">
                {{ linkType.label }}
              </h4>
            </v-col>
            <v-list>
              <v-list-item
                v-for="link in selectedRequestLinks[linkType.accessor]"
                :key="link"
              >
                <v-list-item-content>{{ link }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </div>
      </template>
    </v-card-text>
    <v-card-text v-if="activeTab === 3">
      <template v-if="selectedRequest && selectedRequest.versions.length">
        <v-col class="section-textfield">
          <h4 class="text--secondary">
            {{ $t('zoan.ordered_products') }}
          </h4>
        </v-col>
        <v-list
          v-for="product in zoanProducts"
          :key="product.label"
          class="ma-0 pa-0"
        >
          <v-list-item>
            <v-list-item-content>{{ product.label + ": " + product.value }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-col class="section-textfield">
          <h4 class="text--secondary">
            {{ $t('Comments') }}
          </h4>
        </v-col>
        <v-list
          v-for="comment in zoanComments"
          :key="comment.label"
          class="ma-0 pa-0"
        >
          <v-list-item>
            <v-list-item-content>{{ comment.label + ": " + comment.value }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-card-text>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
// import Table from '../components/Table.vue'
import helpers from '../helpers'
import Alert from '../components/Alert.vue'
import CdsButton from '../components/CircleDesignSystem/CdsButton.vue'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'ServiceRequestsWindow',
  components: { Alert, CdsButton },
  data () {
    return {
      selectedRequestDocuments: null,
      selectedRequest: null,
      newVersion: null,
      requestTypes: helpers.requestTypes.types(),
      linkTypes: [
        { id: 0, label: this.$t('Site'), accessor: 'Sites' },
        { id: 1, label: this.$t('Building'), accessor: 'Buildings' },
        { id: 2, label: this.$t('Floor'), accessor: 'Floors' },
        { id: 3, label: this.$t('Unit'), accessor: 'Units' },
        { id: 4, label: this.$t('Space'), accessor: 'Spaces' }
      ],
      states: [
        { id: 0, label: this.$t('New job'), color: '#fb8c00', icon: 'new_releases' },
        { id: 1, label: this.$t('Job started'), color: '#2A74B5', icon: 'how_to_reg' },
        { id: 2, label: this.$t('Job done'), color: '#47ccaa', icon: 'done' },
        { id: 3, label: this.$t('Job removed'), color: 'white', icon: 'delete' }
      ],
      activeTab: 0,
      tabs: [
        {
          label: this.$t('Timeline'),
          icon: 'access_time',
          id: 0,
          show: function () {
            return true
          }
        },
        {
          label: this.$t('Documents'),
          icon: 'file_copy',
          id: 1,
          show: this.hasDocuments
        },
        { label: this.$t('Links'), icon: 'link', id: 2, show: this.hasLinks },
        {
          label: this.$t('Comments'),
          icon: 'notes',
          id: 3,
          show: this.isZoanRequest
        }
      ],
      saving: false,
      userHasEditRights: false,
      userHasZoanEditRights: false,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      counter400: [v => v.length <= 400 || this.$t('Maximum length {n} characters', {n: 400})]
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    timeline () {
      if (this.selectedRequest === null) {
        return []
      } else {
        return this.selectedRequest.versions
          .map(event => {
            return {
              id: event.id,
              text: event.comment,
              time: event.date,
              state: event.state,
              creator: event.creator
            }
          })
          .reverse()
      }
    },
    availableStates () {
      const editRights = this.isZoanRequest()
        ? this.userHasZoanEditRights
        : this.userHasEditRights
      if (editRights) {
        return this.states.filter(state => state.id === 1 || state.id === 2 || (state.id === 3 && this.isSuperAdmin))
      } else {
        return this.states.filter(state => state.id === 3 && this.isSuperAdmin)
      }
    },
    canUserEdit () {
      if (this.selectedRequest === null) {
        return false
      }
      const lastVersionState = this.selectedRequest.versions[
        this.selectedRequest.versions.length - 1
      ].state

      const editRights = this.isZoanRequest()
        ? this.userHasZoanEditRights
        : this.userHasEditRights
      if (lastVersionState === 2 || lastVersionState === 3) {
        return false
      } else if (/* lastVersionState === 1 && */ editRights === false) {
        return false
      } else {
        return true
      }
    },
    zoanProducts () {
      if (this.selectedRequest.versions.length) {
        const request = this.selectedRequest.versions[
          this.selectedRequest.versions.length - 1
        ]
        return [
          {
            label: this.$t('zoan.floor_plan'),
            value: this.answer(request.floor_plan)
          },
          {
            label: this.$t('zoan.interior_visualization'),
            value: this.answer(request.interior_visualization)
          },
          {
            label: this.$t('zoan.exterior_visualization'),
            value: this.answer(request.exterior_visualization)
          },
          {
            label: this.$t('zoan.presentation_material'),
            value: this.answer(request.presentation_material)
          }
        ]
      } else {
        return []
      }
    },
    zoanComments () {
      if (this.selectedRequest.versions.length) {
        const request = this.selectedRequest.versions[
          this.selectedRequest.versions.length - 1
        ]

        const deliveryFormats = helpers.requestTypes.deliveryFormats()
        return [
          {
            label: this.$t('zoan.request_schedule'),
            value: request.request_schedule
              ? helpers.humanize.date(
                new Date(request.request_schedule).toISOString(),
                'date'
              )
              : ' - '
          },
          {
            label: this.$t('zoan.quality'),
            value: request.quality
              ? this.$t('zoan.standard')
              : this.$t('zoan.marketing')
          },
          {
            label: this.$t('zoan.furniture'),
            value: this.answer(request.furniture)
          },
          {
            label: this.$t('zoan.delivery_format'),
            value: deliveryFormats[request.delivery_format]
          },
          {
            label: this.$t('zoan.reference_to_invoicing'),
            value: request.reference_to_invoicing
          }
        ]
      } else {
        return []
      }
    },
    isSuperAdmin () {
      // comparison because not superadmin value is object not boolean
      return this.$store.state.app.superAdmin === true
    },
    commentSavingDisabled () {
      return this.newVersion.comment.length <= 400 ? false : true
    }
  },
  watch: {
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    }
  },
  methods: {
    ...mapMutations('app', ['setPopupOpen']),
    hasDocuments () {
      if (this.selectedRequestDocuments === null) {
        return false
      } else if (this.selectedRequestDocuments.length > 0) {
        return true
      } else {
        return false
      }
    },
    hasLinks () {
      for (const prop in this.selectedRequestLinks) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.selectedRequestLinks,
            prop
          ) &&
          this.selectedRequestLinks[prop].length > 0
        ) {
          return true
        }
      }
      return false
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    getHumanReadableDateTime (date) {
      return helpers.humanize.date(date)
    },
    setNewVersionState (stateId) {
      const latestVersion = this.selectedRequest.versions[
        this.selectedRequest.versions.length - 1
      ]
      this.newVersion = {
        comment: '',
        id_request: this.selectedRequest.id,
        date: new Date(),
        state: stateId,
        floor_plan: latestVersion.floor_plan,
        interior_visualization: latestVersion.interior_visualization,
        exterior_visualization: latestVersion.exterior_visualization,
        presentation_material: latestVersion.presentation_material,
        quality: latestVersion.quality,
        request_schedule: latestVersion.request_schedule,
        delivery_format: latestVersion.delivery_format,
        furniture: latestVersion.furniture,
        reference_to_invoicing: latestVersion.reference_to_invoicing
      }
    },
    download (doc) {
      this.$rambollfmapi.documents
        .download(doc.idDocument, { retrievalProgress: 0 })
        .then(res => {
          const blob = new Blob([res.data], { type: 'octet/stream' })
          helpers.saveAs(blob, doc.filename)
        })
    },
    async saveNew () {
      this.saving = true

      try {
        this.newVersion.date = new Date()
        await this.$rambollfmapi.support.servicerequest().postVersion(this.newVersion.id_request, this.newVersion)
        const newRequest = await this.$rambollfmapi.support.servicerequest().get(this.selectedRequest.id)
        const serviceData = await this.$rambollfmapi.support.servicerequest().list()

        this.newVersion = null
        this.selectedRequest = newRequest
        this.servicesData = serviceData
      } catch {
        this.indicatorMessage = this.$t("Error while saving")
        this.operationResult = 'error'
        this.showIndicator = true
      }

      this.saving = false
    },
    closeWindow () {
      self.close()
    },
    populateData () {
      if (this.$route.params.extra !== 'null') {
        var extraString = this.$route.params.extra
        var comment = ''

        if (extraString.includes('details:')) {
          comment = extraString
            .split('details:')[1]
            .split(';')[0]
            .split(':nl:')
            .join('\n')
          extraString = extraString.split('details:')[0]
        }

        if (extraString.includes('status:')) {
          var state = extraString.split('status:')[1].split(';')[0]

          this.newVersion = {
            comment: comment,
            id_request: this.selectedRequest.id,
            date: new Date(),
            state: state
          }
        }
      }
      this.loading = false
    },
    isZoanRequest () {
      return this.selectedRequest ? this.selectedRequest.id_type === 3 : false
    },
    answer (value) {
      return value ? this.$t('Yes') : this.$t('No')
    }
  },
  async mounted () {
    this.setPopupOpen(true)
    const id = this.$route.params.id_request
    this.selectedRequest = await this.$rambollfmapi.support
      .servicerequest()
      .get(id)
    this.selectedRequestLinks = await this.$rambollfmapi.support
      .servicerequest()
      .links(id)
    this.selectedRequestDocuments = await this.$rambollfmapi.support
      .servicerequest()
      .documents(id)

    if (this.hasApplicationPermissionByName('RAMBOLL_TILAUKSET')) {
      this.userHasEditRights = true
    }
    if (this.hasApplicationPermissionByName('ZOAN_TILAUKSET')) {
      this.userHasZoanEditRights = true
    }
    this.populateData()
  }
}
</script>
<style scoped>
div .v-timeline-item__body .v-sheet {
  border-radius: 2em;
}

div .v-timeline-item:first-of-type .v-timeline-item__body .v-sheet {
  border: 1px solid var(--c-color-accent);
}

div .v-timeline-item:first-of-type >>> .v-timeline-item__dot {
  background-color: var(--c-color-accent);
}

.message-box-container {
  padding: 0;
}

.message-box-title {
  padding-bottom: 0;
}

.left {
  border-radius: 20px 20px 0 0 !important;
  border: 1px solid lightgrey;
  border-bottom: 0;
  width: 200px;
  margin: 0px;
  background-color: #f5f5f5 !important;
  margin-left: 0.5em;
}

.navigation {
  background-color: #f5f5f5;
  border-bottom: 1px solid lightgray;
}

.v-btn.active {
  border-bottom: 0px;
  background: white !important;
  color: black !important;
  bottom: -1px;
}
</style>
