<template>
  <v-row
    id="site-info"
    class="px-4 py-6"
  >
    <v-dialog
      v-if="dataUpdateDialog"
      v-model="dataUpdateDialog"
      max-width="600"
      max-height="300"
      persistent
      scrollable
    >
      <v-card style="max-height: 600px">
        <v-card-title class="toolbar dialog">
          <v-row
            align="center"
          >
            <v-col class="grow">
              <h2>{{ $t('Import from spreadsheet') }}</h2>
            </v-col>
            <v-spacer />
            <v-col class="shrink">
              <v-btn
                large
                icon
                @click="dataUpdateDialog = false"
              >
                <v-icon>close</v-icon>
                <span class="d-sr-only">{{ $t('Close') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="padding: 2em">
          <div>
            <div v-if="importSuccess">
              <div v-if="rentalStatusChanges.length">
                <h3>{{ $t('dataimport.import_success') }}</h3>
                <h4>{{ $t('dataimport.list_changes') }}</h4>
                <ul>
                  <li
                    v-for="(status, idx) in rentalStatusChanges"
                    :key="idx"
                  >
                    {{ status.buildingCode + ' ' + status.unitName }}
                  </li>
                </ul>
              </div>
              <span v-else-if="savingFailed">{{
                $t('dataimport.saving_failed')
              }}</span>
              <span v-else>{{ $t('dataimport.no_changes') }}</span>
              <div v-if="errors.length && !savingFailed">
                <h4>{{ $t('dataimport.changes_ignored') }}</h4>
                <ul>
                  <li
                    v-for="(error, idx) in errors"
                    :key="idx"
                  >
                    {{
                      error.buildingCode +
                        ' ' +
                        error.unitName +
                        ' - ' +
                        error.label +
                        ': ' +
                        error.value
                    }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <h3>{{ $t('dataimport.import_failed') }}</h3>
              <h4
                v-if="
                  importErrors.uneditable && importErrors.uneditable.length > 0
                "
              >
                {{
                  $t('dataimport.not_editable') +
                    ': ' +
                    importErrors.uneditable.join(', ')
                }}
              </h4>
              <h4
                v-if="
                  importErrors.unexisting && importErrors.unexisting.length > 0
                "
              >
                {{
                  $t('dataimport.not_existing') +
                    ': ' +
                    importErrors.unexisting.join(', ')
                }}
              </h4>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            outlined
            rounded
            @click="dataUpdateDialog = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            v-if="importSuccess && rentalStatusChanges.length"
            :disabled="isSavingChanges"
            rounded
            depressed
            class="primary"
          >
            <span v-if="!isSavingChanges">
              {{ $t('Save changes') }}
            </span>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            />
          </v-btn>
          <v-btn
            v-else
            rounded
            depressed
            color="primary"
            @click=";(dataUpdateDialog = false), (errors = [])"
          >
            {{ $t('Ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="spaceModifyDialog">
      <v-card>
        <SpaceModify
          v-if="spaceModifyDialog"
          :spaces="siteSpaces"
          :customizable-fields="customizableDataFields.items"
          @close="spaceModifyDialog = false"
          @saveError="
            showIndicator = true
            operationResult = 'error'
          "
          @saveSuccess="
            showIndicator = true
            operationResult = 'success'
          "
          @update="updateWidget"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="unitModifyDialog">
      <v-card>
        <UnitModify
          v-if="unitModifyDialog"
          :units="siteUnits"
          @close="unitModifyDialog = false"
          @saveError="
            unitModifyDialog = false
            showIndicator = true
            operationResult = 'error'
          "
          @saveSuccess="
            unitModifyDialog = false
            showIndicator = true
            operationResult = 'success'
          "
          @update="updateWidget"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="hasApplicationPermissionByName('HALYTYKSET')"
      v-model="showAddAlertDialog"
      persistent
      max-width="550"
    >
      <AddAlert
        v-if="showAddAlertDialog"
        :edit="true"
        :edit-object="alertToBeEdited"
        view="site"
        @close="showAddAlertDialog = false"
        @alertSaved="updateWidget"
      />
    </v-dialog>
    <v-dialog
      v-if="showCreateRentContractModal"
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          :contract-id="editedContractId"
          :out-rent="outRent"
          @contractIdUpdated="onContractIdUpdated"
          @handleSave="onCreateRentContractModalClosed"
        />
      </v-card>
    </v-dialog>
    <v-col
      lg="2"
      cols="12"
    >
      <div>
        <Gallery
          :key="siteId"
          :site-id="siteId"
          :disabled="!hasWritePermission"
        />
      </div>

      <div class="widget">
        <Map
          :key="siteId"
          :center="map.mapCenter"
          :center-projection="map.mapProjection"
          :maps="map.mapLayers"
          :zoom="map.mapZoom"
          class="map"
          map-id="dashboard-map"
        />
      </div>
    </v-col>

    <v-col
      lg="4"
      cols="12"
    >
      <h1
        id="site-content"
        class="d-sr-only"
      >
        {{ currentSiteName }}
      </h1>
      <div
        v-for="(widget) in widgets.filter(w => w.id === 'site' && isWidgetVisible(w))"
        :key="widget.id"
      >
        <v-expansion-panels
          v-if="currentSite"
          :value="widget.maximized[0] === true ? [0] : []"
          multiple
        >
          <v-expansion-panel
            v-for="(source, idx) in Array.isArray(widget.data.items)
              ? widget.data.items
              : widget.data.items !== undefined
                ? [widget.data.items]
                : []"
            :key="widget + '-' + idx"
            v-model="widget.maximized"
            class="no-padding"
          >
            <v-expansion-panel-header>
              <v-row
                align="center"
                justify="start"
                no-gutters
              >
                <v-icon v-if="widget.data.icon !== null">
                  {{
                    widget.data.icon
                  }}
                </v-icon>
                {{
                  widget.data.titleSource
                    ? source[widget.data.titleSource]
                    : $t(widget.id)
                }}
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row v-if="widget.loading">
                <v-spacer />
                <v-col class="shrink">
                  <v-progress-circular
                    size="64"
                    indeterminate
                    color="primary"
                    style="margin: 3em"
                  />
                </v-col>
                <v-spacer />
              </v-row>
              <div v-else>
                <MetadataForm
                  v-if="widget.type === 'MetadataForm'"
                  :metadata="widget.data.metadata"
                  :value="source"
                  :category="widget.data.category"
                  :disabled="!hasWritePermission"
                  :multiline="widget.data.multiline"
                  :with-status-icon="true"
                  :tags="widget.data.tags"
                  @change="fieldUpdate"
                />
              </div>
              <div
                class="subpanel"
              >
                <SiteModification
                  :site-id="source.id_site"
                  :disabled="!hasWritePermission"
                  @saved="reload"
                />
                <AddSiteAreas
                  :disabled="!hasWritePermission"
                  @saved="reload"
                />
                <AddNewSiteLinkDialog
                  :add-new-link-dialog="siteLinksDialogOpen"
                  @refreshLinkData="updateSiteLinks"
                  @close="siteLinksDialogOpen = false"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <draggable
        v-model="smallWidgets"
        :disabled="!isDraggable"
      >
        <div
          v-for="(widget) in smallWidgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            v-if="isWidgetDynamic(widget)"
            :id="widget.id"
            :type="widget.type"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            :style="{ 'border-color': draggableColor }"
            :is-rights-to-modify="widget.isRightsToModify"
            :is-rights-to-add="widget.isRightsToAdd"
            :maximized="widget.maximized"
            @dataUpdate="dataUpdated(widget, $event)"
            @addNewLinkDialogOpen="siteLinksDialogOpen = true"
          />
          <v-expansion-panels
            v-else-if="currentSite"
            :style="{ 'border-color': draggableColor }"
          >
            <!-- v-model doesn't work the same anymore on v-expansion-panel inside v-expansion-panels, so workaround onclick -->
            <v-expansion-panel
              v-for="(source, idx) in Array.isArray(widget.data.items)
                ? widget.data.items
                : widget.data.items !== undefined
                  ? [widget.data.items]
                  : []"
              :key="widget.id + '-' + idx"
              class="no-padding"
              @click="onExpansionPanelClick(widget)"
            >
              <v-expansion-panel-header>
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                >
                  <v-icon v-if="widget.data.icon !== null">
                    {{
                      widget.data.icon
                    }}
                  </v-icon>
                  {{
                    widget.data.titleSource
                      ? source[widget.data.titleSource]
                      : $t(widget.id)
                  }}
                  <!-- SmallWidgets: {{ widget.maximized[0] }} -->
                  <span
                    v-if="
                      (Array.isArray(widget.data.items)
                        ? widget.data.items
                        : [widget.data.items]
                      ).length > 1
                    "
                  >({{ idx + 1 }} / {{ widget.data.items.length }})</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="widget.loading">
                  <v-spacer />
                  <v-col class="shrink">
                    <v-progress-circular
                      size="64"
                      indeterminate
                      color="primary"
                      style="margin: 3em"
                    />
                  </v-col>
                  <v-spacer />
                </v-row>
                <div v-else>
                  <MetadataForm
                    v-if="widget.type === 'MetadataForm'"
                    :metadata="widget.data.metadata"
                    :value="source"
                    :category="widget.data.category"
                    :disabled="!hasWritePermission"
                    :multiline="widget.data.multiline"
                    :with-status-icon="true"
                    :tags="widget.data.tags"
                    @change="fieldUpdate"
                  />

                  <template v-if="source">
                    <!-- Original condition for showing  building permits subpanel. Commented out for further development (Not in use at the moment)
                  <v-expansion-panel
                    v-if="source.building_code && widget.id === 'site.buildings'" -->
                    <v-expansion-panel
                      v-if="false"
                      expand
                      class="subpanel"
                    >
                      <v-expansion-panel-content>
                        <div slot="header">
                          {{ $t('Building permits') }}
                        </div>
                        <BuildingPermits
                          :building-code="source.building_code"
                          :disabled="!hasWritePermission"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Original condition for showing  building permits subpanel. Commented out for further development (Not in use at the moment)
                    <v-expansion-panel
                      v-if="source.building_code && widget.id === 'site.buildings'" -->
                    <v-expansion-panel
                      v-if="false"
                      expand
                      class="subpanel"
                    >
                      <v-expansion-panel-content>
                        <div slot="header">
                          {{ $t('Partial buildings') }}
                        </div>
                        <PartialBuildings
                          :building-code="source.building_code"
                          :disabled="!hasWritePermission"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <div
                      v-if="widget.id === 'site.estates'"
                      class="subpanel"
                    >
                      <EstateModification
                        :estate-id="source.estate_id"
                        :estate-identifier="source.estate_identifier"
                        :disabled="!hasWritePermission"
                        @saved="reload"
                      />
                    </div>
                    <div
                      v-if="source.building_code && widget.id === 'site.buildings'"
                      class="subpanel"
                    >
                      <BuildingModification
                        :site-id="source.id_site"
                        :building-id="source.id_building"
                        :building-code="source.building_code"
                        :disabled="!hasWritePermission"
                        @delete="customIndicator"
                        @update="customIndicator"
                      />
                    </div>
                  </template>

                  <SiteParties
                    v-if="widget.type === 'Parties'"
                    :parties="siteParties.parties"
                    :party-links="siteParties.partyLinks"
                    :site-id="siteId"
                    :disabled="!hasWritePermissionForResponsibilities"
                    :responsibilities-loading="responsibilitiesLoading"
                    @update="reloadSiteParties"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </draggable>
      <v-row
        class="flex-column"
        style="right: 0; bottom: 0; position: sticky; z-index: 1"
      >
        <v-row
          v-if="changes.length > 0 || isSavingChanges"
        >
          <v-spacer />
          <v-btn
            v-if="!isSavingChanges"
            :class="fieldError ? '' : 'primary'"
            :disabled="fieldError"
            rounded
            depressed
            @click="saveChanges()"
          >
            {{ $t('Save changes') }}
          </v-btn>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-row>
        <v-row>
          <v-spacer />
          <v-btn
            v-if="isDraggable"
            outlined
            rounded
            text
            @click="getWidgetOrder()"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            v-if="isDraggable"
            rounded
            depressed
            class="primary"
            @click="setWidgetOrder()"
          >
            {{ $t('Save default view') }}
          </v-btn>
        </v-row>
      </v-row>
    </v-col>
    <v-col
      lg="6"
      cols="12"
    >
      <div
        v-for="widget in widgets.filter(
          (w) => (w.id === 'site.description' || w.id === 'site.lease_plan' || w.id === 'site.additional_info') && isWidgetVisible(w)
        )"
        :key="widget.id"
        class="widget"
      >
        <v-expansion-panels
          v-if="currentSite !== undefined"
          :value="widget.maximized[0] ? [0] : []"
          multiple
        >
          <v-expansion-panel
            v-if="currentSite !== undefined"
            v-model="widget.maximized"
            class="no-padding"
          >
            <v-expansion-panel-header
              v-if="widget.data.title !== null"
            >
              <v-icon v-if="widget.data.icon !== null">
                {{
                  widget.data.icon
                }}
              </v-icon>
              {{ $t(widget.id) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="(source, idx) in Array.isArray(widget.data.items)
                ? widget.data.items
                : widget.data.items !== undefined
                  ? [widget.data.items]
                  : []"
              :key="widget.id + '-' + idx"
            >
              <v-row v-if="widget.loading">
                <v-spacer />
                <v-col class="shrink">
                  <v-progress-circular
                    size="64"
                    indeterminate
                    color="primary"
                    style="margin: 3em"
                  />
                </v-col>
                <v-spacer />
              </v-row>
              <div v-else>
                <MetadataForm
                  v-if="widget.type === 'MetadataForm'"
                  :metadata="widget.data.metadata"
                  :value="source"
                  :category="widget.data.category"
                  :disabled="!hasSiteCommentPermission"
                  :multiline="widget.data.multiline"
                  @change="fieldUpdate"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        v-for="widget in widgets.filter(
          (w) => w.id === 'site.site_notebook' && isWidgetVisible(w)
        )"
        :key="widget.id"
        class="widget"
      >
        <div v-if="widget.loading">
          <v-spacer />
          <v-col class="shrink">
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
              style="margin: 3em"
            />
          </v-col>
          <v-spacer />
        </div>
        <div
          v-else
        >
          <Notes
            v-if="hasApplicationPermissionByName('LEASING_PROSPEKTIT')"
            :single-site="currentSite"
            class="mb-4"
          />
        </div>
      </div>

      <!-- normal -->
      <draggable
        v-model="largeWidgets"
        :disabled="!isDraggable"
      >
        <div
          v-for="widget in largeWidgets"
          :key="widget.id"
          class="widget"
        >
          <DynamicWidget
            :id="widget.id"
            :type="widget.type"
            :has-action="widget.action ? true : false"
            :data="widget.data"
            :title="$t(widget.id)"
            :loading="widget.loading"
            :style="{ 'border-color': draggableColor }"
            :maximized="widget.maximized"
            :is-rights-to-modify="widget.isRightsToModify"
            :is-rights-to-add="widget.isRightsToAdd"
            @dataUpdate="dataUpdated(widget, $event)"
            @open="widget.maximized = $event"
            @edit="startEditing(widget.action)"
            @updateWidget="updateWidget"
            @triggerCustomEvents="triggerCustomEvents"
          >
            <template
              v-if="widget.id == 'site.alerts'"
              #customComponent="{ item }"
            >
              <v-btn
                v-if="item.createdBySelf || item.alertSelf"
                icon
                small
                class="ma-0 ml-4"
                :aria-label="$t('Modify')"
                @click="editAlert(item)"
              >
                <v-icon
                  small
                >
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                v-if="item.createdBySelf"
                icon
                small
                class="ma-0 ml-2"
                :aria-label="$t('Delete')"
                @click="deleteAlert(item.id)"
              >
                <v-icon
                  small
                >
                  delete
                </v-icon>
              </v-btn>
            </template>
          </DynamicWidget>
        </div>
      </draggable>
    </v-col>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import Gallery from '../components/widgets/Gallery.vue'
import DynamicWidget from '../components/DynamicWidget.vue'
import Map from '../components/Map.vue'
import Alert from '../components/Alert.vue'
import MetadataForm from '../components/MetadataForm.vue'
import SiteParties from '../components/SiteParties.vue'
import BuildingPermits from '../components/BuildingPermits.vue'
import PartialBuildings from '../components/PartialBuildings.vue'
import BuildingModification from '../components/BuildingModification.vue'
import AddSiteAreas from '../components/AddSiteAreas.vue'
import AddNewSiteLinkDialog from '../components/AddNewSiteLinkDialog.vue'
import EstateModification from '../components/EstateModification.vue'
import SiteModification from '../components/SiteModification.vue'
import SpaceModify from '../components/SpaceModify.vue'
import UnitModify from '../components/UnitModify.vue'
import draggable from 'vuedraggable'
import helpers from '../helpers'
import proj4 from 'proj4'
import GraveyardWidgets from '../widgets/graveyard.js'
import {
  EmptyRentalSpaces,
  Legal,
  Acquisition,
  Apartments,
  Comments,
  LeasePlan,
  unitsWithFloorPlan,
  unitsWithoutFloorPlan,
  unitsFromFloors,
  siteDevices,
} from '../widgets/site'
import Notes from '../components/Leasing/Notes.vue'
import { AlertsWidget } from '../widgets/alerts.js'
import AddAlert from '../components/AddAlert.vue'
import CreateRentContractModal from '../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'

export default {
  name: 'SiteInfo',
  components: {
    Gallery,
    Map,
    Alert,
    MetadataForm,
    SpaceModify,
    UnitModify,
    DynamicWidget,
    SiteParties,
    BuildingPermits,
    PartialBuildings,
    BuildingModification,
    AddSiteAreas,
    AddNewSiteLinkDialog,
    EstateModification,
    SiteModification,
    draggable,
    Notes,
    AddAlert: AddAlert,
    CreateRentContractModal: CreateRentContractModal
  },
  metaInfo () {
    return {
      title: `${this.currentSite?.name ? this.currentSite.name + ' · ' : ''} ${this.$t('Sites')} ·`,
    }
  },
  data () {
    return {
      // Page specific
      siteChanged: false,
      responsibilitiesLoading: true,
      // Map
      map: {
        mapProjection: 'EPSG:3857',
        mapCenter: [2807128, 9638898],
        mapZoom: 5,
        mapLayers: [
          {
            name: 'Main map',
            title: 'Main map',
            type: 'OSM',
            opacity: 1
          }
        ],
        mapViewport: null
      },
      // Widgets
      widgetOrder: [],
      largeWidgets: [],
      smallWidgets: [],
      draggableColor: null,
      unitData: undefined,
      unitMetadata: undefined,
      siteSpacesUpdated: false,
      widgets: [
        ...GraveyardWidgets.Sites(),
        Legal(),
        Acquisition(),
        Apartments(),
        {
          id: 'site',
          type: 'MetadataForm',
          data: {
            icon: 'location_on',
            source: 'currentSite',
            titleSource: 'name',
            metadataSource: 'siteMetadata',
            metadata: null,
            category: 'None'
          },
          small: true,
          loading: true,
          maximized: [true]
        },
        {
          id: 'site.contact_details',
          type: 'MetadataForm',
          data: {
            icon: '',
            source: 'currentSite',
            titleSource: null,
            metadataSource: 'siteMetadata',
            metadata: null,
            category: 'Contact'
          },
          small: true,
          loading: true,
          maximized: [false]
        },
        {
          id: 'site.buildings',
          type: 'MetadataForm',
          data: {
            icon: 'home',
            source: 'buildingsForCurrentSite',
            titleSource: 'building_name',
            metadataSource: 'buildingMetadata',
            metadata: null,
            category: 'None'
          },
          small: true,
          loading: true,
          statucIcon: false,
          maximized: [false, false, false, false, false]
        },
        {
          id: 'site.structures',
          type: 'MetadataForm',
          data: {
            icon: 'house_siding',
            source: 'structuresForCurrentSite',
            titleSource: 'structure_name',
            title: null,
            metadataSource: 'structureMetadata',
            metadata: null,
            category: 'None'
          },
          small: true,
          loading: true,
          statucIcon: false,
          maximized: [false, false, false, false, false]
        },
        {
          id: "site.links",
          type: "LinkTableWithButtons",
          data: {
            items: [],
            source: 'getCurrentSiteLinks'
          },
          small: true,
          loading: false
        },
        {
          id: 'site.estates',
          type: 'MetadataForm',
          data: {
            icon: 'storage',
            source: 'estatesForCurrentSite',
            titleSource: 'name',
            metadataSource: 'estateMetadata',
            metadata: null,
            category: 'None'
          },
          small: true,
          loading: true,
          maximized: [false]
        },
        {
          id: 'site.responsibilities',
          type: 'Parties',
          data: {
            icon: null,
            source: 'currentSite',
            titleSource: null,
            metadataSource: 'siteMetadata',
            metadata: null,
            category: 'None'
          },
          small: true,
          loading: false,
          maximized: [false]
        },
        {
          id: 'site.metadata',
          type: 'MetadataForm',
          data: {
            icon: null,
            source: 'currentSite',
            titleSource: null,
            metadataSource: 'siteMetadata',
            metadata: null,
            category: 'Metadata'
          },
          small: true,
          loading: true,
          maximized: [false]
        },
        Comments(),
        LeasePlan(),
        EmptyRentalSpaces(),
        {
          id: 'site.additional_info',
          type: 'MetadataForm',
          data: {
            icon: null,
            source: 'currentSite',
            titleSource: null,
            metadataSource: 'siteMetadata',
            metadata: null,
            category: 'AdditionalInfo',
            multiline: true
          },
          small: false,
          loading: true,
          maximized: [true]
        },
        {
          id: 'site.rental_units',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Unit'), value: 'unit_name', unitLink: true },
              { text: this.$t('Unit use'), value: 'unit_class', format: 'Translation' },
              { text: this.$t('Tenant'), value: 'tenant' },
              {
                text: this.$t('VAT responsibility'),
                value: 'vat_responsibility'
              },
              { text: this.$t('Unit area (VAT)'), value: 'unit_area_vat', format: 'Area' },
              { text: this.$t('Portion (VAT)'), value: 'percentage_vat', format: 'Percentage' },
              {
                text: this.$t('Unit area (Not VAT)'),
                value: 'unit_area_not_vat',
                format: 'Area'
              },
              {
                text: this.$t('Portion (Not VAT)'),
                value: 'percentage_not_vat',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              { value: 'unit_area_vat', format: 'Area' },
              {
                value: 'percentage_vat',
                format: 'Percentage',
                unit: 'percentage',
                numeratorSum: 'unit_area_vat',
                denominator: 'total_rentable_area_by_unit',
              },
              { value: 'unit_area_not_vat', format: 'Area' },
              {
                value: 'percentage_not_vat',
                unit: 'percentage',
                format: 'Percentage',
                numeratorSum: 'unit_area_vat',
                denominator: 'total_rentable_area_by_unit',
              }
            ],
            items: [],
            report: 'rentalUnits',
            sortBy: 'start_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.free_spaces',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Space number'), value: 'space_name' },
              { text: this.$t('Net room area'), value: 'net_room_area', format: 'Area' },
              { text: this.$t('Purpose of use'), value: 'use' },
              {
                text: this.$t('All workstations (PCS)'),
                value: 'workstation_count',
                format: 'Number'
              },
              {
                text: this.$t('Vacant workstations (PCS)'),
                value: 'vacant_workstations',
                format: 'Number'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'net_room_area', format: 'Area' },
              {},
              { value: 'workstation_count', format: 'Number' },
              { value: 'vacant_workstations', format: 'Number' }
            ],
            items: [],
            report: 'spacesWithWorkstations'
          },
          maximized: [false]
        },
        {
          id: 'site.tenants',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'buildingName' },
              { text: this.$t('Tenant'), value: 'curPartyName' },
              { text: this.$t('Unit area'), value: 'area', format: 'Area' },
              { text: this.$t('Unit code long'), value: 'unit_code_long' },
              { text: this.$t('Unit'), value: 'unitName', unitLink: true },
              { text: this.$t('Unit use'), value: 'unitUsage', format: 'Translation' },
              { text: this.$t('Start date'), value: 'curPartyStartDate', format: 'Date' },
              { text: this.$t('End date'), value: 'curPartyEndDate', format: 'Date' },
              { text: this.$t('Apartment type'), value: 'apartmentType'}
            ],
            footers: [
              { text: this.$t('Total') },
              { text: '' },
              { value: 'area', format: 'Area' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' }
            ],
            items: [],
            report: 'tenants',
            sortBy: 'curPartyEndDate',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.unit_areas_by_ownership',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Owner'), value: 'owner' },
              { text: this.$t('Leasable area'), value: 'leasable_area', format: 'Area' },
              { text: this.$t('Leased area'), value: 'leased_area', format: 'Area' },
              { text: this.$t('Free area'), value: 'free_area', format: 'Area' },
              { text: this.$t('Other area'), value: 'other_area', format: 'Area' },
              { text: this.$t('Technical area'), value: 'technical_area', format: 'Area' },
              { text: this.$t('Public area'), value: 'public_area', format: 'Area' },
              {
                text: this.$t('notrentable.repairs'),
                value: 'construction_area',
                format: 'Area'
              },
              { text: this.$t('notrentable'), value: 'notrentable_area', format: 'Area' },
              { text: this.$t('notrentable.unknown'), value: 'unknown_area', format: 'Area' }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'leasable_area', format: 'Area' },
              { value: 'leased_area', format: 'Area' },
              { value: 'free_area', format: 'Area' },
              { value: 'other_area', format: 'Area' },
              { value: 'technical_area', format: 'Area' },
              { value: 'public_area', format: 'Area' },
              { value: 'construction_area', format: 'Area' },
              { value: 'notrentable_area', format: 'Area' },
              { value: 'unknown_area', format: 'Area' }
            ],
            items: [],
            report: 'unitAreasByOwnership'
          },
          maximized: [false]
        },
        {
          id: 'site.carparks',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Carpark'), value: 'number' },
              { text: this.$t('Carpark code long'), value: 'carpark_code_long' },
              { text: this.$t('Area'), value: 'area', format: 'Area'},
              { text: this.$t('Floor'), value: 'floor' },
              { text: this.$t('Type'), value: 'type' },
              { text: this.$t('Handicap spot'), value: 'invalid_spot', format: 'YesNoUnknown' },
              { text: this.$t('Plug in spot'), value: 'heating', format: 'YesNoUnknown' },
              { text: this.$t('Charge in'), value: 'charge_in' },
              { text: this.$t('Tenant'), value: 'tenant' },
              { text: this.$t('Next tenant'), value: 'next_tenant' },
              { text: this.$t('Start date'), value: 'start_date', format: 'Date' },
              { text: this.$t('End date'), value: 'end_date', format: 'Date' }
            ],
            items: [],
            report: 'carparkStatus',
            sortBy: 'number',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.carparksandparkingzones',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Carpark'), value: 'number' },
              { text: this.$t('Carpark code long'), value: 'carpark_code_long' },
              { text: this.$t('Type'), value: 'type' },
              { text: this.$t('Renting Type'), value: 'rentingtype', format: 'Translation' },
              { text: this.$t('Renter'), value: 'renter' },
              { text: this.$t('Contract number'), value: 'contract_number' },
              { text: this.$t('Tenant'), value: 'tenant' },
              { text: this.$t('Num of car spaces'), value: 'num_of_car_spaces_without_rights', format: 'Number' },
              { text: this.$t('Available car spaces'), value: 'num_of_free_car_spaces', format: 'Number' },
              { text: this.$t('Num of parking privileges'), value: 'num_of_parking_rights', format: 'Number' },
              { text: this.$t('Available parking privileges'), value: 'num_of_free_parking_rights', format: 'Number' },
              { text: this.$t('Area'), value: 'area', format: 'Area'},
              { text: this.$t('Charge in'), value: 'charge_in' },
              { text: this.$t('Charging spots amount'), value: 'charging_spots_amount', format: 'Number' },
              { text: this.$t('Charging power'), value: 'charging_power', format: 'Number' },
              { text: this.$t('Charging info'), value: 'charging_info' },
            ],
            footers: [
              { text: this.$t('Total') },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { value: 'num_of_car_spaces_without_rights', format: 'Number' },
              { value: 'num_of_free_car_spaces', format: 'Number' },
              { value: 'num_of_parking_rights', format: 'Number' },
              { value: 'num_of_free_parking_rights', format: 'Number' },
              { value: 'area', format: 'Area' },
              { text: '' },
              { value: 'charging_spots_amount', format: 'Number' },
              { text: '' },
              { text: '' },
            ],
            items: [],
            report: 'carparksandparkingzones',
            sortBy: 'number',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.rental_contracts',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'buildingName' },
              { text: this.$t('Tenant'), value: 'partyName' },
              { text: this.$t('Contract number'), value: 'contractNumber' },
              { text: this.$t('Unit area'), value: 'area', format: 'Area' },
              { text: this.$t('Unit'), value: 'unitName' },
              { text: this.$t('Start date'), value: 'start_date', format: 'Date' },
              { text: this.$t('End date'), value: 'end_date', format: 'Date' }
            ],
            footers: [
              { text: this.$t('Total') },
              { text: '' },
              { text: '' },
              { value: 'area', format: 'Area' }
            ],
            items: [],
            report: 'rentalContracts',
            sortBy: 'end_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.unit_vacancies_by_unit_usage',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Unit use'), value: 'usage', format: 'Translation' },
              { text: this.$t('Facility area'), value: 'total_area', format: 'Area' },
              { text: this.$t('Spaces owned by KOY'), value: 'estate_area', format: 'Area' },
              { text: this.$t('Rented floor area'), value: 'leased_area', format: 'Area' },
              { text: this.$t('Free spaces'), value: 'free_area', format: 'Area' },
              {
                text: this.$t('Vacancy rate'),
                value: 'vacancy_percentage',
                format: 'Percentage'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'total_area', format: 'Area' },
              { value: 'estate_area', format: 'Area' },
              { value: 'leased_area', format: 'Area' },
              { value: 'free_area', format: 'Area' },
              {
                value: 'vacancy_percentage',
                format: 'Percentage',
                hundredMinusValue: true,
                numeratorSum: 'leased_area',
                denominatorSum: 'total_area_minus_estate_area',
              }
            ],
            items: [],
            report: 'unitVacanciesByUsage',
            sortBy: 'usage',
            sortDesc: true
          }
        },
        {
          id: 'site.electricity_meters',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Meter number'), value: 'meter_number' },
              { text: this.$t('Tenant'), value: 'party_name' },
              { text: this.$t('Total room area'), value: 'total_area', format: 'Area' },
              { text: this.$t('Tenant space area'), value: 'area_for_tenant', format: 'Area' },
              { text: this.$t('Tenant share'), value: 'percentage_for_tenant', format: 'Percentage' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'total_area', format: 'Area'},
              { value: 'area_for_tenant', format: 'Area' },
              {}
            ],
            items: [],
            report: 'electricityMetersByTenant',
            sortBy: 'meter_number',
            sortDesc: true
          }
        },
        {
          id: 'site.spaces_by_purpose_of_use',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Main usage'), value: 'main_usage' },
              { text: this.$t('Purpose of use'), value: 'space_use' },
              { text: this.$t('Net room area'), value: 'area', format: 'Area' },
              { text: this.$t('Percentage of space area'), value: 'percentage_of_space_area', format: 'Percentage' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'area', format: 'Area' },
              {}
            ],
            items: [],
            report: 'spacesByPurposeOfUse',
            sortBy: 'start_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.personnel',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Name'), value: 'name' },
              { text: this.$t('Cost center'), value: 'cost_center' },
              { text: this.$t('Space number'), value: 'room' },
              { text: this.$t('Floor'), value: 'floor' }
            ],
            items: [],
            report: 'sitePersonnel',
            sortBy: 'start_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.vacant_spaces_from_costcenters',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Space number'), value: 'room_number' },
              { text: this.$t('Net room area'), value: 'area', format: 'Area' },
              { text: this.$t('Purpose of use'), value: 'purpose' },
              { text: this.$t('Cost center'), value: 'costcenter' },
              { text: this.$t('Share'), value: 'share', format: 'Percentage' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'shared_area', format: 'Area' },
              {},
              {},
              {}
            ],
            items: [],
            report: 'costcenterVacancies'
          }
        },
        {
          id: 'site.cost_areas',
          type: 'MetaDataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Cost center identifier'), value: 'costCenterIdentifier' },
              { text: this.$t('Cost center name'), value: 'costCenterName' },
              { text: this.$t('Domain'), value: 'domainIdentifier' },
              { text: this.$t("Domain's name"), value: 'domainName' },
              { text: this.$t('Net room area'), value: 'area', format: 'Area' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Allocated unit area'), value: 'allocated_unit_area', format: 'Area' }
            ],
            footers: [
              { text: this.$t('Total'), alwaysVisible: true },
              { headerValue: 'costCenterIdentifier' },
              { headerValue: 'costCenterName' },
              { headerValue: 'domainIdentifier' },
              { headerValue: 'domainName' },
              { value: 'area', headerValue: 'area', format: 'Area' },
              { value: 'unit_area', headerValue: 'unit_area', format: 'Area' },
              { value: 'allocated_unit_area', headerValue: 'allocated_unit_area', format: 'Area' }
            ],
            items: [],
            source: 'costCenterAreas',
            sortBy: 'start_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.cost_areas_personnel',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              {
                text: this.$t('Cost center identifier'),
                value: 'costCenterIdentifier'
              },
              { text: this.$t('Cost center name'), value: 'costCenterName' },
              { text: this.$t('Domain'), value: 'domainIdentifier' },
              { text: this.$t("Domain's name"), value: 'domainName' },
              { text: this.$t('Net room area'), value: 'area', format: 'Area' },
              { text: this.$t('Person count'), value: 'personnelCount', format: 'Number' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              { value: 'area', format: 'Area' },
              { value: 'personnelCount', format: 'Number' }
            ],
            items: [],
            report: 'costCenterAreas',
            sortBy: 'start_date',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.purpose_zone_status',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization'},
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Floor name'), value: 'floor_name' },
              {
                text: this.$t('Purpose zone defined'),
                value: 'purpose_zone_defined_area',
                format: 'Area'
              },
              {
                text: this.$t('Purpose zone undefined'),
                value: 'purpose_zone_undefined_area',
                format: 'Area'
              },
              { text: this.$t('Undefined spaces'), value: 'spaces' },
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'purpose_zone_defined_area', format: 'Area' },
              { value: 'purpose_zone_undefined_area', format: 'Area' },
              {}

            ],
            items: [],
            report: 'purposeZoneStatus'
          }
        },
        {
          id: 'site.units',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              {
                text: this.$t('Cost center'),
                value: 'site_identifier',
                editable: false,
                type: undefined
              },
              {
                text: this.$t('Building code'),
                value: 'buildingCode',
                editable: false,
                type: undefined
              },
              {
                text: this.$t('Unit'),
                value: 'unitName',
                editable: false,
                type: undefined,
                unitLink: true
              },
              {
                text: this.$t('Unit Status'),
                value: 'unit_status',
                editable: false,
                type: undefined
              },
              {
                text: this.$t('Contract no.'),
                value: 'contractNumber',
                editable: false,
                type: undefined
              },
              {
                text: this.$t('Tenant'),
                value: 'curPartyName',
                editable: false,
                type: undefined
              },
              {
                text: this.$t('Contract party'),
                value: 'contract_party',
                editable: true,
                type: undefined
              },
              {
                text: this.$t('Validity'),
                value: 'validity',
                editable: true,
                type: undefined
              },
              {
                text: this.$t('Contract date'),
                value: 'signatureDate',
                editable: true,
                format: 'Date'
              },
              {
                text: this.$t('Contract start date'),
                value: 'curPartyStartDate',
                editable: true,
                format: 'Date'
              },
              {
                text: this.$t('Contract first possible end date'),
                value: 'contractFirstPossibleEndDate',
                editable: true,
                format: 'Date'
              },
              {
                text: this.$t('Contract end date'),
                value: 'curPartyEndDate',
                editable: true,
                format: 'Date'
              },
              {
                text: this.$t('Agreement notice period (Tenant)'),
                value: 'tenantNotice',
                editable: true,
                format: 'Number'
              },
              {
                text: this.$t('Agreement notice period (Lanlord)'),
                value: 'landlordNotice',
                editable: true,
                format: 'Number'
              },
              {
                text: this.$t('Validity notice'),
                value: 'huom',
                editable: true,
                type: undefined
              },
              {
                text: this.$t('Contract area'),
                value: 'contract_area',
                editable: true,
                format: 'Area'
              },
              {
                text: this.$t('Contract details'),
                value: 'contract_note',
                editable: true,
                type: undefined
              },
              {
                text: this.$t('VAT-responsibility'),
                value: 'vat_status',
                editable: true,
                type: undefined
              }

            ],
            items: [],
            report: 'units',
            sortBy: 'unit_name',
            sortDesc: false,
            importing: true
          },
          maximized: [true]
        },
        {
          id: 'site.floor_areas',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name' },
              { text: this.$t('Floor'), value: 'floor_name' },
              { text: this.$t('Gross area'), value: 'gross_area', format: 'Area' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area' },
              { text: this.$t('Other floor area'), value: 'other_floor_area', format: 'Area' },
              {
                text: this.$t('Rentable floor area'),
                value: 'rentable_floor_area',
                format: 'Area'
              },
              {
                text: this.$t('Rented floor area'),
                value: 'rented_floor_area',
                format: 'Area'
              },
              { text: this.$t('Free floor area'), value: 'vacant_floor_area', format: 'Area' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'gross_area', format: 'Area' },
              { value: 'net_floor_area', format: 'Area' },
              { value: 'other_floor_area', format: 'Area' },
              { value: 'rentable_floor_area', format: 'Area'},
              { value: 'rented_floor_area', format: 'Area'},
              { value: 'vacant_floor_area', format: 'Area'}
            ],
            items: [],
            report: 'floorRentalStatus',
            sortBy: 'building_name',
            sortDesc: true
          },
          maximized: [false]
        },
        {
          id: 'site.purpose_of_use_zones_by_buildings',
          type: 'ObjectTable',
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building' },
              { text: this.$t('Purpose zone level'), value: 'purpose_zone_level' },
              { text: this.$t('Description'), value: 'description' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area' },
              { text: this.$t('Number of workstations'), value: 'number_of_workstations', format: 'Amount' },
              { text: this.$t('HTV'), value: 'htv' },
              { text: this.$t('Number of personnel'), value: 'number_of_personnel'},
              { text: this.$t('Htm\u00B2/HTV'), value: 'net_floor_area_by_htv', format: 'Area' },
              { text: '', value: 'toggle' }
            ],
            browseHeaders: [
              { text: this.$t('Building name'), value: 'building' },
              { text: this.$t('Purpose zone1 string'), value: 'purpose_zone_1' },
              { text: this.$t('Purpose zone2 string'), value: 'purpose_zone_2' },
              { text: this.$t('Purpose zone3 string'), value: 'purpose_zone_3' },
              { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area' },
              { text: this.$t('Number of workstations'), value: 'number_of_workstations', format: 'Amount' },
              { text: this.$t('HTV'), value: 'htv' },
              { text: this.$t('Number of personnel'), value: 'number_of_personnel'},
              { text: this.$t('Htm\u00B2/HTV'), value: 'net_floor_area_by_htv', format: 'Area' }
            ],
            items: [],
            report: 'PurposeZonesByBuildings',
          },
          maximized: [true],
          loading: true
        },
        {
          id: 'site.spaces_with_ktv',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name'},
              { text: this.$t('Floor'), value: 'floor_name'},
              { text: this.$t('Room'), value: 'space_code'},
              { text: this.$t('Purpose zone1 string'), value: 'KTV1' },
              { text: this.$t('Purpose zone2 string'), value: 'KTV2' },
              { text: this.$t('Purpose zone3 string'), value: 'KTV3' },
              { text: this.$t('Cost center and share'), value: 'cost_center_share' },
              { text: this.$t('Usage'), value: 'usage'},
              { text: this.$t('Net room area'), value: 'area', format: 'Area'}
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { value: 'area', format: 'Area'}
            ],
            items: [],
            report: 'spacesWithKTV',
            sortDesc: true
          },
          maximized: [true]
        },
        {
          id: 'site.spaces_without_dwg',
          type: 'MetaDataTable',
          data: {
            headers: [
              { text: this.$t('Building'), value: 'building_name', alwaysVisible: true },
              { text: this.$t('Floor'), value: 'floor_name', alwaysVisible: true },
              { text: this.$t('Unit'), value: 'unit_name', unitLink: true },
              { text: this.$t('Unit identifier'), value: 'unit_identifier' },
              { text: this.$t('Space number'), value: 'name' },
              { text: this.$t('Space name'), value: 'space_name' },
              { text: this.$t('Net floor room area'), value: 'area', format: 'Area' },
              { text: this.$t('Facility area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Allocated unit area'), value: 'allocated_unit_area', format: 'Area' },
              { text: this.$t('Purpose of use'), value: 'id_purpose', isPurposeOfUse: true },
              { text: this.$t('Clarified purpose of use'), value: 'clarified_purpose_of_use' },
              { text: this.$t('Purpose zone1 string'), value: 'id_purpose_zone1', isPurposeZone: true },
              { text: this.$t('Purpose zone2 string'), value: 'id_purpose_zone2', isPurposeZone: true },
              { text: this.$t('Purpose zone3 string'), value: 'id_purpose_zone3', isPurposeZone: true },
              { text: this.$t('Floor material'), value: 'floor_material', isDefinition: true },
              { text: this.$t('Cleaning layer'), value: 'cleaning_area' },
              { text: this.$t('Cleaning frequency'), value: 'cleaning_frequency', isDefinition: true },
              { text: this.$t('Cleaning layer low'), value: 'cleaning_area_low' },
              { text: this.$t('Cleaning details'), value: 'cleaning_details' },
              { text: this.$t('Fire area'), value: 'fire_area' },
              { text: this.$t('Movement area'), value: 'movement_area', isDefinition: true },
              { text: this.$t('Additional cost (€/m2/mth)'), value: 'additional_cost', format: 'AdditionalCost'},
              { text: this.$t('Cost center and person count'), value: 'shares', isExtraField: true},
              { text: this.$t('Person count'), value: 'number_of_personnel', format: 'Number' },
              { text: this.$t('Number of workstations'), value: 'number_of_workstations', format: 'Amount' },
              { text: this.$t('Meter number'), value: 'meter_number', format: 'Number' },
              { text: this.$t('Classification'), value: 'classification' },
              { text: this.$t('Capacity quantity'), value: 'capacity_quantity' },
              { text: this.$t('Capacity unit'), value: 'capacity_unit', isDefinition: true },
              { text: this.$t('Contract number (SAP)'), value: 'contract_number_sap' },

            ],
            footers: [
              { text: this.$t('Total'), alwaysVisible: true },
              { alwaysVisible: true },
              { headerValue: 'name' },
              { headerValue: 'unit_name' },
              { headerValue: 'unit_identifier' },
              { headerValue: 'space_name'},
              { value: 'area', format: 'Area', headerValue: 'area' },
              { value: 'unit_area', format: 'Area', headerValue: 'unit_area' },
              { value: 'allocated_unit_area', format: 'Area', headerValue: 'allocated_unit_area' },
              { headerValue: 'id_purpose' },
              { headerValue: 'clarified_purpose_of_use' },
              { headerValue: 'id_purpose_zone1' },
              { headerValue: 'id_purpose_zone2' },
              { headerValue: 'id_purpose_zone3' },
              { headerValue: 'floor_material' },
              { headerValue: 'cleaning_area' },
              { headerValue: 'cleaning_frequency' },
              { headerValue: 'cleaning_area_low' },
              { headerValue: 'cleaning_details' },
              { headerValue: 'fire_area' },
              { headerValue: 'movement_area' },
              { headerValue: 'additional_cost', format: 'AdditionalCost' },
              { headerValue: 'shares', isExtraField: true},
              { headerValue: 'number_of_personnel', format: 'Number' },
              { headerValue: 'number_of_workstations', format: 'Amount' },
              { headerValue: 'meter_number', format: 'Number' },
              { headerValue: 'classification' },
              { headerValue: 'capacity_quantity' },
              { headerValue: 'capacity_unit' },
              { headerValue: 'contract_number_sap' }
            ],
            items: [],
            source: 'spacesForCurrentSite',
            sortDesc: true,
            filterByCostcenter: true,
            button: 'addSpace'
          },
          action: 'openSpaceModifyDialog',
          loading: true,
          maximized: [false]
        },
        unitsWithoutFloorPlan(),
        unitsWithFloorPlan(),
        unitsFromFloors(),
        siteDevices(),
        {
          id: 'site.daily_personnel',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Purpose zone1 string'), value: 'ktv1' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Number of personnel'), value: 'number_of_personnel' },
              { text: this.$t('Personnel on area'), value: 'personnel_space' }

            ],
             footers: [
              { text: this.$t('Total') },
              {},
              { value: 'site_area', format: 'Area'},
              { value: 'number_of_personnel' },
              { value: 'whole_personnel_space' }
            ],
            items: [],
            report: 'DailyPersonnel',
          },
          maximized: [false],
          loading: true
        },
         {
          id: 'site.pwy_accumulation',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Purpose zone1 string'), value: 'ktv1' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Area per pwy'), value: 'pwy_area' },
              { text: this.$t('Prognosis for year'), value: 'prognosis_for_year' },
              { text: this.$t('January'), value: 'january', format: 'NumberTwoDecimals' },
              { text: this.$t('February'), value: 'february', format: 'NumberTwoDecimals' },
              { text: this.$t('March'), value: 'march', format: 'NumberTwoDecimals' },
              { text: this.$t('April'), value: 'april', format: 'NumberTwoDecimals' },
              { text: this.$t('May'), value: 'may', format: 'NumberTwoDecimals' },
              { text: this.$t('June'), value: 'june', format: 'NumberTwoDecimals' },
              { text: this.$t('July'), value: 'july', format: 'NumberTwoDecimals' },
              { text: this.$t('August'), value: 'august', format: 'NumberTwoDecimals' },
              { text: this.$t('September'), value: 'september', format: 'NumberTwoDecimals' },
              { text: this.$t('October'), value: 'october', format: 'NumberTwoDecimals' },
              { text: this.$t('November'), value: 'november', format: 'NumberTwoDecimals' },
              { text: this.$t('December'), value: 'december', format: 'NumberTwoDecimals' }

            ],
             footers: [
              { text: this.$t('Total') },
              {},
              { value: 'site_area', format: 'Area' },
              { value: 'all_pwy_area' },
              { value: 'prognosis_for_year' },
              { value: 'january', format: 'NumberTwoDecimals' },
              { value: 'february', format: 'NumberTwoDecimals' },
              { value: 'march', format: 'NumberTwoDecimals' },
              { value: 'april', format: 'NumberTwoDecimals' },
              { value: 'may', format: 'NumberTwoDecimals' },
              { value: 'june', format: 'NumberTwoDecimals' },
              { value: 'july', format: 'NumberTwoDecimals' },
              { value: 'august', format: 'NumberTwoDecimals' },
              { value: 'september', format: 'NumberTwoDecimals' },
              { value: 'october', format: 'NumberTwoDecimals' },
              { value: 'november', format: 'NumberTwoDecimals' },
              { value: 'december', format: 'NumberTwoDecimals' }
            ],
            items: [],
            report: 'PWYAccumulation',
          },
          maximized: [false],
          loading: true
        },
        {
          id: 'site.pwy_accumulation_by_building',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Building'), value: 'building'},
              { text: this.$t('Purpose zone1 string'), value: 'ktv1' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Area per pwy'), value: 'pwy_area' },
              { text: this.$t('Prognosis for year'), value: 'prognosis_for_year' },
              { text: this.$t('January'), value: 'january' },
              { text: this.$t('February'), value: 'february' },
              { text: this.$t('March'), value: 'march' },
              { text: this.$t('April'), value: 'april' },
              { text: this.$t('May'), value: 'may' },
              { text: this.$t('June'), value: 'june' },
              { text: this.$t('July'), value: 'july' },
              { text: this.$t('August'), value: 'august' },
              { text: this.$t('September'), value: 'september' },
              { text: this.$t('October'), value: 'october' },
              { text: this.$t('November'), value: 'november' },
              { text: this.$t('December'), value: 'december' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'site_area', format: 'Area' },
              { value: 'all_pwy_area' },
              { value: 'prognosis_for_year' },
              { value: 'january' },
              { value: 'february' },
              { value: 'march' },
              { value: 'april' },
              { value: 'may' },
              { value: 'june' },
              { value: 'july' },
              { value: 'august' },
              { value: 'september' },
              { value: 'october' },
              { value: 'november' },
              { value: 'december' }
            ],
            items: [],
            report: 'PWYAccumulationPerBuilding'
          },
          maximized: [false],
          loading: true
        },
        {
          id: 'site.personnel_accumulation',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Purpose zone1 string'), value: 'ktv1' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Personnel on area avg'), value: 'personnel_area' },
              { text: this.$t('Average for year'), value: 'average_for_year' },
              { text: this.$t('January'), value: 'january' },
              { text: this.$t('February'), value: 'february' },
              { text: this.$t('March'), value: 'march' },
              { text: this.$t('April'), value: 'april' },
              { text: this.$t('May'), value: 'may' },
              { text: this.$t('June'), value: 'june' },
              { text: this.$t('July'), value: 'july' },
              { text: this.$t('August'), value: 'august' },
              { text: this.$t('September'), value: 'september' },
              { text: this.$t('October'), value: 'october' },
              { text: this.$t('November'), value: 'november' },
              { text: this.$t('December'), value: 'december' }

            ],
             footers: [
              { text: this.$t('Total') },
              {},
              { value: 'site_area', format: 'Area' },
              { value: 'all_pwy_area'},
              { value: 'average_for_year'},
              { value: 'january' },
              { value: 'february' },
              { value: 'march' },
              { value: 'april' },
              { value: 'may' },
              { value: 'june' },
              { value: 'july' },
              { value: 'august' },
              { value: 'september' },
              { value: 'october' },
              { value: 'november' },
              { value: 'december' },
            ],
            items: [],
            report: 'PersonnelAccumulation',
          },
          maximized: [false],
          loading: true
        },
         {
          id: 'site.capacities',
          type: 'DynamicDataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Building'), value: 'building_name' },
            ],
             footers: [
              { text: this.$t('Total') },
              {},
            ],
            items: [],
            report: 'SiteCapacities',
          },
          maximized: [false],
          loading: true
        },
        {
          id: 'site.rental_contracts_by_organization',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Tenant'), value: 'tenant' },
              { text: this.$t('Contract number'), value: 'contract_number', format: 'Number' },
              { text: this.$t('Contract type'), value: 'contract_type' },
              { text: this.$t('contract.contract start date'), value: 'start_date', format: 'Date' },
              { text: this.$t('contract.contract end date'), value: 'agreement_end_date', format: 'Date' },
              { text: this.$t('Building'), value: 'building_names' },
              { text: this.$t('Unit'), value: 'unit_names' },
              { text: this.$t('Contract area'), value: 'contract_area', format: 'Area' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('contract.tenant term of notice'), value: 'notice_period', format: 'Month' },
              { text: this.$t('contract.landlord term of notice'), value: 'landlord_notice_period', format: 'Month' },
              { text: this.$t('contract.tenant first term of notice day'), value: 'earliest_notice_date', format: 'Date'},
              { text: this.$t('contract.landlord first term of notice day'), value: 'landlord_earliest_notice_date', format: 'Date'},
              { text: this.$t('contract.monthly rent 0'), value: 'total_net', format: 'Euro'},
            ],
             footers: [
              { text: this.$t('Total') },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { value: 'contract_area', format: 'Area' },
              { value: 'unit_area', format: 'Area' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { value: 'total_net', format: 'Euro' }
            ],
            items: [],
            report: 'RentalContractsByOrganizations',
          },
          maximized: [true],
          loading: true
        },
        {
          id: 'site.personnel_accumulation_by_building',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('User Organization'), value: 'organization' },
              { text: this.$t('Building'), value: 'building'},
              { text: this.$t('Purpose zone1 string'), value: 'ktv1' },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('Personnel on area avg'), value: 'personnel_area' },
              { text: this.$t('Average for year'), value: 'average_for_year' },
              { text: this.$t('January'), value: 'january' },
              { text: this.$t('February'), value: 'february' },
              { text: this.$t('March'), value: 'march' },
              { text: this.$t('April'), value: 'april' },
              { text: this.$t('May'), value: 'may' },
              { text: this.$t('June'), value: 'june' },
              { text: this.$t('July'), value: 'july' },
              { text: this.$t('August'), value: 'august' },
              { text: this.$t('September'), value: 'september' },
              { text: this.$t('October'), value: 'october' },
              { text: this.$t('November'), value: 'november' },
              { text: this.$t('December'), value: 'december' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'site_area', format: 'Area' },
              { value: 'all_pwy_area'},
              { value: 'average_for_year'},
              { value: 'january' },
              { value: 'february' },
              { value: 'march' },
              { value: 'april' },
              { value: 'may' },
              { value: 'june' },
              { value: 'july' },
              { value: 'august' },
              { value: 'september' },
              { value: 'october' },
              { value: 'november' },
              { value: 'december' }
            ],
            items: [],
            report: 'PersonnelAccumulationPerBuilding'
          },
          maximized: [false],
          loading: true
        },
        {
          id: 'site.breeam_in_use_part1',
          type: 'HorizontalBarDataGraph',
          data: {
            report: 'SiteBreeamInUsePart1Certifications',
            starRating: true,
            measType: 'Asset performance',
            items: [],
            label: this.$t('Certification class'),
            sets: [
              {
                method: 'Breeam In-Use Certification',
                part: 1
              }
            ]
          },
          small: true,
          loading: true
        },
        {
          id: 'site.breeam_in_use_part2',
          type: 'HorizontalBarDataGraph',
          data: {
            report: 'SiteBreeamInUsePart2Certifications',
            starRating: true,
            measType: 'Management performance',
            items: [],
            label: this.$t('Certification class'),
            sets: [
              {
                method: 'Breeam In-Use Certification',
                part: 2
              }
            ]
          },
          small: true,
          loading: true
        },
        {
          id: 'site.site_notebook',
          data: {
            source: 'currentSite'
          },
          small: false,
          loading: true,
          maximized: [true]
        },
        {
          id: 'site.building_documents',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('Building name'), value: 'building_name' },
              { text: this.$t('Document name'), value: 'document_name' },
              { text: this.$t('File'), value: 'file' },
              { text: this.$t('Engineering department'), value: 'engineering_department' },
              { text: this.$t('Document category'), value: 'type' },
              { text: this.$t('Document content'), value: 'description' },
              { text: this.$t('Date'), value: 'date' }
            ],
            footers: [
              { text: this.$t('Total') },
              { value: 'total_count' },
              {},
              {},
              {},
              {},
              {},
            ],
            items: [],
            report: 'BuildingDocumentsForCurrentSite'
          },
          loading: true,
          maximized: [false]
        },
        {
          id: 'site.building_projects',
          type: 'MetaDataTable',
          data: {
            headers: [
              { text: this.$t('Building identifier'), value: 'building_identifier' },
              { text: this.$t('Building'), value: 'building_code' },
              { text: this.$t('Buildingproject identifier'), value: 'buildingproject_identifier' },
              { text: this.$t('Buildingproject number'), value: 'buildingproject_number', },
              { text: this.$t('Name'), value: 'name', },
              { text: this.$t('building_projects.description'), value: 'description', hideOverflow: true },
              { text: this.$t('building_projects.additional_info'), value: 'additional_info', hideOverflow: true },
              { text: this.$t('Purpose of use with denomination'), value: 'purpose_of_use_with_denomination' },
              { text: this.$t('building_projects.strategy'), value: 'strategy', hideOverflow: true },
              { text: this.$t('Unit area'), value: 'unit_area', format: 'Area' },
              { text: this.$t('building_projects.gross_area'), value: 'gross_area', format: 'Area' },
              { text: this.$t('building_projects.net_area'), value: 'net_area', format: 'Area' },
              { text: this.$t('Owner'), value: 'landlord' },
              { text: this.$t('Buildingproject ownership relation'), value: 'ownership_relation' },
              { text: this.$t('Property maintenance name'), value: 'property_maintenance_name' },
              { text: this.$t('Property maintenance phone'), value: 'property_maintenance_phone' },
              { text: this.$t('Property maintenance email'), value: 'property_maintenance_email' },
              { text: this.$t('Situation code'), value: 'situation_code' },
              { text: this.$t('Situation description'), value: 'situation_description' },
              { text: this.$t('BuildingProjectType'), value: 'project_type' },
              { text: this.$t('BuildingProjectTypeDescription'), value: 'project_type_description' },
              { text: this.$t('BuildingProjectStatus'), value: 'status' },
              { text: this.$t('CustomerDescription'), value: 'customer' },
              { text: this.$t('building_projects.implementation_type'), value: 'implementation_type' },
              { text: this.$t('FinancingType'), value: 'financing_type' },
              { text: this.$t('building_projects.is_allocated'), value: 'is_allocated' },
              { text: this.$t('building_projects.order_system'), value: 'order_system' },
              { text: this.$t('TotalFinancingProvision'), value: 'total_financing_provision', format: 'Euro' },
              { text: this.$t('AcceptanceDate'), value: 'acceptance_date', format: 'Date' },
              { text: this.$t('building_projects.temporary_relocation'), value: 'temporary_relocation' },
              { text: this.$t('building_projects.estimated_rent'), value: 'estimated_rent', format: 'EuroPerMonth' },
              { text: this.$t('Start date'), value: 'start_date', format: 'Date' },
              { text: this.$t('End date'), value: 'end_date', format: 'Date' },
              { text: this.$t('ExternalUrl'), value: 'external_url' },
            ],
            footers: [
              { text: this.$t('Total'), alwaysVisible: true },
              { headerValue: 'building_code' },
              { headerValue: 'buildingproject_identifier' },
              { headerValue: 'buildingproject_number' },
              { headerValue: 'name' },
              { headerValue: 'description'},
              { headerValue: 'purpose_of_use_with_denomination' },
              { headerValue: 'strategy' },
              { headerValue: 'unit_area', value: 'unit_area', format: 'Area' },
              { headerValue: 'gross_area', value: 'gross_area', format: 'Area' },
              { headerValue: 'net_area', value: 'net_area', format: 'Area' },
              { headerValue: 'landlord' },
              { headerValue: 'ownership_relation' },
              { headerValue: 'property_maintenance_name' },
              { headerValue: 'property_maintenance_phone' },
              { headerValue: 'property_maintenance_email' },
              { headerValue: 'situation_code' },
              { headerValue: 'situation_description' },
              { headerValue: 'project_type' },
              { headerValue: 'project_type_description' },
              { headerValue: 'status' },
              { headerValue: 'customer' },
              { headerValue: 'implementation_type' },
              { headerValue: 'financing_type' },
              { headerValue: 'is_allocated' },
              { headerValue: 'order_system' },
              { headerValue: 'total_financing_provision', value: 'total_financing_provision', format: 'Euro' },
              { headerValue: 'acceptance_date' },
              { headerValue: 'temporary_relocation' },
              { headerValue: 'estimated_rent', value: 'estimated_rent', format: 'EuroPerMonth' },
              { headerValue: 'start_date' },
              { headerValue: 'end_date' },
              { headerValue: 'external_url' },
            ],
            items: [],
            source: 'buildingProjectsForCurrentSite',
            sortBy: 'acceptance_date',
            sortDesc: true,
            filterByCostcenter: true,
          },
          loading: true,
          maximized: [false]
        },
        {
          id: 'site.marketing',
          type: 'DataTable',
          action: null,
          loading: true,
          maximized: [false],
          data: {
            headers: [
              { text: this.$t('Unit code'), value: 'unit_code', unitLink: true },
              { text: this.$t('Contract number'), value: 'contract_numbers', format: 'List' },
              { text: this.$t('Marketing'), value: 'marketing' },
              { text: this.$t('marketing.rent_request'), value: 'rent', format: 'EuroPerMonth' },
              { text: this.$t('marketing.description'), value: 'description', hideOverflow: true },
              { text: this.$t('marketing.photos'), value: 'photos_count', format: 'Amount' },
              { text: this.$t('Building completed year'), value: 'completed_year', format: 'Number' },
              { text: this.$t('Usage'), value: 'usage' },
              { text: this.$t('Actions'), value: 'marketing_id'},
            ],
            items: [],
            noBrowse: true,
            report: 'GetAllMarketingRows',
            sortDesc: true
          },
        },
        {
          id: 'site.diary',
          type: 'DataTable',
          loading: false,
          maximazed: [false],
          data: {
            headers: [
              { text: this.$t('site.diary.notification_date'), value: 'notification_date', alwaysVisible: true, format: 'Date' },
              { text: this.$t('site.diary.notifier'), value: 'notifier', alwaysVisible: true },
              { text: this.$t('site.diary.handling_date'), value: 'handling_date', format: 'Date' },
              { text: this.$t('site.diary.author'), value: 'author' },
              { text: this.$t('site.diary.case'), value: 'diary_case' },
              { text: this.$t('site.diary.solution'), value: 'solution' },
              { text: this.$t('site.diary.state'), value: 'state'},
              { text: this.$t('site.diary.assignee'), value: 'assignee'},
              { text: this.$t("site.diary.actions"), value: 'diary.actions' }
            ],
            footers: [{},{},{},{},{},{},{},{},],
            report: 'GetAllDiaries',
            button: 'addDiary',
            items: [],
          },
          action: null,
        },
        {
          id: 'site.contract_tenants',
          type: 'DataTable',
          data: {
            headers: [
              { text: this.$t('Tenant'), value: 'tenant', format: 'Translation' },
              { text: this.$t('Contract number'), value: 'contract_number', event: 'showCreateRentContractModal' },
              { text: this.$t('Building'), value: 'building' },
              { text: this.$t('Unit'), value: 'unit', },
              { text: this.$t('Structure'), value: 'structure', },
              { text: this.$t('Agreed area'), value: 'agreed_area', format: 'Area' },
            ],
            footers: [
              { text: this.$t('Total'), alwaysVisible: true },
              { },{ },{ },{ },
              { value: 'agreed_area' , format: 'Area'},
            ],
            items: [],
            report: 'GetContractTenants',
            sortBy: '',
            sortDesc: true,
          },
          loading: true,
          maximized: [false]
        },
        AlertsWidget('site.alerts')
      ],
      widgetDataCostAreas: [],
      widgetDataEmptySpacesData: [],
      widgetDataFreeRentData: [],
      widgetDataRentalData: [],
      widgetDataSpacesByPurposeOfUse: [],
      widgetDataFloors: [],

      // init and widget update bools (MAYBE NOT THE BEST SOLUTION HERE)
      initialized: false,
      widgetsUpdated: false,
      mapUpdated: false,

      // site
      currentSiteName: this.$t('Site'),
      currentSiteOriginal: null,
      currentSiteWithChanges: null,
      currentSitePatch: null,
      currentSiteObserver: null,

      // estates
      currentSiteEstates: {},

      // structures
      currentSiteStructures: {},

      // buildings
      currentSiteBuildings: {},
      isSavingChanges: false,
      changes: [],

      // site units
      widgetDataUnits: [],

      // rental status
      rentalStatusChanges: [],

      // imported csv data
      importSuccess: false,
      importErrors: { unexisting: [], uneditable: [] },
      dataUpdateDialog: false,
      errors: [],
      savingFailed: false,
      siteParties: [],

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',
      xCoordError: false,
      yCoordError: false,

      // space widget and modify
      spaceReportForCurrentSite: {},
      spaceModifyDialog: false,
      unitModifyDialog: false,
      siteUnits: [],
      unitVisibilityRights: {},
      unitPartyLinks: [],

      // site links
      siteLinksDialogOpen: false,
      forceRefreshSiteLinks: false,
      isSiteLinkBtnVisible: false,
      showAddAlertDialog: false,
      alertToBeEdited: {},
      outRent: false,
      editedContractId: undefined,
      showCreateRentContractModal: false,

      // all available tags
      availableSiteTags: [],
      availableBuildingTags: [],

      // edited tags
      siteTags: null,
      buildingTags: null,
    }
  },
  computed: {
    ...mapState('sites', [
      'loadedSites',
      'siteMetadata',
      'buildingMetadata',
      'structureMetadata',
      'estateMetadata',
      'loadedReports',
      'currentSiteId',
      'siteServices',
      'sitePermissions',
      'currentSiteLinks',
      'floorsForCurrentSite'
    ]),
    ...mapState('app', ['currentDate', 'userWidgets', 'userInfo', 'sites']),
    ...mapGetters('sites', [
      'buildingsForCurrentSite',
      'cemeteriesForCurrentSite',
      'estatesForCurrentSite',
      'structuresForCurrentSite',
      'reportForCurrentSite',
      'partiesForCurrentSite',
      'rentingForCurrentSite',
      'currentSite',
      'siteSpaces',
      'customizableDataFields',
      'hasWritePermission',
    ]),
    ...mapGetters('app', [
      'definitionsByGroupId',
      'widgetPermissions',
      'applicationPermissions',
      'hasApplicationPermissionByName'
    ]),
    hasWritePermissionForResponsibilities () {
      const permission = this.applicationPermissions.find(
        p => p.id === '04.02.05'
      )
      if (permission && permission.accessLevel === 1) {
        return true
      }
      return false
    },
    hasSiteCommentPermission () {
      // First check if we have site permission...
      const sitePermit = this.sitePermissions.find(
        p => Number(p.id) === Number(this.currentSiteId)
      )
      if (sitePermit && sitePermit.accessLevel === 1) {
        // ...then check that we have correct application permit
        const appPermit = this.applicationPermissions.find(
          p => p.id === '04.02.08'
        )
        if (appPermit && appPermit.accessLevel === 1) {
          // If both condition are fullfilled, user can write and save site description
          return true
        }
      }

      return false
    },
    siteId () {
      return Number(this.$route.params.id_site)
    },
    siteFields () {
      if (this.siteMetadata === null) {
        return []
      }
      return Object.keys(this.siteMetadata)
        .map(key => {
          return { ...this.siteMetadata[key], name: key }
        })
        .filter(v => v.isShown)
        .sort((a, b) => (a.order < b.order ? -1 : 1))
    },
    isDraggable () {
      return this.$store.state.app.sortableWidgets
    },
    fieldError () {
      return this.xCoordError || this.yCoordError
    },
  },
  watch: {
    currentDate: function () {
      this.siteSpacesUpdated = false
    },
    currentSite: {
      handler: async function () {
        this.currentSiteName = this.currentSite?.name ?? this.$t('Site')
        // Make a copy of the currentSite
        this.$log.info('Current site changed. updating site info...')
        this.widgetsUpdated = false
        await this.initView()
      },
      deep: false
    },
    loadedReports: function () {
      this.initChanges()
      this.updateWidgetReportData()
    },
    isDraggable: function () {
      if (this.isDraggable) {
        this.draggableColor = '#8b8b8b'
      } else {
        this.draggableColor = null
      }
    },
    partiesForCurrentSite: {
      handler: function (parties) {
        this.siteParties = parties
        this.responsibilitiesLoading = false
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.new_user_added'))
      }
    },
    estatesForCurrentSite: {
      handler: async function () {
        this.estatesForCurrentSite.sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    buildingsForCurrentSite: {
      handler: async function () {
        this.updateWidgetData()
      }
    },
    structuresForCurrentSite: {
      handler: async function () {
        this.structuresForCurrentSite.sort((a, b) => a.structure_name.localeCompare(b.structure_name))
        this.updateWidgetData()
      }
    },
    siteMetadata: {
      handler: async function (value) {
        if (value != null) {
          await this.getSiteTags(value);
        }
      },
      deep: true,
      immediate: true,
    },
    buildingMetadata: {
      handler: async function (value) {
        if (value != null) {
          await this.getBuildingTags(value);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted () {
    await this.initViewStart()
    // when coming back to view show Parties widget
    if (this.partiesForCurrentSite.parties.length > 0) {
      this.siteParties = this.partiesForCurrentSite
      this.responsibilitiesLoading = false
    } else {
      this.responsibilitiesLoading = false
    }
    this.getParties()
    this.initializeWidgetRights()
  },
  methods: {
    ...mapActions('sites', [
      'loadSite',
      'loadSiteReports',
      'loadSiteParties',
      'updateUnitRentalStatus',
      'getSiteLinks',
      'loadSiteSpaces',
      'loadFloorsForCurrentSite']),
    ...mapActions('app',['getParties']),
    ...mapMutations('sites', ['setCurrentSite']),
    async initView () {
      if (this.currentSite && !this.initialized) {
        this.siteSpacesUpdated = false
        this.initialized = true
        this.changes = []
        this.resetChanges()
        this.initChanges()
        this.updateWidgetData()
        await this.getWidgetOrder()
        this.$store.commit('app/setSortableWidgets', false)
      }
    },
    initializeWidgetRights () {
    const withoutDwgWidgets = ['site.units_without_dwg', 'site_units_from_floors']
      this.widgets.forEach(widget => {
        if (withoutDwgWidgets.includes(widget.id)) {
          widget.isRightsToModify = this.hasApplicationPermissionByName('HUONEISTO_MUOKKAUS')
          widget.isRightsToAdd = this.hasApplicationPermissionByName('HUONEISTON_LISAYS')
        }
      })
    },
    async initViewStart () {
      if (this.currentSite && !this.initialized) {
        this.initialized = true
        this.changes = []
        this.resetChanges()
        this.initChanges()
        await this.updateWidgetData()
        if (this.buildingsForCurrentSite || this.cemeteriesForCurrentSite) {
          this.updateMap()
        }
        await this.getWidgetOrder()
        this.$store.commit('app/setSortableWidgets', false)
      }
    },
    updateChanges: function () {
      this.changes = [
        ...(this.currentSitePatch === null ? [] : this.currentSitePatch),
        ...Object.keys(this.currentSiteBuildings)
          .map(k => this.currentSiteBuildings[k])
          .filter(b => b.patch !== null)
          .map(b => b.patch),
        ...Object.keys(this.currentSiteStructures)
          .map(k => this.currentSiteStructures[k])
          .filter(b => b.patch !== null)
          .map(b => b.patch),
        ...Object.keys(this.currentSiteEstates)
          .map(k => this.currentSiteEstates[k])
          .filter(e => e.patch !== null)
          .map(e => e.patch),
        ...(this.siteTags == null ? [] : this.siteTags),
        ...(this.buildingTags == null ? [] : this.buildingTags),
      ]
    },
    isWidgetDynamic (widget) {
      return widget.type === 'HorizontalBarDataGraph' ||
        widget.type === 'VerticalBarDataGraph' ||
        widget.type === 'VerticalStackedGraph' ||
        widget.type === 'PieDataGraph' ||
        widget.type === 'LineChart' ||
        widget.type === 'TimeLineGraph' ||
        widget.type === 'LinkTableWithButtons'
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    async getCurrentSiteLinks () {
      var params = {
        "siteId" : this.siteId,
        "forceUpdate" : this.forceRefreshSiteLinks
      }
      await this.getSiteLinks(params)
      return this.currentSiteLinks;
    },
    async updateSiteLinks () {
      this.showIndicator = true
      this.operationResult = 'success'
      this.forceRefreshSiteLinks = true;
      await this.updateWidgetData();
      this.forceRefreshSiteLinks = false;
    },
    initChanges: function () {
      if (this.currentSite === undefined) {
        this.$log.warn('Current site was not defined while initializing...')
        return
      }
      this.currentSiteOriginal = JSON.parse(JSON.stringify(this.currentSite))
      this.currentSiteWithChanges = JSON.parse(JSON.stringify(this.currentSite))
      this.currentSiteObserver = this.$jsonpatch.observe(
        this.currentSiteWithChanges
      )
      this.estatesForCurrentSite.forEach(estate => {
        const withChanges = JSON.parse(JSON.stringify(estate))
        this.currentSiteEstates[estate.estate_id] = {
          original: JSON.parse(JSON.stringify(estate)),
          withChanges: withChanges,
          patch: null
        }
      })

      this.structuresForCurrentSite.forEach(structure => {
        const withChanges = JSON.parse(JSON.stringify(structure))
        this.currentSiteStructures[structure.structure_id] = {
          original: JSON.parse(JSON.stringify(structure)),
          withChanges: withChanges,
          patch: null
        }
      })

      this.buildingsForCurrentSite.forEach(building => {
        const withChanges = JSON.parse(JSON.stringify(building))
        this.currentSiteBuildings[building.building_code] = {
          original: JSON.parse(JSON.stringify(building)),
          withChanges: withChanges,
          patch: null
        }
      })
      this.isSiteLinkBtnVisible = this.applicationPermissions.find(
        p => p.id === '04.02.09'
      );
    },
    isWidgetVisible: function (widget) {
      const overrides = ['site', 'site.description']

      // This widget should only be shown if user does not have leasing permissions.... I guess
      // we will use the 23.01 KOHDE_VUOKRAUSVALILEHTI
      if(widget.id === 'site.empty_rental_spaces'){
        const permission = this.hasApplicationPermissionByName("KOHDE_VUOKRAUSLEHTI")

        if (permission) {
          return false
        }
      }
      return (
        this.widgetPermissions.find(
          w => w.name === widget.id || overrides.includes(widget.id)
        ) !== undefined
      )
    },
    getWidgetDataFromSource: function (widget) {
      return this[widget.data.source]
    },
    getWidgetMetadataFromSource: function (widget) {
      return this[widget.data.metadataSource]
    },
    saveChanges: async function () {
      this.isSavingChanges = true
      this.operationResult = ''
      const time = this.currentDate.toISOString()

      // Save site
      if (this.currentSitePatch !== null) {
          const siteResult = await this.$rambollfmapi.sites.patch(
            this.currentSite.id_site,
            time,
            this.currentSite.version,
            this.currentSitePatch
          )
          if(siteResult.name === 'Error') {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
          }

        this.currentSitePatch = null
        this.currentSiteOriginal = JSON.parse(
          JSON.stringify(this.currentSiteWithChanges)
        )
      }

      // Save estates
      const estates = Object.keys(this.currentSiteEstates).map(
        k => this.currentSiteEstates[k]
      )
      for (let i = 0; i < estates.length; i++) {
        const estate = estates[i]
        if (estate.patch !== null) {
          const estatesResult = await this.$rambollfmapi.estates.patch(
            estate.withChanges.estate_id,
            time,
            estate.patch
          )
          if (estatesResult.name === 'Error') {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
          }
        }
        estate.patch = null
        estate.original = JSON.parse(JSON.stringify(estate.withChanges))
      }

      // Save structures
      const structures = Object.keys(this.currentSiteStructures).map(
        k => this.currentSiteStructures[k]
      )
      for (let i = 0; i < structures.length; i++) {
        const structure = structures[i]
        if (structure.patch !== null) {
          const structuresResult = await this.$rambollfmapi.structures.patch(
            structure.withChanges.structure_id,
            time,
            structure.patch
          )
          if (structuresResult.name === 'Error') {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
          }
        }

        structure.patch = null,
        structure.original = JSON.parse(JSON.stringify(structure.withChanges))
      }

      // Save buildings
      const buildings = Object.keys(this.currentSiteBuildings).map(
        k => this.currentSiteBuildings[k]
      )
      for (let i = 0; i < buildings.length; i++) {
        const building = buildings[i]
        if (building.patch !== null) {
          if (building.original.managed_by_intg) {
            building.patch.push({
            op: 'replace',
            path: '/managed_by_intg',
            value: building.original.managed_by_intg
          })
          }
          // If the building was originally a draft, keep is_draft as true
          if (building.original.is_draft) {
            building.patch.push({
              op: 'replace',
              path: '/is_draft',
              value: true
            })
          }
          const buildingsResult = await this.$rambollfmapi.buildings.patch(
            building.withChanges.building_code,
            time,
            building.patch
          )
          if (buildingsResult.name === 'Error') {
            this.indicatorMessage = this.$t("Error while saving")
            this.showIndicator = true;
            this.operationResult = "error"
          }
        }
        building.patch = null
        building.original = JSON.parse(JSON.stringify(building.withChanges))
      }

      if (this.siteTags != null) {
        await this.$rambollfmapi.sites.tags.update([{ idSite: this.currentSite.id_site, siteTags: this.siteTags }])
        this.getSiteTags(this.siteMetadata);
      }

      if (this.buildingTags != null) {
        await this.$rambollfmapi.buildings.tags.update(this.buildingTags)
        this.getBuildingTags(this.buildingMetadata);
      }

      // Added the reload method here as we would need to refresh the page anyway to see update effects outside of changing calendar date
      await this.reload()

      this.updateChanges()

      if(this.operationResult !== 'error') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.showIndicator = true
        this.operationResult = 'success'
        this.yPosition = 'bottom'
      }

      this.isSavingChanges = false
    },
    fieldUpdate: function ({ data, field, value }) {
      // This method is a router for the real data updates
      if (
        typeof data.id_site !== 'undefined' &&
        typeof data.building_code === 'undefined' &&
        typeof data.estate_id === 'undefined' &&
        typeof data.structure_id === 'undefined'
      ) {
        this.siteFieldUpdate({ data, field, value })
      }

      if (typeof data.building_code !== 'undefined') {
        this.buildingFieldUpdate({ data, field, value })
      }

      if (typeof data.structure_id !== 'undefined') {
        this.structureFieldUpdate({ data, field, value })
      }

      if (
        typeof data.estate_id !== 'undefined' &&
        typeof data.id_site !== 'undefined'
      ) {
        this.estateFieldUpdate({ data, field, value })
      }
      if (this.hasWritePermission) {
        this.updateChanges()
      }
    },
    estateFieldUpdate: function ({ data, field, value }) {
      this.$log.info(data, field, value)
      const estate = this.currentSiteEstates[data.estate_id]
      estate.withChanges[field.name] = value
      estate.patch = this.$jsonpatch.compare(
        estate.original,
        estate.withChanges
      )
    },
    structureFieldUpdate: function ({ data, field, value }) {
      this.$log.info(data, field, value)
      const structure = this.currentSiteStructures[data.structure_id]
      structure.withChanges[field.name] = value
      structure.patch = this.$jsonpatch.compare(
        structure.original,
        structure.withChanges
      )
    },
    buildingFieldUpdate: function ({ data, field, value }) {
      this.$log.info(data, field, value)
      const building = this.currentSiteBuildings[data.building_code]

      // tags are updated separately
      if (field.name == 'tags') {
        this.onBuildingTagsChanged(data.id_building, value)
        return
      }

      if (field.editType === 'Number' && typeof value === 'string') {
        if (value === '') {
          value = 0
        } else {
          value = Number(value)
        }
      }

      var fail = this.getCoordinateValidate(field, value, fail)

      if (fail !== true) {
        building.withChanges[field.name] = value
        building.patch = this.$jsonpatch.compare(
        building.original,
        building.withChanges
        )
        // coordinate conversion
        proj4.defs(
          'EPSG:3879',
          '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
        )
        let latitude = 0.0
        let longitude = 0.0
        building.patch.forEach(op => {
          if (op.path === '/coordinate_x') {
            const temp = proj4('EPSG:4326', 'EPSG:3879').inverse([
              Number(op.value),
              Number(building.withChanges.coordinate_y)
            ])
            longitude = temp[0]
          }
          if (op.path === '/coordinate_y') {
            const temp = proj4('EPSG:4326', 'EPSG:3879').inverse([
              Number(building.withChanges.coordinate_x),
              Number(op.value)
            ])
            latitude = temp[1]
          }
        })
        if (latitude > 0.0) {
          building.patch.push({
            op: 'replace',
            path: '/latitude',
            value: latitude
          })
        }
        if (longitude > 0.0) {
          building.patch.push({
            op: 'replace',
            path: '/longitude',
            value: longitude
          })
        }
      }
    },
    validateXcoord (value) {
      // westest point in Finland: 25175847.09
      // eastest point in Finland: 25834373.43
      var minX = 21531406.93
      var maxX = 25834373.43

      return !(value < minX || value > maxX)
    },
    validateYcoord (value) {
      // northest point in Finland: 7781023.88
      // southest point in Finland: 6634660.81
      var minY = 4503686.78
      var maxY = 9373384.10

      return !(value < minY || value > maxY)
    },
    siteFieldUpdate: function ({ field, value }) {
      // tags are updated separately
      if (field.name == 'tags') {
        this.siteTags = value
        return
      }

      this.currentSiteWithChanges[field.name] = value
      this.currentSitePatch = this.$jsonpatch.compare(
        this.currentSiteOriginal,
        this.currentSiteWithChanges
      )
    },
    getCoordinateValidate (field, value, fail) {
      fail = false
      if (field.name === "coordinate_x") {
        var minX = 21531406.93
        var maxX = 25834373.43

        if (value < minX || value > maxX) {
          this.indicatorMessage = this.$t("Coordinate x error")
          this.showIndicator = true
          this.operationResult = "error"
          this.yPosition = 'bottom'
          fail = true
          this.xCoordError = true
          return fail
        } else {
          this.xCoordError = false
          return fail
        }
      }
      if (field.name === "coordinate_y") {
        var minY = 4503686.78
        var maxY = 9373384.10
        if (value < minY || value > maxY) {
          this.indicatorMessage = this.$t("Coordinate y error")
          this.showIndicator = true
          this.operationResult = "error"
          this.yPosition = 'bottom'
          fail = true
          this.yCoordError = true
          return fail
        } else {
          this.yCoordError = false
          return fail
        }
      } else {
        return fail
      }
    },
    getFieldName: function (field) {
      /*
      if (typeof Object.keys(this.fieldText).find(field) !== 'undefined') {
        return this.fieldText[field]
      }
      */

      // guess the name
      field = field.split('_').join(' ')
      field = field.charAt(0).toUpperCase() + field.slice(1)
      return field
    },
    async updateWidgetData () {
      this.widgetsUpdated = true
      //These need to be reseted here so that the unit widgets fetches them again in case of changes
      this.unitData = undefined
      this.unitMetadata = undefined

      let reWidgets = this.userWidgets.filter(w => w.id !== -1)
      // use widgets filtered according to user control values + site info and comment widgets
      const filteredWidgets = this.widgets.filter(w => reWidgets.find(wo => wo.name === w.id) || w.id === 'site' || w.id === 'site.description' || w.id === 'site.site_notebook' || w.id === 'site.additional_info')
      await this.iterateWidgetData(filteredWidgets)
    },
    // Additional update for widgets with reports and widgets containing structure, building and estate data
    // Added to prevent missing data inside widgets, and for optimization purposes
    async updateWidgetReportData () {
      if (this.checkReports && this.widgetsUpdated) {
        this.updateMap()
        let reWidgets = this.userWidgets.filter(w => w.id !== -1)
        // use widgets filtered according to user control values & if widget uses reports or has one of the defined ids
        const filteredWidgets = this.widgets.filter(w => reWidgets.find(wo => wo.name === w.id) && (w.data.report !== undefined ||  w.id === 'site.structures' || w.id === 'site.buildings' || w.id === 'site.estates'))
        await this.iterateWidgetData(filteredWidgets)
      }
      else {
        // If non-report widgets have not been updated earlier, update everything instead
        this.updateMap()
        this.updateWidgetData()
      }
    },
    async iterateWidgetData (data) {
      for (let w of data) {
          if (w.data.report !== undefined) {
            // if report is defined in widget, use the data from store
            w.data.items = this.reportForCurrentSite(w.data.report)
            if (w.data.items === null  || w.data.items === undefined) {
              w.data.items = []
              // if report does not have content, check them afterwards to prevent data not loading (bool check)
              this.checkReports = true
            } else {
              w.loading = false
            }
          } else if (w.data.source !== undefined && w.data.source !== null) {
            // if source is defined in widget, use the data from component
            if (w.id == "site.links")
              w.data.buttonVisible = this.isSiteLinkBtnVisible;
            if (typeof this[w.data.source] === 'function') {
              w.data.items = await this[w.data.source]()
            } else {
              w.data.items = this[w.data.source]
              if (w.data.items && w.data.items['building_class_id']) {
                w.data.items['building_class'] = w.data.items['building_class_id']
              }
            }
            w.loading = false
          }
          // Set metadata
          if (w.data.metadataSource !== null) {
            w.data.metadata = this[w.data.metadataSource]
          } else if (
            w.data.metadataLabels !== null &&
            w.data.metadataLabels !== undefined
          ) {
            w.data.metadata = {}
            w.data.metadataLabels.forEach(label => {
              w.data.metadata[label.value] = {
                isShown: true,
                category: w.data.category ?? 'None',
                isDefinition: !!label.isDefinition,
                type: label.type ? label.type : 'String',
                format: label.format ? label.format : ''
              }
            })
          }
        }
    },
    updateMap () {
      if (!this.mapUpdated) {
        this.$log.info("Updating map...")
        // Update Map layers
        if (this.buildingsForCurrentSite.length > 0) {
          this.mapUpdated = true
          const layer = {
            name: 'Buildings',
            title: 'Buildings',
            type: 'Points',
            opacity: 1,
            points: this.buildingsForCurrentSite
              .filter(b => this.validateXcoord(b.coordinate_x))
              .filter(b => this.validateYcoord(b.coordinate_y))
              .map(b => {
                return {
                  x: parseFloat(b.coordinate_x),
                  y: parseFloat(b.coordinate_y),
                  projection: 'EPSG:3879',
                  data: b,
                  name: b.building_code,
                  description: b.building_code + ' ' + b.post_office
                }
              })
          }
          this.map.mapLayers = this.map.mapLayers.filter(
            l => l.name !== 'Buildings'
          )
          this.centerMap(layer)
        }
        if (typeof this.cemeteriesForCurrentSite !== 'undefined') {
          if (this.cemeteriesForCurrentSite.length > 0) {
            const layer = {
              name: 'Graveyards',
              title: 'Graveyards',
              type: 'Points',
              opacity: 1,
              points: this.cemeteriesForCurrentSite
                .filter(g =>  this.validateXcoord(g.xkoord))
                .filter(g => this.validateYcoord(g.ykoord))
                .map(g => {
                  return {
                    x: parseFloat(g.xkoord),
                    y: parseFloat(g.ykoord),
                    projection: 'EPSG:3879',
                    data: g,
                    name: g.name
                  }
                })
            }
            this.map.mapLayers = this.map.mapLayers.filter(
              l => l.name !== 'Graveyards'
            )
            this.centerMap(layer)
          }
        }
      }
    },
    centerMap (layer) {
        // Center the map
        if (layer.points.length > 0) {
          this.map.mapLayers.push(layer)
          this.map.mapCenter = [layer.points[0].x, layer.points[0].y]
          this.map.mapProjection = 'EPSG:3879'
          this.map.mapZoom = 14
        } else {
          this.$log.error('Cannot center the map, coordinates are missing.')
        }
    },
    async getSortableWidgets (widgets) {
      this.largeWidgets = widgets.filter(
        widget =>
          widget.small !== true &&
          this.isWidgetVisible(widget) &&
          widget.id !== 'site.description' && widget.id !=='site.additional_info' && widget.id !== 'site.site_notebook' && widget.id !== 'site.lease_plan'
      )

      this.smallWidgets = widgets.filter((w) => {
        return w.small && this.isWidgetVisible(w) && w.id !== 'site.site'
      })

      // Muokattavien lisäkenttien ylätunnisteen sekä (tyhjän) alatunnisteen lisäys Huone-widgettiin, kenttien arvojen lisäys spacesForCurrentSite-funktiossa
      const customizabledata = await this.$rambollfmapi.customizabledatafields.get()
      const customHeadersAndMetadata = customizabledata.items.filter(chmd => chmd.enabled == true).map(chmd => { return { text: chmd.name, value: chmd.name, alwaysVisible: true } })
      this.largeWidgets.find(lw => lw.id == 'site.spaces_without_dwg')?.data.headers.push(...customHeadersAndMetadata)

      for (let i = 0; i < customHeadersAndMetadata.length; i++) {
        let customFooters = { alwaysVisible: true }
        this.largeWidgets.find(lw => lw.id == 'site.spaces_without_dwg').data.footers.push(customFooters)
      }
    },
    async getWidgetOrder () {
      this.widgetOrder = this.userWidgets.filter(w => w.id !== -1)

      const filteredWidgets = this.widgets.filter(w => this.widgetOrder.find(wo => wo.name === w.id))
      const order = this.widgetOrder.map(wo => wo.name)
      const sortedWidgets = filteredWidgets.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })
      sortedWidgets.map(sw => {
        if (this.widgetOrder.find(wo => wo.name === sw.id).isOpen !== null) {
          sw.maximized = [!!this.widgetOrder.find(wo => wo.name === sw.id).isOpen]
        } else if (typeof sw.maximized === 'undefined') {
          sw.maximized = [true]
        }
      })

      if (sortedWidgets.length !== 0) {
        await this.getSortableWidgets(sortedWidgets)
      }
      this.$store.commit('app/setSortableWidgets', false)
    },
    async setWidgetOrder () {
      const currentChanges = this.largeWidgets.concat(this.smallWidgets)
      const newOrder = currentChanges.map(widget => widget.id)

      const newWidgetOrder = this.widgetOrder.sort((a, b) => {
        return newOrder.indexOf(a.name) - newOrder.indexOf(b.name)
      })
      newWidgetOrder.map(wo => {
        if (currentChanges.find(cc => cc.id === wo.name)){
          wo.isOpen = currentChanges.find(cc => cc.id === wo.name).maximized[0]
        }
      })

      await this.$rambollfmapi.accounts.widgets.put(newWidgetOrder)
      this.$store.dispatch('app/getUserInfo')
      this.$store.commit('app/setSortableWidgets', false)
    },
    dataUpdated (widget, data) {
      const items = helpers.dataTable.updateItems(widget.data, data.data, 1, 2)
      this.importSuccess = items.success
      this.importErrors = items.errors
      this.errors = []
      this.rentalStatusChanges = []
      if (items.success) {
        this.handleUpdate(items)
      }
      this.savingFailed = false
      this.dataUpdateDialog = true
    },
    handleUpdate (importedItems) {
      const reports = this.reportForCurrentSite('rentalStatus')
      const changedReports = []

      // detect changed rows
      reports.forEach(report => {
        // update is only possible if the unit has a rental contract already
        if (report.contractId && report.curUnitPartyId) {
          const reportCopy = JSON.parse(JSON.stringify(report))
          const reportObserver = this.$jsonpatch.observe(reportCopy)
          // use unitId here to identify correct item (could also use buildingCode and unitName combination)
          const modifiedItem = importedItems.items.find(
            item => item.unitId === report.unitId
          )

          Object.keys(reportCopy).forEach(key => {
            reportCopy[key] = modifiedItem[key]
          })

          const errorObject = {
            buildingCode: reportCopy.buildingCode,
            unitName: reportCopy.unitName
          }

          const widgetHeaders = this.widgets.find(
            widget => widget.id === 'site.units'
          ).data.headers

          // validate input fields that they are in correct format
          widgetHeaders.forEach(header => {
            let isNotCorrect
            if (reportCopy[header.value]) {
              if (header.type === Date) {
                // ignore dates that are not recognized as date format
                isNotCorrect = isNaN(Date.parse(reportCopy[header.value]))
              }
              if (header.type === Number) {
                // export function converts all decimal dots to commas, convert them back
                reportCopy[header.value] = String(
                  reportCopy[header.value]
                ).replace(',', '.')
                // ignore NaN notice periods
                isNotCorrect = isNaN(Number(reportCopy[header.value]))
              }

              if (isNotCorrect) {
                this.errors.push({
                  ...errorObject,
                  value: reportCopy[header.value],
                  label: header.text
                })
                // return original value
                reportCopy[header.value] = report[header.value]
              }
            }

            if (header.type === Number) {
              // export function converts all numbers to strings, convert them back to numbers if needed
              reportCopy[header.value] = reportCopy[header.value]
                ? Number(reportCopy[header.value])
                : report[header.value]
            }
          })

          const reportPatch = this.$jsonpatch.generate(reportObserver)
          if (reportPatch.length) {
            // huom field changed back to boolean value
            reportCopy.huom = reportCopy.huom === this.$t('Considerations')
            // replace original signature date with a possible change
            reportCopy.signature_date = reportCopy.signatureDate
            changedReports.push(reportCopy)
          }
        }
      })

      // Set vat-value to match the text in vat_status
      changedReports.forEach((value, index, arr) => {
        arr[index].vat = this.parseVatString(arr[index].vat_status)
      })

      this.rentalStatusChanges = changedReports
    },
    async saveRentalStatusChanges () {
      this.isSavingChanges = true
      try {
        await this.updateUnitRentalStatus(this.rentalStatusChanges)
        this.resetChanges()
      } catch {
        this.savingFailed = true
        this.resetChanges()
        this.dataUpdateDialog = true
      }
    },
    resetChanges () {
      this.dataUpdateDialog = false
      this.rentalStatusChanges = []
      this.errors = []
    },
    async reloadSiteParties () {
      this.showIndicator = true
      this.operationResult = 'success'
      this.responsibilitiesLoading = true
      const parties = await this.$rambollfmapi.sites
        .parties(this.siteId)
        .list({ query: { visibility_status: true, time: this.currentDate } })
      const partyLinks = await this.$rambollfmapi.sites
        .partyLinks(this.siteId)
        .list({ query: { time: this.currentDate } })
      this.siteParties.parties = parties
      this.siteParties.partyLinks = partyLinks
      this.responsibilitiesLoading = false
    },
    parseVatString (str) {
      const lwrStr = str.toLowerCase()
      if (
        lwrStr.indexOf('kyllä') !== -1 ||
        lwrStr.indexOf('kylla') !== -1 ||
        lwrStr.indexOf('yes') !== -1 ||
        lwrStr.indexOf('true') !== -1
      ) {
        return 1
      } else if (
        lwrStr.indexOf('ei') !== -1 ||
        lwrStr.indexOf('no') !== -1 ||
        lwrStr.indexOf('false') !== -1
      ) {
        return 0
      }
      return null
    },
    async reload () {
      // NOTE: Bit of a hack solution as it's not most recommended way to handle page refresh
      // May need an update at some point to other reload method
      // Update Map layers
      this.showIndicator = true
      this.operationResult = 'success'
      if (this.buildingsForCurrentSite.length > 0) {
        const layer = {
          name: 'Buildings',
          title: 'Buildings',
          type: 'Points',
          opacity: 1,
          points: this.buildingsForCurrentSite
            .filter(b => this.validateXcoord(b.coordinate_x))
            .filter(b => this.validateYcoord(b.coordinate_y))
            .map(b => {
              return {
                x: parseFloat(b.coordinate_x),
                y: parseFloat(b.coordinate_y),
                projection: 'EPSG:3879',
                data: b,
                name: b.building_code,
                description: b.building_code + ' ' + b.post_office
              }
            })
        }
        this.map.mapLayers = this.map.mapLayers.filter(
          l => l.name !== 'Buildings'
        )
        this.centerMap(layer)
      }
      this.initialized = false
      this.widgetsUpdated = false
      await this.loadSiteData()
      await this.initView()
    },
    loadSiteData: async function () {
      this.setCurrentSite({ siteId: this.siteId })
      await this.loadSite(this.siteId)
      await this.loadSiteParties(this.siteId)
      await this.loadSiteReports(this.siteId)
    },
    onExpansionPanelClick (widget) {
      if(widget.maximized[0] === true) {
        widget.maximized[0] = false
      } else {
        widget.maximized[0] = true
      }
    },
    startEditing (action) {
      if (action === 'openSpaceModifyDialog') {
        this.spaceModifyDialog = true
      } else if (action === 'openUnitModifyDialog') {
        this.unitModifyDialog = true
      }
    },
    updateWidget () {
      this.loadSiteReports(this.siteId)
      this.widgetsUpdated = false
      this.updateWidgetData()
    },
    async buildingProjectsForCurrentSite () {
      const id = this.siteId ? this.siteId : this.currentSiteId

      const data = await this.$rambollfmapi.buildingProjects.getBuildingProjectsBySiteId(id, this.currentDate)
      const metadata = await this.$rambollfmapi.buildingProjects.reports().metadata()

      // XXX Convert boolean value to "Yes/No" value
      data.forEach(row => { row.is_allocated = row.is_allocated ? this.$t('Yes') : this.$t('No') })

      return { data, metadata }
    },
    async spacesForCurrentSite () {
      if(!this.siteSpacesUpdated){
        this.siteSpacesUpdated = true
        await this.loadSiteSpaces(this.siteId)
      }
      const data = this.siteSpaces
      const metadata = await this.$rambollfmapi.spaces.metadata()

      return { data, metadata }
    },
    async costCenterAreas () {
      const buildings = await this.$rambollfmapi.buildings.list({ query: { siteId: this.siteId, time: this.currentDate } })
      const buildingIds = buildings.map(building => building.id_building)
      const data = await this.$rambollfmapi.buildings.costCenterAreasByIdList(buildingIds, this.currentDate)
      const metadata = await this.$rambollfmapi.sites.costCenterMetadata()
      return { data, metadata }
    },
    async unitsForCurrentSiteWithoutSpace () {
      if(!this.siteSpacesUpdated) {
        this.siteSpacesUpdated = true
        await this.loadSiteSpaces(this.siteId)
      }
      await this.fetchUnitInformation()
      var spacesByUnit = helpers.codeUtility.generateMapFromObjectArray(this.siteSpaces, "id_unit")

      const data = this.unitData.filter(item => !spacesByUnit.has(item.id));

      this.siteUnits = data
      var metadata = this.unitMetadata

      return { data, metadata }
    },
    async unitsForCurrentSite () {
      await this.fetchUnitInformation()

      const data = this.unitData
      const metadata = this.unitMetadata

      this.siteUnits = data
      return { data, metadata }
    },
    async unitsForCurrentSiteWithSpace () {
    if(!this.siteSpacesUpdated){
        this.siteSpacesUpdated = true
        await this.loadSiteSpaces(this.siteId)
      }
      await this.fetchUnitInformation()
      var spacesByUnit = helpers.codeUtility.generateMapFromObjectArray(this.siteSpaces, "id_unit")
      const data = this.unitData.filter(item => spacesByUnit.has(item.id));

      var metadata = this.unitMetadata
      return { data, metadata }
    },
    async fetchUnitInformation ()
    {
      const id = this.siteId ? this.siteId : this.currentSiteId
      this.unitData = this.unitData ? this.unitData : await this.$rambollfmapi.sites.unitsExtra(id, {query: { time: this.currentDate}}).list()
      this.unitData.forEach(unit => {
        unit.usage = this.$t(unit.usage)
      })
      this.unitMetadata = this.unitMetadata ? this.unitMetadata : await this.$rambollfmapi.units.extra().metadata()
    },
    async deleteAlert (alertId) {
      if (confirm(this.$t('alert.confirm_delete'))) {
        await this.$rambollfmapi.alerts.delete(alertId)
        this.updateWidget()
      }
    },
    editAlert (item) {
      this.alertToBeEdited = item
      this.showAddAlertDialog = true
    },
    triggerCustomEvents (event) {
      if (event.eventName === 'showCreateRentContractModal') {
        this.openContractModal(event.row)
      } else if (event.eventName === 'goToSite') {
        // site link can only work when there's only one site
        if (event.row.siteIds?.split(',')?.length === 1) {
          this.$router.push(`/sites/${event.row.siteIds?.trim()}`)
        }
      }
    },
    openContractModal (row) {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')
        || this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
        this.outRent = row.outRent
        this.editedContractId = row.idContract
        this.showCreateRentContractModal = true
      }
    },
    onCreateRentContractModalClosed () {
      this.editedContractId = undefined
      this.showCreateRentContractModal = false
    },
    onContractIdUpdated (contractId) {
      this.editedContractId = contractId
    },
    async getSiteTags (metadata) {
      if (metadata?.tags) {
        const availableSiteTags = await this.$rambollfmapi.sites.tags.get()
        this.widgets.find(w => w.id === 'site').data.tags = availableSiteTags
      }
    },
    async getBuildingTags (metadata) {
      if (metadata?.tags) {
        const availableBuildingTags = await this.$rambollfmapi.buildings.tags.get()
        this.widgets.find(w => w.id === 'site.buildings').data.tags = availableBuildingTags
      }
    },
    onBuildingTagsChanged (idBuilding, value) {
      var currentBuildingTags = {
        idBuilding,
        buildingTags: value,
      }
      var allModifiedBuildingTags = this.buildingTags ?? []
      var found = this.buildingTags != null && this.buildingTags.length > 0
        ? this.buildingTags.find(t => t.idBuilding == idBuilding)
        : null

      if (found == null) {
        allModifiedBuildingTags.push(currentBuildingTags)
      } else {
        allModifiedBuildingTags = allModifiedBuildingTags.map(building => building.idBuilding == idBuilding ? currentBuildingTags : building)
      }

      this.buildingTags = allModifiedBuildingTags
    },
    customIndicator (props)
    {
      this.indicatorMessage = props.message
      this.operationResult = props.operationResult
      this.showIndicator = true
      this.reload()
    },
  }
}
</script>
<style scoped>
#site-info {
  padding: 0.5em;
  padding-top: 0;
}

#site-info > .flex {
  padding: 0.5em;
  padding-top: 0;
}

#site-info > .flex > div:not(:first-child) > .v-expansion-panel {
  margin-top: 0.5em;
}

#site-info .subpanel {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: auto;
}

#site-info .subpanel:not(:first-child) {
  margin-top: 0.5em;
}

#site-info .subpanel:last-child {
  margin-bottom: 0.5em;
}
</style>
