<template>
  <div style="position: relative">
    <div
      style="position: absolute; right: 0"
    >
      <SetSelector
        :sets="data.sets"
        :current-set="currentSet ? currentSet.title : null"
        :set-button-text="data.setButtonText"
        @onSetChange="selectSet($event)"
      />
      <SetSelector
        v-if="data.itemSets && data.itemSets.length > 0"
        :sets="data.itemSets"
        :current-set="currentItemSet ? currentItemSet.title : null"
        @onSetChange="selectItemSet($event)"
      />
    </div>
    <HorizontalBarChart
      v-if="GraphData !== null && data.items.length !== 0"
      class="graph"
      :data="GraphData"
      :options="barOptions"
      style="height: 420px"
    />
  </div>
</template>
<script>
import helpers from '../../helpers'
import HorizontalBarChart from '../HorizontalBarChart.vue'
import SetSelector from './components/SetSelector.vue'

/*
Widget data documentation. This widget should have atleast one set with groupBy and stackBy variables defined
data: {
  items: [],
  customColors: function (label){ return "blue" }
  sets: [
    {
      title: "Prospektien m2 (Oletus)",
      groupBy: 'siteName', // Defines by which field we will from the rows
      groupValue: "unit_area_preference", // The length of the bar is calculated using this field in the provided objects
      stackBy: "prospectType", // If the data set needs to be stacked, provide variable name here. This will create datasets based on this variable. 
      limit: 5
    },
    {
      // Without groupValue variable, will default to the number of items in each group
      title: "Prospektien lkm",
      groupBy: 'siteName', // Defines by which field we will from the rows
      stackBy: prospectType, // // If the data set needs to be stacked, provide variable name here. This will create datasets based on this variable. 
      limit: 5, 
    }
  ]
}

*/

export default {
  components: {
    HorizontalBarChart,
    SetSelector,
  },
  props: {
    data: { type: Object, default: null },
  },
  data () {
    return {
      currentSet: null,
      currentItemSet: null,
      barOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 3,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: '#8b8b8b',
          },
          onClick: (e) => e.stopPropagation()
        },
        tooltips: {
          callbacks: {
            // Only round the decimals if the data has any decimals
            label: function (tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              const value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const format = data.datasets[tooltipItem.datasetIndex].format

              let valueString = helpers.format.formatData(value, format)

              return ` ${label}: ${valueString}`
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#8b8b8b',
                zeroLineColor: 'white',
              },
              ticks: {
                fontColor: '#8b8b8b',
                fontSize: 10,
                callback: function (value, index, values) {
                  return helpers.humanize.thousand_separator(value)
                },
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: 'white',
                zeroLineColor: 'white',
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#8b8b8b',
              },
              stacked: true,
            },
          ],
        },
      },
    }
  },
  computed: {
    GraphData: function () {
      if (!this.data || !this.currentSet) {
        return {
          labels: [],
          datasets: [],
        }
      }

      let { items, customColors } = this.data

      const { groupBy, groupValue, stackBy, limit = 13, format } = this.currentSet
      
      // If item set is given, filter values based on it's field name
      if (this.currentItemSet) {
        const { fieldName, value } = this.currentItemSet
        if (value != null) {
          items = items.filter(item => item[fieldName] === value)
        }
      }
      

      // Next up let's form the groups. Aka. rows of the graph
      const grouped = this.groupBy(items, groupBy, groupValue)

      // Now let's sort and limit the possible rows.
      const labels = Object.keys(grouped)

      labels.sort((a, b) => {
        return grouped[b].total - grouped[a].total
      })

      const limitedLabels = labels.slice(0, limit)

      // Next we will create the datasets for each row. NOTE! all possible stacks wont be present by now.
      // NOTE: labels should be limited and sorted by this time into the wanted order
      const stacks = limitedLabels.map((label) => {
        return this.groupBy(grouped[label].objects, stackBy, groupValue)
      })

      // So we want to create a dataset for each stack. Let's find out all the possible labels
      // We could use dedicated function also for this, but in this case this will suffice
      const stackLabels = Object.keys(this.groupBy(items, stackBy))

      // Create the colors
      let colorArray = []

      if (customColors) {
        colorArray = stackLabels.map((label) => customColors(label))
      } else {
        colorArray = this.getThemeColorArray(stackLabels.length)
      }

      return {
        labels: limitedLabels,
        // Create a dataset for each stack label.
        datasets: stackLabels.map((label, index) => {
          return {
            label: this.$i18n.t(label),
            backgroundColor: colorArray[index],
            borderColor: 'white',
            data: stacks.map((stack) =>
              stack[label] ? stack[label].total : 0
            ),
            format
          }
        }),
      }
    },
  },
  watch: {
    data: function () {
      this.initSets()
    },
    currentSet: function (newSet) {
      this.barOptions.scales.xAxes[0].ticks.callback = function (value) {
        return helpers.format.formatData(value, newSet.format)
      }
    }
  },
  mounted: function () {
    this.initSets()
    // showProgress property is to show percentual progress in HorizontalBarDataGraph
    if (this.data.showProgress) {
      // Set the x axes max scale to 100
      this.barOptions.scales.xAxes[0].ticks.max = 100
    }
  },
  methods: {
    selectSet (set) {
      this.currentSet = set
    },
    selectItemSet (set) {
      this.currentItemSet = set
    },
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${204 + 45 * index}, 82%, 72%)`
    },
    initSets () {
      if (this.data.sets && this.data.sets.length > 0) {
        this.selectSet(this.data.sets[0])
      }
      if (this.data.itemSets?.length > 0) {
        this.selectItemSet(this.data.itemSets[0])
      }
    },
    /**
     * Returns an object which keys are the values of groupBy variable.
     * input: [
     *  { foo: "a", bar: "1", value: 3},
     *  { foo: "a", bar: "1", value: 1},
     *  { foo: "a", bar: "1", value: 2}
     * ]
     *
     * groupBy(input, "a", "value")
     *
     * output: {
     *  a: {
     *    label: a,
     *    total: 6,
     *    objects: [
     *      { foo: "a", bar: "1", value: 3},
     *      { foo: "a", bar: "1", value: 1},
     *      { foo: "a", bar: "1", value: 2}
     *    ]
     *  }
     * }
     * @param {Object[]} list - List of items which should contain groupBy values.
     * @param {String} groupBy - Variable name with which groups are formed
     * @param {String} groupValue - The total is calculated using this variable. The underlaying variable should be numeric. If no groupValue is provided, will default to the number of items in group
     */
    groupBy (list, groupBy, groupValue) {
      const grouped = list.reduce((acc, item) => {
        const val = item[groupBy]

        if (acc[val]) {
          acc[val].total = acc[val].total + this.getValue(item, groupValue)
          acc[val].objects.push(item)
        } else {
          acc[val] = {
            label: val,
            total: this.getValue(item, groupValue),
            objects: [item],
          }
        }
        return acc
      }, {})

      return grouped
    },
    /**
     * Checks if the item contains field named as variable. If the value is NaN will return 0, other wise returns number. If variable is not defined
     * will return 1,
     * @param {Object} - Object containing variable field
     * @param {String} - Name of the field in item.
     * @returns Number
     */
    getValue (item, variable) {
      if (!variable) {
        return 1
      }

      const value = Number(item[variable])

      if (isNaN(value)) {
        return 0
      }

      return value
    },
  },
}
/*
Target input for chart
return {
  labels: [ "a", "b", "c" ],
  datasets: [
    { 
      label: "+",
      data: [
        1,
        2,
        3
      ]
    },
    { 
      label: "-",             
      data: [
        3,
        2,
        1
      ]
    }
  ]
}

Ascii representation of the graph
=======
a: +---
b: ++--
c: +++-
*/
</script>

<style scoped></style>
