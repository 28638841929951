<template>
  <span>
    <v-dialog
      v-model="addPartyModal"
      persistent
      max-width="400"
    >
      <NewPartyModal
        :parties="allParties"
        :type="partyType"
        :visible="addPartyModal"
        @close="addPartyModal = false"
        @refreshParties="refreshParties"
      />
    </v-dialog>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <BaseModal
        @cancel="close"
      >
        <template #title>
          {{ $t("leasing.create_new_rental") }}
        </template>
        <template #content>
          <v-form ref="form">
            <v-container
              class="px-2"
            >
              <p class="text-caption font-italic px-3">
                {{ $t('leasing.new_rental_info') }}
              </p>
              <p
                v-if="settings.hasMFilesIntegration"
                class="text-caption font-italic px-3"
              >
                {{ $t('leasing.new_rental_info2') }}
              </p>
              <!-- Tenant corporation -->
              <validation-provider
                v-slot="{ errors }"
                :rules="{required: !namelessSearch}"
                name="tenantCorporation"
              >
                <v-autocomplete
                  v-model="corporation"
                  :items="tenantCorporations"
                  :label="`${$t('Tenant corporation')} ${!namelessSearch ? '*' : ''}`"
                  :loading="partiesLoading"
                  :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
                  :error-messages="errors"
                  :disabled="namelessSearch"
                  clearable
                  return-object
                  item-text="name"
                >
                  <template
                    #append-outer
                  >
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-icon
                            color="grey"
                            :disabled="namelessSearch"
                            @click="() => openPartyModal('tenant corporation')"
                          >
                            person_add
                          </v-icon>
                        </div>
                      </template>
                      <span>{{ $t('Create a new tenant corporation') }}</span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </validation-provider>
              <!-- No tenant checkbox -->
              <v-checkbox
                v-model="namelessSearch"
                :label="$t('leasing.nameless_search')"
                class="ml-3"
                @change="namelessSearchText = ''"
              />
              <!-- No tenant extra info -->
              <v-expand-transition>
                <v-text-field
                  v-if="namelessSearch"
                  v-model="namelessSearchText"
                  :label="$t('leasing.new_rental_tenant')"
                  class="pt-0 mt-0 mb-4"
                >
                  <template #append>
                    <InfoBall
                      :info="$t('leasing.rental_tag_info')"
                      right
                    />
                  </template>
                </v-text-field>
              </v-expand-transition>
              <!-- State of rental process -->
              <validation-provider
                v-slot="{ errors }"
                name="rentalProcessStage"
                rules="required"
              >
                <v-select
                  v-model="stage"
                  :items="rentalProcessStages"
                  :label="$t('leasing.rental_process_stage') + ' *'"
                  :error-messages="errors"
                  item-text="label"
                  return-object
                >
                  <template #selection="{ item }">
                    {{ $t(`${item.label}`) }}
                  </template>
                  <template #item="{item}">
                    {{ $t(`${item.label}`) }}
                  </template>
                </v-select>
              </validation-provider>
              <!-- Lead responsible -->
              <v-autocomplete
                v-model="responsible"
                :label="$t('prospect.primary_responsible')"
                :items="users.filter(user => user != secondaryResponsible)"
                class="form-field"
                item-text="name"
                item-value="id_user"
                return-object
                clearable
              />
              <!-- Secondary lead responsible -->
              <v-autocomplete
                v-model="secondaryResponsible"
                :label="$t('prospect.secondary_responsible')"
                :items="users.filter(user => user != responsible)"
                class="form-field"
                item-text="name"
                item-value="id_user"
                return-object
                clearable
              />
              <!-- Lead origin -->
              <v-select
                v-model="leadOrigin"
                :label="$t('prospect.lead_origin')"
                :items="leadOrigins"
                return-object
                item-text="label"
                class="form-field"
                clearable
                @change="clearLeadInfo"
              >
                <template #selection="{ item }">
                  {{ $t(`${item.label}`) }}
                </template>
                <template #item="{item}">
                  {{ $t(`${item.label}`) }}
                </template>
              </v-select>
              <!-- Lead origin comments (for other leads only) -->
              <v-textarea
                v-if="leadOrigin && leadOrigin.label === 'Muu liidi'"
                v-model="leadComments"
                :label="$t('prospect.lead_comments')"
                rows="4"
                class="form-field"
                maxlength="4000"
                counter
              />
              <!-- Lead mediator -->
              <validation-provider
                v-if="leadOrigin && leadOrigin.label === 'Liidi välittäjältä'"
                v-slot="{ errors }"
                name="mediator"
                rules="required"
              >
                <v-autocomplete
                  v-model="mediator"
                  :label="$t('Välittäjäyritys') + ' *'"
                  :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
                  :items="mediators"
                  :loading="partiesLoading"
                  :error-messages="$t(errors)"
                  clearable
                  return-object
                  item-text="name"
                  class="form-field"
                >
                  <template
                    #append-outer
                  >
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-icon
                            color="grey"
                            @click="() => openPartyModal('mediator')"
                          >
                            person_add
                          </v-icon>
                        </div>
                      </template>
                      <span>{{ $t('Create a new mediator') }}</span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </validation-provider>
              <!--Currency selection-->
              <CurrencySelect 
                v-if="userCurrencies.length > 1"
                :value="currencyId"
                class="form-field"
                :label="$t('Currency')"
                :items="userCurrencies"
                item-text="code"
                item-value="id"
                @change="currencyChanged"
              />
            </v-container>
          </v-form>
        </template>
        <template #footer>
          <v-btn
            :disabled="invalid"
            :loading="saving"
            type="submit"
            rounded
            depressed
            color="primary"
            @click="save"
          >
            {{ $t('Save') }}
          </v-btn>
        </template>
      </BaseModal>
    </validation-observer>
  </span>
</template>

<script>
import BaseModal from '../../general/BaseModal.vue'
import InfoBall from '../../InfoBall.vue'
import NewPartyModal from './NewPartyModal.vue'
import CurrencySelect from '../../CurrencySelect.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState, mapGetters, mapActions }  from 'vuex'

export default {
  emits: ['close', 'openRentalProcessModal'],
  name: "NewRentalProcessModal",
  components: { 
    BaseModal,
    InfoBall,
    NewPartyModal,
    ValidationProvider,
    ValidationObserver,
    CurrencySelect
  },
  props: {
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      // Form state values
      corporation: null,
      namelessSearch: false,
      namelessSearchText: '',
      stage: null,
      responsible: null,
      secondaryResponsible: null,
      leadOrigin: null,
      leadComments: '',
      mediator: null,
      currencyId: 1,

      // Fetched values
      allParties: [],
      tenantCorporations: [],
      mediators: [],
      users: [],

      // Modal states
      addPartyModal: false,
      partyType: '',

      // Loading state values
      partiesLoading: false,
      usersLoading: false,
      saving: false,
    }
  },
  computed: {
    ...mapState('app', ['userInfo']),

    ...mapGetters('app', ['definitionsByGroupLabel', 'settings', 'currencies']),

    // Definitions
    rentalProcessStages () {
      // Only first 5 stages should be available when creating new rental process
      return this.definitionsByGroupLabel('leasing.rental_process_stage')
        .filter(stage => parseInt(stage.label.charAt(0)) < 6)
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    leadOrigins () {
      return this.definitionsByGroupLabel('prospect.leadorigin').filter(item => item.label !== 'Olemassa oleva vuokrasuhde')
    },
    userCurrencies () {
      return this.settings.availableCurrencies.map(ac => { return this.currencies.find(c => c.code === ac) })
    },
  },

  watch: {
    visible: {
      handler: async function (value) {
        if (value) {
          await this.open()
        }
        else {
          this.reset()
        }
      }
    },
  },
  async mounted () {
    await this.open()
  },

  methods: {
    ...mapActions('rentalProcess', ['openRentalProcess', 'getRentalProcess']),
    close () {
      this.$emit('close')
    },
    async open () {
      this.getParties()
      await this.getLeasingUsers()
      this.responsible = this.users.find(user => user.id_user === this.userInfo.id)
      this.stage = this.rentalProcessStages.find(stage => stage.label === '1. Lead')
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.observer.reset()
    },
    async save () {
      // Check values
      if (!(this.checkFields())) {
        alert(this.$t('Check info'))
        return
      }
      // Create new rental process
      this.saving = true
      const rentalProcess = this.createRentalProcessObject()
      const id = await this.$rambollfmapi.leasing.rentalProcesses.post(rentalProcess)
      this.saving = false
      this.openRentalProcess({id})
      this.$emit('openRentalProcessModal')

      this.getRentalProcess({})
      this.$emit('close')
    },
    // Checks all fields to make sure there are no errors
    checkFields () {
      // Check stage is selected
      if (!(this.stage)) {
        return false
      }
      // Check business corporation or nameless search is selected
      if (!this.namelessSearch && !(this.corporation)) {
        return false
      }
      
      return true
    },
    // Create rental process by getting party ids and trimming values
    createRentalProcessObject () {
      return  {
        corporation_id: this.corporation?.id,
        nameless_search: this.namelessSearch ?? 0,
        tag: this.namelessSearchText?.trim(),
        stage: this.stage.id,
        responsible: this.responsible?.id_user,
        secondary_responsible: this.secondaryResponsible?.id_user,
        lead_origin: this.leadOrigin?.id,
        lead_comments: this.leadComments?.trim(),
        mediator_id: this.mediator?.id,
        currency_id: this.currencyId,
      }
    },
    openPartyModal (type) {
      this.partyType = type
      this.addPartyModal = true
    },
    clearLeadInfo () {
      this.leadComments = '',
      this.mediator = null
    },
    async getParties () {
      this.partiesLoading = true
      const data = await this.$rambollfmapi.parties.list({ query: { party_type: 0, visibility_status: true } })
      this.allParties = data
      this.tenantCorporations = data.filter(party => party.tenant_corporation === true)
      this.mediators = data.filter(party => party.mediator_company === true)
      this.partiesLoading = false
    },
    async getLeasingUsers () {
      this.usersLoading = true
      this.users =  await this.$rambollfmapi.leasing.leasingUsers.get()
      this.usersLoading = false
    },
    async refreshParties (type, id) {
      await this.getParties()
      if (type === 'tenant corporation') {
        this.corporation = this.tenantCorporations.find(party => party.id === id)
      }
      if (type === 'mediator') {
        this.mediator = this.mediators.find(party => party.id === id)
      }
    },
    currencyChanged (value) {
      this.currencyId = value
    }
  },
}
</script>

<style scoped>
</style>