<template>
  <v-row
    id="internalrent-dashboard"
  >
    <v-col
      v-for="widget in smallWidgets"
      :key="widget.id"
      lg="6"
      cols="12"
    >
      <DynamicWidget
        v-if="widgets.find(uw => uw.name === widget.id)"
        :type="widget.type"
        :title="$t(widget.id)"
        :data="widget.data"
        :loading="widget.loading"
        :stored-views="widget.data.storedViews"
      />
    </v-col>
    <v-col
      cols="12"
    > 
      <div>
        <draggable
          v-model="largeWidgets"
          :disabled="!sortableWidgets"
        >
          <div
            v-for="widget in largeWidgets"
            :key="widget.id"
            class="widget"
          >
            <DynamicWidget
              v-if="widgets.find(uw => uw.name === widget.id)"
              :type="widget.type"
              :title="$t(widget.id)"
              :data="widget.data"
              :loading="widget.loading"
              :style="{ 'border-color': draggableColor }"
              :maximized="widget.maximized"
              :stored-views="widget.data.storedViews"
              @open="widget.maximized = $event"
            />
          </div>
        </draggable>
      </div>
      <div style="right: 0; bottom: 0; position: sticky; z-index: 1">
        <div>
          <v-spacer />
          <v-btn
            v-if="sortableWidgets"
            outlined
            rounded
            text
            @click="getWidgetOrder()"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            v-if="sortableWidgets"
            rounded
            depressed
            class="primary"
            @click="setWidgetOrder()"
          >
            {{ $t('Save default view') }}
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DynamicWidget from '../DynamicWidget.vue'
import draggable from 'vuedraggable'
import helpers from '../../helpers'
import _debounce from 'lodash/debounce';
export default {
  name: 'Dashboard',
  components: {
    DynamicWidget,
    draggable
  },
  props: {
    filteredPortfolios: {
      type: Array,
      default: function () { return [] }
    }
  },
  data () {
    return {
      allWidgets: [
        {
          id: 'internalrent_dashboard.areas_by_business_units',
          type: 'PieDataGraph',
          data: {
            items: [],
            sets: [
              {
                title: this.$t('Allocated area'),
                groupBy: 'domain',
                method: 'sum',
                parameters: ['area']
              }
            ],
            label: 'mainUsage',
            field: 'mainUsage',
            report: 'BusinessUnitAreas'
          },
          small: true,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.budget_per_cost_type',
          type: 'ExpenseBudgetGraph',
          data: {
            items: [],
            labels: [],
            sets: [ 
              {
                title: this.$t('Total (€)'),
                groupBy: 'name',
                method: 'sum',
                parameters: ['thisYearBudget']
              },
              {
                title: this.$t('€/m\u00B2'),
                groupBy: 'name',
                method: 'sum',
                parameters: ['budgetPerArea']
              }
            ],
            report: 'budgetPerCostType'
          },         
          small: true,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.budget_and_realisation_per_cost_type',
          type: 'BudgetRealizationGraph',
          data: {
            items: [],
            labels: [],
            sets: [
              {
                title: this.$t('Total (€)'),
                groupBy: 'name',
                method: 'sum',
                parameters: ['thisYearBudget', 'thisYearRealisation']
              },
              {
                title: this.$t('€/m\u00B2'),
                groupBy: 'name',
                method: 'sum',
                parameters: ['budgetPerArea', 'thisYearRealisation']
              }
            ],
            report: 'budgetPerCostType'
          },
          small: false,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.costcenters_total_by_portfolios',
          type: 'GroupTable',
          data: {
            group: 'idPortfolio',
            childRowIdentifier: 'costcenterName',
            headers: [
              {
                text: this.$t('Status portfolio'),
                value: 'portfolioName',
                onlyParent: true
              },
              {
                text: this.$t('Cost center'),
                value: 'costcenterName',
                onlyChild: true,
                readAs: 'text'
              },
              {
                text: this.$t('Net room area'),
                value: 'shareFromArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Allocated area'),
                value: 'allocatedArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Capital rent'),
                value: 'capitalRent',
                sum: true,
                format: 'Euro'
              },
              {
                text: this.$t('Maintenance rent'),
                value: 'maintenanceRent',
                sum: true,
                format: 'Euro'
              },
              {
                text: this.$t('fimx_set.total'),
                value: 'totalRent',
                sum: true,
                format: 'Euro'
              },
              { text: '', value: 'toggle' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'shareFromArea', unit: 'area', format: 'Area' },
              { value: 'allocatedArea', unit: 'area', format: 'Area' },
              { value: 'capitalRent', unit: 'Rent', format: 'Euro' },
              { value: 'maintenanceRent', unit: 'Rent', format: 'Euro' },
              { value: 'totalRent', unit: 'Rent', format: 'Euro' },
              {}
            ],
            items: [],
            report: 'TotalCostcenters'
          },
          small: false,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses',
          type: 'GroupTable',
          data: {
            group: 'idPortfolio',
            childRowIdentifier: 'costcenterName',
            headers: [],
            items: [],
            report: 'TotalCostcentersWithExpenses'
          },
          bgnHeaders: [
              {
                text: this.$t('Status portfolio'),
                value: 'portfolioName',
                onlyParent: true
              },
              {
                text: this.$t('Cost center'),
                value: 'costcenterName',
                onlyChild: true,
                readAs: 'text'
              },
              {
                text: this.$t('Net room area'),
                value: 'shareFromArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Allocated area'),
                value: 'allocatedArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Share'),
                value: 'share_percentage',
                onlyChild: true,
                format: 'Percentage'
              }
            ],
            endHeaders: [
              {
                text: this.$t('fimx_set.total'),
                value: 'totalRent',
                sum: true,
                format: 'Euro'
              },
              
              { text: '', value: 'toggle' }
            ],
            bgnFooters: [
              { text: this.$t('Total') },
              {},
              { value: 'shareFromArea', format: 'Area' },
              { value: 'allocatedArea', format: 'Area'},
              {}
            ],
            endFooters: [
              { value: 'totalRent', format: 'Euro' },
              {}
            ],
          small: false,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.domains_total_by_portfolios',
          type: 'DataTable',
          data: {
            headers: [
              {
                text: this.$t('Status portfolio'),
                value: 'portfolioName',
                readAs: 'text'
              },
              {
                text: this.$t('Business unit'),
                value: 'businessUnitName',
                readAs: 'text'
              },
              {
                text: this.$t('Domain'),
                value: 'domainName',
                readAs: 'text'
              },

              {
                text: this.$t('Net room area'),
                value: 'shareFromArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Allocated area'),
                value: 'allocatedArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Capital rent'),
                value: 'capitalRent',
                format: 'Euro'
              },
              {
                text: this.$t('Maintenance rent'),
                value: 'maintenanceRent',
                format: 'Euro'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              { value: 'shareFromArea', format: 'Area' },
              { value: 'allocatedArea', format: 'Area' },
              { value: 'capitalRent', format: 'Euro' },
              { value: 'maintenanceRent', format: 'Euro' }
            ],
            items: [],
            report: 'TotalDomains'
          },
          small: false,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.costcenters_by_expense_type',
          type: 'DataTable',
          data: {
            items: [],
            headers: [],
            footers: [],
            report: 'CCByExpenseType'
          },
          bgnHeaders: [
              {
              text: this.$t('Status portfolio'),
              value: 'portfolioName'
              },
              { text: this.$t('Floor'), value: 'floorName' },
              { text: this.$t('Room'), value: 'spaceNumber', readAs: 'text' },
              { text: this.$t('Purpose of use'), value: 'purpose' },
              {
                text: this.$t('Business unit'),
                value: 'businessUnit',
                readAs: 'text'
              },
              { text: this.$t('Domain'), value: 'domain', readAs: 'text' },
              {
                text: this.$t('Cost center'),
                value: 'costcenterCode',
                readAs: 'text'
              },
              {
                text: this.$t('Cost center name'),
                value: 'costcenterName',
                readAs: 'text'
              },
              { text: this.$t('Net room area'), value: 'roomArea', format: 'Area' },
              { text: this.$t('Share'), value: 'share', format: 'Percentage' },
              { text: this.$t('Share from room area'), value: 'shareFromArea', format: 'Area' },
              { text: this.$t('Allocated area'), value: 'allocatedArea', format: 'Area' },
          ],
          endHeaders: [
              {
                text: this.$t('fimx_set.total'),
                value: 'totalRent',
                format: 'Euro'
              },
              {
                text: this.$t('Total (€/m2)'),
                value: 'totalRentBySquare',
                format: 'Euro'
              },
              {
                text: this.$t('internalRent.Additional cost'),
                value: 'additionalCost',
                format: 'Euro'
              },
              {
                text: this.$t('Additional cost for purpose'),
                value: 'additionalCostForPurpose',
                format: 'Euro'
              }
          ],
          bgnFooters: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { value: 'shareFromArea', format: 'Area'},
              { value: 'allocatedArea', format: 'Area' },
          ],
          endFooters: [
              { value: 'totalRent', format: 'Euro' },
              {},
              { value: 'additionalCost', format: 'Euro' },
              { value: 'additionalCostForPurpose', format: 'Euro' }
          ],
          loading: true
        },
        {
          id: 'internalrent_portfolio.expenses_by_space',
          type: 'DataTable',
          data: {
            items: [],
            headers: [],
            footers: [],
            report: 'ExpensesBySpace'
          },
          bgnHeaders: [
            {
              text: this.$t('Status portfolio'),
              value: 'portfolioName',
              readAs: 'text'
            },
            { text: this.$t('Room'), value: 'spaceCode', readAs: 'text' },
            { text: this.$t('Purpose of use'), value: 'purpose' },
            { text: this.$t('Net room area'), value: 'area', format: 'Area' }
          ],
          endHeaders: [
            {
              text: this.$t('Capital rent'),
              value: 'capitalRent',
              format: 'Euro'
            },
            {
              text: this.$t('Maintenance rent'),
              value: 'maintenanceRent',
              format: 'Euro'
            },
            {
              text: this.$t('Totals'),
              value: 'totalRent',
              format: 'Euro'
            },
            {
              text: this.$t('Total (€/m2)'),
              value: 'totalRentBySquare',
              format: 'Euro'
            },
            {
              text: this.$t('Additional cost'),
              value: 'additionalCost',
              format: 'Euro'
            },
            {
              text: this.$t('Additional cost for purpose'),
              value: 'additionalCostForPurpose',
              format: 'Euro'
            }
          ],
          bgnFooters: [
            { text: this.$t('Total') },
            {},
            {},
            { value: 'area', format: 'Area' }
          ],
          endFooters: [
            {
              value: 'capitalRent',
              format: 'Euro'
            },
            {
              value: 'maintenanceRent',
              format: 'Euro'
            },
            { value: 'totalRent', format: 'Euro' },
            {},
            { value: 'additionalCost', format: 'Euro' },
            { value: 'additionalCostForPurpose', format: 'Euro' }
          ],
          loading: true
        },
        {
          id: 'internalrent_dashboard.data_integration_01',
          type: 'DataTable',
          data: {
            title: this.$t('Accounting transfer document'),
            headers: [
              {
                text: this.$t('Account'),
                value: 'account'
              },
              {
                text: this.$t('Proj'),
                value: 'project'
              },
              {
                text: this.$t('VA/kp'),
                value: 'costcenter'
              },
              {
                text: this.$t('VAT'),
                value: 'vat'
              },
              {
                text: this.$t('Partner'),
                value: 'partner'
              },
              {
                text: this.$t('Site'),
                value: 'site'
              },
              {
                text: this.$t('Person'),
                value: 'person'
              },
              {
                text: this.$t('Differentation'),
                value: 'differentationCode'
              },
              {
                text: this.$t('Debit+ / Credit-'),
                value: 'euroAmount',
                format: 'Euro'
              },
              {
                text: this.$t('Transfer description'),
                value: 'description'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { value: 'amount', format: 'Euro'}
            ],
            items: [],
            report: 'AtoIntegration'
          },
          small: false,
          loading: true,
          show: false
        },
        {
          id: 'internalrent_dashboard.data_integration_02',
          type: 'DataTable',
          data: {
            title: this.$t('Accounting transfer document'),
            headers: [
              {
                text: this.$t('Cost center name'),
                value: 'costcenterName'
              },
              {
                text: this.$t('Building'),
                value: 'buildingName'
              },
              {
                text: this.$t('Cost center'),
                value: 'costcenter'
              },
              {
                text: this.$t('ir_ka.project'),
                value: 'project'
              },
              {
                text: this.$t('ir_ka.firstsum'),
                value: 'firstSum',
                format: 'Euro'
              },
              {
                text: this.$t('ir_ka.secondsum'),
                value: 'secondSum',
                format: 'Euro'
              },
              {
                text: this.$t('ir_ka.estateupkeep'),
                value: 'estateUpkeepExpense',
                format: 'Euro'
              },
              {
                text: this.$t('ir_ka.upkeep'),
                value: 'upkeepExpense',
                format: 'Euro'
              },
              {
                text: this.$t('Total (€/mth)'),
                value: 'euroAmountByMonth',
                format: 'Euro'
              }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              { value: 'firstSum', format: 'Euro'},
              { value: 'secondSum', format: 'Euro'},
              { value: 'estateUpkeepExpense', format: 'Euro'},
              { value: 'upkeepExpense', format: 'Euro'},
              { value: 'euroAmountByMonth', format: 'Euro'}
            ],
            items: [],
            report: 'KnaIntegration'
          },
          small: false,
          loading: true,
          show: false
        },
        {
          id: 'internalrent_dashboard.data_integration_03',
          type: 'DataTable',
          data: {
            title: this.$t('Accounting transfer document'),
            headers: [
              {
                text: this.$t('Accounting account'),
                value: 'kirjanpidontili'
              },
              {
                text: this.$t('Domain'),
                value: 'tulosyksikkö'
              },
              {
                text: this.$t('Cost center'),
                value: 'kustannuspaikka'
              },
              {
                text: this.$t('Internal order'),
                value: 'sisäinentilaus'
              },
              {
                text: this.$t('ir_ka.project'),
                value: 'projekti',
              },
              {
                text: this.$t('Area of operation'),
                value: 'toimintaalue',
              },
              {
                text: this.$t('VAT code'),
                value: 'alvkoodi',
              },
              {
                text: "€",
                value: 'euro',
                format: 'Euro'
              },
              {
                text: this.$t('Debit/Credit'),
                value: 'debCre'
              },
              {
                text: this.$t('Tax rate'),
                value: 'verokanta'
              },
              {
                text: this.$t('Building'),
                value: 'rakennus'
              },
              {
                text: this.$t('Approver'),
                value: 'hyväksyjä'
              },
              {
                text: this.$t('Tr.type'),
                value: 'trtyyppi'
              },
              {
                text: this.$t('Ir.Partner'),
                value: 'kumppani'
              },
            ],
            footers: [],
            items: [],
            report: 'TkuIntegration'
          },
          small: false,
          loading: true,
          show: false
        },
        {
          id: 'internalrent_dashboard.costcenters_total_by_portfolios_realization',
          type: 'GroupTable',
          data: {
            group: 'idPortfolio',
            childRowIdentifier: 'costcenterName',
            headers: [
              {
                text: this.$t('Status portfolio'),
                value: 'portfolioName',
                onlyParent: true
              },
              {
                text: this.$t('Cost center'),
                value: 'costcenterName',
                onlyChild: true,
                readAs: 'text'
              },
              {
                text: this.$t('Net room area'),
                value: 'shareFromArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Allocated area'),
                value: 'allocatedArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Capital rent'),
                value: 'capitalRent',
                sum: true,
                format: 'Euro'
              },
              {
                text: this.$t('Maintenance rent'),
                value: 'maintenanceRent',
                sum: true,
                format: 'Euro'
              },
              {
                text: this.$t('Totals'),
                value: 'totalRent',
                sum: true,
                format: 'Euro'
              },
              { text: '', value: 'toggle' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              { value: 'shareFromArea', unit: 'area', format: 'Area' },
              { value: 'allocatedArea', unit: 'area', format: 'Area' },
              { value: 'capitalRent', unit: 'Rent', format: 'Euro' },
              { value: 'maintenanceRent', unit: 'Rent', format: 'Euro' },
              { value: 'totalRent', unit: 'Rent', format: 'Euro' },
              {}
            ],
            items: [],
            report: 'TotalCostcentersRealization'
          },
          small: false,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses_realization',
          type: 'GroupTable',
          data: {
            group: 'idPortfolio',
            childRowIdentifier: 'costcenterName',
            headers: [],
            items: [],
            report: 'TotalCostcentersWithExpensesRealization'
          },
          bgnHeaders: [
              {
                text: this.$t('Status portfolio'),
                value: 'portfolioName',
                onlyParent: true
              },
              {
                text: this.$t('Cost center'),
                value: 'costcenterName',
                onlyChild: true,
                readAs: 'text'
              },
              {
                text: this.$t('Net room area'),
                value: 'shareFromArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Allocated area'),
                value: 'allocatedArea',
                sum: true,
                format: 'Area'
              },
              {
                text: this.$t('Share'),
                value: 'share_percentage',
                onlyChild: true,
                format: 'Percentage'
              }
          ],
          endHeaders: [
              {
                text: this.$t('Totals'),
                value: 'totalRent',
                sum: true,
                format: 'Euro'
              },
              { text: '', value: 'toggle' }
          ],
          bgnFooters: [
              { text: this.$t('Total') },
              {},
              { value: 'shareFromArea', format: 'Area' },
              { value: 'allocatedArea', format: 'Area'},
              {}
          ],
          endFooters: [
              { value: 'totalRent', format: 'Euro' },
              {}
          ],
          small: false,
          loading: true,
          show: true
        },
        {
          id: 'internalrent_dashboard.costcenters_by_expense_type_realization',
          type: 'DataTable',
          data: {
            items: [],
            headers: [],
            footers: [],
            report: 'CCByExpenseTypeRealization'
          },
          bgnHeaders: [
              {
                text: this.$t('Building name'),
                value: 'buildingName'
              },
              { text: this.$t('Floor'), value: 'floorName' },
              { text: this.$t('Room'), value: 'spaceNumber', readAs: 'text' },
              { text: this.$t('Purpose of use'), value: 'purpose' },
              {
                text: this.$t('Business unit'),
                value: 'businessUnit',
                readAs: 'text'
              },
              { text: this.$t('Domain'), value: 'domain', readAs: 'text' },
              {
                text: this.$t('Cost center'),
                value: 'costcenterCode',
                readAs: 'text'
              },
              {
                text: this.$t('Cost center name'),
                value: 'costcenterName',
                readAs: 'text'
              },
              { text: this.$t('Net room area'), value: 'roomArea', format: 'Area' },
              { text: this.$t('Share'), value: 'share', format: 'Percentage' },
              { text: this.$t('Share from room area'), value: 'shareFromArea', format: 'Area' },
              { text: this.$t('Allocated area'), value: 'allocatedArea', format: 'Area' }
          ],
          endHeaders: [
              {
                text: this.$t('Totals'),
                value: 'totalRent',
                format: 'Euro'
              },
              {
                text: this.$t('Total (€/m2)'),
                value: 'totalRentBySquare',
                format: 'Euro'
              },
              {
                text: this.$t('Additional cost'),
                value: 'additionalCost',
                format: 'Euro'
              },
              {
                text: this.$t('Additional cost for purpose'),
                value: 'additionalCostForPurpose',
                format: 'Euro'
              }
          ],
          bgnFooters: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { value: 'roomArea', format: 'Area'},
              {},
              { value: 'shareFromArea', format: 'Area'},
              { value: 'allocatedArea', format: 'Area' }
          ],
          endFooters: [
              { value: 'totalRent', format: 'Euro' },
              {},
              { value: 'additionalCost', format: 'Euro' },
              { value: 'additionalCostForPurpose', format: 'Euro' }
          ],
          loading: true
        },
        {
          id: 'internalrent_portfolio.expenses_by_space_realization',
          type: 'DataTable',
          data: {
            items: [],
            headers: [],
            footers: [],
            report: 'ExpensesRealizationBySpace'
          },
          bgnHeaders: [
            {
                text: this.$t('Status portfolio'),
                value: 'portfolioName',
                readAs: 'text'
              },
            { text: this.$t('Building name'), value: 'buildingName' },
            { text: this.$t('Room'), value: 'spaceCode', readAs: 'text' },
            { text: this.$t('Purpose of use'), value: 'purpose' },
            { text: this.$t('Net room area'), value: 'area', format: 'Area' }
          ],
          endHeaders: [
            {
              text: this.$t('Capital rent'),
              value: 'capitalRent',
              format: 'Euro'
            },
            {
              text: this.$t('Maintenance rent'),
              value: 'maintenanceRent',
              format: 'Euro'
            },
            {
              text: this.$t('Totals'),
              value: 'totalRent',
              format: 'Euro'
            },
            {
              text: this.$t('Total (€/m2)'),
              value: 'totalRentBySquare',
              format: 'Euro'
            },
            {
              text: this.$t('Additional cost'),
              value: 'additionalCost',
              format: 'Euro'
            },
            {
              text: this.$t('Additional cost for purpose'),
              value: 'additionalCostForPurpose',
              format: 'Euro'
            }
          ],
          bgnFooters: [
            { text: this.$t('Total') },
            {},
            {},
            {},
            { value: 'area', format: 'Area' }
          ],
          endFooters: [
            {
              value: 'capitalRent',
              format: 'Euro'
            },
            {
              value: 'maintenanceRent',
              format: 'Euro'
            },
            { value: 'totalRent', format: 'Euro' },
            {},
            { value: 'additionalCost', format: 'Euro' },
            { value: 'additionalCostForPurpose', format: 'Euro' }
          ],
          loading: true
        },
        {
          id: 'internalrent_dashboard.swarmed_expenses',
          type: 'DataTable',
          data: {
            items: [],
            headers: [],
            footers: [],
            report: 'SwarmedExpenses'
          },
          beginHeaders: [
            {
              text: this.$t('Status portfolio'),
              value: 'portfolioName',
            }
          ],
          endHeaders: [
            {
              text: this.$t('Totals'),
              value: 'portfoliosExpenseSum',
              sum: true,
              format: 'Euro'
            }
          ],
          beginFooters: [
            { text: this.$t('Total') }
          ],
          endFooters: [
          {
              value: 'portfoliosExpenseSum',
              format: 'Euro'
            }
          ],
          loading: true
        },
      ],
      reportData: {},
      widgetsWithExpenseHeaders: ['internalrent_dashboard.costcenters_by_expense_type', 'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses', 
      'internalrent_portfolio.expenses_by_space', 'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses_realization',
      'internalrent_portfolio.expenses_by_space_realization', 'internalrent_dashboard.costcenters_by_expense_type_realization'],
      widgetOrder: [],
      smallWidgets: [],
      largeWidgets: [],
      draggableColor: null
    }
  },
  computed: {
    ...mapState('app', ['sortableWidgets', 'userGroups', 'userWidgets']),
    ...mapState('internalRent', ['expenses', 'portfolio', 'widgets', 'loadedReports', 'inspectionYear', 'inspectionMonths']),
    ...mapGetters('app', ['applicationPermissions']),
    ...mapGetters('internalRent', ['getReport', 'getAllTargetingCodes'])
  },
  watch: {
    inspectionYear: function () {
      this.getReports()
    },
    filteredPortfolios: async function () {
      this.getReports()
      this.getWidgetOrder()
    },
    userWidgets: async function () {
      this.getReports()
      this.getWidgetOrder()
    },
    loadedReports: function () {
      this.updateWidgetData()
    },
    inspectionMonths: {
      handler: function () {
        this.getReports()
      },
      deep: true
    },    
    sortableWidgets: function (boolean) {
      if (boolean === true) {
        this.draggableColor = '#8b8b8b'
      } else {
        this.draggableColor = null
      }
    }
  },
  async mounted () {
    if (this.inspectionYear) {
      this.getReports()
    }
    this.getWidgetOrder()
  },
  methods: {
    ...mapActions('internalRent', ['loadReports']),
    async updateWidgetData () {
      // Permission for SIS_VUOKRA_SIIRTOTIEDOSTOT
        const permission = this.applicationPermissions.find(
          p => p.id === '12.01.10'
        )

      const filteredWidgets = this.allWidgets.filter(aw => this.widgets.find(uw => uw.name === aw.id))
      for (let w of filteredWidgets) {
        w.data.items = await this.getReport(w.data.report)
        if (w.data.items === null) {
          w.data.items = []
        }
        if (this.widgetsWithExpenseHeaders.find(id => id === w.id)) {
          this.addExpensesForWidget(w.id, w.data.items)
        }
        if (w.id.indexOf('internalrent_dashboard.data_integration') > -1 && permission && permission.accessLevel === 1 ) {
          w.show = true
        }
        if (w.id === 'internalrent_dashboard.budget_per_cost_type') {
          w.data.labels = this.expenses
            .map(expense => { return expense.name })
        }
        if(w.id === 'internalrent_dashboard.swarmed_expenses') {
          w.data.headers = this.buildSwarmedExpensesHeaders(w.data.items, w.beginHeaders, w.endHeaders)
          w.data.footers = this.buildSwarmedExpensesFooters(w.data.headers, w.beginFooters, w.endFooters)
          w.data.items = this.buildSwarmedExpenses(w.data.items)
        }
        w.loading = false

      }
    },
    addExpensesForWidget (widget, report) {
      const expenseIds = []

      // Get ids of distinct expenses
      report.map(x => x.expenses).forEach(x => {
        x.forEach(y => {
          if (!expenseIds.includes(y.idExpense)) {
            expenseIds.push(y.idExpense)
          }
        })
      })
      report.map(x => {
        expenseIds.forEach(id => {
          if (typeof x[String(id) + 'Rent'] == 'undefined') {
             x[String(id) + 'Rent'] = 0;
          }
        })
      })
      this.updateWidgetHeaders(widget, expenseIds)
    },
    getValueWithUnit (value, header) {
      if (header.indexOf('date') >= 0) {
        return helpers.humanize.date(value, 'date')
      }

      if (header.indexOf('area') >= 0) {
        return value + ' m&sup2;'
      }

      if (
        header.indexOf('capitalRent') >= 0 ||
        header.indexOf('maintenanceRent') >= 0 ||
        header.indexOf('totalRentByMonth') >= 0
      ) {
        if (isNaN(Number(value))) {
          return '-'
        }
        return Number(value).toFixed(2) + ' €'
      }

      if (header.indexOf('trend') >= 0) {
        return (value > 0 ? '+' : '') + Number(value).toFixed(0) + '%'
      }

      return value
    },
    updateWidgetHeaders (widgetId, expenses) {
      const widget = this.allWidgets.find(x => x.id === widgetId)

      const newHeaders = []
      const newFooters = []

      for (var idExpense of expenses) {
        var expense = this.expenses.find(x => x.id === idExpense)
        if (expense.selectable === true) {
          newHeaders.push({
            text: expense ? expense.name : '',
            value: String(idExpense) + 'Rent',
            format: 'Euro',
            sum: true
          })
          newFooters.push({
            value: String(idExpense) + 'Rent',
            format: 'Euro'
          })
        }
      }

      widget.data.headers = [
        ...widget.bgnHeaders,
        ...newHeaders,
        ...widget.endHeaders
      ]
      if (widget.bgnFooters) {
        widget.data.footers = [
          ...widget.bgnFooters,
          ...newFooters,
          ...widget.endFooters
        ]
      }
    },
    getReports (){
      if(this.filteredPortfolios) {
        this.getReportsDebounce(this);
      }
    },
    //Debounce reports which prevents multiple report fetches at startup
    getReportsDebounce: _debounce((that) => {
      // logic change, we retrieve the data if portfolio or portfolios have been selected
      if (that.filteredPortfolios.length > 0) {
        for (const widget of that.allWidgets) {
          widget.loading = true
        }
        that.loadReports(that.filteredPortfolios)
      } else {
        // Otherwisely set it so that no data is displayed
        that.allWidgets.forEach(w => {
          w.loading = false
          w.data.items = []
        })
      }
    }, 500),
    sortWidgets (widgets) {
      this.smallWidgets = widgets.filter(w => w.small === true)
      this.largeWidgets = widgets.filter(w => w.small !== true)
    },
    async getWidgetOrder () {
      this.widgetOrder = this.userWidgets.filter(w => w.id !== -1)

      const filteredWidgets = this.allWidgets.filter(w => this.widgetOrder.find(wo => wo.name === w.id))
      const order = this.widgetOrder.map(wo => wo.name)
      const sortedWidgets = filteredWidgets.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })
      sortedWidgets.map(sw => {
        if (this.widgetOrder.find(wo => wo.name === sw.id).isOpen !== null) {
          sw.maximized = [!!this.widgetOrder.find(wo => wo.name === sw.id).isOpen]
        } else if (typeof sw.maximized === 'undefined') {
          sw.maximized = [true]
        }
      })


      if (sortedWidgets.length > 0) {
        this.sortWidgets(sortedWidgets)
      }
      this.$store.commit('app/setSortableWidgets', false)
    },
    async setWidgetOrder () {
      const currentChanges = this.largeWidgets.concat(this.smallWidgets)
      const newOrder = currentChanges.map(widget => widget.id)
      const newWidgetOrder = this.widgetOrder.sort((a, b) => {
        return newOrder.indexOf(a.name) - newOrder.indexOf(b.name)
      })
      newWidgetOrder.map(wo => {
        if (currentChanges.find(cc => cc.id === wo.name)){
          wo.isOpen = currentChanges.find(cc => cc.id === wo.name).maximized[0]
        }
      })
      
      await this.$rambollfmapi.accounts.widgets.put(newWidgetOrder)
      this.$store.dispatch('app/getUserInfo')
      this.$store.commit('app/setSortableWidgets', false)
    },
    buildSwarmedExpensesHeaders (items, startHeaders, endHeaders) {
      let expenseAppended = []
      let headers = [...startHeaders]
      items.map(item => {
        if(!expenseAppended.includes(item.expenseId))
        {
          headers.push({ text: item.expenseName, value: String(item.expenseId)+ '_expense', format: 'Euro' })
          expenseAppended.push(item.expenseId)
        }
      })
      headers.push(...endHeaders)
      return headers
    },
    buildSwarmedExpenses (items) {
      let rows = []
      items.map(item => {
        let portfolioRowExists = rows.find(r => r.portfolioId === item.portfolioId)
        if(!portfolioRowExists)
        {
          rows.push({ portfolioId: item.portfolioId, portfolioName: item.portfolioName, [String(item.expenseId)+'_expense']: item.expenseSum })
        } else {
          portfolioRowExists[String(item.expenseId)+'_expense'] = item.expenseSum
        }
      })
      const dataRows = rows.map(row => {
        let rowExpenseSum = 0
        Object.keys(row).map(key => {
          if(key.includes('_expense')) {
            rowExpenseSum += row[key]
          }
        })
        row['portfoliosExpenseSum'] = rowExpenseSum
        return row
      })
      return dataRows
    },
    buildSwarmedExpensesFooters (headers, beginFooters, endFooters) {
      let footers = [...beginFooters]
      headers.map(h => {
        if(h.value.includes('_expense')) {
          footers.push({ value: h.value, format: 'Euro' })
        }
      })
      footers.push(...endFooters)
      return footers
    }
  }
}
</script>

<style scoped>
</style>
