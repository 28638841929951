import Vue from 'vue'
import Vuex from 'vuex'

import appStore from './app.js'
import sitesStore from './sites.js'
import ltpStore from './ltp.js'
import dashboardStore from './dashboard.js'
import internalRentStore from './internalRent.js'
import leasingStore from './leasing.js'
import personnelAllocationStore from './personnelAllocation.js'
import errorStore from './error.js'
import rentingGoalStore from './rentingGoal.js'
import openBills from './openBills.js'
import haltia from './haltia.js'
import billingStore from './billing.js'
import carpark from './carpark.js'
import rentalProcess from './rentalProcess.js'
import calculatorStore from './calculator.js'
import contracts from './contracts.js'
import costCenters from './costCenters.js'
import esg from './esg.js'
import rentContracts from './rentContracts.js'
import device from './device.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sites: sitesStore,
    app: appStore,
    dashboard: dashboardStore,
    internalRent: internalRentStore,
    ltp: ltpStore,
    leasing: leasingStore,
    error: errorStore,
    personnelAllocation: personnelAllocationStore,
    rentingGoal: rentingGoalStore,
    openBills,
    haltia,
    billing: billingStore,
    carpark,
    rentalProcess,
    calculator: calculatorStore,
    contracts,
    costCenters,
    esg,
    rentContracts,
    device,
  },
  actions: {
    setApiEndpoint ({ state }, endpoint) {
      state.app.apiEndpoint = endpoint
      state.sites.apiEndpoint = endpoint
      state.ltp.apiEndpoint = endpoint
      state.dashboard.apiEndpoint = endpoint
      state.internalRent.apiEndpoint = endpoint
      state.leasing.apiEndpoint = endpoint
      state.personnelAllocation.apiEndpoint = endpoint
      state.rentingGoal.apiEndpoint = endpoint
      state.billing.apiEndpoint = endpoint
      state.calculator.apiEndpoint = endpoint
      state.esg.apiEndpoint = endpoint
      state.rentContracts.apiEndpoint = endpoint
      state.device.apiEndpoint = endpoint
    },
    setApiToken ({ state }, token) {
      state.app.apiToken = token
      state.sites.apiToken = token
      state.ltp.apiToken = token
      state.dashboard.apiToken = token
      state.internalRent.apiToken = token
      state.leasing.apiToken = token
      state.personnelAllocation.apiToken = token
      state.rentingGoal.apiToken = token
      state.billing.apiToken = token
      state.calculator.apiToken = token
      state.esg.apiToken = token
      state.rentContracts.apiToken = token
      state.device.apiToken = token
    }
  }
})
