<template>
  <div style="position: relative">
    <v-menu 
      v-if="data.sets ? data.sets.length > 1 : false "
      left 
    >
      <template #activator="{ on }">
        <v-btn
          outlined
          small
          absolute
          right
          icon
          style="background: rgba(255, 255, 255, 0.8) !important"
          v-on="on"
        >
          <v-icon>menu</v-icon>
          <span class="d-sr-only">{{ $t('Menu') }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="set in sets"
          :key="set.title"
          @click="selectSet(set)"
        >
          <v-list-item-title>{{ set.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <VerticalBarChart 
      v-if="GraphData !== null"
      :data="GraphData"
      :options="barOptions"
      class="graph"
      style="height: 340px"
    />
  </div>
</template>
<script>
import helpers from '../../helpers'
import VerticalBarChart from '../VerticalBarChart.vue'
import { mapState } from 'vuex'
export default {
    components: {
    VerticalBarChart
  },
  props: ['data'],
  data () {
    const vm = this
      return {
          currentSet: null,
          barOptions: {
              aspectRatio: 3,
              maintainAspectRatio: false,
              responsive: true,
              legend: {
                  display: true,
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    fontColor: '#8b8b8b',
                    fontSize: 12,
                    boxWidth: 20
                  }
              },
              tooltips: {
                callbacks: {
                  title: function (tooltipItem, data) {
                    return ''
                  },
                  label: function (tooltipItem, data) {
                    if (vm.currentSet.title !== '€/m²') {
                      return vm.$t('Budget') + ' ' + vm.inspectionYear.toString() + ': ' + 
                      vm.data.items.filter(item => vm.objects[item.name] > 0)[tooltipItem.datasetIndex].name + ' ' + 
                      helpers.humanize.thousand_separator(tooltipItem.value) + ' €'
                    } else {
                      return vm.$t('Budget') + ' ' + vm.inspectionYear.toString() + ': ' + 
                      vm.data.items.filter(item => vm.objects[item.name] > 0)[tooltipItem.datasetIndex].name + ' ' + tooltipItem.value + ' €/m\u00B2'
                    }
                    
                  }
                }
              },
              scales: {
                  xAxes: {
                    ticks: {
                      display: false
                    }
                  },
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 5,
                        maxTicksLimit: 10,
                        fontColor: '#8b8b8b',
                        callback: function (value, index, values) {
                          return helpers.humanize.thousand_separator(value) + ' €'
                        }
                      }
                    }
                    
                  ]
              }
          }
      }
  },
  computed: {
    ...mapState('internalRent', ['inspectionYear']),
    sets: function () {
      if (this.data.sets.length === 0) {
          return []
      }
      
      return this.data.sets.filter(set => {
        const param = set.parameters.length > 0 ? set.parameters[0] : null

        // count is an exception as it does not require parameters
        if (param === null && set.method === 'count') {
          return true
        }

        const data = this.data.items.length > 0 ? this.data.items[0] : null

        if (data === null || typeof data[param] === 'undefined') {
          return false
        }
        return true
      })
    },
    objects: function () {
      let result = {}
      if (Array.isArray(this.data.items)) {
        
        if (this.currentSet !== null) {
          const groupedData = this.data.items.reduce((acc, cur) => {
            // group
            const key = cur[this.currentSet.groupBy]
            if (typeof key === 'undefined' || key === null) {
              return acc
            }

            if (typeof acc[key] === 'undefined') {
              acc[key] = []
            }
            acc[key].push(cur)

            return acc
          }, {})
          

          const items = Object.keys(groupedData).reduce((acc, key) => {
            const set = groupedData[key]
            const paramVal = set.map(s => {
              const values = []
              this.currentSet.parameters.forEach(param => {
                values.push(s[param])
              })
              return values
            })
            const method = this.currentSet.method
            if (method === 'count') {
              acc[key] = paramVal.length
            } else if (method === 'sum') {
              acc[key] = paramVal.reduce((acc, cur) => acc + cur[0], 0)
            }
            return acc
          }, {})

          result = items
        } 
      }

      return result
    },
    GraphData: function () {
      //const objectKeysArray = [...Object.keys(this.objects)]
      return {
        labels: ['Cost types'],
        datasets: this.getCostTypeSets()
      }
    }
  },
  mounted: function () {
    if (typeof this.data.sets !== 'undefined') {
      this.selectSet(this.data.sets[0])
    }
  },
  methods: {
    selectSet (set) {
      this.currentSet = set
    },
    getCostTypeSets () {
      const sets = []
      this.data.items.forEach((item, idx) => {
        if (Object.values(this.objects)[idx] > 0) {
          const set = {
            label: item.name,
            backgroundColor: this.getThemeColor(idx),
            hoverBackgroundColor: this.getThemeColor(idx),
            borderColor: 'white',
            data: [ 
              Object.values(this.objects)[idx]
            ]
          }
          sets.push(set)
        }
      })
      return sets
    },
    getThemeColorArray (amount) {
      const colorArray = []
      for (let i = 0; i < amount; ++i) {
        colorArray.push(this.getThemeColor(i))
      }
      return colorArray
    },
    getThemeColor (index) {
      return `hsl(${177 + 27 * index}, 82%, 72%)`
    }
  }
}
</script>