<template>
  <v-autocomplete
    :value="value"
    chips
    clearable
    deletable-chips
    multiple
    hide-details
    return-object
    :items="items"
    :label="label"
    :class="overflow"
    :item-text="itemText"
    @input="onChange($event)"
  >
    <template #prepend-item>
      <v-list-item
        ripple
        @mousedown.prevent
        @click="selectAll()"
      >
        <v-list-item-action>
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{
              allSelected
                ? $t("site_filters.deselect")
                : $t("site_filters.select")
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-1" />
    </template>
  </v-autocomplete>
</template>
<script>

export default {
  emits: ['input'],
  components: {},
  props: {
    value: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    itemText: {type: String, default: ""},
  },
  computed: {
    icon () {
      if (this.allSelected) {
        return "check_box"
      }

      if (this.someSelected) {
        return "indeterminate_check_box"
      
      }
      return "check_box_outline_blank"
    },
    allSelected () {
      return this.items.length === this.value.length
    },
    someSelected () {
      return this.value.length > 0 && !this.allSelected
    },
    overflow () {
      return this.value.length > 10
        ? "overflow-auto"
        : ""
    },
  },
  methods: {
    selectAll () {
      if(this.allSelected){
        this.$emit("input", [])  
      } else {
        this.$emit("input", this.items.slice())
      }
    },
    onChange (params) {
      this.$emit("input", params.slice())
    }
  },
}
</script>

<style scoped>
.v-autocomplete {
  height: auto !important;
  max-height: 250px;
}
</style>
