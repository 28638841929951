!<template>
  <v-card>
    <BaseModal
      @cancel="close"
    >
      <template #title>
        {{ $t(title) }}
      </template>
      <template #content>
        <v-card 
          class="px-1 py-2"
          elevation="0"
        >
          <span v-if="showInfo">
            <p class="text-center font-weight-bold text-subtitle-1">
              {{ $t("prospect.passivate_confirmation_question") }}
              <br>
              {{ $t("prospect.passivate_confirmation_clarification") }}
            </p>
          </span>
          <v-select
            v-model="inactivationReasonChoice"
            :items="inactivationReasons"
            :label="$t('Reason')"
            item-text="label"
            return-object
          >
            <template #selection="{ item, index }">
              {{ $t(`${item.label}`) }}
            </template>
            <template #item="{item, index}">
              {{ $t(`${item.label}`) }}
            </template>
          </v-select>
        </v-card>
      </template>
      <template #footer>
        <v-btn
          :disabled="inactivationReasonChoice == null"
          rounded
          depressed
          color="error"
          @click="passivate"
        >
          {{ $t("prospect.passivate") }}
        </v-btn>
      </template>
    </BaseModal>
  </v-card>
</template>

<script>
import BaseModal from '../general/BaseModal.vue'

export default {
  emits: ['close', 'passivate'],
    name: 'InactivationModal',
    components: {
      BaseModal,
    },
    props: {
      inactivationReasons: {
        type: Array,
        default: () => []
      },
      showInfo: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        inactivationReasonChoice: null
      }
    },
    methods: {
      close () {
        this.$emit('close')
        this.inactivationReasonChoice = null
      },
      passivate () {
        this.$emit('passivate', this.inactivationReasonChoice.id)
        this.inactivationReasonChoice = null
      }
    }
}
</script>
<style scoped>
</style>