<template>
  <v-row
    class="dashboard"
  >
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <multi-select
        v-model="processTypeFilter"
        :label="$t('leasing.select_process_type')"
        :items="processTypeFilterItems.map(item => item.label)"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="8"
    /> 
    <v-col
      v-for="widget in widgets.filter(w => hasWidgetPermissionByName(w.id))"
      :key="widget.id"
      :lg="widget.small ? 3 : !widget.small && !widget.large ? 6 : 12"
      cols="12"
    >
      <DynamicWidget
        :type="widget.type"
        :title="$t(widget.id)"
        :data="widget.data"
        :plugins="widget.plugins"
        :loading="widgetLoading(widget.id)"
        @triggerCustomEvents="onCustomEvent"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DynamicWidget from '../../DynamicWidget.vue'
import { rentingWidget, toRentingWidgetData } from '../../../widgets/leasing/rentingWidget.js'
import { rentingProcessWorkQueue, toRentingProcessWorkQueueData, getRentingProcessWorkQueueHeaders } from '../../../widgets/leasing/rentingProcessWorkQueue.js'
import MultiSelect from '../../MultiSelect.vue'
import { getProspectType } from '../../../helpers/leasing/prospect.js'

export default {
  name: 'RentingProcess',
  components: { 
    DynamicWidget,
    MultiSelect
  },
  props: {
    filterResponsibility: {
      type: Boolean,
      default: false
    },
    selectedResponsibles: {
      type: Array,
      default: () => []
    },
    userSites: { type: Array, default: () => []},
    showOnlyUserSites: { type: Boolean, default: false}
  },
  data () {
    return {
      widgets: [],
      processTypeFilter: [],
      processTypeFilterItems: [
        { 
          label: this.$t('leasing.new_negotiation'), 
          value: "leasing.new_negotiation"
        },
        {
          label: this.$t('leasing.renegotiations_fixed'),
          value: "leasing.renegotiations"
        },
        {
          label: this.$t('leasing.renewals_permanent'),
          value: "leasing.renewal"
        }
      ]
    }
  },
  computed: {
    ...mapState('leasing', ['leasingUsers', 'municipalities']),
    ...mapState('rentalProcess', ['rentalProcesses', 'rentalProcessLoading']),
    ...mapState('app', ['sites', 'sitesLoading', 'userInfo']),
    ...mapGetters('app', ['hasApplicationPermissionByName', 'definitionById', 'definitionsByGroupLabel', 'hasWidgetPermissionByName', 'siteFiltersByTypes']),
    
    sitesFilter () {
      if(this.showOnlyUserSites){
        return this.userSites.map(site => site.id_site)
      }

      if(this.sites && this.sites.length > 0){
        return this.sites.map(site => site.id_site)
      }

      return null
    }
  },
  watch: {
    rentalProcesses (){
      this.loadWidgetData('leasing.renting')
    },
    processTypeFilter () {
      this.loadWidgetData()
    },
    sites () {
      this.loadWidgetData();
    },
    filterResponsibility () {
      this.loadWidgetData()
    },
    selectedResponsibles () {
      this.loadWidgetData()
    },
    userSites: function () {
      this.loadWidgetData()
    }
  },
  mounted () {
    this.setWidgets()
    this.loadWidgetData()
  },
  methods: {
    ...mapActions('rentalProcess', ['openRentalProcess']),
    setWidgets (){

      const widgets = [
        rentingProcessWorkQueue()
      ]

      if(this.hasApplicationPermissionByName('LEASING')){
        widgets.push(rentingWidget())
      }

      this.widgets = widgets
    },
    loadWidgetData () {
      const { widgets } = this
      widgets.forEach(widget => {
        // First load any extra data widget might need (dynamic sets/headers, extra data)
        if (widget.data.dynamicHeaders) {
          widget.data.headers = this.dataHeadersForWidget(widget.id)
        }
        if (widget.data.dynamicSets) {
          widget.data.sets = this.dataSetsForWidget(widget.id)
        }
        if (widget.data.lineItems) {
          widget.data.lineItems = this.extraDataForWidget(widget.id)
        }
        if (widget.data.customLegend) {
          widget.data.legends = this.legendForWidget(widget.id)
        }

        widget.data.items = this.dataForWidget(widget.id)
        // Append extra filter options by source data
        if(widget.data.dynamicFilterOptions && widget.data.items.length) {
          widget.data.filter.options = this.filterOptionsForWidget(widget.id, widget.data)
        }
      })
    },
    legendForWidget (id) {
      try {
        switch (id) {
          default:
            return null
        }
      } catch (err) {
        this.$log.error(`Error trying to process filters for widget: "${id}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return null
      }
    },
    widgetLoading (id) {
      switch(id) {
        case 'leasing.renting':
          return this.sitesLoading || this.rentalProcessLoading
        case 'leasing.renting_process_work_queue':
          return this.sitesLoading || this.rentalProcessLoading
        default:
          return true
      }
    },
    dataForWidget (id) {
      try {
        if (this.widgetLoading(id)) {
          return []
        }

        const rentalProcesses = this.getRentalProcesses()

        switch(id) {
          case 'leasing.renting':
            // Moving the data mapping function into the same file as our widget definition is. This way we keep the 
            // presentational and functional logic in the same place and seperate from other logic. Improves clarity. 
            // We could also append the mapping function to the widget definition into the object. For example:
            // {
            //   id: "widget_id",
            //   ...
            //   getWidgetData(context){
            //     return context.rentingProcess.map(...)
            //   }
            // }
            //
            // After that you could just call: widget.getWidgetData(this)
            return toRentingWidgetData(rentalProcesses, this.leasingUsers, this.getDefinitionLabelById, this.municipalities)
          case 'leasing.renting_process_work_queue': 
            return toRentingProcessWorkQueueData(rentalProcesses, this.sites)
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error(`Error trying to process data for widget: "${  id  }"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    getRentalProcesses (){
      // [$t('leasing.new_negotiation'), $t('leasing.renegotiations_fixed'), $t('leasing.renewals_permanent')]

      let rentalProcesses = this.rentalProcesses.filter(processes => {
        for (let i = 0; i < processes.sites.length; i++) {
          if (this.userSites.find(sitesItem => sitesItem.id_site == processes.sites[i].id_site) == null) {
            return false
          }
        }
        return true
      })
      
      // If any site filters are active, filter out rental processes with no sites set
      if (this.siteFiltersByTypes('all').length > 0) {
        rentalProcesses = rentalProcesses.filter(processes => processes.sites.length !== 0)
      }
      
      if(this.processTypeFilter.length > 0) {
        const filterValues = this.processTypeFilter.map(filter => {
          const filterValue = this.processTypeFilterItems.find(item => item.label === filter)
          return filterValue?.value
        })

        rentalProcesses = rentalProcesses.filter(process => {

          const processType = getProspectType(process)

          return filterValues.includes(processType?.typeText)

        })
      }

      // Filtering by rental process responsibles
      let responsibles = []

      // Users rentals
      if (this.filterResponsibility) {
        responsibles.push(this.userInfo.id)
      }

      // Other responsibles' rentals
      if (this.selectedResponsibles?.length > 0) {
        const selectedResponsibleIds = this.selectedResponsibles.map(user => user.id_user)
        responsibles = responsibles.concat(selectedResponsibleIds)
      }

      // If either filter is active, filter rentals
      if (this.filterResponsibility || this.selectedResponsibles?.length > 0) {
        rentalProcesses = rentalProcesses.filter(process => 
        responsibles.includes(process.owner) || responsibles.includes(process.secondaryOwner))
      }


      return rentalProcesses
    },
    dataHeadersForWidget (id){
      try {
        if (this.widgetLoading(id)) {
          return []
        }
        switch(id) {
          case 'leasing.renting_process_work_queue':{
            const stages = this.definitionsByGroupLabel('leasing.rental_process_stage')
            return getRentingProcessWorkQueueHeaders(stages)
          } 
            
          default:
            return []
        }
      }
      catch (err) {
        this.$log.error(`Error trying to process data for widget: "${  id  }"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    filterOptionsForWidget (widgetId, widgetData) {
      try {
        const sites = this.sites
        switch (widgetId) {
          case 'leasing.renting': {
            widgetData.filter.header = "stage"
            const itemsFromFilteredColumn = widgetData.items.reduce((uniqueValues, currentItem) => {
              if(currentItem[widgetData.filter.header] && !uniqueValues.includes(currentItem[widgetData.filter.header]))
              {
                uniqueValues.push(currentItem[widgetData.filter.header])
              }
              return uniqueValues
            }, [])
            const filterOptions = [{ text: 'prospect.show_all', value: null }].concat(itemsFromFilteredColumn.sort().map(i => {
              return { text: i, value: i }
            }))
            return filterOptions
          }
          case 'leasing.renting_process_work_queue': {
            const dynamicValues = sites
              .reduce((acc, site) => {
              
                const { rent_priority } = site

                if(!acc[rent_priority]){
                  acc[rent_priority] = rent_priority
                }
                return acc
              }, {})

              const dynamicOptions = Object.values(dynamicValues)
                .sort()
                .map(priority => ({ text: priority, value: priority}))

            return [{ text: 'prospect.show_all', value: null }, { text: "No assigned site", value: "No assigned site"}, ...dynamicOptions]
          }
          default:
            return []
        }
      } catch (err) {
        this.$log.error(`Error trying to process custom filter options for widget: "${widgetId}"`, err)
        this.$store.dispatch('error/addError', 'err.report_data_handling_failed')
        return []
      }
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? def.label : this.$t('Undefined')
    },
    onCustomEvent (event){

      const { eventName, row } = event

      if(eventName === "onClickRentingProcessWorkQueue"){
        this.openRentalProcess(row)
      }
      
    }
  }
}
</script>

<style scoped>
</style>