<template>
  <div id="reports">
    <v-dialog
      v-model="showCreateRentContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          v-if="showCreateRentContractModal"
          :contract-id="editedContractId"
          :out-rent="tabLocation == 'contractlayers.renting_out'"
          :contract-numbers="contractNumbers"
          :contract-template="contractTemplate"
          @contractIdUpdated="onContractIdUpdated"
          @handleSave="onCreateRentContractModalClosed"
          @changedDraft="
            showIndicator = true
            operationResult = 'changedDraft'
          "
          @savedDraft="
            showIndicator = true
            operationResult = 'savedDraft'
          "
        />
      </v-card>
    </v-dialog>
    <SkipTo
      :label="$t('skip-to-contracts-content')"
      to="#contracts-content"
    />
    <nav :aria-label="$t('SubMenu')">
      <v-tabs
        class="pl-5"
        show-arrows
      >
        <v-tab
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')"
          :to="{ name: 'contractlayers.renting_out' }"
          exact
          @click="updateTabLocation(); outRentChanged();"
        >
          {{ $t('Renting out') }}
        </v-tab>
        <v-tab
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')"
          :to="{ name: 'contractlayers.renting_in' }"
          exact
          @click="updateTabLocation(); outRentChanged();"
        >
          {{ $t('Renting in') }}
        </v-tab>
        <v-tab
          v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')"
          :to="{ name: 'contractlayers.dashboard' }"
          exact
          @click="updateTabLocation();"
        >
          {{ $t('Dashboard') }}
        </v-tab>
      </v-tabs>
      <v-divider />
    </nav>
    <BaseView v-if="tabLocation != 'contractlayers.dashboard'">
      <template #selections>
        <h1 class="d-sr-only">
          {{ $t('Contracts') }}
        </h1>
        <v-select
          v-model="contractLevel"
          :label="$t('Contract level')"
          :items="contractLevels"
          item-text="text"
          item-value="value"
          class="mb-2"
          style="width: 10%"
        />
        <v-combobox
          v-if="contractLevel === 'contract' || contractLevel === 'contract_rows'"
          v-model="selectedContractStates"
          :items="translatedContractStates"
          :label="$t('Contract state')"
          item-text="name"
          item-value="id"
          multiple
          clearable
          class="ml-2 mb-2 combobox"
          style="width: 10%"
        >
          <template #selection="{ item, index }">
            <span class="mr-1 stateText">
              {{ item.name + (index === selectedContractStates.length - 1 ? "" : ",") }}
            </span>
          </template>
        </v-combobox>
      </template>
      <template #buttons>
        <v-btn
          :disabled="loading"
          small
          outlined
          rounded
          @click="showDefaultSearchDialog"
        >
          {{ $t('Save default search') }}
        </v-btn>
        <v-btn
          :disabled="loading"
          small
          outlined
          rounded
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          small
          outlined
          rounded
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          small
          outlined
          rounded
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
        <ImportButton
          v-if="importing"
          :additional-date-format="true"
          :headers="getHeaders"
          :required-fields="getRequiredFields"
          :disabled="loading"
          @dataUpdate="saveFile"
        />
        <v-menu
          v-if="isUpdatingAllowed && templates.length > 1"
          :disabled="loading"
          offset-y
          small
          outlined
          class="pa-0 ma-0"
        >
          <template #activator="{ on, attrs }">
            <div class="ma-0 pa-0">
              <v-list
                small
                class="ma-0 pa-0"
                rounded
              >
                <v-list-item
                  small
                  rounded
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-title class="pa-0 ma-0">
                    <span class="v-btn v-btn--outlined v-btn--rounded v-btn__content v-size--small">
                      {{ $t('create_rent_contract.create_new_contract') }} {{ "(" + (tabLocation == 'contractlayers.renting_out' ? $t('Renting out') : $t('Renting in')) + ")" }}
                      <v-icon>keyboard_arrow_down</v-icon>
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(template, index) in templates"
              :key="index"
              class="v-btn v-btn__content v-size--small pa-0 ma-0"
              @click="createContractWithTemplate(template)"
            >
              <v-list-item-title class="v-btn v-btn__content v-size--small ma-0">
                {{ $t(template.code) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="isUpdatingAllowed"
          id="create_contract"
          :disabled="loading"
          small
          outlined
          rounded
          @click="createContractWithTemplate(templates[0])"
        >
          {{ $t('create_rent_contract.create_new_contract') }} {{ "(" + (tabLocation == 'contractlayers.renting_out' ? $t('Renting out') : $t('Renting in')) + ")" }}
        </v-btn>
        <v-dialog
          v-if="dataUpdateDialog && contractLevel === 'fairy'"
          v-model="dataUpdateDialog"
          persistent
          max-width="600"
        >
          <BaseModal
            @cancel="resetChanges"
          >
            <template #title>
              {{ $t('Import from spreadsheet') }}
            </template>
            <template #content>
              <v-col
                v-if="loading"
                style="text-align: center; padding: 100px"
              >
                <v-progress-circular
                  size="64"
                  indeterminate
                  color="primary"
                />
              </v-col>
              <v-col
                v-else-if="updatingItems || savingNewContracts"
                style="text-align: center; padding: 100px"
              >
                <div
                  class="text-h6"
                  style="margin-bottom: 2em"
                >
                  {{ `${savingNewContracts ? $t('dataimport.saving_rows') : $t('dataimport.handling_rows') }` }}
                  <p> {{ `${itemsLength}` }} </p>
                </div>
              </v-col>
              <div v-else-if="importSuccess">
                <div v-if="savingFailed">
                  <h3> {{ $t('dataimport.saving_failed') }} </h3>
                  <br>
                  <h4>{{ $t('dataimport.successful_rows') + " " + savedContracts }}</h4>
                  <h4>{{ $t('dataimport.successful_changes') + " " + updatedContracts }}</h4>
                  <h4>{{ $t('dataimport.failed_rows') + " " + savingErrorsCount }}</h4>
                  <h4>{{ $t('dataimport.failed_updates') + " " + failedUpdates }}</h4>
                  <div v-if="overlapErrors.length > 0">
                    <br>
                    <span>{{
                      $t('dataimport.overlapping_contracts')
                    }}</span>
                    <ul>
                      <li
                        v-for="(error, idx) in overlapErrors"
                        :key="idx"
                      >
                        {{ viewErrorText(error) }}
                      </li>
                    </ul>
                  </div>
                  <div v-if="savingErrors.length > 0">
                    <br>
                    <span>{{
                      $t('dataimport.saving_errors')
                    }}</span>
                    <ul>
                      <li
                        v-for="(error, idx) in savingErrors"
                        :key="idx"
                      >
                        {{ viewErrorText(error) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-else-if="hasChanges">
                  <h3>{{ $t('dataimport.import_success') }}</h3>
                  <br>
                  <h4>{{ $t('dataimport.rows_imported') + " " + totalItems }}</h4>
                  <br>
                  <div
                    v-for="change in changesList"
                    :key="change.text"
                  >
                    <h4>{{ change.text + " " + change.changes.length }}</h4>
                  </div>
                  <h4>{{ $t('dataimport.change_errors') + " " + errorRows }}</h4>
                  <h4>{{ $t('dataimport.items_not_changed') + " " + itemsNotChanged }}</h4>
                  <br>
                </div>
                <span v-else>{{ $t('dataimport.no_changes') }}</span>
                <div v-if="errors.length && !savingFailed">
                  <h4>{{ $t('dataimport.changes_ignored') }}</h4>
                  <ul>
                    <li
                      v-for="(error, idx) in errors"
                      :key="idx"
                    >
                      {{ viewErrorText(error) }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <h3>{{ $t('dataimport.import_failed') }}</h3>
                <h4
                  v-if="
                    importErrors.uneditable && importErrors.uneditable.length > 0
                  "
                >
                  {{
                    $t('dataimport.not_editable') +
                      ': ' +
                      importErrors.uneditable.join(', ')
                  }}
                </h4>
                <h4
                  v-if="
                    importErrors.unexisting && importErrors.unexisting.length > 0
                  "
                >
                  {{
                    $t('dataimport.not_existing') +
                      ': ' +
                      importErrors.unexisting.join(', ')
                  }}
                </h4>
              </div>
            </template>
            <template #hideCancel>
              <p />
            </template>
            <template #footer>
              <v-btn
                v-if="!savingFailed"
                rounded
                outlined
                text
                @click="resetChanges"
              >
                {{ updatingItems || isSavingChanges ? $t('Abort') : $t('Cancel') }}
              </v-btn>
              <v-btn
                v-if="importSuccess && hasChanges"
                :disabled="isSavingChanges"
                rounded
                depressed
                color="primary"
                @click="saveChanges()"
              >
                <span v-if="!isSavingChanges">
                  {{ $t('Save changes') }}
                </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-btn>
              <v-btn
                v-else
                :disabled="updatingItems || isSavingChanges || loading"
                rounded
                depressed
                class="primary"
                @click="resetChanges"
              >
                <span v-if="!updatingItems && !isSavingChanges && !loading">
                  {{ $t('OK') }}
                </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-btn>
            </template>
          </BaseModal>
        </v-dialog>
        <DataUpdateDialog
          v-if="dataUpdateDialog && contractLevel === 'market_rent'"
          :data-update-dialog="dataUpdateDialog"
          :import-success="importSuccess"
          :rental-status-changes="rentalStatusChanges"
          :saving-failed="savingFailed"
          :errors="importErrors"
          :is-saving-changes="isSavingChanges"
          :car-parks="false"
          @saveChanges="saveMarketRentChanges"
          @cancel="dataUpdateDialog = false"
          @ok="handleOk"
        />
      </template>
      <template #main>
        <div
          v-if="tabLocation != 'contractlayers.dashboard'"
          class="viewSelection"
        >
          <v-select
            v-model="selectedView"
            :label="$t('Stored views')"
            :items="availableStoredViews"
            item-text="name"
            item-value="id"
            clearable
            :disabled="availableStoredViews.length === 0"
            class="mb-8"
          >
            <template
              #selection="data"
            >
              {{ data.item.name }}
            </template>
            <template
              #item="data"
            >
              {{ getViewShortName(data.item.name) }}
              <v-spacer />
              <v-icon
                v-if="data.item.private || hasApplicationPermissionByName('VAKIOHAKU_LUONTI')"
                :style="{ 'margin-right': '0.4em' }"
                @click="deleteSelectedView(data.item.id)"
              >
                delete
              </v-icon>
            </template>
          </v-select>
        </div>
        <Table
          v-show="!loading && tabLocation != 'contractlayers.dashboard'"
          id="contracts-content"
          :rows="viewData"
          :headers="viewHeaders"
          :footers="createFooters"
          :footer-definitions="footerDefinitions"
          :hide="true"
          :show-rows-per-page-selection="showPagination"
          :show-filter="showColumnsFilter"
          :show-default-search="showDefaultSearch"
          :document-type="exportAsDocument"
          :pagination="getPagination"
          :emit-pagination="contractLevel === 'contract_payments' || contractLevel === 'contract' || contractLevel === 'contract_rows'"
          :row-count="getTableRowCount"
          :rows-loading="rowsLoading"
          :show-edit-control="isUpdatingAllowed && contractLevel === 'contract'"
          :out-rent="tabLocation == 'contractlayers.renting_out'"
          :selected-contract-states="selectedContractStates.map(state => state.id)"
          :get-export-data="contractLevel === 'contract' ? getContractExport : contractLevel === 'contract_payments' ? getContractPaymentsExport : getContractRowsExportData"
          :show-save-selection="true"
          :selected-view-string="storedView ? storedView.name : null"
          :stored-views="storedViews.filter(sv => sv.level === contractLevel)"
          :show-confirm="confirmation"
          :multi-select-keys="multiSelectKeys"
          @showFilterDialog="showColumnsFilterDialog"
          @showDefaultSearchDialog="showDefaultSearchDialog"
          @exported="exporting = {}"
          @updatePagination="updatePagination"
          @filterSearch="filterSearch"
          @edit-row="onEditRow"
          @remove-row="onRemoveRow"
          @triggerCustomEvents="triggerCustomEvents"
          @select_view="selectView"
          @save-selection="saveStoredView"
          @delete-view="deleteSelectedView"
        />
      </template>
    </BaseView>
    <ContractlayersDashboard
      v-if="tabLocation == 'contractlayers.dashboard'"
      :contract-states="contractStates"
    />
    <v-col
      v-if="loading"
      style="text-align: center; padding: 100px"
    >
      <div
        class="text-h5"
        style="margin-bottom: 2em"
      >
        {{ $t('Gathering information. Wait a second...') }}
      </div>
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </v-col>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
  </div>
</template>

<script>
import Alert from '../components/Alert.vue'
import helpers from '../helpers'
import ImportButton from '../components/ImportButton.vue'
import Table from '../components/Table.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseModal from '../components/general/BaseModal.vue'
import BaseView from '../components/general/BaseView.vue'
import SkipTo from '../components/general/SkipTo.vue'
import CreateRentContractModal from '../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'
import DataUpdateDialog from '../components/DataUpdateDialog.vue'
import ContractlayersDashboard from '../components/ContractlayersDashboard.vue'
import moment from 'moment'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Contraclayers',
  components: {
    Alert,
    BaseModal,
    BaseView,
    SkipTo,
    ImportButton,
    Table,
    CreateRentContractModal,
    DataUpdateDialog,
    ContractlayersDashboard
    },
  props: {
    siteId: { type: Number, default: undefined }
  },
  metaInfo () {
    return {
      title: `${this.$t('Contracts')} ·`,
    }
  },
  data () {
    return {
      templates: [],
      category: 'None',
      showColumnsFilter: false,
      exportAsDocument: [],
      tablePagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['possessor'],
      },
      contractPagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['contract_number'],
        page: 1,
        search: {}
      },
      contractPaymentsPagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['contract_number'],
        page: 1,
        search: []
      },
      contractRowsPagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['contract_number'],
        page: 1,
        search: []
      },
      rentalStatusChanges: [],
      savingFailed: false,
      isSavingChanges: false,
      dataUpdateDialog: false,
      importSuccess: false,
      importErrors: { unexisting: [], uneditable: [] },
      errors: [],
      newContracts: [],
      loading: true,
      requiredFields: ['contractNumber', 'curPartyName', 'curPartyStartDate', 'business_id'],
      overlapErrors: [],
      savingErrors: [],
      contractLevel: '',
      contractLevels: [],
      updatingItems: false,
      savingNewContracts: false,
      currentItem: 0,
      itemsLength: 0,
      itemsNotChanged: 0,
      totalItems: 0,
      errorRows: 0,
      savedContracts: 0,
      updatedContracts: 0,
      failedUpdates: 0,
      savingErrorsCount: 0,
      futureRentalStatuses: [],
      contracts: [],
      contractPayments: [],
      contractRows: [],
      contractsCount: 0,
      contractPaymentsCount: 0,
      contractRowsCount: 0,
      contractPaymentsDataLoading: false,
      contractRowsDataLoading: false,
      contractDataLoading: false,
      abortController: undefined,
      searchValues: {},
      showCreateRentContractModal: false,
      editedContractId: undefined,
      selectedContractStates: [],
      translatedContractStates: [],
      contractStates: [],
      rentPaymentDomains: [],
      exporting: {},
      storedView: null,
      confirmation: false,
      selectedView: null,
      showDefaultSearch: false,
      contractTemplate: undefined,
      multiSelectKeys: { multiSelectOptionTypes: {}},
      tabLocation: null,

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',
    }
  },
  computed: {
    ...mapState('app', ['sites', 'contractMetadata', 'contractData', 'currentDate', 'buildings', 'siteIds', 'settings', 'storedViews']),
    ...mapState('leasing', ['rentalStatuses']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionsByGroupId', 'definitionById', 'definitionByLabel', 'hasApplicationPermissionByName']),
    ...mapGetters('rentContracts', ['getContractTemplatesByOutRent']),
    isUpdatingAllowed () {
      return this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_MUOKKAUS')
    },
    rowData () {
      switch (this.contractLevel) {
        case 'fairy':
          return this.getAllRentalUnits()
        case 'contract': {
          let contractData = [...this.contracts]
          return contractData.map(data => {
            return {
              ...data,
              contract_type: this.$t(data.contract_type_label),
              contract_type_label: this.$t(data.contract_type_label),
              contract_method: data.contract_method_label,
              //contract_status: this.getDefinitionLabelById(data.contract_status),
              //renting_type: this.getDefinitionLabelById(data.renting_type),
              is_fixed_term: data.is_fixed_term ? this.$t('Yes') : data.is_fixed_term === null ? this.$t('Undefined') : this.$t('No'),
              is_taxable: data.is_taxable ? this.$t('Yes') : data.is_taxable === null ? this.$t('Undefined') : this.$t('No'),
              rent_review_type: data.rent_review_type_label,
              is_non_billable: data.is_non_billable ? this.$t('Yes') : data.is_non_billable === null ? this.$t('Undefined') : this.$t('No'),
              customer_stays_in_permises: data.customer_stays_in_premises ? this.$t('Yes') : data.customer_stays_in_premises === null ? this.$t('Undefined') : this.$t('No'),
              tenant: data.tenant === 'PrivatePerson' ? this.$t('PrivatePerson') : data.tenant ?? this.$t('Undefined'),
              rental_target_tenant: data.rental_target_tenant ?? this.$t('Undefined'),
              renter: data.renter ?? this.$t('Undefined'),
              asset_type_id: data.asset_type_label,
              address: data.address.filter(item => item).join(", "),
              target_name: data.target_name.filter(item => item).join(", "),
              target_code: data.target_code.filter(item => item).join(", "),
              is_draft: data.is_draft ? this.$t('Yes') : this.$t('No'),
              contract_state: data.contract_state ? this.$t(data.contract_state) : this.$t('Undefined'),
              validity: data.is_fixed_term ? this.$t('Fixed term') : this.$t('Permanent'),
              is_vat: data.is_vat  === null ? this.$t(' ') : data.is_vat ? this.$t('Taxable') : this.$t('Exempt from taxation'),
              removeAble: data.is_draft && (data.invoice_count == 0) && this.isUpdatingAllowed,
              post_number: data.post_number.filter(item => item).join(", "),
              automatic_billing_reasoning: data.automatic_billing_reasoning,
              automatic_billing_allowed: data.automatic_billing_allowed ? this.$t('Yes') : this.$t('No'),
              hallintamuoto: this.$t(data.hallintamuoto),
            }
          })
        }
        case 'contract_payments': {
          let contractPaymentsData = [...this.contractPayments]
          return contractPaymentsData.map(data => {
            return {
              ...data,
              agreed_area: data.agreed_area  !== null ? data.agreed_area  : this.$t('Undefined'),
              start_date: data.start_date  !== null ? data.start_date : this.$t(' '),
              end_date: data.end_date  !== null ? data.end_date : this.$t(' '),
              payment_date: data.payment_date  !== null ? data.payment_date  : this.$t(' '),
              latest_review_date: data.latest_review_date  !== null ?  data.latest_review_date : this.$t('Undefined'),
              invoicing_basis: data.invoicing_basis  ? this.$t(data.invoicing_basis) : this.$t('Undefined'),
              payment_type: data.payment_type ? this.$t(data.payment_type) : this.$t('Undefined'),
              def_basis: data.def_basis  !== null ?  data.def_basis  : this.$t('Undefined'),
              payment_basis: data.payment_basis  ? this.$t(data.payment_basis) : this.$t('Undefined'),
              is_discount_payment: data.is_discount_payment === null ? this.$t('No') : data.is_discount_payment ? this.$t('Yes') : this.$t('No'),
              unitnames: data.unitnames  !== null ? data.unitnames : this.$t(' '),
              rent_review_type: data.rent_review_type ? this.$t(data.rent_review_type) : this.$t('Undefined'),
              tenant: data.tenant  !== null ?  data.tenant : this.$t('Undefined'),
              payment_domain: data.payment_domain !== null ? this.$t(data.payment_domain) : this.$t('Undefined'),
              is_square_based: data.is_square_based  === null ? this.$t('No') : data.is_square_based ? this.$t('Yes') : this.$t('No'),
              site: data.site  !== null ? data.site  : this.$t('Undefined'),
              latest_billing_date: data.latest_billing_date !== null ? data.latest_billing_date : this.$t(' '),
              target: data.target === 'Whole contract payment  ' ? this.$t('Whole contract payment') : data.target
            }
          })
        }
        case 'contract_rows': {
          let contractRowsData = [...this.contractRows]
          return contractRowsData.map(data => {
            return {
              ...data,
              agreed_area: data.agreed_area  !== null ? data.agreed_area  : this.$t('Undefined'),
              start_date: data.start_date  !== null ? data.start_date : this.$t(' '),
              end_date: data.end_date  !== null ? data.end_date : this.$t(' '),
              payment_date: data.payment_date  !== null ? data.payment_date  : this.$t(' '),
              latest_review_date: data.latest_review_date  !== null ?  data.latest_review_date : this.$t('Undefined'),
              tenant: data.tenant  !== null ?  data.tenant : this.$t('Undefined'),
              site: data.site  !== null ? data.site  : this.$t('Undefined'),
              contract_status: this.$t(data.contract_status),
              contract_type: this.$t(data.contract_type),
              unit_status: data.unit_status !== null ? this.$t(data.unit_status) : null
            }
          })
        }
        case 'usage_fee': {
          let usageFeeData = [...this.contractData.usageFee]
          return usageFeeData.map(data => {
            return {
            ...data,
            is_nonrecurring: data.is_nonrecurring ? this.$t('Yes') : data.is_nonrecurring === null ? this.$t('Undefined') : this.$t('No')
            }
          })
        }
        case 'market_rent': {
          const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
          const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)
          const marketRentData = [...this.rentalStatuses]
          const futureMarketRentData = [...this.futureRentalStatuses]
          return this.getMarketRentViewData(rentableDefs, marketRentData, futureMarketRentData)
        }
        default: {
          return []
        }
      }
    },
    contractNumbers () {
      return this.contracts.map((contract) => contract.contract_number);
    },
    rowsLoading () {
      return this.contractPaymentsDataLoading || this.contractDataLoading || this.contractRowsDataLoading
    },
    createFooters () {
      if (this.contractLevel === 'fairy') {
        const footerValues = this.footerDefinitions
        const footerSums = []
        const calculatedFooterValues = {}
        calculatedFooterValues['site_identifier'] = this.$t('Total')
        footerValues.forEach((item) => {
          let value = item.value
          let sum = this.rentalStatuses.map(r => r[value] && r.value !== 'undefined' ? isNaN(r[value]) ? 1 : Number(r[value]): 0)
            .reduce((acc, cur) => acc + cur, 0).toFixed(2)
          calculatedFooterValues[item.value] = sum
        })
        footerSums.push(calculatedFooterValues)
        return footerSums
      }
      return []
    },
    footerDefinitions () {
      if (this.contractLevel === 'fairy') {
        return [
          { text: this.$t('Total') },
          { value: 'area', unit: 'area', format: 'Area' },
          { value: 'contract_area', unit: 'area', format: 'Area' },
          { value: 'capitalRent', unit: 'Rent', format: 'Euro' },
          { value: 'maintenanceRent', unit: 'Rent', format: 'Euro' },
          { value: 'maintenanceRentEqualization', unit: 'Rent', format: 'Euro' },
          { value: 'changeoverRent', unit: 'number', format: 'Number' },
          { value: 'separateAllowances', unit: 'number', format: 'Number' },
          { value: 'discounts', unit: 'number', format: 'Number' },
          { value: 'openBillsTotal', unit: 'number', format: 'Number' },
          { value: 'basicRent', unit: 'Rent', format: 'Euro' }
        ]
      }
      return []
    },
    importing () {
      if (this.contractLevel === 'market_rent') {
        return this.hasApplicationPermissionByName('MARKKINAVUOKRAT') && this.siteId == undefined
      }
      return this.hasApplicationPermissionByName('VSOP_IMPORT') && this.siteId == undefined
    },
    changesList () {
      return [
        {changes: this.rentalStatusChanges, text: this.$t('dataimport.list_changes')},
        {changes: this.newContracts, text: this.$t('dataimport.new_contracts')}
      ]
    },
    hasChanges () {
      return this.rentalStatusChanges.length || this.newContracts.length
    },
    getRequiredFields () {
      return this.getHeaders.filter(header => this.requiredFields.includes(header.value)).map(header => header.text)
    },
    getHeaders () {
      switch (this.contractLevel) {
        case 'fairy':
          return [
          //The spread operator (...) does nothing if the array is empty but otherwise adds the object inside array to the returning array
          //This way empty object or undefined is not added into the returning array if siteId is not undefined
          ...(this.siteId === undefined ? [
          {
            value: 'site_identifier',
            text: this.$t('Site identifier'),
            format: ''
          }] : []),
          {
            value: 'siteName',
            text: this.$t('Site name'),
            format: '',
            ignoreHeader: true
          },
          {
            value: 'longUnitCodes',
            text: this.$t('Unit code long'),
            format: '',
            ignoreHeader: true
          },
          {
            value: 'buildingCode',
            text: this.$t('Building code'),
            format: ''
          },
          {
            value: 'unitName',
            text: this.$t('Unit'),
            format: ''
          },
          {
            value: 'contractNumber',
            text: this.$t('Contract number'),
            format: '',
            editable: true
          },
          {
            value: 'curPartyName',
            text: this.$t('Tenant'),
            format: '',
            editable: true
          },
          {
            value: 'business_id',
            text: this.$t('Business ID'),
            format: '',
            editable: true
          },
          {
            value: 'validity',
            text: this.$t('Validity'),
            format: '',
            editable: true
          },
          {
            value: 'signatureDate',
            text: this.$t('Contract date'),
            format: 'Date',
            editable: true
          },
          {
            value: 'curPartyStartDate',
            text: this.$t('Contract start date'),
            format: 'Date',
            editable: true
          },
          {
            value: 'contractFirstPossibleEndDate',
            text: this.$t('Contract first possible end date'),
            format: 'Date',
            editable: true
          },
          {
            value: 'curPartyEndDate',
            text: this.$t('Contract end date'),
            format: 'Date',
            editable: true
          },
          {
            value: 'tenantFirstPossibleEndDate',
            text: this.$t('rental_status.tenant_first_possible_end_date'),
            format: 'Date',
            editable: true
          },
          {
            value: 'tenantNotice',
            text: this.$t('Agreement notice period (Tenant)'),
            format: 'Number',
            editable: true
          },
          {
            value: 'landlordFirstPossibleEndDate',
            text: this.$t('rental_status.landlord_first_possible_end_date'),
            format: 'Date',
            editable: true
          },
          {
            value: 'landlordNotice',
            text: this.$t('Agreement notice period (Lanlord)'),
            format: 'Number',
            editable: true
          },
          {
            value: 'huom',
            text: this.$t('Validity notice'),
            format: '',
            editable: true
          },
          {
            value: 'area',
            text: this.$t('Unit area'),
            format: 'Area',
            ignoreHeader: true
          },
          {
            value: 'contract_area',
            text: this.$t('Contract area'),
            format: 'Area',
            editable: true
          },
          {
            value: 'contract_note',
            text: this.$t('Contract details'),
            format: '',
            editable: true
          },
          {
            value: 'vat_status',
            text: this.$t('VAT-responsibility'),
            format: '',
            editable: true
          },
          {
            value: 'contractType',
            text: this.$t('leasing.contract_type'),
            format: '',
            editable: true
          },
          {
            value: 'industry',
            text: this.$t('leasing.industry'),
            format: '',
            editable: true
          },
          {
            value: 'contract_status',
            text: this.$t('Contract status'),
            format: '',
            editable: true
          },
          {
            value: 'capitalRent',
            text: this.$t('Capital rent'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'maintenanceRent',
            text: this.$t('Maintenance rent'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'maintenanceRentEqualization',
            text: this.$t('rental_status.maintenance_rent_equalization'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'changeoverRent',
            text: this.$t('rental_status.changeover_rent'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'separateAllowances',
            text: this.$t('rental_status.separate_allowances'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'discounts',
            text: this.$t('rental_status.discounts'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'openBillsTotal',
            text: this.$t('rental_status.open_bills_total'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'basicRent',
            text: this.$t('rental_status.basic_rent'),
            format: 'Euro',
            editable: true
          },
          {
            value: 'rentReviewType',
            text: this.$t('rental_status.rent_review_type'),
            format: '',
            editable: true
          },
          {
            value: 'indexType',
            text: this.$t('rental_status.index_type'),
            format: '',
            editable: true
          },
          {
            value: 'basicIndexPointNumber',
            text: this.$t('rental_status.basic_index_point_number'),
            format: 'Number',
            editable: true
          },
          {
            value: 'reviewMonths',
            text: this.$t('rental_status.review_months'),
            format: '',
            editable: true
          },
          {
            value: 'latestIndexYear',
            text: this.$t('rental_status.latest_index_year'),
            format: 'Number',
            editable: true
          },
          {
            value: 'latestIndexMonth',
            text: this.$t('rental_status.latest_index_month'),
            format: 'Number',
            editable: true
          },
          {
            value: 'latestIndexPointNumber',
            text: this.$t('rental_status.latest_index_point_number'),
            format: 'Number',
            editable: true
          },
          {
            value: 'minimumIncrease',
            text: this.$t('rental_status.minimum_increase'),
            format: 'Percentage',
            editable: true
          }
          ]
        case 'market_rent':
          return [
          {
            text: this.$t('Site'),
            value: 'siteName',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Cost center'),
            value: 'site_identifier',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Building code'),
            value: 'buildingCode',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Unit'),
            value: 'unitName',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Unit code long'),
            value: 'unit_code_long',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Market rent'),
            value: 'market_rent',
            format: 'Euro',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Market rent added date'),
            value: 'commercial_info_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Estimated TI Expense'),
            value: 'estimated_ti_expense',
            format: 'MoneyPerSquare',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Clarified TI Expense'),
            value: 'clarified_ti_expense',
            format: 'MoneyPerSquare',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Unit version end date'),
            value: 'unit_version_end_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Unit version start date'),
            value: 'unit_version_start_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Latest updater'),
            value: 'commercial_info_user',
            editable: false,
            ignoreHeader: true
          },
          {
            text: this.$t('Rent target'),
            value: 'rent_target',
            format: 'MoneyPerSquare',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Lease plan'),
            value: 'lease_plan',
            format: undefined,
            editable: true,
            type: undefined
          },
          {
            text: this.$t('Lease plan updated'),
            value: 'lease_plan_updated',
            format: undefined,
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'curPartyName',
            text: this.$t('Tenant'),
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'nextPartyContractNumber',
            text: this.$t('Next party contract no.'),
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'contractNumber',
            text: this.$t('Contract no.'),
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'capitalRent',
            text: this.$t('Capital rent (€/mth)'),
            format: 'Euro',
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'capitalRentPerSquare',
            text: this.$t('Capital rent (€/m2/mth)'),
            format: 'Euro',
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'contractType',
            text: this.$t('leasing.contract_type'),
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'validity',
            text: this.$t('Validity'),
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'signature_date',
            text: this.$t('Contract date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'curPartyStartDate',
            text: this.$t('Contract start date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          {
            value: 'curPartyEndDate',
            text: this.$t('Contract end date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          ]
        case 'contract':
          return [
          /*{ value: 'target_info', text: this.$t('Target info') },*/

          { text: 'Contract number', value: 'contract_number' , event: 'showCreateRentContractModal' },
          ...this.buildHeaders(this.contractMetadata.contract, true)
        ]
        case 'usage_fee':
          return [
          ...this.buildHeaders(this.contractMetadata.usageFee)
        ]
        case 'contract_payments':
          return [
          {
            text: this.$t('Contract number'),
            value: 'contract_number',
            event: 'showCreateRentContractModal',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Contract sites'),
            value: 'site',
            editable: false,
            type: undefined,
            event: 'showSite'
          },
          {
            text: this.$t('Site identifier'),
            value: 'site_identifier',
            editable: false,
            type: undefined,
          },
          {
            text: this.$t('Tenant'),
            value: 'tenant',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Payment type'),
            value: 'payment_type',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.type'
          },
          {
            text: this.$t('Start date'),
            value: 'start_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('End date'),
            value: 'end_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Allocation'),
            value: 'target',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Billing date'),
            value: 'payment_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Payment domain'),
            value: 'payment_domain',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true
          },
          {
            text: this.$t('Payment basis'),
            value: 'payment_basis',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.basis'
          },
          {
            text: this.$t('create_rent_contract.payment_legend'),
            value: 'legend',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Invoicing basis'),
            value: 'invoicing_basis',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.invoicing_basis'
          },
          {
            text: this.$t('InvoicingBasisPcs'),
            value: 'pcs_count',
            format: "Number",
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Agreed area'),
            value: 'agreed_area',
            format: 'Area',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('create_rent_contract.payment_pcs_based'),
            value: 'is_square_based',
            format: 'Array',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('create_rent_contract.contract_payment_discount'),
            value: 'is_discount_payment',
            format: 'Array',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Net square rent'),
            value: 'net_square_rent',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false,
          },
          {
            text: this.$t('create_rent_contract.payment_net_sum'),
            value: 'net_sum',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false
          },
          {
            text: this.$t('Tax'),
            value: 'tax',
            format: 'Percentage',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Gross square rent'),
            value: 'gross_square_rent',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false
          },
          {
            text: this.$t('create_rent_contract.gross_sum'),
            value: 'gross_sum',
            format: 'Currency',
            editable: false,
            type: undefined,
            sortable: false
          },
          {
            text: this.$t('Rent review type'),
            value: 'rent_review_type',
            format: 'Array',
            editable: false,
            type: undefined,
            isMultiSelect: true,
            optionsTranslationEntries: true,
            definitionGroupLabel: 'rent_payments.rent_review'
          },
          {
            text: this.$t('create_rent_contract.contract_payment_latest_review_date'),
            value: 'latest_review_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Last created invoice date'),
            value: 'latest_billing_date',
            format: 'MonthAndYear',
            editable: false,
            type: undefined
          },
        ]
        case 'contract_rows':
          return [
          {
            text: this.$t('Contract number'),
            value: 'contract_number',
            event: 'showCreateRentContractModal',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Tenant'),
            value: 'tenant_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Contract status'),
            value: 'contract_status',
            editable: false,
            type: undefined,
            sortable: false,
          },
          {
            text: this.$t('contract.start_date'),
            value: 'contract_start_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('contract.end_date'),
            value: 'contract_end_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Site identifier'),
            value: 'target_code',
            editable: false,
            type: undefined,
          },
          {
            text: this.$t('Contract sites'),
            value: 'site_name',
            editable: false,
            type: undefined,
            event: 'showSite',
            link: { target: 'target_id', path: 'sites.info' },
          },
          {
            text: this.$t('Building name'),
            value: 'building_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Building code'),
            value: 'building_code',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Address'),
            value: 'address',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Governance entity'),
            value: 'governance_entity',
            editable: false,
            type: undefined,
            definitionGroupLabel: 'building.governanceentity',
            isMultiSelect: true
          },
          {
            text: this.$t('Unit'),
            value: 'unit_code',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Structure'),
            value: 'structure_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Carpark'),
            value: 'parking_space_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Unit status'),
            value: 'unit_status',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Premise type'),
            value: 'unit_premise_type',
            editable: false,
            type: undefined,
            definitionGroupLabel: 'unit.usage',
            isMultiSelect: true
          },
          {
            text: this.$t('Target goal'),
            value: 'unit_target',
            editable: false,
            type: undefined,
            definitionGroupLabel: 'unit.goal',
            isMultiSelect: true
          },
          {
            text: this.$t('Cost center'),
            value: 'costcenter',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('contract_row.start_date'),
            value: 'start_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('contract_row.end_date'),
            value: 'end_date',
            format: 'Date',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Net floor area'),
            value: 'unit_area',
            editable: false,
            type: undefined,
            format: 'Area'
          },
          {
            text: this.$t('Agreed area'),
            value: 'agreed_area',
            editable: false,
            type: undefined,
            format: 'Area'
          },
          {
            text: this.$t('Net sum'),
            value: 'net_sum',
            editable: false,
            type: undefined,
            format: 'Euro'
          },
          {
            text: this.$t('Contract type'),
            value: 'contract_type',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Clientele'),
            value: 'clientele',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Landlord'),
            value: 'renter_name',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Last created invoice date'),
            value: 'last_created_invoice_date',
            editable: false,
            type: undefined,
            format: 'MonthAndYear'
          }
        ]
        default: {
          return []
        }
      }
    },
    viewHeaders () {
      let headers = this.getHeaders
      if (this.contractLevel === 'fairy') {
        headers = headers.filter(header => header.value !== 'buildingCode')
      }
      this.multiSelectKeys = { multiSelectOptionTypes: {}}
      headers.forEach(h => {
        if (h.isMultiSelect === true)
        {
          if (h.definitionGroupId !== null && h.definitionGroupId !== undefined &&
              h.value !== 'contract_type_label')
          {
            this.multiSelectKeys[h.value] = []
            this.definitionsByGroupId(h.definitionGroupId).forEach(
              definitionOfGroup => {
                let labelText = definitionOfGroup.label
                if (h.optionsTranslationEntries === true)
                {
                  labelText = this.$t(labelText)
                }
                this.multiSelectKeys[h.value].push({ text: labelText, value: definitionOfGroup.id })
              }
            )
            this.multiSelectKeys[h.value].push({ text: '-', value: 'NULL' })
          }
          else if (h.definitionGroupLabel !== undefined)
          {
            this.multiSelectKeys[h.value] = []
            this.definitionsByGroupLabel(h.definitionGroupLabel).forEach(
              definitionOfGroup => {
                let labelText = definitionOfGroup.label
                if (h.optionsTranslationEntries === true)
                {
                  labelText = this.$t(labelText)
                }
                this.multiSelectKeys[h.value].push({ text: labelText, value: definitionOfGroup.id })
              }
            )
            this.multiSelectKeys[h.value].push({ text: '-', value: 'NULL' })
          }
          else if (h.value === "payment_domain")
          {
            this.multiSelectKeys[h.value] = []
            this.rentPaymentDomains.forEach(paymentDomain => {
              this.multiSelectKeys[h.value].push({ text: this.$t(paymentDomain.name) + " " + this.$t("VAT") + " " + paymentDomain.vatPercent + "%", value: paymentDomain.id })
            })
            this.multiSelectKeys[h.value].push({ text: '-', value: 'NULL' })
          }
          this.multiSelectKeys['multiSelectOptionTypes'][h.value] = 1
        }
      })
      return headers
    },
    viewData () {
      return this.contractLevel === 'fairy' ? this.getRentalUnitsViewData() : this.rowData
    },
    showPagination () {
      return this.contractLevel !== 'fairy' && this.contractLevel !== 'market_rent'
    },
    getPagination () {
      if (this.contractLevel === 'contract_payments') {
        return this.contractPaymentsPagination
      }

      if (this.contractLevel === 'contract') {
        return this.contractPagination
      }

      if (this.contractLevel === 'contract_rows') {
        return this.contractRowsPagination
      }

      return this.tablePagination
    },
    checkEmitStatus () {
      if (this.contractLevel === 'contract' || this.contractLevel === 'contract_payments' || this.contractLevel === 'contract_rows') {
        return true
      }
      return false
    },
    availableStoredViews () {
      return this.storedViews.filter(sv => sv.level === this.contractLevel)
    },
    getContractExport () {
      if(this.contractLevel === 'contract'){
        return this.getContractExportData
      }
      else return null
    },
    getContractPaymentsExport () {
      if(this.contractLevel === 'contract_payments'){
        return this.getContractPaymentsExportData
      }
      else return null
    },
    getContractRowsExport () {
      if(this.contractLevel === 'contract_rows'){
        return this.getContractRowsExportData
      }
      else return null
    },
    getTableRowCount () {
      switch (this.contractLevel) {
        case 'contract':
          return this.contractsCount
        case 'contract_payments':
          return this.contractPaymentsCount
        case 'contract_rows':
          return this.contractRowsCount
        default:
          return 0
      }
    }
  },
  watch: {
    buildings: async function (buildings) {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        this.loading = true
        if(buildings.length) {
          await this.getRentalData()
          this.loading = false
        }
      }
    },
    currentDate: async function () {
      await this.loadData()
    },
    contractLevel: async function (level) {
      await this.loadData()

      this.loading = level === 'fairy' || level === 'market_rent' ? this.loading : true
      await this.getLevelData(level)
      this.loading = level === 'fairy' || level === 'market_rent' ? this.loading : false
      this.selectedView = null
    },
    contractPaymentsPagination: {
      async handler () {
        await this.getContractPaymentsData()
      },
      deep: true
    },
    contractRowsPagination: {
      async handler () {
        await this.getContractRowsData()
      },
      deep: true
    },
    contractPagination: {
      async handler () {
          await this.getContractData()
      },
      deep: true
    },
    selectedContractStates: async function () {
      await this.loadData()
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
      }
      if (value === 'warning') {
        this.indicatorMessage = this.$t('Warning') + ': ' + name + ' ' + this.$t('will be overwritten')
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
      }
      if (value === 'changedDraft') {
        this.indicatorMessage = this.$t('Changed to draft')
      }
      if (value === 'savedDraft') {
        this.indicatorMessage = this.$t('Saved as draft')
      }
      if (value === 'delete') {
        this.indicatorMessage = this.$t('Succesfull delete')
      }
    },
    selectedView: function (view) {
      this.storedView = view ? this.storedViews.filter(sv => sv.level === this.contractLevel && sv.id === view)[0] : null
    }
  },
  mounted: async function () {
    this.updateTabLocation()
    var promises = []
    if (!this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') && !this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
      if(this.siteId === undefined)
      {
        this.$router.push({ name: 'dashboard' })
      }
      else
      {
        this.$router.push({ name: 'sites.info' })
      }
      promises.push(this.getStoredViews('contract'))
      await Promise.all(promises)
    } else {
      promises.push(this.getSettings())
      promises.push(this.getContractConditionsTemplates())
      promises.push(this.getAllRentPaymentDomains())
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        promises.push(this.getRentalData())
      } else {
        promises.push(this.getContractMetadata())
        promises.push(this.getAllContractStates())
      }
      promises.push(this.getStoredViews('contract'))
      await Promise.all(promises)
      this.formContractLevels()
    }
    await this.$store.dispatch('rentContracts/initContractTemplates')
    this.templates = this.getContractTemplatesByOutRent(this.tabLocation == 'contractlayers.renting_out')
    this.loading = false
  },
  methods: {
    ...mapActions('leasing', ['getRentalStatuses']),
    ...mapActions('app', ['getUsageFeeData', 'getContractMetadata', 'getContractConditionsTemplates', 'getSettings', 'getStoredViews']),
    ...mapActions('dashboard', ['updateUnitInformation']),
    ...mapActions("calculator", ["resetCalculatorData"]),
    async createContractWithTemplate (template) {
      this.contractTemplate = template
      this.showCreateRentContractModal = true;
    },
    async getLevelData (level) {
      let sortBy = level === 'fairy' ? ['possessor'] : ['contract_number']
      if (level === 'market_rent') {
        sortBy = ['buildingCode']
      }
      if (level === 'usage_fee') {
        if (!(this.contractData.usageFee.length > 0)) {
          await this.getUsageFeeData()
        }
      }
      this.tablePagination = {
        page: 1,
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy,
      }
    },
    onContractIdUpdated (contractId) {
      this.editedContractId = contractId
    },
    async loadData () {
      this.contractDataLoading = false
      this.contractPaymentsDataLoading = false
      this.contractRowsDataLoading = false

      if (this.contractLevel === 'contract'){
        await this.getContractData()
        await this.getContractTotalCount()
      }
      if (this.contractLevel === 'contract_payments') {
        await this.getContractPaymentsData()
        await this.getContractPaymentsTotalCount()
      }
      if (this.contractLevel === 'contract_rows') {
        await this.getContractRowsData()
        await this.getContractRowsTotalCount()
      }
    },
    async getContractPaymentsData () {
      this.contractPaymentsDataLoading = true
      const pagination = this.contractPaymentsPagination
      const descending = pagination.sortDesc ? pagination.sortDesc[0] : false
      const sortBy = pagination.sortBy ? pagination.sortBy[0] : null

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal
      const queryBody = {
        offset: (pagination.page - 1) * pagination.itemsPerPage,
        fetch: pagination.itemsPerPage,
        descending,
        time: this.currentDate,
        sortby: sortBy,
        search: this.searchValues,
        outRent: this.tabLocation == 'contractlayers.renting_out'
      }
      const resultRows = await this.$rambollfmapi.contracts.contractPayments.reports.get(queryBody, signal)

      // Update the contract payments if the request has not been canceled
      if (resultRows?.length >= 0) {
        this.contractPayments = resultRows
        this.contractPaymentsDataLoading = false
      }
    },
    async getContractData () {
      this.contractDataLoading = true
      const pagination = this.contractPagination
      const descending = pagination.sortDesc ? pagination.sortDesc[0] : false
      const sortBy = pagination.sortBy ? pagination.sortBy[0] : null

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal
      const queryBody = {
        offset: (pagination.page - 1) * pagination.itemsPerPage,
        fetch: pagination.itemsPerPage,
        descending,
        time: this.currentDate,
        sortby: sortBy,
        search: this.searchValues,
        outRent: this.tabLocation == 'contractlayers.renting_out',
        contractStates: this.selectedContractStates.map(state => state.id),
      }
      const resultRows = await this.$rambollfmapi.contracts.contract.contractreports.get(queryBody, signal)
      // Update the contracts if the request has not been canceled
      if (resultRows?.length >= 0) {
        this.contracts = resultRows
        this.contractDataLoading = false
      }
    },
    async getContractRowsData () {
      this.contractRowsDataLoading = true
      const pagination = this.contractRowsPagination
      const descending = pagination.sortDesc ? pagination.sortDesc[0] : false
      const sortBy = pagination.sortBy ? pagination.sortBy[0] : null

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController()
      const signal = this.abortController.signal
      const queryBody = {
        offset: (pagination.page - 1) * pagination.itemsPerPage,
        fetch: pagination.itemsPerPage,
        descending,
        time: this.currentDate,
        sortby: sortBy,
        search: this.searchValues,
        outRent: this.tabLocation == 'contractlayers.renting_out',
        withPayments: true,
        contractStates: this.selectedContractStates.map(state => state.id),
      }
      const resultRows = await this.$rambollfmapi.contracts.contractRow.reports.get(queryBody, signal)

      // Update the contract payments if the request has not been canceled
      if (resultRows?.length >= 0) {
        this.contractRows = resultRows
        this.contractRowsDataLoading = false
      }
    },
    async outRentChanged () {
      await this.loadData()
      this.templates = this.getContractTemplatesByOutRent(this.tabLocation == 'contractlayers.renting_out')
    },
    async getRentalData () {
      const siteIds = this.siteId === undefined ? this.sites.map(site => {
        return site.id_site
      }) : [ this.siteId ]
      const buildingIds = this.buildings.filter(b => siteIds.includes(b.id_site)).map(b => b.id_building)
      this.futureRentalStatuses = await this.$rambollfmapi.reports.post('allfuturerentalstatusversions', buildingIds, this.currentDate)
      await this.getRentalStatuses(siteIds)
    },
    onCreateRentContractModalClosed (addContract, closeModal) {
      if (addContract) {
        this.contractsCount += 1
      }

      this.getContractData()
      if (closeModal || closeModal === undefined) {
        this.editedContractId = undefined
        this.showCreateRentContractModal = false
      }
      this.resetCalculatorData()
    },
    getFieldText (field) {
      // guess the name
      let name = field.split('_').join(' ')

      if (field == 'tags') {
        name = 'Category'
      }

      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    getFieldValue (field) {
      switch (field) {
        default:
          return field
      }
    },
    addStatuses (statuses) {
      return statuses.map(rs => {
        rs.signatureDate = rs.signature_date
        rs.vat === 1
          ? (rs.vat_status = 'Kyllä')
          : (rs.vat_status = 'Ei')
        rs.huom = rs.huom ? this.$t('Considerations') : null
        return rs
      })
    },
    async formContractLevels () {
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        this.contractLevels.push({
            text: this.$t('Fairy contracts'),
            value: 'fairy'
        })
        this.contractLevels.push({
          text: this.$t('contracts.market_rent_and_ti_expenses'),
          value: 'market_rent'
        })
        this.contractLevel = 'fairy'
      } else {
        this.contractLevels.push({
          text: this.$t('Contract level'),
          value: 'contract'
        })
        if (this.contractLevel === '') {
          this.contractLevel = 'contract'
        }
        this.contractLevels.push({
          text: this.$t('Contract payments level'),
          value: 'contract_payments'
        })
        if (this.settings.canShowContractRowLayer === true) {
          this.contractLevels.push({
            text: this.$t('Contract row level'),
            value: 'contract_rows'
          })
        }
      }
    },
    getAllRentalUnits () {
      const rentalStatus = this.rentalStatuses
      const futureRentalStatus = this.futureRentalStatuses
      return this.addStatuses([...rentalStatus, ...futureRentalStatus])
    },
    getRentalUnitsViewData () {
      const rentalStatus = this.rentalStatuses

      const data = rentalStatus.map(rs => {
        rs.signatureDate = rs.signature_date
        rs.vat === 1
          ? (rs.vat_status = 'Kyllä')
          : rs.vat === 0
            ? (rs.vat_status = 'Ei')
            : (rs.vat_status = '-')
        rs.huom = rs.huom ? this.$t('Considerations') : null
        return rs
      })

      const rows = []
      data.forEach(d => {
        if (d.contractNumber) {
          const index = rows.findIndex(row => row.contractNumber === d.contractNumber)
          if (index >= 0) {
            const buildingIndex = rows[index].unitNames.findIndex(b => b.buildingCode === d.buildingCode)
            if (buildingIndex >= 0) {
              rows[index].unitNames[buildingIndex].units.push(d.unitName)
            } else {
              rows[index].unitNames.push({buildingCode: d.buildingCode, units: [d.unitName]})
            }
            if(d.unit_code_long && d.unit_code_long !== ""){
              rows[index].longUnitCodeList.push(d.unit_code_long)
            }
              rows[index].area += d.area
          } else {
            const newRow = {...d}
            newRow.unitNames = [{buildingCode: d.buildingCode, units: [d.unitName]}]
            newRow.longUnitCodeList = [d.unit_code_long]
            rows.push(newRow)
          }
        }
      })

      rows.forEach(row => {
        row.unitName = row.unitNames.reduce((a,b, index) => {
          let text = a
          if (index > 0) {
            text += '<br/>'
          }
          return `${text}<b>${b.buildingCode}:</b> ${b.units.join(', ')}`
        }, '')

      row.longUnitCodes = row.longUnitCodeList.join(",\n")

      })
      return rows
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    showDefaultSearchDialog () {
      this.showDefaultSearch = !this.showDefaultSearch
      this.confirmation = false
    },
    closeViewAndSelectionDialogs () {
      if (this.showDefaultSearch) {
        this.showDefaultSearchDialog()
      }
      if (this.showColumnsFilter) {
        this.showColumnsFilterDialog()
      }
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType

      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('contract')
    },
    onEditRow (row) {
      this.editedContractId = row.id
      this.showCreateRentContractModal = true
    },
    async onRemoveRow (row) {
      if (confirm(this.$t('Confirm remove contract draft'))) {
        await this.$rambollfmapi.contracts.contract.deleteContract(row.id)
        this.getContractData()
      }
    },
    async saveFile (results) {
      this.dataUpdateDialog = true
      this.updatingItems = true
      const data = {
        items: this.rowData,
        headers: this.getHeaders
      }
      // find correct identifier indexes
      const firstIdentifier = this.getHeaders.findIndex(h => h.value === 'buildingCode')
      const secondIdentifier = this.getHeaders.findIndex(h => h.value === 'unitName')
      if (firstIdentifier > -1 && secondIdentifier > -1 ) {
        let items = null
        if (this.contractLevel === 'market_rent') {
          items = helpers.dataTable.updateItems(data, results.data, firstIdentifier, secondIdentifier)
          this.importSuccess = items.success
          this.errors = []
          this.rentalStatusChanges = []
          if (this.importSuccess) {
            this.handleMarketRentUpdate(items.items, data.items, data.headers)
          } else {
            // If import contains error group those to error list
            const uneditables = items.errors.uneditable
            const unexistings = items.errors.unexisting

            if(uneditables.length) {
              this.errors.uneditable = uneditables
            }

            if(unexistings.length) {
              this.errors.unexisting = unexistings
            }
          }
          this.savingFailed = false
        }
        else {
          items = helpers.dataTable.modifyItems(data, results.data, firstIdentifier, secondIdentifier, true)
           this.currentItem = 0
          this.itemsLength = items.items.length
          this.importSuccess = items.success
          this.importErrors = items.errors
          this.errors = []
          this.rentalStatusChanges = []
          if (items.success) {
            await this.handleUpdate(items.items)
          }
          this.savingFailed = false
        }
      } else {
        // identifiers not found from headers, check headers code and select correct identifier values
        this.savingFailed = true
      }
      this.isSavingChanges = false
      this.updatingItems = false
    },
    async handleUpdate (items) {
      const reports = this.getAllRentalUnits()
      let changedReports = []
      let newRentalContracts = []
      let unChangedRows = 0
      let errRows = 0
      this.importErrors.unexisting.forEach(s => {
        errRows +=1
        var errArr = s.split(",")
        this.errors.push({
          buildingCode: errArr[0],
          unitName: errArr[1],
          value: this.$t('dataimport.building_or_unit_not_found'),
          label: this.getHeaders.find(header => header.value === 'unitName').text
        })

      })
      // detect changed rows
      this.totalItems = items.length
      items.forEach(modifiedItem => {
        // use unitId here to identify correct item (could also use buildingCode and unitName combination)
        let newContract = false
        let report = reports.find(r => (r.buildingCode === modifiedItem.buildingCode && r.unitName === modifiedItem.unitName && r.contractNumber === modifiedItem.contractNumber))
        if (!report) {
          newContract = true
          report = reports.find(r => (r.buildingCode === modifiedItem.buildingCode && r.unitName === modifiedItem.unitName))
        }
        if (report) {
          const reportCopy = {}
          this.getHeaders.forEach(header => {
            reportCopy[header.value] = report[header.value]
          })
          reportCopy.unitId = report.unitId
          reportCopy.curUnitPartyId = report.curUnitPartyId
          reportCopy.contractId = report.contractId
          reportCopy.curPartyId = report.curPartyId
          modifiedItem.unitId = report.unitId
          modifiedItem.curUnitPartyId = report.curUnitPartyId
          modifiedItem.contractId = report.contractId
          modifiedItem.huom = modifiedItem.huom ? modifiedItem.huom : null
          modifiedItem.curPartyId = report.curPartyId

          // changing tenant corporation name is not allowed
          if (report.business_id == modifiedItem.business_id && report.tenant_corporation) {
            modifiedItem.curPartyName = report.curPartyName
          }

          const reportObserver = this.$jsonpatch.observe(reportCopy)
          Object.keys(reportCopy).forEach(key => {
            const propertyHeader = this.getHeaders.find(h => h.value === key)
            // replace only editable fields
            if (propertyHeader && (propertyHeader.editable && !propertyHeader.ignoreHeader)) {
              reportCopy[key] = modifiedItem[key]
            }
          })

          const errorObject = {
            buildingCode: reportCopy.buildingCode,
            unitName: reportCopy.unitName
          }
          let inputFieldError = false

          // validate input fields that they are in correct format
          this.getHeaders.forEach(header => {
            let isNotCorrect
            if (reportCopy[header.value]) {
              if (header.format === 'Date') {
                // ignore dates that are not recognized as date format
                const momentDay = moment(reportCopy[header.value], "DD.MM.YYYY")
                const momentDayWithSlash = moment(reportCopy[header.value], "DD/MM/YYYY")
                const momentDayWithDash = moment(reportCopy[header.value], "YYYY-MM-DD")
                const originalTime = moment(report[header.value]).endOf('day').toDate().getTime()
                let newTime = new Date()
                // verify correct time format
                if(momentDayWithDash.isValid() && reportCopy[header.value].includes('-') && !reportCopy[header.value].includes(':')) {
                  newTime = momentDayWithDash.endOf('day').toDate()
                } else if (momentDay.isValid() && reportCopy[header.value].includes('.') && !reportCopy[header.value].includes('-')) {
                  newTime = momentDay.endOf('day').toDate()
                } else if (momentDayWithSlash.isValid() && reportCopy[header.value].includes('/') && !reportCopy[header.value].includes('-')) {
                  newTime = momentDayWithSlash.endOf('day').toDate()
                } else {
                  isNotCorrect = isNaN(Date.parse(reportCopy[header.value]))
                  if (!isNotCorrect) {
                    newTime = moment(reportCopy[header.value]).endOf('day').toDate()
                  }
                }
                // no need to patch if only hours or minutes are different
                reportCopy[header.value] = newTime.getTime() !== originalTime ? newTime : report[header.value]
              }
              if (header.format === 'Number' || header.format === 'AreaExact' || header.format === 'Percentage') {
                // export function converts all decimal dots to commas, convert them back
                reportCopy[header.value] = String(
                  reportCopy[header.value]
                ).replace(',', '.')
                // ignore NaN notice periods
                isNotCorrect = isNaN(Number(reportCopy[header.value]))
              }

              if (isNotCorrect) {
                inputFieldError = true
                this.errors.push({
                  ...errorObject,
                  value: reportCopy[header.value] + this.$t('dataimport.incorrect_input'),
                  label: header.text
                })
                errRows += 1
                // return original value
                reportCopy[header.value] = report[header.value]
              }
            }

            if (header.format === 'Number' || header.format === 'AreaExact' || header.format === 'Percentage') {
              // export function converts all numbers to strings, convert them back to numbers if needed
              reportCopy[header.value] = reportCopy[header.value]
                ? Number(reportCopy[header.value])
                : null
            }
          })

          if (!inputFieldError) {
            const reportPatch = this.$jsonpatch.generate(reportObserver)
            if (reportPatch.length) {
              let error = false
              this.requiredFields.forEach(field => {
                if (!reportCopy[field]) {
                  error = true
                  this.errors.push({
                    ...errorObject,
                    value: this.$t('Required'),
                    label: this.getHeaders.find(header => header.value === field).text
                  })
                  errRows += 1
                }
              })
              if (!error) {
                // huom field changed back to boolean value
                reportCopy.huom = reportCopy.huom === this.$t('Considerations')
                // replace original signature date with a possible change
                reportCopy.signature_date = reportCopy.signatureDate
                if (!newContract && report.contractId && report.curUnitPartyId) {
                  if (!reportCopy.business_id) {
                    this.errors.push({
                      ...errorObject,
                      value: reportCopy.business_id, // note that business ID is missing
                      label: this.getHeaders.find(header => header.value === 'business_id').text
                    })
                    errRows += 1
                  } else {
                    changedReports.push(reportCopy)
                  }
                } else if (newContract && this.checkBusinessIdAndName(reportCopy)) {
                  // we created a new contract
                  newRentalContracts.push(reportCopy)
                } else if (!reportCopy.business_id || !reportCopy.business_id.replace(/\s/g,'')) {
                  this.errors.push({
                      ...errorObject,
                      value: reportCopy.business_id ? reportCopy.business_id : '',
                      label: this.getHeaders.find(header => header.value === 'business_id').text
                  })
                  errRows += 1
                } else if (!reportCopy.curPartyName || !reportCopy.curPartyName.replace(/\s/g,'')) {
                  this.errors.push({
                      ...errorObject,
                      value: reportCopy.curPartyName ? reportCopy.curPartyName : '',
                      label: this.getHeaders.find(header => header.value === 'curPartyName').text
                  })
                  errRows += 1
                }
              }
            } else {
              unChangedRows += 1
            }
          }
        }
      })
      this.itemsNotChanged = unChangedRows
      this.errorRows = errRows
      if (this.updatingItems) {
        const areaCheck = []
        // Set vat-value to match the text in vat_status
        // and contract areas must match within the same contract number
        changedReports.forEach((value, index, arr) => {
          arr[index].vat = this.parseVatString(arr[index].vat_status)
          changedReports = this.checkContractArea(areaCheck, value, changedReports)
        })
        newRentalContracts.forEach((value, index, arr) => {
          arr[index].vat = this.parseVatString(arr[index].vat_status)
          newRentalContracts = this.checkContractArea(areaCheck, value, newRentalContracts)
        })
        this.rentalStatusChanges = changedReports
        this.newContracts = newRentalContracts
      }
    },
    handleMarketRentUpdate (items, data, headers) {
      const changedReports = []
      // detect changed rows
      data.forEach(report => {
        // building code and unit name is used to identify changed information
        const modifiedItem = items.find(item => {
          return (
            item.buildingCode === report.buildingCode &&
            item.unitName === report.unitName &&
            ((item.market_rent !== null &&
            (Number(String(item.market_rent).replace(',', '.')) !==
              report.market_rent)) ||
            (item.estimated_ti_expense !== null &&
            (Number(String(item.estimated_ti_expense).replace(',', '.')) !==
              report.estimated_ti_expense)) ||
            (item.clarified_ti_expense !== null &&
            (Number(String(item.clarified_ti_expense).replace(',', '.')) !==
              report.clarified_ti_expense)) ||
            (item.rent_target !== null &&
            (Number(String(item.rent_target).replace(',', '.')) !==
            report.rent_target)) ||
            (item.lease_plan !== null && item.lease_plan !== report.lease_plan)
            )
          )
        })
        if (modifiedItem) {
          const errorObject = {
            buildingCode: modifiedItem.buildingCode,
            unitName: modifiedItem.unitName
          }
          let validMarketRent = true
          // validate input fields that they are in correct format
          headers.forEach(header => {
            let isNotCorrect
            if (modifiedItem[header.value]) {
              if (header.type === Number) {
                // export function converts all decimal dots to commas, convert them back
                modifiedItem[header.value] = String(
                  modifiedItem[header.value]
                ).replace(',', '.')
                // ignore NaN notice periods
                isNotCorrect = isNaN(Number(modifiedItem[header.value]))
              }

              if (isNotCorrect) {
                this.errors.push({
                  ...errorObject,
                  value: modifiedItem[header.value],
                  label: header.text
                })
                // return original value
                modifiedItem[header.value] = report[header.value]
                if (this.importingCarParks) {
                  validMarketRent = false
                }
              }
            }
            if (header.type === Number) {
              // export function converts all numbers to strings, convert them back to numbers if needed
              modifiedItem[header.value] = modifiedItem[header.value]
                ? Number(modifiedItem[header.value])
                : report[header.value]
            }
            // if editable header has same value in modifieditem obj and report obj remove
            // it to not send it as updated value in saveMarketRentChanges method
            if (header.editable && modifiedItem[header.value] === report[header.value]) {
              delete modifiedItem[header.value]
            }
          })
          // Add modified items to changed reports array
          if (validMarketRent) {
            changedReports.push(modifiedItem)
          }
        }
      })
      this.rentalStatusChanges = changedReports
    },
    checkBusinessIdAndName (contract) {
      return contract.business_id && contract.business_id.replace(/\s/g,'') && contract.curPartyName && contract.curPartyName.replace(/\s/g,'')
    },
    parseVatString (str) {
      const lwrStr = str.toLowerCase()
      if (
        lwrStr.indexOf('kyllä') !== -1 ||
        lwrStr.indexOf('kylla') !== -1 ||
        lwrStr.indexOf('yes') !== -1 ||
        lwrStr.indexOf('true') !== -1
      ) {
        return 1
      } else {
        return 0
      }
    },
    checkContractArea (areaArray, value, contracts) {
      if (!areaArray[value.contractNumber]) {
        areaArray[value.contractNumber] = value.contract_area
      } else if (areaArray[value.contractNumber] !== value.contract_area) {
        const errorObject = {
          buildingCode: value.buildingCode,
          unitName: value.unitName
        }
        this.errors.push({
          ...errorObject,
          value: this.$t('dataimport.area_check') + value.contractNumber,
          label: this.$t('Contract area')
        })
        // remove all changes which are with the same contract number
        return contracts.filter(item => item.contractNumber !== value.contractNumber)
      }
      return contracts
    },
    async saveChanges () {
      this.isSavingChanges = true
      try {
        if (this.rentalStatusChanges.length > 0) {
          await this.saveRentalStatusChanges()
        }
        await this.addNewRentalContracts()
      } catch {
        this.savingFailed = true
        this.resetChanges()
        this.dataUpdateDialog = true
      }
      if (this.overlapErrors.length < 1 && this.savingErrors.length < 1) {
        this.resetChanges()
      } else {
        this.setDefaults()
        this.isSavingChanges = false
        this.savingFailed = true
        this.dataUpdateDialog = true
      }
      this.loading = true
      // update rental status
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        await this.getRentalData()
      }
      this.loading = false
    },
    async saveRentalStatusChanges () {
      let errors = []
      try {
        errors = await this.$rambollfmapi.units.rentalStatus().update(this.rentalStatusChanges, true)
        if (errors.name === 'Error') {
          throw Error
        }
      } catch (err) {
        this.savingErrors.push({
          label: this.$t('dataimport.unexpected_connection_error')
        })
      }
      this.updatedContracts = this.rentalStatusChanges.length - errors.length
      this.failedUpdates = errors.length
      this.handleErrors(errors)
    },
    async addNewRentalContracts () {
      this.savingNewContracts = true
      this.currentItem = 0
      this.itemsLength = this.newContracts.length
      let errors = []
      try {
        errors = await this.$rambollfmapi.units.rentalStatus().post(this.newContracts)
        if (errors.name === 'Error') {
          throw Error
        }
      } catch (err) {
        this.savingErrors.push({
          label: this.$t('dataimport.unexpected_connection_error')
        })
      }
      this.savedContracts = this.newContracts.length - errors.length
      this.savingErrorsCount = errors.length
      this.handleErrors(errors)
      this.savingNewContracts = false
    },
    async saveMarketRentChanges () {
      const time = new Date().toISOString().slice(0, 10)
      this.isSavingChanges = true
      for (const rentalStatusChange of this.rentalStatusChanges) {
        const patchObj = []
        if(rentalStatusChange.market_rent !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/market_rent',
            value: rentalStatusChange.market_rent
        })
        }
        if(rentalStatusChange.rent_target !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/rent_target',
            value: rentalStatusChange.rent_target
          })
        }
        if(rentalStatusChange.lease_plan !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/lease_plan',
            value: rentalStatusChange.lease_plan
          })
        }
        if(rentalStatusChange.estimated_ti_expense !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/estimated_ti_expense',
            value: rentalStatusChange.estimated_ti_expense
          })
        }
        if(rentalStatusChange.clarified_ti_expense !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/clarified_ti_expense',
            value: rentalStatusChange.clarified_ti_expense
          })
        }
        try {
          if (patchObj.length > 0) {
            const data = {
              unitId: rentalStatusChange.unitId,
              time: time,
              patch: patchObj
            }
            await this.updateUnitInformation(data)
          }
        } catch {
          this.savingFailed = true
          this.resetChanges()
          this.dataUpdateDialog = true
        }
      }
      if (this.savingFailed === false) {
        this.resetChanges()
      }
      this.loading = true
      // update rental status
      if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
        await this.getRentalData()
      }
      this.loading = false
    },
    resetChanges () {
      this.isSavingChanges = false
      this.updatingItems = false
      this.savingNewContracts = false
      this.dataUpdateDialog = false
      this.setDefaults()
      this.errors = []
      this.overlapErrors = []
      this.savingErrors = []
    },
    setDefaults () {
      this.rentalStatusChanges = []
      this.newContracts = []
    },
    handleErrors (errors) {
      if (errors.length > 0) {
        const startDate = this.getHeaders.find(header => header.value === 'curPartyStartDate').text
        const endDate = this.getHeaders.find(header => header.value === 'curPartyEndDate').text
        const contractNumber = this.getHeaders.find(header => header.value === 'contractNumber').text
        const partyName = this.getHeaders.find(header => header.value === 'curPartyName').text
        errors.forEach( err => {
          const errorObject = {
            buildingCode: err.buildingCode,
            unitName: err.unitName
          }

          switch (err.label) {
            case 'overlapping': {
              const start = err.startDate ? moment(new Date(err.startDate)).format('DD.MM.YYYY') : '-'
              const end = err.endDate ? moment(new Date(err.endDate)).format('DD.MM.YYYY') : '-'
              this.overlapErrors.push({
                ...errorObject,
                value: `${err.contractNumber}, ${startDate}: ${start}, ${endDate}: ${end}`,
                label: `${err.newContract ? this.$t("dataimport.new_contract") : ""}${contractNumber}`
              })
              break
            }
            case 'startDate':
              this.savingErrors.push({
                ...errorObject,
                value: this.$t('dataimport.not_existing'),
                label: startDate
              })
              break
            case 'unitStatus':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}, ${startDate}: ${moment(new Date(err.startDate)).format('DD.MM.YYYY')}, ${this.$t('dataimport.unit_in_wrong_status')}: ${this.getDefinitionLabelById(parseInt(err.value, 10))}`,
                label: contractNumber
              })
              break
            case 'patchError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractNumber + ". " + this.$t('dataimport.unexpected_connection_error'),
                label: contractNumber
              })
              break
            case 'partyError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractPartyName + ". " + this.$t('dataimport.party_creation_error') + " " + this.$t('dataimport.try_again'),
                label: partyName
              })
              break
            case 'contractError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractNumber + ". " + this.$t('dataimport.contract_creation_error') + " " + this.$t('dataimport.try_again'),
                label: contractNumber
              })
              break
            case 'linkError':
              this.savingErrors.push({
                ...errorObject,
                value: err.contractPartyName + ". " + this.$t('dataimport.party_linking_error') + " " + this.$t('dataimport.try_again'),
                label: partyName
              })
              break
            case 'unitNotFound':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}. ${this.$t('dataimport.unit_not_found')}`,
                label: contractNumber
              })
              break
            case 'unknownError':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}. ${err.value}`,
                label: contractNumber
              })
              break
            case 'contractNotFound':
              this.savingErrors.push({
                ...errorObject,
                value: `${err.contractNumber}. ${this.$t('dataimport.contract_not_found')}`,
                label: contractNumber
              })
              break
            default:
              this.savingErrors.push({
                ...errorObject,
                value: err.contractNumber + ". " + this.$t('dataimport.unexpected_connection_error'),
                label: contractNumber
              })
          }
        })
      }
    },
    handleOk () {
      this.dataUpdateDialog = false
      this.errors = []
    },
    viewErrorText (error) {
      const {buildingCode, unitName, label, value} = error
      if (!buildingCode) {
        return label
      }
      return `${buildingCode} ${unitName} - ${label}: ${value}`
    },
    calculateCanShowPortfolio (metadata) {
      let canShowPortfolio = false

      if("status_portfolio" in metadata){
        if (this.settings != undefined) {
          if (this.settings.length === 0) {
            canShowPortfolio = false;
          }
          else {
            canShowPortfolio = this.tabLocation == 'contractlayers.renting_out' == true
          ? this.settings.outrentCanConnectContractToPortfolio
          : this.settings.inrentCanConnectContractToPortfolio;
          }
        }
        return canShowPortfolio
      }
    },
    buildHeaders (metadata, fromContract = false) {
      if (metadata === null) {
        return []
      }

      let canShowPortfolio = this.calculateCanShowPortfolio(metadata)

      return Object.keys(metadata)
        // filter out metadata.contract_number because we want to initialize the header for contract_number elsewhere for clickable link
        .filter(key => key !== 'contract_number')
        .map(key => {
          return { ...metadata[key], name: key }
        })
        // filter with some hardcoded values
        .filter(v => {
          if (fromContract) {
            if ((v.name === 'status_portfolio')) {
              return canShowPortfolio
            }
          }
          return v.isShown
        })
        .filter(v => v.category === this.category)
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(key => {
          let header = {
            text: this.getFieldText(key.name),
            value: this.getFieldValue(key.name),
            format: key.format,
            sortable: fromContract && (key.name === 'payments_net_sum' || key.name === 'contract_state') ? false : key.sortable,
            isMultiSelect: key.definitionGroupId !== 0 && key.definitionGroupId !== null && key.definitionGroupId !== undefined && key.name !== 'contract_type_label',
            definitionGroupId: key.definitionGroupId,
            type: this.getFieldType(key),
          }
          if (header.text === 'Automatic billing reasoning') {
            header.hideOverflow = true
          }
          return header
        })
    },
    changedRow (row) {
      const start = moment(new Date(row.curPartyStartDate)).format('DD.MM.YYYY')
      const end = row.curPartyEndDate ? moment(new Date(row.curPartyEndDate)).format('DD.MM.YYYY') : ''
      return row.buildingCode + ' ' + row.unitName + ', ' + row.curPartyName + ': ' + start + ' - ' + end
    },
    updatePagination (pag) {
      if (this.contractLevel === 'contract') {
        Object.assign(this.contractPagination, pag)
      }
      if (this.contractLevel === 'contract_payments') {
        Object.assign(this.contractPaymentsPagination, pag)
      }
      if (this.contractLevel === 'contract_rows') {
        Object.assign(this.contractRowsPagination, pag)
      }
    },
    async filterSearch (query) {
      if (this.contractLevel === 'contract') {
        let queryStr = JSON.stringify(query)
        if (queryStr != JSON.stringify(this.contractPagination.search)) {
          this.contractPagination.search = queryStr
          this.contractPagination.page = 1
          this.searchValues = queryStr
          await this.getContractTotalCount()
        }
      }
      if (this.contractLevel === 'contract_payments') {
        let queryStr = JSON.stringify(query)
        if (queryStr != JSON.stringify(this.contractPaymentsPagination.search)) {
          this.contractPaymentsPagination.search = JSON.stringify(query)
          this.contractPaymentsPagination.page = 1
          this.searchValues = JSON.stringify(query)
          await this.getContractPaymentsTotalCount()
        }
      }
      if (this.contractLevel === 'contract_rows') {
        let queryStr = JSON.stringify(query)
        if (queryStr != JSON.stringify(this.contractRowsPagination.search)) {
          this.contractRowsPagination.search = JSON.stringify(query)
          this.contractRowsPagination.page = 1
          this.searchValues = JSON.stringify(query)
          await this.getContractRowsTotalCount()
        }
      }
    },
    getMarketRentViewData (rentableDefs, marketRentData, futureMarketRentData) {

      const totalAreaByContractNumber = marketRentData.reduce((acc, curr) => {
        if (curr.contractNumber) {
          if (acc[curr.contractNumber]) {
            acc[curr.contractNumber] += curr.area
          }else {
            acc[curr.contractNumber] = curr.area
          }
        }
        return acc
      }, {})

      // Map future market rent data to objects for fast access
      const futureUnitContracts = Object.fromEntries(futureMarketRentData.map(item => [item.unitId, item.contractNumber]))

      return marketRentData.filter(s => rentableDefs.includes(s.unitStatus)).map(rs => {

        const capitalRent =  totalAreaByContractNumber[rs.contractNumber] ? rs.capitalRent * rs.area / totalAreaByContractNumber[rs.contractNumber] : null

        return {
          siteName: rs.siteName,
          unitId: rs.unitId,
          unitName: rs.unitName,
          buildingCode: rs.buildingCode,
          site_identifier: rs.site_identifier,
          market_rent: rs.market_rent,
          commercial_info_date: rs.commercial_info_date,
          siteIdentifier: rs.siteId,
          unitIdentifier: rs.unitId,
          estimated_ti_expense: rs.estimated_ti_expense,
          unit_code_long: rs.unit_code_long,
          clarified_ti_expense: rs.clarified_ti_expense,
          unit_version_end_date: rs.curUnitEndDate,
          unit_version_start_date: rs.curUnitStartDate,
          commercial_info_user: rs.commercial_info_user,
          rent_target: rs.rent_target,
          lease_plan: rs.lease_plan,
          lease_plan_updated: rs.lease_plan_user && rs.lease_plan_date ? rs.lease_plan_user + ', ' + moment(rs.lease_plan_date).format('DD.MM.YYYY') : '',
          curPartyName: rs.curPartyName,
          contractNumber: rs.contractNumber,
          capitalRent: capitalRent,
          capitalRentPerSquare: capitalRent / rs.area,
          contractType: rs.contractType,
          validity: rs.validity,
          signature_date: rs.signature_date,
          curPartyStartDate: rs.curPartyStartDate,
          curPartyEndDate: rs.curPartyEndDate,
          nextPartyContractNumber: futureUnitContracts[rs.unitId] !== rs.contractNumber ? futureUnitContracts[rs.unitId] : null
        }
      })
    },
    async getContractTotalCount () {
      const stateIds = this.selectedContractStates.map(state => state.id)
      const result = await this.$rambollfmapi.contracts.contract.contractreports.totalCount(this.currentDate, this.searchValues, this.tabLocation == 'contractlayers.renting_out', stateIds)
      this.contractsCount = isNaN(result) ? 0 : result
    },
    async getContractPaymentsTotalCount () {
      const result = await this.$rambollfmapi.contracts.contractPayments.reports.totalCount(this.currentDate, this.searchValues, this.tabLocation == 'contractlayers.renting_out')
      this.contractPaymentsCount = isNaN(result) ? 0 : result
    },
    async getContractRowsTotalCount () {
      const stateIds = this.selectedContractStates.map(state => state.id)
      const result = await this.$rambollfmapi.contracts.contractRow.reports.totalCount(this.currentDate, this.searchValues, this.tabLocation == 'contractlayers.renting_out', stateIds)
      this.contractRowsCount = isNaN(result) ? 0 : result
    },
    async getAllContractStates () {
      await this.getSettings()
      const states = await this.$rambollfmapi.contracts.contract.getAllContractStates()
      var filteredStates = states
      if (this.settings.canShowContractSigningStatuses == false) {
        filteredStates = states.filter(obj => ![
          'ContractStateReadyForSigning',
          'ContractStateSigningRequestSent',
          'ContractStateSigningRequestExpired',
          'ContractStateNoElectornigSigning',
          'ContractStateRequestFailed'
        ].includes(obj.name));
      }
      let ContractStateExpiredId = filteredStates.filter(state => state.name == "ContractStateExpired").map(state => state.id);
      this.contractStates = filteredStates
      this.translatedContractStates = filteredStates.map(state => ({ id: state.id, name: this.$t(state.name) }))
      this.selectedContractStates = this.translatedContractStates
      this.selectedContractStates = this.translatedContractStates.filter(state => !ContractStateExpiredId.includes(state.id));
    },
    async getAllRentPaymentDomains () {
      const result = await this.$rambollfmapi.contracts.contract.getRentPaymentDomains()
      this.rentPaymentDomains = result
    },
    openContractModal (row) {
      this.editedContractId = row.id
      this.showCreateRentContractModal = true
    },
    showSite (row) {
      let siteIdArr = row.siteids.split(',')
      if (siteIdArr.length == 1) {
        window.open("../sites/" + siteIdArr[0].trim(), "_blank")
      }
    },
    triggerCustomEvents (event) {
      if (event.eventName === 'showCreateRentContractModal') {
        this.openContractModal(event.row)
      }
      if (event.eventName === 'showSite') {
        this.showSite(event.row)
      }
    },
    async getContractExportData () {
        const data = await this.$rambollfmapi.contracts.contract.contractreports.get({
            time: this.currentDate,
            offset: 0,
            fetch: 1000000,
            descending: this.tablePagination.sortDesc[0],
            sortby: this.tablePagination.sortBy[0],
            search: this.searchValues,
            contractStates: this.selectedContractStates.map(state => state.id),
            outRent: this.tabLocation == 'contractlayers.renting_out'
          })

        return data
    },
    async getContractPaymentsExportData () {
        const data = await this.$rambollfmapi.contracts.contractPayments.reports.get({
            time: this.currentDate,
            offset: 0,
            fetch: 1000000,
            descending: this.tablePagination.sortDesc[0],
            sortby: this.tablePagination.sortBy[0],
            search: this.searchValues,
            outRent: this.tabLocation == 'contractlayers.renting_out'
          })

        return data
    },
    async getContractRowsExportData () {
        const data = await this.$rambollfmapi.contracts.contractRow.reports.get({
            time: this.currentDate,
            offset: 0,
            fetch: 1000000,
            descending: this.tablePagination.sortDesc[0],
            sortby: this.tablePagination.sortBy[0],
            search: this.searchValues,
            outRent: this.tabLocation == 'contractlayers.renting_out',
            withPayments: true,
            contractStates: this.selectedContractStates.map(state => state.id),
          })

        return data
    },
    selectView (view) {
      if (view) {
        this.selectedView = this.availableStoredViews.find(v => v.name === view.name).id
      } else {
        this.selectedView = null
      }
    },
    async saveStoredView (name, selectedHeaders, confirm, privacy = true) {
      this.confirmation = false
      const exists = this.availableStoredViews.includes(name)
      if (exists && !confirm) {
          this.confirmation = true
          this.operationResult = 'warning'
          this.storedView.stored_fields = selectedHeaders.map(h => {
          return {
              field: h,
              id_storedview: this.storedView.id
          }
        })
      } else {
          try {
            var rename = false
            if (exists) {
              // Modify existing
              await this.$rambollfmapi.storedviews.put(this.storedView.id, selectedHeaders)
            } else {
              // Create new
              const data = {
                  parent_level: 'contract',
                  level: this.contractLevel,
                  name: name,
                  privacy: privacy
              }

              await this.$rambollfmapi.storedviews.post(data, selectedHeaders).then(res => {
                if (res == 'Name already exist') {
                  rename = true
                }
              })
            }
            if (rename == false) {
              this.closeViewAndSelectionDialogs()
              this.operationResult = 'success'
              this.indicatorMessage = this.$t('Save success')
            } else {
              this.operationResult = 'warning'
              this.indicatorMessage = this.$t('Name already in use')
            }
          } catch (error) {
            this.operationResult = 'error'
            this.indicatorMessage = this.$t('Error while saving')
          }
      }
      this.showIndicator = true
      this.operationResult = 'success'
      if (this.operationResult === 'success') {
        await this.getStoredViews('contract')
        this.selectedView = this.availableStoredViews.find(view => view.name === name).id
      }
    },
    async deleteSelectedView (id) {
      const view = this.availableStoredViews.find(x => x.id === id)
      if (confirm(this.$t('storedview.deletion') + ' ' + view.name + '?' + '\n' + (view.private ? this.$t('storedview.deletion.private') : this.$t('storedview.deletion.public')))) {
        try {
            await this.$rambollfmapi.storedviews.delete(id)
            this.operationResult = 'delete'
            this.selectedView = null
        } catch (error) {
            this.operationResult = 'error'
        }
        await this.getStoredViews(this.parentLevel)
        this.showIndicator = true
      }
    },
    getViewShortName (s) {
      const breakpoint = this.$vuetify.breakpoint
      var max = 0
      if (breakpoint.xl) {
        max = 40
      } else if (breakpoint.lg) {
        max = 20
      } else if (breakpoint.md) {
        max = 10
      } else if (breakpoint.sm) {
        max = 5
      } else if (breakpoint.xs) {
        max = 2
      }

      if (s.length > max) {
        return s.substring(0, max) + '...'
      }
      return s
    },
    getFieldType (field) {
      if (!field) return null;

      let type = null
      switch (field.name) {
        case "tags":
          type = "chipField"
          break;
      }
      return type
    },
    updateTabLocation () {
      // Initialize first tab to be open
      if (this.$router.history.current.name === 'contractlayers') {
        if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')) {
            this.$router.push({ name: 'contractlayers.renting_out'})
        } else if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
            this.$router.push({ name: 'contractlayers.renting_in'})
        } else {
          this.$router.push({ name: 'contractlayers.dashboard'})
        }
      }

      if (this.tabLocation === 'contractlayers.renting_out' && !this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')) {
        if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
          this.$router.push({ name: 'contractlayers.renting_in'})
        } else {
          this.$router.push({ name: 'contractlayers.dashboard'})
        }
      } else if (this.tabLocation === 'contractlayers.renting_in' && !this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')) {
        if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS')) {
          this.$router.push({ name: 'contractlayers.renting_out'})
        } else {
          this.$router.push({ name: 'contractlayers.dashboard'})
        }
      }

      this.tabLocation = this.$router.history.current.name
    }
  }
}
</script>
<style scoped>
.combobox >>> .v-select__selections {
  overflow: hidden;
  flex-wrap: nowrap;
}
.stateText {
  overflow: hidden;
  white-space: nowrap;
  min-width: fit-content;
}
.storedViewSelection {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: clip;
  max-width: 100%;
  background-color: "blue";
}
.viewSelection {
  width: 25%;
}

@media screen and (max-width: 600px) {
  .viewSelection {
    width: 100%;
    padding-right: 5px;
  }
}
</style>