import { i18n } from '../plugins/i18n.js'

export default {
  translateAndSortLabels (defs) {
    // defs is an array of objects with id and label keys
    if (!defs) {
      return []
    }
    defs.forEach(u => {
      u.label = i18n.t(u.label); // translate keys
    })
    return defs.sort((a, b) => a.label.localeCompare(b.label)) // sort by label
  },
}