<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ $t('guarantees.newDeliver') }}
    </template>
    <template #content>
      <v-row class="my-2">
        <v-col>       
          <CurrencyField
            :value="deliverySum"
            :label="$t('Sum')"
            :currency="selectedCurrency"
            :decimals="2"
            type="number"
            @change="onDeliverySumChanged"
          />
        </v-col>
        <v-col>
          <DatePicker
            :label="$t('GuaranteeDateOfDelivery')"
            :initial-date="dateOfDelivery"
            class="date-picker"
            @dateupdated="dateOfDeliveryUpdated"
          />
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <v-btn
        rounded
        depressed
        color="primary"
        @click="onAddGuaranteeDelivery"
      >
        {{ addButtonText }}
      </v-btn>
    </template>
  </Basemodal>
</template>
<script>
import BaseModal from '../../../general/BaseModal.vue'
import DatePicker from '../../../general/DatePicker.vue'
import CurrencyField from '../../../CurrencyField.vue'

export default {
  emits: ['close', 'guaranteeDeliveryUpdated', 'guaranteeDeliveryAdded'],
  name: "GuaranteeDeliveryModal",
  components: {
      BaseModal,
      DatePicker,
      CurrencyField,
  },
  props: {
    delivery: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
      deliverySum: undefined,
      dateOfDelivery: undefined,
    }
  },
  computed: {
    addButtonText () {
      return this.delivery ? this.$t('GuaranteeSave') : this.$t('GuaranteeAdd')
    },
  },
  watch: {
    visible () {
      if (this.visible) {
        this.copyDataFromDelivery()
      }
    },
    delivery () {
      this.copyDataFromDelivery()
    },
  },
  methods: {
    copyDataFromDelivery () {
      if (this.delivery) {
        this.deliverySum = this.delivery.deliverySum
        this.dateOfDelivery = this.delivery.dateOfDelivery
      }
    },
    clear () {
      this.deliverySum = undefined
      this.dateOfDelivery = undefined
    },
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
        this.clear()
      }
    },
    dateOfDeliveryUpdated (val) {
      this.dateOfDelivery = val
    },
    async onAddGuaranteeDelivery () {
      const modifiedGuaranteeDelivery = {
          id: this.delivery?.id,
          deliverySum: Number(this.deliverySum),
          dateOfDelivery: this.dateOfDelivery,
      }

      if (this.delivery) {
        this.$emit('guaranteeDeliveryUpdated', modifiedGuaranteeDelivery)
        this.clear()
      } else {
        this.$emit('guaranteeDeliveryAdded', modifiedGuaranteeDelivery)
        this.clear()
      }
    },
    onDeliverySumChanged (value) {
      this.deliverySum = value
    },
  }
}
</script>
<style scoped>
.date-picker >>> .form-field {
  margin-top: var(--r-spacing-xxs) !important;
}
</style>
