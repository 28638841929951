
import Vue from 'vue'
import vuetify from './plugins/vuetify.js'
import App from './App.vue'
import router from './router.js'
import store from './store/store.js'
import rambollfmapi from './api/vue-rambollfm.js'
import jsonpatch from './api/jsonpatch.js'
import axios, { AxiosError } from 'axios'
import logger from './plugins/logger.js'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import { i18n } from './plugins/i18n.js' // asynchronous translation loading disabled
import 'typeface-roboto/index.css';

// Manage meta data
Vue.use(VueMeta)

Vue.config.productionTip = false

// Configure logger for this project
Vue.use(logger, {
  logLevel: process.env.NODE_ENV === 'production' ? 'warn' : 'debug'
})

// Configure the global rambollfm API controller
Vue.use(rambollfmapi, {
  endpoint: process.env.VUE_APP_RAMBOLLFM_API_ENDPOINT,
  token: VueCookies.get('token')
})

// Add json patch as part of application
Vue.use(jsonpatch)

Vue.use(VueCookies)

// Store endpoint to vuex
store.dispatch('setApiEndpoint', process.env.VUE_APP_RAMBOLLFM_API_ENDPOINT)

// Add a request interceptor for custom headers
axios.interceptors.request.use(function (config) {
  // i18n
  const locale = localStorage.getItem('locale')
  if (typeof locale !== 'undefined' && locale !== null) {
    config.headers['Accept-Language'] = (locale === 'en') ? 'en; q=1.0' : locale + '; q=1.0, en; q=0.5'
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// Add jwt refresh loop
async function RefreshApiTokenLoop () {
  // Refresh token
  await RefreshApiToken()

  // Prepare the next update
  const currentDate = new Date()
  console.log('RefreshApiTokenLoop currentdate : ' , currentDate)
  const expireDate = new Date(VueCookies.get('tokenExpires'))
  console.log('RefreshApiTokenLoop expireDate : ' , expireDate)
  const expiresIn = expireDate.getTime() - currentDate.getTime()
  console.log('RefreshApiTokenLoop expiresIn : ' , expiresIn)
  setTimeout(RefreshApiTokenLoop, expiresIn - 10000)
}

async function RefreshApiToken () {
  const refreshAttempt = VueCookies.get('refreshAttempt')
  console.log('Refreshtoken Bugi logitus - refreshAttempt: ', refreshAttempt)
  const refreshToken = VueCookies.get('refreshToken')
  console.log('Refreshtoken Bugi logitus - refreshToken: ', refreshToken)
  const tokenExpires = VueCookies.get('tokenExpires')
  console.log('Refreshtoken Bugi logitus - tokenExpires: ', tokenExpires)
  // We had a failure and the token is no longer valid. Time to refresh
  if (new Date(tokenExpires) <= new Date(new Date().getTime() + 10000)) {
    // Remove the interceptor to prevent a loop in case token refresh also causes the 401
    // axios.interceptors.response.eject(interceptorId)

    // Make sure refresh tokens exist and there are no existing queries trying to ret
    if (typeof refreshToken !== 'undefined' && refreshToken !== null && refreshAttempt === null) {
      // We are now attempting to refresh the token
      window.localStorage.setItem('refreshAttempt', 'running')
      const result = await Vue.api.accounts.refresh(refreshToken).post()
      console.log('RefreshApiToken result : ', result)
      window.localStorage.removeItem('refreshAttempt')
      // Request is done and is successful
      // Save the token to local storage
      VueCookies.set('token', result.token, new Date(new Date().getTime() + 60 * 60 * 1000), null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      VueCookies.set('tokenExpires', result.expiration, '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
      console.log('VueCookies.set(token, : ', result.token)
      console.log('VueCookies.set(tokenExpires : ', result.expiration)
      // Save the token to Vuex
      console.log('Save the token, setApiToken : ' , result.token)
      store.dispatch('setApiToken', result.token)

      // Save the token to fambollfmapi
      Vue.api.accessToken = result.token
    }
  }

  if (refreshToken === null && tokenExpires == null) {
    return false;
  }

  return true;
}

// If queries fail then throw the user to login screen
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    // Authentication error happened

    // If the query was for whitelisted endpoint, skip the redirect
    if (error.config.url.endsWith('accounts/userinfo')) {
      return error;
    }

    if (typeof error.response !== 'undefined' && error.response.status === 401) {
      // Most likely the refresh token is expired. Move to login!
      window.localStorage.setItem('refreshAttempt', 'failed')
      if (VueCookies.isKey('token')) {
        VueCookies.remove('token')
      }
      if (VueCookies.isKey('tokenExpires')) {
        VueCookies.remove('tokenExpires')
      }
      if (VueCookies.isKey('refreshToken')) {
        VueCookies.remove('refreshToken')
      }
      window.localStorage.removeItem('refreshAttempt')
      if (VueCookies.isKey('v3ViewerToken')) {
        VueCookies.remove('v3ViewerToken')
      }
      window.location = '/login.html'
    }

    if (error.response.status === 403) {
      store.commit('app/setAuthorizationError', true)
    }

    return error
  } else {
    return error
  }
})

Vue.api.accounts.userinfo().then((response) => {
  if (response instanceof AxiosError) {
    Vue.loglevel.info(response)
    RefreshApiToken().then((success) => {
      if (success) {
        Vue.loglevel.info(success)
        Initialization()
      } else {
        window.location = '/login.html'
      }
    })
  } else {
    Initialization()
  }
})

async function Initialization () {
  if (window.location.pathname == '/') {
    window.location = 'login.html'
  } else {
    // Get data before creating application
    await store.dispatch('app/getUserInfo')

    // Create the application
    new Vue({
      router,
      created: async function () {
        // Handle external login
        const token = VueCookies.get('token')
        if (typeof token !== 'undefined') {
          this.$store.dispatch('setApiToken', token)
          Vue.api.accessToken = token
        }

        // Prepare the initial refresh
        let currentDate = new Date()
        console.log('initialization currentDate : ' , currentDate)
        let expireDate = new Date(VueCookies.get('tokenExpires'))
        console.log('initialization expireDate : ' , expireDate)
        let expiresIn = expireDate.getTime() - currentDate.getTime()
        console.log('initialization expiresIn : ' , expiresIn)

        if (expiresIn < 10000) {
          await RefreshApiToken()
          currentDate = new Date()
          expireDate = new Date(VueCookies.get('tokenExpires'))
          expiresIn = expireDate.getTime() - currentDate.getTime()
        }

        setTimeout(RefreshApiTokenLoop, expiresIn - 10000)
      },
      render: h => h(App),
      vuetify,
      store,
      i18n
    }).$mount('#app')
  }
}