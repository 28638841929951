<template>
  <div class="mb-6">
    <h3 class="ml-2">
      {{ $t('create_rent_contract.signature') }}
    </h3>
    <h4 class="mt-4 ml-2">
      {{ $t('create_rent_contract.select_signature_method') }}
    </h4>
    <v-radio-group
      v-model="signatureMethod"
      :disabled="isDisabled || !canUseElectronicSigning"
      mandatory
      class="form-fields mt-2 ml-2 d-inline-block"
    >
      <v-radio
        :label="$t('create_rent_contract.esignature')"
        value="eSignature"
      />
      <v-radio
        :label="$t('create_rent_contract.no_esignature')"
        value="noESignature"
      />
    </v-radio-group>

    <h4 class="ml-2 mb-2">
      {{ $t('create_rent_contract.select_signatories') }}
    </h4>

    <ValidationProvider
      v-slot="{ errors }"
      :rules="`${ ((isOutRent || (!isOutRent && isElectronicSigning)) && landlordSignatoryValue?.email === undefined) ? 'required' : ''}`"
    >
      <v-select
        v-model="landlordSignatoryValue.id"
        :items="landlordPersonParties"
        :label="$t('create_rent_contract.landlord_signatory')"
        :error-messages="!isDisabled &&
          (errors[0] || isElectronicSigningValue && (landlordSignatoryValue?.email === undefined || landlordSignatoryValue.email === '' ))
          ? $t('Required') + ' — ' + $t('create_rent_contract.error_no_email') : ''"
        :disabled="isDisabled"
        :required="isOutRent || isElectronicSigning"
        :hint="!isDisabled && (isOutRent || (!isOutRent && isElectronicSigning)) ? $t('Required') : ''"
        persistent-hint
        item-value="id"
        item-text="name"
        class="my-4 ml-2"
        @change="onDataChanged()"
      />
      <CdsAlert
        v-if="isElectronicSigningValue && showSignatoryState(landlordSignatoryValue.name, landlordSignatoryValue.signed, landlordSignatoryValue.signatoryDate)"
        size="small"
        class="mt-2 ml-6 mb-2"
      >
        {{ showSignatoryState(landlordSignatoryValue.name, landlordSignatoryValue.signed, landlordSignatoryValue.signatoryDate) }}
      </CdsAlert>
    </ValidationProvider>

    <div
      v-for="(signatory, index) in tenantSignatoriesValue"
      :key="index"
    >
      <ValidationProvider
        v-slot="{ errors }"
        :rules="`${isOutRent && index === 0 ? 'required' : ''}`"
      >
        <v-row
          align="center"
          no-gutters
        >
          <v-select
            v-model="signatory.id"
            :items="tenantPersonParties"
            :label="$t('create_rent_contract.tenant_signatory')"
            :error-messages="!isDisabled &&
              (errors[0] || isElectronicSigningValue && (signatory?.email === undefined || signatory.email === '' ))
              ? $t('Required') + ' — ' + $t('create_rent_contract.error_no_email') : ''"
            :disabled="isDisabled"
            :required="isOutRent || isElectronicSigning"
            :hint="!isDisabled && (isOutRent || (!isOutRent && isElectronicSigning)) ? $t('Required') : ''"
            persistent-hint
            item-text="name"
            item-value="id"
            class="my-4 ml-2"
            @change="onSignatoryChange(signatory.name, signatory.id, index)"
          />
          <CdsButton
            v-show="index != 0 && !isDisabled"
            type="tertiary"
            icon-left="delete"
            hide-text
            @click="removeTenantSignatory(index)"
          >
            {{ $t('create_rent_contract.remove_tenant_signatory') }}
          </CdsButton>
        </v-row>
        <CdsAlert
          v-if="isElectronicSigningValue && showSignatoryState(landlordSignatoryValue.name, landlordSignatoryValue.signed, landlordSignatoryValue.signatoryDate)"
          size="small"
          class="mt-0 ml-6 mb-0"
        >
          {{ showSignatoryState(signatory.name, signatory.signed, signatory.signatoryDate) }}
        </CdsAlert>
      </ValidationProvider>
    </div>
    <div v-if="!isDisabled">
      <CdsButton
        v-if="tenantType == 'company' || tenantSignatories.length < 2"
        :disabled="isDisabled"
        type="primary"
        icon-left="add"
        class="mt-4"
        @click="addTenantSignatory"
      >
        {{ $t('create_rent_contract.add_tenant_signatory') }}
      </CdsButton>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import humanize from '../../../../helpers/humanize'
import CdsButton from '../../../CircleDesignSystem/CdsButton.vue'
import CdsAlert from '../../../CircleDesignSystem/CdsAlert.vue'

export default {
  name: "ContractSignatories",
  components: {
    ValidationProvider,
    CdsButton,
    CdsAlert,
  },
  props: {
    tenantId: {
      type: Number,
      default: undefined
    },
    otherTenantId: {
      type: Number,
      default: undefined
    },
    landlordId: {
      type: Number,
      default: undefined
    },
    idLandlordContactPerson: {
      type: Number,
      default: undefined
    },
    landlordSignatory: {
      type: Object,
      default: () => undefined
    },
    tenantSignatories: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    tenantType: {
      type: String,
      default: undefined
    },
    allParties: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => true
    },
    firstTabTenantTypeChange: {
      type: Boolean,
      default: false
    },
    requestState: {
      type: String,
      default: null
    },
    canUseElectronicSigning: {
      type: Boolean,
      default: true
    },
    isElectronicSigning: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updated'],
  data () {
    return {
      signatureMethod: 'eSignature',
      landlordSignatoryValue: {
        id: undefined,
        name: undefined,
        email: undefined,
        signatoryDate: undefined,
        signed: false,
      },
      tenantSignatoriesValue: [
        {
          id: undefined,
          name: undefined,
          email: undefined,
          signatoryDate: undefined,
          signed: false,
        }
      ],
      landlordPersonParties: undefined,
      tenantPersonParties: undefined,
      isElectronicSigningValue: undefined,
    }
  },

  watch: {
    signatureMethod () {
      if (this.canUseElectronicSigning == false) {
        this.signatureMethod = 'noESignature'
      }
      this.isElectronicSigningValue = this.signatureMethod === 'eSignature'
      this.onDataChanged()
    },
    tenantType () {
      if (this.tenantId || this.tenantType === 'person') {
        this.getTenantPersons()
      }
    },
    firstTabTenantTypeChange () {
      if (this.firstTabTenantTypeChange) {
        this.getTenantPersons()
      }
    },
    tenantId () {
      if (this.tenantId || this.tenantType === 'person') {
        this.getTenantPersons()
      }
    },
    otherTenantId () {
      this.getTenantPersons()
    },
    landlordId () {
        if (this.landlordId) {
          this.getLandlordPersons()
        }
    },
    landlordSignatory () {
      this.updateDataFromExisting ()
    },
    allParties () {
      this.getLandlordPersons()
      this.getTenantPersons()
    },
    tenantSignatories: function () {
      this.updateDataFromExisting ()
    }
  },
  mounted () {
    this.updateDataFromExisting()
  },
  methods: {
    showSignatoryState (name, isSigned, signatoryDate) {
      if (!isSigned) {
        if (["ContractStateExpired","ContractStateSigned"].includes(this.requestState)) {
          return this.$t("Signature date unknown")
        } else if (this.requestState === "ContractStateSigningRequestSent") {
          return this.$t("Signature is waiting")
        }
        return ""
      }
      return this.$t("Signed on") + this.getHumanreadableDate(signatoryDate)
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'time')
    },
    addTenantSignatory () {
      this.tenantSignatoriesValue.push({
        id: undefined,
        name: undefined,
        email: undefined
      })
    },
    getTenantPersons () {
      if (this.tenantType === 'person') {
        this.tenantPersonParties = this.allParties.filter(party => party.id == this.tenantId || party.id == this.otherTenantId).map(
          p => ({
            'name': p.name,
            'email': p.email,
            'id': p.id,
            'isSigned': false,
            'signedDate': undefined
          })
        );
      } else {
        this.tenantPersonParties =
          this.allParties.filter(party => party.type == 1 && party.company_id == this.tenantId).
          map(p => ({
            'name': p.name,
            'email': p.email,
            'id': p.id,
            'isSigned': false,
            'signedDate': undefined
          })
        );
      }
    },
    getLandlordPersons () {
      this.landlordPersonParties =
        this.allParties.filter(party => party.type == 1 && party.company_id == this.landlordId).
        map(p => ({
          'name': p.name,
          'email': p.email,
          'id': p.id,
          'isSigned': false,
          'signedDate': undefined
        })
      );
    },
    removeTenantSignatory (index) {
      this.tenantSignatoriesValue = this.tenantSignatoriesValue.splice(index, 1)
      this.onDataChanged()
    },
    onSignatoryChange (name, id, index) {
      const signatoryData = this.allParties.filter(party => party.id == id).map(p => ({
          'name': p.name,
          'email': p.email,
          'id': p.id,
        }));
      if (this.tenantSignatoriesValue === undefined) {
        this.tenantSignatoriesValue = []
      }
      if (this.tenantSignatoriesValue.length === index) {
        this.tenantSignatoriesValue.push({})
      }
      this.tenantSignatoriesValue[index].name = signatoryData[0].name
      this.tenantSignatoriesValue[index].email = signatoryData[0].email
      this.tenantSignatoriesValue[index].id = signatoryData[0].id

      this.onDataChanged()
    },
    async updateDataFromExisting () {
      this.getTenantPersons ()
      this.getLandlordPersons ()
      this.signedDateValue = this.signedDate
      this.isElectronicSigningValue = this.isElectronicSigning
      this.signatureMethod = this.isElectronicSigningValue === true ?
        'eSignature' : 'noESignature'

      this.landlordSignatoryValue = this.landlordSignatory
      if (this.landlordSignatoryValue !== undefined) {
        let landlordData = this.allParties.filter(party => party.id === this.landlordSignatoryValue.id).map(p => (
          {
            'name': p.name,
            'email': p.email
          }))
        if (landlordData.length > 0) {
          this.landlordSignatoryValue.name = landlordData[0].name
          this.landlordSignatoryValue.email = landlordData[0].email
        }
      }
      if (this.landlordSignatoryValue !== undefined) {
        this.landlordPersonParties.
          filter(party => party.id === this.landlordSignatoryValue.id)[0] =
          this.landlordSignatoryValue
      }

      if (this.landlordSignatoryValue !== undefined) {
        this.landlordPersonParties.
          filter(party => party.id === this.landlordSignatoryValue.id)
      }

      this.tenantSignatoriesValue = this.tenantSignatories.map(s => {
        const idData = this.allParties.filter(party => party.id === s.id).map(p => (
          {
            'name': p.name,
            'email': p.email,
          }
        ))
        if (idData.length > 0) {
          s.name = idData[0].name
          s.email = idData[0].email
        }
        return s
      })
      for(let i=0; i < this.tenantSignatoriesValue.length; i++) {
        let tenantParty = this.tenantPersonParties.
            filter(party => party.id === this.tenantSignatoriesValue[i].id)
        if (tenantParty.length > 0) {
            tenantParty = tenantParty[0]
            let iParty = this.tenantPersonParties.indexOf(tenantParty)
            if (iParty > -1) {
              this.tenantPersonParties[iParty] = this.tenantSignatoriesValue[i]
            }
        }
      }
      if (this.tenantSignatoriesValue.length === 0) {
        this.tenantSignatoriesValue = [
          {
            'id': undefined,
            'name': undefined,
            'email': undefined,
            'signatoryDate': undefined,
            'signed': false,
          }
        ]
      }
      if (this.landlordSignatoryValue === undefined) {
        this.landlordSignatoryValue = {
          'id': undefined,
          'name': undefined,
          'email': undefined,
          'signatoryDate': undefined,
          'signed': false,
        }
      }
    },
    async onDataChanged () {
      this.tenantSignatoriesValue.forEach(tenantSignatory => {
        let tenantParty = this.tenantPersonParties.filter(party => party.id === tenantSignatory.id)
        if (tenantParty.length > 0) {
          tenantSignatory.name = tenantParty[0].name
          tenantSignatory.email = tenantParty[0].email
        }
      })
      let tenantsHasEmails =
         this.tenantSignatoriesValue.every(t => t.email !== null && t.email !== undefined && t.email.length > 0)
      let landlordSignatoryValueArr = []
      if (this.landlordSignatoryValue !== undefined) {
        landlordSignatoryValueArr = this.landlordPersonParties.
          filter(party => party.id === this.landlordSignatoryValue.id).
          map(party => ({
            'id': this.landlordSignatoryValue.id,
            'name': party.name,
            'email': party.email
            }))
          }
      this.landlordSignatoryValue = landlordSignatoryValueArr.length > 0 ?
        landlordSignatoryValueArr[0] : undefined
      let landlordEmail = undefined
      let landlordHasEmail = false
      if (this.landlordSignatoryValue !== undefined) {
        landlordEmail = this.landlordSignatoryValue.email;
        landlordHasEmail = landlordEmail !== undefined && landlordEmail !== null && landlordEmail.length > 0
      }
      const data = {
        landlordSignatory: this.landlordSignatoryValue,
        tenantSignatories: this.tenantSignatoriesValue,
        signedContract: this.signedContract,
        isElectronicSigning: this.isElectronicSigningValue,
        landlordHasEmail: landlordHasEmail,
        tenantsHasEmails: tenantsHasEmails,
      }
      this.$emit('updated', data)
    }
  }
}
</script>

<style scoped>
</style>