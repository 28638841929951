<template>
  <div>
    <AriaLive />
    <BaseModal
      :cancel-disabled="isReviewOngoing"
      @cancel="close()"
    >
      <template #title>
        {{ $t('StartRentReview') }}
      </template>
      <template #content>
        <v-col v-show="false">
          <v-tabs
            v-model="currentPage"
          >
            <v-tab
              v-for="page in pages"
              :key="page.id"
            />
          </v-tabs>
        </v-col>
        <v-tabs-items v-model="currentPage">
          <RentReviewSearchTab
            ref="search"
            :rent-review-types="rentReviewTypes"
            @goToRentReviews="goToRentReviews"
          />
          <RentReviewTableTab 
            :rent-reviews="rentReviews"
            :rent-reviews-lookup="rentReviewsLookup"
            :rent-review-types="rentReviewTypes"
            :loading="loading"
            :search-criteria="searchCriteria"
            @deleteRow="deleteRow"
          />
        </v-tabs-items>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <v-col
          cols="4"
          class="text-right"
        >
          <v-btn
            v-if="currentPage === 0"
            rounded
            depressed
            color="secondary"
            @click="search"
          >
            {{ $t('Continue') }}
          </v-btn>
          <v-btn
            v-if="currentPage === 1"
            text
            rounded
            outlined
            depressed
            class="button"
            :disabled="isReviewOngoing"
            @click="currentPage--"
          >
            {{ $t('Back') }}
          </v-btn>
          <v-tooltip
            v-if="currentPage === 1"
            top
          >
            <template #activator="{ on }">
              <v-btn
                :disabled="loading || isReviewOngoing"
                rounded
                depressed
                color="primary"
                @click="review"
                v-on="on"
              >
                <span v-if="loading || isReviewOngoing">
                  <v-progress-circular
                    :size="20"
                    :width="3"
                    indeterminate
                    color="grey"
                  />
                </span>
                <span v-else>{{ $t('rentReview.review') }}</span>
              </v-btn>
            </template>
            <span>{{ $t('rentReview.reviewInfo') }}</span>
          </v-tooltip>
        </v-col>
        <Alert
          :show="showIndicator"
          :result="operationResult"
          :message="indicatorMessage"
          :timeout="4000"
        />
      </template>
    </BaseModal>
  </div>
</template>
    
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import moment from 'moment'
import BaseModal from '../general/BaseModal.vue'
import AriaLive from '../AriaLive.vue'
import RentReviewSearchTab from './RentReviewSearchTab.vue'
import RentReviewTableTab from './RentReviewTableTab.vue'
import Alert from '../../components/Alert.vue'
import RentReviewPdfService from './Services/RentReviewPdfService.js'

export default {
  emits: ['close', 'save-success'],
  name: "RentReviewModal",
  components: {
    BaseModal,
    AriaLive,
    RentReviewSearchTab,
    RentReviewTableTab,
    Alert: Alert
  },
  data () {
    return {
      historyEvents:
      {
        rentReview: 11,
      },
      currentPage: 0,
      pages: [
        { id: 0 },
        { id: 1 }
      ],
      rentReviews: [],
      searchCriteria: undefined,
      loading: true,
      showIndicator: false,
      indicatorMessage: undefined,
      operationResult: '',
      isReviewOngoing: false,
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'customerLogo']),
    ...mapState('app', ['currentDate', 'buildings']),
    rentReviewTypes () {
      return this.definitionsByGroupLabel('rent_payments.rent_review')
        .filter(type => type.label === 'PaymentRentReviewIndex')
        .map(type => ({ id: type.id, label: this.$t(type.label) }))
    },
    rentReviewBasedOnDefinitions () {
      var defs = this.definitionsByGroupLabel('contract.rent_review_based_on')
        .map(def => ({ id: def.id, label: this.$t(def.label) }))
      return defs
    },
    rentReviewsLookup () {
      return this.getRentReviewLookup(this.rentReviews)
    }
  },
  watch: {
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.showIndicator = false
        }, 4000)
      }
    }
  },
  async mounted () {
    this.getCustomerLogo()
  },
  methods: {
    ...mapActions('app', ['getCustomerLogo']),
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
        this.currentPage = 0
      }
    },
    async goToRentReviews (searchCriteria) {
      this.currentPage++
      this.rentReviews = []
      if (searchCriteria) {
        this.loading = true
        var rentReviews = await this.$rambollfmapi.rentpayments.rentReviews.get(searchCriteria)
        this.rentReviews = this.translateReviewProperties(rentReviews)
        this.loading = false
        this.searchCriteria = searchCriteria
      }
    },
    translateReviewProperties (rentReviews) {
      return rentReviews.map(review => ({
        ...review,
        typeOfPayment: review.isContractPayment ? this.$t('contractPayment') : this.$t('separatePayment'),
        rentReviewType: this.rentReviewTypes.find(type => type.id === review.defRentReviewType).label,
        rentReviewBasedOn: this.rentReviewBasedOnDefinitions.find(type => type.id === review.defRentReviewBased).label,
        indexDate: review.usedIndex.month + ', ' + review.usedIndex.year + ': ' + review.usedIndex.costOfLivingIndex,
      }))
    },
    search () {
      this.$refs.search.executeSearch()
    },
    async review () {
      if (this.searchCriteria) {
        this.isReviewOngoing = true
        this.loading = true
        let contractPaymentIds = [];
        let separatePaymentIds = [];
        let reviewedContractIds = [];
        for (let i = 0; i < this.rentReviews.length; i++) {
          reviewedContractIds.push(this.rentReviews[i].idContract)
          if (this.rentReviews[i].isContractPayment) {
            contractPaymentIds.push(this.rentReviews[i].rentPaymentId)
          } else {
            separatePaymentIds.push(this.rentReviews[i].rentPaymentId)
          }
        }
        const ids = {
          contractPaymentIds,
          separatePaymentIds,
        }
        const queryParams = {
          ...this.searchCriteria,
          currentDate: new Date(),
        }

        const reviewResult = await this.$rambollfmapi.rentpayments.rentReviews.post(queryParams, ids)
        this.loading = false
        let hasError = false
        if (reviewResult.failedReviews && reviewResult.failedReviews.length > 0) {
          this.rentReviews = this.translateReviewProperties(reviewResult.failedReviews);
          hasError = true
          this.showIndicator = true
          this.operationResult = "error"
          this.indicatorMessage = this.$t('rentReview.reviewSavingFailed')
          this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
        }
        else if (reviewResult.response && reviewResult.response.status === 400) {
          hasError = true
          this.showIndicator = true
          this.operationResult = "error"
          this.indicatorMessage = this.$t('rentReview.reviewFailed')
          this.isReviewOngoing = false
          return
        }
        
        if (reviewResult?.successfulReviews?.length > 0) {
          let successfullyReviewedContractIds = reviewResult.successfulReviews.map(r => r.idContract)
          this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.rentReview, successfullyReviewedContractIds)
          await this.getRentReviewPdf(reviewResult.bundleGuid, reviewResult, hasError)
        }
      }
    },
    async getRentReviewPdf (bundleGuid, reviewResult, hasError = false) {
      const successfulReviews = this.translateReviewProperties(reviewResult.successfulReviews)
      const successfulReviewLookup = this.getRentReviewLookup(successfulReviews)

      const pdfBlob = await RentReviewPdfService.createRentReviewPdf(
        reviewResult.contracts.map(contract => ({
          rentReviews: successfulReviewLookup[contract.contractNumber],
          contract,
        })),
        this.searchCriteria.rentReviewMonth,
        this.customerLogo
      )
      
      const newDocument = {}
      const fileDate = `${moment(this.searchCriteria.rentReviewMonth).format('YYYY-MM')}`
      const fileName = `${this.$t('rentReview.increaseNoticeFilename')}_${fileDate}.pdf`
      newDocument.title = fileName
      newDocument.type = "Tarkistuskirjeet"
      newDocument.description = `Tarkistuskirjeet ${fileDate}`
      var document = await this.$rambollfmapi.documents.post(newDocument)

      const fileToUpload = new File(
        [pdfBlob],
        fileName, 
        {
            lastModified: new Date().getTime(),
            type: pdfBlob.type 
        }
      )

      await this.$rambollfmapi.documents.upload(
        document.idDocument,
        {
          fileData: fileToUpload,
          query: {
            description: document.description
          }
        }, 
        progress => {
          this.uploadProgressPercentage = progress.percentage
        }
      )
      // link document to rentReviewLetter guid
      await this.$rambollfmapi.rentpayments.rentReviews.postReviewLetter(document.idDocument, [bundleGuid])

      if (!hasError) {
        this.$emit('save-success')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
      }
      
      this.isReviewOngoing = false
    },
    deleteRow (row) {
      const index = this.rentReviews.findIndex(item => item.rentPaymentId === row.rentPaymentId)
      this.rentReviews.splice(index, 1)
    },
    getRentReviewLookup (reviews) {
      const reviewLookup = reviews.reduce((lookup, currentVal) => {
        if (!lookup[currentVal.contractNumber]) {
          lookup[currentVal.contractNumber] = []
        }
        lookup[currentVal.contractNumber].push(currentVal)
        return lookup
      }, {})
      return reviewLookup
    },
  }
}
</script>

<style scoped>
.form-headers {
  margin: 5px 5px 5px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
</style>
    