<template>
  <div>
    <v-dialog
      v-model="showTableDataDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <div>
          <v-btn
            large
            icon
            absolute
            right
            style="margin-top: 0.8em"
            @click="showTableDataDialog = false"
          >
            <v-icon>close</v-icon>
            <span class="d-sr-only">{{ $t('Close') }}</span>
          </v-btn>
          <v-card-title
            primary-title
            style="width:80%"
          >
            <h1>
              {{ tableData.title }}
            </h1>
          </v-card-title>
        </div>
        <Table
          :rows="tableData.rows"
          :headers="tableData.headers"
          :footers="createFooters"
          :footer-definitions="tableData.footers"
          :pagination.sync="viewDataOptions"
          :filter-by-costcenter="tableData.filterByCostcenter"
          @onCostCenterFilter="setCostCenterFilter"
        />
      </v-card>
    </v-dialog>
    <v-data-table
      :header-props="dataTableHeaderDefinitions"
      :headers="
        headers.map((h) => {
          return {
            text: $t(h.text),
            value: h.value,
            isDefinition: h.isDefinition,
            definitionGroupId: h.definitionGroupId
          }
        })
      "
      :items="items"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      :no-data-text="$t('No data available')"
      class="elevation-0"
      mobile-breakpoint="0"
    >
      <template
        v-slot:item="props"
      >
        <tr>
          <template v-for="header in headers">
            <HideOverflowColumn
              v-if="header.hideOverflow"
              :key="'hideOverflow' + header.value"
              :header="header"
              :item="props.item"
            />
            <td
              v-else-if="header.type === 'chipField'"
              :key="header.value"
            >
              <ChipField
                :items="props.item[header.value]"
              />
            </td>
            <td 
              v-else
              :key="header.value"
            >
              <span
                v-if="header.definitionGroupId > 0"
                :key="header.value"
                class="col-align-left"
              >
                {{ getDefinitionLabelForGroup(header.definitionGroupId , props.item[header.value]) }}
              </span>
              <span
                v-else-if="header.isDefinition === true"
                :key="header.value"
                class="col-align-left"
              >
                {{ getDefinitionLabel(props.item[header.value]) }}
              </span>
              <span
                v-else-if="header.value === 'buildingproject_number' || header.value === 'buildingproject_identifier'"
                :key="header.value"
              >
                <button
                  :class="getLinkStyle(props.item)"
                  @click="showBuildingProjectValueInformation = true; formatSelectedBuildingProject(props.item, header)"
                >
                  {{ formatData(props.item, header.value, header.format, header) }}
                </button>
              </span>
              <span
                v-else-if="header.value === 'unit_name' || header.value === 'unitName'"
                :key="header.value !== undefined ? header.value : Math.random()"
              >
                <a
                  :key="(props.item.unitId !== undefined ? props.item.unitId : props.item.id_unit !== undefined) ? 
                    props.item.unitId !== undefined ? props.item.unitId : props.item.id_unit !== undefined : Math.random()"
                  :class="getLinkStyle(props.item)"
                  @click="(props.item.unitId !== undefined ? props.item.unitId : props.item.id_unit) ? 
                    routeTo(props.item.siteId !== undefined ? props.item.siteId : props.item.id_site,
                            props.item.unitId !== undefined ? props.item.unitId : props.item.id_unit) : null"
                >
                  <div>
                    {{ escapeHtml(props.item[header.value]) }}
                  </div>
                </a>
              </span>
              <span
                v-else-if="header.value === 'external_url'"
                :key="header.value"
              >
                <a
                  :href="formatData(props.item, header.value, header.format, header)"
                  target="_blank"
                  rel="noreferrer"
                >
                  <v-icon>open_in_new</v-icon>
                  <span class="d-sr-only">{{ $t('building_projects.OpenInNewWindow') }}</span>
                </a>
              </span>
              <span
                v-else
                :key="header.value"
                :class="defaultCellClass(header)"
              >
                {{ $t(formatData(props.item, header.value, header.format, header)) }}
              </span>
              </v-row>
            </td>
          </template>
        </tr>
      </template>
      <template slot="body.append">
        <tr tabindex="0">
          <td
            v-for="(footer, idx) in getVisibleFooters(data.footers)"
            :key="idx"
            :class="{
              'col-align-right': isRightAligned(footer.format),
            }"
          >
            {{ 
              getFooterValue(
                data.items.data,
                footer.value,
                footer.text,
                footer.average,
                footer.format
              ) 
            }}
          </td>
        </tr>
      </template>
      <template 
        v-slot:footer.page-text="item"
      >
        <v-row>
          <ImportButton
            v-if="importing"
            :headers="data.headers"
            @dataUpdate="$emit('dataUpdated', $event)"
          />&nbsp;
          <v-btn
            v-if="importing"
            small
            outlined
            rounded
            :aria-label="`${$t('Export to spreadsheet')}: ${title}`"
            @click="exportAsCsv(data.headers, data.items)"
          >
            {{ $t('Export to spreadsheet') }}
          </v-btn>&nbsp;
          <AddSpaceDialog v-if="isModification && data.button === 'addSpace'" />&nbsp;
          <EndHierarchyItemDialog
            v-if="data.button === 'addUnit' && (isRightsToModifyProp === undefined || isRightsToModifyProp === true)"
            :metadata="data.items.data"
            level="unit"
            @saved="$emit('updateWidget')"
          />&nbsp;
          <AddUnitDialog
            v-if="data.button === 'addUnit' && (isRightsToAdd === undefined || isRightsToAdd === true)"
            :metadata="data.items.metadata"
            :headers="headers"
            @saved="$emit('updateWidget')"
          />&nbsp;
          <v-btn
            v-if="data.alwaysShowEdit || (isModification && hasAction)"
            :disabled="!isRightsToModify"
            small
            outlined
            rounded
            :aria-label="`${$t('Modify')}: ${title}`"
            @click="openEdit"
          >
            {{ $t('Modify') }}
          </v-btn>&nbsp;
          <v-btn
            small
            outlined
            :aria-label="`${$t('Browse')}: ${title}`"
            rounded
            @click="viewData(title, headers, data.items.data)"
          >
            {{ $t('Browse') }}
          </v-btn>
          <div
            style="padding-top: 10px;"
          >
            {{ item.pageStart }}-{{ item.pageStop }} {{ $t('of') }}
            {{ item.itemsLength.toLocaleString('fi-FI') }}   
          </div> 
        </v-row>  
      </template>
    </v-data-table>
    <v-dialog
      v-model="showBuildingProjectValueInformation"
      persistent
      max-width="1000"
    >
      <BuildingProjectValueInformation
        :building-project-id="buildingProjectId"
        :building-project="buildingProject"
        @cancel="showBuildingProjectValueInformation = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import helpers from '../../helpers'
import Table from '../Table.vue'
import ImportButton from '../../components/ImportButton.vue'
import AddSpaceDialog from '../AddSpaceDialog.vue'
import AddUnitDialog from '../AddUnitDialog.vue'
import EndHierarchyItemDialog from '../EndHierarchyItemDialog.vue'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import BuildingProjectValueInformation from '../BuildingProjectValueInformation.vue'
import HideOverflowColumn from '../../components/general/HideOverflowColumn.vue'
import ChipField from '../../components/general/ChipField.vue'

export default {
  emits: ['dataUpdated', 'updateWidget', 'view-data', 'edit'],
  components: {
    Table,
    ImportButton,
    AddSpaceDialog,
    AddUnitDialog,
    BuildingProjectValueInformation,
    EndHierarchyItemDialog,
    HideOverflowColumn,
    ChipField,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    },
    hasAction: {
      type: Boolean,
      default: true
    },
    isRightsToModifyProp: {
      type: Boolean,
      default: undefined
    },
    isRightsToAdd: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      colorAreasRed: false,
      showTableDataDialog: false,
      isRightsToModify: false,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: [],
        filterByCostcenter: false
      },
      options: {
        page: 1,
        itemsPerPage: this.data.rowsPerPage ? this.data.rowsPerPage : 5,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      viewDataOptions: {
        page: 1,
        itemsPerPage: this.data.browseRowsPerPage
          ? this.data.browseRowsPerPage
          : this.data.rowsPerPage
            ? this.data.rowsPerPage
            : 100,
        sortBy: [this.data.sortBy ? this.data.sortBy : ''],
        sortDesc: [this.data.sortDesc ? this.data.sortDesc : false],
        totalItems: 0
      },
      importing: this.data.importing ? this.data.importing : false,
      costcenters: [],
      shares: [],
      modifiedSpaces: {},
      rejectedSpaces: [],
      isModification: false,
      costCenterFilter: -1,
      showBuildingProjectValueInformation: false,
      buildingProjectId: '',
      buildingProject: null,
      buildingProjectHeaders: null,
    }
  },
  computed: {
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById', 'hasApplicationPermissionByName', 'dataTableHeaderDefinitions']),
    ...mapState('app', ['purposeZones', 'purposesOfUse']),
    items: function () {
      var avoidStringifyHeaders = this.headers
        .filter(h => h.keepDataStructure === true)
        .map(h => h.value)
      return this.data.items.data.map(item => {
        const keys = Object.keys(item)
        const thisItem = JSON.parse(JSON.stringify(item))
        keys.forEach(key => {
          if (Array.isArray(item[key]) && !avoidStringifyHeaders.includes(key)) {
            // No need to duplicate things
            const list = [... new Set(item[key]) ]
            let itemStr = ''
            // If there is more than 4 items, just show the first 4 items and add ... as the last item
            if (list.length > 4) {
              itemStr = [
                ...list.slice(0, 4).map(v => this.getValueWithUnit(v, key))].join(', ') + '...'
            } else {
              itemStr = [...list]
                .map(v => this.getValueWithUnit(v, key))
                .filter(v => v !== null && v !== '')
                .join(', ')
            }
            thisItem[key] = itemStr
          }
        })
        return thisItem
      })
    },
    headers: function () {
      const metaDataHeaders = this.data.items.metadata
      const headers = this.data.headers
      const headersObject = headers.filter(h => (metaDataHeaders[h.value] && metaDataHeaders[h.value].isShown === true) || h.alwaysVisible === true || h.isExtraField)
      return headersObject
    },
    siteId () {
      return Number(this.$route.params.id_site)
    }
  },
  watch: {
    items: function () {
      this.getShares(this.data.items.data)
      this.checkDwgsAvailability()
      
    }
  },
  mounted () {
    if (this.isRightsToModifyProp !== undefined) {
      this.isRightsToModify = this.isRightsToModifyProp
    }
    else if (this.hasApplicationPermissionByName('HUONE_MUOKKAUS')) {
      this.isRightsToModify = true
    }
    
    this.checkDwgsAvailability()
    this.getCostcenters()
    this.colorAreasRed = this.hasNonDWGSAreas()
    this.getPurposeZones()
    this.getPurposes()
    this.getShares(this.data.items.data)
  }, 
  methods: {
    ...mapActions('app', ['getPurposeZones', 'getPurposes']),
    hasNonDWGSAreas () {
      const buildingsWithDwgs = this.buildingsForCurrentSite.filter(x => x.has_dwg)
      if (buildingsWithDwgs.length > 0) {
        const buildings = buildingsWithDwgs.map(b => {
          return b.building_name
        })
        const itemBuildings = this.data.items.data.map(i => {
          if (i.building_name) {
            return i.building_name
          }
          if (i.buildingName) {
            return i.buildingName
          }
        })
        if (itemBuildings !== undefined && itemBuildings.length > 0) {
          const set = [...new Set(itemBuildings)]
          // Logic here is that if the datatable contains buildings that are not among
          // the ones that have dwgs available and thus the length of the arrays differs,
          // we return true as it means widget contains area values that have no dwgs as a base
          if (set !== undefined && set.length === buildings.length) {
            return false
          } else if (set !== undefined && set.length > buildings.length){
            return true
          }
        }
      } else {
        return true
      }
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    defaultCellClass (header) {
      return {
        'col-align-right': this.isRightAligned(header.format),
      }
    },
    async linkTo (destination, path) {
      let target = undefined
      if(destination.siteIdentifier !== undefined ){
          target = destination.siteIdentifier
      }
      else if (destination.id_site !== undefined){
        target = destination.id_site
      }
       else if (destination.siteId !== undefined){
        target = destination.siteId
      }
      if(target !== undefined){   
        if(path === 'sites.info')
        {
          this.$router.push({
          name: 'sites.info',
          params: { id_site: target}
          })
        }
        if(path === 'sites.facilitymanagement')
        { 
          let unitparam = undefined
          // dashboard link
          if(destination.unitIdentifier !== undefined ){ 
            unitparam = destination.unitIdentifier
          }
          // site link
          else if (destination.unitId !== undefined){
            unitparam = destination.unitId
          }
          let plan = await this.$rambollfmapi.sites.get(target)
          if(plan.has_dwg === true)
          {
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: target},
            query: { suite_id: unitparam}
          })}
          else
          {
            this.$router.push({
            name: 'sites.info',
            params: { id_site: target}
          })
          }
        
        }
      }
      
    },
    formatSelectedBuildingProject (bp) {
      this.buildingProjectId = bp.id_buildingproject
      // Show available fields only
      const bpArray = Object.entries(bp).map((e) => ( { header: e[0], value: e[1], }));
      this.buildingProject = this.headers.map(h => {
        let items = []
        bpArray.map(item => {
          if (h.value === item.header) {
            items.push({ ...item, format: h.format })
          }
        })
        return items
      }).flat()
    },
    setCostCenterFilter (value) {
      this.data.costCenterFilter = value
    },
    getValueWithUnit (value, header) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }
      if (header === 'general_rating') {
        return helpers.format.valueWithUnit(value, header)
      }
      if (this.siteId !== undefined && this.siteId !== null) {
        if (!isNaN(this.siteId)) {
            if (header.indexOf('area') >= 0 && this.colorAreasRed === true) {
              return helpers.format.markValueAsRed(value, header)
            } 
        }
      }
      return helpers.format.valueWithUnit(value, header)
    },
    // link exceptions
    getLinkStyle (value)
    {
      if(value.priority !== undefined)
        return null
      else
        return 'linked'
    },
    getObjectWithUnit (data, name) {
      var object = { name, data }
      return object
    },
    getVisibleFooters (footers) {
      if (this.data.shouldGenerateFooters) {
        return this.createFooterArray(footers)
      }

      const metaDataHeaders = this.data.items.metadata
      const footersObject = footers.filter(f => (metaDataHeaders[f.headerValue] && metaDataHeaders[f.headerValue].isShown === true) || f.alwaysVisible === true || f.isExtraField)
      return footersObject
    },
    getFooterValue (data, headerName, text, average, format) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }

      let value = data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (headerName === 'general_rating') {
        var totalCount = 0
        var averageRating = 0
        var sitesWithAudit = 0
        for (let i = 0; i < data.length; i++) {
          var singleRate = data[i].general_rating
          if (singleRate !== null) {
            totalCount += singleRate
            sitesWithAudit += 1
          }
        }
        averageRating = totalCount / sitesWithAudit
        if (isNaN(averageRating)) {
          averageRating = 0
          }
        value = helpers.format.formatData(averageRating, format)
        return value
      }
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      value = helpers.format.formatData(value, format)
      
      return value
    },
    viewData (title, headers) {
      // this.$root.$emit('view-data', title, headers, data)
      this.tableData = {
        title: title,
        headers: headers.map((h, idx) => {
          if (idx === 0) {
            h.width = '30%'
          }
          return h
        }),
        rows: this.data.items.data.map(data => ({
            ...data,
            status: this.$t(data.status),
          })
        ),
        footers: [this.getVisibleFooters(this.data.footers)],
        filterByCostcenter: this.data.filterByCostcenter ?? false
      }
      this.viewDataOptions = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        itemsPerPage: 100
      }
      this.showTableDataDialog = true
    },
    createFooters (headers, rows) {
      return helpers.table.createFooters(headers, rows, this.data.browseFooters, this.data.footers)
    },
    getHumanReadableDate (date) {
      return helpers.humanize.date(date, 'date')
    },
    exportAsCsv (originalHeaders, originalData) {
      const headers = [...JSON.parse(JSON.stringify(originalHeaders))]
      const data = [...JSON.parse(JSON.stringify(originalData))]

      const csvHeaders = headers.map(h => this.$t(h.text))
      const csvData = data.map(d => {
        const row = []
        headers.forEach(h => {
          let val = d[h.value]
          val = val === undefined ? '' : val
          val = this.getValueWithUnitForCsvExport(val, h.value, false)
          row.push(val)
        })
        return row
      })
      helpers.csv.export(csvHeaders, csvData)
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, encode)
      }

      if (header.includes('date')) {
        return helpers.format.valueWithUnit(value, header, encode)
      }

      if (value === null) {
        return ''
      }

      if (!isNaN(value)) {
        return value.toString().replace('.', ',')
      }

      return value
    },
    getTruncatedStringFromArray (value, header, encode) {
      // No need to duplicate things
      const list = new Set(value)
      let itemStr = ''
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      if (list.length > 4) {
        itemStr = [
          ...list
            .slice(0, 4)
            .map(v => this.getValueWithUnit(v, header, encode)),
          '...'
        ].join(', ')
      } else {
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header, encode))
          .filter(v => v !== null && v !== '')
          .join(', ')
      }

      return itemStr
    },
    formatData (row, header, format, headerObject) {
      if (headerObject.isPurposeZone === true) {
        return this.getPurposeZone(row[header])
      } else if (headerObject.isPurposeOfUse === true) {
          return this.getPurposeName(row[header])
      } else if (headerObject.isExtraField === true) {
         var costcenterWithShares = this.getCorrectCostCenter(row)
         return costcenterWithShares
      } else {
        return helpers.format.formatData(row[header], format)
      }
    },
    getCostcenters () {
      this.$rambollfmapi.organizations
        .costCenters()
        .list()
        .then(res => {
          this.costcenters = res.map(cc => {
            return {
              id: cc.id,
              name: cc.code + ' - ' + cc.name,
              allocation_rule: cc.allocation_rule,
              code: cc.code
            }
          })
        })
    },
    getDefinitionLabel (id) {
      return this.definitionLabelById(id)
    },
    getDefinitionLabelForGroup (groupId, definitionId) {
      const definitions = this.getDefinitionOptionsById(groupId)
      const definition = definitions.find(
        d => Number(d.id) === Number(definitionId)
      )
      if (definition) {
        return definition.label
      } else {
        return ''
      }
    },
    getDefinitionOptionsById (definitionId, isRequired) {
      if (definitionId === 0) {
        return []
      }
      var defs = this.definitionsByGroupId(definitionId).map(d => {
        return {
          id: d.id,
          annotationGroup: d.annotationGroup,
          label: this.$t(d.label)
        }
      })

      if (isRequired === false) {
        defs.unshift({
          id: null,
          annotationGroup: null,
          label: this.$t('undefined')
        })
      }

      return defs
    },
    getPurposeZone (id) {
      var zone = ''
      if (this.purposeZones.length > 0) { 
        var zoneObject = this.purposeZones.find(pz => pz.id === id)
        if (typeof zoneObject !== 'undefined' && typeof zoneObject.name !== 'undefined') {
          zone = zoneObject.name
        }
        return zone
      } else {
        return zone
      }
    },
    getPurposeName (id) {
      var purposeOfUse = ''
      if (id > 0) { 
        if (this.purposesOfUse.length > 0) { 
          var pouObject = this.purposesOfUse.find(pz => pz.id === id)
          if (typeof pouObject !== 'undefined' && typeof pouObject.title !== 'undefined') {
            purposeOfUse = this.purposesOfUse.find(x => x.id === id).title
          }
          return purposeOfUse
        } else {
          return purposeOfUse
        }
      }
    },
    openEdit () {
      this.$emit('edit')
    },
    async getShares (spaces){
      if (!spaces[0]?.id) return

      this.startDate = moment(this.$store.state.app.currentDate).format(
          'YYYY-MM-DD'
        )

        const oldModifications = JSON.parse(JSON.stringify(this.modifiedSpaces))
        this.modifiedSpaces = {}

        this.shares = await this.$rambollfmapi.internalrent
          .shares()
          .list(spaces.map(x => x.id), this.$store.state.app.currentDate) 

        this.rejectedSpaces = []
        Object.keys(oldModifications).filter(key => !spaces.find(s => s.id.toString() === key)).forEach(key => {
          if (Object.keys(oldModifications[key]).find(header => header !== 'shares' && header !== 'name' && oldModifications[key][header])) {
            this.rejectedSpaces.push(oldModifications[key].name)
          }
        })

        spaces.forEach(element => {
          if (oldModifications && oldModifications[element.id]){
            // if we already have unsaved modifications for selected space use those
            this.$set(this.modifiedSpaces, element.id, oldModifications[element.id])
          }
            // otherwise set modifications for space empty
            this.$set(this.modifiedSpaces, element.id, { name: element.name })
            
            if (
              this.hasApplicationPermissionByName('SISAINENVUOKRAUS')
            ) {
              const sharesForSpace = this.shares.filter(x => {
                return x.idSpace === element.id
              })
              this.$set(
                this.modifiedSpaces[element.id],
                'shares',
                JSON.parse(JSON.stringify(sharesForSpace))
                )
                element.shares = sharesForSpace
              if (
                this.hasApplicationPermissionByName(
                  'HUONE_HENKILO_KPAIKKA_LISAYS'
                )
              ) {
                if (sharesForSpace.length === 1) {
                  const costcenter = this.costcenters.find(
                    cc => cc.id === sharesForSpace[0].idCostcenter
                  )
                  if (costcenter !== undefined) {
                    this.$set(
                      this.modifiedSpaces[element.id],
                     'costcenterInsteadOfUser',
                      costcenter.id
                    )
                  }
                }
              }
          }
        })
    },
    getCorrectCostCenter (space) {
      let cc = ''
      let centers = []

      if (this.shares !== null) {
        centers = []
        const shares = this.shares.filter(share => share.idSpace === space.id)

        var remainingSharePercentage = 100
        var countWithoutShare = 0
        shares.forEach(share => {
          if (share.share === null) {
            countWithoutShare += 1
          } else {
            remainingSharePercentage -= share.share
          }
        })
        const percentagePerShare = remainingSharePercentage > 0 ? (remainingSharePercentage / countWithoutShare) : 0
        shares.forEach(share => {
          const costcenter = this.costcenters.find(cc => cc.id === share.idCostcenter)
          if (typeof costcenter !== 'undefined') {
            centers.push(costcenter.name.split(' - ')[1] + ' ' + (share.share != null ? share.share : percentagePerShare).toFixed(2) + '%')
          }
        })
      }
      if (Array.isArray(centers)) {
          const list = centers
            let itemStr = ''
            // If there is more than 4 items, just show the first 4 items and add ... as the last item
            if (list.length > 4) {
              itemStr = [
                ...list.slice(0, 4).map(v => this.getValueWithUnit(v, centers))].join(', ') + '...'
            } else {
              itemStr = [...list]
                .map(v => this.getValueWithUnit(v, centers))
                .filter(v => v !== null && v !== '')
                .join(', ')
          }
          cc = itemStr
          cc= cc.replace('&gt;','>')
      }
      return cc
    },
    checkDwgsAvailability () {
      let isModificationAvailableArr = []
      for (let i = 0; i < this.buildingsForCurrentSite.length; i++) {
        let building  = this.buildingsForCurrentSite[i]
        if (!building.has_dwg) {
          isModificationAvailableArr.push(building)
        }
      }
      if (isModificationAvailableArr.length > 0) {
        this.isModification = true
      }
    },
    escapeHtml (unitName) {
      return helpers.format.escapeHtml(unitName)
    },
    async routeTo (siteId, unitId) {
      let plan = await this.$rambollfmapi.sites.get(siteId)
      plan.has_dwg ?
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: siteId },
            query: { suite_id: unitId }
          }) :
          this.$router.push({
            name: 'sites.info',
            params: { id_site: siteId }
          })
    },
    createFooterArray (footers) {
      let footerMap = new Map(footers.map(footer => [ footer.value, footer ]))
      let footerArray = [ { text: this.$t('Total'), alwaysVisible: true } ]
      for (let i = 1; i < this.headers.length; i++) {
        const footer = footerMap.get(this.headers[i].value) ?? {};
        footer.headerValue = this.headers[i].value
        footer.alwaysVisible = true
        footerArray.push(footer)
      }
      return footerArray
    },
  }
}
</script>
<style scoped>
.icon_padding {
  padding-right: 0.5em;
}
</style>