import RambollFMAPI from '../api/rambollfm.js'
import { defaultGoals as allDefaultGoals } from '../constants/leasing/situationAndTarget.js'

export default {
  namespaced: true,
  state: {
    apiToken: null,
    apiEndpoint: null,
    // Mostly we want to find which goal belongs to which site. For faster lookups let's use Map. If we need a list we
    // can just transform this Map to list (or even iterate over it using forEach). Values are grouped by site id.
    goals: new Map(),
    goalsLoading: false,
    goalsSaving: false
  },
  getters: {
    getDefaultGoals (){
      return { 
        id_site: null,
        ...allDefaultGoals
      }
    }
  },
  mutations: {
    setGoals (state, goals) {
      state.goals = goals
      state.goalsLoading = false
    },
    setGoalsLoading (state, loading) {
      state.goalsLoading = loading
    },
    setGoalsSaving (state, saving){
      state.goalsSaving = saving
    }
  },
  actions: {
    async fetchAllGoals ({ commit, state }, time) {
      commit('setGoalsLoading', true)
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const goals = await api.rentingGoals.list(time)

      const goalsMap = goals.reduce((acc, goal) => {
        if (!acc.has(goal.id_site)) {
          acc.set(goal.id_site, goal)
        }
        return acc
      }, new Map())

      commit('setGoals', goalsMap)
    },
    async updateAllGoals ({ commit, state }, params){
      const { modifiedGoals, time } = params

      commit("setGoalsSaving", true)
      
      // Let's determine which goals have been changed and do the necessary operation POST, PUT, DELETE.
      // Goals which exists in modifiedGoals, but does not exist in state.goals
      const postGoals = []
      // Goals which exists in modifiedGoals and exists in state.goals
      const putGoals = []
      
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      Object.values(modifiedGoals).forEach(goal => {
        if(!goal.shouldUpdate){
          return
        }

        const stateGoal = state.goals.get(goal.id_site)
        // So this goal already exists. Let's modify it.
        if(stateGoal){
          putGoals.push(goal)
          return
        }
        // Otherwise let's create new one.
        postGoals.push(goal)
      })

      // Create necessary api requests and run them async.
      const promises = []

      if(postGoals.length > 0){
        promises.push(api.rentingGoals.create(postGoals, time))
      }

      if(putGoals.length > 0){
        promises.push(api.rentingGoals.modify(putGoals, time))
      }
      
      await Promise.all(promises)
      
      commit("setGoalsSaving", false)
    }
  },
}
