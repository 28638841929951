import moment from 'moment'

export function getMarketRent (rs) {
  let area = 0
  let marketRent = 0

  if (rs.area) {
    area = rs.area
  }

  if (rs.market_rent) {
    marketRent = rs.market_rent
  }

  if (rs.contractNumber) {
    return rs.capitalRent
  }

  return area * marketRent
}

export function calculateTotal (data, type = {}, coefficiency = 1) {
  // Use market rent as sum value
  if (!data) {
    return null
  }
  const { value } = type

  if (value === 'marketRent') {
    return data.reduce((acc, cur) => {
      let val = 0
      if (cur.rentalStatus) {
        val = getMarketRent(cur.rentalStatus)
      } else if (cur.prospect) {
        val = cur.prospect.unit_area_preference // Tässä pitäisi olla joku hinta???????????
      }
      // If weight is set, let's use it. This is used for example based on certain contract type or prospect likelihood.
      if (cur.weight || cur.weight === 0) {
        val = val * cur.weight
      }

      return acc + val
    }, 0) * coefficiency
  }

  // Otherwise use area....
  return data.reduce((acc, cur) => {
    let val = 0

    if (cur.rentalStatus) {
      val = cur.rentalStatus.area || 0
    } else if (cur.prospect) {
      val = cur.prospect.area_preference
    }
    // If weight is set, let's use it. This is used for example based on certain contract type or prospect likelihood.
    if (cur.weight || cur.weight === 0) {
      val = val * cur.weight
    }

    return acc + val
  }, 0) * coefficiency
}

export function calculateConfirmedTotal (data, type = {}) {
  // Use market rent as sum value
  if (!data) {
    return null
  }
  const { value } = type

  const contracts = data.reduce((acc, cur) => {
    if (!cur.rentalStatus) {
      return acc
    }
    if(!acc[cur.rentalStatus.contractNumber]){
      acc[cur.rentalStatus.contractNumber] = {
        units: 1
      }
    } else{
      acc[cur.rentalStatus.contractNumber].units += 1
    }

    return acc
  }, {})

  const calculatedValues = data.reduce((acc, cur) => {

   if(!cur.prospect){
      return acc
    }

    const totalUnits = cur.prospect.contract_numbers.reduce((total, contract_number) => {
      const contract = contracts[contract_number]
      if(contract && contract.units){
        return total + contract.units
      }
      return  total
    }, 0)
    let prospectEuros = cur.prospect.contract_info.reduce((sum, contract) => sum + contract.capitalRent, 0)
    let prospectArea = cur.prospect.contract_info.reduce((sum, contract) => sum + contract.reportableArea, 0)

    acc.euros += prospectEuros / totalUnits
    acc.area += prospectArea / totalUnits
    return acc
  }, { euros: 0, area: 0})

  if (value === 'marketRent') {
    return calculatedValues.euros
  }

  // Otherwise use area....
  return calculatedValues.area
}

export function calculateNetTotals (data, group, set, currentDate) {
  const endingTotal = data.find((d) => d.type === 'ending_total')
  // Rental type net totals are calculated bit differently when we show only new contracts..
  if (group === 'rentalType' && set === 'new') {
    const newTotal = data.find((d) => d.type === 'new')

    const { confirmed: totalConfirmed = 0 } = newTotal
    const { confirmed: endingTotalConfirmed } = endingTotal

    const confirmed = totalConfirmed - endingTotalConfirmed

    return { confirmed }
  }

  // Target percentage from start of year
  // Dates needed
  const startOfYear = new Date(moment(currentDate).startOf('year'))
  const endOfYear = new Date(moment(currentDate).endOf('year'))

  const targetSoFarPercentage =
    (currentDate - startOfYear) / (endOfYear - startOfYear)

  const total = data.find((d) => d.type === 'total')

  const { confirmed: totalConfirmed = 0, target } = total
  const { confirmed: endingTotalConfirmed = 0 } = endingTotal

  const confirmed = totalConfirmed - endingTotalConfirmed
  const targetSoFar = targetSoFarPercentage * target
  const color = confirmed >= targetSoFar ? '#0dc526' : '#ff0000'
  const currentFree = null

  return {
    confirmed,
    color,
    currentFree,
  }
}

export function calculateGraphNetTotals (data, group, set, currentDate) {
  // Target percentage from start of year
  // Dates needed
  const startOfYear = new Date(moment(currentDate).startOf('year'))
  const endOfYear = new Date(moment(currentDate).endOf('year'))

  const targetSoFarPercentage =
    (currentDate - startOfYear) / (endOfYear - startOfYear)

  const endingTotalConfirmed = calculateTotal(
    data.ending_total.confirmed,
    group
  )

  if (group.value === 'rentalType' && set.value === 'new') {
    const totalConfirmed = calculateTotal(data.new.confirmed, group)

    const confirmed = totalConfirmed - endingTotalConfirmed
    const color = confirmed >= targetSoFar ? '#0dc526' : '#ff0000'

    return { confirmed, color }
  }

  const totalConfirmed = calculateTotal(data.total.confirmed, group)
  const target = calculateTotal(data.total.target, group)

  const confirmed = totalConfirmed - endingTotalConfirmed
  const targetSoFar = targetSoFarPercentage * target
  const color = confirmed >= targetSoFar ? '#0dc526' : '#ff0000'
  const currentFree = null

  return {
    confirmed,
    color,
    currentFree,
  }
}

export function calculateConfirmedEndedTotal (data) {

  if (!data) {
    return null
  }

  // All rentalStatuses and carparkContracts connected to the same contractNumber have the same reportable_area, so it needs to be added only once.
  const processedContractNumbers = []
  return data.reduce((acc, cur) => {

    let contractObject = null
    let val = 0

    if (cur.rentalStatus) {
      contractObject = {
        contractNumber: cur.rentalStatus.contractNumber,
        reportableArea: cur.rentalStatus.reportable_area,
        reportableEnding: cur.rentalStatus.reportable_ending,
        area: cur.rentalStatus.area || 0
      }
    } else if (cur.carparkContract) {
      contractObject = {
        contractNumber: cur.carparkContract.contract_number,
        reportableArea: cur.carparkContract.reportable_area,
        reportableEnding: cur.carparkContract.reportable_ending,
        contractArea: cur.carparkContract.contract_area || 0
      }
    }
    if (contractObject) {
      const { reportableEnding, reportableArea, area, contractNumber, contractArea } = contractObject

      if (reportableEnding === false) {
        val = 0
      } else if (reportableArea === null && area) {
        val = area
        processedContractNumbers.push(contractNumber)
      } else if (reportableArea === null && contractArea && !processedContractNumbers.includes(contractNumber)) {
        val = contractArea
        processedContractNumbers.push(contractNumber)
      } else if (reportableArea !== null && !processedContractNumbers.includes(contractNumber)) {
        val = reportableArea
        processedContractNumbers.push(contractNumber)
      }
    }
    return acc + val
  }, 0)
}