<template>
  <v-row
    id="building"
  >
    <v-col
      v-if="isLoading"
      style="text-align: center; padding: 100px"
    >
      <div
        class="text-h5"
        style="margin-bottom: 2em"
      >
        {{ $t('Downloading. Wait a second...') }}
      </div>
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </v-col>

    <v-col
      v-if="!isLoading"
      cols="3"
    >
      <v-carousel height="300">
        <v-carousel-item :src="imgUrl" />
      </v-carousel>
      <Map
        class="map"
        map-id="building-map"
        :center="mapCenter"
        :center-projection="mapProjection"
        :maps="mapLayers"
        :zoom="mapZoom"
        :marker="mapMarker"
      />
      <PieChart
        :data="rentalPercentageGraphData"
        :options="pieOptions"
        style="height: 200px"
      />
    </v-col>

    <v-col
      v-if="!isLoading"
      cols="3"
    >
      <v-list
        v-if="buildingData"
        dense
        class="info-box"
      >
        <v-list-item class="tile">
          <v-list-item-content class="tile-content">
            {{ buildingData.name }}
          </v-list-item-content>
          <v-list-item-content class="tile-content tile-content-icon">
            <v-btn
              icon
              small
              @click="show.buildingInfo = !show.buildingInfo"
            >
              <v-icon v-if="!show.buildingInfo">
                keyboard_arrow_down
              </v-icon>
              <v-icon v-else>
                keyboard_arrow_up
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="show.buildingInfo"
          class="tile"
        >
          <v-list-item-content class="tile-content">
            {{ buildingData.use }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="show.buildingInfo"
          class="tile"
        >
          <v-list-item-content class="tile-content">
            {{ $t('Facility area') }}
          </v-list-item-content>
          <v-list-item-content class="tile-content">
            {{ buildingData.gross_area }} m&sup2;
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="show.buildingInfo"
          class="tile"
        >
          <v-list-item-content class="tile-content">
            {{ $t('Spaces owned by KOY') }}
          </v-list-item-content>
          <v-list-item-content
            class="tile-content"
          >
            {{ Number(buildingData.gross_area) - Number(buildingData.leasable_area) }} m&sup2;
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="show.buildingInfo"
          class="tile"
        >
          <v-list-item-content class="tile-content">
            {{ $t('Rentable overall area') }}
          </v-list-item-content>
          <v-list-item-content class="tile-content">
            {{ buildingData.leasable_area }} m&sup2;
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="show.buildingInfo"
          class="tile"
        >
          <v-list-item-content class="tile-content">
            {{ $t('Free rentable area') }}
          </v-list-item-content>
          <v-list-item-content
            class="tile-content"
          >
            {{ rentStatus.length > 0 ? rentStatus[0].leasable_area.toFixed(0) : '' }} m&sup2;
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="tile">
          <v-list-item-content class="tile-content">
            {{ $t('Headcount') }}
          </v-list-item-content>
          <v-list-item-content class="tile-content">
            {{ persons.length }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        v-if="contractData"
        dense
        class="info-box"
      >
        <v-list-item class="tile">
          <v-list-item-content class="tile-content">
            {{ $t('Tenants') }}
          </v-list-item-content>
          <v-list-item-content class="tile-content tile-content-icon">
            <v-btn
              icon
              small
              @click="show.tenantInfo = !show.tenantInfo"
            >
              <v-icon v-if="!show.tenantInfo">
                keyboard_arrow_down
              </v-icon>
              <v-icon v-else>
                keyboard_arrow_up
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <template v-if="show.tenantInfo">
          <v-list-item
            v-for="contract in contractData"
            :key="contract.id"
            class="tile"
          >
            <v-list-item-content class="tile-content">
              {{ getContractInfoString(contract) }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
    <v-col
      v-if="!isLoading"
      cols="6"
    >
      <v-expansion-panels>
        <v-expansion-panel
          :value="[emptySpacesWithContracts !== null]"
          class="no-padding"
          expand
        >
          <v-expansion-panel-content v-if="buildingData !== null">
            <div slot="header">
              {{ $t('Target strategy') }}
            </div>
            <v-textarea
              v-model="buildingData.comment"
              @change="saveComment"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <div style="height: 1em" />
        <!-- tables -->
        <v-expansion-panel
          :value="[emptySpacesWithContracts !== null]"
          class="no-padding"
          expand
        >
          <v-expansion-panel-content>
            <div slot="header">
              {{ $t('Empty rental spaces') }}
            </div>

            <v-data-table
              v-if="emptySpacesWithContracts !== null"
              :header-props="dataTableHeaderDefinitions"
              :headers="emptySpacesDataHeaders"
              :items="emptySpacesWithContracts"
              class="elevation-0"
            >
              <template
                v-slot:item="props"
              >
                <td
                  v-for="header in emptySpacesDataHeaders"
                  :key="header.value"
                  v-html="Sanitize(getValueWithUnit(props.item[header.value], header.value))"
                />
              </template>
            </v-data-table>
          <!--<div class="text-xs-right">
            <v-btn icon flat small><v-icon>keyboard_arrow_left</v-icon></v-btn>
            <v-btn icon flat small class="pagination_page">1</v-btn>
            <v-btn icon flat small class="pagination_page">2</v-btn>
            <v-btn icon flat small><v-icon>keyboard_arrow_right</v-icon></v-btn>
          </div>-->
          </v-expansion-panel-content>
        </v-expansion-panel>
        <div style="height: 1em" />
        <v-expansion-panel
          :value="[emptySpacesWithContracts !== null]"
          class="no-padding"
          expand
        >
          <v-expansion-panel-content>
            <div slot="header">
              {{ $t('Ending contracts') }}
            </div>

            <v-data-table
              :header-props="dataTableHeaderDefinitions"
              :headers="contractDataHeaders"
              :items="contracts"
              class="elevation-0"
            >
              <template
                v-slot:item="props"
              >
                <td
                  v-for="header in contractDataHeaders"
                  :key="header.value"
                  v-html="Sanitize(getValueWithUnit(props.item[header.value], header.value))"
                />
              <!-- <td>{{ props.item.type }}</td>
              <td>{{ props.item.space_name }}</td>
              <td>{{ props.item.area }}</td>
              <td>{{ props.item.end_date}}</td>
              <td>{{ props.item.tenants.length > 0 ? props.item.tenants[0].name : ''}}</td>-->
              </template>
              <template slot="no-data">
                {{ $t('No ending contracts') }}
              </template>
            </v-data-table>
          <!--<div class="text-xs-right">
            <v-btn icon flat small><v-icon>keyboard_arrow_left</v-icon></v-btn>
            <v-btn icon flat small class="pagination_page">1</v-btn>
            <v-btn icon flat small class="pagination_page">2</v-btn>
            <v-btn icon flat small><v-icon>keyboard_arrow_right</v-icon></v-btn>
          </div>-->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
import PieChart from '../components/PieChart.vue'
import Map from '../components/Map.vue'
import helpers from '../helpers'
import { mapGetters } from 'vuex'
export default {
  name: 'Building',
  components: {
    Map,
    PieChart
  },
  data () {
    return {
      mapProjection: 'EPSG:3857',
      mapCenter: [2807128, 9638898],
      mapZoom: 17,
      mapLayers: [
        {
          name: 'Main map',
          title: 'Main map',
          type: 'OSM',
          opacity: 1
        }
      ],
      mapMarker: null,
      buildingData: null,
      contractData: null,
      show: {
        buildingInfo: true,
        tenantInfo: true
      },
      pieOptions: {
        aspectRatio: 3,
        maintainAspectRatio: false,
        responsive: true,
        responsiveAnimationDuration: 1,
        legend: {
          labels: {
            fontColor: 'black',
            fontSize: 18,
            boxWidth: 20
          },
          position: 'right'
        },
        layout: {
          padding: {
            top: 20,
            left: 0,
            right: 0,
            bottom: 20
          }
        },
        cutoutPercentage: 40
      },
      emptySpacesWithContracts: null,
      emptySpacesDataHeaders: [
        { text: this.$t('Type'), value: 'type' },
        { text: this.$t('Facility'), value: 'space_name' },
        { text: this.$t('Gross area'), value: 'area' },
        { text: 'Vapautunut', value: 'end_date' },
        { text: this.$t('Previous tenant'), value: 'current_tenant' }
      ],
      contractDataHeaders: [
        { text: this.$t('Tenant'), value: 'current_tenant' },
        { text: this.$t('Spaces count'), value: 'spaces_count' },
        { text: this.$t('Gross area'), value: 'area' },
        { text: 'Vapautuu', value: 'end_date' },
        { text: this.$t('Type'), value: 'type' }
      ],
      contracts: [],

      pagination: {},
      persons: [],
      imgUrls: [
        { building_code: '1629021', src: 'https://api.rambollfm.fi/public/1629021.JPG' },
        { building_code: 'AHV04', src: 'https://api.rambollfm.fi/public/AHV04.jpg' },
        { building_code: 'gal08', src: 'https://api.rambollfm.fi/public/gal08.JPG' },
        { building_code: 'JAA33', src: 'https://api.rambollfm.fi/public/JAA33.jpg' },
        { building_code: 'KAU09', src: 'https://api.rambollfm.fi/public/KAU09.jpg' },
        { building_code: 'kir04', src: 'https://api.rambollfm.fi/public/kir04.jpg' },
        { building_code: 'KIV01', src: 'https://api.rambollfm.fi/public/KIV01.jpg' },
        { building_code: 'LAN15', src: 'https://api.rambollfm.fi/public/LAN15.jpg' },
        { building_code: 'LAS08', src: 'https://api.rambollfm.fi/public/LAS08.png' },
        { building_code: 'LI003', src: 'https://api.rambollfm.fi/public/LI003.jpg' },
        { building_code: 'NII08', src: 'https://api.rambollfm.fi/public/NII08.jpg' },
        { building_code: 'NOS04', src: 'https://api.rambollfm.fi/public/NOS04.JPG' },
        { building_code: 'OLA24', src: 'https://api.rambollfm.fi/public/OLA24.jpg' },
        { building_code: 'PAK02', src: 'https://api.rambollfm.fi/public/PAK02.jpg' },
        { building_code: 'RUU54', src: 'https://api.rambollfm.fi/public/RUU54.png' },
        { building_code: 'TOR01', src: 'https://api.rambollfm.fi/public/TOR01.JPG' },
        { building_code: 'YLI26', src: 'https://api.rambollfm.fi/public/YLI26.jpg' },
        { building_code: 'default', src: 'https://api.rambollfm.fi/public/talo3.png' }
      ],
      rentStatus: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('app', ['dataTableHeaderDefinitions']),
    rentalPercentageGraphData () {
      const leasable = this.rentStatus.length > 0 ? this.rentStatus[0].leasable_area - this.rentStatus[0].leased_area : 0
      const leased = this.rentStatus.length > 0 ? this.rentStatus[0].leased_area : 0
      return {
        labels: [this.$t('No documents available'), this.$t('Not rented'), this.$t('Rented')],
        datasets: [{
          label: 'Neuvotteluhuoneet',
          borderWidth: 0,
          backgroundColor: ['#f8a57b', '#87bab0'],
          data: [leasable, leased]
        }]
      }
    },
    imgUrl () {
      const buildingCode = this.$route.params.buildingCode

      const url = this.imgUrls.find(u => u.building_code === buildingCode)

      if (typeof url !== 'undefined') {
        this.$log.debug(url.src)
        return url.src
      }

      return this.imgUrls.find(u => u.building_code === 'default').src
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.$log.debug('route changed')
        this.updateViewData()
      }
    }
  },
  async mounted () {
    await this.updateViewData()
  },
  methods: {
    saveComment () {
      this.$rambollfmapi.buildings.patch(this.buildingData.building_code, this.buildingData.version, [{
        op: 'replace',
        path: '/comment',
        value: this.buildingData.comment
      }])
    },
    getValueWithUnit (value, header) {
      if (header.indexOf('date') >= 0) {
        const val = helpers.humanize.date(value, 'date')
        if (val === null) {
          return '-'
        }
        return val
      }

      if (header.indexOf('area') >= 0) {
        return value + ' m&sup2;'
      }

      if (header.indexOf('trend') >= 0) {
        return (value > 0 ? '+' : '') + Number(value).toFixed(0) + '%'
      }

      return value
    },
    getContractInfoString (contract) {
      let str = ''
      if (contract.tenants != null && contract.tenants.length > 0) {
        str += contract.tenants[0].name
        str += ' ' + contract.spaces.map(s => Number(s.area)).reduce((acc, cur) => acc + cur, 0) + ' m²'
      }
      return str
    },
    async updateViewData () {
      this.isLoading = true
      const buildingCode = this.$route.params.buildingCode
      this.$log.debug(buildingCode)
      this.rentStatus = await this.$rambollfmapi.rent.rentstatus().list({ query: { building: buildingCode } })
      this.buildingData = await this.$rambollfmapi.buildings.get(buildingCode)
      this.$store.commit('setCurrentBuilding', this.buildingData)
      this.persons = await this.$rambollfmapi.persons.list({ query: { building: buildingCode } })
      this.contractData = await this.$rambollfmapi.rent.contracts().list({ query: { building: buildingCode, includeSpaceData: true } })
      if (this.buildingData.coordinates !== null) {
        this.mapCenter = [this.buildingData.coordinate_x, this.buildingData.coordinate_y]
        this.mapProjection = 'EPSG:3879'
        this.mapZoom = 14

        this.mapMarker = {
          longitude: this.buildingData.coordinate_x,
          latitude: this.buildingData.coordinate_y,
          projection: 'EPSG:3879'
        }
      }
      this.emptySpacesWithContracts = this.contractData.reduce((acc, cur) => {
        const list = []

        cur.spaces.forEach(s => {
          const contract = JSON.parse(JSON.stringify(cur))
          contract.space_name = s.hnro
          contract.area = s.area
          contract.area_unit = s.unit
          contract.current_tenant = Array.isArray(contract.tenants) && contract.tenants.length > 0 ? contract.tenants[0].name : ''
          list.push(contract)
        })

        acc = [...acc, ...list]

        return acc
      }, []).filter(c => c.end_date !== null && new Date(c.end_date).getTime() < new Date().getTime())
      this.$log.debug(this.emptySpacesWithContracts)
      this.contracts = this.contractData.map(c => {
        c.gross_area = c.spaces.map(s => Number(s.area)).reduce((acc, cur) => acc + cur, 0)
        c.spaces_count = c.spaces.length
        c.current_tenant = Array.isArray(c.tenants) && c.tenants.length > 0 ? c.tenants[0].name : ''
        c.area = c.spaces.map(s => s.area).reduce((acc, cur) => acc + cur, 0)
        return c
      }).filter(c => new Date(c.end_date) > new Date(this.$store.state.app.currentDate) || c.end_date === null)
      this.isLoading = false
    },
    Sanitize (text) {
      return helpers.format.sanitize(text)
    }
  }
}
</script>
<style >
#building .info-box .tile {
  margin: 2px;
}
#building .info-box .tile .tile-content {
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
  min-width: 3em;
  border: 1px solid #ebeef8;
  border-top: 2px solid #ebeef8;
  border-bottom: 2px solid #ebeef8;
}
#building .info-box .tile .tile-content:first-child {
  border-left: 2px solid #ebeef8;
}
#building .info-box .tile .tile-content:last-child {
  border-right: 2px solid #ebeef8;
}
#building .info-box .tile:first-child .tile-content {
  margin-right: 2px;
  margin-left: 2px;
}
#building .info-box .tile .tile-content:nth-child(2) {
  flex: 0 0 7em;
  font-weight: normal;
}
#building .info-box .tile:first-child .tile-content:nth-child(2) {
  flex: 1 1 0;
}
#building .info-box .tile:first-child .tile-content:first-child {
}
#building .info-box .tile:last-child .tile-content:first-child {
  border-bottom-left-radius: 2em;
}
#building .info-box .tile:last-child .tile-content:last-child {
  border-bottom-right-radius: 2em;
}
#building .info-box .tile-content-icon {
  padding: 0 !important;
  padding-right: 0px !important;
  flex: 0 !important;
  padding-right: 3.5em !important;
}
#building .map {
  height: 300px;
}
#building .v-window.v-carousel {
  border-radius: 1rem;
  margin-top: 0.5em;
}
#building-map .ol-control {
  background-color: transparent;
}
#building-map .ol-control .ol-zoom-in {
  border-radius: 1em 1em 0 0;
  background: var(--c-color-accent);
}

#building-map .ol-control .ol-zoom-out {
  border-radius: 0 0 1em 1em;
  background: var(--c-color-accent);
}
</style>
