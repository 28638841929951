<template>
  <BaseModal
    @cancel="close"
  >
    <template
      v-if="isEditing"
      #title
    >
      {{ $t('Edit attachment') }}
    </template>
    <template
      v-else
      #title
    >
      {{ $t('Add attachment') }}
    </template>
    <template #content>
      <v-file-input
        ref="fileInput"
        v-model="file"
        class="form-field"
        type="file"
        clearable
        show-size
        truncate-length="100"
        :accept="acceptableFileTypes"
        :rules="[rules.attachmentFileInput]"
        :disabled="isEditing"
        :label="$t('File')"
        :hint="$t('Required')"
        persistent-hint
        required
      />
      <v-text-field
        v-model="attachmentName"
        class="form-field mt-8"
        clearable
        :rules="[rules.attachmentNameInput]"
        :label="$t('Attachment_name')"
        :hint="$t('Required')"
        persistent-hint
        required
      />
      <v-select
        v-model="attachmentType"
        class="form-field mt-8"
        clearable
        :items="items"
        :label="$t('Type')"
      />
      <v-checkbox
        v-model="addAttachmentToContract"
        :disabled="!isAttachmentPdf"
        :label="$t('Add file for contract only pdf')"
      />
    </template>
    <template #footer>
      <v-btn
        v-if="isEditing"
        class="blue-button"
        rounded
        depressed
        :disabled="(hasErrorAttachmentFile || hasErrorAttachmentName)"
        @click="editAttachment()"
      >
        {{ $t('Edit') }}
      </v-btn>
      <v-btn
        v-else
        class="blue-button"
        rounded
        depressed
        :disabled="(hasErrorAttachmentFile || hasErrorAttachmentName)"
        @click="addAttachment()"
      >
        {{ $t('Add') }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../../general/BaseModal.vue'
export default {
  emits: ['close', 'edit'],
  name: "RentContractAddAttachmentModal",
    components: {
      BaseModal,
    },
  props: {
    editingData: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      items: [],
      file: null,
      hasErrorAttachmentFile: true,
      hasErrorAttachmentName: true,
      addAttachmentToContract: false,
      isAttachmentPdf: false,
      attachmentName: null,
      attachmentType: null,
      acceptableFileTypes: [
        '.txt', '.csv', '.dwg', '.pdf', '.jpeg', '.jpg', '.tif', '.tiff', '.gif', '.png', '.bmp', '.odt', '.ods', '.odp', '.pptx', '.ppt',
        '.xls', '.xlsx', '.doc', '.docx', '.avi', '.mp4', '.mpg', '.mpeg', '.wmv', '.webm', '.mov', '.mkv', '.flv', '.dwf', '.msg'
      ],
      rules: {
        attachmentFileInput: (file) => {
          if (!file || file.size > 67108864)
            return !file ? false || this.$t('Required') : false || this.$t('File is too large. Maximum size is:') + ' 64 MB'
          
            if (file.type == 'application/pdf') {
            this.isAttachmentPdf = true
            this.hasErrorAttachmentFile = false
            return true
          }
          else {
            this.addAttachmentToContract = false
            this.isAttachmentPdf = false
          }

          const setOfAcceptableFileTypes = new Set(this.acceptableFileTypes)
          const fileExtension = '.' + file.name.split('.').pop().toLowerCase()

          if (setOfAcceptableFileTypes.has(fileExtension)) {
            this.hasErrorAttachmentFile = false
            return true
          }
          else {
            this.hasErrorAttachmentFile = true
            return false || this.$t('Unallowed type')
          }
        },
        attachmentNameInput: (value) => {
          if (value) {
            this.hasErrorAttachmentName = false
            return true
          }
          else {
            this.hasErrorAttachmentName = true
            return !!value || this.$t('Required')
          }
        }
      }
    }
  },
  async mounted () {
    var definitionsForTypes = await this.$rambollfmapi.definitions.list('contract.attachmenttype')
    for (let definition in definitionsForTypes) {
      this.items.push(definitionsForTypes[definition].label)
    }
    if (this.isEditing) {
      this.file = this.editingData.file != undefined ? this.editingData.file : { name: this.editingData.filename, size: this.editingData.size, type: this.editingData.type }
      this.attachmentName = this.editingData.title
      this.attachmentType = this.editingData.typeAttachment
      this.addAttachmentToContract = this.editingData.isForContract
      this.isAttachmentPdf = this.file.type == 'application/pdf' ? true : false
      this.hasErrorAttachmentFile = false
      this.hasErrorAttachmentName = false
    }
  },
  methods: {
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
      }
    },
    addAttachment () {
      const attachment = { file: this.file, attachmentName: this.attachmentName, type: this.attachmentType, attachmentToContract: this.addAttachmentToContract }
      this.$emit("close", attachment)
    },
    editAttachment () {
      const editedAttachment = { attachmentName: this.attachmentName, type: this.attachmentType, attachmentToContract: this.addAttachmentToContract,
        index: this.editingData.index, idContract: this.editingData.idContract, idDocument: this.editingData.idDocument, file: this.editingData.file }
      this.$emit('edit', editedAttachment)
    }
  }
}
</script>

<style scoped>
</style>