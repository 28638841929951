<template>
  <div>
    <AriaLive />
    <BaseModal
      :cancel-disabled="sendButtonClicked"
      @cancel="close"
    >
      <template #title>
        {{ $t('InvoiceForming') }}
      </template>
      <template #content>
        <div
          v-for="widget in widgets"
          :key="widget.id"
        >
          <p v-if="widget.id == 'InvoiceRows' || widget.data.items.length > 0">
            <span v-html="getFormattedWidgetDescription(widget.id)" />
          </p>
          <DynamicWidget
            v-if="widget.id != 'InvoiceErrors' || widget.data.items.length > 0"
            :data="widget.data"
            :type="widget.type"
            :title="widget.title"
            :loading="widget.loading"
          >
            <template #customComponent="{ item }">
              <v-icon
                right
                @click="openDisableBillingModal(item)"
              >
                delete
              </v-icon>
            </template>
          </DynamicWidget>
        </div>
      </template>
      <template #footer>
        <v-btn
          rounded
          depressed
          class="primary"
          :loading="sendButtonClicked" 
          :disabled="!isInvoicesReadyForSending"
          @click="markAsReadyForSending()"
        >
          {{ $t('Send') }}
        </v-btn>
      </template>
    </BaseModal>
    <v-dialog
      v-if="showSuppressContractModal"
      v-model="showSuppressContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <DisableAutomaticBillingModal
          :contract-id="contractToSuppress"
          :invoice-id="invoiceToSuppress"
          @close="closeSuppressContractModal"
          @refreshData="refreshData"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import BaseModal from '../general/BaseModal.vue'
import AriaLive from '../AriaLive.vue'
import DynamicWidget from '../DynamicWidget.vue'
import DisableAutomaticBillingModal from './DisableAutomaticBillingModal.vue'

export default {
  emits: ['close', 'refreshData', 'sent'],
  name: "CreateInvoicesModal",
  components: {
    BaseModal,
    AriaLive,
    DynamicWidget,
    DisableAutomaticBillingModal
  },
  props: {
    draftInvoices: {
      type: Array,
      default: function () {
        return []
      }
    },
    newInvoiceErrors: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      sendButtonClicked: false,
      showSuppressContractModal: false,
      contractToSuppress: undefined,
      invoiceIdsReadyForSending: [],
      widgets: [
        {
          id: 'InvoiceRows',
          type: 'ObjectTable',
          title: this.$t('InvoiceRows'),
          loading: true,
          data: {
            headers: [
              { text: this.$t('Landlord'), value: 'landlord' },
              { text: this.$t('Tenant'), value: 'tenant' },
              { text: this.$t('Contract number'), value: 'contractNumber' },
              { text: this.$t('invoice_date'), value: 'billingDate' },
              { text: this.$t('InvoiceSum'), value: 'netSum', format: 'Euro' },
              { text: this.$t('InvoiceNumber'), value: 'invoiceNumber',  },
              { text: this.$t('ReferenceNumber'), value: 'referenceNumber' },
              { text: this.$t('Site'), value: 'siteName' },
              { text: this.$t('create_rent_contract.contract_payment_legend'), value: 'legend' },
              { text: this.$t('create_rent_contract.payment_domain'), value: 'paymentDomainName' },
              { text: this.$t('Actions'), value: 'customComponent' },
              { text: '', value: 'toggle' },
            ],
            items: [],
            sortBy: 'contractNumber',
            noBrowse: true,
          }
        },
        {
          id: 'InvoiceErrors',
          type: 'DataTable',
          title: this.$t('InvoiceErrors'),
          loading: false,
          data: {
            headers: [
              { text: this.$t('Contract number'), value: 'contractNumber' },
              { text: this.$t('BillingMonth'), value: 'billingDate' },
              { text: this.$t('Error info'), value: 'errorType' },
              { text: this.$t('ErrorTimestamp'), value: 'errorTimestamp' },
            ],
            items: [],
            sortBy: 'contractNumber',
            exportCsv: true,
            noBrowse: true,
            customFormatters: {
              errorTimestamp: (row) => {
                return moment(row.errorTimestamp).format('DD.MM.YYYY HH:mm:ss.SSS')
              },
            }
          }
        }
      ],
    }
  },
  computed: {
    ...mapGetters('app', [ 'hasApplicationPermissionByName', 'definitionLabelById' ]),
    isInvoicesReadyForSending () {
      return this.hasApplicationPermissionByName('LASKUTUS_MUOKKAUS') === true && this.invoiceIdsReadyForSending.length > 0
    },
  },
  watch: {
    draftInvoices: {
      handler: function () {
        this.setInvoices()
      },
      deep: true
    },
    newInvoiceErrors: {
      handler: function () {
        this.setErrors()
      },
      deep: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async setInvoices () {
      const items = this.draftInvoices.map((item, i) => {
        const row = { 
          ...item,
          billingDate: this.$t(moment(new Date(item.billingDate)).format("M/YYYY")),
        }        
        row.id = i,
        row.invoiceId = item.id,
        row.showToggle = true
        
        const firstMonth = item.invoiceRows[0].month;
        const firstYear = item.invoiceRows[0].year;
        const lastMonth = item.invoiceRows[item.invoiceRows.length - 1].month;
        const lastYear = item.invoiceRows[item.invoiceRows.length - 1].year;

        if (firstMonth !== lastMonth || firstYear !== lastYear) {
            row.billingDate = `${firstMonth}/${firstYear}-${lastMonth}/${lastYear}`;
        }
        
        row.subItems = item.invoiceRows.map(invoiceRow => {         
          return {
            ...item,
            billingDate: `${invoiceRow.month}/${invoiceRow.year}`,
            netSum: invoiceRow.netSum,
            legend: invoiceRow.legend,
            paymentDomainName: invoiceRow.rentPaymentDomain?.name ?? "",
            showToggle: false,
            showCustomComponent: false,
          }
        })               
        return row;
      });  
      this.invoiceIdsReadyForSending = items.map(inv => inv.invoiceId)
      // We need to filter out rows with netSum = 0 because we dont want show them in the widget and we dont want to send them
      this.widgets[0].data.items = items.filter(row => row.netSum !== 0);   
      this.widgets[0].loading = false
    },   
    openDisableBillingModal (item) {
      if (item.invoiceType == "CreditInvoice" || item.invoiceType == "HandInvoice") {
        const itemIndex = this.widgets[0].data.items.findIndex(widgetItem => widgetItem.id === item.id)
        this.invoiceIdsReadyForSending.splice(itemIndex, 1)
        this.widgets[0].data.items.splice(itemIndex, 1)
      }
      else {
        this.contractToSuppress = item.idContract
        this.invoiceToSuppress = item.invoiceId
        this.showSuppressContractModal = true
      }
    },
    closeSuppressContractModal () {
      this.showSuppressContractModal = false
    },
    refreshData () {
      this.$emit('refreshData')
      this.setInvoices()
    },
    async markAsReadyForSending () {
      this.sendButtonClicked = true
      await this.$rambollfmapi.invoices.markAsReadyForSending(this.invoiceIdsReadyForSending)
      this.refreshData()
      this.$store.dispatch('app/addAriaLive', this.$t('Success'))
      this.$emit('sent')
      this.close()
    },
    setErrors () {           
      this.widgets[1].data.loading = true
      this.widgets[1].data.items = this.newInvoiceErrors
        .map(err => ({
          ...err,
          billingDate: this.$t(moment(new Date(err.billingDate)).format('M/YYYY')),
          errorType: this.$t(err.errorType),
        }))
        ?? []
    },
    getFormattedWidgetDescription (widgetId) {
      const description = this.getWidgetDescription(widgetId);
      if (widgetId === 'InvoiceRows') {
        return description.replace(this.$t('DisableAutomaticBillingAdditionalInfo'), `<span class="font-weight-bold">${this.$t('DisableAutomaticBillingAdditionalInfo')}</span>`);
      }  
      else {
        return description;
      }
    },
    getWidgetDescription (widgetId) {
      if (widgetId == 'InvoiceRows') {
        return this.$t('DisableAutomaticBillingInfo') + " " + this.$t('DisableAutomaticBillingAdditionalInfo')
      } else if (widgetId == 'InvoiceErrors') {
        return this.$t('InvoiceErrorTableDescription')
      }
      return ""
    }
  },
}
</script>
<style scoped>
.v-expansion-panels {
  border: none;
}
</style>