import { i18n } from '../../../plugins/i18n.js'

export function guarantees (contractStates) {  
    return {
    id: "contract.guarantees",
    type: "LazyTable",
    loading: true,
    data: {
      headers: [
        {
          text: "Contract number",
          value: "contractNumber",
          event: "showCreateRentContractModal",
        },
        { text: "contract.start_date", value: "contractStartDate", format: "Date" },
        { text: "contract.end_date", value: "contractEndDate", format: "Date" },
        { text: "Contract status", value: "contractStatus", format: "Array" },
        { text: "tenantName", value: "tenantName" },
        { text: "Target name", value: "siteNames", event: "goToSite" },
        { text: "Site identifier", value: "siteIdentifiers" },
        { text: "GuaranteeType", value: "guaranteeType" },
        { text: "GuaranteeDescription", value: "guaranteeDescription", hideOverflow: true },
        { text: "GuaranteeSum", value: "guaranteeSum", format: "Euro" },
        { text: "Delivered", value: "deliveredSum", format: "Euro" },
        { text: "Returned", value: "returnedSum", format: "Euro" },
        { text: "PresentValue", value: "presentSum", format: "Euro" },
        {
          text: "GuaranteeAgreedDateOfDelivery",
          value: "agreedDateOfDelivery",
          format: "Date",
        },
        {
          text: "GuaranteeDateOfDelivery",
          value: "dateOfDelivery",
        },
        {
          text: "GuaranteeDateOfExpiration",
          value: "dateOfExpiration",
          format: "Date",
        },
        {
          text: "GuaranteeDateOfReturn",
          value: "dateOfReturn",
        },
        { 
          text: "GuaranteeAdditionalInfo",
          value: "guaranteeAdditionalInfo",
          hideOverflow: true,
        },
      ],
      customFormatters: {
        contractStatus: (row) => {
          return i18n.t(row.contractStatus)
        },
      },
      source: "contractguarantees",
      items: [],
      triggerGetAllData: 0,
      customPagination: {
        sortBy: ["contractNumber"],
        sortDesc: [true],
      },
      customFilterContent: {
        contractStatus: contractStates.map((state) => ({
          text: i18n.t(state.name),
          value: state.name,
        })),
      },
    },
  }
}
