<template>
  <div>
    <h2 class="category-toolbar">
      <v-icon
        v-if="anyVisible"
        @click="deselectAll()"
      >
        check_box
      </v-icon>
      <v-icon
        v-else
        @click="selectAll()"
      >
        check_box_outline_blank
      </v-icon>
      <v-icon>format_paint</v-icon>
      {{ categoryName }}
    </h2>
    <div
      class="header-column"
    >
      <v-expansion-panels
        v-for="header in categoryData"
        :key="header.value"
        class="service-panel"
      >
        <v-expansion-panel
          :disabled="header.count === 0"
        >
          <v-expansion-panel-header
            v-if="header.count !== null"
            class="header-count"
          >
            <v-row
              align="center"
              justify="start"
              no-gutters
              class="flex-nowrap text-left"
            >
              <v-icon
                v-if="header.show"
                :color="'rgba(' + header.rgb + ', 1)'"
                @click="toggleHeaderColor(header.value)"
                @click.stop
              >
                check_box
              </v-icon>
              <v-icon
                v-else
                :color="'rgba(' + header.rgb + ', 1)'"
                @click="toggleHeaderColor(header.value)"
                @click.stop
              >
                check_box_outline_blank
              </v-icon>
              {{ $t(header.text) }}
            </v-row>
            <v-row
              align="center"
              justify="end"
              no-gutters
            >
              {{ header.count + ' kpl' }}
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="section-content"
          >
            <v-expansion-panels
              v-for="sectionName in headerSections(header)"
              :key="sectionName"
              class="section-panel"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="capitalized-header"
                >
                  {{ $t('Graveyard section') + ' ' + sectionName }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-for="grave in headerSectionGraves(header, sectionName)"
                    :key="grave.graveId"
                    justify="space-between"
                    align="center"
                    class="grave-row"
                    @click="selectGrave(grave.graveId)"
                  >
                    {{ grave.graveIdentifier }}
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['toggle-header-color', 'select-grave', 'select-all', 'deselect-all'],
  props: {
      categoryName: {
        type: String,
        default: () => ''
      },
      categoryData: {
        type: Array,
        default: () => []
      }
  },
  computed: {
    anyVisible () {
      return this.categoryData.some(category => category.show)
    }
  },
  methods: {
    headerSections (header) {
      return [...new Set(header.graves.map(x => x.sectionName))]
    },
    headerSectionGraves (header, sectionName) {
      return header.graves.filter(x => x.sectionName === sectionName)
    },
    toggleHeaderColor (header) {
      this.$emit('toggle-header-color', header)
    },
    selectGrave (graveId) {
      this.$emit('select-grave', graveId)
    },
    selectAll () {
      this.$emit('select-all')
    },
    deselectAll () {
      this.$emit('deselect-all')
    }
  }
}
</script>

<style scoped>
.category-toolbar {
  margin-left: 0.5em;
  margin-top: 1em;
}
.grave-row {
  cursor: pointer;
  font-weight: bold;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 1px solid #ebeef8;
  border-top: 2px solid #ebeef8;
  border-bottom: 2px solid #ebeef8;
  min-height: 40px;
  margin: 2px;
}
.header-count {
  text-align: right;
  margin-right: 0.5em;
}
.header-column {
  pointer-events: auto;
}
.capitalized-header {
  text-transform: capitalize;
}
.service-panel {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.section-content {
  padding-left: 0.2em;
  padding-right: 0.2em;
}
.section-panel {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.section-panel .v-expansion-panel-content:last-child {
  padding-bottom: 0.6em;
}
</style>