import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { PDFDocument } from 'pdf-lib'
import moment from 'moment'
import helpers from '../../../../../helpers'
import { i18n } from '../../../../../plugins/i18n.js'

import { createContractTerminationPdf } from './pdf/createContractTerminationPdf.js'
import { customPdfCreator } from './pdf/customPdfCreator.js'

export default {
  async createContractPdf (contractNumber, contractType, spaceUser, spaceUsage, logo, parties, selectedTargets, rentPeriod, housingModification, billingInfo, payments,
    guarantees, otherConditions, attachments, getAttachmentArrayBuffer, currentDate, blob = false) {

    const doc = new JsPDF('portrait')

    doc.setFontSize(12)
    
    addHeader(doc, logo, contractNumber, contractType)

    addParties(doc, parties)

    let yPosition = doc.lastAutoTable.finalY + 20
    
    addUnits(doc, selectedTargets.targets, spaceUser, spaceUsage, yPosition)

    addSpaces(doc, selectedTargets.targets, payments.contractPayments, yPosition)

    addAdditionalInfo(doc, selectedTargets.additionalInfoUnits, 'pdf.additionalInfoUnits', yPosition)

    addParkingSpaces(doc, selectedTargets.targets, payments.contractPayments, yPosition)

    addAdditionalInfo(doc, selectedTargets.additionalInfoParking, 'pdf.additionalInfoCarParks', yPosition)

    addRentPeriod(doc, rentPeriod, yPosition)

    addNoticePeriods(doc, rentPeriod, yPosition)

    addRent(doc, payments.contractPayments, payments.separatePayments, billingInfo, selectedTargets.targets, currentDate, yPosition)

    addPayments(doc, payments.contractPayments, selectedTargets.targets, yPosition)

    addSeparatePayments(doc, payments.separatePayments, selectedTargets.targets, yPosition)

    addSinglePayments(doc, payments.singlePayments, selectedTargets.targets, yPosition)

    addRentReviews(doc, payments.contractPayments, currentDate, yPosition)

    addGuarantees(doc, guarantees, yPosition)

    addHousingModifications(doc, housingModification, yPosition)

    addOtherConditions(doc, otherConditions, yPosition)

    addAttachments(doc, attachments, yPosition)

    addSignatures(doc, parties.landlord, parties.tenant, yPosition)

    addFooter(doc)
    
    if (attachments?.length > 0) {
      return await addAttachmentsMerge(doc, attachments, contractNumber, getAttachmentArrayBuffer, blob)
    } else {
      return blob ? doc.output('blob') : doc.save(`${i18n.t("pdf.contract")}-${contractNumber}.pdf`)
    }
  },
  createContractTerminationPdf,
  customPdfCreator,
}

function addHeader (doc, logo, contractNumber, contractType) {

  if (logo) {
    var imgData = 'data:image/png;base64,' + logo
    doc.addImage(imgData, 'PNG', 10, 10, 28, 12)
  }
  doc.text(`${contractType ? i18n.t(contractType) : i18n.t("pdf.Contract")} ${contractNumber}`, 80, 22)
}

function addFooter (doc) {

  const pageCount = doc.internal.getNumberOfPages()
  for (let i = 1; i <= pageCount; i++) {
    doc.setFontSize(9)
    doc.setPage(i)
    const pageSize = doc.internal.pageSize
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const footer = `${i18n.t("pdf.page")} ${i} / ${pageCount}`
    doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 10, { baseline: 'bottom' })
  }
}

function addParties (doc, parties) {

  doc.text(i18n.t("pdf.parties"), 10, 40)
  doc.autoTable({
      startY: 50,      
      body: getLandlordTable(parties.landlord, parties.landlordContactPerson),
      theme: 'grid',
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      }
    },    
  )
  doc.autoTable({
      body: getTenantTable(parties.tenant, parties.tenantIdentityNumber, parties.tenantContactPerson),
      theme: 'grid',
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      }
    }
  )
  doc.autoTable({
      body: getTenantTable(parties.otherTenant, parties.otherTenantIdentityNumber),
      theme: 'grid',
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      }
    }
  )
}

function addUnits (doc, units, spaceUser, spaceUsage, yPosition) {
  if (units?.length > 0) {
    yPosition = doc.lastAutoTable.finalY + 20
    doc.text(i18n.t("pdf.contract_sites"), 10, doc.lastAutoTable.finalY + 15)

      doc.autoTable({
          startY: yPosition,
          body: getContractSiteTable(units, spaceUser, spaceUsage),
          theme: 'grid',
          margin: { left: 10, right: 10 },
          columnStyles: {
            0: { cellWidth: 50 },
          }
        }
      )
      yPosition = doc.lastAutoTable.finalY + 5
  }
}

function addSpaces (doc, units, payments, yPosition) {
  const unitsAndStructures = units?.filter(u => !!u.unitId || !!u.structureId)
  if (unitsAndStructures?.length > 0) {
    const table = []
    for (const unit of unitsAndStructures) {
      table.push(getSpaceTableRow(unit, payments))
    }
    const totalArea = unitsAndStructures.reduce((acc, obj) => acc + obj.agreedArea , 0)
    table.push(getTotalAreaTableRow(totalArea))

    yPosition = doc.lastAutoTable.finalY + 10
    doc.autoTable({
      startY: yPosition,
      head: getSpaceHeadRow(),
      body: table,
      theme: 'grid',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      styles: {
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
      },
    })
  }
}

function addParkingSpaces (doc, units, payments, yPosition) {
  const parkingSpaces = units?.filter(u => !!u.parkingSpaceId)
  if (parkingSpaces?.length > 0) {
    
    doc.text(i18n.t("Car parks"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    
    const table = [] 
    for (const unit of parkingSpaces) {
      table.push(getParkingSpaceTableRow(unit, payments))
    }
    const totalAmount = parkingSpaces.reduce((acc, obj) => acc + obj.agreedAmount, 0)
    table.push(getTotalAmountTableRow(totalAmount))

    yPosition = doc.lastAutoTable.finalY + 10
    doc.autoTable({
      startY: yPosition,
      head: getParkingSpaceHeadRow(),
      body: table,
      theme: 'grid',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      styles: {
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
      },
    })
  }
}

function addRentPeriod (doc, rentPeriod, yPosition) {

  doc.text(i18n.t("pdf.rent_time"), 10, doc.lastAutoTable.finalY + 15)
  yPosition = doc.lastAutoTable.finalY + 20
  doc.autoTable({
      startY: yPosition,
      body: getRentPeriod(rentPeriod),
      theme: 'grid',
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      }
    }
  )
}

function addNoticePeriods (doc, rentPeriod, yPosition) {

  if (rentPeriod.noticePeriodLandlord || rentPeriod.noticePeriodTenant) {
    doc.text(i18n.t("pdf.notice_period"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable({
      startY: yPosition,
      body: [[i18n.t("pdf.notice_period_clause")]],
      theme: 'grid',
      margin: { left: 10, right: 10 },
    })
    yPosition = doc.lastAutoTable.finalY
    doc.autoTable({
        startY: yPosition,
        body: noticePeriodTable(rentPeriod),
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles: {
          0: { cellWidth: 50 },
        }
      }
    )
  }
}

function addSignatures (doc, landlord, tenant, yPosition) {

  doc.text(i18n.t("pdf.signatures"), 10, doc.lastAutoTable.finalY + 15)
  yPosition = doc.lastAutoTable.finalY + 20
  doc.autoTable({
    startY: yPosition,
    body: [[i18n.t("pdf.signature_oath")]],
    theme: 'grid',
    margin: { left: 10, right: 10 }
  })
  yPosition = doc.lastAutoTable.finalY
  doc.autoTable({
      startY: yPosition,
      body: signatureTable(landlord, tenant),
      theme: 'grid',
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      }
    }
  )
}

function signatureTable (landlord, tenant) {

  return [
    [i18n.t("pdf.dateTime"), ""],
    [i18n.t("pdf.landlord"), landlord?.name + '\n\n\n\n\n' + i18n.t("pdf.signature")],
    [i18n.t("pdf.dateTime"), ""],
    [i18n.t("pdf.tenant"), tenant?.name + '\n\n\n\n\n' + i18n.t("pdf.signature")],
  ]
}

function addAttachments (doc, attachments, yPosition) {

  if (attachments?.length === 0) return

  const table = attachments.map((attachment, i) => attachmentTable(attachment, i))

  doc.text(i18n.t("Contract attachments"), 10, doc.lastAutoTable.finalY + 15)
  yPosition = doc.lastAutoTable.finalY + 20

  doc.autoTable({
    startY: yPosition,
    body: table,
    theme: 'grid',
    margin: { left: 10, right: 10 },
    columnStyles: {
      0: { cellWidth: 50 },
    },
    styles: {
      lineColor: [200, 200, 200],
      lineWidth: 0.1,
    },
  })
}

function attachmentTable (attachment, i) {

  return [
    `${i18n.t("pdf.Attachment")} ${i + 1}`,
    attachment?.title,
  ]
}

async function addAttachmentsMerge (doc, attachments, contractNumber, getAttachmentArrayBuffer, blob) {
  
  const contractArrayBuffer = doc.output('arraybuffer')
  const mergedPdfDoc = await PDFDocument.create()

  // Contract pages
  const contractDoc = await PDFDocument.load(contractArrayBuffer)
  const firstPage = await mergedPdfDoc.copyPages(contractDoc, contractDoc.getPageIndices())
  firstPage.forEach((page) => mergedPdfDoc.addPage(page))

  // Attachment pages
  for (let i = 0; i < attachments.length; i++) {
    const attachmentBuffer = attachments[i].idDocument ? await getAttachmentArrayBuffer(attachments[i].idDocument) : await attachments[i].file.arrayBuffer()
    const attachmentDoc = await PDFDocument.load(attachmentBuffer)
    const attachmentPage = await mergedPdfDoc.copyPages(attachmentDoc, attachmentDoc.getPageIndices())
    attachmentPage.forEach((page) => mergedPdfDoc.addPage(page))
  }

  const pdfBytes = await mergedPdfDoc.save()
  const fileBlob = new Blob([pdfBytes], { type: 'application/pdf' })
  
  return blob ? fileBlob : helpers.saveAs(fileBlob, `${i18n.t("pdf.contract")}-${contractNumber}.pdf`)
}

function addHousingModifications (doc, housingModification, yPosition) {

    doc.text(i18n.t("pdf.housingModifications"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable (
      {
        startY: yPosition,
        body: housingModificationsTable(housingModification),
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles: {
          0: { cellWidth: 50 },
        }
      }
    )
}

function addOtherConditions (doc, conditions, yPosition) {

  if (conditions?.length > 0) {
    doc.text(i18n.t("pdf.additional_conditions"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable({
        startY: yPosition,
        body: [[conditions]],
        theme: 'grid',
        margin: { left: 10, right: 10 },
      }
    )
  }
}

function addGuarantees (doc, guarantees, yPosition) {

  if (guarantees?.length > 0) {
    doc.text(i18n.t("pdf.guarantee"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable({
      startY: yPosition,
      body: [[i18n.t("pdf.guarantee_description")]],
      theme: 'grid',
      margin: { left: 10, right: 10 },
    })
    yPosition = doc.lastAutoTable.finalY
    for (const guarantee of guarantees) {
      doc.autoTable({
          startY: yPosition,
          body: createGuaranteesTable(guarantee),
          theme: 'grid',
          margin: { left: 10, right: 10 },
          columnStyles: {
            0: { cellWidth: 50 },
          }
        }
      )
      yPosition = doc.lastAutoTable.finalY + 10
    }
  }
  else {
    doc.text(i18n.t("pdf.guarantee"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable({
      startY: yPosition,
      body: [[i18n.t("pdf.noGuarantees")]],
      theme: 'grid',
      margin: { left: 10, right: 10 },
    })
    yPosition = doc.lastAutoTable.finalY
  }
}

function createGuaranteesTable (guarantee) {

  return [
    [i18n.t("pdf.guaranteeSum"), guarantee.guaranteeSum ? `${helpers.humanize.amount_long(guarantee.guaranteeSum, 2).replace('.',',')} €` : ''],
    [i18n.t("pdf.guaranteeDescription"), guarantee.guaranteeDescription],
    [i18n.t("pdf.guaranteeType"), i18n.t(guarantee.guaranteeType)],
    [i18n.t("pdf.guaranteeDateOfDelivery"), toDateString(guarantee.agreedDateOfDelivery)],
    [i18n.t("pdf.guaranteeDateOfExpiration"), toDateString(guarantee.dateOfExpiration)],
  ]
}

function addRentReviews (doc, contractPayments, currentDate, yPosition) {
  
  if (!contractPayments || contractPayments.length === 0) return

  if (contractPayments?.some((p) => (p.reviewType !== "Ei tarkistusta") && p.reviewType !== "")) {
    doc.text(i18n.t("pdf.rentReview"), 10, doc.lastAutoTable.finalY + 15)
    const reviewGroups = contractPayments.reduce((acc, value) => {
      if (!acc[value.reviewType]) {
        acc[value.reviewType] = []
      }
      acc[value.reviewType].push(value)
      return acc;
    }, {})
    yPosition = doc.lastAutoTable.finalY + 20

    for (const [reviewType, reviewGroup] of Object.entries(reviewGroups)) {
      if (reviewType !== "" && reviewType !== "Ei tarkistusta") {  
        doc.autoTable({
          startY: yPosition,
          body: rentReviewTable(reviewGroup, Date.parse(currentDate)),
          theme: 'grid',
          margin: { left: 10, right: 10 },
          columnStyles: {
            0: { cellWidth: 50 },
          }
        })
        yPosition = doc.lastAutoTable.finalY + 10
      }
    }
  }
}

function rentReviewTable (group, currentDate) {
  // Select currentDate, if rent review exist currently
  let currentRentReviewDate = group.some(g => (currentDate >= Date.parse(g.startDate) && (!g.endDate || currentDate <= Date.parse(g.endDate)))) ? currentDate : null

  // If currentRentReviewDate doesn't excist, rent reviews are in future. We pick first one from future
  if (!currentRentReviewDate) {
    currentRentReviewDate = Date.parse(group.reduce((pre, cur) => pre.startDate > cur.startDate ? cur : pre)?.startDate)
  }
  // If currentRentReviewDate doesn't excist, rent reviews are in history. We pick oldest one from history
  if (!currentRentReviewDate) {
    currentRentReviewDate = Date.parse(group.reduce((pre, cur) => pre.startDate < cur.startDate ? cur : pre)?.startDate)
  }
  
  const netSum = group.filter(g => Date.parse(g.startDate) <= currentRentReviewDate && (!g.endDate || Date.parse(g.endDate) >= currentRentReviewDate)).map(p => p.netSum).reduce((acc, val) => {
    acc += val;
    return acc;
  }, 0)

  // Init values for table
  const rows = {
    reviewType: [],
    paymentType: [],
    netSum: [netSum !== undefined ? `${helpers.humanize.amount_long(Number(netSum), 2).replace('.',',')} €` : ''],
    indexHeading: [i18n.t("pdf.index")],
    baseindex: [],
    basicIndexPointNumber: [],
    rentReviewStartDate: [],
    reviewMonths: [],
    indexMonths: [],
    minimumIncrease: [],
    rentReviewBasedOn: [],
    reviewAdditionalInfo: [],
    dynamicReviewInfo: [],
  }

  // Update values
  group.map(p => {
    const formatDate = (date) => {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("D.M.YYYY") : undefined
    }
    const getMonthName = (monthNumber) => {
      const date = new Date()
      date.setMonth(monthNumber - 1)
      return date.toLocaleString('en-US', { month: 'long' })
    }
    
    // Dynamic review info
    const rentBasedOn = [p.reviewMonths.map(m => i18n.t(getMonthName(m))).join(', '), i18n.t(p.rentReviewBasedOn).toLowerCase()]
    const rentIsCalculated = p.reviewMonths.map((r, i) => ([ i18n.t(getMonthName(r)), i18n.t(getMonthName(p.indexMonths[i]))]))
    const partA = i18n.tc('pdf.rentChecks', p.reviewMonths.length)
    const partB = i18n.t('pdf.rentBasedOn', rentBasedOn)
    const partC = rentIsCalculated.map(rm => (i18n.t('pdf.rentIsCalculated', rm))).join(', ').replace(/, ([^,]*)$/, ` ${i18n.t('and')} $1`)
    const partD = p.rentReviewBasedOn === "CurrentRentAndLatestIndex" ? i18n.t("pdf.review_original_rent_info") : ""
    const dynamicReviewInfo = `${partA} ${partB} ${partC}. ${partD}`

    // Push values for rows
    rows.reviewType.push(p.reviewType)
    rows.paymentType.push(p.paymentType)
    if (p.basicIndexMonth && p.basicIndexYear) rows.baseindex.push(p.basicIndexMonth + "/" + p.basicIndexYear)
    rows.basicIndexPointNumber.push(p.basicIndexPointNumber)
    if (p.rentReviewStartDate) rows.rentReviewStartDate.push(`${formatDate(p.rentReviewStartDate)}, 100 %`)
    rows.reviewMonths.push(p.reviewMonths.map(month => month).join(', '))
    rows.indexMonths.push(p.indexMonths.map(month => month).join(', '))
    rows.minimumIncrease.push(p.minimumIncrease ? `${p.minimumIncrease} %. ${i18n.t("pdf.indexLowerText")}` : i18n.t("pdf.indexLowerText"))
    rows.rentReviewBasedOn.push(i18n.t(p.rentReviewBasedOn))
    rows.reviewAdditionalInfo.push(p.reviewAdditionalInfo)
    rows.dynamicReviewInfo.push(dynamicReviewInfo)
  })

  // Create rows for Rent Review Table
  const rowsWithValues = []
  for (const [key, value] of Object.entries(rows)) {
    const uniqueValues = [...new Set(value.filter(v => v != null && v != undefined && v != ""))] // remove duplicated and empty data
    if (value.length > 0) rowsWithValues.push([i18n.t(`pdf.${key}`), uniqueValues.join('\n')])
  }

  if (group[0].reviewType === "Indeksitarkistus") {
    return rowsWithValues
  } else {
    return [
      [i18n.t("pdf.reviewType"), rows.reviewType.join("\n")],
      [i18n.t("pdf.paymentTypes"), rows.paymentType.join("\n")],
      [i18n.t("pdf.netSum"), netSum !== undefined ? `${helpers.humanize.amount_long(Number(netSum), 2).replace('.',',')} €` : ''],
    ]
  }
}

function addRent (doc, contractPayments, separatePayments, billingInfo, units, currentDate, yPosition) {

  if (contractPayments?.length > 0) {
    doc.text(i18n.t("pdf.rent"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    if (billingInfo.isVat) {
      doc.autoTable({
        startY: yPosition,
        body: [[i18n.t("pdf.tax_info")]],
        theme: 'grid',
        margin: { left: 10, right: 10 },
      })
      yPosition = doc.lastAutoTable.finalY
    }
    doc.autoTable({
        startY: yPosition,
        body: rentTable(contractPayments, separatePayments, billingInfo, units, Date.parse(currentDate)),
        theme: 'grid',
        margin: { left: 10, right: 10 },
        columnStyles: {
          0: { cellWidth: 50 },
        }
      }
    )
  }
}

function rentTable (contractPayments, separatePayments, billingInfo, units, currentDate) {
  if (!contractPayments && !separatePayments) return
  // Select currentDate, if payments exist currently
  let currentPaymentDate = [...contractPayments, ...separatePayments].some(p => (currentDate >= Date.parse(p.startDate) && (!p.endDate || currentDate <= Date.parse(p.endDate)))) ? currentDate : null

  // If currentPaymentDate doesn't excist, payments are in future. We pick first one from future
  if (!currentPaymentDate) {
    currentPaymentDate = Date.parse([...contractPayments, ...separatePayments].reduce((pre, cur) => pre.startDate > cur.startDate ? cur : pre)?.startDate)
  }

  // If currentPaymentDate doesn't excist, payments are in history. We pick oldest one from history
  if (!currentPaymentDate) {
    currentPaymentDate = Date.parse([...contractPayments, ...separatePayments].reduce((pre, cur) => pre.endDate < cur.endDate ? cur : pre)?.startDate)
  }
  
  const getPaymentsTotals = (payments) => {
    // Only valid payments are counted
    const validPayments = payments.filter(p => currentPaymentDate >= Date.parse(p.startDate) && (!p.endDate || currentPaymentDate <= Date.parse(p.endDate)))
    // For area based payments
    const totalArea = units.reduce((acc, cur) => acc + cur?.agreedArea, 0)
    // For pcs based payments
    const totalPsc = validPayments.reduce((acc, cur) => acc + cur?.pcsCount, 0)
    const netSquareDenominator = validPayments[0]?.invoicingBasis === 'Pinta-ala' ? totalArea : totalPsc

    return {
      net: validPayments.reduce((acc, cur) => acc + cur?.sums.netSum, 0),
      gross: validPayments.reduce((acc, cur) => acc + cur?.sums.grossSum, 0),
      netSquare: validPayments.reduce((acc, cur) => acc + cur?.sums.netSum, 0) / netSquareDenominator,
      grossSquare: validPayments.reduce((acc, cur) => acc + cur?.sums.grossSum, 0) / netSquareDenominator,
      tax: validPayments.reduce((acc, cur) => acc + cur?.sums.tax, 0),
      invoicingBasisList: validPayments.map(p => p?.invoicingBasis),
      processingModeList: validPayments.map(p => p?.processingMode),
      vatList: validPayments.map(p => p?.paymentVat + ' %')
    }
  }

  const getTotalsText = (sums) => {
    if (!sums.net) return
    const humanize = (num) => num ? helpers.humanize.amount_long(Number(num), 2).replace('.',',') : ''
    // Remove empty and duplicated data
    const invoicingBasis = [...new Set(sums.invoicingBasisList.filter(ib => ib !== ""))]
    const processingMode = [...new Set(sums.processingModeList.filter(pm => pm !== ""))]
    // Add default processing mode
    if (invoicingBasis[0] !== 'Kpl') processingMode[0] = i18n.t("pdf.euro_m2_month")
    const totalsText = [
      {
        name: `${i18n.t('Exempt from taxation')}:`,
        total: `${humanize(sums.net)} €`,
        squareBased: invoicingBasis.length === 1 && sums.netSquare ? `(${humanize(sums.netSquare)} ${processingMode[0] ?? ''})` : '',
        show: true,
      },
      {
        name: `${i18n.t('Taxable')}:`,
        total: `${humanize(sums.gross)} €`,
        squareBased: invoicingBasis.length === 1 && sums.grossSquare ? `(${humanize(sums.grossSquare)} ${processingMode[0] ?? ''})` : '',
        show: sums.tax !== 0
      },
      {
        name: `${i18n.t('Tax')}:`,
        total: `${humanize(sums.tax)} € ${[...new Set(sums.vatList)].join(', ')}`,
        squareBased: invoicingBasis.length === 1 && (sums.grossSquare || sums.netSquare) ? `(${humanize(sums.grossSquare - sums.netSquare)} ${processingMode[0] ?? ''})` : '',
        show: sums.tax !== 0
      }
    ]

    return totalsText.map(t => t.show ? `${t.name} ${t.total} ${t.squareBased}` : '').filter(t => t !== "").join('\n')
  }

  const contractSums = getPaymentsTotals(contractPayments)
  const separateSums = getPaymentsTotals(separatePayments)
  const getMonthName = (monthNumber) => {
    const date = new Date()
    date.setMonth(monthNumber - 1)
    return date.toLocaleString('en-US', { month: 'long' })
  }

  return [
    [i18n.t("pdf.rent_per_month"), getTotalsText(contractSums)],
    [i18n.t("pdf.separate_rent_per_month"), getTotalsText(separateSums)],
    [i18n.t("pdf.due"), (billingInfo.billingCycleLength == 12) ? (i18n.t("pdf.alt_due_text") + i18n.t(getMonthName(billingInfo.firstBillingMonth))) : i18n.t("pdf.due_text")],
    [i18n.t("pdf.rent_period"), billingInfo.billingCycleLength ? billingInfo.billingCycleLength + " kk" : ""],
    [i18n.t("pdf.late_payment_interest"), i18n.t("pdf.according_to_the_interest_act")],
  ]
}

function addPayments (doc, contractPayments, units, yPosition) {

  if (contractPayments?.length > 0 && units?.length > 0) {
    const table = []
    for (const payment of contractPayments) {
      const targetUnitCodes = units
        .filter(({ unitId, structureId, parkingSpaceId }) => (payment?.targetUnits?.includes(Number(unitId)) || payment?.targetStructures?.includes(Number(structureId)) || payment?.targetParkingSpaces?.includes(Number(parkingSpaceId))))
        .map(u => u.unitCode)
        .join(', ')
      table.push(paymentsTable(payment, targetUnitCodes))
    }
    doc.text(i18n.t("pdf.paymentValidity"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20

    doc.autoTable({
      startY: yPosition,
      head: getPaymentsHeadRow(),
      body: table,
      theme: 'grid',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      styles: {
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
      },
    })
  }
}

function getPaymentsHeadRow () {

  return [{
    type: i18n.t("pdf.paymentType"),
    starting: i18n.t("pdf.starting"),
    ending: i18n.t("pdf.ending"),
    reviewType: i18n.t("pdf.reviewType"),
    net: i18n.t("pdf.netSum_month"),
    allocation: i18n.t("pdf.allocation"),
  }]
}

function paymentsTable (payment, targetUnitCodes) {

  return [
    payment?.paymentType,
    toDateString(payment?.startDate),
    toDateString(payment?.endDate),
    payment?.reviewType,
    payment?.netSum !== undefined ? `${helpers.humanize.amount_long(Number(payment?.netSum), 2).replace('.',',')} €` : '',
    payment?.wholeContractPayment ? i18n.t("create_rent_contract.single_payment_whole_contract") : targetUnitCodes
  ]
}

function addSeparatePayments (doc, separatePayments, units, yPosition) {

  if (separatePayments?.length > 0) {
    const table = []
    for (const payment of separatePayments) {
      const targetUnitCodes = units
      .filter(({ unitId, structureId, parkingSpaceId }) => (payment?.targetUnits?.includes(Number(unitId)) || payment?.targetStructures?.includes(Number(structureId)) || payment?.targetParkingSpaces?.includes(Number(parkingSpaceId))))
      .map(u => u.unitCode)
      .join(', ')
      table.push(separatePaymentsTable(payment, targetUnitCodes))
    }
    doc.text(i18n.t("create_rent_contract.separate_payments"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20

    doc.autoTable({
      startY: yPosition,
      head: getSeparatePaymentsHeadRow(),
      body: table,
      theme: 'grid',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      styles: {
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
      },
    })
  }
}

function getSeparatePaymentsHeadRow () {

  return [{
    expl: i18n.t("create_rent_contract.payment_legend"),
    starting: i18n.t("pdf.starting"),
    ending: i18n.t("pdf.ending"),
    price: i18n.t("create_rent_contract.payment_square_rent_tax_free"),
    net: i18n.t("pdf.netSum_month"),
    alv: `${i18n.t("VAT")} %`,
    allocation: i18n.t("pdf.allocation"),
  }]
}

function separatePaymentsTable (payment, targetUnitCodes) {

  return [
    payment?.legend,
    toDateString(payment?.startDate),
    toDateString(payment?.endDate),
    payment?.sums.netSquareRent !== undefined ? `${helpers.humanize.amount_long(Number(payment?.sums.netSquareRent), 2).replace('.',',')} ${i18n.t("pdf.euro_m2_month")}` : '',
    payment?.sums.netSum !== undefined ? `${helpers.humanize.amount_long(Number(payment?.sums.netSum), 2).replace('.',',')} €` : '',
    payment?.paymentVat !== undefined ? `${payment?.paymentVat} %` : '',
    payment?.wholeContractPayment ? i18n.t("create_rent_contract.single_payment_whole_contract") : targetUnitCodes
  ]
}


function addSinglePayments (doc, singlePayments, units, yPosition) {

  if (singlePayments?.length > 0 && units?.length > 0) {
    const table = []
    for (const payment of singlePayments) {
      const targetUnitCodes = units
        .filter(({ unitId, structureId, parkingSpaceId }) => (payment?.targetUnits?.includes(Number(unitId)) || payment?.targetStructures?.includes(Number(structureId)) || payment?.targetParkingSpaces?.includes(Number(parkingSpaceId))))
        .map(u => u.unitCode)
        .join(', ')
      table.push(singlePaymentsTable(payment, targetUnitCodes))
    }
    doc.text(i18n.t("Single payments"), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20

    doc.autoTable({
      startY: yPosition,
      head: getSinglePaymentsHeadRow(),
      body: table,
      theme: 'grid',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      margin: { left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      styles: {
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
      },
    })
  }
}

function getSinglePaymentsHeadRow () {

  return [{
    type: i18n.t("pdf.paymentType"),
    starting: i18n.t("create_rent_contract.single_payment_date"),
    net: i18n.t("pdf.netSum_month"),
    alv: `${i18n.t("VAT")} %`,
    gross: i18n.t("pdf.grossSum_month"),
    allocation: i18n.t("pdf.allocation"),
  }]
}

function singlePaymentsTable (payment, targetUnitCodes) {
  const grossSum = (1 + ( payment?.paymentVat / 100 )) * payment?.netSum
  return [
    payment?.paymentType,
    toDateString(payment?.paymentDate),
    payment?.netSum !== undefined ? `${helpers.humanize.amount_long(Number(payment?.netSum), 2).replace('.',',')} €` : '',
    payment?.paymentVat !== undefined ? `${payment?.paymentVat} %` : '',
    grossSum !== undefined ? `${helpers.humanize.amount_long(Number(grossSum), 2).replace('.',',')} €` : '',
    payment?.wholeContractPayment ? i18n.t("create_rent_contract.single_payment_whole_contract") : targetUnitCodes,
  ]
}

function addAdditionalInfo (doc, additionalInfo, title, yPosition) {
  if (additionalInfo !== null && additionalInfo !== "" && additionalInfo !== undefined) {
    doc.text(i18n.t(title), 10, doc.lastAutoTable.finalY + 15)
    yPosition = doc.lastAutoTable.finalY + 20
    doc.autoTable({
      startY: yPosition,
      body: [[additionalInfo]],
      theme: 'grid',
      margin: { left: 10, right: 10 },
    })
    yPosition = doc.lastAutoTable.finalY
  }
}

function noticePeriodTable (rentPeriod) {

  return rentPeriod.hasContractPeriodOfExtension ?  
    [
      [i18n.t("pdf.landlordNoticePeriod"), rentPeriod?.noticePeriodLandlord + " " + i18n.t("Mth")],
      [i18n.t("pdf.tenantNoticePeriod"), rentPeriod?.noticePeriodTenant + " " + i18n.t("Mth")],
      [i18n.t("pdf.landlordExtensionPeriod"), rentPeriod.landlordExtensionPeriod ? rentPeriod?.landlordExtensionPeriod + " " + i18n.t("Mth") : null],
      [i18n.t("pdf.tenantExtensionPeriod"), rentPeriod.tenantExtensionPeriod ? rentPeriod?.tenantExtensionPeriod + " " + i18n.t("Mth") : null],
      [i18n.t("pdf.landlordEarliestNoticeDate"), toDateString(rentPeriod?.earliestNoticeDateLandlord)],
      [i18n.t("pdf.tenantEarliestNoticeDate"), toDateString(rentPeriod?.earliestNoticeDateTenant)]
    ] : 
    [
      [i18n.t("pdf.landlordNoticePeriod"), rentPeriod?.noticePeriodLandlord + " " + i18n.t("Mth")],
      [i18n.t("pdf.tenantNoticePeriod"), rentPeriod?.noticePeriodTenant + " " + i18n.t("Mth")],
      [i18n.t("pdf.landlordEarliestNoticeDate"), toDateString(rentPeriod?.earliestNoticeDateLandlord)],
      [i18n.t("pdf.tenantEarliestNoticeDate"), toDateString(rentPeriod?.earliestNoticeDateTenant)]
    ]
}

function getRentPeriod (rentPeriod) {

  return [
    [i18n.t("pdf.contract_valid"), rentPeriod?.rentPeriodEndDate ? i18n.t("pdf.fixed_term") : i18n.t("pdf.permanent") ],
    [i18n.t("pdf.start_date"), toDateString(rentPeriod?.rentPeriodStartDate)],
    [i18n.t("pdf.end_date"), toDateString(rentPeriod?.rentPeriodEndDate)],
    [i18n.t("pdf.tenure_start_date"), toDateString(rentPeriod?.tenureStartDate)],
    [i18n.t("pdf.payments_start_date"), toDateString(rentPeriod?.billingStartDate)],
    [i18n.t("pdf.contract_validity_additional_info"), rentPeriod?.validityAdditionalInfo],
  ]
}

function housingModificationsTable (housingModification) {

  return housingModification?.housingModification === 'contract.no_housing_modifications' || !housingModification?.housingModification ?
  [
    [i18n.t("pdf.housingModifications"), i18n.t("No")]
  ] :
  [
    [i18n.t("pdf.housingModifications"), i18n.t("Yes")],
    [i18n.t("pdf.housingModificationsAgreedUpon"), housingModification?.housingModificationAgreedUpon]
  ]
}

function toDateString (date) {

  if (date === null) {
    return "–"
  }
  var newDate = new Date(date)
  return isNaN(newDate) ? "" : newDate.toLocaleDateString("fi-FI")
}

function getContractSiteTable (units, spaceUser, spaceUsage) {
  // Unique sites and addresses line by line
  const contractSites = [...new Set(units.map(unit => unit.site))].join("\n");
  const contractAddresses = [...new Set(units.filter(unit => unit.address !== "").map(unit => unit.address))].join("\n");
  
  return [
    [i18n.t("pdf.site_name"), contractSites],
    [i18n.t("pdf.address"), contractAddresses],
    [i18n.t("Space user"), spaceUser ? spaceUser : ""],
    [i18n.t("SpaceUsage"), spaceUsage ? spaceUsage : ""],
  ]
}

function getSpaceHeadRow () {

  return [{ 
    id: i18n.t("pdf.unique_id"), 
    purpose: i18n.t("pdf.purpose"),
    area: i18n.t("pdf.area"),
  }]
}

function getParkingSpaceHeadRow () {

  return [{ 
    id: i18n.t("pdf.unique_id"), 
    purpose: i18n.t("pdf.purpose"),
    area: i18n.t("pdf.amount"),
  }]
}

function getSpaceTableRow (unitData) {

  return [
    unitData.unitCode,
    unitData.usage,
    `${helpers.humanize.amount_long(unitData.agreedArea, 1).replace('.',',')} m²`,
  ]
}

function getTotalAreaTableRow (area) {
  return [
    i18n.t('pdf.totalArea'),
    null,
    `${helpers.humanize.amount_long(area, 1).replace('.',',')} m²`
  ]
}

function getParkingSpaceTableRow (unitData) {
  return [
    unitData.unitCode,
    unitData.rentalTypeName,
    unitData.agreedAmount,
  ]
}

function getTotalAmountTableRow (amount) {
  return [
    i18n.t('pdf.totalAmount'),
    null,
    `${amount} ${i18n.t('pcs')}`
  ]
}

function getTenantTable (tenant, tenantIdentityNumber, tenantContactPerson ) {

  if (tenant === undefined)
    return []

  if (tenant.type === 1) {
    return [
      [i18n.t("pdf.tenant"), tenant?.name],
      [i18n.t("pdf.person_id"), tenantIdentityNumber],
      [i18n.t("pdf.email"), tenant?.email],
      [i18n.t("pdf.phone"), tenant?.phone_number],
    ]
  } else {
  return [
    [i18n.t("pdf.tenant"), tenant?.name],
    [i18n.t("pdf.y_code"), tenant?.business_id],
    [i18n.t("pdf.email"), tenant?.email],
    [i18n.t("pdf.phone"), tenant?.phone_number],
    [i18n.t("pdf.contact_person_name"), tenantContactPerson?.name],
    [i18n.t("pdf.contact_person_email"), tenantContactPerson?.email],
    [i18n.t("pdf.contact_person_phone"), tenantContactPerson?.phone_number],
    ]
  }
}

function getLandlordTable (landlord, landlordContactPerson) {

  return [
    [i18n.t("pdf.landlord"), landlord?.name],
    [i18n.t("pdf.phone"), landlord?.phone_number],
    [i18n.t("pdf.address"), landlord?.address],
    [i18n.t("pdf.y_code"), landlord?.business_id],
    [i18n.t("pdf.contact_person_name"), landlordContactPerson?.name],
    [i18n.t("pdf.contact_person_email"), landlordContactPerson?.email],
    [i18n.t("pdf.contact_person_phone"), landlordContactPerson?.phone_number],
    [i18n.t("pdf.bank_account"), landlord?.account_number],
  ]
}