<template>
  <v-card v-if="readyState">
    <BaseModal
      @cancel="$emit('close')"
    >
      <template #title>
        {{ $t('internalrent.edit_cost_centers') }}
      </template>
      <template #content>
        <v-container>
          <v-row no-gutters>
            <v-col cols="5">
              <h4 class="text--secondary">
                {{ $t('Cost center').toUpperCase() }}
              </h4>
            </v-col>
            <v-col cols="7">
              <v-row no-gutters>
                <v-col cols="4">
                  <h4 class="text--secondary">
                    {{ $t('internalrent.allocated_for_spaces') }}
                  </h4>
                </v-col>
                <v-col cols="4">
                  <h4 class="text--secondary">
                    {{ $t('internalrent.zero_allocation') }}
                  </h4>
                </v-col>
                <v-col cols="4">
                  <h4 class="text--secondary">
                    {{ $t('internalrent.ignore') }}
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <div
          v-for="item in items"
          :key="item.id"
          cols="12"
        >
          <v-container>
            <v-row
              align="center"
              class="flex-nowrap"
              no-gutters
            >
              <v-col cols="4">
                <v-text-field
                  :value="costCenter(item)"
                  disabled
                  class="ma-0"
                />
              </v-col>
              <v-col
                cols="1"
                class="cost-center"
              >
                <v-btn
                  small
                  icon
                >
                  <v-icon
                    :class="openItem === item.id ? '' : 'listButton'"
                    @click.stop="openList(item.id)"
                  >
                    view_list
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="7">
                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col
                    v-for="index in [0, 1, 2]"
                    :key="index"
                    cols="4"
                  >
                    <v-switch
                      v-if="costCenterOwnRule(item)"
                      v-model="dataItems[item.id][index]"
                      :disabled="readOnlyCheck(item.id, index)"
                      class="unchangeable"
                      color="warning"
                      @change="dataItemChange(item.id, index)"
                    />
                    <v-switch
                      v-else
                      v-model="dataItems[item.id][index]"
                      :disabled="readOnlyCheck(item.id, index)"
                      @change="dataItemChange(item.id, index)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <div v-if="openItem === item.id">
            <div
              v-for="space in item.spaces"
              :key="space.idSpace"
            >
              <v-container>
                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col
                    cols="4"
                    class="cost-center-left"
                  >
                    <v-text-field
                      :value="spaceName(space)"
                      disabled
                      class="ml-8"
                    />
                  </v-col>
                  <v-col
                    cols="1"
                    class="cost-center"
                  />
                  <v-col cols="7">
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-col
                        v-for="index in [0, 1, 2]"
                        :key="index"
                        cols="4"  
                      >
                        <v-switch 
                          v-if="costCenterOwnRule(item)"                       
                          :input-value="spaceAllocation(space.idSpace, item, index)"
                          :disabled="spaceReadOnlyCheck(space.idSpace, item, index)"
                          color="warning"
                          class="unchangeable"
                          @change="spaceChange($event, index, space.idSpace, item)"
                        />
                        <v-switch
                          v-else
                          :input-value="spaceAllocation(space.idSpace, item, index)"
                          :disabled="spaceReadOnlyCheck(space.idSpace, item, index)"
                          @change="spaceChange($event, index, space.idSpace, item)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <v-btn
          rounded
          depressed
          color="primary"
          :disabled="saving || !changedItems.length"
          @click="saveChanges"
        >
          <span v-if="!saving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import BaseModal from '../general/BaseModal.vue'
const rulesArray = ['AllocateToSpaces', 'ZeroAllocation', 'ZeroExpense', 'None']
export default {
  emits: ['close'],
  name: 'ValuationDialog',
  components: {
    BaseModal,
  },
  props: {
    items: {
      type: Array,
      default: null
    },
    portfolioId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataItems: [],
      ready: false,
      changedItems: [],
      openItem: '',
      spaceRuleChanges: [],
      saving: false
    }
  },
  methods: {
    ...mapActions('internalRent', ['updateRules']),
    costCenter (item) {
      return `${item.code} ${item.name}`
    },
    costCenterOwnRule (item) {
      if (item.rule.allowModification === false) {
        return true
      }
      return false
    },
    spaceName (item) {
      return `${item.code} ${item.purposeOfUseName}       ${item.floorName}`
    },
    spaceChange (event, index, idSpace, item) {
      const newRule = event ? rulesArray[index] : rulesArray[3]
      const rule = {
        id: item.id,
        rule: {
          idSpace: idSpace,
          rule: newRule,
          spaces: []
        }
      }

      this.spaceRuleChanges = this.spaceRuleChanges.filter(
        s => s.rule.idSpace !== idSpace
      )

      const originalRule = item.rule.spaceRules.find(
        rule => rule.idSpace === idSpace
      )
      if (!(originalRule && originalRule.rule === newRule)) {
        this.spaceRuleChanges.push(rule)
        if (!this.changedItems.includes(item.id)) {
          this.changedItems.push(item.id)
        }
      }
    },
    dataItemChange (id, index) {
      // reset all states
      const item = [false, false, false]
      if (this.dataItems[id][index]) {
        // set changed item state
        item[index] = this.dataItems[id][index]
      }
      this.dataItems[id] = item

      // log items that have changed
      if (!this.changedItems.includes(id)) {
        this.changedItems.push(id)
      }
    },
    openList (id) {
      this.openItem = this.openItem !== id ? id : ''
    },
    async saveChanges () {
      // check if any item actually has changed to different value
      this.saving = true
      const list = this.items
        .filter(item => {
          return this.changedItems.includes(item.id)
        })
        .filter(item => {
          if (
            item.rule.rule !== this.changedAllocation(item.id) ||
            this.spaceRuleChanges
              .map(spaceRule => spaceRule.id)
              .includes(item.id)
          ) {
            return true
          }
          return false
        })
      if (list.length > 0) {
        const updatedItems = this.items.map(item => {
          item.allocate_to_spaces = this.dataItems[item.id][0]
          item.zero_allocation = this.dataItems[item.id][1]
          item.zero_expense = this.dataItems[item.id][2]
          item.rule.rule = this.changedAllocation(item.id)
          const newSpaceRules = this.spaceRuleChanges
            .filter(spaceRule => spaceRule.id === item.id)
            .map(s => s.rule)
          const spaceRuleFilter = newSpaceRules.map(s => s.idSpace)
          const filteredOldRules = item.rule.spaceRules.filter(
            s => !spaceRuleFilter.includes(s.idSpace)
          )
          item.rule.spaceRules = [...filteredOldRules, ...newSpaceRules]
          return item.rule
        })
        await this.updateRules({
          portfolioId: this.portfolioId,
          data: updatedItems
        })
        this.saving = false
      }
      this.$emit('close')
    },
    readOnlyCheck (id, index) {
      return this.dataItems[id][index]
        ? false
        : this.dataItems[id].some(item => item)
    },
    spaceReadOnlyCheck (idSpace, item, type) {
      return this.spaceAllocation(idSpace, item, type)
        ? false
        : this.spaceAllocation(idSpace, item, 0) ||
            this.spaceAllocation(idSpace, item, 1) ||
            this.spaceAllocation(idSpace, item, 2)
    },
    changedAllocation (id) {
      const index = this.dataItems[id].findIndex(item => item === true)
      return index < 0 ? rulesArray[3] : rulesArray[index]
    },
    spaceAllocation (idSpace, item, type) {
      // first check if rule has been updated
      const updatedRule = this.spaceRuleChanges.find(
        rule => rule.rule.idSpace === idSpace
      )
      if (updatedRule) {
        return updatedRule.rule.rule === rulesArray[type]
      }
      // check if space has its' own rule set, if not then use costcenter rule
      const ruleFound = item.rule.spaceRules.find(
        rule => rule.idSpace === idSpace
      )
      if (!ruleFound) {
        const rule = this.changedAllocation(item.id)
        return rule === rulesArray[type]
      } else {
        return ruleFound.rule === rulesArray[type]
      }
    }
  },
  computed: {
    readyState () {
      return this.ready
    }
  },
  mounted () {
    const costCenterAllocations = {}
    this.items.forEach(item => {
      costCenterAllocations[item.id] = [
        item.allocate_to_spaces,
        item.zero_allocation,
        item.zero_expense
      ]
    })
    this.dataItems = costCenterAllocations
    this.ready = true
  }
}
</script>

<style>
.grey-text {
  color: #8b8b8b;
}
.cost-center {
  padding-right: 5em;
}
.cost-center-left {
  padding-left: 3em;
}
.listButton {
  color: var(--c-color-accent) !important;
}
.unchangeable {
  pointer-events: none;
}
.v-input--is-disabled {
  background-color: white !important;
}
</style>
