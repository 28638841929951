<template>
  <div>
    <v-expansion-panels
      :value="[0]"
      multiple
    >
      <v-expansion-panel
        class="no-padding"
      >
        <v-expansion-panel-header>
          {{ $t('LTP Tasks') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="text-align: center; padding: 2em;"
          >
            <v-progress-circular
              size="64"
              indeterminate
              color="primary"
            />
          </div>
          <v-data-table
            v-else
            id="scrollable"
            :header-props="dataTableHeaderDefinitions"
            :headers="data.headers"
            :items="items"
            class="elevation-0 viewsize-scroll"
            mobile-breakpoint="0"
            :options.sync="options"
            multi-sort
            :footer-props="{
              itemsPerPageOptions: [],
            }"
            :no-data-text="$t('No data available')"
          >
            <template
              v-slot:item="props"
            >
              <tr v-if="data.headers !== null">
                <td
                  v-for="header in data.headers"
                  :key="props.item.id + header.value"
                  class="active-colunm"
                  :style="getStyle(header, props.item)"
                  @click="handleClick(header, props.item)"
                >
                  <template v-if="header.value === 'latestComment'">
                    <v-tooltip
                      bottom
                      max-width="400"
                    >
                      <template #activator="{ on }">
                        <div
                          class="text-truncate active-colunm"
                          style="max-width: 150px;"
                          v-on="on"
                        >
                          {{ getLatestComment(props.item) }}
                        </div>
                      </template>
                      <span>{{ getLatestComment(props.item) }}</span>
                    </v-tooltip>
                  </template>
                  <template v-if="header.value === 'taskDescription'">
                    <v-icon :color="props.item.taskDescription ? 'primary' : ''">
                      email
                    </v-icon>
                  </template>
                  <template v-if="header.value === 'finished'">
                    <v-icon :color="props.item.finished ? 'primary' : ''">
                      done
                    </v-icon>
                  </template>
                  <template v-if="header.isYear">
                    <v-chip
                      v-if="startYearColor(header.text, props.item) !== null"
                      color="white"
                      class="year-chip"
                      small
                    >
                      {{ formatCurrency(props.item.budget, props.item.currency) }}
                    </v-chip>
                  </template>
                  <template v-else>
                    <template v-if="header.editable === true && edit">
                      <input
                        v-if="edit"
                        v-model="getDataObject(header.object)[props.item.id]"
                        @change="editTask(props.item)"
                      >
                    </template>
                    <template v-else>
                      <template v-if="header.format === 'Number' || header.format === 'Money'">
                        {{ $t(props.item[header.value]) ? formatThousands(props.item[header.value]) : null }}
                      </template>
                      <template v-else-if="header.format === 'Boolean'">
                        {{ $t(props.item[header.value]) ? getBoolean(props.item[header.value]) : null }}
                      </template>
                      <template v-else-if="header.format === 'Status'">
                        {{ getStatus(props.item[header.value]) }}
                      </template>
                      <template v-else-if="header.format === 'Time'">
                        {{ formatTime(props.item[header.value]) }}
                      </template>
                      <template v-else>
                        {{ $t(props.item[header.value]) }}
                      </template>
                    </template>
                  </template>
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <tr>
                <td
                  v-for="(footer, idx) in data.footers"
                  :key="idx"
                  style="text-align: center"
                  v-html="Sanitize(
                    getFooterValue(
                      data.items,
                      footer.value,
                      footer.text,
                      footer.format,
                      footer.average
                    ))
                  "
                />
              </tr>
            </template>
            <template
              v-slot:footer.page-text="item"
            >
              <v-btn
                small
                outlined
                rounded
                @click="exportAs('csv')"
              >
                {{ $t('Export to spreadsheet') }}
              </v-btn>
              {{ item.pageStart }}-{{ formatThousands(item.pageStop) }} {{ $t('of') }} {{ item.itemsLength.toLocaleString('fi-Fi') }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row
      justify="end"
      style="right: 0; bottom: 0; position: sticky; z-index: 1"
    >
      <v-btn
        v-if="edit && edited"
        rounded
        depressed
        :disabled="!edited"
        class="primary"
        @click="saveChanges"
      >
        {{ $t('Save changes') }}
      </v-btn>&nbsp;
    </v-row>
  </div>
</template>
<script>
import helpers from '../../helpers'
import format from '../../helpers/format.js'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

export default {
  emits: ['continue', 'handleDialog', 'handleDialogForComments'],
  name: 'TaskListTable',
  components: {},
  props: {
    data: Object,
    loading: Boolean,
    edit: Boolean
  },
  data () {
    return {
      responsibilitys: {},
      designers: {},
      builders: {},
      supervisors: {},
      contractors: {},
      editedTasks: [],
      showTaskListDialog: false,
      headersList: [],
      to: null,
      tableData: {
        title: '',
        headers: [],
        rows: [],
        footers: []
      },
      options: {
        sortDesc: this.data.sortDesc ? this.data.sortDesc : [ true, true ],
        page: 1,
        itemsPerPage: -1,
        sortBy: this.data.sortBy ? this.data.sortBy : [],
        totalItems: 0
      },      
      paginated: true,
      topScrollPosition: 0,
      leftScrollPosition: 0,
      documentScrollPosition: 0
    }
  },
  computed: {
    ...mapGetters('app' , ['dataTableHeaderDefinitions']),
    ...mapState('ltp', ['edited', 'saveStatus']),
    items: function () {
      return this.data.items.map(item => {
        return item
      })
    }
  },
  watch: {
    'data.headers': async function () {
      if (!this.loading) {
        await this.setDataFields()
        document.getElementById('scrollable').children[0].scrollTop = this.topScrollPosition
        document.getElementById('scrollable').children[0].scrollLeft = this.leftScrollPosition
        document.documentElement.scrollTop = this.documentScrollPosition
      }
    },
    saveStatus: async function () {
      if (this.saveStatus) {
        await this.saveChanges()
        this.$emit('continue')
      } else {
        this.editedTasks = []
        this.setDataFields()
      }
    },
    edited: function () {
      if (!this.edited) {
        this.editedTasks = []
        this.setDataFields()
      }
    }
  },
  methods: {
    ...mapActions('ltp', ['updateManyTasks']),
    ...mapMutations('ltp', ['setEdited']),
    /**
     * getDataObject provides data object to the vue components if needed
     * Otherwise, we would need to handle each object separately. Now it
     * is configurable via headers
     */
    getDataObject (obj) {
      return this[obj]
    },
    /**
     * getStyle provides style to capex tasks
     * At the moment it is used mainly for year coloring
     */
    getStyle (header, data) {
      if (typeof header.isYear !== 'undefined') {
        return {
          background: this.startYearColor(header.text, data)
        }
      }

      if (header.format === 'Number' || header.format === 'Money') {
        return {
          textAlign: 'right'
        }
      }

      return undefined
    },
    /**
     * handleClick provides click event for the data rows
     */
    handleClick (header, data) {
      if (header.dialog !== undefined && header.dialog !== null && header.dialog > 0) {
        // do not open dialog in edit mode if the field is editable
        if (header.editable && this.edit) {
          return
        }
        // number 10 assigned to comment handling. Otherwise, use the default one
        if (header.dialog < 10) {
          this.taskHandlingDialog(6, true, data)
        } else {
          this.commentHandlingDialog(true, data)
        }
      }
    },
    editTask: function (task) {
      if (!this.editedTasks.includes(task.id)) {
        this.editedTasks.push(task.id)
        this.setEdited(true)
      }
    },
    getLatestComment (task) {
      if (!task.comments || task.comments.length < 1) {
        return null
      }
      const commentIds = task.comments.map(comment => comment.commentId)
      const latestId = Math.max(...commentIds)
      return task.comments.filter(comment => comment.commentId === latestId)[0]
        .comment
    },
    saveChanges: async function () {
      const tasks = []
      this.editedTasks.forEach(id => {
        const task = this.data.items.find(task => task.id === id)
        const editedTask = { ...task }
        editedTask.responsibility = this.responsibilitys[id]
        editedTask.designer = this.designers[id]
        editedTask.builder = this.builders[id]
        editedTask.supervisor = this.supervisors[id]
        editedTask.contractor = this.contractors[id]
        tasks.push(editedTask)
      })
      this.updateManyTasks(tasks)
      this.editedTasks = []
      this.setEdited(false)
    },
    showAll () {
      if (this.paginated) {
        this.options.itemsPerPage = -1
      } else {
        this.options.itemsPerPage = this.data.rowsPerPage
          ? this.data.rowsPerPage
          : 5
      }
      this.paginated = !this.paginated
    },
    formatTime (time) {
      const plannedMonth = new Date(time).getMonth() + 1
      const month = `${
        plannedMonth >= 10 ? plannedMonth : '0' + plannedMonth.toString()
      }`
      const year = new Date(time)
        .getFullYear()
        .toString()
      return `${month}/${year}`
    },
    formatThousands (value) {
      return helpers.humanize.thousand_separator(value)
    },
    formatLongAmount (value, decimalAmount, thousandSeparator) {
      return helpers.humanize.amount_long(value, decimalAmount, thousandSeparator)
    },
    formatCurrency (val, currency){
      const cur = format.symbolForCurrencyAbbreviation(currency)
      return helpers.humanize.thousand_separator(Number(val)) + ' ' + cur
    },
    startYearColor (year, task) {
      const startYear = new Date(task.implementationStart).getFullYear()
      if (year !== startYear) {
        return null
      }

      if (!task.schedules || task.schedules.length === 0) {
        return '#add095' // Green
      }

      if (task.schedules.length === 1) {
        return '#ffe582' // Yellow
      }

      return '#ff8855' // Red
    },
    getStatus (status) {
      const statuses = helpers.ltpConstants.statusMappings()
      return this.$t(statuses[status])
    },
    getBoolean (value) {
      const values = helpers.ltpConstants.booleanMappings()
      return this.$t(values[value])
    },
    getFooterValue (data, headerName, text, format, average) {
      if (typeof text !== 'undefined') {
        return text
      }
      if (typeof headerName === 'undefined' || typeof data === 'undefined') {
        return null
      }

      // We cannot sum up columns that have differing currencies.
      if (headerName === 'budget' || headerName === 'budgetSpent') {
        if (this.data.items.length > 0 && this.data.items.some(x => x.currency !== this.data.items[0].currency)) {
          return '-'
        }
      }

      // Sum up the different budgets that start on the same years. Also handle differing currencies.
      if (headerName.includes('year')) {
        const yearToAccumulateFor = this.data.years.find(x => x.value === headerName)?.text
        const rowsForYear = this.data.items.filter(x => new Date(x.implementationStart).getFullYear() === yearToAccumulateFor)
        if (rowsForYear.length > 0 && rowsForYear.some(x => x.currency !== rowsForYear[0].currency)) {
          return '-'
        } else {
          return rowsForYear.map(x => x.budget).reduce((acc, cur) => acc + cur, 0)
        }
      }

      let value = data
        .map(i => {
          const toNumber = Number(i[headerName])
          return isNaN(toNumber) ? 0 : toNumber
        })
        .reduce((acc, cur) => Number(acc) + Number(cur), 0)
      if (typeof average !== 'undefined' && average === true) {
        value = (parseFloat(value) / data.length).toFixed(2)
      }
      if (typeof format !== 'undefined') {
        value = helpers.format.formatData(value, format)
      }
      return value
    },
    exportAs (type) {
      const headers = this.data.headers
        .map(header => {
          if (header.value === 'latestComment') {
            header.value = 'comments'
          }
          return header
        })

      let data = [...JSON.parse(JSON.stringify(this.data.items))]
      const footers = this.createFooters(headers, data)
      data = [...data, ...footers]
      // Komponentissa käytetään multisorttausta, joten sortDesc on muotoa [true, true]. Halutaanko molemmat ottaa jotenkin
      // huomioon kun määritellään exportin sorttausjärjestys? Nyt otetaan huomioon ainoastaan ensimmäinen. Tämä komponentti taisi olla
      // aiemmin rikki, sillä se ei saanut mistään descending -propsia johon tämä if-lause oli sidottu.
      if (this.options.sortDesc[0]) {
        data.sort((a, b) =>
          a[this.options.sortBy] < b[this.options.sortBy] ? 1 : -1
        )
      } else {
        data.sort((a, b) =>
          a[this.options.sortBy] > b[this.options.sortBy] ? 1 : -1
        )
      }

      if (type === 'csv') {
        const csvHeaders = headers.map(h => h.text)
        const csvData = data.map(d => {
          const row = []
          headers.forEach(h => {
            let val = d[h.value]
            if (h.isYear) {
              if (new Date(d.implementationStart).getFullYear() === h.text) {
                val = d.budget
              }
            }
            val = val === undefined ? '' : val
            val = this.getValueWithUnitForCsvExport(val, h.value, false)
            row.push(val)
          })
          return row
        })
        helpers.csv.export(csvHeaders, csvData)
      }
    },
    getValueWithUnitForCsvExport (value, header, encode) {
      if (value === null || value === '') {
        return ''
      }
      if (header.includes('implementationStart')) {
        return this.formatTime(value)
      }
      if (header.includes('finished')) {
        return value ? this.$t('Yes') : this.$t('No')
      }
      if (header.includes('implementationStatus')) {
        return this.getStatus(value)
      }

      if (!isNaN(value)) {
        return value.toString().replace('.', ',')
      }

      if (header.includes('comments')) {
        const commentList = []
        value.forEach(comment => {
          commentList.push(
            this.clearText(
              `${comment.comment} ${new Date(
                comment.submitted
              ).toLocaleDateString('fi-FI', options)} ${comment.submitter}`
            )
          )
        })
        return commentList.join('. ')
      }

      if (typeof value === 'string') {
        return this.clearText(value)
      }

      return value
    },
    clearText (text) {
      // replaces linebreaks and semicolons from the text string
      text = text.replace(/\n/g, ' ')
      return text.replace(/;/g, '.')
    },
    createFooters (headers, rows) {
      return [
        headers.reduce((acc, cur, idx) => {
          if (idx === 0) {
            acc[cur.value] = this.$t('Total')
            return acc
          }
          // At this point sum area values
          if (cur.value.includes('budget')) {
            if (rows.length > 0 & rows.some(x => x.currency !== rows[0].currency)) {
              acc[cur.value] = '-'
            } else {
              acc[cur.value] = rows
              .map(r => Number(r[cur.value]))
              .reduce((acc, cur) => acc + cur, 0)
            }
          } else if (cur.value.includes('year')) {
              const yearToAccumulateFor = this.data.years.find(x => x.value === cur.value)?.text
              const rowsForYear = rows.filter(x => new Date(x.implementationStart).getFullYear() === yearToAccumulateFor)
              if (rowsForYear.length > 0 && rowsForYear.some(x => x.currency !== rowsForYear[0].currency)) {
                acc[cur.value] = '-'
              } else {
                acc[cur.value] = rowsForYear.map(x => x.budget).reduce((acc, cur) => acc + cur, 0)
              }
            } else {
            acc[cur.value] = null
          }
          return acc
        }, {})
      ]
    },

    taskHandlingDialog (step, dialogOpen, task) {
      this.storeScrollPosition()
      this.$emit('handleDialog', step, dialogOpen, task)
    },
    setDataFields () {
      this.data.items.forEach(item => {
        this.responsibilitys[item.id] = item.responsibility
        this.designers[item.id] = item.designer
        this.builders[item.id] = item.builder
        this.supervisors[item.id] = item.supervisor
        this.contractors[item.id] = item.contractor
      })
    },
    commentHandlingDialog (dialogOpen, task) {
      this.storeScrollPosition()
      this.$emit('handleDialogForComments', dialogOpen, task)
    },
    storeScrollPosition () {
      // Due to the viewsize-scroll class on datatable, the element that contains a
      // scroll position, is the direct child of scrollable
      this.topScrollPosition = document.getElementById('scrollable').children[0].scrollTop
      this.leftScrollPosition = document.getElementById('scrollable').children[0].scrollLeft
      this.documentScrollPosition = document.documentElement.scrollTop
    },
    Sanitize (text) {
      return helpers.format.sanitize(text)
    }
  }
  
}
</script>
<style>
.fieldText {
  width: 100%;
  min-width: 1em;
  height: 100%;
  min-height: 2em;
  display: block;
}
.active-colunm {
  cursor: pointer;
}
.year-chip {
  pointer-events: none;
}
</style>
