import { i18n } from '../plugins/i18n.js'
/**
 * Creates footers based on headers, rows, and footer options.
 *
 * @param {Object[]} headers - The headers for the data.
 * @param {Object[]} rows - The row data.
 * @param {Object[]} browseFooters - The footers for "View".
 * @param {Object[]} footers - The footers for the data.
 * @param {Boolean} hideTotalRow - Whether to hide the total row.
 * @returns {Object[]|null}  Returns an array of footer object or null.
 */
export default { 
    createFooters (headers, rows, browseFooters, footers, hideTotalRow = false, averageTotalRow = false) {
        // If browseFooters is not defined, use footers.
        const usedFooters = browseFooters ?? footers;
        if (!usedFooters) {
            // If neither browseFooters nor footers is defined, return null.
            return null;
        }

        // If there are multiple currencies in the data, selectedCurrency value will be 'multiple'
        const rowCurrencies = [...new Set(rows.map(i => i.currency_id))]
        const selectedCurrency = rowCurrencies.length === 1 ? rowCurrencies[0] : 'multiple'

        // Reduce the headers array to a single object that represents the footer row.
        return [headers.reduce((accumulator, currentHeader, index) => {
            // Find a footer that matches the current header.
            const matchingFooter = usedFooters.find(footer => footer.value === currentHeader.value);

            if (index === 0) {
                // Set the footer value to "average" if averageTotalRow set else if 'Total' if hideTotalRow = false, else ''  
                accumulator[currentHeader.value] = averageTotalRow ? i18n.t('audits.average') : !hideTotalRow ? i18n.t('Total') : '';
                // set currency for sums
                accumulator['currency_id'] = selectedCurrency
            } else if (matchingFooter && matchingFooter.unique) {
                // If there's a matching footer and it's unique, calculate the sum of unique values in the rows for the current header.
                const uniqueValues = [...new Set(rows.map(row => row[currentHeader.value]))];
                const sum = uniqueValues.reduce((total, value) => Number(total) + (isNaN(value) ? 1 : Number(value)), 0);
                accumulator[currentHeader.value] = sum;
            } else if (typeof currentHeader.average !== 'undefined' && currentHeader.average === true) {
                // If the current header has an 'average' property and it's true, calculate the average of the values in the rows for the current header.
                const val = rows
                .map(r => Number(r[currentHeader.value]))
                .reduce((acc, cur) => acc + cur, 0)
                accumulator[currentHeader.value] = (parseFloat(val) / rows.length).toFixed(2)
            } else if (doesFootersContainValue(currentHeader.value, usedFooters)) {
                // If the used footers contain the current header value, calculate the sum of the values in the rows for the current header.
                const sum = rows
                .map(row => isNaN(row[currentHeader.value]) ? 1 : Number(row[currentHeader.value]))
                .reduce((total, value) => total + value, 0);
                accumulator[currentHeader.value] = sum;
            }
            return accumulator;
        }, {})];
    },
    /**
    * Function sorts objects based on the 'name' property of the first object in each object.
    * It's used for custom sorting in a v-data-table.
    *
    * @param {Array} a - The first object to compare.
    * @param {Array} b - The second object to compare.
    * @returns {number} - The sorting order. Returns -1 if 'a' should come before 'b', 1 if 'a' should come after 'b', and 0 if they are equal.
    */
    sortByObjectName (a, b) {
        const nameA = a[0]?.name?.toUpperCase() || ""
        const nameB = b[0]?.name?.toUpperCase() || ""
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
    },
    /**
     * Determines the currency symbol for a set of rows.
     * If all rows have the same currency, returns the corresponding currency symbol.
     * If rows have multiple currencies, returns 'multiple'.
     *
     * @param {Array} rows - The array of row objects, each containing a currency_id property.
     * @param {Array} currencies - The array of currency objects, each containing an id and symbol property.
     * @returns {string} - The currency symbol if all rows have the same currency, otherwise 'multiple'.
     */
    defineRowCurrency (rows, currencies) {
        const uniqueCurrencies = [...new Set(rows.map(i => i.currency_id))]
        return uniqueCurrencies.length === 1 ? currencies.find(c => c.id === uniqueCurrencies?.[0])?.symbol : 'multiple'
    },
}
/**
 * Checks if a value exists in the footers object.
 *
 * @param {String} value - The value to check.
 * @param {Object[]} footers - The array of footer objects.
 * @returns {boolean} - Returns true if the value is found, otherwise false.
 */
function doesFootersContainValue (value, footers) {
    if (!footers) {
        return false;
    }
    return footers.some(footer => footer.value === value);
}

/**
     * Returns an array of field names that represent currency sums.
     *
     * @returns {string[]} An array of strings representing the field names for currency sums.
     */
export const currencySumFields = [
    'budget',
    'year0_budget',
    'year1_budget',
    'year2_budget',
    'year3_budget',
    'year4_budget',
    'year5_budget',
    'year6_budget',
    'year7_budget',
    'year8_budget',
    'year9_budget',
    'estimated_value_impact',
    'totalMarketRent',
] 