import { i18n } from '../../../plugins/i18n.js'

export function guaranteesAwaitingDelivery (contractStates) {  
    return {
    id: "contract.guarantees_awaiting_delivery",
    type: "LazyTable",
    loading: true,
    data: {
      headers: [
        {
          text: "Contract number",
          value: "contractNumber",
          event: "showCreateRentContractModal",
        },
        { text: "tenantName", value: "tenantName" },
        { text: "Target name", value: "siteNames", event: "goToSite" },
        { text: "Landlord contact person", value: "landlordContactPerson" },
        { text: "contract.contract_startdate", value: "contractStartDate", format: "Date" },
        { text: "Contract status", value: "contractStatus", format: "Array" },
        { text: "GuaranteeType", value: "guaranteeType" },
        { text: "GuaranteeDescription", value: "guaranteeDescription" },
        { text: "GuaranteeSum", value: "guaranteeSum", format: "Euro" },
        { text: "Delivered", value: "deliveredSum", format: "Euro" },
        {
          text: "GuaranteeAgreedDateOfDelivery",
          value: "agreedDateOfDelivery",
          format: "Date",
        },
      ],
      customFormatters: {
        contractStatus: (row) => {
          return i18n.t(row.contractStatus)
        },
      },
      source: "contractguaranteesawaitingdelivery",
      items: [],
      triggerGetAllData: 0,
      customPagination: {
        sortBy: ["contractStartDate"],
        sortDesc: [false],
      },
      customFilterContent: {
        contractStatus: contractStates.map((state) => ({
          text: i18n.t(state.name),
          value: state.name,
        })),
      },
    },
  }
}
