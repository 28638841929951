<template>
  <div
    v-if="loading"
    class="ma-auto"
  >
    <v-progress-circular
      size="64"
      indeterminate
      color="primary"
    />
  </div>
  <div v-else>
    <div v-if="latestOwnNotes.length > 0">
      <h2 class="text-subtitle-2 mb-2">
        {{ $t('prospect.latest_own_notes') }} {{ noteTypeString }}
      </h2>
      <div
        v-for="note in latestOwnNotes"
        :key="note.id_prospect_note"
      >
        <v-row
          justify="center"
          align="center"
          class="mb-2"
        >
          <v-col
            cols="6"
            class="linked text-subtitle-2"
            @click="openNotes(note)"
          >
            <span> {{ getNoteLink(note) }}</span>
          </v-col>
          <v-col
            cols="6"
            class="my-0 py-0 text-right extra-small"
          >
            {{ formatDate(note.modified_date) }} <br>
            {{ note.user }} 
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="latestModifiedNotes.length > 0">
      <h2 class="text-subtitle-2 mt-4 mb-2">
        {{ $t('prospect.latest_modified_notes') }} {{ noteTypeString }}
      </h2>
      <div
        v-for="note in latestModifiedNotes"
        :key="note.id_prospect_note"
      >
        <v-row
          justify="center"
          align="center"
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="6"
            class="linked text-subtitle-2"
            @click="openNotes(note)"
          >
            <span> {{ getNoteLink(note) }}</span>
          </v-col>
          <v-col
            cols="6"
            class="my-0 py-0 text-right extra-small"
          >
            {{ formatDate(note.modified_date) }} <br>
            {{ note.user }} 
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="latestCreatedNotes.length > 0">
      <h2 class="text-subtitle-2 mt-4 mb-2">
        {{ $t('prospect.latest_created_notes') }} {{ noteTypeString }}
      </h2>
      <div
        v-for="note in latestCreatedNotes"
        :key="note.id_prospect_note"
      >
        <v-row
          justify="center"
          align="center"
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="6"
            class="linked text-subtitle-2"
            @click="openNotes(note)"
          >
            <span> {{ getNoteLink(note) }}</span>
          </v-col>
          <v-col
            cols="6"
            class="my-0 py-0 text-right extra-small"
          >
            {{ formatDate(note.created) }} <br>
            {{ note.user }}
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import humanize from '../../helpers/humanize.js'
import { getRentalProcessTitle } from '../../helpers/leasing/rentalprocess.js'
export default {
  emits: ['openNotes'],
  name: "NotesSummary",
  props: {
    notes: {
      type: Array,
      default: () => []
    },
    userInfo: {
      type: Object,
      default: () => {}
    },
    sites: {
      type: Array,
      default: () => []
    },
    rentalProcesses: {
      type: Array,
      default: () => []
    },
    corporations: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    noteType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    latestOwnNotes () {
      let userNotes = this.notes.filter(note => note.id_user === this.userInfo.id)
      userNotes.sort((a,b) => new Date(b.modified_date) - new Date(a.modified_date))
      return userNotes.slice(0, 3)
    },
    latestModifiedNotes () {
      let notesCopy = [...this.notes]
      notesCopy.sort((a,b) => new Date(b.modified_date) - new Date(a.modified_date))
      return notesCopy.slice(0, 3)
    },
    latestCreatedNotes () {
      let notesCopy = [...this.notes]
      notesCopy.sort((a,b) => new Date(b.created) - new Date(a.created))
      return notesCopy.slice(0, 3)
    },
    noteTypeString () {
      let text = ''
      switch (this.noteType) {
        case 'customers':
          text = this.$t('Customer')
          break
        default:
          return ''
      }
      text = '(' + text + ')'
      return text
    }
  },
  mounted () {
  },
  methods: {
    getNoteLink (note) {
      if (note.id_prospect != null) {
        let process = this.rentalProcesses.find(item => item.id === note.id_prospect)
        return process ? getRentalProcessTitle(process) : ''
      }
      else if (note.id_kohde != null) {
        let siteName = this.sites.find(item => item.id_site === note.id_kohde)?.name ?? ''
        if (note.id_hsto != null) {
          return siteName + ' - ' + note.unit_name ?? ''
        }
        return siteName
      }
      else if (note.id_osapuoli != null) {
        let corporation = this.corporations.find(item => item.id === note.id_osapuoli)
        return corporation ? corporation.corporation_name : ''
      }
      else {
        return ''
      }
    },
    formatDate (date) {
      return humanize.date(date, 'time')
    },
    openNotes (note) {
      this.$emit('openNotes', note)
    }
  }
}
</script>

<style scoped>
.extra-small {
  font-size: 11px !important;
}
</style>