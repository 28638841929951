<template>
  <div v-if="editUser">
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <BaseModal
          @cancel="close"
        >
          <template #title>
            {{ $t('Change password') }}
          </template>
          <template #content>
            <v-text-field
              v-model="editUser.name"
              :label="$t('Name')"
              readonly
              class="form-field"
            />
            <v-text-field
              v-model="editUser.username"
              :label="$t('Username')"
              readonly
              class="form-field"
            />
            <v-text-field
              v-model="editUser.customer"
              :label="$t('Customer')"
              readonly
              class="form-field"
            />
            <validation-provider
              ref="password"
              v-slot="{ errors }"
              name="password"
              rules="required|min:12"
            >
              <v-text-field
                v-model="password"
                :label="$t('New password')"
                :type="'password'"
                autocomplete="new-password"
                class="form-field"
                required
                :hint="$t('Required')"
                persistent-hint
                counter
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="password1"
              rules="required|confirmed:password"
            >
              <v-text-field
                v-model="password1"
                :label="$t('New password again')"
                :type="'password'"
                class="form-field mt-8 mb-6"
                required
                :hint="$t('Required')"
                persistent-hint
                counter
                :error-messages="errors"
              />
            </validation-provider>
          </template>
          <template #footer>
            <v-btn
              rounded
              :disabled="invalid || !fieldsFilled"
              depressed
              color="primary"
              @click="validate"
            >
              {{ $t('Save') }}
            </v-btn>
          </template>
        </BaseModal>
      </v-form>
    </validation-observer>
  </div>
</template>
<script>
import { required, confirmed, min } from 'vee-validate/dist/rules'
import { i18n } from './../plugins/i18n.js'
import BaseModal from './general/BaseModal.vue'
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: i18n.t('Required')
})

extend('confirmed', {
  ...confirmed,
  message: i18n.t('Passwords do not match')
})

extend('min', {
  ...min,
  message: i18n.t('Min 12 characters')
})

export default {
  emits: ['childToParent'],
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseModal,
  },
  props: {
    editUser: Object
  },
  data () {
    return {
      valid: true,
      childMessage: null,
      password: null,
      password1: null
    }
  },
  computed: {
    fieldsFilled: function () {
      if (this.password && this.password1) {
        if (this.password1.length >= 12) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
    if (this.$store.state.app.userInfo.ssoAccount) {
      this.close()
    }
  },
  methods: {
    close (event) {
      this.password = ''
      this.password1 = ''
      this.$emit('childToParent', false)
    },
    async validate () {
      const formIsValid = await this.$refs.observer.validate()
      if (formIsValid) {
        const data = []
        const newPassword = this.password1

        if (newPassword !== null) {
          this.password = newPassword

          data.push({
            op: 'replace',
            path: '/password',
            value: newPassword
          })
        }

        await this.$rambollfmapi.accounts.patch(this.editUser.id, data)
        this.$emit('childToParent', false)
      }
    }
  }
}
</script>
<style scoped>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}

.form-fields {
  margin-top: 20px !important;
}
</style>
