<template>
  <v-dialog
    v-if="localDataUpdateDialog"
    v-model="localDataUpdateDialog"
    max-width="550"
    persistent
  >
    <BaseModal
      @cancel="cancel"
    >
      <template #title>
        {{ $t('Import from spreadsheet') }}
      </template>
      <template #content>
        <div v-if="importSuccess">
          <div v-if="rentalStatusChanges.length">
            <h3>{{ $t('dataimport.import_success') }}</h3>
            <h4>{{ $t('dataimport.list_changes') }}</h4>
            <ul>
              <li
                v-for="(status, idx) in rentalStatusChanges"
                :key="idx"
              >
                {{ changeText(status) }}
              </li>
            </ul>
          </div>
          <span v-else-if="savingFailed">{{ $t('dataimport.saving_failed') }}</span>
          <span v-else>{{ $t('dataimport.no_changes') }}</span>
        </div>
        <div v-else>
          <h3>
            {{ $t('dataimport.import_failed') }}
          </h3>
        </div>
        <template v-if="!savingFailed">
          <div
            v-for="(errorObject, errorType) in errors"
            :key="errorType"
          >
            <div v-if="errorObject.length > 0">
              <h4>{{ errorHeader(errorType) }}</h4>
              <ul>
                <li
                  v-for="(error, idx) in errorObject"
                  :key="idx"
                >
                  {{ errorText(error, errorType) }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </template>
      <template #footer>
        <v-btn
          v-if="importSuccess && rentalStatusChanges.length"
          rounded
          depressed
          :disabled="isSavingChanges"
          color="primary"
          @click="saveRentalStatusChanges"
        >
          <span v-if="!isSavingChanges">
            {{ $t('Save changes') }}
          </span>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-btn>
        <v-btn
          v-else
          rounded
          depressed
          color="primary"
          @click="ok"
        >
          {{ $t('OK') }}
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>
<script>
import BaseModal from './general/BaseModal.vue'
export default {
  name: 'DataUpdateDialog',
  components: { BaseModal },
  props: {
    dataUpdateDialog: {
      type: Boolean,
      required: true
    },
    importSuccess: Boolean,
    rentalStatusChanges: {
      type: Array,
      default: () => []
    },
    savingFailed: Boolean,
    errors: {
      type: Object,
      default: () => {}
    },
    isSavingChanges: Boolean,
    carParks: Boolean
  },
  emits: ['cancel', 'saveChanges', 'ok'],
  data () {
    return {
      localDataUpdateDialog: this.dataUpdateDialog
    };
  },
  computed: {},
  watch: {
    dataUpdateDialog (newVal) {
      this.localDataUpdateDialog = newVal;
    },
  },
  methods: {
    errorHeader (errorType) {
      if (errorType === 'unexisting') {
        return this.$t('dataimport.not_existing')
      }
      if (this.carParks) {
        return this.importSuccess ? this.$t('dataimport.carpark_changes_ignored') : this.$t('dataimport.carpark_changes_failed')
      }
      return  this.importSuccess ? this.$t('dataimport.changes_ignored') : this.$t('dataimport.changes_failed')
    },
    cancel () {
      this.$emit('cancel')
    },
    saveRentalStatusChanges () {
      this.$emit('saveChanges')
    },
    ok () {
      this.$emit('ok')
    },
    changeText (status) {
      if (this.carParks) {
        return status.siteName + ' ' + status.number
      }
      return status.buildingCode + ' ' + status.unitName
    },
    errorText (error, errorType) {
      if (errorType === 'uneditable') {
        let text = error.buildingCode + ' ' + error.unitName + " - "
        if (this.carParks) {
          text = error.siteName + ' ' + error.number + " - "
        }
        return text + error.label + ": " + error.value
      }
      return error
    }
  }
}
</script>
<style scoped>
</style>
